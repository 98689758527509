import React, { useState } from "react";
import CreateLesson from "./CreateLesson";
import CreateMilstone from "./createMilStone";
import CreateSubjectiveQuiz from "./CreateSubQuiz";

import "./CreateCourse.css";
import Course from "./Course";
import { db, functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import CreateQuiz from "./CreateQuiz";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Navbar from "../../Component/NavbarTop";
import { t } from "i18next";
import Sidebar from "../../Component/Sidebar";
import { getCookie } from "../../../Cookies";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import DeleteLesson from "./lessonDeletePopUp";

import { DeleteSVg, SvgVector, EditSvg, ExpandMoreSvg } from "./icons";
import LoadingScreen from "../BasicCourse/lodingScree";

const CreateCourse = () => {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  const location = useLocation();
  const editCourseId = location.state?.courseId;
  //===========API for Course EDIT=========

  const getCourseInfo = httpsCallable(functions, "getCourseInfo");
  const getLessonInfo = httpsCallable(functions, "getLessonInfo");
  const getQuizInfo = httpsCallable(functions, "getQuizInfo");
  const getQuestionSetInfo = httpsCallable(functions, "getQuestionSetInfo");
  const [editQuizArr, setEditQuizArr] = useState(false);
  const [editCourseInfo, setEditCourseInfo] = useState(false);
  const [checkEditFlag, setCheckEditFlag] = useState(false);
  const [deleteLessonId, setDeleteLessonId] = useState("");
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);
  //=======================================
  const createQuestionSet = httpsCallable(functions, "createQuestionSet");
  const addLessonExtraInfo = httpsCallable(functions, "addLessonExtraInfo");
  const addCourseQuestionSetOrder = httpsCallable(
    functions,
    "addCourseQuestionSetOrder"
  );
  const deleteQuestionSet = httpsCallable(functions, "deleteQuestionSet");

  const getAllQuestionSetDataForCourse = httpsCallable(
    functions,
    "getAllQuestionSetDataForCourse"
  );
  // deleteLesson
  useEffect(() => {
    if (
      getCookie("UID") == null ||
      (getCookie("ROLE") != "admin" && getCookie("ROLE") != "instructor")
    ) {
      goToHome();
      return <></>;
    }
  });

  const [schedule, setSchedule] = useState("immediate");
  const [lessonId, setLessonId] = useState("");
  const [editable, setEditable] = useState(false);
  const [list, setList] = useState([]);
  const [isCourseIdSet, setIsCourseIdSet] = useState("");
  const [checkFailed, setCheckFailed] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const initialInfo = {
    lessonName: "",
    lessonDescription: "",
    created: "",
    isSample: "no",
    hasQuiz: "yes",
    daysAfter: "",
    startDate: "",
    quiz: [],
    topic: [],
    base64Image: "",
    questionSetInstruction: "",
  };
  const [lessonDetails, setLessonDetails] = useState({ ...initialInfo });
  const [checkErrorInForm, setCheckErrorInForm] = useState({
    lessonName: "",
    lessonDescription: "",
    questionSetInstruction: "",
  });

  useEffect(() => {
    if (!editCourseId) return;
    setLoadingScreen(true);
    // setCheckEditFlag(true);

    // setCheckEditFlag(false);

    //setIsCourseIdSet(editCourseId)
    getAllQuestionSetDataForCourse({ courseId: editCourseId })
      .then((res) => {
        setLoadingScreen(false);
        for (let i = 0; i < res.data.length; i++) {
          setEditQuizArr(true);
          setList((prev) =>
            prev.concat({
              id: res.data[i].questionSetId,
              name: res.data[i].questionSetName,
              description: res.data[i].questionSetDescription,

              questionSetInstruction: res.data[i].questionSetInstruction,
              courseAssociated: editCourseId,
            })
          );
        }
      })
      .catch((e) => {
        setLoadingScreen(false);
      });
  }, []);

  const handleInput = (e) => {
    const { value, name } = e.target;
    setLessonDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const formCheck = () => {
    if (lessonDetails.lessonName === "") {
      setCheckErrorInForm((prev) => ({ ...prev, lessonName: "error" }));
      return "lessonName";
    }
    if (lessonDetails.lessonDescription === "") {
      setCheckErrorInForm((prev) => ({ ...prev, lessonDescription: "error" }));
      return "lessonDescription";
    }
    if (lessonDetails.questionSetInstruction === "") {
      setCheckErrorInForm((prev) => ({
        ...prev,
        questionSetInstruction: "error",
      }));
      return "questionSetInstruction";
    }

    return "allIsWell";
    // not checking for files
  };

  const addLesson = async () => {
    let nsn = lessonDetails.lessonName.replaceAll(" ", "");

    let dat = /[^A-Za-z0-9]/.test(nsn);

    if (!dat) {
    } else {
      alert("you can not use any special characters");
      return;
    }
    let check = formCheck();
    if (check != "allIsWell") {
      setCheckFailed(true);
      return;
    }
    setCheckFailed(false);
    setCheckErrorInForm({
      lessonName: "",
      lessonDescription: "",
      questionSetInstruction: "",
    });
    let d = 0;
    if (schedule == "enrolment") {
      d = lessonDetails.daysAfter;
    } else if (schedule == "specific") {
      d = lessonDetails.startDate;
    }
    const newList = list.concat({
      id: lessonId,
      name: lessonDetails.lessonName,
      description: lessonDetails.lessonDescription,
      questionSetInstruction: lessonDetails.questionSetInstruction,
      courseAssociated: isCourseIdSet,
    });

    const createRes = createQuestionSet({
      questionSetId: lessonId,
      created:
        lessonDetails.created == ""
          ? new Date().toString()
          : lessonDetails.created,
      courseAssociated: isCourseIdSet,
      questionSetDescription: lessonDetails.lessonDescription,
      questionSetInstruction: lessonDetails.questionSetInstruction,
      questionSetName: lessonDetails.lessonName,
    });

    setList(newList);
    saveOrder(newList);
    setLessonDetails(initialInfo);
    toggle();
    alert("Lesson Added");
  };

  const updateLesson = async () => {
    let nsn = lessonDetails.lessonName.replaceAll(" ", "");

    let dat = /[^A-Za-z0-9]/.test(nsn);

    if (!dat) {
    } else {
      alert("you can not use any special characters");
      return;
    }
    let check = formCheck();
    if (check != "allIsWell") {
      setCheckFailed(true);
      return;
    }
    setCheckFailed(false);

    let d = 0;
    if (schedule == "enrolment") {
      d = lessonDetails.daysAfter;
    } else if (schedule == "specific") {
      d = lessonDetails.startDate;
    }

    createQuestionSet({
      questionSetId: lessonId,
      created:
        lessonDetails.created == ""
          ? new Date().toString()
          : lessonDetails.created,
      courseAssociated: isCourseIdSet,
      questionSetDescription: lessonDetails.lessonDescription,
      questionSetInstruction: lessonDetails.questionSetInstruction,
      questionSetName: lessonDetails.lessonName,
    });

    const newList = Array.from(list);
    for (let i = 0; i < newList.length; i++) {
      if (lessonId === newList[i].id) {
        newList[i] = {
          id: lessonId,
          name: lessonDetails.lessonName,
          description: lessonDetails.lessonDescription,
          questionSetInstruction: lessonDetails.questionSetInstruction,

          courseAssociated: isCourseIdSet,
        };
      }
    }
    setList(newList);
    saveOrder(newList);
    setLessonDetails(initialInfo);
    toggle();
  };

  const toggle = () => {
    // resetForm
    setLessonDetails({ ...initialInfo });
    setLessonId("");
    setFormOpen((prev) => !prev);
  };

  function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }
    addCourseQuestionSetOrder({
      courseId: isCourseIdSet,
      questionSetId: orderList,
      edit: true,
    });
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    saveOrder(items);
  }

  function edit(lesson) {
    toggle();
    let lessonInfo = {
      lessonName: lesson.name,
      lessonDescription: lesson.description,
      questionSetInstruction: lesson.questionSetInstruction,
      created: "",
    };

    setLessonId(lesson.id);

    setLessonDetails(lessonInfo);

    setEditable(true);
  }
  async function del(lesson) {
    // implement delete API done
    setopenLessonPopUp(true);
    setDeleteLessonId(lesson);
  }

  const delfunction = async (lesson) => {
    deleteQuestionSet({ questionSetId: lesson.id });
    alert("Deleted " + lesson.name);
    const item = [];
    for (let i = 0; i < list.length; i++) {
      if (lesson.id !== list[i].id) {
        item.push(list[i]);
      }
    }
    setList(item);
    saveOrder(item);
  };
  const toggleForm = () => {
    setFormOpen((prev) => !prev);
  };
  return (
    <>
      {/* <section className="section">
        <Navbar page={t("Entrance Course")} />

        <LoadingScreen
          open={loadingScreen}
          onClose={() => setLoadingScreen(false)}
        />
        <div className="createcourse-main">
          <Sidebar page={"training"} hidepage={true} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Course
              editCourseInfo={editCourseInfo}
              setIsCourseIdSet={setIsCourseIdSet}
              checkEditFlag={checkEditFlag}
            />
            <DeleteLesson
              open={openLessonPopUp}
              onClose={() => setopenLessonPopUp(false)}
              delfun={(topic) => {
                delfunction(topic);
              }}
              topicId={deleteLessonId}
            />
            {isCourseIdSet && (
              <div className="createcourse-main-bottom">
                <button
                  className="addLesson-btn"
                  onClick={() => {
                    setEditable(false);
                    toggle();
                  }}
                >
                  Add QuestionSet
                </button>

                {formOpen && (
                  <div id="lessonForm" className="lessonForm-container">
                    <h3 className="lessonForm-heading">Question Set</h3>
                    <div className="lessonForm-row">
                      <p className="lessonForm-inputLabel">
                        Question Set Name *
                      </p>
                      <input
                        type="text"
                        className="lessonForm-inputBar"
                        name="lessonName"
                        id="lessonName"
                        style={{
                          border: `${
                            checkErrorInForm.lessonName == "error"
                              ? "2px solid red"
                              : "1px solid #d7d7d7"
                          }`,
                        }}
                        value={lessonDetails.lessonName}
                        onChange={(event) => {
                          handleInput(event);
                          setCheckErrorInForm((prev) => ({
                            ...prev,
                            lessonName: "",
                          }));
                          if (!editable) {
                            var today = new Date();
                            let value = event.target.value.replaceAll(" ", "");
                            let time = new Date().getTime();
                            setLessonId(value + "_" + time);
                          }
                        }}
                      />
                      {checkErrorInForm.lessonName == "error" && (
                        <span
                          style={{
                            marginLeft: "20px",
                            color: "red",
                            fontSize: "15px",
                          }}
                        >
                          Enter Question Set Name
                        </span>
                      )}
                    </div>
                    <div className="lessonForm-row">
                      <p className="lessonForm-inputLabel">
                        Short Description *
                      </p>
                      <textarea
                        type="text"
                        className="lessonForm-inputBar"
                        id="lessonDescription"
                        style={{
                          border: `${
                            checkErrorInForm.lessonDescription == "error"
                              ? "2px solid red"
                              : "1px solid #d7d7d7"
                          }`,
                        }}
                        name="lessonDescription"
                        value={lessonDetails.lessonDescription}
                        onChange={(e) => {
                          handleInput(e);
                          setCheckErrorInForm((prev) => ({
                            ...prev,
                            lessonDescription: "",
                          }));
                        }}
                      />
                      {checkErrorInForm.lessonDescription == "error" && (
                        <span
                          style={{
                            marginLeft: "20px",
                            color: "red",
                            fontSize: "15px",
                          }}
                        >
                          Enter Question Set Description
                        </span>
                      )}
                    </div>

                    <div className="lessonForm-row">
                      <p className="lessonForm-inputLabel">
                        Question Set Instruction*
                      </p>
                      <textarea
                        type="text"
                        className="lessonForm-inputBar"
                        id="QuestionSetDescription"
                        style={{
                          border: `${
                            checkErrorInForm.questionSetInstruction == "error"
                              ? "2px solid red"
                              : "1px solid #d7d7d7"
                          }`,
                        }}
                        name="questionSetInstruction"
                        value={lessonDetails.questionSetInstruction}
                        onChange={(e) => {
                          handleInput(e);
                          setCheckErrorInForm((prev) => ({
                            ...prev,
                            questionSetInstruction: "",
                          }));
                        }}
                      />
                      {checkErrorInForm.questionSetInstruction == "error" && (
                        <span
                          style={{
                            marginLeft: "20px",
                            color: "red",
                            fontSize: "15px",
                          }}
                        >
                          Enter Question Set Instruction
                        </span>
                      )}
                    </div>

                    <div className="lessonForm-row">
                      <p className="lessonForm-inputLabel">Question Set ID</p>
                      <input
                        disabled
                        type="text"
                        className="lessonForm-inputBar"
                        id="lessonId"
                        value={lessonId}
                      />
                    </div>

                    {checkFailed && (
                      <p style={{ color: "red" }}>Fill all the fields</p>
                    )}
                    <button
                      className="lessonForm-save-btn"
                      onClick={() => (editable ? updateLesson() : addLesson())}
                    >
                      Save Question Set
                    </button>

                    <button
                      className="addItem-btn"
                      style={{ marginLeft: "10px" }}
                      onClick={toggleForm}
                    >
                      Cancel
                    </button>
                    <br />
                  </div>
                )}
                <div className="lessonList-container">
                  <div className="lessonForm-heading">Question Set</div>
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="list">
                      {(provided) => (
                        <ul
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            display: "block",
                            overflow: "hidden",
                            width: "100%",
                            listStyleType: "none",
                            margin: "0px",
                            padding: "0px",
                          }}
                          id="lessonList"
                        >
                          {list.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided) => (
                                <li
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  key={item.id}
                                  className="lessonList-draggable"
                                >
                                  <Accordion>
                                    <AccordionSummary
                                      {...provided.dragHandleProps}
                                      style={{
                                        backgroundColor: "#FA9600",
                                        color: "white",
                                        borderRadius: "5px",
                                      }}
                                      expandIcon={<ExpandMoreSvg />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <SvgVector />
                                        <div className="lessonList-accordion-heading">
                                          {item.name}
                                        </div>
                                      </div>
                                    </AccordionSummary>
                                    <AccordionDetails
                                      style={{ borderRadius: "2px" }}
                                      isDragDisabled={true}
                                    >
                                      <div className="lessonList-draggable-summary">
                                        <p>{item.id}</p>
                                        <div
                                          style={{
                                            marginLeft: "auto",
                                            marginRight: "10px",
                                          }}
                                          onClick={() => del(item)}
                                        >
                                          <DeleteSVg size={28} />
                                        </div>
                                        <div onClick={() => edit(item)}>
                                          <EditSvg size={28} />
                                        </div>
                                      </div>

                                      <p>{item.description}</p>
                                      <p>Course Associated - {isCourseIdSet}</p>
                                      {/* <CreateLesson
                                        quizData={item.quizData}
                                        itemsData={item.itemsData}
                                        topicsData={item.topicsData}
                                        courseId={isCourseIdSet}
                                        lessonId={item.id}
                                      /> /}

                                      <CreateSubjectiveQuiz
                                        editQuizArr={editQuizArr}
                                        courseId={isCourseIdSet}
                                        lessonId={item.id}
                                        name={"add SubJective quiz"}
                                      />

                                      <CreateQuiz
                                        editQuizArr={editQuizArr}
                                        courseId={isCourseIdSet}
                                        lessonId={item.id}
                                        name={"add Objective quiz"}
                                      />
                                    </AccordionDetails>
                                  </Accordion>
                                </li>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
                <CreateMilstone
                  editQuizArr={editQuizArr}
                  courseId={isCourseIdSet}
                />
              </div>
            )}
          </div>
        </div>
      </section>

      <div style={{ height: "100px", margin: "auto", width: "100px" }}>
        <button
          style={{ backgroundColor: "rgb(36,24,135)" }}
          className="addLesson-btn"
          onClick={() => navigate(-1)}
        >
          Back
        </button>{" "}
      </div> */}
      <section className="section">
        <Navbar page={t("Entrance Course")} />

        <LoadingScreen
          open={loadingScreen}
          onClose={() => setLoadingScreen(false)}
        />
        <div className="createcourse-main">
          <Sidebar page={"training"} hidepage={true} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Course
              editCourseInfo={editCourseInfo}
              setIsCourseIdSet={setIsCourseIdSet}
              checkEditFlag={checkEditFlag}
            />
            <DeleteLesson
              open={openLessonPopUp}
              onClose={() => setopenLessonPopUp(false)}
              delfun={(topic) => {
                delfunction(topic);
              }}
              topicId={deleteLessonId}
            />
            {isCourseIdSet && (
              <div className="createcourse-main-bottom">
                <button
                  className="primary-btn"
                  onClick={() => {
                    setEditable(false);
                    toggle();
                  }}
                >
                  Add QuestionSet
                </button>

                {formOpen && (
                  <div id="lessonForm" className="lessonForm-container">
                    <h3 className="lessonForm-heading">Question Set</h3>
                    <div className="lessonForm-row">
                      <p
                        className="lessonForm-inputLabel"
                        style={{
                          fontWeight: 500,
                          color: "#171B1E",
                          width: "20%",
                        }}
                      >
                        Question Set Name *
                      </p>
                      <input
                        type="text"
                        className="lessonForm-inputBar"
                        name="lessonName"
                        id="lessonName"
                        style={{
                          border: "1px solid #d7d7d7",
                          width: "30%",
                          borderRadius: "6px",
                          outline: "none",
                          padding: "15px",
                        }}
                        value={lessonDetails.lessonName}
                        onChange={(event) => {
                          handleInput(event);
                          setCheckErrorInForm((prev) => ({
                            ...prev,
                            lessonName: "",
                          }));
                          if (!editable) {
                            var today = new Date();
                            let value = event.target.value.replaceAll(" ", "");
                            let time = new Date().getTime();
                            setLessonId(value + "_" + time);
                          }
                        }}
                      />
                      {checkErrorInForm.lessonName == "error" && (
                        <span
                          style={{
                            marginLeft: "20px",
                            color: "red",
                            fontSize: "15px",
                          }}
                        >
                          Enter Question Set Name
                        </span>
                      )}
                    </div>
                    <div className="lessonForm-row">
                      <p
                        className="lessonForm-inputLabel"
                        style={{
                          fontWeight: 500,
                          color: "#171B1E",
                          width: "20%",
                        }}
                      >
                        Short Description *
                      </p>
                      <textarea
                        type="text"
                        className="lessonForm-inputBar"
                        id="lessonDescription"
                        style={{
                          border: "1px solid #d7d7d7",
                          width: "30%",
                          minHeight: "100px",
                          borderRadius: "6px",
                          outline: "none",
                          padding: "15px",
                        }}
                        name="lessonDescription"
                        value={lessonDetails.lessonDescription}
                        onChange={(e) => {
                          handleInput(e);
                          setCheckErrorInForm((prev) => ({
                            ...prev,
                            lessonDescription: "",
                          }));
                        }}
                      />
                      {checkErrorInForm.lessonDescription == "error" && (
                        <span
                          style={{
                            marginLeft: "20px",
                            color: "red",
                            fontSize: "15px",
                          }}
                        >
                          Enter Question Set Description
                        </span>
                      )}
                    </div>

                    <div className="lessonForm-row">
                      <p
                        className="lessonForm-inputLabel"
                        style={{
                          fontWeight: 500,
                          color: "#171B1E",
                          width: "20%",
                        }}
                      >
                        Question Set Instruction*
                      </p>
                      <textarea
                        type="text"
                        className="lessonForm-inputBar"
                        id="QuestionSetDescription"
                        style={{
                          border: "1px solid #d7d7d7",
                          width: "30%",
                          minHeight: "100px",
                          borderRadius: "6px",
                          outline: "none",
                          padding: "15px",
                        }}
                        name="questionSetInstruction"
                        value={lessonDetails.questionSetInstruction}
                        onChange={(e) => {
                          handleInput(e);
                          setCheckErrorInForm((prev) => ({
                            ...prev,
                            questionSetInstruction: "",
                          }));
                        }}
                      />
                      {checkErrorInForm.questionSetInstruction == "error" && (
                        <span
                          style={{
                            marginLeft: "20px",
                            color: "red",
                            fontSize: "15px",
                          }}
                        >
                          Enter Question Set Instruction
                        </span>
                      )}
                    </div>

                    <div className="lessonForm-row">
                      <p
                        className="lessonForm-inputLabel"
                        style={{
                          fontWeight: 500,
                          color: "#171B1E",
                          width: "20%",
                        }}
                      >
                        Question Set ID
                      </p>
                      <input
                        disabled
                        type="text"
                        className="lessonForm-inputBar"
                        id="lessonId"
                        value={lessonId}
                        style={{
                          border: "1px solid #d7d7d7",
                          width: "30%",
                          borderRadius: "6px",
                          outline: "none",
                          padding: "15px",
                        }}
                      />
                    </div>

                    {checkFailed && (
                      <p style={{ color: "red" }}>Fill all the fields</p>
                    )}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "30px",
                        marginTop: "30px",
                      }}
                    >
                      <button
                        className="primary-btn"
                        onClick={() =>
                          editable ? updateLesson() : addLesson()
                        }
                      >
                        Save Question Set
                      </button>

                      <button
                        className="secondary-btn"
                        style={{ marginLeft: "10px" }}
                        onClick={toggleForm}
                      >
                        Cancel
                      </button>
                    </div>
                    <br />
                  </div>
                )}
                <div className="lessonList-container">
                  <div className="lessonForm-heading">Question Set</div>
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="list">
                      {(provided) => (
                        <ul
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            display: "block",
                            overflow: "hidden",
                            width: "100%",
                            listStyleType: "none",
                            margin: "0px",
                            padding: "0px",
                          }}
                          id="lessonList"
                        >
                          {list.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided) => (
                                <li
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  key={item.id}
                                  className="lessonList-draggable"
                                >
                                  <Accordion>
                                    <AccordionSummary
                                      {...provided.dragHandleProps}
                                      style={{
                                        backgroundColor: "#F17850",
                                        color: "white",
                                        borderRadius: "5px",
                                      }}
                                      expandIcon={<ExpandMoreSvg />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <SvgVector />
                                        <div className="lessonList-accordion-heading">
                                          {item.name}
                                        </div>
                                      </div>
                                    </AccordionSummary>
                                    <AccordionDetails
                                      style={{ borderRadius: "2px" }}
                                      isDragDisabled={true}
                                    >
                                      <div className="lessonList-draggable-summary">
                                        <p>{item.id}</p>
                                        <div
                                          style={{
                                            marginLeft: "auto",
                                            marginRight: "10px",
                                          }}
                                          onClick={() => del(item)}
                                        >
                                          <DeleteSVg size={28} />
                                        </div>
                                        <div onClick={() => edit(item)}>
                                          <EditSvg size={28} />
                                        </div>
                                      </div>

                                      <p>{item.description}</p>
                                      <p>Course Associated - {isCourseIdSet}</p>
                                      {/* <CreateLesson
                                        quizData={item.quizData}
                                        itemsData={item.itemsData}
                                        topicsData={item.topicsData}
                                        courseId={isCourseIdSet}
                                        lessonId={item.id}
                                      /> */}

                                      <CreateSubjectiveQuiz
                                        editQuizArr={editQuizArr}
                                        courseId={isCourseIdSet}
                                        lessonId={item.id}
                                        name={"add SubJective quiz"}
                                      />

                                      <CreateQuiz
                                        editQuizArr={editQuizArr}
                                        courseId={isCourseIdSet}
                                        lessonId={item.id}
                                        name={"add Objective quiz"}
                                      />
                                    </AccordionDetails>
                                  </Accordion>
                                </li>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
                <CreateMilstone
                  editQuizArr={editQuizArr}
                  courseId={isCourseIdSet}
                />
              </div>
            )}
          </div>
        </div>
      </section>

      <div style={{ height: "100px", margin: "auto", width: "100px" }}>
        <button
          className="secondary-btn"
          style={{ marginTop: "20px" }}
          onClick={() => navigate(-1)}
        >
          Back
        </button>{" "}
      </div>
    </>
  );
};

export default CreateCourse;
