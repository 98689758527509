import React, { useState, useRef } from "react";
import CreateLesson from "./CreateLesson";
import CreateQuiz from "./CreateQuiz";
import Item from "./Item";

import "./CreateCourse.css";
import BasicCourse from "./Course";
import { db, functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Navbar from "../../Component/NavbarTop";
import { t } from "i18next";
import Sidebar from "../../Component/Sidebar";
import { getCookie } from "../../../Cookies";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import DeleteLesson from "./lessonDeletePopUp";
import "./styles/item.css";
import { DeleteSVg, SvgVector, EditSvg, ExpandMoreSvg } from "./icons";
import LoadingScreen from "./lodingScree";
import { async } from "@firebase/util";

const CreateBasicCourse = () => {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  const location = useLocation();
  const editCourseId = location.state?.courseId;

  //console.log(editCourseId);
  //===========API for Course EDIT=========

  const getCourseInfo = httpsCallable(functions, "getCourseInfo");
  const getLessonInfo = httpsCallable(functions, "getLessonInfo");
  const getQuizInfo = httpsCallable(functions, "getQuizInfo");
  const getLessonsForBasicCourse = httpsCallable(
    functions,
    "getLessonsForBasicCourse"
  );
  const getQuestionSetInfo = httpsCallable(functions, "getQuestionSetInfo");
  const [editQuizArr, setEditQuizArr] = useState([]);
  const [editCourseInfo, setEditCourseInfo] = useState(null);
  const [checkEditFlag, setCheckEditFlag] = useState(false);
  const [deleteLessonId, setDeleteLessonId] = useState("");
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);
  const [selectedCateGory, setSelectedCateGory] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(false);

  const [base64Img, setBase64Img] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [uploadedVideoUrl, setUploadesVideoUrl] = useState("");
  const [videoFile, setVideoFile] = useState();
  //=======================================
  const createLesson = httpsCallable(functions, "createLesson");
  const addLessonExtraInfo = httpsCallable(functions, "addLessonExtraInfo");
  const addBasicCourseLessonsOrder = httpsCallable(
    functions,
    "addBasicCourseLessonsOrder"
  );
  const deletebasicLessons = httpsCallable(functions, "deletebasicLessons");
  const getBasicCourseFullData = httpsCallable(
    functions,
    "getBasicCourseFullData"
  );
  const getAllLessonsForABasicCourse = httpsCallable(
    functions,
    "getAllLessonsForABasicCourse"
  );
  const fetchBasicLessonCategory = httpsCallable(
    functions,
    "fetchBasicLessonCategory"
  );
  // deleteLesson
  useEffect(() => {
    if (
      getCookie("UID") == null ||
      (getCookie("ROLE") != "admin" && getCookie("ROLE") != "instructor")
    ) {
      goToHome();
      return <></>;
    }
  });
  const imageRef = useRef(null);

  const onImageClick = () => {
    imageRef.current.click();
  };

  const [schedule, setSchedule] = useState("immediate");
  const [lessonId, setLessonId] = useState("");
  const [editable, setEditable] = useState(false);
  const [list, setList] = useState([]);
  const [isCourseIdSet, setIsCourseIdSet] = useState("");
  const [checkFailed, setCheckFailed] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [categoryArr, setcategoryArr] = useState([]);

  const [category, setcategory] = useState("");
  const initialInfo = {
    lessonName: "",
    lessonDescription: "",
    created: "",
    isSample: "no",
    hasQuiz: "yes",
    daysAfter: "",
    startDate: "",
    quiz: [],
    topic: [],
    base64Image: "",
    questionSetInstruction: "",
  };
  const [lessonDetails, setLessonDetails] = useState({ ...initialInfo });
  const [checkErrorInForm, setCheckErrorInForm] = useState({
    lessonName: "",
    lessonDescription: "",
    questionSetInstruction: "",
    category: "",
    courseCategory: "",
    imageUrl: "",
    videoLink: "",
  });

  const handleCourseImage = (e) => {
    let reader = new FileReader();
    // console.log(e);

    let filenameType = e.target.value.split(".");

    //console.log(filenameType[filenameType.length - 1]);

    let filetypedata = filenameType[filenameType.length - 1].toLowerCase();
    if (
      filetypedata == "png" ||
      filetypedata == "jpeg" ||
      filetypedata == "jpg"
    ) {
    } else {
      alert("only jpeg  , gif  ,png  file accepted");
      e.target.value = null;

      return;
    }

    let rfilesize = Number(e.target.files[0].size) / 1024;

    rfilesize = rfilesize / 1024;
    //console.log(rfilesize);
    if (Number(rfilesize) > 3) {
      alert("size must be less than 3 mb");

      e.target.value = null;
      return;
    } else {
      let url = URL.createObjectURL(e.target.files[0]);

      const img = new Image();
      img.onload = function () {
        //alert(this.width + 'x' + this.height);
        if (
          Number(this.width) > 105 ||
          (Number(this.width) < 100 && Number(this.height) > 103) ||
          Number(this.height) < 98
        ) {
          alert("Aspect Ratio must be 102px:100px. Update and try again.");
          return false;
        } else {
          setImageUrl(url);
          // setImgFileName(e.target.files[0].name);
          reader.readAsDataURL(e.target.files[0]);
          reader.onload = function () {
            // console.log(reader.result);
            setBase64Img(reader.result);
          };
        }
      };
      img.src = url;

      // console.log(rfilesize);
      // let url = URL.createObjectURL(e.target.files[0]);
      // setImageUrl(url);

      // reader.readAsDataURL(e.target.files[0]);
      // reader.onload = function () {
      //   console.log(reader.result);
      //   setBase64Img(reader.result);
      // };
    }
  };

  useEffect(() => {
    fetchBasicLessonCategory().then((res) => {
      //console.log(res);
      setcategoryArr(res.data);
    });
    if (!editCourseId) return;
    setLoadingScreen(true);
    setCheckEditFlag(true);

    getBasicCourseFullData({ courseId: editCourseId }).then((res) => {
      setCheckEditFlag(false);

      setEditCourseInfo(res.data);

      //console.log(res.data);

      (async () => {
        let associate = res.data?.associateLesson
          ? res.data?.associateLesson
          : [];
        for (let j = 0; j < associate?.length; j++) {
          await getLessonsForBasicCourse({ lessonId: associate[j] }).then(
            (ress) => {
              setLoadingScreen(false);

              // console.log(ress);
              setList((prev) =>
                prev.concat({
                  id: ress.data.data?.lessonId,
                  name: ress.data.data?.lessonName,
                  description: ress.data.data?.lessonDescription,
                  category: ress.data.data?.category,
                  courseAssociated: editCourseId,
                  ImageUrl: ress.data.data?.ImageUrl,
                  created: ress.data.data.created,
                })
              );
            }
          );
        }
      })();

      // getAllLessonsForABasicCourse({ courseId: editCourseId }).then(
      //   (ress) => {}
      // );
    });
  }, []);

  const handleInput = (e) => {
    const { value, name } = e.target;
    setLessonDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const formCheck = () => {
    if (lessonDetails.lessonName === "") {
      setCheckErrorInForm((prev) => ({ ...prev, lessonName: "error" }));
      return "lessonName";
    }
    if (lessonDetails.lessonDescription === "") {
      setCheckErrorInForm((prev) => ({ ...prev, lessonDescription: "error" }));
      return "lessonDescription";
    }

    // if (imageUrl == "") {
    //   setCheckErrorInForm((prev) => ({ ...prev, imageUrl: "error" }));
    //   return "imageUrl";
    // }

    if (selectedCateGory.length == 0) {
      let arr = [];

      for (var option of document.getElementById("courseShare").options) {
        if (option.selected) {
          if (option.value !== "") {
            arr.push(option.value);
            setSelectedCateGory(arr);
            //console.log(arr);
          }
        }
      }
      if (arr.length == 0) {
        setCheckErrorInForm((prev) => ({ ...prev, courseCategory: "error" }));
        return "selectedCategory";
      }
    }

    return "allIsWell";
    // not checking for files
  };

  function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }
    addBasicCourseLessonsOrder({
      courseId: isCourseIdSet,
      associateLesson: orderList,
      edit: true,
    });
  }
  const addLesson = async () => {
    let arr = [];

    for (var option of document.getElementById("courseShare").options) {
      if (option.selected) {
        if (option.value !== "") {
          arr.push(option.value);
          setSelectedCateGory(arr);
          //console.log(arr);
        }
      }
    }

    let check = formCheck();
    if (check != "allIsWell") {
      setCheckFailed(true);
      return;
    }

    let nsn = lessonDetails.lessonName.replaceAll(" ", "");

    for (let l = 0; l < nsn.length; l++) {
      if (nsn[l] == "/") {
        alert("you can not use any special characters (/)");
        return;
      }
    }

    setCheckFailed(false);

    // let shared = [];
    // for (var option of document.getElementById("courseShare").options) {
    //   if (option.selected) {
    //     console.log(option.value)
    //     shared.push(option.value);
    //   }
    // }

    setCheckErrorInForm({
      lessonName: "",
      lessonDescription: "",
      questionSetInstruction: "",
    });
    let d = 0;
    if (schedule == "enrolment") {
      d = lessonDetails.daysAfter;
    } else if (schedule == "specific") {
      d = lessonDetails.startDate;
    }
    const newList = list.concat({
      id: lessonId,
      name: lessonDetails.lessonName,
      description: lessonDetails.lessonDescription,
      questionSetInstruction: lessonDetails.questionSetInstruction,
      courseAssociated: isCourseIdSet,
      category: arr,
      ImageUrl: imageUrl,
    });

    const createRes = createLesson({
      lessonId: lessonId,
      created: lessonDetails.created == "" ? "" : lessonDetails.created,
      courseAssociated: isCourseIdSet,
      lessonDescription: lessonDetails.lessonDescription,

      lessonName: lessonDetails.lessonName,
      category: selectedCateGory,
      imageUrl: base64Img ? base64Img : imageUrl,
    });

    setList(newList);
    saveOrder(newList);
    setLessonDetails(initialInfo);
    toggle();
    alert("Lesson Added");
  };

  const updateLesson = async () => {
    let arr = [];

    for (var option of document.getElementById("courseShare").options) {
      if (option.selected) {
        if (option.value !== "") {
          arr.push(option.value);
          setSelectedCateGory(arr);
          // console.log(arr);
        }
      }
    }

    let check = formCheck();
    if (check != "allIsWell") {
      setCheckFailed(true);
      return;
    }
    setCheckFailed(false);

    const createRes = createLesson({
      lessonId: lessonId,
      created: lessonDetails.created == "" ? "" : lessonDetails.created,
      courseAssociated: isCourseIdSet,
      lessonDescription: lessonDetails.lessonDescription,

      lessonName: lessonDetails.lessonName,
      category: arr,
      imageUrl: base64Img ? base64Img : imageUrl,
    });

    const newList = Array.from(list);
    for (let i = 0; i < newList.length; i++) {
      if (lessonId === newList[i].id) {
        newList[i] = {
          id: lessonId,
          name: lessonDetails.lessonName,
          description: lessonDetails.lessonDescription,
          questionSetInstruction: lessonDetails.questionSetInstruction,
          category: arr,
          courseAssociated: isCourseIdSet,
          ImageUrl: imageUrl,
        };
      }
    }
    setList(newList);
    saveOrder(newList);
    setLessonDetails(initialInfo);
    toggle();
  };

  const toggle = () => {
    // resetForm
    setLessonDetails({ ...initialInfo });
    setLessonId("");
    setFormOpen((prev) => !prev);
  };

  function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }
    addBasicCourseLessonsOrder({
      courseId: isCourseIdSet,
      associateLesson: orderList,
      edit: true,
    });
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    saveOrder(items);
  }

  function edit(lesson) {
    toggle();
    let lessonInfo = {
      lessonName: lesson.name,
      lessonDescription: lesson.description,
      questionSetInstruction: lesson.questionSetInstruction,
      created: lesson.created,
    };
    //console.log(lesson);
    setLessonId(lesson.id);
    setSelectedCateGory(lesson.category);
    setLessonDetails(lessonInfo);
    setImageUrl(lesson.ImageUrl);
    setEditable(true);
  }
  async function del(lesson) {
    // implement delete API done
    setopenLessonPopUp(true);
    setDeleteLessonId(lesson);
  }

  const delfunction = async (lesson) => {
    deletebasicLessons({ lessonId: lesson.id });
    alert("Deleted " + lesson.name);
    const item = [];
    for (let i = 0; i < list.length; i++) {
      if (lesson.id !== list[i].id) {
        item.push(list[i]);
      }
    }
    setList(item);
    saveOrder(item);
  };

  const toggleForm = () => {
    setFormOpen((prev) => !prev);
  };
  return (
    <>
      <section className="section">
        <Navbar page={t("Basic course")} />
        <div className="createcourse-main">
          <LoadingScreen
            open={loadingScreen}
            onClose={() => setLoadingScreen(false)}
          />
          <Sidebar page={"training"} hidepage={true} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <BasicCourse
              editCourseInfo={editCourseInfo}
              setIsCourseIdSet={setIsCourseIdSet}
              checkEditFlag={checkEditFlag}
            />
            <DeleteLesson
              open={openLessonPopUp}
              onClose={() => setopenLessonPopUp(false)}
              delfun={(topic) => {
                delfunction(topic);
              }}
              topicId={deleteLessonId}
            />
            {isCourseIdSet && (
              <div
                className="createcourse-main-bottom"
                style={{ marginTop: "30px" }}
              >
                <button
                  className="primary-btn"
                  onClick={() => {
                    setEditable(false);
                    setSelectedCateGory([]);
                    toggle();
                  }}
                >
                  Add Lesson
                </button>

                {formOpen && (
                  <div id="lessonForm" className="lessonForm-container">
                    <h3 className="lessonForm-heading">Lesson</h3>
                    <div className="lessonForm-row">
                      <p
                        className="lessonForm-inputLabel"
                        style={{
                          fontWeight: 500,
                          color: "#171B1E",
                          width: "20%",
                        }}
                      >
                        Lesson Name *
                      </p>
                      <input
                        type="text"
                        className="lessonForm-inputBar"
                        name="lessonName"
                        id="lessonName"
                        style={{
                          border: `${
                            checkErrorInForm.lessonName == "error"
                              ? "2px solid red"
                              : "1px solid #d7d7d7"
                          }`,

                          width: "30%",
                          borderRadius: "6px",
                          outline: "1px solid #171B1E",
                          padding: "15px",
                        }}
                        value={lessonDetails.lessonName}
                        onChange={(event) => {
                          handleInput(event);
                          setCheckErrorInForm((prev) => ({
                            ...prev,
                            lessonName: "",
                          }));
                          if (!editable) {
                            var today = new Date();
                            let value = event.target.value.replaceAll(" ", "");
                            let time = new Date().getTime();
                            setLessonId(value + "_" + time);
                          }
                        }}
                      />
                      {checkErrorInForm.lessonName == "error" && (
                        <span
                          style={{
                            marginLeft: "20px",
                            color: "red",
                            fontSize: "15px",
                          }}
                        >
                          Enter Lesson Name
                        </span>
                      )}
                    </div>
                    <div className="lessonForm-row">
                      <p
                        className="lessonForm-inputLabel"
                        style={{
                          fontWeight: 500,
                          color: "#171B1E",
                          width: "20%",
                        }}
                      >
                        Short Description *
                      </p>
                      <textarea
                        type="text"
                        className="lessonForm-inputBar"
                        id="lessonDescription"
                        style={{
                          border: `${
                            checkErrorInForm.lessonDescription == "error"
                              ? "2px solid red"
                              : "1px solid #d7d7d7"
                          }`,
                          width: "30%",
                          minHeight: "100px",
                          borderRadius: "6px",
                          outline: "1px solid #171B1E",
                          padding: "15px",
                        }}
                        name="lessonDescription"
                        value={lessonDetails.lessonDescription}
                        onChange={(e) => {
                          handleInput(e);
                          setCheckErrorInForm((prev) => ({
                            ...prev,
                            lessonDescription: "",
                          }));
                        }}
                      />
                      {checkErrorInForm.lessonDescription == "error" && (
                        <span
                          style={{
                            marginLeft: "20px",
                            color: "red",
                            fontSize: "15px",
                          }}
                        >
                          Enter Question Set Description
                        </span>
                      )}
                    </div>

                    {/* <div className="course-builder-info">
                      <p className="course-builder-inputLable">
                        Lesson category *
                      </p>
                      <select
                        className="course-builder-inputSelect"
                        value={category}
                        onChange={(e) => {
                          setcategory(e.target.value);
                        }}
                        style={{
                          border: `${
                            checkErrorInForm.category == "error"
                              ? "2px solid red"
                              : "1px solid #d7d7d7"
                          }`,
                        }}
                      >
                        <option value="" selected disabled hidden>
                          Select an Option
                        </option>
                        {categoryArr?.map((item, id) => (
                          <option key={id} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      {checkErrorInForm.selectedTemplate === "error" && (
                        <span
                          style={{
                            marginLeft: "20px",
                            color: "red",
                            fontSize: "15px",
                          }}
                        >
                          {" "}
                          select Template
                        </span>
                      )}
                    </div> */}

                    <div className="course-builder-info">
                      <p
                        className="course-builder-inputLable"
                        style={{
                          fontWeight: 500,
                          color: "#171B1E",
                          width: "20%",
                        }}
                      >
                        lesson Category *
                      </p>
                      <select
                        style={{
                          padding: "15px",
                          width: "30%",
                          outline: "none",
                        }}
                        id="courseShare"
                        // value={categoryName}
                        onClick={(e) => {
                          setCheckErrorInForm((prev) => ({
                            ...prev,
                            courseCategory: "",
                          }));
                        }}
                        className="course-builder-multipleSelect custom-scrollbar"
                        multiple
                      >
                        <option value="" selected disabled hidden>
                          Select an Option
                        </option>
                        {categoryArr?.map((item, id) => {
                          const fun = (i) => {
                            for (let j = 0; j < selectedCateGory?.length; j++) {
                              if (
                                selectedCateGory[j] == i.basicLessonCategoryId
                              ) {
                                return true;
                              }
                            }
                            return false;
                          };
                          if (fun(item)) {
                            //console.log(item);
                            return (
                              <option
                                value={item.basicLessonCategoryId}
                                selected={true}
                                key={id}
                              >
                                {item.name}
                              </option>
                            );
                          } else {
                            return (
                              <option
                                value={item.basicLessonCategoryId}
                                key={id}
                              >
                                {item.name}
                              </option>
                            );
                          }
                        })}
                      </select>

                      {checkErrorInForm.courseCategory === "error" && (
                        <span
                          style={{
                            marginLeft: "20px",
                            color: "red",
                            fontSize: "15px",
                          }}
                        >
                          {" "}
                          select category
                        </span>
                      )}
                    </div>

                    {/* <div className="course-builder-info">
                      <div style={{}}>
                        <p
                          className="addItem-inputLabel"
                          style={{
                            fontWeight: 500,
                            color: "#171B1E",
                          }}
                        >
                          {" "}
                          Upload Lesson Thumbnail*
                        </p>
                        <p style={{ fontSize: "15px", fontWeight: "200" }}>
                          (Aspect Ratio 102px:100px.){" "}
                        </p>
                      </div>

                      {imageUrl == "" ? (
                        <div className="image-box" onClick={onImageClick}>
                          <button className="image-box-btn">
                            <div className="image-box-icon">
                              <svg
                                width="25"
                                height="26"
                                viewBox="0 0 25 26"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.27998 25.2535C2.42931 25.2535 1.70135 24.9509 1.09609 24.3456C0.489792 23.7393 0.186646 23.0108 0.186646 22.1602V17.5202H3.27998V22.1602H21.84V17.5202H24.9333V22.1602C24.9333 23.0108 24.6307 23.7393 24.0254 24.3456C23.4191 24.9509 22.6906 25.2535 21.84 25.2535H3.27998ZM11.0133 19.0668V6.4615L6.99198 10.4828L4.82665 8.24017L12.56 0.506836L20.2933 8.24017L18.128 10.4828L14.1066 6.4615V19.0668H11.0133Z"
                                  fill="#D9D9D9"
                                />
                              </svg>
                            </div>
                          </button>
                          <p className="image-box-text">Upload Image</p>
                        </div>
                      ) : (
                        <img
                          style={{
                            width: "20vw",
                            height: "8em",
                            borderRadius: "10px",
                          }}
                          onClick={onImageClick}
                          src={imageUrl}
                        ></img>
                      )}
                      <input
                        ref={imageRef}
                        style={{ display: "none" }}
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        className="course-builder-inputImg"
                        id="courseImage"
                        onChange={(e) => {
                          handleCourseImage(e);
                          setCheckErrorInForm((prev) => ({
                            ...prev,
                            imageUrl: "",
                          }));
                        }}
                      />

                      {checkErrorInForm.imageUrl == "error" && (
                        <span
                          style={{
                            marginLeft: "20px",
                            color: "red",
                            fontSize: "15px",
                          }}
                        >
                          {" "}
                          upload Image
                        </span>
                      )}
                    </div> */}

                    {/* <div className="course-builder-info">
                      <div
                        className="course-builder-inputLable"
                        style={{ marginTop: "2vw" }}
                      ></div>
                      <select
                        id="courseShare"
                        className="course-builder-multipleSelect"
                        multiple
                      >
                        {categoryArr?.map((item, id) => (
                          <option key={id} value={item.courseCategoryId}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div> */}

                    <div className="lessonForm-row">
                      <p
                        className="lessonForm-inputLabel"
                        style={{
                          fontWeight: 500,
                          color: "#171B1E",
                          width: "20%",
                        }}
                      >
                        lesson ID
                      </p>
                      <input
                        disabled
                        type="text"
                        className="lessonForm-inputBar"
                        id="lessonId"
                        value={lessonId}
                        style={{
                          width: "30%",
                          borderRadius: "6px",
                          border: "1px solid #d7d7d7",
                          padding: "15px",
                        }}
                      />
                    </div>

                    {checkFailed && (
                      <p style={{ color: "red" }}>Fill all the fields</p>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "30px",
                        marginTop: "30px",
                      }}
                    >
                      <button
                        className="primary-btn"
                        onClick={() =>
                          editable ? updateLesson() : addLesson()
                        }
                      >
                        Save Lesson
                      </button>
                      <button
                        className="secondary-btn"
                        style={{ marginLeft: "10px" }}
                        onClick={toggleForm}
                      >
                        Cancel
                      </button>
                    </div>
                    <br />
                  </div>
                )}
                <div className="lessonList-container">
                  <div className="lessonForm-heading">Lesson</div>
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="list">
                      {(provided) => (
                        <ul
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            display: "block",
                            overflow: "hidden",
                            width: "100%",
                            listStyleType: "none",
                            margin: "0px",
                            padding: "0px",
                          }}
                          id="lessonList"
                        >
                          {list.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided) => (
                                <li
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  key={item.id}
                                  className="lessonList-draggable"
                                >
                                  <Accordion>
                                    <AccordionSummary
                                      {...provided.dragHandleProps}
                                      style={{
                                        backgroundColor: "#F17850",
                                        color: "white",
                                        borderRadius: "5px",
                                      }}
                                      expandIcon={<ExpandMoreSvg />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <SvgVector />
                                        <div className="lessonList-accordion-heading">
                                          Lesson- {item.name}
                                        </div>
                                      </div>
                                    </AccordionSummary>
                                    <AccordionDetails
                                      style={{ borderRadius: "2px" }}
                                      isDragDisabled={true}
                                    >
                                      <div className="lessonList-draggable-summary">
                                        <p>{item.id}</p>
                                        <div
                                          style={{
                                            marginLeft: "auto",
                                            marginRight: "10px",
                                          }}
                                          onClick={() => del(item)}
                                        >
                                          <DeleteSVg size={28} />
                                        </div>
                                        <div onClick={() => edit(item)}>
                                          <EditSvg size={28} />
                                        </div>
                                      </div>

                                      <p>{item.description}</p>
                                      <p>Course Associated - {isCourseIdSet}</p>
                                      {/* <CreateLesson
                                        quizData={item.quizData}
                                        itemsData={item.itemsData}
                                        topicsData={item.topicsData}
                                        courseId={isCourseIdSet}
                                        lessonId={item.id}
                                      /> */}
                                      <Item
                                        editCourseId={editCourseId}
                                        courseId={isCourseIdSet}
                                        lessonId={item.id}
                                      />

                                      <CreateQuiz
                                        editCourseId={editCourseId}
                                        courseId={isCourseIdSet}
                                        lessonId={item.id}
                                        name={"Add Objective quiz"}
                                      />
                                    </AccordionDetails>
                                  </Accordion>
                                </li>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      <div
        style={{
          height: "100px",
          margin: "auto",
          width: "100px",
          marginTop: "30px",
        }}
      >
        <button className="secondary-btn" onClick={() => navigate(-1)}>
          Back
        </button>{" "}
      </div>
    </>
  );
};

export default CreateBasicCourse;
