import { getAuth, signOut } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
//import 'rsuite/dist/styles/rsuite-default.css';

/* import { DateRangePicker } from 'rsuite'; */
//import 'rsuite/dist/styles/rsuite-default.css';
/* import Navbar from "../Component/Navbar"; */
import { url } from "../../apiBaseUrl";
import "./StyleAdminComponents/GroupList.css";
import { Link } from "react-router-dom";
import { getCookie } from "../../Cookies";
import { functions } from "../../Firebase";
import NavbarTop from "../Component/NavbarTop";
import Sidebar from "../Component/Sidebar";
import "./Dashboard.css";
import "./StyleAdminComponents/Dashboard.css";
import axios from "axios";
import LoadingScreen from "./BasicCourse/lodingScree";
import img from "./339477-PAMLRY-414.png";
const Dashboard = () => {
  const [loadingScreen, setLoadingScreen] = useState(false);
  const { clrs } = useSelector((state) => state.createClr);
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const goToEditUser = (state) =>
    navigate("/admin/UsersListForFunnelView", { state: state });
  let getuserSubjectivegiveninallcourse = httpsCallable(
    functions,
    "getuserSubjectivegiveninallcourse"
  );

  const getuserDocumentsUploadedinallcourse = httpsCallable(
    functions,
    "getuserDocumentsUploadedinallcourse"
  );

  const getuserAgreementUploadedinallcourse = httpsCallable(
    functions,
    "getuserAgreementUploadedinallcourse"
  );

  const getuserApprovedpendinginginallcourse = httpsCallable(
    functions,
    "getuserApprovedpendinginginallcourse"
  );

  const getAllCourse = httpsCallable(functions, "getAllCourse");

  const [employeeInfo, setEmployeeInfo] = useState(null);
  const [certificateInfo, setCertificateInfo] = useState(null);
  const [overDueInfo, setOverDueInfo] = useState(null);
  const [userPassedInfo, setUserPassedInfo] = useState(null);
  const [userFailedInfo, setUserFailedInfo] = useState(null);

  const [courseUserData, setCourseUserData] = useState([]);
  useEffect(() => {
    setLoadingScreen(true);
    getAllCourse({}).then((res) => {
      setLoadingScreen(false);
      if (res.data.data.length > 0) {
        for (let i = 0; i < res.data.data.length; i++) {
          let courseId = res.data.data[i].courseId;

          //console.log(courseUserData);
          //  / // setCourseUserData((prev)=>({...prev,[res.data.data.courseName]:{}}))
          let obj = {
            courseName: res.data.data[i].courseName,
            courseId: res.data.data[i].courseId,
          };
          setCourseUserData((formDetails) => formDetails.concat(obj));
          setLoadingScreen(true);

          getuserApprovedpendinginginallcourse({
            courseId: courseId ? courseId : "",
            type: "size",
          })
            .then((rees) => {
              setCourseUserData((prev) => {
                setLoadingScreen(false);

                return prev.filter((curvalue, idx) => {
                  if (curvalue.courseId == courseId) {
                    curvalue.app = rees.data;
                  }
                  return curvalue;
                });
              });
            })
            .catch((e) => {
              setLoadingScreen(false);
            });

          getuserAgreementUploadedinallcourse({
            courseId: courseId ? courseId : "",
            type: "size",
          }).then((rees) => {
            setCourseUserData((prev) => {
              return prev.filter((curvalue, idx) => {
                if (curvalue.courseId == courseId) {
                  curvalue.agree = rees.data;
                }
                return curvalue;
              });
            });

            let size = rees.data;
          });

          getuserDocumentsUploadedinallcourse({
            courseId: courseId ? courseId : "",
            type: "size",
          }).then((rees) => {
            setCourseUserData((prev) => {
              return prev.filter((curvalue, idx) => {
                if (curvalue.courseId == courseId) {
                  curvalue.doc = rees.data;
                }
                return curvalue;
              });
            });

            //let size = rees.data;
          });

          getuserSubjectivegiveninallcourse({
            courseId: courseId ? courseId : "",
            type: "size",
          }).then((rees) => {
            setCourseUserData((prev) => {
              return prev.filter((curvalue, idx) => {
                if (curvalue.courseId == courseId) {
                  curvalue.sub = rees.data;
                }
                return curvalue;
              });
            });
            //let size = rees.data;
          });
        }
      }
    });
  }, []);
  // console.log(courseUserData);

  useEffect(() => {
    if (
      getCookie("UID") == null ||
      (getCookie("ROLE") != "admin" && getCookie("ROLE") != "instructor")
    ) {
      goToHome();
      return <></>;
    }
  });

  const viewPage = (linkl, data) => {
    // console.log(url);

    // /AgreementReuploadHttps

    let link = `${url}/${linkl}`;
    data = { courseId: data.courseId ? data.courseId : "" };
    axios
      .post(link, {
        data: data,
      })
      .then(function (response) {
        //console.log(response.data.result.map((item)=>({ ...item, selected: false })));

        goToEditUser(
          response.data.result.map((item) => ({ ...item, selected: false }))
        );
      })
      .catch(function (error) {
        //  console.log(error);
      });
  };

  return (
    <section className="dashboard">
      <NavbarTop page={t("Dashboard")} />
      {/* <Navbar /> */}

      <LoadingScreen
        open={loadingScreen}
        onClose={() => setLoadingScreen(false)}
      />
      <div style={{ height: "41vw" }} className="dashboard-main">
        <Sidebar page={"Dashboard"} hidepage={false} />
        <div className="admin-dashboard-main srollbar_div">
          <div style={{ height: "3vw" }} className="admin-overdue-top">
            <div className="admin-overdue-top-item">{t("Statistics")}</div>
          </div>
          {/*  <DateRangePicker/> */}

          {courseUserData?.length == 0 ? (
            <img src={img} style={{ margin: "5px 5px 5px 10vw" }} />
          ) : (
            ""
          )}
          {courseUserData?.map((ele) => (
            <div
              style={{
                border: "1px solid rgba(30, 55, 88, 0.2)",
                width: "78vw",
                borderRadius: "8px",
                marginLeft: "20px",
                boxShadow: "0px 4px 4px 0px #00000040",
                marginTop: "8px",
              }}
            >
              {" "}
              <div style={{ height: "3vw" }} className="admin-overdue-top">
                <div
                  className="admin-overdue-top-item"
                  style={{
                    fontSize: "24px",
                    color: "#222121",
                  }}
                >
                  {ele.courseName}
                </div>
              </div>
              <div className="admin-dashboard-main-bottom">
                <div
                  style={{ width: "24vw" }}
                  className="admin-dashboard-main-bottom-item"
                >
                  <div className="admin-dashboard-main-bottom-item-left">
                    <div className="admin-dashboard-main-bottom-item-left-top">
                      {t("Subjective Test")}
                    </div>
                    <div className="admin-dashboard-main-bottom-item-left-mid">
                      {t("Subjective Test Evaluation Remaining")}
                    </div>
                    <div className="admin-dashboard-main-bottom-item-left-btm">
                      <button
                        className="primary-btn-input"
                        onClick={() => {
                          viewPage("getuserSubjectivegiveninallcourse", ele);
                        }}
                      >
                        <div>{t("ViewAll")}</div>
                      </button>
                    </div>
                  </div>
                  <div className="admin-dashboard-main-bottom-item-right">
                    <div
                      className="admin-dashboard-main-bottom-item-right-cnt"
                      style={{
                        color: "black",
                      }}
                    >
                      {ele.sub !== undefined ? (
                        ele.sub
                      ) : (
                        <div
                          className="admin-overdue-bottom-table-cnt-certi"
                          style={{ width: "100%", textAlign: "center" }}
                          key={0}
                        >
                          <div
                            className="spinner-container"
                            style={{ width: "100%", textAlign: "center" }}
                          >
                            <div
                              className="loading-spinner"
                              style={{
                                width: "20px",
                                height: "20px",
                                border: " 5px solid #f3f3f3",
                                borderTop: "5px solid #06FF00",
                                margin: "auto auto auto 8px",
                              }}
                            ></div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  style={{ width: "24vw" }}
                  className="admin-dashboard-main-bottom-item"
                >
                  <div className="admin-dashboard-main-bottom-item-left">
                    <div className="admin-dashboard-main-bottom-item-left-top">
                      {t("Registration Verification")}
                    </div>
                    <div className="admin-dashboard-main-bottom-item-left-mid">
                      {t("Registration Verification Remaining")}
                    </div>
                    <div className="admin-dashboard-main-bottom-item-left-btm">
                      <button
                        className="primary-btn-input"
                        onClick={() => {
                          viewPage("getuserDocumentsUploadedinallcourse", ele);
                        }}
                      >
                        <div>{t("ViewAll")}</div>
                      </button>
                    </div>
                  </div>
                  <div className="admin-dashboard-main-bottom-item-right">
                    <div
                      className="admin-dashboard-main-bottom-item-right-cnt"
                      style={{
                        color: "black",
                      }}
                    >
                      {ele.doc !== undefined ? (
                        ele?.doc
                      ) : (
                        <div
                          className="admin-overdue-bottom-table-cnt-certi"
                          style={{ width: "100%", textAlign: "center" }}
                          key={0}
                        >
                          <div
                            className="spinner-container"
                            style={{ width: "100%", textAlign: "center" }}
                          >
                            <div
                              className="loading-spinner"
                              style={{
                                width: "20px",
                                height: "20px",
                                border: " 5px solid #f3f3f3",
                                borderTop: "5px solid #06FF00",
                                margin: "auto auto auto 8px",
                              }}
                            ></div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{ width: "24vw" }}
                  className="admin-dashboard-main-bottom-item"
                >
                  <div className="admin-dashboard-main-bottom-item-left">
                    <div className="admin-dashboard-main-bottom-item-left-top">
                      {t("Document Verification")}
                    </div>
                    <div className="admin-dashboard-main-bottom-item-left-mid">
                      {t("Document Verification Remaining")}
                    </div>
                    <div className="admin-dashboard-main-bottom-item-left-btm">
                      <button
                        className="primary-btn-input"
                        onClick={() => {
                          viewPage("getuserAgreementUploadedinallcourse", ele);
                        }}
                      >
                        <div>{t("ViewAll")}</div>
                      </button>
                    </div>
                  </div>
                  <div className="admin-dashboard-main-bottom-item-right">
                    <div
                      className="admin-dashboard-main-bottom-item-right-cnt"
                      style={{
                        color: "black",
                      }}
                    >
                      {ele.agree !== undefined ? (
                        ele?.agree
                      ) : (
                        <div
                          className="admin-overdue-bottom-table-cnt-certi"
                          style={{ width: "100%", textAlign: "center" }}
                          key={0}
                        >
                          <div
                            className="spinner-container"
                            style={{ width: "100%", textAlign: "center" }}
                          >
                            <div
                              className="loading-spinner"
                              style={{
                                width: "20px",
                                height: "20px",
                                border: " 5px solid #f3f3f3",
                                borderTop: "5px solid #06FF00",
                                margin: "auto auto auto 8px",
                              }}
                            ></div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{ height: "8.5vw", width: "24vw" }}
                  className="admin-dashboard-main-bottom-item"
                >
                  <div className="admin-dashboard-main-bottom-item-left">
                    <div className="admin-dashboard-main-bottom-item-left-top">
                      {t("Approval")}
                    </div>
                    <div className="admin-dashboard-main-bottom-item-left-mid">
                      {t("No.of.Pending Approval")}
                    </div>
                    <div className="admin-dashboard-main-bottom-item-left-btm">
                      <button
                        className="primary-btn-input"
                        onClick={() => {
                          viewPage("getuserApprovedpendinginginallcourse", ele);
                        }}
                      >
                        <div>{t("ViewAll")}</div>
                      </button>
                    </div>
                  </div>
                  <div className="admin-dashboard-main-bottom-item-right">
                    <div
                      className="admin-dashboard-main-bottom-item-right-cnt"
                      style={{
                        color: "black",
                      }}
                    >
                      {ele.app !== undefined ? (
                        ele?.app
                      ) : (
                        <div
                          className="admin-overdue-bottom-table-cnt-certi"
                          style={{ width: "100%", textAlign: "center" }}
                          key={0}
                        >
                          <div
                            className="spinner-container"
                            style={{ width: "100%", textAlign: "center" }}
                          >
                            <div
                              className="loading-spinner"
                              style={{
                                width: "20px",
                                height: "20px",
                                border: " 5px solid #f3f3f3",
                                borderTop: "5px solid #06FF00",
                                margin: "auto auto auto 8px",
                              }}
                            ></div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
