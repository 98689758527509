import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AddNewUser from "./Admin/AdminComponents/AddNewUser";
import AdminSettings from "./Admin/AdminComponents/AdminSetting/AdminSettings";
import CourseCreation from "./Admin/AdminComponents/Builder/CourseCreation";
import CreateCourse from "./Admin/AdminComponents/Builder/CreateCourse";
import CreateBasicCourse from "./Admin/AdminComponents/BasicCourse/CreateCourse";
import QuestionForm from "./Admin/AdminComponents/Builder/QuestionForm";
import CertificateRenewal from "./Admin/AdminComponents/CertificateRenewal";
import Certificates from "./Admin/AdminComponents/Certificates/Certificates";
import CourseList from "./Admin/AdminComponents/CourseList";
import CourseReport from "./Admin/AdminComponents/CourseReport";
import Dashboard from "./Admin/AdminComponents/Dashboard";
import ExpiringTraining from "./Admin/AdminComponents/Dashboard/ExpiringTraining";
import NewEmployees from "./Admin/AdminComponents/Dashboard/NewEmployees";
import OverdueEnrollments from "./Admin/AdminComponents/Dashboard/OverdueEnrollments";
import UserFailed from "./Admin/AdminComponents/Dashboard/UserFailed";
import UserPassed from "./Admin/AdminComponents/Dashboard/UserPassed";
import ExternalTraining from "./Admin/AdminComponents/ExternalTraining";
import ExternalTrainingEdits from "./Admin/AdminComponents/ExternalTrainingEdits";
import CourseOverView from "./Admin/AdminComponents/courseOverView/courseOverView";
import GroupEdits from "./Admin/AdminComponents/Groups/GroupEdit";
import GroupEnrollment from "./Admin/AdminComponents/GroupEnrollment/GroupEnrollment";
import IndividualEnrollmentList from "./Admin/AdminComponents/IndividualEnrollment/IndividualEnrollmentList";
import GroupEnrollmentEdits from "./Admin/AdminComponents/GroupEnrollment/GroupEnrollmentEdits";
import Groups from "./Admin/AdminComponents/Groups/Groups";
import InternalOfflineTraining from "./Admin/AdminComponents/InternalOfflineTraining";
import InternalOfflineTrainingEdits from "./Admin/AdminComponents/InternalOfflineTrainingEdits";
import InternalTraining from "./Admin/AdminComponents/InternalTraining";
import Notifications from "./Admin/AdminComponents/Notifications";
import PreviousTraining from "./Admin/AdminComponents/PreviousTraining";
import PreviousTrainingEdits from "./Admin/AdminComponents/PreviousTrainingEdits";
import TrainExtAdd from "./Admin/AdminComponents/TrainExtAdd";
import TrainExtrenalTrainEn from "./Admin/AdminComponents/TrainExtrenalTrainEn";
import TrainingEnrollments from "./Admin/AdminComponents/TrainingEnrollments";
import Trainings from "./Admin/AdminComponents/Trainings";
import TrainInterAdd from "./Admin/AdminComponents/TrainInterAdd";
import TrainInternalOffTrainEn from "./Admin/AdminComponents/TrainInternalOffTrainEn";
import TrainInternalTrainEn from "./Admin/AdminComponents/TrainInternalTrainEn";
import TrainInterOffAdd from "./Admin/AdminComponents/TrainInterOffAdd";
import Users from "./Admin/AdminComponents/Users/Users";
import UserView from "./Admin/AdminComponents/Users/UserView";
import Certificatedetails from "./Admin/AdminComponents/CertificateTemplate/Certificatedetails";
import CertificateCreate from "./Admin/AdminComponents/CertificateTemplate/CertificateCreate";
import "./App.css";

import MileStoneView from "./Admin/AdminComponents/Users/mileStoneView";
import ONe from "./ONe";
// import LoginPage from "./User/components/loginpage/LoginPage";
// import Hompage from "./User/components/Homepage/Hompage";
// import UserSettings from "./User/components/Settings/Settings";
// import CourseLibraryMain from "./User/components/CourseLibrary/CourseLibraryMain";
// import TrainingMain from "./User/components/Training/TrainingMain";
// import ProfilePageMain from "./User/components/ProfilePage/ProfilePageMain";
// import YourAssignments from "./User/components/YourAssignments/YourAssignments";
// import UserCertificates from "./User/components/Certificates/Certificates";
// import UserReports from "./User/components/UserReports/UserReports";
// import CourseViewAll from "./User/components/CourseLibrary/CourseViewAll";
// import SingleCourse from "./User/components/CourseLibrary/SingleCourse";
// import QuizMainPage from "./User/components/CourseLibrary/Quiz/QuizMainPage";
// import QuizMainPage from "./User/components/CourseLibrary/SingleCourse/Quiz/QuizItem.js";

import ChangePassword from "./User/components/ChangePassword/ChangePassword";

import LoginPage from "./User/components/loginpage/LoginPage";
import GenerateReportPage from "./Admin/AdminComponents/GenerateReports";
import ForgotPassword from "./User/components/Forgot-password/ForgotPassword";

import SetPassword from "./User/components/setPaassword/SetPassword";

import PageNotFound from "./PageNotFound";

import { CompanyDepartmentSettings } from "./Admin/AdminComponents/AdminSetting/CompanyDepartmentSettings";
import { CompanyDepartmentCreate } from "./Admin/AdminComponents/AdminSetting/SettingComponents/CompanyDepartmentCreate";
import { EditCompany } from "./Admin/AdminComponents/AdminSetting/SettingComponents/EditCompany";
import { ViewCompany } from "./Admin/AdminComponents/AdminSetting/SettingComponents/ViewCompany";
import { EmailSettings } from "./Admin/AdminComponents/AdminSetting/EmailSettings";
import EmailSettingPage from "./Admin/AdminComponents/Emails/EmailSettingPage";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import { toast, ToastContainer } from "react-toastify";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { CreateEmailNotification } from "./Admin/AdminComponents/Emails/CreateEmailNotification";
import { functions, auth } from "./Firebase";
import { httpsCallable } from "firebase/functions";
import { onAuthStateChanged } from "firebase/auth";
import AddNewEnrollment from "./Admin/AdminComponents/IndividualEnrollment/AddNewEnrollment";
import GroupEnrollmentView from "./Admin/AdminComponents/GroupEnrollment/GroupEnrollmentView";
import AllUsersFunnelView from "./Admin/AdminComponents/Users/AllUserOnTheApp";
import AvailableCertificates from "./Admin/AdminComponents/AdminSetting/AvailableCertificates";
import GroupEnrollmentFunnelView from "./Admin/AdminComponents/GroupEnrollment/groupFunnelView";
import GroupEnrollmentMileStone from "./Admin/AdminComponents/GroupEnrollment/groupEnroolmentMileStone";
import UsersListForFunnelView from "./Admin/AdminComponents/Users/userListForCategory";
import { BasicLessonCategory } from "./Admin/AdminComponents/AdminSetting/basicLessonCategory";
import { UserFilterInAppQuestions } from "./Admin/AdminComponents/AdminSetting/UserFilterQuestion";
import { CategoryForWellComeQuestion } from "./Admin/AdminComponents/AdminSetting/createWellComeCategory";

import GenerateEntranceReportPage from "./Admin/AdminComponents/entranceCourseReport";
import { Coordinator } from "./Admin/AdminComponents/AdminSetting/coordinator/coordinator";
// import { toast, ToastContainer } from "react-toastify";

const App = () => {
  const saveToken = httpsCallable(functions, "saveToken");

  // const [messagingToken, setMessagingToken] = useState(null);

  // console.log('Requesting permission...');
  // Notification.requestPermission()
  //   .then((permission) => {
  //     if (permission === "granted") {
  //       console.log("Notification permission granted.");
  //     }
  //   })
  //   .then(() => {
  //     getToken(messaging, {
  //       vapidKey:
  //         "BHCP0XRwzckQmAlqb6_H9Jo_Ggy18XzNifQCWHJKcar2F0hASNIUh7HXinpcib_EVjpYY9J2Lxj1mrtZ7b0UigE",
  //     }).then(async (currentToken) => {
  //       if (currentToken) {
  //         // use onAuthStateChange

  //         // save if user is logged in
  //         await saveToken({ token: currentToken });

  //         // setMessagingToken(currentToken);
  //         // console.log(currentToken);
  //       } else {
  //         console.log(
  //           "No registration token available. Request permission to generate one."
  //         );
  //       }
  //     });
  //   });
  useEffect(() => {
    // console.log(process.env.Name)
    // console.log("UseEffect", messaging);

    const unsubAuth = onAuthStateChanged(auth, async (user) => {
      // console.log(user);
      if (!user) return;
    });

    return () => {
      // unsubMessage();
      unsubAuth();
    };
  }, []);
  // onMessage(messaging, (payload) => {
  //   //console.log("Message received. ", payload);

  //   // toast.success(payload.notification.body)
  //   // Post params - fcm, message
  //   // toast(payload.notification.body);
  //   NotificationManager.success(
  //     payload.notification.body,
  //     payload.notification.title
  //   ); //success, warning, info, danger

  //   // alert(payload.notification.body)
  // });

  return (
    <div className="App">
      {/* notification  */}
      {/* <button onClick={showNotification}>show Notification</button> */}
      <NotificationContainer style={{ zIndex: 100 }} />
      {/* <ToastContainer */}
      {/* // position="top-right"
      // autoClose={1500}
      // hideProgressBar={false}
      // closeOnClick={true}
      // newestOnTop={false}
      // pauseOnFocusLoss
      // pauseOnHover
      // draggable */}
      {/* /> */}

      <Router>
        <Routes>
          {/* User Routes */}
          {/* <Route path="/" element={<LoginPage />} />
          <Route path="/user" element={<Hompage />} />
          <Route path="/user/settings" element={<UserSettings />} />
          <Route path="/user/courselibrary" element={<CourseLibraryMain />} />
          <Route path="/user/training" element={<TrainingMain />} />
          <Route path="/user/profile" element={<ProfilePageMain />} />
          <Route path="/user/yourassignment" element={<YourAssignments />} />
          <Route path="/user/certificates" element={<UserCertificates />} />
          <Route path="/user/userreports" element={<UserReports />} />
          <Route
          path="/user/courselibrary/viewall"
          element={<CourseViewAll />}
          />
          <Route
          path="/user/courselibrary/singlecourse/:courseId"
          element={<SingleCourse />}
          />
          <Route path="/user/courselibrary/quiz" element={<QuizMainPage />} />

          <Route path="/resetPassword" element={<ResetPassword />}></Route> */}
          {/* <Route path="/setPassword" element={<SetPassword />} /> */}
          <Route path="/" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/set-password" element={<SetPassword />} />
          <Route
            path="/admin/settings/available-certificates"
            element={<AvailableCertificates />}
          ></Route>{" "}
          {/* /user/training/external-training/view-materials */}
          <Route path="profile/change-password" element={<ChangePassword />} />
          {/* <Route path="/instructor" element={<InstructorDashboard />}></Route> */}
          {/* <Route path="/admin/one" element={<ONe />}></Route> */}
          {/* Admin Routes */}
          <Route path="/admin" element={<Dashboard />}></Route>
          <Route path="/admin/users" element={<Users />}></Route> {/**a */}
          <Route path="/admin/addNewUser" element={<AddNewUser />}></Route>{" "}
          {/**a */}
          <Route
            path="/admin/settings"
            element={<AdminSettings />}
          ></Route>{" "}
          {/**a */}
          <Route path="/admin/courseBuilder" element={<CreateCourse />}></Route>
          <Route
            path="/admin/user/MileStoneView"
            element={<MileStoneView />}
          ></Route>
          <Route path="/admin/groups" element={<Groups />}></Route>
          {/**ai */}
          <Route path="/admin/groupEdits/" element={<GroupEdits />}></Route>
          {/**ai */}
          <Route
            path="/admin/basicCourse"
            element={<CreateBasicCourse />}
          ></Route>
          {/* muda muda muda... */}
          {/* <Route path="/admin/externalTraining" element={<ExternalTraining />}></Route> */}
          {/**ai */}
          <Route
            path="/admin/settings/certificate"
            element={<Certificatedetails />}
          ></Route>
          {/**a */}
          <Route
            path="/admin/settings/certificatecreate"
            element={<CertificateCreate />}
          ></Route>
          {/**a */}
          <Route
            path="/admin/settings/email"
            element={<EmailSettings />}
          ></Route>
          {/**a */}
          <Route
            path="/admin/settings/emailtemplate"
            element={<EmailSettingPage />}
          ></Route>
          {/**a */}
          <Route
            path="/admin/settings/email-create"
            element={<CreateEmailNotification />}
          ></Route>
          {/**a */}
          <Route
            path="/admin/settings/course-question-Category"
            element={<CompanyDepartmentSettings />}
          ></Route>
          <Route
            path="/admin/settings/BasicLessonCategory"
            element={<BasicLessonCategory />}
          ></Route>
          {/**a */}
          <Route
            path="/admin/settings/UserFilterInAppQuestions"
            element={<UserFilterInAppQuestions />}
          ></Route>
          <Route
            path="/admin/settings/CategoryForWellComeQuestion"
            element={<CategoryForWellComeQuestion />}
          ></Route>
          <Route
            path="/admin/settings/companycreate"
            element={<CompanyDepartmentCreate />}
          ></Route>
          {/**a */}
          <Route
            path="/admin/settings/edit-company"
            element={<EditCompany />}
          ></Route>
          <Route
            path="/admin/settings/coordinator"
            element={<Coordinator />}
          ></Route>
          {/**a */}
          <Route
            path="/admin/settings/view-company"
            element={<ViewCompany />}
          ></Route>
          {/**a */}
          {/* <Route
            path="/admin/externalTrainingEdits/:id"
            element={<ExternalTrainingEdits />}
          ></Route> */}
          {/**ai */}
          {/* <Route
            path="/admin/internalOfflineTraining"
            element={<InternalOfflineTraining />}
          ></Route> */}
          {/**ai */}
          {/* <Route
            path="/admin/internalOfflineTrainingEdits/:id"
            element={<InternalOfflineTrainingEdits />}
          ></Route> */}
          {/**ai */}
          <Route
            path="/admin/groupEnrollment"
            element={<GroupEnrollment />}
          ></Route>
          {/**ai */}
          <Route
            path="/admin/groupEnrollment/view"
            element={<GroupEnrollmentView />}
          ></Route>
          <Route
            path="/admin/IndividualEnrollment"
            element={<IndividualEnrollmentList />}
          ></Route>
          {/**ai */}
          <Route
            path="/admin/IndividualEnrollment/addNewIndividualEnrollment"
            element={<AddNewEnrollment />}
          ></Route>
          <Route
            path="/admin/groupEnrollmentFunnel"
            element={<GroupEnrollmentFunnelView />}
          ></Route>
          {/**ai */}
          <Route
            path="/admin/groupEnrollmentMileStone/"
            element={<GroupEnrollmentMileStone />}
          ></Route>
          {/* IndividualEnrollmentList */}
          <Route
            path="/admin/groupEnrollmentEdits/"
            element={<GroupEnrollmentEdits />}
          ></Route>
          {/**ai */}
          <Route path="/admin/certificate" element={<Certificates />}></Route>
          {/**a */}
          <Route
            path="/admin/overdueenrollments"
            element={<OverdueEnrollments />}
          />
          {/*ai */}
          <Route path="/admin/userpassed" element={<UserPassed />} />
          {/*ai */}
          <Route path="/admin/userfailed" element={<UserFailed />} />
          {/*ai */}
          <Route path="/admin/newemployees" element={<NewEmployees />} />
          {/*a */}
          <Route
            path="/admin/expiringtraining"
            element={<ExpiringTraining />}
          />
          {/*ai */}
          <Route path="/admin/course-reports" element={<CourseReport />} />
          {/*a */}
          <Route
            path="/admin/course-reports/GenerateReportPage"
            element={<GenerateReportPage />}
          />
          {/*a */}
          <Route
            path="/admin/course-reports/GenerateEntranceReportPage"
            element={<GenerateEntranceReportPage />}
          />
          <Route
            path="/admin/user/allUserFunnelview"
            element={<AllUsersFunnelView />}
          />
          <Route
            path="/admin/UsersListForFunnelView"
            element={<UsersListForFunnelView />}
          ></Route>
          <Route path="/admin/userview" element={<UserView />} />
          {/*a */}
          <Route path="/admin/training" element={<Trainings />} />
          <Route
            path="/admin/training/internal"
            element={<TrainInternalTrainEn />}
          />
          <Route
            path="/admin/training/extrenal"
            element={<TrainExtrenalTrainEn />}
          />
          <Route
            path="/admin/training/internalOff"
            element={<TrainInternalOffTrainEn />}
          />
          <Route
            path="/admin/training/extrenal/add"
            element={<TrainExtAdd />}
          />
          <Route
            path="/admin/training/internalOff/add"
            element={<TrainInterOffAdd />}
          />
          <Route
            path="/admin/course/CourseOverView"
            element={<CourseOverView />}
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
