import { t } from "i18next";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Component/NavbarTop";
import Sidebar from "../Component/Sidebar";
import { Timestamp } from "firebase/firestore";
import "./CourseList.css";
import { getCookie } from "../../Cookies";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Cdata } from "./CourseData";
import { Checkbox } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../Firebase";

function TrainInternalOffTrainEn() {
  const [trainingArray, setTrainingArray] = useState(null);
  const getInternalOfflineTrainings = httpsCallable(functions, "getInternalOfflineTrainings");
  const { clrs } = useSelector((state) => state.createClr);
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const goToBack = () => navigate("/admin/training");
  const [showLoading, setLoading] = useState(true);

  useEffect(() => {
    if (
      getCookie("UID") == null ||
      (getCookie("ROLE") != "admin" && getCookie("ROLE") != "instructor")
    ) {
      goToHome();
      return <></>;
    }
  });

  const [arrLength, setArrLength] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const handleActionChange = (e, id) => {
    console.log(e.target.value);
    if (e.target.value == "Edit") {
      console.log(e.target.value);
      navigate("add", { state: { trainingId: trainingArray[id] } });
    }
  };
  useEffect(() => {
    getInternalOfflineTrainings().then((res) => {
      if (res.data?.error) {
        console.log(res.data?.error);
        setTrainingArray([]);
        setArrLength(0);
        setLoading(false);
        return;
      }

      setTrainingArray(res.data?.map((item) => ({ ...item, selected: false })));
      setArrLength(res.data?.length);
      setLoading(false);
    });
  }, []);

  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(arrLength / pageSize)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };
  const SelectHand = (i) => {
    let newData;
    newData = trainingArray.map((item, id) => {
      if (id === i) {
        item.selected = item.selected ? false : true;
        return item;
      } else {
        return item;
      }
    });
    if (newData) setTrainingArray(newData);
  };

  console.log(trainingArray);
  return (
    <div className="courselist">
      <Navbar page={t("Training")} />
      <div className="courselist-main" style={{ gridTemplateColumns: "6vw 1fr" }}>
        <Sidebar page={"training"} hidepage={true} />
        <div className="courselist-main-cnt">
          <div className="courselist-main-cnt-top">
            <div className="courselist-main-cnt-top-txt"></div>

            <div>
              <Link to={"/admin/training/internalOff/add"}>
                <button
                  style={{
                    background: `${clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"}`,
                  }}
                >
                  Add New Internal Offline Training
                </button>
              </Link>
            </div>
          </div>
          <div className="course-head">Internal Offline Training</div>
          <div className="courselist-main-cnt-bottom">
            <div
              className="courselist-main-cnt-bottom-heading"
              style={{
                textAlign: "center",
                padding: "0",
                gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
              }}
            >
              <div style={{ textAlign: "left", paddingLeft: "3vw" }}>S.NO.</div>
              <div style={{ paddingLeft: "2vw" }}>Training Name</div>
              <div style={{ paddingLeft: "2vw" }}>Created Date</div>
              <div style={{ paddingLeft: "2vw" }}>Training Provider</div>
              <div style={{}}>Action</div>
            </div>
            <div className="admin-overdue-bottom">
              <div className="admin-overdue-bottom-table">
                {showLoading && (
                  <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td">
                      <div className="spinner-container">
                        <div className="loading-spinner"></div>
                      </div>
                    </div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div
                      className="admin-overdue-bottom-table-td"
                      style={{ color: "#004577" }}
                    ></div>
                  </div>
                )}
                {trainingArray?.map((item, id) => {
                  if (id < pageSize * currentPage) return;
                  if (id >= pageSize * (currentPage + 1)) return;

                  let createdDate = Timestamp.fromMillis(item.createdOn._seconds * 1000)
                    .toDate()
                    .toDateString();
                  return (
                    <div
                      className="admin-overdue-bottom-table-cnt-c "
                      style={{
                        gridTemplateColumns: "3vw 1fr 1fr 1fr 1fr 1fr",
                        backgroundColor: `${
                          item.selected ? "rgba(0, 69, 119, 0.1)" : "rgba(255, 255, 255, 1)"
                        }`,
                      }}
                      key={id}
                    >
                      <div className="admin-overdue-bottom-table-td">
                        {/* <Checkbox checked={item.selected} onClick={() => SelectHand(id)} /> */}
                      </div>
                      <div
                        className="admin-overdue-bottom-table-td"
                        style={{
                          display: "grid",
                          justifyContent: "left",
                          alignItems: "center",
                        }}
                        id="c-div"
                      >
                        {id + 1}
                      </div>
                      <div className="admin-overdue-bottom-table-td" id="c-div">
                        {item.trainingName}
                      </div>
                      <div className="admin-overdue-bottom-table-td" id="c-div">
                        {createdDate}
                      </div>
                      <div className="admin-overdue-bottom-table-td" id="c-div">
                        {item.trainingProvider}
                      </div>
                      <div className="admin-overdue-bottom-table-td" id="c-div">
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "3vw 3vw",
                          }}
                        >
                          <div>
                            <select
                              style={{
                                border: "none",
                                backgroundColor: "#F2F2F2",
                                height: "4vh",
                                width: "7vw",
                                padding: "0 1vh",
                                fontWeight: "700",
                                color: "#717579",
                                borderRadius: "6px",
                              }}
                              onChange={(e) => handleActionChange(e, id)}
                            >
                              <option selected>Action</option>
                              <option value="Edit">Edit</option>
                              {/* <option>Archive</option> */}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="admin-overdue-bottom-pagination">
                <div className="admin-overdue-bottom-pagination-cnt">
                  <div className="admin-overdue-bottom-pagination-cnt-item">
                    <svg
                      onClick={() => clickhandler(currentPage - 1)}
                      width="auto"
                      height="22"
                      viewBox="0 0 14 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                        fill="#717579"
                      />
                    </svg>
                  </div>
                  <div className="admin-overdue-bottom-pagination-cnt-item-btn">
                    {currentPage + 1} of {Math.ceil(arrLength / pageSize)}
                  </div>
                  <div
                    style={{ marginRight: "19vw" }}
                    className="admin-overdue-bottom-pagination-cnt-item"
                  >
                    <svg
                      onClick={() => clickhandler(currentPage + 1)}
                      width="auto"
                      height="20"
                      viewBox="0 0 13 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                        fill="#717579"
                      />
                    </svg>
                  </div>
                  <div
                    style={{ display: "flex", marginRight: "-30vw" }}
                    className="admin-overdue-bottom-pagination-cnt-item"
                  >
                    <label className="admin-row">{t("Show")}</label>
                    <select
                      style={{
                        width: "4vw",
                        margin: "0.5vw",
                        marginBottom: "1.5vw",
                        height: "1.5vw",
                      }}
                      value={pageSize}
                      onChange={(e) => setPageSize(e.target.value)}
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={15}>15</option>
                      <option value={20}>20</option>
                    </select>
                    <label className="admin-row"> {t("PagePerItem")}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="courselist-main-cnt-back">
            <div className="courselist-main-cnt-back-btn">
              <button
                style={{
                  background: `${clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"}`,
                }}
                onClick={() => navigate(-1)}
              >
                Back
              </button>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrainInternalOffTrainEn;
