import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "'Readex Pro', sans-serif",
      textTransform: "none",
      fontSize: 16,
    },
  },
});
