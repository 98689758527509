import React, { useEffect, useState } from "react";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  uploadBytes,
  deleteObject,
} from "@firebase/storage";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  increment,
} from "firebase/firestore";
import { db, functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { Timestamp } from "firebase/firestore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import "./createQuiz.css";
import { ExpandMoreSharp } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdEdit, MdDelete } from "react-icons/md";
import { ExpandMoreSvg } from "./icons";
import DeleteLesson from "./lessonDeletePopUp";
import LoadingScreen from "./lodingScree";
const CreateQuestion = ({
  editCourseId,
  quizId,
  courseId,
  questionSetId,
  type,
  mileStoneQuizId,
  mileStoneId,
}) => {
  const storage = getStorage();

  const createQuizQuestionOrderBasicCourse = httpsCallable(
    functions,
    "createQuizQuestionOrderBasicCourse"
  );

  const deleteBasicQuestion = httpsCallable(functions, "deleteBasicQuestion");

  const createQuestionForbasicCourse = httpsCallable(
    functions,
    "createQuestionForbasicCourse"
  );

  const getAllQUestionForBasicCourseQuiz = httpsCallable(
    functions,
    "getAllQUestionForBasicCourseQuiz"
  );
  //addQuestionObjectiveOrder
  // added by Himanshu
  const [deleteQuestionId, setDeleteQuestionId] = useState("");
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);

  const [editQuestionFlag, setEditQuestionFlag] = useState(false);

  const [questionName, setQuestionName] = useState("");
  const [questionId, setQuestionId] = useState("");
  const [loadingScreen, setLoadingScreen] = useState(false);

  const initialList = [];
  const [list, setList] = useState(initialList);

  const [picMCQ, setPicMCQ] = useState({
    url: "",
    base64: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    answer: 0,
  });

  function onPicMCQChange(e) {
    const { value, name, files } = e.target;
    setPicMCQ((prev) => ({
      ...prev,
      [name]: value,
    }));
  }
  function setPicMCQFile(e) {
    const { files } = e.target;
    const url = URL.createObjectURL(files[0]);
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result.length > 1000000) {
        alert("Image Too large, compress and try again");
        e.target.value = "";
        return;
      }
      setPicMCQ((prev) => ({ ...prev, url: url, base64: reader.result }));
      //console.log("setBase64Image done", reader.result);
    };
    reader.readAsDataURL(files[0]);

    // setPicMCQ((prev) => ({ ...prev,  }));
  }

  function uploadPicMCQ(file) {
    if (!file) return;
    let time = JSON.stringify(new Date().getTime());
    const storageref = ref(storage, `/files/${file.name + "_" + time}`);
    const uploadTask = uploadBytesResumable(storageref, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        // can set progress.
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) =>
          setPicMCQ((prev) => ({ ...prev, url: url }))
        );
      }
    );
  }

  const [fillUpsAns, setFillUpsAns] = useState("");
  // rearrange
  const [canEditRearrange, setCanEditRearrange] = useState(true);
  const [rearrangeOptions, setRearrangeOptions] = useState([
    {
      text: "First",
      position: 1,
    },
    {
      text: "Second",
      position: 2,
    },
    {
      text: "Third",
      position: 3,
    },
    {
      text: "Fourth",
      position: 4,
    },
  ]);
  /* arr element example
    {
      text:"rearrange",
      position: 1
    }
  */
  const handleRearrange = (e, order) => {
    // console.log("onChange");
    if (!canEditRearrange) return;
    setRearrangeOptions((prev) => {
      let item = {
        text: e.target.value,
        position: order,
      };
      let res = Array.from(prev);
      res[order - 1] = item;
      return res;
    });
  };
  const handleScramble = () => {
    setCanEditRearrange(false);
    setRearrangeOptions((prev) => {
      let res = scrambleArr(prev);
      // console.log(res);
      return res;
    });
  };
  const scrambleArr = (arr) => {
    //console.log(arr[0]);
    let res = Array.from(arr);
    return res.sort((a, b) => 0.5 - Math.random());
  };

  const [canEditMatchPair, setCanEditMatchPair] = useState(true);
  const [leftOptions, setLeftOptions] = useState(new Array(4).fill(""));
  const [rightOptions, setRightOptions] = useState([
    {
      text: "",
      match: 1,
    },
    {
      text: "",
      match: 2,
    },
    {
      text: "",
      match: 3,
    },
    {
      text: "",
      match: 4,
    },
  ]);

  const handleMatchPair = (e, order, side) => {
    //  console.log("onChange");
    if (!canEditMatchPair) return;
    if (side == "left") {
      setLeftOptions((prev) => {
        let item = e.target.value;

        let res = Array.from(prev);
        res[order - 1] = item;
        return res;
      });
    } else {
      setRightOptions((prev) => {
        let item = {
          text: e.target.value,
          match: order,
        };
        let res = Array.from(prev);
        res[order - 1] = item;
        return res;
      });
    }
  };

  const handleMatchUpScramble = () => {
    setCanEditMatchPair(false);
    setRightOptions((prev) => {
      let res = scrambleArr(prev);
      // console.log(res);
      return res;
    });
  };

  // match words to image

  const [imageOptions, setImageOptions] = useState(
    new Array(4).fill("options")
  );
  const [imageFiles, setImageFiles] = useState([
    {
      base64: "",
      match: 1,
      url: "",
    },
    {
      base64: "",
      match: 2,
      url: "",
    },
    {
      base64: "",
      match: 3,
      url: "",
    },
    {
      base64: "",
      match: 4,
      url: "",
    },
  ]);
  const [disableImage, setDisableImage] = useState(false);
  const [uploading, setUploading] = useState(false);
  const handleOptionsChange = (e, index) => {
    let { value } = e.target;
    setImageOptions((prev) => {
      let newArr = Array.from(prev);
      newArr[index] = value;
      return newArr;
    });
  };
  const handleImageChange = (e, index) => {
    let { files } = e.target;
    let reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result.length > 1000000) {
        alert("Image Too large, compress and try again");
        e.target.value = "";
        return;
      }
      setImageFiles((prev) => {
        let newArr = Array.from(prev);
        let item = {
          match: index + 1,
          url: URL.createObjectURL(files[0]),
          base64: reader.result,
        };
        newArr[index] = item;
        return newArr;
      });
      // console.log("Image converted ", index);
    };
    reader.readAsDataURL(files[0]);
  };

  const scrambleImage = () => {
    setDisableImage(true);
    setImageFiles((prev) => scrambleArr(prev));
  };

  async function uplaodMatchImage(file) {
    if (!file) return;
    let resURL = "";
    let time = JSON.stringify(new Date().getTime());
    const storageref = ref(storage, `/files/${time + "_" + file.name}`);
    const uploadTask = await uploadBytes(storageref, file);
    resURL = await getDownloadURL(storageref);
    // console.log(resURL);
    return resURL;
  }

  const [imageMcq, setImageMcq] = useState([
    { base64: "", blobUrl: "" },
    { base64: "", blobUrl: "" },
    { base64: "", blobUrl: "" },
    { base64: "", blobUrl: "" },
  ]);
  const [imageMcqAnswer, setImageMcqAnswer] = useState(0);

  const handleImageMcqInput = (e, index) => {
    const { files } = e.target;
    const url = URL.createObjectURL(files[0]);
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result.length > 1000000) {
        alert("Image too large, compress and try again");
        e.target.value = "";
        return;
      }
      setImageMcq((prev) => {
        let newArr = Array.from(prev);
        newArr[index].base64 = reader.result;
        newArr[index].blobUrl = url;
        return newArr;
      });
      //  console.log("setBase64Image done", reader.result);
    };
    reader.readAsDataURL(files[0]);

    // setImageMcq((prev) => {
    //   let newArr = Array.from(prev);
    //   return newArr;
    // });
  };

  //

  useEffect(() => {
    if (!editCourseId) return;
    setLoadingScreen(true);
    getAllQUestionForBasicCourseQuiz({ quizId: quizId })
      .then((res) => {
        setLoadingScreen(false);
        for (let i = 0; i < res.data.length; i++) {
          setList((prev) =>
            prev.concat({
              id: res.data[i].questionId,
              name: res.data[i].questionName,
              type: res.data[i].questionType,
              data: res.data[i]?.data,

              questionCategory: res.data[i]?.questionCategory,
              questionTime: res.data[i]?.questionTime,
            })
          );
        }
      })
      .catch((e) => {
        setLoadingScreen(false);
      });
  }, []);

  const formCheckFunction = () => {
    if (questionName == "") return "Question Name";
    if (questionId == "") return "Question id";
    if (qus == "") return "Question";
    // if (qusInstruction == "") return "Instruction";
    if (qus5Answer == "") return "qus5Answer";
    return "alliswell";
  };

  const [questionCategory, setquestionCategory] = useState("");
  const [maxWordLimit, setmaxWordLimit] = useState("");
  const [ansType, setansType] = useState("");
  const [qusTime, setqusTime] = useState();
  const [qusMode, setqusMode] = useState("");
  const [qustype, setqustype] = useState("MCQ");
  const [qus, setqus] = useState("");
  const [qusInstruction, setqusInstruction] = useState("");
  const [PointorScore, setPointorScore] = useState("");
  const [qus5, setqus5] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4: "",
  });
  const [qus5Answer, setqus5Answer] = useState("");
  const [qus2, setqus2] = useState({ word: "", correctImage: "" });
  const [formCheck, setFormCheck] = useState("");
  function submit(e) {
    e.preventDefault();
  }

  function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }

    if (type == "basicCourse") {
      createQuizQuestionOrderBasicCourse({
        quizId: quizId,
        questionArr: orderList,
        edit: true,
      });
    }

    // db.collection("quiz")
    //   .doc(quizId)
    //   .collection("extraInfo")
    //   .doc("infoDoc")
    //   .update({
    //     questions: orderList,
    //   });
    // db.collection("questionSet")
    //   .doc(quizId)
    //   .collection("extraInfo")
    //   .doc("infoDoc")
    //   .update({
    //     questions: orderList,
    //   });
  }

  const storedDataOnFirebase = async () => {
    let check = formCheckFunction();
    if (check != "alliswell") {
      setFormCheck("check");
      return;
    }

    let nsn = questionName.replaceAll(" ", "");

    let dat = /[^A-Za-z0-9]/.test(nsn);

    if (!dat) {
    } else {
      alert("you can not use any special characters");
      return;
    }

    setFormCheck("");
    let questionTypeEnum;
    let managequestionData;

    if (qustype == "MCQ") {
      let setoptionFromuser;
      if (qus5Answer == "Option 1") {
        let optionsFromUser = [
          { iscorrect: true, option: qus5.option1 },
          { iscorrect: false, option: qus5.option2 },
          { iscorrect: false, option: qus5.option3 },
          { iscorrect: false, option: qus5.option4 },
        ];
        setoptionFromuser = optionsFromUser;
      } else if (qus5Answer == "Option 2") {
        let optionsFromUser = [
          { iscorrect: false, option: qus5.option1 },
          { iscorrect: true, option: qus5.option2 },
          { iscorrect: false, option: qus5.option3 },
          { iscorrect: false, option: qus5.option4 },
        ];
        setoptionFromuser = optionsFromUser;
      } else if (qus5Answer == "Option 3") {
        let optionsFromUser = [
          { iscorrect: false, option: qus5.option1 },
          { iscorrect: false, option: qus5.option2 },
          { iscorrect: true, option: qus5.option3 },
          { iscorrect: false, option: qus5.option4 },
        ];
        setoptionFromuser = optionsFromUser;
      } else if (qus5Answer == "Option 4") {
        let optionsFromUser = [
          { iscorrect: false, option: qus5.option1 },
          { iscorrect: false, option: qus5.option2 },
          { iscorrect: false, option: qus5.option3 },
          { iscorrect: true, option: qus5.option4 },
        ];
        setoptionFromuser = optionsFromUser;
      }
      let questionDatafromUser = {
        options: setoptionFromuser,
        question: qus,
        questionInstruction: qusInstruction,
      };
      managequestionData = questionDatafromUser;

      questionTypeEnum = 5;
    }
    const docData = {
      questionName: questionName,
      questionId: questionId,
      associatedQuiz: quizId,

      data: managequestionData,
      questionType: qustype,
      created: Timestamp.now(),
    };
    // console.log(docData);

    createQuestionForbasicCourse({
      questionId: questionId,
      questionName: questionName,
      questionTime: qusTime,
      questionType: qustype,
      created: new Date().toString(),
      data: managequestionData,
      associatedQuiz: quizId,
      associatedCourse: courseId,
      questionCategory: questionCategory,
      maxWordLimit: maxWordLimit,
      ansType: ansType,
    });

    alert("data saved successfully");
    const newList = list.concat({
      id: questionId,
      name: questionName,
      type: qustype,
    });

    if (!editQuestionFlag) {
      setList(newList);
      saveOrder(newList);

      setEditQuestionFlag(false);
    } else {
      for (let i = 0; i < list.length; i++) {
        if (questionId === list[i].id) {
          list[i] = {
            id: questionId,
            name: questionName,
            type: qustype,
          };
        }
      }
      setList(list);
    }
    document.getElementById(quizId + "question-form").reset();
    document.getElementById(quizId + "questionName").value = "";
    toggleQuestionForm();
  };

  const toggleQuestionForm = (e) => {
    resetData();
    document.getElementById(quizId + "questionForm").style.display =
      document.getElementById(quizId + "questionForm").style.display == "none"
        ? "block"
        : "none";
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    saveOrder(items);
  }

  function edit(lesson) {
    setEditQuestionFlag(true);
    toggleQuestionForm();

    // console.log(lesson);
    setQuestionId(lesson.id);
    setQuestionName(lesson?.name);
    setqusInstruction(lesson?.data?.questionInstruction);
    setqus(lesson.data?.question);
    setqus5Answer(
      lesson.data.options.map((e, i) => {
        if (e.iscorrect) {
          return `${e} ${i + 1}`;
        }
      })
    );
    setqusTime(lesson.questionTime);
    let obj = {};
    lesson.data.options.map((e, i) => {
      if (i == 0) {
        obj["option1"] = e.option;
        if (e.iscorrect) {
          setqus5Answer("Option 1");
        }
      }
      if (i == 1) {
        obj["option2"] = e.option;
        if (e.iscorrect) {
          setqus5Answer("Option 2");
        }
      }
      if (i == 2) {
        obj["option3"] = e.option;
        if (e.iscorrect) {
          setqus5Answer("Option 3");
        }
      }
      if (i == 3) {
        obj["option4"] = e.option;
        if (e.iscorrect) {
          setqus5Answer("Option 4");
        }
      }
    });
    setqus5(obj);

    // console.log("lesson");
    setquestionCategory(lesson?.questionCategory);
  }

  function resetData() {}
  async function del(question) {
    setopenLessonPopUp(true);
    setDeleteQuestionId(question);
  }

  const delfunction = (question) => {
    deleteBasicQuestion({ questionId: question.id });
    alert("Deleted " + question.name);
    const item1 = [];
    for (let i = 0; i < list.length; i++) {
      if (question.id !== list[i].id) {
        item1.push(list[i]);
      }
    }
    setList(item1);
    saveOrder(item1);
  };
  return (
    <section className="quizForm-container">
      <DeleteLesson
        open={openLessonPopUp}
        onClose={() => setopenLessonPopUp(false)}
        delfun={(topic) => {
          delfunction(topic);
        }}
        topicId={deleteQuestionId}
      />

      <LoadingScreen
        open={loadingScreen}
        onClose={() => setLoadingScreen(false)}
      />
      <button
        className="primary-btn"
        onClick={() => {
          toggleQuestionForm();
          setEditQuestionFlag(false);
        }}
      >
        Add Question
      </button>
      <div
        className="quizFrom-mainFrom"
        id={quizId + "questionForm"}
        style={{ display: "none" }}
      >
        <div className="quizFrom-mainFrom-head">Question Form</div>

        <form id={quizId + "question-form"} onSubmit={submit}>
          <div className="quizForm-mainForm-infoRow">
            <p
              className="quizForm-mainForm-label"
              style={{
                fontWeight: 500,
                color: "#171B1E",
                width: "20%",
              }}
            >
              Question Name :
            </p>
            <input
              style={{
                border: "1px solid #d7d7d7",
                width: "30%",
                borderRadius: "6px",
                outline: "none",
                padding: "15px",
              }}
              type="text"
              className="quizForm-mainForm-inputBar"
              id={quizId + "questionName"}
              value={questionName}
              onChange={(e) => {
                setQuestionName(e.target.value);

                let value = e.target.value.replaceAll(" ", "");
                let time = new Date().getTime();
                if (!editQuestionFlag) {
                  setQuestionId(value + "_" + time);
                }
              }}
            />
          </div>
          <div className="quizForm-mainForm-infoRow">
            <p
              className="quizForm-mainForm-label"
              style={{
                fontWeight: 500,
                color: "#171B1E",
                width: "20%",
              }}
            >
              Type of question :{" "}
            </p>
            <select
              className="quizForm-mainForm-select"
              value={qustype}
              onChange={(event) => {
                setqustype(event.target.value);
              }}
              style={{
                width: "30%",
                border: "1px solid #d7d7d7",
                outline: "none",
                height: "40px",
              }}
            >
              <option value="MCQ">Multiple Choice Question</option>
            </select>
          </div>
          <div className="quizForm-mainForm-infoRow">
            <p
              className="quizForm-mainForm-label"
              style={{
                fontWeight: 500,
                color: "#171B1E",
                width: "20%",
              }}
            >
              Question :{" "}
            </p>
            <textarea
              className="quizForm-mainForm-textarea"
              value={qus}
              onChange={(event) => {
                setqus(event.target.value);
              }}
              style={{
                border: "1px solid #d7d7d7",
                width: "30%",
                minHeight: "100px",
                borderRadius: "6px",
                outline: "none",
                padding: "15px",
              }}
            ></textarea>
          </div>

          <div className="quizForm-mainForm-infoRow">
            <p
              className="quizForm-mainForm-label"
              style={{
                fontWeight: 500,
                color: "#171B1E",
                width: "20%",
              }}
            >
              Question time :{" "}
            </p>
            <input
              type="Number"
              className="quizForm-mainForm-inputBar"
              value={qusTime}
              onChange={(event) => {
                setqusTime(event.target.value);
              }}
              style={{
                border: "1px solid #d7d7d7",
                width: "30%",
                borderRadius: "6px",
                outline: "none",
                padding: "15px",
              }}
            />
          </div>
          {/* <div className="quizForm-mainForm-infoRow">
            <p
              className="quizForm-mainForm-label"
              style={{
                fontWeight: 500,
                color: "#171B1E",
                width: "20%",
              }}
            >
              Instruction :{" "}
            </p>
            <textarea
              className="quizForm-mainForm-textarea"
              value={qusInstruction}
              onChange={(event) => {
                setqusInstruction(event.target.value);
              }}
              style={{
                border: "1px solid #d7d7d7",
                width: "30%",
                minHeight: "100px",
                borderRadius: "6px",
                outline: "none",
                padding: "15px",
              }}
            ></textarea>
          </div> */}

          {/* <div className="quizForm-mainForm-infoRow">
                <label className="quizForm-mainForm-label"> question Category : </label>
                <select
                  className="quizForm-mainForm-select"
                  value={questionCategory}
                  onChange={(event) => {
                    setquestionCategory(event.target.value);
                  }}
                >
                  <option value="cat1">cat1</option>
                  <option value="cat2">cat2</option>
                  <option value="cat3">cat3</option>
                  <option value="cat4">cat4</option>
                </select>
              </div> */}
          {qustype == "MCQ" ? (
            <>
              <div className="quizForm-mainForm-infoRow">
                <p
                  className="quizForm-mainForm-label"
                  style={{
                    fontWeight: 500,
                    color: "#171B1E",
                    width: "20%",
                  }}
                >
                  Option 1 :{" "}
                </p>
                <input
                  className="quizForm-mainForm-inputBar"
                  name="text"
                  value={qus5.option1}
                  placeholder="Enter Option"
                  onChange={(event) => {
                    setqus5({ ...qus5, option1: event.target.value });
                  }}
                  style={{
                    border: "1px solid #d7d7d7",
                    width: "30%",
                    borderRadius: "6px",
                    outline: "none",
                    padding: "15px",
                  }}
                />
              </div>
              <div className="quizForm-mainForm-infoRow">
                <p
                  className="quizForm-mainForm-label"
                  style={{
                    fontWeight: 500,
                    color: "#171B1E",
                    width: "20%",
                  }}
                >
                  Option 2 :{" "}
                </p>
                <input
                  className="quizForm-mainForm-inputBar"
                  name="text"
                  value={qus5.option2}
                  placeholder="Enter Option"
                  onChange={(event) => {
                    setqus5({ ...qus5, option2: event.target.value });
                  }}
                  style={{
                    border: "1px solid #d7d7d7",
                    width: "30%",
                    borderRadius: "6px",
                    outline: "none",
                    padding: "15px",
                  }}
                />
              </div>
              <div className="quizForm-mainForm-infoRow">
                <p
                  className="quizForm-mainForm-label"
                  style={{
                    fontWeight: 500,
                    color: "#171B1E",
                    width: "20%",
                  }}
                >
                  Option 3 :{" "}
                </p>
                <input
                  className="quizForm-mainForm-inputBar"
                  name="text"
                  value={qus5.option3}
                  placeholder="Enter Option"
                  onChange={(event) => {
                    setqus5({ ...qus5, option3: event.target.value });
                  }}
                  style={{
                    border: "1px solid #d7d7d7",
                    width: "30%",
                    borderRadius: "6px",
                    outline: "none",
                    padding: "15px",
                  }}
                />
              </div>
              <div className="quizForm-mainForm-infoRow">
                <p
                  className="quizForm-mainForm-label"
                  style={{
                    fontWeight: 500,
                    color: "#171B1E",
                    width: "20%",
                  }}
                >
                  Option 4 :{" "}
                </p>
                <input
                  className="quizForm-mainForm-inputBar"
                  type="text"
                  value={qus5.option4}
                  placeholder="Enter Option"
                  onChange={(event) => {
                    setqus5({ ...qus5, option4: event.target.value });
                  }}
                  style={{
                    border: "1px solid #d7d7d7",
                    width: "30%",
                    borderRadius: "6px",
                    outline: "none",
                    padding: "15px",
                  }}
                />
              </div>

              <div className="quizForm-mainForm-infoRow">
                <p
                  className="quizForm-mainForm-label"
                  style={{
                    fontWeight: 500,
                    color: "#171B1E",
                    width: "20%",
                  }}
                >
                  {" "}
                  Choose the correct Option :{" "}
                </p>
                <select
                  className="quizForm-mainForm-select"
                  value={qus5Answer}
                  onChange={(event) => {
                    setqus5Answer(event.target.value);
                  }}
                  style={{
                    border: "1px solid #1E3758",

                    outline: "none",
                    width: "30%",
                    height: "60px",
                    padding: "10px",
                    fontSize: "18px",
                    borderRadius: "6px",
                  }}
                >
                  <option value="">select option</option>
                  <option value="Option 1">Option 1</option>
                  <option value="Option 2">Option 2</option>
                  <option value="Option 3">Option 3</option>
                  <option value="Option 4">Option 4</option>
                </select>
              </div>
            </>
          ) : (
            " "
          )}
        </form>
        {formCheck && <div> Fill all the Required fields.</div>}
        <div className="quizForm-mainForm-infoRow">
          <button
            className="primary-btn"
            style={{ marginLeft: "auto" }}
            onClick={storedDataOnFirebase}
          >
            Submit
          </button>
        </div>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <ul
              id="questionList"
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                display: "block",
                overflow: "hidden",
                width: "100%",
                listStyleType: "none",
                margin: "0px",
                padding: "0px",
              }}
            >
              {list.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      key={item.id}
                    >
                      <Accordion>
                        <AccordionSummary
                          {...provided.dragHandleProps}
                          expandIcon={<ExpandMoreSvg />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          sx={{
                            backgroundColor: "#1E3758",
                            marginTop: "10px",
                            color: "white",
                            borderRadius: "5px",
                          }}
                        >
                          <Typography>{item.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <MdDelete
                            style={{ float: "right", margin: "20px" }}
                            size={28}
                            onClick={() => del(item)}
                          />
                          <div>
                            {" "}
                            <MdEdit
                              style={{ float: "right", margin: "20px" }}
                              size={28}
                              onClick={() => edit(item)}
                            />
                          </div>
                          <p>{item.type}</p>
                        </AccordionDetails>
                      </Accordion>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </section>
  );
};

export default CreateQuestion;
