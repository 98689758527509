import React, { createRef, useEffect } from "react";
import { useState } from "react";
import $ from "jquery";
import "./CreateCourse.css";
import CreateQuestion from "./CreateQuestion";
import { SvgVector, EditSvg, ExpandMoreSvg, DeleteSVg } from "./icons";
import { functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import { Timestamp } from "firebase/firestore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { ExpandMoreSharp, FunctionsTwoTone } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./createTopic.css";
import DeleteLesson from "./lessonDeletePopUp";
import LoadingScreen from "./lodingScree";
const CreateQuiz = ({ editCourseId, topicId, courseId, lessonId, name }) => {
  const initialList = [];
  const [list, setList] = useState(initialList);
  const createQuizForBasicCourse = httpsCallable(
    functions,
    "createQuizForBasicCourse"
  );
  const addQuestionObjectiveExtraInfo = httpsCallable(
    functions,
    "addQuestionObjectiveExtraInfo"
  );
  const addQuizForLessons = httpsCallable(functions, "addQuizForLessons");
  const addLessonQuizOrder = httpsCallable(functions, "addLessonQuizOrder");

  const getQuizInfo = httpsCallable(functions, "getQuizInfo");
  const getQuestionSetInfo = httpsCallable(functions, "getQuestionSetInfo");
  const deleteBasicObjQuiz = httpsCallable(functions, "deleteBasicObjQuiz");
  // deleteQuiz
  const getAllQuizFromABasicLesson = httpsCallable(
    functions,
    "getAllQuizFromABasicLesson"
  );

  const [deleteQuizId, setDeleteQuizId] = useState("");
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);

  const [loadingScreen, setLoadingScreen] = useState(false);
  const [quizId, setQuizId] = useState("");
  const [editable, setEditable] = useState(false);
  const [checkFailed, setCheckFailed] = useState(false);

  const [quizDetails, setQuizDetails] = useState({
    quizName: "",

    randomizeQuestions: "yes",

    instruction: "flow the ins",
  });
  const [checkErrorInForm, setCheckErrorInForm] = useState({
    quizName: "",

    randomizeQuestions: "yes",

    instruction: "flow the instruction",
  });
  useEffect(() => {
    //console.log("quiz Arr ", editCourseId);
    if (!editCourseId) return;
    setLoadingScreen(true);
    getAllQuizFromABasicLesson({ lessonId: lessonId })
      .then((res) => {
        setLoadingScreen(false);
        for (let i = 0; i < res.data.length; i++) {
          //  console.log(res.data[i]);
          setList((prev) =>
            prev.concat({
              id: res.data[i]?.quizId,
              name: res.data[i]?.quizName,

              random: res.data[i]?.randomizeQuestions,

              instruction: res.data[i]?.instruction,
            })
          );
        }
      })
      .catch((e) => {
        setLoadingScreen(false);
      });
  }, [editCourseId]);

  const quizFormRef = createRef();

  const handleInput = (e) => {
    const { value, name } = e.target;
    setQuizDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  function checkForm() {
    let keys = Object.keys(quizDetails);
    let res = "allIsWell";
    for (let key of keys) {
      if (quizDetails[key] === "") {
        setCheckErrorInForm((prev) => ({ ...prev, [key]: "error" }));
        res = key;
        break;
      }
    }
    return res;
  }

  function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }
    if (lessonId != null) {
      addQuizForLessons({
        lessonId: lessonId,
        associatedQuiz: orderList,
        edit: true,
      });
    }
    //else if (lessonId != null) {
    //   addLessonQuizOrder({
    //     lessonId: lessonId,
    //     quizzes: orderList,
    //     edit: true,
    //   });
    // }
  }
  const addQuiz = async () => {
    let check = checkForm();

    //////

    if (check != "allIsWell") {
      // console.log(check);
      setCheckFailed(true);
      return;
    }
    let nsn = quizDetails.quizName.replaceAll(" ", "");

    let dat = /[^A-Za-z0-9]/.test(nsn);

    if (!dat) {
    } else {
      alert("you can not use any special characters");
      return;
    }

    setCheckErrorInForm({
      quizName: "",

      randomizeQuestions: "yes",

      instruction: "",
    });
    setCheckFailed(false);

    const newList = list.concat({
      id: quizId,
      name: quizDetails.quizName,

      random: quizDetails.randomizeQuestions,

      instruction: quizDetails.instruction,
      lessonId: lessonId,
    });
    createQuizForBasicCourse({
      quizId: quizId,
      created: new Date().toString(),
      quizName: quizDetails.quizName,

      associatedLesson: lessonId,
      courseAssociated: courseId,
      instruction: quizDetails.instruction,
      randomizeQuestions: quizDetails.randomizeQuestions,
    });

    setList(newList);
    saveOrder(newList);
    alert("Added");

    setQuizDetails({
      quizName: "",

      randomizeQuestions: "yes",

      instruction: "",
    });

    toggleForm();
  };

  const toggleForm = (e) => {
    quizFormRef.current.style.display =
      quizFormRef.current.style.display === "none" ? "" : "none";
    setQuizDetails({
      quizName: "",

      randomizeQuestions: "yes",

      instruction: "flow",
    });
    setQuizId("");
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    saveOrder(items);
  }

  function edit(quiz) {
    setEditable(true);
    quizFormRef.current.style.display = "";
    let params = {
      quizName: quiz.name,

      randomizeQuestions: quiz.random,

      instruction: quiz.instruction,
      lessonId: quiz.lessonId,
    };
    setQuizDetails(params);
    setQuizId(quiz.id);
  }

  function updateQuiz() {
    let check = checkForm();
    if (check != "allIsWell") {
      setCheckFailed(true);
      //console.log("update ", check);
      return;
    }
    setCheckFailed(false);

    createQuizForBasicCourse({
      quizId: quizId,
      created: new Date().toString(),
      quizName: quizDetails.quizName,

      associatedLesson: lessonId,
      courseAssociated: courseId,
      instruction: quizDetails.instruction,
      randomizeQuestions: quizDetails.randomizeQuestions,
    });

    for (let i = 0; i < list.length; i++) {
      if (quizId === list[i].id) {
        list[i] = {
          id: quizId,
          name: quizDetails.quizName,

          random: quizDetails.randomizeQuestions,

          instruction: quizDetails.instruction,
        };
      }
    }
    setList(list);
    alert("Updated");
    setEditable(false);
    quizFormRef.current.style.display = "none";
  }

  async function del(quiz) {
    // implement delete api done
    setopenLessonPopUp(true);
    setDeleteQuizId(quiz);
  }
  const delfunction = (quiz) => {
    deleteBasicObjQuiz({ quizId: quiz.id });
    alert("Deleted " + quiz.name);
    const item = [];
    for (let i = 0; i < list.length; i++) {
      if (quiz.id !== list[i].id) {
        item.push(list[i]);
      }
    }
    setList(item);
    saveOrder(item);
  };
  return (
    <>
      <DeleteLesson
        open={openLessonPopUp}
        onClose={() => setopenLessonPopUp(false)}
        delfun={(topic) => {
          delfunction(topic);
        }}
        topicId={deleteQuizId}
      />
      <LoadingScreen
        open={loadingScreen}
        onClose={() => setLoadingScreen(false)}
      />
      <section className="createQuiz-container">
        <button
          className="primary-btn"
          disabled={list.length >= 1 ? true : false}
          onClick={() => {
            setEditable(false);
            toggleForm();
          }}
        >
          {name}
        </button>
        <div
          className="createQuiz-quizForm"
          ref={quizFormRef}
          id={"quizForm"}
          style={{ display: "none" }}
        >
          <div className="createQuiz-heading">Quiz</div>
          <div className="createQuiz-row">
            <label
              className="createQuiz-inputLabel"
              style={{
                fontWeight: 500,
                color: "#171B1E",
                width: "20%",
              }}
            >
              Quiz Name *
            </label>
            <input
              type="text"
              className="createQuiz-inputBar"
              id={topicId + courseId + lessonId + "quizName"}
              style={{
                border: `${
                  checkErrorInForm.quizName == "error"
                    ? "2px solid red"
                    : "1px solid #d7d7d7"
                }`,
                width: "30%",
                outline: "none",
                padding: "15px",
              }}
              name="quizName"
              value={quizDetails.quizName}
              onChange={(event) => {
                handleInput(event);
                setCheckErrorInForm((prev) => ({ ...prev, quizName: "" }));
                if (!editable) {
                  let value = event.target.value.replaceAll(" ", "");
                  let time = new Date().getTime();
                  setQuizId(value + "_" + time);
                }
              }}
            />{" "}
            {checkErrorInForm.quizName == "error" && (
              <span
                style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
              >
                Enter Quiz Name
              </span>
            )}
          </div>

          <div className="createQuiz-row">
            <p
              className="createQuiz-inputLabel"
              style={{
                fontWeight: 500,
                color: "#171B1E",
                width: "20%",
              }}
            >
              Quiz Instruction
            </p>
            <textarea
              style={{
                width: "30%",
                minHeight: "100px",
                borderRadius: "6px",
                outline: "none",
                padding: "15px",
              }}
              type="text"
              className="createQuiz-textBar"
              id={topicId + courseId + lessonId + "instruction"}
              value={quizDetails.instruction}
              name="instruction"
              onChange={handleInput}
            />
          </div>

          <div className="createQuiz-row">
            <p
              className="createQuiz-inputLabel"
              style={{
                fontWeight: 500,
                color: "#171B1E",
                width: "20%",
              }}
            >
              Randomize Questions
            </p>
            <select
              style={{
                width: "30%",
                border: "1px solid #d7d7d7",
                outline: "none",
                height: "40px",
              }}
              className="createQuiz-inputBar"
              id={topicId + courseId + lessonId + "randomQuestion"}
              value={quizDetails.randomizeQuestions}
              name="randomizeQuestions"
              onChange={handleInput}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>

          {checkFailed && <p>Enter the Required Fields</p>}
          <div className="createQuiz-row" style={{ marginTop: "30px" }}>
            <button
              className="primary-btn"
              onClick={!editable ? addQuiz : updateQuiz}
            >
              Save Quiz
            </button>

            <button
              className="secondary-btn"
              style={{ marginLeft: "10px" }}
              onClick={toggleForm}
            >
              Cancel
            </button>
          </div>
        </div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <ul
                id="quizList"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: "block",
                  overflow: "hidden",
                  width: "100%",
                  listStyleType: "none",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                {list.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        key={item.id}
                        className="quiz-dragable"
                      >
                        <Accordion>
                          <AccordionSummary
                            {...provided.dragHandleProps}
                            expandIcon={<ExpandMoreSvg />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                              backgroundColor: "#174C97",
                              color: "white",
                              borderRadius: "5px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <SvgVector />
                              <div className="quiz-accordion-heading">
                                {item.name}
                              </div>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="quiz-accordion-details-head">
                              <p> Quiz -{item.name}</p>
                              <div
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "10px",
                                }}
                                onClick={() => del(item)}
                              >
                                <DeleteSVg size={28} />
                              </div>
                              <div onClick={() => edit(item)}>
                                <EditSvg size={28} />
                              </div>
                            </div>
                            <p>Associated course - </p>

                            <CreateQuestion
                              editCourseId={editCourseId}
                              courseId={courseId}
                              quizId={item.id}
                              lessonId={item.lessonId}
                              type={"basicCourse"}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </section>
    </>
  );
};

export default CreateQuiz;
