import React, { useEffect, useRef } from "react";
import { useState, setState } from "react";
import { setCookie, getCookie } from "../../../Cookies";
import $ from "jquery";
import { db, functions } from "../../../Firebase";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { HelpOutline } from "@material-ui/icons";
import { moment } from "moment";
import { useSelector } from "react-redux";
import Navbar from "../../Component/NavbarTop";
import Sidebar from "../../Component/Sidebar";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as UploadIcon } from "../../AdminComponents/assets/upload.svg";
import { ReactComponent as PdfIcon } from "../../AdminComponents/assets/pdfIcon.svg";
import { ReactComponent as Edit } from "../../AdminComponents/assets/edit.svg";
import { ReactComponent as Delete } from "../../AdminComponents/assets/delete.svg";
import { auth } from "../../../Firebase";
import "./Enrollment.css";

import { t } from "i18next";
import { httpsCallable } from "firebase/functions";
import { Timestamp } from "firebase/firestore";
import UploadCertificate from "./modal/UploadCertificate";
import DeleteCertificate from "./modal/DeleteCertificate";
import GenrateCertificate from "./modal/GenrateCertificate";
import Warning from "./modal/Warning";
import Time from "./time";
import { MenuItem, Select } from "@mui/material";

function GroupEnrollmentEdits() {
  const navigate = useNavigate();
  const getAllCourse = httpsCallable(functions, "getAllCourse");
  const getMapping = httpsCallable(functions, "getMapping");
  const getExternalTrainings = httpsCallable(functions, "getExternalTrainings");
  const getInternalOfflineTrainings = httpsCallable(
    functions,
    "getInternalOfflineTrainings"
  );
  const editGroupEnrollment = httpsCallable(functions, "editGroupEnrollment");
  const getUserEnrollmentInfo = httpsCallable(
    functions,
    "getUserEnrollmentInfo"
  );
  const uploadPdf = httpsCallable(functions, "uploadPdf");
  const updateDueDate = httpsCallable(functions, "updateDueDate");

  const [datesForTimeLine, setDatesForTimeLine] = useState({
    applicationStartDate: "",
    applicationEndDate: "",
    courseStartDate: "",
    courseEndDate: "",
    AppStartDate: "",
    coStartDate: "",
    coEndDate: "",
    AppEndDate: "",
  });

  let dateFormat = "YYYY/MM/DD";
  const [GroupTittle, setGroupTittle] = useState("");

  const [Duration, setDuration] = useState("");
  const fetchGroups = httpsCallable(functions, "fetchGroups");
  const goToHome = () => navigate("/");
  useEffect(() => {
    if (
      getCookie("UID") == null ||
      (getCookie("ROLE") != "admin" && getCookie("ROLE") != "instructor")
    ) {
      goToHome();
      return <></>;
    }
  });

  const location = useLocation();
  const editState = location.state;
  console.log(editState);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [TimePicerForInduction, setTimePicerForInduction] = useState({
    startTime: {
      h: "",
      m: "",
      a: "",
    },

    endTime: {
      h: "",
      m: "",
      a: "",
    },
  });

  // console.log(new Date(TimePicerForInduction.startDate).toISOString());
  const [DatePicerForInduction, setDatePicerForInduction] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [houberSelct, sethouberSelct] = useState([
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
  ]);

  const [minuteSelect, setMinuteSelect] = useState([
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
    40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
    59,
  ]);

  const [DatePicerOnlineTraining, setDatePicerOnlineTraining] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const [dayRange, setDayRange] = useState({
    startDay: "",
    endDay: "",
  });

  const [TimePicerOnlineTraining, setTimePicerOnlineTraining] = useState({
    startTime: {
      h: "",
      m: "",
      a: "",
    },

    endTime: {
      h: "",
      m: "",
      a: "",
    },
  });

  console.log(TimePicerOnlineTraining);
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [generateOpen, setGenerateOpen] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);
  const [enrollmentData, setEnrollmentData] = useState();
  //deleteOpen

  const [training_value, setTraining] = useState("course");
  const [courseId, setCourseId] = useState("");
  const [courseName, setCourseName] = useState("");
  const [groupId, setGroupId] = useState("");
  const [groupName, setGroupName] = useState("");
  const [dueDate, setDueDate] = useState("");
  // const [setNotification, setSetNotification] = useState("no");
  const [notiFreq, setNotiFreq] = useState("");
  const [completed, setCompleted] = useState("not completed");
  const [enrollmentId, setEnrollmentId] = useState("Ram");
  const [groupInfo, setGroupInfo] = useState(null);
  const [groupUsers, setGroupUsers] = useState([]);
  const [courseInfo, setCourseInfo] = useState(null);
  const [internalOfflineInfo, setInternalOfflineInfo] = useState(null);
  const [externalTrainingInfo, setExternalTrainingInfo] = useState(null);
  const [clickedUser, setClickedUser] = useState({ userId: "", userName: "" });
  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const { clrs } = useSelector((state) => state.createClr);
  const setTrainingRef = useRef();
  const setCourseNameRef = useRef();
  const setGroupNameRef = useRef();
  const dropRef = useRef(null);
  const inputRef = useRef(null);
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(false);

  const [courseVersion, setCorseVersion] = useState([]);

  const [version, setVersion] = useState("");
  useEffect(() => {
    if (editState) {
      setEdit(true);
      console.log(editState);
      setTraining(editState?.courseType);
      setCompleted(editState?.completed);
      setCourseId(editState?.courseId);
      setCourseName(editState?.courseName);
      setGroupId(editState?.groupId);
      setGroupName(editState?.groupName);
      setNotiFreq(editState?.notiFreq);
      setGroupTittle(editState?.tittle);
      setDuration(editState?.Duration);
      setTimePicerForInduction(editState?.TimeForInduction);
      setTimePicerOnlineTraining(editState?.TimeOnlineTraining);
      setDatePicerForInduction({
        startDate: new Date(editState?.DateForInduction?.startDate),
        // endDate: new Date(editState?.DateForInduction?.endDate).toLocaleString(),
      });

      setDatePicerOnlineTraining({
        startDate: new Date(editState?.DateOnlineTraining?.startDate),
        endDate: new Date(editState?.DateOnlineTraining?.endDate),
      });
      // new Date(TimePicerForInduction[1]).toLocaleString()
      setDayRange(editState?.dayForOnlineTraining);
      // tittle: data.tittle,
      // Duration: data.Duration,
      // DateForInduction: data.DateForInduction,
      // TimeForInduction: data.TimeForInduction,
      // DateOnlineTraining: data.DateOnlineTraining,
      // TimeOnlineTraining: data.TimeOnlineTraining,
      setEnrollmentId(editState?.enrollmentId);
      setFileList(editState?.notes);
      setGroupNameRef.current.disabled = true; // should not edit
      setCourseNameRef.current.disabled = true; // should not edit

      setDatesForTimeLine({
        applicationEndDate: editState.applicationEndDate,
        applicationStartDate: editState.applicationStartDate,

        courseStartDate: editState.startDate,
        courseEndDate: editState.endDate,
        AppStartDate: Timestamp.fromMillis(
          editState?.applicationStartDate?._seconds * 1000
        )
          .toDate()
          .toISOString()
          .split("T")[0],
        coStartDate: Timestamp.fromMillis(editState?.startDate?._seconds * 1000)
          .toDate()
          .toISOString()
          .split("T")[0],
        coEndDate: Timestamp.fromMillis(editState?.endDate?._seconds * 1000)
          .toDate()
          .toISOString()
          .split("T")[0],
        AppEndDate: Timestamp.fromMillis(
          editState?.applicationEndDate?._seconds * 1000
        )
          .toDate()
          .toISOString()
          .split("T")[0],
      });

      let dueDateEdit = Timestamp.fromMillis(
        editState.dueDate?._seconds * 1000
      ).toDate();
      // console.log(dueDateEdit?.toISOString().split("T")[0]);
      // setDueDate(dueDateEdit?.toISOString().split("T")[0]);
      setLoading(true);
    }
  }, []);
  console.log(datesForTimeLine);
  useEffect(() => {
    fetchGroups().then((result) => {
      if (result.data?.error) {
        setGroupInfo([]);
        return;
      }
      setGroupInfo(
        result.data.filter((ele) => ele.archived == false || !ele.archived)
      );
    });
  }, []);

  useEffect(() => {
    getAllCourse().then((result) => {
      if (result.data.error) {
        return;
      }
      setCourseInfo(result?.data?.data);
    });
  }, []);

  var today = new Date().toISOString().split("T")[0];
  const allIsWell = () => {
    if (
      training_value == "" ||
      groupId == "" ||
      dueDate == "" ||
      groupName == "" ||
      courseId == "" ||
      courseName == ""
    ) {
      return false;
    }
    return true;
  };

  const handleOnSave = async (e) => {
    e.preventDefault();

    // if (!allIsWell()) {
    //   setError(true);
    //   return;
    // }

    setDisable(true);

    let GGId = "";
    if (enrollmentId === "Ram") {
      GGId = `${groupId}_${courseId}`;
      console.log(GGId);
      setEnrollmentId(GGId);
    } else {
      GGId = enrollmentId;
    }

    editGroupEnrollment({
      applicationStartDate: new Date(datesForTimeLine.AppStartDate),
      applicationEndDate: new Date(datesForTimeLine.AppEndDate),
      courseStartDate: new Date(datesForTimeLine.coStartDate),
      courseEndDate: new Date(datesForTimeLine.coEndDate),
      groupId: groupId,
      // createdOn: Timestamp.fromDate(new Date()),
      tittle: GroupTittle,
      Duration: Duration,
      DateForInduction: DatePicerForInduction,
      TimeForInduction: TimePicerForInduction,
      DateOnlineTraining: DatePicerOnlineTraining,
      TimeOnlineTraining: TimePicerOnlineTraining,
      dayForOnlineTraining: dayRange,
      completed: false,
      groupName: groupName,

      courseId: courseId,
      courseName: courseName,
      enrollmentId: GGId,
    }).then((result) => {
      navigate("/admin/groupEnrollment");
    });
  };

  console.log(groupUsers);

  const dataToStringFunction = (date, type, hum) => {
    let dd = Timestamp.fromDate(new Date(date));
    setDatesForTimeLine({ ...datesForTimeLine, [type]: dd, [hum]: date });
    console.log();
  };

  // let d = new Date(TimePicerForInduction[0]).toLocaleString();
  // let ds = new Date(TimePicerForInduction[1]).toLocaleString();
  // console.log(DatePicerOnlineTraining);

  return (
    <div className="section" style={{ backgroundColor: "#e5e5e5" }}>
      <Navbar page={t("Enrollments")} />
      <div className="createcourse-main">
        <Sidebar page={"enrollments"} hidepage={true} />
        <div className="admin-overdue">
          <div className="admin-overdue-top">
            <div className="admin-overdue-top-item">
              {t("Add New / Edit Group Enrollments")}
            </div>
          </div>

          <div className="admin-overdue-grop-cnt">
            <div className="admin-enrollment-main">
              <div>
                <div id="admin-course-main-top-id-grp">
                  <div>
                    <div
                      className="admin-course-main-top-inputlable"
                      style={{ margin: "0" }}
                    >
                      {t("Tittle")}*
                    </div>
                    <div>
                      <input
                        className="admin-course-main-top-select"
                        id="course-ids"
                        placeholder="Enter Tittle"
                        value={GroupTittle}
                        onChange={(e) => {
                          setGroupTittle(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div id="admin-course-main-top-id-grp">
                  <div>
                    <div
                      className="admin-course-main-top-inputlable"
                      style={{ margin: "0" }}
                    >
                      {t("Duration")}*(in months)
                    </div>
                    <div>
                      <input
                        className="admin-course-main-top-select"
                        id="course-ids"
                        type="number"
                        placeholder="Enter Batch Duration"
                        value={Duration}
                        onChange={(e) => {
                          setDuration(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div id="admin-course-main-top-id-grp">
                  <div>
                    <div
                      className="admin-course-main-top-inputlable"
                      style={{ margin: "0" }}
                    >
                      Program Induction Date *
                    </div>
                    <div>
                      <div
                        className="admin-course-main-top-select"
                        style={{
                          width: "60vw",
                          display: "grid",
                          gridTemplateColumns: "30% 30% 30%",
                        }}
                      >
                        <div>
                          {" "}
                          Start date
                          <DatePicker
                            selected={DatePicerForInduction.startDate}
                            placeholderText="Start Date"
                            onChange={(date) => {
                              setDatePicerForInduction((prev) => ({
                                ...prev,
                                startDate: date,
                              }));
                            }}
                            selectsStart
                            startDate={DatePicerForInduction.startDate}
                            endDate={DatePicerForInduction.endDate}
                          />{" "}
                        </div>

                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr",
                            gridTemplateRows: "20px 20px",
                            gridGap: "6px",
                          }}
                        >
                          {" "}
                          <div> Time From </div>
                          <div>
                            <Select
                              MenuProps={{
                                sx: {
                                  maxHeight: "200px",
                                },
                              }}
                              sx={{
                                height: "20px",
                              }}
                              value={TimePicerForInduction.startTime.h}
                              onChange={(e) => {
                                let temp = TimePicerForInduction;
                                temp.startTime.h = e.target.value;

                                setTimePicerForInduction({});
                                setTimePicerForInduction((prev) => ({
                                  ...prev,
                                  ...temp,
                                }));
                              }}
                              setTimePicerForInduction
                            >
                              <MenuItem value="" disabled>
                                <em>select hour</em>
                              </MenuItem>
                              {houberSelct.map((e) => (
                                <MenuItem value={e}> {e}</MenuItem>
                              ))}
                            </Select>
                            <Select
                              sx={{
                                height: "20px",
                              }}
                              MenuProps={{
                                sx: {
                                  maxHeight: "200px",
                                },
                              }}
                              value={TimePicerForInduction.startTime.m}
                              onChange={(e) => {
                                let temp = TimePicerForInduction;
                                temp.startTime.m = e.target.value;

                                setTimePicerForInduction({});
                                setTimePicerForInduction((prev) => ({
                                  ...prev,
                                  ...temp,
                                }));
                              }}
                            >
                              <MenuItem value="" disabled>
                                <em>select minute</em>
                              </MenuItem>
                              {minuteSelect.map((e) => (
                                <MenuItem value={e}>{e}</MenuItem>
                              ))}
                            </Select>
                            <Select
                              sx={{
                                height: "20px",
                              }}
                              value={TimePicerForInduction.startTime.a}
                              onChange={(e) => {
                                let temp = TimePicerForInduction;
                                temp.startTime.a = e.target.value;

                                setTimePicerForInduction({});
                                setTimePicerForInduction((prev) => ({
                                  ...prev,
                                  ...temp,
                                }));
                              }}
                            >
                              <MenuItem value={"AM"}> AM</MenuItem>
                              <MenuItem value={"PM"}> PM</MenuItem>
                            </Select>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr",
                            gridTemplateRows: "20px 20px",
                            gridGap: "6px",
                          }}
                        >
                          {" "}
                          <div> Time To </div>
                          <div>
                            <Select
                              MenuProps={{
                                sx: {
                                  maxHeight: "200px",
                                },
                              }}
                              sx={{
                                height: "20px",
                              }}
                              value={TimePicerForInduction.endTime.h}
                              onChange={(e) => {
                                let temp = TimePicerForInduction;
                                temp.endTime.h = e.target.value;

                                setTimePicerForInduction({});
                                setTimePicerForInduction((prev) => ({
                                  ...prev,
                                  ...temp,
                                }));
                              }}
                            >
                              {" "}
                              <MenuItem value="" disabled>
                                <em>select hour</em>
                              </MenuItem>
                              {houberSelct.map((e) => (
                                <MenuItem value={e}> {e}</MenuItem>
                              ))}
                            </Select>
                            <Select
                              sx={{
                                height: "20px",
                              }}
                              MenuProps={{
                                sx: {
                                  maxHeight: "200px",
                                },
                              }}
                              value={TimePicerForInduction.endTime.m}
                              onChange={(e) => {
                                let temp = TimePicerForInduction;
                                temp.endTime.m = e.target.value;

                                setTimePicerForInduction({});
                                setTimePicerForInduction((prev) => ({
                                  ...prev,
                                  ...temp,
                                }));
                              }}
                            >
                              {" "}
                              <MenuItem value="" disabled>
                                <em>select minute</em>
                              </MenuItem>
                              {minuteSelect.map((e) => (
                                <MenuItem value={e}>{e}</MenuItem>
                              ))}
                            </Select>
                            <Select
                              sx={{
                                height: "20px",
                              }}
                              value={TimePicerForInduction.endTime.a}
                              onChange={(e) => {
                                let temp = TimePicerForInduction;
                                temp.endTime.a = e.target.value;

                                setTimePicerForInduction({});
                                setTimePicerForInduction((prev) => ({
                                  ...prev,
                                  ...temp,
                                }));
                              }}
                            >
                              <MenuItem value={"AM"}> AM</MenuItem>
                              <MenuItem value={"PM"}> PM</MenuItem>
                            </Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="admin-course-main-top-id-grp">
                  <div>
                    <div
                      className="admin-course-main-top-inputlable"
                      style={{ margin: "0" }}
                    >
                      {t("Instructor-Led Online Training Dates")}*
                    </div>
                    <div>
                      <div
                        className="admin-course-main-top-select"
                        style={{
                          width: "80vw",
                          display: "grid",
                          gridTemplateColumns: "25% 25% 25% 25%",
                        }}
                      >
                        <div>
                          {" "}
                          Start date
                          <DatePicker
                            selected={DatePicerOnlineTraining.startDate}
                            placeholderText="Start Date"
                            onChange={(date) => {
                              setDatePicerOnlineTraining((prev) => ({
                                ...prev,
                                startDate: date,
                              }));
                            }}
                            selectsStart
                            startDate={DatePicerOnlineTraining.startDate}
                            endDate={DatePicerOnlineTraining.endDate}
                          />{" "}
                        </div>

                        <div>
                          {" "}
                          End date
                          <DatePicker
                            selected={DatePicerOnlineTraining.endDate}
                            placeholderText="End Date"
                            onChange={(date) => {
                              setDatePicerOnlineTraining((prev) => ({
                                ...prev,
                                endDate: date,
                              }));
                            }}
                            selectsStart
                            startDate={DatePicerOnlineTraining.startDate}
                            endDate={DatePicerOnlineTraining.endDate}
                          />{" "}
                        </div>

                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr",
                            gridTemplateRows: "20px 20px",
                            gridGap: "6px",
                          }}
                        >
                          <div> Time From </div>
                          <div>
                            <Select
                              MenuProps={{
                                sx: {
                                  maxHeight: "200px",
                                },
                              }}
                              sx={{
                                height: "20px",
                              }}
                              value={TimePicerOnlineTraining.startTime.h}
                              onChange={(e) => {
                                let temp = TimePicerOnlineTraining;
                                temp.startTime.h = e.target.value;

                                setTimePicerOnlineTraining({});
                                setTimePicerOnlineTraining((prev) => ({
                                  ...prev,
                                  ...temp,
                                }));
                              }}
                            >
                              <MenuItem value="" disabled>
                                <em>select hour</em>
                              </MenuItem>
                              {houberSelct.map((e) => (
                                <MenuItem value={e}> {e}</MenuItem>
                              ))}
                            </Select>
                            <Select
                              sx={{
                                height: "20px",
                              }}
                              value={TimePicerOnlineTraining.startTime.m}
                              onChange={(e) => {
                                let temp = TimePicerOnlineTraining;
                                temp.startTime.m = e.target.value;
                                console.log(temp);

                                setTimePicerOnlineTraining({});
                                setTimePicerOnlineTraining((prev) => ({
                                  ...prev,
                                  ...temp,
                                }));
                              }}
                              MenuProps={{
                                sx: {
                                  maxHeight: "200px",
                                },
                              }}
                            >
                              <MenuItem value="" disabled>
                                <em>select minute</em>
                              </MenuItem>
                              {minuteSelect.map((e) => (
                                <MenuItem value={e}>{e}</MenuItem>
                              ))}
                            </Select>
                            <Select
                              sx={{
                                height: "20px",
                              }}
                              value={TimePicerOnlineTraining.startTime.a}
                              onChange={(e) => {
                                let temp = TimePicerOnlineTraining;
                                temp.startTime.a = e.target.value;
                                setTimePicerOnlineTraining({});
                                setTimePicerOnlineTraining((prev) => ({
                                  ...prev,
                                  ...temp,
                                }));
                              }}
                            >
                              {/* <MenuItem value="" disabled>
                                <em>select am:pm</em>
                              </MenuItem> */}
                              <MenuItem value={"AM"}> AM</MenuItem>
                              <MenuItem value={"PM"}> PM</MenuItem>
                            </Select>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr",
                            gridTemplateRows: "20px 20px",
                            gridGap: "6px",
                          }}
                        >
                          {" "}
                          <div> Time To </div>
                          <div>
                            <Select
                              MenuProps={{
                                sx: {
                                  maxHeight: "200px",
                                },
                              }}
                              value={TimePicerOnlineTraining.endTime.h}
                              onChange={(e) => {
                                let temp = TimePicerOnlineTraining;
                                temp.endTime.h = e.target.value;
                                setTimePicerOnlineTraining({});
                                setTimePicerOnlineTraining((prev) => ({
                                  ...prev,
                                  ...temp,
                                }));
                              }}
                              sx={{
                                height: "20px",
                              }}
                            >
                              <MenuItem value="" disabled>
                                <em>select hour</em>
                              </MenuItem>
                              {houberSelct.map((e) => (
                                <MenuItem value={e}> {e}</MenuItem>
                              ))}
                            </Select>{" "}
                            <Select
                              sx={{
                                height: "20px",
                              }}
                              value={TimePicerOnlineTraining.endTime.m}
                              onChange={(e) => {
                                let temp = TimePicerOnlineTraining;
                                temp.endTime.m = e.target.value;
                                setTimePicerOnlineTraining({});
                                setTimePicerOnlineTraining((prev) => ({
                                  ...prev,
                                  ...temp,
                                }));
                              }}
                              MenuProps={{
                                sx: {
                                  maxHeight: "200px",
                                },
                              }}
                            >
                              <MenuItem value="" disabled>
                                <em>select minute</em>
                              </MenuItem>
                              {minuteSelect.map((e) => (
                                <MenuItem value={e}>{e}</MenuItem>
                              ))}
                            </Select>
                            <Select
                              sx={{
                                height: "20px",
                              }}
                              value={TimePicerOnlineTraining.endTime.a}
                              onChange={(e) => {
                                let temp = TimePicerOnlineTraining;
                                temp.endTime.a = e.target.value;
                                setTimePicerOnlineTraining({});
                                setTimePicerOnlineTraining((prev) => ({
                                  ...prev,
                                  ...temp,
                                }));
                              }}
                            >
                              <MenuItem value="" disabled>
                                <em>select am:pm</em>
                              </MenuItem>
                              <MenuItem value={"AM"}> AM</MenuItem>
                              <MenuItem value={"PM"}> PM</MenuItem>
                            </Select>
                          </div>
                        </div>
                      </div>

                      <div
                        className="admin-course-main-top-select"
                        // value={TimePicerOnlineTraining}
                        // onChange={setTimePicerOnlineTraining}
                        style={{
                          display: "grid",
                          gridTemplateColumns: "50% 50%",
                          height: "50px",
                          padding: "2px 0px 2px 0px",
                        }}
                      >
                        <select
                          onChange={(e) => {
                            setDayRange((prev) => ({
                              ...prev,
                              startDay: e.target.value,
                            }));
                          }}
                          value={dayRange?.startDay}
                        >
                          <option value=""> Start day </option>
                          <option value="Sun"> Sunday</option>
                          <option value="Mon">Monday </option>
                          <option value="Tue"> Tuesday</option>
                          <option value="Wed">Wednesday </option>
                          <option value="Thur"> Thursday</option>
                          <option value="Fri">Friday </option>
                          <option value="Sat"> Saturday</option>
                        </select>
                        <select
                          onChange={(e) => {
                            setDayRange((prev) => ({
                              ...prev,
                              endDay: e.target.value,
                            }));
                          }}
                          value={dayRange?.endDay}
                        >
                          <option value=""> End day </option>
                          <option value="Sun"> Sunday</option>
                          <option value="Mon">Monday </option>
                          <option value="Tue"> Tuesday</option>
                          <option value="Wed">Wednesday </option>
                          <option value="Thur"> Thursday</option>
                          <option value="Fri">Friday </option>
                          <option value="Sat"> Saturday</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="admin-course-main-top-id-grp">
                  <div>
                    <div
                      className="admin-course-main-top-inputlable"
                      style={{ margin: "0" }}
                    >
                      {t("CourseName")}*
                    </div>
                    <div>
                      <select
                        className="admin-course-main-top-select"
                        id="course-ids"
                        defaultValue=""
                        value={courseId + "---" + courseName}
                        onChange={(e) => {
                          const [id, name] = e.target.value.split("---");
                          setCourseId(id);

                          setCourseName(name);
                        }}
                        ref={setCourseNameRef}
                      >
                        <option style={{ fontWeight: "700" }} value="">
                          Select A Value From The List Below
                        </option>

                        {courseInfo?.map((item, id) => (
                          <option
                            value={item.courseId + "---" + item.courseName}
                            key={id}
                          >
                            {item.courseName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div id="admin-course-main-top-id-grp">
                  <div
                    className="admin-course-main-top-inputlable"
                    style={{ margin: "0" }}
                  >
                    {t("GroupName")}*
                  </div>
                  <div>
                    <select
                      className="admin-course-main-top-select"
                      id="group-ids"
                      value={groupId + "---" + groupName}
                      onChange={(e) => {
                        const [id, name] = e.target.value.split("---");
                        setGroupId(id);
                        setGroupName(name);
                      }}
                      ref={setGroupNameRef}
                    >
                      <option style={{ fontWeight: "700" }} value="">
                        Select A Value From The List Below
                      </option>

                      {groupInfo?.map((item, id) => (
                        <option
                          value={item.groupId + "---" + item.groupName}
                          key={id}
                        >
                          {item.groupName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div id="admin-course-main-top-id-grp">
                  <div
                    className="admin-course-main-top-inputlable"
                    style={{ margin: "0" }}
                  >
                    {t("Course Start Date")}*
                  </div>
                  <input
                    type="date"
                    name="due-date"
                    value={datesForTimeLine.coStartDate}
                    min={today}
                    className="admin-course-main-top-input"
                    onChange={(e) =>
                      dataToStringFunction(
                        e.target.value,
                        "courseStartDate",
                        "coStartDate"
                      )
                    }
                  />
                </div>
                <div id="admin-course-main-top-id-grp">
                  <div
                    className="admin-course-main-top-inputlable"
                    style={{ margin: "0" }}
                  >
                    {t("Course End Date")}*
                  </div>
                  <input
                    type="date"
                    name="due-date"
                    value={datesForTimeLine.coEndDate}
                    min={today}
                    className="admin-course-main-top-input"
                    onChange={(e) =>
                      dataToStringFunction(
                        e.target.value,
                        "courseEndDate",
                        "coEndDate"
                      )
                    }
                  />
                </div>
                <div id="admin-course-main-top-id-grp">
                  <div
                    className="admin-course-main-top-inputlable"
                    style={{ margin: "0" }}
                  >
                    {t("Application Start Date")}*
                  </div>
                  <input
                    type="date"
                    name="due-date"
                    value={datesForTimeLine.AppStartDate}
                    min={today}
                    className="admin-course-main-top-input"
                    onChange={(e) =>
                      dataToStringFunction(
                        e.target.value,
                        "applicationStartDate",
                        "AppStartDate"
                      )
                    }
                  />
                </div>
                <div id="admin-course-main-top-id-grp">
                  <div
                    className="admin-course-main-top-inputlable"
                    style={{ margin: "0" }}
                  >
                    {t("Application End Date")}*
                  </div>
                  <input
                    type="date"
                    name="due-date"
                    value={datesForTimeLine.AppEndDate}
                    min={today}
                    className="admin-course-main-top-input"
                    onChange={(e) =>
                      dataToStringFunction(
                        e.target.value,
                        "applicationEndDate",
                        "AppEndDate"
                      )
                    }
                  />
                </div>
              </div>
            </div>

            {error && (
              <div
                style={{ marginLeft: "20px", color: "red", fontSize: "20px" }}
              >
                Fill all the fileds!
              </div>
            )}

            <div style={{ display: "grid", justifyContent: "right" }}>
              <div className="courselist-main-cnt-back-btn">
                <button className="clear-btn" onClick={() => navigate(-1)}>
                  {t("Cancel")}
                </button>
                <button
                  className="primary-btn"
                  style={{
                    cursor: `${disable ? "wait" : "pointer"}`,
                    margin: "1vw",
                  }}
                  disabled={disable}
                  onClick={handleOnSave}
                >
                  {t(disable ? "Saving..." : "Save")}
                </button>
                <Warning
                  open={warningOpen}
                  enrollmentData={enrollmentData}
                  onClose={() => setWarningOpen(false)}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ height: "100%", width: "6vw", backgroundColor: "white" }}
        ></div>
        <div className="courselist-main-cnt-back">
          <div className="courselist-main-cnt-back-btn">
            <Link to={"/admin/groupEnrollment"}>
              <button className="secondary-btn" onClick={() => navigate(-1)}>
                {t("Back")}
              </button>{" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupEnrollmentEdits;
