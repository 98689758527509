import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getCookie, setCookie } from "../../../Cookies";
import { db } from "../../../Firebase";

import classes from "../../styles/LoginpageStyle/LoginPage.module.css";

import loginLogo from "../../../utilities/images/digvidya.png";
import loginImg from "../../../utilities/images/Frame.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const { createTheme } = require("@mui/material");

const loginTheme = createTheme({
  typography: {
    fontFamily: ["Nunito", "sans-serif"].join(","),
  },
});

function ForgotPassword() {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const goToUserDashboard = () => navigate("/user/home");
  const goToAdminDashboard = () => navigate("/admin");
  const goToInstructorDashboard = () => navigate("/instructor");
  const [linkSent, setLinkSet] = useState(false);
  const [email, setEmail] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [userNameError, setUserNameError] = useState("");

  const [notification, setNotification] = useState("");
  const [loading, setLoading] = useState(false);

  ///========visibility password=======//

  const clearErrors = () => {
    setUserNameError("");
    setPasswordError("");
  };

  ///if user is logged in, redirect to user dashboard
  useEffect(() => {
    if (getCookie("ROLE") === "admin") {
      goToAdminDashboard();
      return;
    }
    if (getCookie("ROLE") === "instructor") {
      goToInstructorDashboard();
      return;
    }
    if (getCookie("ROLE") === "user") {
      goToUserDashboard();
      return;
    }
  }, []);

  const handleForgot = () => {
    clearErrors();
    if (email == "") return setNotification("Please enter your username");
    setLoading(true);
    const auth = getAuth();

    sendPasswordResetEmail(auth, email)
      .then((userCredential) => {
        alert("Reset Link Sent")
        setLoading(false);
        setLinkSet(true);
      })
      .catch((err) => {
        setLoading(false);
        console.log("error", err.code);
        if (err.code == "auth/user-not-found") return setNotification("Email not found");
        else if (err.code == "auth/too-many-requests")
          return setNotification("Too many requests. Please try again later");
        switch (err.code) {
          case "auth/invalid-username":
          case "auth/user-disabled":
          case "auth/user-not-found":
            setUserNameError(err.message);
            break;
          case "auth/wrong-password":
            setPasswordError(err.message);
            break;
        }
      });
  };

  return (
    <ThemeProvider theme={loginTheme}>
      <Box>
        <Container
          style={{ height: "100vh" ,gap:40}}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "40%",
           
              minHeight: "610px",
              maxHeight:"610px",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center",height:"96px",width:"270px" }}>
              <img className={classes.logo} src={loginLogo} alt="" />
            </Box>
            {!linkSent ? (
              <Box sx={{ p: 7,padding:"0px" }}>
                <Box>
                  <Typography variant="h3" sx={{ color: "#1E3758",  fontWeight: 600 }}>
                    Forgot Password
                  </Typography>
                  <Typography sx={{ my: 2, color: "#1E3758" }}>
                    Provide Email to send Password reset link
                  </Typography>
                </Box>
                <form action="">
                  <Box>
                    <Typography sx={{ fontWeight: "700", mb: 1, color: "#1E3758" }}>
                      Enter Your Email
                    </Typography>
                    <div
                      style={{
                      
                        borderRadius: "6px",
                        padding: "10px 15px",
                        border: "1px solid #1E3758",
                        outline: "none",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="text"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="name@domain.com"
                        style={{
                          width: "100%",
                          padding: "7px 15px",
                          border: "none",
                          outline: "none",
                        }}
                      />
                    </div>
                  </Box>
                  <Box sx={{ color: "red", marginTop: "10px" }}>
                    {notification && <span>{notification}</span>}
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    <Button
                      onClick={handleForgot}
                      style={{
                        background: "#D55628",
                        color: "#fff",
                        boxShadow: "0px 20px 15px rgba(213, 86, 40, 0.15)",
                      }}
                      sx={{
                        textTransform: "none",
                        width: "100%",
                        py: 1,
                        borderRadius: "12px",
                        fontSize: "16px",
                        fontWeight: 700,
                      }}
                    >
                      {loading ? "Sending ..." : "Send Reset Link"}
                    </Button>
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    <Link to="/">
                      <Typography
                        style={{ color: "#1E3758", fontWeight: 600 }}
                        sx={{ textAlign: "center" }}
                      >
                        Goto Login
                      </Typography>
                    </Link>
                  </Box>
                </form>
              </Box>
            ) : (
              <Box sx={{ p: 7 }}>
                <Box>
                  <Typography variant="h3" sx={{ color: "#1E3758", fontWeight: 700 }}>
                    Password Reset Link sent
                  </Typography>
                  <Typography sx={{ my: 2, color: "#1E3758" ,fontWeight:400 }}>
                    Please check your inbox and spam folder for the link.
                  </Typography>
                </Box>
                <Box sx={{ mt: 3 }}>
                  <Link to="/">
                    <Typography
                      style={{ color: "#1E3758", fontWeight: 600 }}
                      sx={{ textAlign: "center" }}
                    >
                      Goto Login
                    </Typography>
                  </Link>
                </Box>
              </Box>
            )}
          </Box>
          <Box  sx={{
              width: "60%",
          
              borderRadius: "10px",
              minHeight:"610px",
              maxHeight: "610px",
            }}>
            <div style={{ maxHeight: "610px",}}> 
            <img width="100%" height="605px" style={{ borderRadius: "10px",marginLeft:"2vw"}} src={loginImg} alt="" /> </div>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default ForgotPassword;
