import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { Navigate, useNavigate } from "react-router";
import Img from "./lodingimg.gif";
// import { functions } from "../../../../Firebase";

const LoadingScreen = (props) => {
  const { onClose, open } = props;
  const handleClose = () => {
    setInputId("");
    onClose();
  };
  const [dueDate, setDueDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [loading, setLoading] = useState(false);

  const [inputId, setInputId] = useState("");

  let navigater = useNavigate();

  return (
    <Dialog
      fullWidth={false}
      maxWidth="150px"
      PaperProps={{
        style: {
          borderRadius: "20px",
          height: "140px",
          padding: "16px",
        },
      }}
      onClose={() => {
        if (loading) return;
        handleClose();
      }}
      open={open}
    >
      <img src={Img} height="100px" />
    </Dialog>
  );
};

export default LoadingScreen;
