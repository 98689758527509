import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";
import { useSelector } from "react-redux";

const ALlStatuspage = ({ uid, data, flag }) => {
 // console.log(data);
  const [st, setSt] = useState({});

  useEffect(() => {
  //  console.log("ds");
    setSt({});
    setSt(data);
    
  }, [data]);

  const { clrs } = useSelector((state) => state.createClr);

  return (
    <div className="admin-overdue-bottom" style={{ padding: "20px" }}>
      
      <div style={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: 500 }}>
        {t("User Status")}
      </div>

      <div
        style={{
          fontFamily: "Poppins",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",

            marginTop: "15px",
          }}
        >
          <div
            className="admin-overdue-mid-user-view-cnt"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              fontSize: "18px",
              fontFamily: "Poppins",
            }}
          >
            <div> {t("Objective Test Status")} :</div>
            <div className="admin-overdue-mid-user-view-txt-dim">
              {st?.objectiveTestStatus}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginTop: "15px",
          }}
        >
          <div
            className="admin-overdue-mid-user-view-cnt"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              fontSize: "18px",
              fontFamily: "Poppins",
            }}
          >
            <div> {t("Subjective Test Status")} :</div>
            <div className="admin-overdue-mid-user-view-txt-dim">
              {st?.subjectiveTestStatus}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginTop: "15px",
          }}
        >
          <div
            className="admin-overdue-mid-user-view-cnt"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              fontSize: "18px",
              fontFamily: "Poppins",
            }}
          >
            <div> {t("Registration Status")} :</div>
            <div className="admin-overdue-mid-user-view-txt-dim">
              {st?.documentsVerificationStatus}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginTop: "15px",
          }}
        >
          <div
            className="admin-overdue-mid-user-view-cnt"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              fontSize: "18px",
              fontFamily: "Poppins",
            }}
          >
            <div> {t("Payment Status")} :</div>
            <div className="admin-overdue-mid-user-view-txt-dim">
              {st?.paymentStatus}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginTop: "15px",
          }}
        >
          <div
            className="admin-overdue-mid-user-view-cnt"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              fontSize: "18px",
              fontFamily: "Poppins",
            }}
          >
            <div> {t("Documents Status")} :</div>
            <div className="admin-overdue-mid-user-view-txt-dim">
              {st?.agreementStatus}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginTop: "15px",
          }}
        >
          <div
            className="admin-overdue-mid-user-view-cnt"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              fontSize: "18px",
              fontFamily: "Poppins",
            }}
          >
            <div> {t("Approval Status")} :</div>
            <div className="admin-overdue-mid-user-view-txt-dim">
              {st.approvalStatus}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ALlStatuspage;
