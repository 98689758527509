import React, { useEffect, useRef } from "react";
import { useState } from "react";
// import $ from "jquery";
import { db, functions, storage } from "../../../Firebase";

import { httpsCallable } from "firebase/functions";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { Timestamp } from "firebase/firestore";
import "./Form.css";
import "../StyleAdminComponents/GroupList.css";
import { useNavigate } from "react-router-dom";
import { EditSvg, DeleteSVg } from "./icons";
import { width } from "@mui/system";
import Upload from "./uploadPop";
import { async } from "@firebase/util";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";

const BasicCourse = ({ editCourseInfo, setIsCourseIdSet, checkEditFlag }) => {
  //Course
  const navigate = useNavigate();
  const createBasicCourse = httpsCallable(functions, "createBasicCourse");
  const addBasicCourseExtraInfo = httpsCallable(
    functions,
    "addBasicCourseExtraInfo"
  );
  const getCourses = httpsCallable(functions, "getCourses");
  const fetchCertificateTemplate = httpsCallable(
    functions,
    "fetchCertificateTemplate"
  );
  const getAdminAndInstructorList = httpsCallable(
    functions,
    "getAdminAndInstructorList"
  );

  const getWhyDoYouWantToJoin = httpsCallable(
    functions,
    "getWhyDoYouWantToJoin"
  );
  const deletebasicCourse = httpsCallable(functions, "deletebasicCourse");
  const getCategoryFromConfig = httpsCallable(
    functions,
    "getCategoryFromConfig"
  );

  const addCategoryDataInArr = httpsCallable(functions, "addCategoryDataInArr");

  const removeDataFromCategoryArr = httpsCallable(
    functions,
    "removeDataFromCategoryArr"
  );

  const [categoryDataFromCourse, setCategoryDataFromCourse] = useState([]);
  const [categoryDataFromConfig, setCategoryDataFromConfig] = useState([]);
  const [courseName, setCourseName] = useState("");
  const [add, setAdd] = useState(false);

  const [categoryArr, setCategoryArr] = useState([]);
  const [selectedCateGory, setSelectedCateGory] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [defaultCategory, setDefaultCategory] = useState("");

  const [instruction, setInstructions] = useState("");

  const [progressUpload, setProgressUpload] = useState(0);
  const [openUploadPopUp, setopenUploadPopUp] = useState(false);
  const [courseDescription, setCourseDescription] = useState("");
  const [courseDuration, setCourseDuration] = useState(0);
  const [trainingProvider, setTrainingProvider] = useState("");
  const [courseExpirationDays, setCourseExpirationDays] = useState(0);
  const [templateArr, setTemplateArr] = useState([{ templateName: "temp1" }]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [accessMode, setAccessMode] = useState("free");
  const [objectiveAccessMode, setObjectiveAccessMode] = useState();
  const [hasQuiz, setHasQuiz] = useState("yes");

  const [keyFileImg, setKeyFileImg] = useState([]);
  const [ketFileArr, setKeyFileArr] = useState([]);

  const [industry, setIndustry] = useState([]);
  const [videoFile, setVideoFile] = useState();
  const [uploadedVideoUrl, setUploadesVideoUrl] = useState();

  const [attendArr, setAttendArr] = useState([
    { name: "student" },
    { name: "professional" },
    { name: "all" },
  ]);
  const [subjectiveAccessMode, setSubjectiveAccessMode] = useState();

  const [instructorArr, setInstructorArr] = useState([{ name: "rahul" }]);
  const [instructorId, setInstructorId] = useState("");
  const [sharedByArr, setSharedByArr] = useState(null);
  const [courseId, setCourseId] = useState("");
  const [courseList, setCourseList] = useState(null);
  let newDate = new Date().toDateString();
  const [createdDate, setCreatedDate] = useState(newDate);
  const [lastVisited, setLastVisited] = useState(newDate);

  const [dependencyArr, setDependencyArr] = useState([]);
  const [dependencyMode, setDependencyMode] = useState("or");
  const [flow, setFlow] = useState("linear");
  // const storage = getStorage();
  const [editable, setEditable] = useState(false);
  const [checkFailed, setCheckFailed] = useState(false);
  const [base64Img, setBase64Img] = useState("");
  const [base64Vid, setBase64Vid] = useState("");
  const [imgfilename, setImgFileName] = useState("");
  const [curriculamUrl, setCurriculamUrl] = useState("");
  const [keyHighlishtUrl, setKeyHighlishtUrl] = useState("");
  const [keyHighlisht64base, setKeyHighlisht64base] = useState("");

  const [industrypartnerUrl, setIndustrypartnerUrl] = useState("");
  const [industrypartner64base, setIndustrypartner64base] = useState("");
  const [promises, setPromises] = useState("");
  const [preRequsite, setPreRequsite] = useState("");
  const [curriculam, setCurriculam] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [agreementUrl, setAgreementUrl] = useState("");
  const [base64Agreement, setBase64Agreement] = useState("");
  const [base64Curriculam, setBase64Curriculam] = useState("");
  const [videoFileName, setVideoFileName] = useState("");
  const [faqQuestion, setFaqQuestion] = useState("");
  const [faqAns, setFaqAns] = useState("");
  const [FAQ, setFAQ] = useState([]);
  const [keyImg, setKeyImg] = useState([]);

  const [industryImg, setIndustryImg] = useState([]);
  const [registrationFees, setRegistrationFees] = useState("");
  const [courseFees, setCourseFees] = useState(0);
  const [checkErrorInForm, setCheckErrorInForm] = useState({
    courseName: "",
    courseDescription: "",
    trainingProvider: "",
    courseDuration: "",
    preRequsite: "",
    courseFees: "",
    promises: "",
    curriculamUrl: "",
    eligibility: "",
    agreementUrl: "",
    courseExpirationDays: "",
    imageUrl: "",
    registrationFees: "",
    uploadedVideoUrl: "",
    curriculam: "",
    videoUrl: "",
    instructorId: "",
    selectedTemplate: "",
    courseCategory: "",
  });
  const [editdataflagForLoading, setEditdataflagForLoading] = useState(true);
  const imageRef = useRef(null);
  const videoRef = useRef(null);

  const curriculamRef = useRef(null);
  const keyHighlishtRef = useRef(null);

  const agreementRef = useRef(null);
  const industrypartnerRef = useRef(null);
  var docURL = [];
  var files = [];

  //console.log(sharedByArr)

  const onImageClick = () => {
    imageRef.current.click();
  };

  const onVideoClick = () => {
    videoRef.current.click();
  };
  const onCurriculamClick = () => {
    curriculamRef.current.click();
  };
  const onAgreementClick = () => {
    agreementRef.current.click();
  };

  const onkeyHighlishtClick = () => {
    keyHighlishtRef.current.click();
  };

  const onindustrypartnerClick = () => {
    industrypartnerRef.current.click();
  };
  const formCheck = () => {
    if (courseName.length > 30 || courseName.length < 10) {
      setCheckErrorInForm((prev) => ({ ...prev, courseName: "error" }));
      return "Course Name";
    }
    if (imageUrl === "") {
      setCheckErrorInForm((prev) => ({ ...prev, imageUrl: "error" }));
      alert("Upload image");
      return "base64Img";
    }
    // if(videoUrl===""){
    //   setCheckErrorInForm((prev) => ({ ...prev, videoUrl: "error" }));
    //   alert("Upload video");
    //   return "base64Img";
    // }
    if (FAQ.length === 0) {
      setCheckErrorInForm((prev) => ({ ...prev, eligibility: "error" }));

      return "eligibility";
    }
    // if(promises===""){
    //   setCheckErrorInForm((prev) => ({ ...prev, promises: "error" }));

    //   return "promises";
    // }
    if (courseDescription.length > 40 || courseDescription.length < 10) {
      setCheckErrorInForm((prev) => ({ ...prev, courseDescription: "error" }));
      return "Description";
    }
    if (courseFees === "") {
      setCheckErrorInForm((prev) => ({ ...prev, courseFees: "error" }));
      return "courseFee";
    }

    // if (selectedTemplate == "") {
    //   setCheckErrorInForm((prev) => ({ ...prev, selectedTemplate: "error" }));
    //   return "selectedTemplate";
    // }

    if (selectedCateGory.length == 0) {
      let arr = [];

      for (var option of document.getElementById("courseCategory").options) {
        if (option.selected) {
          if (option.value !== "") {
            arr.push(option.value);
          }
        }
      }
      if (arr.length == 0) {
        setCheckErrorInForm((prev) => ({ ...prev, courseCategory: "error" }));
        return "Certificate Template";
      }
    }
    return "allIsWell";
  };

  if (editdataflagForLoading) {
    // console.log(checkEditFlag)

    document.getElementsByClassName("course-builder-container").display =
      "none";
  }

  useEffect(() => {
    getWhyDoYouWantToJoin({ type: "category" }).then((res) => {
      // console.log(res.data);
      getCategoryFromConfig().then((re) => {
        setCategoryDataFromConfig(re.data);
        let Arr = [];
        if (editCourseInfo) {
          let dd = editCourseInfo?.associatedCategory;
          Arr = [...re.data, ...dd];
        } else {
          Arr = [...re.data];
        }
        // console.log(Arr);
        let TempArr = [];
        for (let i = 0; i < Arr.length; i++) {
          let da = res.data.filter((element) => {
            //   console.log(element);
            if (element.id == Arr[i]) {
              return element;
            }
          });
          // console.log(da);
          if (da.length == 1) {
            TempArr.push(da[0]);
          }
        }
        setCategoryArr(TempArr);
      });
      setDefaultCategory(res.data.filter((e) => e?.isDefault == true));
    });

    fetchCertificateTemplate().then((res) => {
      //console.log(res);
      setTemplateArr(res.data);
    });

    if (!editCourseInfo) return;
    // console.log(editCourseInfo);
    setCourseId(editCourseInfo?.courseId);
    setCourseName(editCourseInfo?.courseName);
    setVideoUrl(editCourseInfo?.courseOverViewVideo);
    setCourseDescription(editCourseInfo?.courseDiscription);
    setImageUrl(editCourseInfo?.courseImageURL);
    setCategoryName(editCourseInfo?.courseCategory);
    setCourseFees(editCourseInfo?.courseFees);
    setPromises(editCourseInfo?.promises);
    setFAQ(editCourseInfo?.eligibility);
    setCourseDuration(editCourseInfo?.courseDuration);
    setFlow(editCourseInfo?.courseProgress);
    setInstructorId(editCourseInfo?.courseInstructor);
    setSelectedTemplate(editCourseInfo?.certificateTemplate);
    setAccessMode(editCourseInfo?.accessMode);
    setSelectedCateGory(editCourseInfo?.associatedCategory);
    setCategoryDataFromCourse(editCourseInfo?.associatedCategory);
    //courseProgress

    setEditdataflagForLoading(false);
    //console.log("i am in useEffect", new Date());
    //console.log(editCourseInfo);
  }, [editCourseInfo]);

  // useEffect(() => {
  //   fetchCertificateTemplate().then((res) => {
  //     setTemplateArr(res.data);
  //   });
  //   getCourses().then((result) => {
  //     setCourseList([...result.data].map((ele) => ({ ...ele, select: false })));
  //   });
  //   getAdminAndInstructorList().then((res) => {
  //     setInstructorArr(res.data.instructor_list);
  //     setSharedByArr([...res.data.instructor_list, ...res.data.admin_list]);
  //     // let shared = res.data.admin_list;
  //     // let instructorList = [];
  //     // res.data.instructor_list;
  //     // instructorList.forEach(ele=>)
  //   });
  // }, []);

  const handleCourseImage = (e) => {
    let reader = new FileReader();
    // console.log(e);

    //  var image = new Image();
    //  image.src = reader.result;
    //  image.onload = function () {
    //    let height = this.height;
    //    let width = this.width;
    //    alert("uudhd")
    //    //console.log(Number(width / height));
    //    // console.log(Number(width / height) != 1.5);
    //    if (Number(width)>390||Number(width)<380&&Number(height)>402||Number(height)<400) {
    //      alert("Aspect Ratio must be 390:402. Update and try again.");
    //      return false;
    //    }
    //    // alert("Uploaded image has valid Height and Width.");

    //   // setSignData({ base64: reader.result, url: URL.createObjectURL(e.target.files[0]) });
    //   // return true;
    //  };

    let filenameType = e.target.value.split(".");

    ///console.log(filenameType[filenameType.length - 1]);

    let filetypedata = filenameType[filenameType.length - 1].toLowerCase();
    if (
      filetypedata == "png" ||
      filetypedata == "jpeg" ||
      filetypedata == "jpg"
    ) {
    } else {
      alert("only jpeg  , gif  ,png  file accepted");
      e.target.value = null;

      return;
    }

    let rfilesize = Number(e.target.files[0].size) / 1024;

    rfilesize = rfilesize / 1024;
    // console.log(rfilesize);
    if (Number(rfilesize) > 3) {
      alert("size must be less than 3 mb");
      setImgFileName("");
      e.target.value = null;
      return;
    } else {
      // alert("thissisj")
      //  console.log(e.target);

      //alert("in")
      let url = URL.createObjectURL(e.target.files[0]);

      const img = new Image();
      img.onload = function () {
        //alert(this.width + 'x' + this.height);
        if (
          Number(this.width) > 390 ||
          (Number(this.width) < 380 && Number(this.height) > 402) ||
          Number(this.height) < 400
        ) {
          alert("Aspect Ratio must be 390px:402px. Update and try again.");
          return false;
        } else {
          setImageUrl(url);
          setImgFileName(e.target.files[0].name);
          reader.readAsDataURL(e.target.files[0]);
          reader.onload = function () {
            //console.log(reader.result);
            setBase64Img(reader.result);
          };
        }
      };
      img.src = url;
    }
  };

  const handleVideo = (e) => {
    setBase64Vid("");
    setVideoUrl("");
    let reader = new FileReader();
    //  console.log(e);

    let filenameType = e.target.value.split(".");

    //console.log(filenameType[filenameType.length - 1]);

    let filetypedata = filenameType[filenameType.length - 1].toLowerCase();

    let url = URL.createObjectURL(e.target.files[0]);
    setVideoUrl(url);

    setVideoFile(e.target.files[0]);
    setVideoFileName(e.target.files[0].name);
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      // console.log(reader.result);
      setBase64Vid(reader.result);
    };
  };

  const handlePdf = (e, state, urlset) => {
    let reader = new FileReader();
    // console.log(e);

    let filenameType = e.target.value.split(".");

    //console.log(filenameType[filenameType.length - 1]);

    let filetypedata = filenameType[filenameType.length - 1].toLowerCase();

    let url = URL.createObjectURL(e.target.files[0]);
    urlset(url);

    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      state(reader.result);
      setKeyFileImg(reader.result);
    };
  };

  const uploadVideoFile = (data) => {
    if (data) {
      setopenUploadPopUp(true);

      let str = Timestamp.fromDate(new Date()).toMillis();
      const storageref = ref(storage, `/files/${str}`);
      const uploadTask = uploadBytesResumable(storageref, data);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          //setprogress1(prog);
          setProgressUpload(prog);
          // console.log(prog);
        },
        (
            err //console.log(err),
          ) =>
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              setUploadesVideoUrl(url);
              // return url
            });
          }
      );
    } else {
      alert("Upload any file");
    }
  };

  const uploadeImgToDataBase = (data) => {};

  const uploadFiles1 = async (file) => {
    let str = Timestamp.fromDate(new Date()).toMillis();
    const storageref = ref(storage, `/files/${str}`);
    const uploadTask = uploadBytesResumable(storageref, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        //setprogress1(prog);
        setProgressUpload(prog);
        //console.log(prog);
      },
      (
          err // console.log(err),
        ) =>
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            // console.log(url);
            // return url
          });
        }
    );
  };

  const addCourse = () => {
    let arr = [];

    for (var option of document.getElementById("courseCategory").options) {
      if (option.selected) {
        if (option.value !== "") {
          arr.push(option.value);
          setSelectedCateGory(arr);
          // console.log(arr);
        }
      }
    }

    // console.log(arr);
    const check = formCheck();
    if (check != "allIsWell") {
      setCheckFailed(true);
      return;
    }

    let nsn = courseName.replaceAll(" ", "");

    let dat = /[^A-Za-z0-9]/.test(nsn);

    if (!dat) {
    } else {
      alert("you can not use any special characters");
      return;
    }

    addCategoryDataInArr({
      arr: categoryDataFromCourse,
    }).then((res) => {
      removeDataFromCategoryArr({
        arr: arr,
      }).then((r) => {
        // console.log(r.data);
      });
    });
    setCheckErrorInForm({
      courseName: "",
      courseDescription: "",
      trainingProvider: "",
      courseDuration: "",
      courseExpirationDays: "",
      imageUrl: "",
      instructorId: "",
      selectedTemplate: "",
      courseFees: "",
    });
    setCheckFailed(false); // removes error message after succesful creation
    let dependency = [];
    // for (var option of document.getElementById("courseDependency").options) {
    //   if (option.selected) {
    //     dependency.push(option.value);
    //   }
    // }
    let shared = [];
    // for (var option of document.getElementById("courseShare").options) {
    //   if (option.selected) {
    //     console.log(option.value)
    //     shared.push(option.value);
    //   }
    // }

    createBasicCourse({
      courseDiscription: courseDescription,
      courseImageURL: base64Img ? base64Img : imageUrl,
      promises: promises,
      courseName: courseName,
      courseId: courseId,
      mappingId: courseId,
      courseVersion: 0,
      courseInstructor: instructorId,

      courseFees: courseFees,
      eligibility: FAQ,
      created: Timestamp.fromDate(new Date()),
    }).then((res) => {
      // console.log(res);
      addBasicCourseExtraInfo({
        courseId: courseId,
        associatedCategory: arr,

        courseOverViewVideo: uploadedVideoUrl ? uploadedVideoUrl : videoUrl,
        certificateTemplate: selectedTemplate,
      }).then(
        (ress) => {} //console.log(ress)
      );
    });

    sessionStorage.setItem("courseId", courseId);
    if (editCourseInfo) {
      alert("Course " + courseName + " Updated");
    } else {
      alert("Course " + courseName + " Added");
    }

    document.getElementById("courseName").disabled = true; // disable the input field
    const elements = document.getElementsByClassName("course-builder-top");
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.display = "none";
    }
    setIsCourseIdSet(courseId);
    document.getElementById("editC").style.display = "";
    document.getElementById("addC").style.display = "none";
    document.getElementById("addL").style.display = "none";

    localStorage.setItem("courseId", courseId);

    // console.log(courseId);
  };

  const moveToLesson = () => {
    sessionStorage.setItem("courseId", courseId);

    document.getElementById("courseName").disabled = true; // disable the input field
    const elements = document.getElementsByClassName("course-builder-top");
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.display = "none";
    }
    document.getElementById("addC").style.display = "none";
    document.getElementById("addL").style.display = "none";
    document.getElementById("editC").style.display = "";
    localStorage.setItem("courseId", courseId);

    setIsCourseIdSet(courseId);
  };
  function edit() {
    document.getElementById("addC").style.display = "";
    document.getElementById("addL").style.display = "";
    const elements = document.getElementsByClassName("course-builder-top");
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.display = "";
    }
  }
  //change this and do it the React Way

  function clier() {
    setVideoFile("");
    setVideoUrl("");
    setUploadesVideoUrl("");
    videoRef.current.value.reset();
    alert("video deleted");
  }
  function addfun() {
    // console.log(add);

    setAdd(!add);
  }

  function addFAQ() {
    let inp = faqAns.replaceAll(" ", "");
    if (faqAns.length > 60) {
      alert("you can not add more then 60 word in one line and");
      return;
    } else if (inp.length == 0) {
      alert("you can not add empty Eligibility");
      return;
    }

    let data = FAQ;
    data.push({ data: faqAns });

    setFAQ(data);

    setFaqAns("");
    setAdd(false);
  }

  const deleteEligibilty = (dd) => {
    let data = [];
    for (let i = 0; i < FAQ.length; i++) {
      if (FAQ[i].data != dd) data.push(FAQ[i]);
    }
    setFAQ(data);
  };

  //console.log(FAQ);
  const AddImageInarr = (type, url, state, fileArr) => {
    let obj = {};

    obj.img = url;

    let newobj = {};
    newobj.Img = keyFileImg;
    let newdata = fileArr;
    newdata.push(newobj);
    let data = state;
    data.push(obj);

    if (type == "keyhigh") {
      setKeyFileArr(newdata);
      // console.log("key");
      setKeyImg(data);
      setKeyHighlishtUrl("");
    }
    if (type == "industry") {
      // console.log("industry");
      setIndustry(newdata);
      setIndustryImg(data);
      setIndustrypartnerUrl("");
    }
  };

  const deletePdf = (type) => {
    if (type == "curriculam") {
      setCurriculamUrl("");
      setBase64Curriculam("");
    }
    if (type == "agreement") {
      setBase64Agreement("");
      setAgreementUrl("");
    }
  };

  const deleteCourse = () => {
    deletebasicCourse({ courseId: courseId }).then((res) => {
      addCategoryDataInArr({
        arr: selectedCateGory,
      }).then((e) => {
        // console.log(e);
      });
      navigate("/admin/training/extrenal");
    });
  };
  return (
    <>
      {/* {" "}
      {!checkEditFlag ? (
        <div className="course-builder-container">
          <h2 className="course-builder-heading">basic course</h2>
          <div
            id="editC"
            style={{ display: "none" }}
            className="course-builder-top-info"
          >
            <table className="course-builder-top-table">
              <thead className="course-builder-top-thead">
                <tr>
                  <th>Course Name</th>
                  <th>Created Date</th>
                  <th>Last Updated</th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{courseName}</td>
                  <td>{createdDate}</td>
                  <td>{lastVisited}</td>

                  <td>
                    <div style={{ display: "flex" }}>
                      <div
                        onClick={() => {
                          setEditable(true);
                          edit();
                        }}
                      >
                        <EditSvg style={{ marginRight: "3px" }} />
                      </div>
                      <div onClick={deleteCourse}>
                        <DeleteSVg />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Upload
            open={openUploadPopUp}
            onClose={() => {
              setopenUploadPopUp(false);
            }}
            upload={progressUpload}
          />

          <div className="course-builder-top">
            <div className="course-builder-info">
              <label htmlFor="courseName" className="course-builder-inputLable">
                Course Name *
              </label>
              <input
                type="text"
                className="course-builder-inputBar"
                id="courseName"
                value={courseName}
                style={{
                  border: `${
                    checkErrorInForm.courseName == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
                onChange={(e) => {
                  setCourseName(e.target.value);
                  let value = e.target.value.replaceAll(" ", "");
                  let time = new Date().getTime();
                  setCheckErrorInForm((prev) => ({ ...prev, courseName: "" }));
                  if (!editCourseInfo) {
                    setCourseId(value + "_" + time);
                  }
                }}
              />
              {checkErrorInForm.courseName == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  Enter Course Name max word 30 and min word 10
                </span>
              )}
            </div>
            <div className="course-builder-info">
              <p className="course-builder-inputLable">Featured Image *</p>
              {imageUrl == "" ? (
                <div
                  style={{
                    width: "20vw",
                    height: "8em",
                    background: "#d5d5d5",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",
                  }}
                  onClick={onImageClick}
                >
                  Click to upload Image
                </div>
              ) : (
                <img
                  style={{
                    width: "20vw",
                    height: "8em",
                    objectFit: "cover",
                    borderRadius: "10px",
                  }}
                  onClick={onImageClick}
                  src={imageUrl}
                ></img>
              )}
              <input
                ref={imageRef}
                style={{ display: "none" }}
                type="file"
                accept="image/png, image/gif, image/jpeg"
                className="course-builder-inputImg"
                id="courseImage"
                onChange={(e) => {
                  handleCourseImage(e);
                  setCheckErrorInForm((prev) => ({ ...prev, imageUrl: "" }));
                }}
              />
              {checkErrorInForm.imageUrl == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  {" "}
                  upload Image
                </span>
              )}
            </div>

            {/* <div className="course-builder-info">
              <p className="course-builder-inputLable">Course  Over View video *</p>
              {videoUrl == "" ? (
                <div
                  style={{
                    width: "275px",
                    height: "200px",
                    background: "#d5d5d5",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",
                  }}
                  onClick={onVideoClick}
                >
                  Click to upload video
                </div>
              ) : (

<>

                <video width="320" height="240" controls>
            <source  src={videoUrl} type="video/mp4"/>
      <source  src={videoUrl} type="video/ogg"/>
        Your browser does not support the video tag.
           </video>
           
           </>
              )}
            

      




 



              <input
                ref={videoRef}
                style={{
                  display:"none"
                }}
                type="file"
                accept="video/*"
                className="course-builder-inputImg"
                id="courseVideo"
                onChange={(e) => {
                  handleVideo(e);
                  setCheckErrorInForm((prev) => ({ ...prev, VideoUrl: "" }));
                }}
              />
             <button className="course-builder-button" style={{ background:"linear-gradient(92.2deg, #1E3758 0.25%, #0475B5 100%)",marginLeft:"5vw"}} onClick={clier}> Delete</button>
             <button className="course-builder-button" style={{ background:"linear-gradient(92.2deg, #1E3758 0.25%, #0475B5 100%)",marginLeft:"5vw"}} onClick={()=>{uploadVideoFile(videoFile)}}> Upload</button>
              {checkErrorInForm.videoUrl == "error" && (
                <span style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}>
                  {" "}
                  upload video
                </span>
              )}
            </div> /}

            <div className="course-builder-info">
              <div
                className="course-builder-inputLable"
                style={{ marginTop: "2vw" }}
              >
                Recommeded for{" "}
                <span>
                  {" "}
                  <button
                    onClick={() => {
                      addfun();
                      setCheckErrorInForm((prev) => ({
                        ...prev,
                        eligibility: "",
                      }));
                    }}
                  >
                    +
                  </button>
                </span>
              </div>
              {checkErrorInForm.eligibility == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  {" "}
                  add Recommeded
                </span>
              )}
            </div>

            {FAQ.map((e, id) => (
              <>
                <div className="course-builder-info">
                  <div
                    className="course-builder-inputLable"
                    style={{ marginTop: "2vw", fontSize: "11px" }}
                  >
                    Recommeded {id + 1}
                  </div>
                  <input
                    type="text"
                    className="course-builder-inputBar"
                    value={e.data}
                    style={{
                      border: `${
                        checkErrorInForm.trainingProvider == "error"
                          ? "2px solid red"
                          : "1px solid #d7d7d7"
                      }`,
                    }}
                    id="trainingProvider"
                  />
                  <button
                    className="course-builder-button"
                    style={{
                      background:
                        "linear-gradient(92.2deg, #1E3758 0.25%, #0475B5 100%)",
                      marginLeft: "5vw",
                    }}
                    onClick={() => {
                      deleteEligibilty(e.data);
                    }}
                  >
                    {" "}
                    Delete
                  </button>
                </div>
              </>
            ))}

            {add == true && (
              <>
                <div className="course-builder-info">
                  <div
                    className="course-builder-inputLable"
                    style={{ marginTop: "2vw" }}
                  >
                    Recommeded
                  </div>
                  <input
                    type="text"
                    className="course-builder-inputBar"
                    value={faqAns}
                    style={{
                      border: `${
                        checkErrorInForm.trainingProvider == "error"
                          ? "2px solid red"
                          : "1px solid #d7d7d7"
                      }`,
                    }}
                    id="trainingProvider"
                    onChange={(event) => {
                      setFaqAns(event.target.value);
                    }}
                  />
                </div>

                <button
                  id="addC"
                  className="course-builder-button"
                  onClick={addFAQ}
                >
                  Save
                </button>
              </>
            )}

            {/* <div className="course-builder-info">
              <p className="course-builder-inputLable">Promises *</p>
              <textarea
                type="text"
                className="course-builder-textarea"
                value={promises}
                style={{
                  border: `${
                    checkErrorInForm.promises == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
                id="promises"
                onChange={(event) => {
                  setPromises(event.target.value);
                  setCheckErrorInForm((prev) => ({ ...prev, promises: "" }));
                }}
              />
              {checkErrorInForm.promises == "error" && (
                <span style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}>
                  Enter Course promises
                </span>
              )}
            </div> /}

            {/* <div className="course-builder-info">
              <p className="course-builder-inputLable">Intructions*</p>
              <textarea
                type="text"
                className="course-builder-textarea"
                value={instruction}
                
                id="intructions"
                onChange={(event) => {
                  setInstructions(event.target.value);
               
                }}
              />
              
            </div> /}

            <div className="course-builder-info">
              <p className="course-builder-inputLable">Short Description*</p>
              <textarea
                type="text"
                className="course-builder-textarea"
                value={courseDescription}
                style={{
                  border: `${
                    checkErrorInForm.courseDescription == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
                id="courseDescription"
                onChange={(event) => {
                  setCourseDescription(event.target.value);
                  setCheckErrorInForm((prev) => ({
                    ...prev,
                    courseDescription: "",
                  }));
                }}
              />
              {checkErrorInForm.courseDescription == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  Enter Course Description Max word 40 and Min word 10
                </span>
              )}
            </div>

            <div className="course-builder-info">
              <p className="course-builder-inputLable">Course Fees *</p>
              <input
                type="Number"
                className="course-builder-inputBar"
                value={courseFees}
                style={{
                  border: `${
                    checkErrorInForm.courseFees == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
                id="courseFees"
                onChange={(event) => {
                  setCourseFees(event.target.value);
                  setCheckErrorInForm((prev) => ({ ...prev, courseFees: "" }));
                }}
              />
              {checkErrorInForm.courseFees == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  Enter Course Fees
                </span>
              )}
            </div>
          </div>
          <div className="course-builder-top">
            <div className="course-builder-info">
              <p className="course-builder-inputLable">Course ID</p>
              <input
                disabled
                type="text"
                className="course-builder-inputBar"
                id="courseId"
                value={courseId}
              />
            </div>
            <div className="course-builder-info">
              <p className="course-builder-inputLable">
                Certificate Template *
              </p>
              <select
                className="course-builder-inputSelect"
                value={selectedTemplate}
                onChange={(e) => {
                  setSelectedTemplate(e.target.value);
                  setCheckErrorInForm((prev) => ({
                    ...prev,
                    selectedTemplate: "",
                  }));
                }}
                style={{
                  border: `${
                    checkErrorInForm.selectedTemplate == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
              >
                <option value="" selected disabled hidden>
                  Select an Option
                </option>
                {templateArr?.map((item, id) => (
                  <option key={id} value={item.templateName}>
                    {item.templateName}
                  </option>
                ))}
              </select>
              {checkErrorInForm.selectedTemplate === "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  {" "}
                  select Template
                </span>
              )}
            </div>
            {/* <div className="course-builder-info">
              <div className="course-builder-inputLable" style={{ marginTop: "2vw" }}>
                Course Instructor *
              </div>
              <select
                id="courseInstructor"
                className="course-builder-inputSelect"
                value={instructorId}
                onChange={(e) => {
                  setInstructorId(e.target.value);
                  setCheckErrorInForm((prev) => ({ ...prev, instructorId: "" }));
                }}
                style={{
                  border: `${
                    checkErrorInForm.instructorId == "error" ? "2px solid red" : "1px solid #d7d7d7"
                  }`,
                }}
              >
                <option value="" selected disabled hidden>
                  Select an Option
                </option>
                {instructorArr?.map((item, id) => (
                  <option value={item.name} key={id}>
                    {item.name}
                  </option>
                ))}
              </select>

              {checkErrorInForm.instructorId === "error" && (
                <span style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}>
                  {" "}
                  select instructor
                </span>
              )}
            </div> /}

            <div className="course-builder-info">
              <div
                className="course-builder-inputLable"
                style={{ marginTop: "2vw" }}
              >
                Course Category *
              </div>
              <select
                id="courseCategory"
                // value={categoryName}
                onClick={(e) => {
                  setCheckErrorInForm((prev) => ({
                    ...prev,
                    courseCategory: "",
                  }));
                }}
                className="course-builder-multipleSelect"
                multiple
              >
                <option value="" selected disabled hidden>
                  Select an Option
                </option>
                {categoryArr?.map((item, id) => {
                  const fun = (i) => {
                    for (let j = 0; j < selectedCateGory.length; j++) {
                      if (selectedCateGory[j] == i.id) {
                        return true;
                      }
                    }
                    return false;
                  };
                  if (fun(item)) {
                    console.log(item);
                    return (
                      <option value={item.id} selected={true} key={id}>
                        {item.categoryName}
                      </option>
                    );
                  } else {
                    return (
                      <option value={item.id} key={id}>
                        {item.categoryName}
                      </option>
                    );
                  }
                })}
              </select>

              {checkErrorInForm.courseCategory === "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  {" "}
                  select category
                </span>
              )}
            </div>

            {/* <div className="course-builder-info">
              <div className="course-builder-inputLable" style={{ marginTop: "2vw" }}>
              Who should attend?
              </div>
              <select  id="courseShare" className="course-builder-multipleSelect"  multiple>
                {attendArr?.map((item, id) => (
                  <option key={id} value={item.name}>{item.name}</option>
                ))}
              </select>
            </div> /}
            <br></br>
            {checkFailed && (
              <div>
                <p style={{ color: "red" }}>Fill all the field in the Form</p>
              </div>
            )}
          </div>
          <div style={{ display: "flex", gap: "40px" }}>
            {" "}
            <button
              id="addC"
              className="course-builder-button"
              onClick={addCourse}
            >
              Save Course
            </button>
            {editCourseInfo ? (
              <button
                id="addL"
                className="course-builder-button"
                style={{ width: "150px" }}
                onClick={moveToLesson}
              >
                Move To Lesson
              </button>
            ) : (
              ""
            )}
          </div>

          {/* <div style={{ height: "100px", margin: "auto" }} className="course-builder-top">
        <button
        id="addC"
          style={{ margin: "auto" }}
          className="course-builder-button"
          onClick={() => navigate(-1)}
        >
          Back
        </button>{" "}
      </div> /}
          {/* {courseAdd==false?<ViewCourses/>:<Lesson/>} /}
        </div>
      ) : (
        <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td">
            <div className="spinner-container">
              <div className="loading-spinner"></div>
            </div>
          </div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div
            className="admin-overdue-bottom-table-td"
            style={{ color: "#004577" }}
          ></div>
        </div>
      )} */}
      {/* -------------------- */}{" "}
      {!checkEditFlag ? (
        <Box
          className="course-builder-top"
          sx={{
            width: "100%",
            backgroundColor: "#FFF",
            padding: "20px",
            mt: "30px",
            borderRadius: "6px",
            margin: "20px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "20px",
              color: "#1E3758",
            }}
          >
            Basic Course
          </Typography>
          <div
            id="editC"
            style={{ display: "none" }}
            className="course-builder-top-info"
          >
            <table className="course-builder-top-table">
              <thead className="course-builder-top-thead">
                <tr>
                  <th>Course Name</th>
                  <th>Created Date</th>
                  <th>Last Updated</th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{courseName}</td>
                  <td>{createdDate}</td>
                  <td>{lastVisited}</td>

                  <td>
                    <div style={{ display: "flex" }}>
                      <div
                        onClick={() => {
                          setEditable(true);
                          edit();
                        }}
                      >
                        <EditSvg style={{ marginRight: "3px" }} />
                      </div>
                      <div onClick={deleteCourse}>
                        <DeleteSVg />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Upload
            open={openUploadPopUp}
            onClose={() => {
              setopenUploadPopUp(false);
            }}
            upload={progressUpload}
          />
          <Stack spacing={4}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <Typography
                sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
              >
                Course Name <span style={{ color: "#DE5353" }}>*</span>
              </Typography>
              <TextField
                type="text"
                value={courseName}
                id="courseName"
                sx={{
                  border: `${
                    checkErrorInForm.courseName == "error"
                      ? "2px solid red"
                      : "1px solid #1E3758"
                  }`,
                  width: "30%",
                  borderRadius: "6px",
                }}
                onChange={(e) => {
                  setCourseName(e.target.value);
                  let value = e.target.value.replaceAll(" ", "");
                  let time = new Date().getTime();
                  setCheckErrorInForm((prev) => ({ ...prev, courseName: "" }));
                  if (!editCourseInfo) {
                    setCourseId(value + "_" + time);
                  }
                }}
              />
              {checkErrorInForm.courseName == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  Enter Course Name max word 30 and min word 10
                </span>
              )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <Typography
                sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
              >
                Featured Image <span style={{ color: "#DE5353" }}>*</span>
                <p style={{ fontSize: "15px", fontWeight: "200" }}>
                  (Aspect Ratio 390px:402px.){" "}
                </p>
              </Typography>
              <Box sx={{ display: "30%" }}>
                {imageUrl == "" ? (
                  <Box
                    onClick={onImageClick}
                    sx={{
                      width: "230px",
                      height: "180px",
                      border: "2px dashed #1E3758",
                      borderRadius: "16px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <Button
                      component="label"
                      sx={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                        border: "2px dashed #1C1B1F",
                        display: "flex",
                        justifyContent: "center",
                        "&:hover": {
                          backgroundColor: "#FFF",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <svg
                          width="25"
                          height="26"
                          viewBox="0 0 25 26"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.27998 25.2535C2.42931 25.2535 1.70135 24.9509 1.09609 24.3456C0.489792 23.7393 0.186646 23.0108 0.186646 22.1602V17.5202H3.27998V22.1602H21.84V17.5202H24.9333V22.1602C24.9333 23.0108 24.6307 23.7393 24.0254 24.3456C23.4191 24.9509 22.6906 25.2535 21.84 25.2535H3.27998ZM11.0133 19.0668V6.4615L6.99198 10.4828L4.82665 8.24017L12.56 0.506836L20.2933 8.24017L18.128 10.4828L14.1066 6.4615V19.0668H11.0133Z"
                            fill="#D9D9D9"
                          />
                        </svg>
                      </Box>
                    </Button>
                    <Typography sx={{ color: "#1E3758" }}>
                      Upload Image
                    </Typography>
                  </Box>
                ) : (
                  <img
                    style={{
                      width: "20vw",
                      height: "10em",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                    onClick={onImageClick}
                    src={imageUrl}
                  ></img>
                )}
              </Box>
              <input
                hidden
                ref={imageRef}
                type="file"
                accept="image/png, image/gif, image/jpeg"
                onChange={(e) => {
                  handleCourseImage(e);
                  setCheckErrorInForm((prev) => ({ ...prev, imageUrl: "" }));
                }}
              />
            </Box>

            {/* <div className="course-builder-info">
              <p className="course-builder-inputLable">Course  Over View video *</p>
              {videoUrl == "" ? (
                <div
                  style={{
                    width: "275px",
                    height: "200px",
                    background: "#d5d5d5",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",
                  }}
                  onClick={onVideoClick}
                >
                  Click to upload video
                </div>
              ) : (

<>

                <video width="320" height="240" controls>
            <source  src={videoUrl} type="video/mp4"/>
      <source  src={videoUrl} type="video/ogg"/>
        Your browser does not support the video tag.
           </video>
           
           </>
              )}
            

      




 



              <input
                ref={videoRef}
                style={{
                  display:"none"
                }}
                type="file"
                accept="video/*"
                className="course-builder-inputImg"
                id="courseVideo"
                onChange={(e) => {
                  handleVideo(e);
                  setCheckErrorInForm((prev) => ({ ...prev, VideoUrl: "" }));
                }}
              />
             <button className="course-builder-button" style={{ background:"linear-gradient(92.2deg, #1E3758 0.25%, #0475B5 100%)",marginLeft:"5vw"}} onClick={clier}> Delete</button>
             <button className="course-builder-button" style={{ background:"linear-gradient(92.2deg, #1E3758 0.25%, #0475B5 100%)",marginLeft:"5vw"}} onClick={()=>{uploadVideoFile(videoFile)}}> Upload</button>
              {checkErrorInForm.videoUrl == "error" && (
                <span style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}>
                  {" "}
                  upload video
                </span>
              )}
            </div> */}
            <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <Typography
                sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
              >
                Recommeded for <span style={{ color: "#DE5353" }}>*</span>
              </Typography>
              <Box>
                <Button
                  sx={{
                    background: "#FFF",
                    borderRadius: "5px",
                    color: "#FFF",
                    padding: "8px 25px",
                    border: "1px solid #062A59",
                    color: "#062A59",
                  }}
                  onClick={() => {
                    addfun();
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      eligibility: "",
                    }));
                  }}
                >
                  Add +
                </Button>
              </Box>
              {checkErrorInForm.eligibility == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  {" "}
                  Add Recommeded
                </span>
              )}
            </Box>

            {FAQ.map((e, id) => (
              <>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <Typography
                    sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                  >
                    Recommeded {id + 1}
                  </Typography>
                  <TextField
                    type="text"
                    value={e.data}
                    min={1}
                    sx={{
                      border: `${
                        checkErrorInForm.trainingProvider == "error"
                          ? "2px solid red"
                          : "1px solid #1E3758"
                      }`,
                      width: "30%",
                      borderRadius: "6px",
                    }}
                  />
                  <button
                    className="course-builder-button"
                    style={{
                      background:
                        "linear-gradient(92.2deg, #1E3758 0.25%, #0475B5 100%)",
                      marginLeft: "5vw",
                    }}
                    onClick={() => {
                      deleteEligibilty(e.data);
                    }}
                  >
                    {" "}
                    Delete
                  </button>
                </Box>
              </>
            ))}

            {add == true && (
              <>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <Typography
                    sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                  >
                    Recommeded
                  </Typography>
                  <TextField
                    type="text"
                    value={faqAns}
                    sx={{
                      border: `${
                        checkErrorInForm.trainingProvider == "error"
                          ? "2px solid red"
                          : "1px solid #1E3758"
                      }`,
                      width: "30%",
                      borderRadius: "6px",
                    }}
                    onChange={(event) => {
                      setFaqAns(event.target.value);
                    }}
                  />
                </Box>

                <Box>
                  <Button
                    sx={{
                      background:
                        "linear-gradient(94.87deg, #062A59 0%, #C2512C 99.3%)",
                      boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
                      borderRadius: "5px",
                      color: "#FFF",
                      padding: "8px 30px",
                    }}
                    onClick={addFAQ}
                  >
                    Save
                  </Button>
                </Box>
              </>
            )}

            {/* <div className="course-builder-info">
              <p className="course-builder-inputLable">Promises *</p>
              <textarea
                type="text"
                className="course-builder-textarea"
                value={promises}
                style={{
                  border: `${
                    checkErrorInForm.promises == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
                id="promises"
                onChange={(event) => {
                  setPromises(event.target.value);
                  setCheckErrorInForm((prev) => ({ ...prev, promises: "" }));
                }}
              />
              {checkErrorInForm.promises == "error" && (
                <span style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}>
                  Enter Course promises
                </span>
              )}
            </div> */}

            {/* <div className="course-builder-info">
              <p className="course-builder-inputLable">Intructions*</p>
              <textarea
                type="text"
                className="course-builder-textarea"
                value={instruction}
                
                id="intructions"
                onChange={(event) => {
                  setInstructions(event.target.value);
               
                }}
              />
              
            </div> */}
            <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <Typography
                sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
              >
                Short Description <span style={{ color: "#DE5353" }}>*</span>
              </Typography>
              <TextField
                type="text"
                value={courseDescription}
                sx={{
                  border: `${
                    checkErrorInForm.courseDescription == "error"
                      ? "2px solid red"
                      : "1px solid #1E3758"
                  }`,
                  width: "30%",
                  borderRadius: "6px",
                }}
                onChange={(event) => {
                  setCourseDescription(event.target.value);
                  setCheckErrorInForm((prev) => ({
                    ...prev,
                    courseDescription: "",
                  }));
                }}
              />
              {checkErrorInForm.courseDescription == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  Enter Course Description Max word 40 and Min word 10
                </span>
              )}
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <Typography
                sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
              >
                Course Fees <span style={{ color: "#DE5353" }}>*</span>
              </Typography>
              <TextField
                type="Number"
                value={courseFees}
                min={1}
                sx={{
                  border: `${
                    checkErrorInForm.courseFees == "error"
                      ? "2px solid red"
                      : "1px solid #1E3758"
                  }`,
                  width: "30%",
                  borderRadius: "6px",
                }}
                onChange={(event) => {
                  setCourseFees(event.target.value);
                  setCheckErrorInForm((prev) => ({ ...prev, courseFees: "" }));
                }}
              />
              {checkErrorInForm.courseFees == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  Enter Course Fees
                </span>
              )}
            </Box>
          </Stack>
          <Box
            sx={{
              width: "100%",
              backgroundColor: "#FFF",
              mt: "20px",
            }}
          >
            <Stack spacing={4}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  borderRadius: "6px",
                }}
              >
                <Typography
                  sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                >
                  Course ID
                </Typography>
                <TextField
                  type="text"
                  value={courseId}
                  disabled
                  sx={{
                    border: `${
                      checkErrorInForm.courseName == "error"
                        ? "2px solid red"
                        : "1px solid #1E3758"
                    }`,
                    width: "30%",
                    borderRadius: "6px",
                  }}
                />
              </Box>
              {/* <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Typography
                  sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                >
                  Certificate Template{" "}
                  <span style={{ color: "#DE5353" }}>*</span>
                </Typography>
                <select
                  value={selectedTemplate}
                  style={{
                    border: `${
                      checkErrorInForm.selectedTemplate == "error"
                        ? "2px solid red"
                        : "1px solid #1E3758"
                    }`,
                    outline: "none",
                    width: "30%",
                    height: "60px",
                    padding: "10px",
                    fontSize: "18px",
                    borderRadius: "6px",
                  }}
                  min={1}
                  onChange={(e) => {
                    setSelectedTemplate(e.target.value);
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      selectedTemplate: "",
                    }));
                  }}
                >
                  <option
                    style={{
                      padding: "10px",
                      color: "#1E3758",
                      fontSize: "20px",
                      height: "60px",
                    }}
                    value=""
                    selected
                    disabled
                    hidden
                  >
                    Select an Option
                  </option>
                  {templateArr?.map((item, id) => (
                    <option
                      style={{
                        padding: "10px",
                        color: "#1E3758",
                        fontSize: "20px",
                        height: "60px",
                      }}
                      key={id}
                      value={item.id}
                    >
                      {item.templateName}
                    </option>
                  ))}
                </select>
                {checkErrorInForm.selectedTemplate === "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    {" "}
                    select Template
                  </span>
                )}
              </Box> */}

              {/* <div className="course-builder-info">
              <div className="course-builder-inputLable" style={{ marginTop: "2vw" }}>
                Course Instructor *
              </div>
              <select
                id="courseInstructor"
                className="course-builder-inputSelect"
                value={instructorId}
                onChange={(e) => {
                  setInstructorId(e.target.value);
                  setCheckErrorInForm((prev) => ({ ...prev, instructorId: "" }));
                }}
                style={{
                  border: `${
                    checkErrorInForm.instructorId == "error" ? "2px solid red" : "1px solid #d7d7d7"
                  }`,
                }}
              >
                <option value="" selected disabled hidden>
                  Select an Option
                </option>
                {instructorArr?.map((item, id) => (
                  <option value={item.name} key={id}>
                    {item.name}
                  </option>
                ))}
              </select>

              {checkErrorInForm.instructorId === "error" && (
                <span style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}>
                  {" "}
                  select instructor
                </span>
              )}
            </div> */}
              <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Typography
                  sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                >
                  Course category <span style={{ color: "#DE5353" }}>*</span>
                </Typography>
                <select
                  id="courseCategory"
                  // value={categoryName}
                  onClick={(e) => {
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      courseCategory: "",
                    }));
                  }}
                  style={{
                    border: `${
                      checkErrorInForm.courseCategory == "error"
                        ? "2px solid red"
                        : "1px solid #1E3758"
                    }`,
                    outline: "none",
                    width: "30%",
                    height: "200px",
                    padding: "10px",
                    fontSize: "18px",
                    borderRadius: "6px",
                  }}
                  multiple
                >
                  <option value="" selected disabled hidden>
                    Select an Option
                  </option>
                  {categoryArr?.map((item, id) => {
                    const fun = (i) => {
                      for (let j = 0; j < selectedCateGory?.length; j++) {
                        if (selectedCateGory[j] == i.id) {
                          return true;
                        }
                      }
                      return false;
                    };
                    if (fun(item)) {
                      console.log(item);
                      return (
                        <option
                          value={item.id}
                          selected={true}
                          key={id}
                          style={{
                            padding: "10px",
                            color: "#1E3758",
                            fontSize: "20px",
                          }}
                        >
                          {item.categoryName}
                        </option>
                      );
                    } else {
                      return (
                        <option
                          value={item.id}
                          key={id}
                          style={{
                            padding: "10px",
                            color: "#1E3758",
                            fontSize: "20px",
                          }}
                        >
                          {item.categoryName}
                        </option>
                      );
                    }
                  })}
                </select>

                {checkErrorInForm.courseCategory === "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    {" "}
                    select category
                  </span>
                )}
              </Box>

              <br></br>
              {checkFailed && (
                <div>
                  <p style={{ color: "red" }}>Fill all the field in the Form</p>
                </div>
              )}
            </Stack>
            <Box
              sx={{
                display: "flex",
                gap: "40px",
                justifyContent: "center",
                alignItems: "center",
                mt: "30px",
              }}
            >
              {" "}
              <button id="addC" className="primary-btn" onClick={addCourse}>
                Save Course
              </button>
              {editCourseInfo ? (
                <button
                  id="addL"
                  className="secondary-btn"
                  onClick={moveToLesson}
                >
                  Move To Lesson
                </button>
              ) : (
                ""
              )}
            </Box>
            {/* <div style={{ display: "flex", gap: "40px" }}>
              {" "}
              <button
                id="addC"
                className="course-builder-button"
                onClick={addCourse}
              >
                Save Course
              </button>
              {editCourseInfo ? (
                <button
                  id="addL"
                  className="course-builder-button"
                  style={{ width: "150px" }}
                  onClick={moveToLesson}
                >
                  Move To Lesson
                </button>
              ) : (
                ""
              )}
            </div> */}
          </Box>

          {/* <div style={{ height: "100px", margin: "auto" }} className="course-builder-top">
        <button
        id="addC"
          style={{ margin: "auto" }}
          className="course-builder-button"
          onClick={() => navigate(-1)}
        >
          Back
        </button>{" "}
      </div> */}
          {/* {courseAdd==false?<ViewCourses/>:<Lesson/>} */}
        </Box>
      ) : (
        <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td">
            <div className="spinner-container">
              <div className="loading-spinner"></div>
            </div>
          </div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div
            className="admin-overdue-bottom-table-td"
            style={{ color: "#004577" }}
          ></div>
        </div>
      )}
    </>
  );
};

export default BasicCourse;
