import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";
import { useSelector } from "react-redux";
import LinkPopUp from "./LinkPopUp";
import CoordinatorePopUp from "./coordinatorePopUp";

const ChnageUserStatus = ({
  uid,
  data,
  flag,
  setUserStatusData,
  userStatusData,
  coordinatoreData,
}) => {
  const { clrs } = useSelector((state) => state.createClr);
  const [training, setTraining] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [arrLength, setArrLength] = useState(0);

  const addCoordinatoreIdInUser = httpsCallable(
    functions,
    "addCoordinatoreIdInUser"
  );
  const getUserStatus = httpsCallable(functions, "getUserStatus");

  const UpdateUserStatus = httpsCallable(functions, "UpdateUserStatus");

  const UpdateUserStatusInActiveCourse = httpsCallable(
    functions,
    "UpdateUserStatusInActiveCourse"
  );
  const updateUserStatusInZoho = httpsCallable(
    functions,
    "updateUserStatusInZoho"
  );
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);

  const [coordinatorId, setcoordinatorId] = useState("");
  const [openCoordinater, setopenCoordinater] = useState(false);
  const createUserHistory = httpsCallable(functions, "createUserHistory");

  const uploadUserAgreement = httpsCallable(functions, "uploadUserAgreement");
  const RejectedFromCourseHttps = httpsCallable(
    functions,
    "RejectedFromCourseHttps"
  );
  const objectiveTestPassHttps = httpsCallable(
    functions,
    "objectiveTestPassHttps"
  );
  const subjectiveTestPassHttps = httpsCallable(
    functions,
    "subjectiveTestPassHttps"
  );
  const PaymentPassedPassHttps = httpsCallable(
    functions,
    "PaymentPassedPassHttps"
  );
  const DocumentsPassedPassHttps = httpsCallable(
    functions,
    "DocumentsPassedPassHttps"
  );
  const PaymentRefundedHttps = httpsCallable(functions, "PaymentRefundedHttps");
  const DocumentsReuploadHttps = httpsCallable(
    functions,
    "DocumentsReuploadHttps"
  );

  const agreementPassedHttps = httpsCallable(functions, "agreementPassedHttps");
  const AgreementReuploadHttps = httpsCallable(
    functions,
    "AgreementReuploadHttps"
  );

  const SelectedInCourseHttps = httpsCallable(
    functions,
    "SelectedInCourseHttps"
  );
  const [objData, setObjData] = useState([]);

  const [selectUserStatus, setSelectUserStatus] = useState("");
  const [rejectsms, setRejectsms] = useState("");

  console.log(objData);
  const clickhandler = (i) => {
    if (i < 0) return;
    if (
      i + 1 >
      Math.ceil(data.length > 0 && data[0]?.subjectiveAns?.length / 1)
    )
      return;
    //TODO: limit upper
    setCurrentPage(i);
  };

  const windoopen = (type, file) => {
    window.open(file);
  };

  useEffect(() => {
    getUserStatus({
      courseId: data[0]?.courseId,
      attempt: data[0]?.attempt,

      uid: uid,
    }).then((res) => {
      // console.log(res.data[0]);
      // alert(res.data[0].coordinatorId);
      setcoordinatorId(res.data[0].coordinatorId);
    });
  }, [data]);
  const submitUserDetails = () => {
    if (selectUserStatus == "approvalStatus_approved") {
      if (!coordinatorId) {
        setRejectsms("");
        setSelectUserStatus("");
        alert("First Select The Coordinator");
        return;
      }
    }

    if (selectUserStatus !== "") {
      let splitData = selectUserStatus.split("Status");

      let stats = splitData[0] + " " + splitData[1].split("_")[1];

      let stpass = splitData[1].split("_")[1];
      let stTeamp = selectUserStatus.split("_")[0];
      console.log(stats);
      setUserStatusData((prev) => ({ ...prev, [stTeamp]: stpass }));
      createUserHistory({
        courseId: data[0].courseId,
        attempt: data[0].attempt,
        userStatus: stats,
        uid: uid,
      });
    }
    if (selectUserStatus == "") {
      alert("select user status");
      return;
    } else if (selectUserStatus.includes("passed")) {
      // let key =selectUserStatus+"message"
      let st = selectUserStatus.split("_")[0];
      let statatys = selectUserStatus.split("_")[1];

      if (selectUserStatus == "objectiveTestStatus_passed") {
        objectiveTestPassHttps({
          userId: uid,
          coursename: data[0].courseName,
          params: {
            courseId: data[0].courseId,
            attempt: data[0].attempt,
            img: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.vecteezy.com%2Fvector-art%2F6086198-notification-icon-for-web&psig=AOvVaw0Y-_kHylgjWh7t3uGUm7Rf&ust=1674367924256000&source=images&cd=vfe&ved=0CBAQjRxqFwoTCIDWs-aA2PwCFQAAAAAdAAAAABAE",
            [st]: statatys,
          },
        });
      }

      if (selectUserStatus == "subjectiveTestStatus_passed") {
        subjectiveTestPassHttps({
          userId: uid,
          coursename: data[0].courseName,
          params: {
            courseId: data[0].courseId,
            attempt: data[0].attempt,
            img: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.vecteezy.com%2Fvector-art%2F6086198-notification-icon-for-web&psig=AOvVaw0Y-_kHylgjWh7t3uGUm7Rf&ust=1674367924256000&source=images&cd=vfe&ved=0CBAQjRxqFwoTCIDWs-aA2PwCFQAAAAAdAAAAABAE",
            [st]: statatys,
          },
        });
      }

      if (selectUserStatus == "paymentStatus_passed") {
        setopenLessonPopUp(true);

        return;
      }

      if (selectUserStatus == "documentsVerificationStatus_passed") {
        DocumentsPassedPassHttps({
          userId: uid,
          coursename: data[0].courseName,
          params: {
            courseId: data[0].courseId,
            attempt: data[0].attempt,
            img: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.vecteezy.com%2Fvector-art%2F6086198-notification-icon-for-web&psig=AOvVaw0Y-_kHylgjWh7t3uGUm7Rf&ust=1674367924256000&source=images&cd=vfe&ved=0CBAQjRxqFwoTCIDWs-aA2PwCFQAAAAAdAAAAABAE",
            [st]: statatys,
          },
        });
      }

      if (selectUserStatus == "agreementStatus_passed") {
        agreementPassedHttps({
          userId: uid,
          coursename: data[0].courseName,
          params: {
            courseId: data[0].courseId,
            attempt: data[0].attempt,
            img: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.vecteezy.com%2Fvector-art%2F6086198-notification-icon-for-web&psig=AOvVaw0Y-_kHylgjWh7t3uGUm7Rf&ust=1674367924256000&source=images&cd=vfe&ved=0CBAQjRxqFwoTCIDWs-aA2PwCFQAAAAAdAAAAABAE",
            [st]: statatys,
          },
        });
      }

      console.log(`${st}Date`);

      UpdateUserStatus({
        attempts: data[0].attempt,
        uid: uid,
        courseId: data[0].courseId,
        attemps: "first",
        userStatus: selectUserStatus ? selectUserStatus : data[0].userStatus,
        [st]: statatys,
        [`${st}Date`]: new Date(),
      });
    } else {
      // let key =selectUserStatus+"message"
      let st = selectUserStatus.split("_")[0];
      let statatys = selectUserStatus.split("_")[1];
      let stsms = selectUserStatus.split("Status")[0] + "Sms";

      //  let st=selectUserStatus.split("_")[0]
      //  let statatys=selectUserStatus.split("_")[1]
      UpdateUserStatus({
        attempts: data[0].attempt,
        uid: uid,
        courseId: data[0].courseId,
        attemps: "first",
        userStatus: selectUserStatus ? selectUserStatus : data[0].userStatus,
        [stsms]: rejectsms,
        [st]: statatys,
        [`${st}Date`]: new Date(),
      });
    }

    if (selectUserStatus.includes("rejected")) {
      let st = selectUserStatus.split("_")[0];
      let statatys = selectUserStatus.split("_")[1];
      let stsms = selectUserStatus.split("Status")[0] + "Sms";

      UpdateUserStatus({
        enrolledInCourse: false,
        attempts: data[0].attempt,
        uid: uid,
        courseId: data[0].courseId,
      });

      RejectedFromCourseHttps({
        userId: uid,
        coursename: data[0].courseName,
        params: {
          courseId: data[0].courseId,
          attempt: data[0].attempt,
          img: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.vecteezy.com%2Fvector-art%2F6086198-notification-icon-for-web&psig=AOvVaw0Y-_kHylgjWh7t3uGUm7Rf&ust=1674367924256000&source=images&cd=vfe&ved=0CBAQjRxqFwoTCIDWs-aA2PwCFQAAAAAdAAAAABAE",
          [st]: statatys,
          [stsms]: rejectsms,
        },
      }).then((res) => {
        console.log("f");
      });
    }

    if (selectUserStatus == "approvalStatus_rejected") {
      UpdateUserStatusInActiveCourse({
        uid: uid,
        courseId: data[0].courseId,
        groupId: data[0]?.groupId ? data[0]?.groupId : "testxbcvdhd",
        activeStatus: false,
      });
    }

    if (selectUserStatus == "approvalStatus_approved") {
      let st = selectUserStatus.split("_")[0];
      let statatys = selectUserStatus.split("_")[1];
      let stsms = selectUserStatus.split("Status")[0] + "Sms";
      UpdateUserStatusInActiveCourse({
        uid: uid,
        courseId: data[0].courseId,
        groupId: data[0]?.groupId ? data[0]?.groupId : "testxbcvdhd",
        activeStatus: true,
      });
      SelectedInCourseHttps({
        userId: uid,
        coursename: data[0].courseName,
        params: {
          courseId: data[0].courseId,
          attempt: data[0].attempt,
          img: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.vecteezy.com%2Fvector-art%2F6086198-notification-icon-for-web&psig=AOvVaw0Y-_kHylgjWh7t3uGUm7Rf&ust=1674367924256000&source=images&cd=vfe&ved=0CBAQjRxqFwoTCIDWs-aA2PwCFQAAAAAdAAAAABAE",
          [st]: statatys,
          [stsms]: rejectsms,
        },
      }).then((res) => {
        console.log("f");
      });
    }

    if (selectUserStatus == "paymentStatus_refunded") {
      let st = selectUserStatus.split("_")[0];
      let statatys = selectUserStatus.split("_")[1];
      let stsms = selectUserStatus.split("Status")[0] + "Sms";
      PaymentRefundedHttps({
        userId: uid,
        coursename: data[0].courseName,
        params: {
          courseId: data[0].courseId,
          attempt: data[0].attempt,
          img: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.vecteezy.com%2Fvector-art%2F6086198-notification-icon-for-web&psig=AOvVaw0Y-_kHylgjWh7t3uGUm7Rf&ust=1674367924256000&source=images&cd=vfe&ved=0CBAQjRxqFwoTCIDWs-aA2PwCFQAAAAAdAAAAABAE",
          [st]: statatys,
          [stsms]: rejectsms,
        },
      }).then((res) => {
        console.log("hfj");
      });
    }
    if (selectUserStatus == "agreementStatus_reupload") {
      let st = selectUserStatus.split("_")[0];
      let statatys = selectUserStatus.split("_")[1];
      let stsms = selectUserStatus.split("Status")[0] + "Sms";
      AgreementReuploadHttps({
        userId: uid,
        coursename: data[0].courseName,

        params: {
          courseId: data[0].courseId,
          attempt: data[0].attempt,
          img: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.vecteezy.com%2Fvector-art%2F6086198-notification-icon-for-web&psig=AOvVaw0Y-_kHylgjWh7t3uGUm7Rf&ust=1674367924256000&source=images&cd=vfe&ved=0CBAQjRxqFwoTCIDWs-aA2PwCFQAAAAAdAAAAABAE",
          [st]: statatys,
          [stsms]: rejectsms,
        },
      });
    }

    if (selectUserStatus == "documentsVerificationStatus_reupload") {
      let st = selectUserStatus.split("_")[0];
      let statatys = selectUserStatus.split("_")[1];
      let stsms = selectUserStatus.split("Status")[0] + "Sms";
      DocumentsReuploadHttps({
        userId: uid,
        coursename: data[0].courseName,
        params: {
          courseId: data[0].courseId,
          attempt: data[0].attempt,
          img: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.vecteezy.com%2Fvector-art%2F6086198-notification-icon-for-web&psig=AOvVaw0Y-_kHylgjWh7t3uGUm7Rf&ust=1674367924256000&source=images&cd=vfe&ved=0CBAQjRxqFwoTCIDWs-aA2PwCFQAAAAAdAAAAABAE",
          [st]: statatys,
          [stsms]: rejectsms,
        },
      }).then((res) => {
        console.log("hfj");
      });
    }

    alert("updated");
  };

  const delfunction = (name) => {
    console.log(name);

    let st = selectUserStatus.split("_")[0];
    let statatys = selectUserStatus.split("_")[1];
    setopenLessonPopUp(false);

    PaymentPassedPassHttps({
      userId: uid,
      coursename: data[0].courseName,
      params: {
        courseId: data[0].courseId,
        attempt: data[0].attempt,
        img: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.vecteezy.com%2Fvector-art%2F6086198-notification-icon-for-web&psig=AOvVaw0Y-_kHylgjWh7t3uGUm7Rf&ust=1674367924256000&source=images&cd=vfe&ved=0CBAQjRxqFwoTCIDWs-aA2PwCFQAAAAAdAAAAABAE",
        [st]: statatys,
      },
    });
    console.log(`${st}Date`);

    let courseagree = data[0].courseId + "_agreementForm";

    uploadUserAgreement({
      uid: uid,
      courseagree: courseagree,
      agreementUrl: name.body,
    })
      .then((res) => {
        UpdateUserStatus({
          attempts: data[0].attempt,
          uid: uid,
          courseId: data[0].courseId,
          attemps: "first",

          userStatus: selectUserStatus ? selectUserStatus : data[0].userStatus,
          [st]: statatys,
          [`${st}Date`]: new Date(),
        });
      })
      .catch((e) => {
        alert("Something gone wrong");
      });
  };

  const coordinaterSt = () => {
    // console.log(coordinatoreData);
    // setcoordinatorId(coordinatoreData);
    setopenCoordinater(true);
  };

  const popUpCoordinator = (name) => {
    setcoordinatorId(name.id);

    addCoordinatoreIdInUser({
      coordinatorId: name.id,
      attempts: data[0].attempt,
      uid: uid,
      courseId: data[0].courseId,
    });
    setopenCoordinater(false);
  };

  return (
    <div
      className="admin-overdue-bottom"
      style={{
        padding: "20px",
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
      }}
    >
      <div>
        <LinkPopUp
          open={openLessonPopUp}
          onClose={() => setopenLessonPopUp(false)}
          delfun={(topic) => {
            delfunction(topic);
          }}
        />

        <div
          style={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: 500 }}
        >
          {t("Update User Status")}
        </div>
        <div style={{ fontFamily: "Poppins" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginTop: "15px",
            }}
          >
            <label> Status : </label>
            <select
              className="admin-course-main-top-select"
              value={selectUserStatus}
              onChange={(e) => {
                setRejectsms("");
                setSelectUserStatus(e.target.value);
              }}
            >
              <option value="">select option </option>
              {/* <option value="UserApplied"> User Applied </option> */}
              {/* <option value="UnderEvaluation"> Under Evaluation </option> */}

              {/* <option value="UserRegister"> User Register </option> */}
              <option value="objectiveTestStatus_passed">
                {" "}
                Objective test passed{" "}
              </option>
              <option value="objectiveTestStatus_rejected">
                {" "}
                Objective test failed{" "}
              </option>
              <option value="subjectiveTestStatus_rejected">
                Subjective test failed
              </option>
              <option value="subjectiveTestStatus_passed">
                {" "}
                Subjective test passed
              </option>
              <option value="paymentStatus_passed"> Payment Received </option>
              {/* <option value="PaymentPending"> Payment Pending </option> */}
              <option value="paymentStatus_refunded"> Payment refunded </option>
              {/* <option value="AggrementDone">Aggrement Done</option> */}
              {/* <option value="AggrementPending">Aggrement Pending </option> */}
              <option value="documentsVerificationStatus_reupload">
                Resubmit registration form
              </option>
              <option value="documentsVerificationStatus_passed">
                Registration details accepted
              </option>
              <option value="documentsVerificationStatus_rejected">
                Registration details rejected{" "}
              </option>

              <option value="agreementStatus_reupload">
                Resubmit documents
              </option>
              <option value="agreementStatus_passed">Documents approved</option>
              <option value="agreementStatus_rejected">
                Documents rejected{" "}
              </option>

              <option value="approvalStatus_rejected">
                Sorry! You are not selected{" "}
              </option>
              <option value="approvalStatus_approved">
                Congratulations! You are selected
              </option>

              {/* <option value=" RejectedFromThisCourse"> Rejected From This Course</option> */}
            </select>
          </div>

          {selectUserStatus.includes("passed") ? (
            <div
              style={{
                fontFamily: "Poppins",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <label> Comments: </label>
              <textarea
                style={{
                  border: "1px solid gray",
                  borderRadius: "5px",
                  marginTop: "15px",
                  minHeight: "100px",
                  minWidth: "300px",
                }}
                type="text"
                value={rejectsms}
                disabled={selectUserStatus.includes("passed") ? true : false}
                onChange={(e) => {
                  setRejectsms(e.target.value);
                }}
              ></textarea>
            </div>
          ) : (
            <div
              style={{
                fontFamily: "Poppins",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <label> Comments: </label>
              <textarea
                style={{
                  border: "1px solid gray",
                  borderRadius: "5px",
                  marginTop: "15px",
                  minHeight: "100px",
                  minWidth: "300px",
                }}
                type="text"
                value={rejectsms}
                onChange={(e) => {
                  setRejectsms(e.target.value);
                }}
              />
            </div>
          )}

          <div>
            <button
              onClick={() => {
                submitUserDetails();
              }}
              className="primary-btn"
              style={{ marginTop: "20px" }}
            >
              {" "}
              Update User Status
            </button>
          </div>
        </div>
      </div>
      <div>
        <div>
          <CoordinatorePopUp
            open={openCoordinater}
            onClose={() => setopenCoordinater(false)}
            coorId={coordinatorId}
            delfun={(topic) => {
              popUpCoordinator(topic);
            }}
          />
          <button
            onClick={() => {
              coordinaterSt();
            }}
            className="primary-btn"
            style={{ marginTop: "20px" }}
          >
            {" "}
            Select Coordinator
          </button>
        </div>{" "}
      </div>
    </div>
  );
};

export default ChnageUserStatus;
