import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";
import { useSelector } from "react-redux";
import { getDocs, Timestamp } from "firebase/firestore";
const UserAgeAndDob = ({ uid, data, flag }) => {
  const { clrs } = useSelector((state) => state.createClr);

  //console.log(data);

  const datetohum = (data) => {
    return Timestamp.fromMillis(data?.dob?._seconds * 1000)
      .toDate()
      .toDateString();
  };
  return (
    <div className="admin-overdue-bottom" style={{ padding: "20px" }}>
      <div style={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: 500 }}>
        {t("User Details")}
      </div>
      <div
        style={{
          fontFamily: "Poppins",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",

            marginTop: "15px",
          }}
        >
          <div
            className="admin-overdue-mid-user-view-cnt"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              fontSize: "18px",
              fontFamily: "Poppins",
            }}
          >
            <div> {t("Gender")} :</div>
            <div className="admin-overdue-mid-user-view-txt-dim">
              {data?.gender}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginTop: "15px",
          }}
        >
          <div
            className="admin-overdue-mid-user-view-cnt"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              fontSize: "18px",
              fontFamily: "Poppins",
            }}
          >
            <div> {t("Alternative Phone No")} :</div>
            <div className="admin-overdue-mid-user-view-txt-dim">
              {data?.alternatePhoneNumber}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginTop: "15px",
          }}
        >
          <div
            className="admin-overdue-mid-user-view-cnt"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              fontSize: "18px",
              fontFamily: "Poppins",
            }}
          >
            <div> {t("Date Of Birth")} :</div>
            <div className="admin-overdue-mid-user-view-txt-dim">
              {datetohum(data)}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginTop: "15px",
          }}
        >
          <div
            className="admin-overdue-mid-user-view-cnt"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              fontSize: "18px",
              fontFamily: "Poppins",
            }}
          >
            <div> {t("Linked In URL")} :</div>
            <div
              className="admin-overdue-mid-user-view-txt-dim"
              // onClick={()=>{
              //     window.open(data?.linkedInUrl);
              // }}
            >
              <a href={data?.linkedInUrl} target="_blank">
                {" "}
                {data?.linkedInUrl}
              </a>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginTop: "15px",
          }}
        >
          {/* <div
                className="admin-overdue-mid-user-view-cnt"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  fontSize: "18px",
                  fontFamily: "Poppins",
          
                }}
              >
                <div> {t("Age")} :</div>
                <div className="admin-overdue-mid-user-view-txt-dim">
                  {data?.age}
                </div>
              </div> */}
        </div>
      </div>
    </div>
  );
};

export default UserAgeAndDob;
