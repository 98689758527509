import Sidebar from "../../../Component/Sidebar";
import NavbarTop from "../../../Component/NavbarTop";
import { t } from "i18next";
import { Button } from "@mui/material";

import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";
import { useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useNavigate } from "react-router";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const CompanyDepartmentCreate = () => {
  const navigate = useNavigate();
  const [color, setColor] = useColor("hex", "#121212");

  const [image, setImage] = useState({
    file: null,
    url: "",
  });
  const [base64Image, setBase64Image] = useState("");

  const createCompany = httpsCallable(functions, "createCompany");

  const [details, setDetails] = useState({
    name: "",
    logo: "",
    brandColor: {
      bgColor: "",
      headingTextColor: "",
      dashboardColor: "",
      bodyTextColor: "",
      primaryBtnColor: "",
      secondaryBtnColor: "",
    },
    companyId: "",
  });

  const handleChange = (e) => {
    const name = e.target.name;
    setDetails({
      ...details,
      brandColor: { ...details.brandColor, [name]: e.target.value },
    });
  };

  // logo change

  const handleFileChange = (e) => {
    const { files } = e.target;
    const url = URL.createObjectURL(files[0]);
    setImage((prev) => ({
      file: files[0],
      url: url,
    }));
    const reader = new FileReader();
    reader.onloadend = () => {
      setBase64Image(reader.result);
      //   console.log("setBase64Image done", reader.result);
      setDetails({ ...details, logo: reader.result });
    };
    reader.readAsDataURL(files[0]);
  };

  const [subloading, setSubloading] = useState(false);
  const handleSubmit = () => {
    //  console.log("details", details);
    if (
      details.name === "" ||
      details.logo === "" ||
      details.brandColor.bgColor === "" ||
      details.brandColor.headingTextColor === "" ||
      details.brandColor.dashboardColor === "" ||
      details.brandColor.bodyTextColor === "" ||
      details.brandColor.primaryBtnColor === "" ||
      details.brandColor.secondaryBtnColor === ""
    ) {
      alert("Please fill all the fields");
      return;
    }
    setSubloading(true);
    createCompany(details)
      .then((res) => {
        // console.log("res", res);
        setSubloading(false);
        navigate("/admin/settings/course-question-Category");
      })
      .catch((err) => {
        console.log(err);
        setSubloading(false);
      });
  };

  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Question category and Course category")} />
        {/* <Navbar /> */}

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              <Button
                variant="contained"
                color="success"
                style={{ marginBottom: "20px" }}
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "100px",
                }}
              >
                <div>
                  <h3>Question category</h3>
                  <br />
                  <input
                    name="companyName"
                    value={details.name}
                    onChange={(e) =>
                      setDetails({
                        ...details,
                        name: e.target.value,
                        companyId:
                          e.target.value.split(" ").join("_") +
                          "_" +
                          new Date().getTime().toString().split(" ").join("_"),
                      })
                    }
                    style={{ padding: "5px", fontSize: "18px" }}
                    type="text"
                  />
                </div>
                <div>
                  <h3>Company Logo</h3>
                  <br />

                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<FileUploadIcon />}
                  >
                    Upload
                    <input
                      name="logo"
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Button>
                </div>
                {image.url && (
                  <div style={{ width: "150px", height: "150px" }}>
                    <img
                      src={image?.url}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "50px",
                }}
              >
                <h1 className="bigger-container-header">Brand Details</h1>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "50px",
                }}
              >
                <div>
                  <h3>Background Color</h3>
                  <input
                    name="bgColor"
                    value={details.brandColor.bgColor}
                    onChange={handleChange}
                    style={{ padding: "5px", fontSize: "18px" }}
                    type="text"
                  />
                </div>
                <div>
                  <h3>Heading Text Color</h3>
                  <input
                    name="headingTextColor"
                    value={details.brandColor.headingTextColor}
                    onChange={handleChange}
                    style={{ padding: "5px", fontSize: "18px" }}
                    type="text"
                  />
                </div>
                <div>
                  <h3>Dashboard Color</h3>
                  <input
                    name="dashboardColor"
                    value={details.brandColor.dashboardColor}
                    onChange={handleChange}
                    style={{ padding: "5px", fontSize: "18px" }}
                    type="text"
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "50px",
                }}
              >
                <div>
                  <h3>Body Text Color</h3>
                  <input
                    name="bodyTextColor"
                    value={details.brandColor.bodyTextColor}
                    onChange={handleChange}
                    style={{ padding: "5px", fontSize: "18px" }}
                    type="text"
                  />
                </div>
                <div>
                  <h3>Primary Button Color</h3>
                  <input
                    name="primaryBtnColor"
                    value={details.brandColor.primaryBtnColor}
                    onChange={handleChange}
                    style={{ padding: "5px", fontSize: "18px" }}
                    type="text"
                  />
                </div>
                <div>
                  <h3>Secondary Color</h3>
                  <input
                    name="secondaryBtnColor"
                    value={details.brandColor.secondaryBtnColor}
                    onChange={handleChange}
                    style={{ padding: "5px", fontSize: "18px" }}
                    type="text"
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ColorPicker
                  width={456}
                  height={228}
                  color={color}
                  onChange={setColor}
                  hideHSV
                  dark
                />
                ;
              </div>

              <div style={{ textAlign: "right" }}>
                <Button
                  disabled={subloading}
                  variant="contained"
                  component="label"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
