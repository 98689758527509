import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";
import { useSelector } from "react-redux";
import { display } from "@mui/system";

const QualificationAndExp = ({ uid, data, flag }) => {
  const { clrs } = useSelector((state) => state.createClr);


  console.log(data)

  return (
    <div className="admin-overdue-bottom" style={{ padding: "20px", display:"grid", height: "182px", gridTemplateColumns:"1fr 1fr",overflowY:"scroll" }}>
     
       
      <div> 
      <div style={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: 500 }}>
        {t("Qualification")}
      </div>
      <div style={{ fontFamily: "Poppins" ,
     display: "grid",
     gridTemplateColumns:"1fr"}}>

{
                data?.qualification?.map((ele)=> 
                <div style={{border:"1px dotted black",margin:"0px 30px 3px 0px" ,borderRadius:"10px"}}> 
                <div
                style={{
                  display: "grid",
                  gridTemplateColumns:"1fr",
                 
                  marginTop: "15px",
                }}
              >
      
      
                    <div
                      className="admin-overdue-mid-user-view-cnt"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        fontSize: "18px",
                        fontFamily: "Poppins",
                      }}
                    >
        
                      <div> {t("Degree")} :</div>
                      <div className="admin-overdue-mid-user-view-txt-dim">
                        {ele?.degree}
                      </div>
                    </div>
      
                    
              </div>

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns:"1fr",
                 
                  marginTop: "15px",
                }}
              >
      
      
                    <div
                      className="admin-overdue-mid-user-view-cnt"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        fontSize: "18px",
                        fontFamily: "Poppins",
                      }}
                    >
        
                      <div> {t("Passedout year")} :</div>
                      <div className="admin-overdue-mid-user-view-txt-dim">
                        {ele?.passoutYear}
                      </div>
                    </div>
      
                    
              </div>


              <div
                style={{
                  display: "grid",
                  gridTemplateColumns:"1fr",
                 
                  marginTop: "15px",
                }}
              >
      
      
                    <div
                      className="admin-overdue-mid-user-view-cnt"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        fontSize: "18px",
                        fontFamily: "Poppins",
                      }}
                    >
        
                      <div> {t("University Name")} :</div>
                      <div className="admin-overdue-mid-user-view-txt-dim">
                        {ele?.universityName}
                      </div>
                    </div>
      
                    
              </div>
              
              </div>)
            }
       

        

        
     
        
      </div>
      </div>


{/* inexp */}
      <div> 

      <div style={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: 500 }}>
        {t("Experience")}
      </div>
      <div style={{ fontFamily: "Poppins" ,
     display: "grid",
     gridTemplateColumns:"1fr",}}>
       {
                data?.experience?.map((ele)=> 
                <div style={{border:"1px dotted black",margin:"0px 30px 3px 0px" ,borderRadius:"10px"}}> 
                <div
                style={{
                  display: "grid",
                  gridTemplateColumns:"1fr",
                 
                  marginTop: "15px",
                }}
              >
      
      
                    <div
                      className="admin-overdue-mid-user-view-cnt"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        fontSize: "18px",
                        fontFamily: "Poppins",
                      }}
                    >
        
                      <div> {t("Company Name")} :</div>
                      <div className="admin-overdue-mid-user-view-txt-dim">
                        {ele?.companyName}
                      </div>
                    </div>
      
                    
              </div>

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns:"1fr",
                 
                  marginTop: "15px",
                }}
              >
      
      
                    <div
                      className="admin-overdue-mid-user-view-cnt"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        fontSize: "18px",
                        fontFamily: "Poppins",
                      }}
                    >
        
                      <div> {t("Duration")} :</div>
                      <div className="admin-overdue-mid-user-view-txt-dim">
                        {ele?.duration}
                      </div>
                    </div>
      
                    
              </div>


              <div
                style={{
                  display: "grid",
                  gridTemplateColumns:"1fr",
                 
                  marginTop: "15px",
                }}
              >
      
      
                    <div
                      className="admin-overdue-mid-user-view-cnt"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        fontSize: "18px",
                        fontFamily: "Poppins",
                      }}
                    >
        
                      <div> {t("Position")} :</div>
                      <div className="admin-overdue-mid-user-view-txt-dim">
                        {ele?.position}
                      </div>
                    </div>
      
                    
              </div>
              
              </div>)
            }
     
      </div>
      </div>
    </div>
  );
};

export default QualificationAndExp;
