import { httpsCallable } from "firebase/functions";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { Link } from "react-router-dom";
import "../../../App.css";
import { getCookie } from "../../../Cookies";
import { db, functions, auth } from "../../../Firebase";
import Navbar from "../../Component/NavbarTop";
import Sidebar from "../../Component/Sidebar";
import { Timestamp } from "firebase/firestore";
import "../StyleAdminComponents/User.css";
import "./Users.css";
import EnterBodyAndTittel from "../courseOverView/bodyAndti";
import LoadingScreen from "../BasicCourse/lodingScree";

function UsersListForFunnelView() {
  const navigate = useNavigate();
  const location = useLocation();
  const dataFromLocation = location.state;

  //console.log(dataFromLocation)
  const uploadReports = httpsCallable(functions, "uploadReports");
  const manualNotification = httpsCallable(functions, "manualNotification");
  const goToAddNewUser = (state) =>
    navigate("/admin/addNewUser", { state: { ...state } });
  const goToEditUser = (state) =>
    navigate("/admin/userview", { state: { ...state } });
  const goToHome = () => navigate("/");
  const [valueSelect, setvalue] = useState("");
  const [loadingScreen, setLoadingScreen] = useState(false);
  const initState = {
    name: "",
    employeeId: "",
    company: "",
    department: "",
    jobTitle: "",
    role: "",
    selected: "default",
    phoneNumber: "",
    email: "",
  };

  const [openLessonPopUp, setopenLessonPopUp] = useState(false);
  const [query, setQuery] = useState(initState);
  useEffect(() => {
    if (getCookie("UID") == null || getCookie("ROLE") != "admin") {
      goToHome();
      return <></>;
    }
  });
  const [showLoading, setLoading] = useState(true);

  const [userArray, setUserArray] = useState([]);
  const [details, setDetails] = useState([]);
  const getUserSatusFullDataWithCourseIdAndstatsu = httpsCallable(
    functions,
    "getUserSatusFullDataWithCourseIdAndstatsu"
  );
  const archiveUser = httpsCallable(functions, "archiveUser");
  const unarchiveUser = httpsCallable(functions, "unarchiveUser");
  const { clrs } = useSelector((state) => state.createClr);
  const [arrLength, setArrLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [userJobTitle, setUserJobTitle] = useState([]);
  const [userCompany, setUserCompany] = useState([]);
  const [userDepart, setUserDepart] = useState([]);
  const [stateTempForfetchUserList, setStateTempForfetchUserList] =
    useState(true);

  const [tempLoading, setTemploading] = useState("test");
  const handleQueryChange = (e) => {
    setQuery({
      ...query,
      [e.target.name]: e.target.value,
    });
  };
  const setDefault = () => {
    setQuery(initState);
    setDetails(userArray);
    setArrLength(userArray.length);
    return;
  };
  const handleSearch = (name) => {
    const value = query[name];
    if (value == "") {
      setDetails(userArray);
      setArrLength(userArray.length);
      return;
    }
    let temp = [];
    if (name == "phoneNumber") {
      temp = userArray.filter((item) => item[name]?.includes(value));
    } else {
      temp = userArray.filter((item) =>
        item[name]?.toLowerCase().includes(value.toLowerCase())
      );
    }

    // console.log(temp);
    setDetails(temp);
    setArrLength(temp.length);
    // console.log(temp);
    setCurrentPage(0);
  };
  const handleStatusChange = (e) => {
    setQuery({
      ...query,
      role: e.target.value,
    });
    console.log(e.target.name);
    console.log(e.target.value);
    if (e.target.value == "default") {
      setDetails(userArray);
      setArrLength(userArray.length);
      return;
    }
    // if(role==)
    const temp = userArray.filter((item) => item.role == e.target.value);
    // console.log(temp);
    setDetails(temp);
    setArrLength(temp.length);
    // console.log(temp);
    setCurrentPage(0);
  };
  useEffect(() => {
    if (dataFromLocation) {
      setUserArray(dataFromLocation);
      setDetails(dataFromLocation);
      setArrLength(dataFromLocation.length);
      setLoading(false);

      // setUserJobTitle([])
    } else {
      setLoading(false);
    }

    // getUserSatusFullDataWithCourseIdAndstatsu({courseId:dataFromLocation.courseId,
    //     userStatus:dataFromLocation.name
    //     ,

    // }).then((res) => {
    //   if (res.data.error == "No matches found") {
    //     setUserArray([]);
    //     setDetails([]);
    //     setArrLength(0);
    //     setUserJobTitle([])
    //     setUserCompany([])
    //     setUserDepart([])
    //     setLoading(false);
    //   } else {
    //     setUserArray(res.data?.map((item) => ({ ...item, selected: false })));
    //     setDetails(res.data?.map((item) => ({ ...item, selected: false })));

    //    console.log(res.data)
    //       setCurrentPage(0);

    //     setLoading(false);
    //   }
    // });
  }, [stateTempForfetchUserList]);
  // console.log(userArray);
  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(arrLength / pageSize)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };

  const handleChange = (event, item, id) => {
    var data = event.target.value;
    setvalue(event.target.value);

    if (data == "edit") {
      goToAddNewUser(item);
    } else if (data == "view") {
      goToEditUser(item);
    }
  };

  const checkBoxHelder = (data, item, type) => {
    if (type == "all") {
      let temparr = details.map((e) => ({ ...e, select: item.target.checked }));
      setDetails(temparr);
    }

    if (type == "one") {
      let temparr = details.map((e) => {
        if (e.uid == data.uid) {
          return { ...e, select: item.target.checked };
        } else {
          return e;
        }
      });
      setDetails(temparr);
    }
  };

  const generateReport = () => {
    //console.log(auth)

    let fil = [];

    let userList = details.filter((e) => e.select == true);
    if (userList.length > 0) {
      //etopenLessonPopUp(true)
    } else {
      alert("select user");
      return;
    }

    setLoadingScreen(true);
    for (let i = 0; i < details.length; i++) {
      if (details[i].select == true) {
        let obj = {};
        obj["name"] = details[i]?.name;
        obj["id"] = details[i].uid;

        obj["email"] = details[i]?.email;
        obj["phoneNumber"] = details[i]?.phoneNumber;
        obj["courseId"] = details[i]?.courseId;

        obj["status"] = details[i]?.userStatus;
        fil.push(obj);
      }
    }
    let time = new Date().getTime();
    let name = getCookie("NAME");
    uploadReports({
      id: "testReport" + time,
      data: fil,
      reportType: "Entrence Course Report",
      userId: name,
    })
      .then((res) => {
        setLoadingScreen(false);
        console.log(res.data);
        alert("genreted");
      })
      .catch((e) => {
        setLoadingScreen(false);
      });
    console.log(fil);
  };

  const delfunction = (name) => {
    console.log(name);
    setopenLessonPopUp(false);
    let userList = details.filter((e) => e.select == true);

    manualNotification({
      body: name.body,
      trigger: name.title,
      userList: userList,

      params: {
        courseId: details[0].courseId ? details[0].courseId : "",
      },
    });
  };

  const sendNotification = () => {
    let userList = details.filter((e) => e.select == true);
    if (userList.length > 0) {
      setopenLessonPopUp(true);
    } else {
      alert("select user");
      return;
    }
  };
  return (
    <div className="admin-users">
      <Navbar page={t("Users")} />
      <div className="admin-users-main">
        <EnterBodyAndTittel
          open={openLessonPopUp}
          onClose={() => setopenLessonPopUp(false)}
          delfun={(topic) => {
            delfunction(topic);
          }}
        />
        <LoadingScreen
          open={loadingScreen}
          onClose={() => setLoadingScreen(false)}
        />

        <Sidebar page={"user"} hidepage={false} />
        <div className="admin-overdue">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              alignItems: "center",
            }}
            className="admin-dashboard-main-mid"
          >
            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("Email Id")}
              </div>
              <div style={{ position: "relative" }}>
                <input
                  style={{ width: "11vw" }}
                  name="email"
                  value={query.email}
                  type="text"
                  onChange={handleQueryChange}
                  placeholder="Search By Employee No."
                />
                <div
                  className="admin-dashboard-main-top-last"
                  style={{
                    position: "absolute",
                    top: "-0.5vw",
                    right: "1.1vw",
                  }}
                >
                  <button
                    className="primary-btn-input"
                    onClick={() => handleSearch("email")}
                  >
                    {t("Search")}
                  </button>
                </div>
              </div>
            </div>

            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("Mobile Number")}
              </div>
              <div style={{ position: "relative" }}>
                <input
                  style={{ width: "11vw" }}
                  name="phoneNumber"
                  value={query.phoneNumber}
                  type="text"
                  onChange={handleQueryChange}
                  placeholder="Search By Employee No."
                />
                <div
                  className="admin-dashboard-main-top-last"
                  style={{
                    position: "absolute",
                    top: "-0.5vw",
                    right: "1.1vw",
                  }}
                >
                  <button
                    className="primary-btn-input"
                    onClick={() => handleSearch("phoneNumber")}
                  >
                    {t("Search")}
                  </button>
                </div>
              </div>
            </div>

            <div class="admin-dashboard-main-top-last">
              <button className="clear-btn" onClick={setDefault}>
                {t("Clear Filters")}
              </button>
            </div>

            <div
              class="admin-dashboard-main-top-last"
              style={{
                display: "flex",
                gap: "20px",
              }}
            >
              <button className="primary-btn-input" onClick={sendNotification}>
                {t("Send Notification")}
              </button>

              <button className="primary-btn-input" onClick={generateReport}>
                {t("Generate Pdf")}
              </button>
            </div>
          </div>

          <div style={{ height: "8vw" }} className="courselist-main-cnt-top">
            {/*     <div className="courselist-main-cnt-top-txt">Group List</div> */}
            <div
              style={{
                marginTop: "6vw",
                marginLeft: "0vw",
              }}
              className="head"
            >
              {t("UserList")}
            </div>
          </div>
          <div
            style={{ margin: "0vw 2vw 2vw 2vw" }}
            className="admin-overdue-bottom"
          >
            <div className="admin-overdue-bottom-table">
              <div
                className="admin-overdue-bottom-table-cnt-usr"
                style={{
                  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                }}
              >
                <div className="admin-overdue-bottom-table-head">
                  <input
                    type="checkbox"
                    onClick={(e) => {
                      checkBoxHelder("", e, "all");
                    }}
                  />
                  {t("Sr.No.")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Name")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Email")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Mobile Number")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("status")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("courseId")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("date")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("attemps")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Action")}
                </div>
              </div>
              {showLoading && (
                <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td">
                    <div className="spinner-container">
                      <div className="loading-spinner"></div>
                    </div>
                  </div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div
                    className="admin-overdue-bottom-table-td"
                    style={{ color: "#004577" }}
                  ></div>
                </div>
              )}

              {details ? (
                details.map((item, id) => {
                  if (id < pageSize * currentPage) return;
                  if (id >= pageSize * (currentPage + 1)) return;
                  let joiningDate = Timestamp.fromMillis(
                    item?.date?._seconds * 1000
                  )
                    .toDate()
                    .toDateString();
                  return (
                    <div
                      className="admin-overdue-bottom-table-cnt-usr"
                      key={id}
                      style={{
                        gridTemplateColumns:
                          "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                      }}
                    >
                      <div
                        className="admin-overdue-bottom-table-td"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "20px 20px",
                          margin: "auto",
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={item.select ? true : false}
                          onClick={(e) => {
                            checkBoxHelder(item, e, "one");
                          }}
                        />

                        {id + 1}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item?.name}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item?.email}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item?.phoneNumber}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item?.userStatus}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item?.courseId}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {joiningDate}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item?.attemps}
                      </div>
                      <div
                        className="admin-overdue-bottom-table-td"
                        style={{ color: "#004577" }}
                      >
                        <select
                          style={{
                            border: "none",
                            backgroundColor: "#F2F2F2",
                            height: "4vh",
                            width: "7vw",
                            padding: "0 1vh",
                            fontWeight: "700",
                            color: "#717579",
                            borderRadius: "6px",
                          }}
                          onChange={(event) => handleChange(event, item, id)}
                        >
                          <option>{t("Action")}</option>
                          <option value={"view"}>{t("view")}</option>
                        </select>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No user </p>
              )}
            </div>
            <div className="admin-overdue-bottom-pagination">
              <div className="admin-overdue-bottom-pagination-cnt">
                <div className="admin-overdue-bottom-pagination-cnt-item">
                  <svg
                    onClick={() => clickhandler(currentPage - 1)}
                    width="auto"
                    height="22"
                    viewBox="0 0 14 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div className="admin-overdue-bottom-pagination-cnt-item-btn">
                  {currentPage + 1} of {Math.ceil(arrLength / pageSize)}
                </div>
                <div
                  style={{ marginRight: "19vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <svg
                    onClick={() => clickhandler(currentPage + 1)}
                    width="auto"
                    height="20"
                    viewBox="0 0 13 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div
                  style={{ display: "flex", marginRight: "-23vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <label className="admin-row">{t("Show")}</label>
                  <select
                    style={{
                      width: "4vw",
                      margin: "0.5vw",
                      marginBottom: "1.5vw",
                      height: "1.5vw",
                    }}
                    value={pageSize}
                    onChange={(e) => setPageSize(e.target.value)}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                  </select>
                  <label className="admin-row"> {t("PagePerItem")}</label>
                </div>
              </div>
            </div>
            <div>
              {/*    <Link to={"/admin/addNewUser"}>
              <button>add new user</button>
            </Link> */}
              {/*  <Link to={"/admin/userview"}>
              <button>userview</button>
            </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UsersListForFunnelView;
