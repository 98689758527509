import { getDocs, Timestamp } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getCookie } from "../../../Cookies";
import { db, functions } from "../../../Firebase";
import Navbar from "../../Component/NavbarTop";
import Sidebar from "../../Component/Sidebar";
import "../GroupEnrollments.css";
import "../StyleAdminComponents/GroupList.css";

function GroupEnrollmentMileStone() {
  const [togarl, settogal] = useState(false);
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const getAllMileStoneDataForCourse = httpsCallable(
    functions,
    "getAllMileStoneDataForCourse"
  );
  const getGroupEnrollmentsForSingleId = httpsCallable(
    functions,
    "getGroupEnrollmentsForSingleId"
  );

  const addMilstoneOrderIngroupEnrollment = httpsCallable(
    functions,
    "addMilstoneOrderIngroupEnrollment"
  );
  const [enrollment, setEnrollments] = useState(null);
  const { clrs } = useSelector((state) => state.createClr);
  const [pageSize, setPageSize] = useState(5);
  const [showLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [arrLength, setArrLength] = useState(0);
  const [action, setAction] = useState("action");
  const [details, setDetails] = useState([]);

  const [handelUseEffectTogal, setHandelUseEffectTogal] = useState(true);
  const [tempLoading, setTemploading] = useState("test");
  const initState = {
    courseName: "",
    groupName: "",
    courseType: "demo",
  };
  const [query, setQuery] = useState(initState);
  console.log("enrollment", enrollment);
  const [editDataState, setEditDataState] = useState([]);
  const location = useLocation();
  const courseIdFromlocation = location.state.courseId;
  const groupEnrollmentId = location.state.enrollmentId;

  useEffect(() => {
    if (
      getCookie("UID") == null ||
      (getCookie("ROLE") != "admin" && getCookie("ROLE") != "instructor")
    ) {
      goToHome();
      return <></>;
    }

    getAllMileStoneDataForCourse({ courseId: courseIdFromlocation }).then(
      (result) => {
        if (result.data?.error) {
          console.log(result.data?.error);
          setEnrollments([]);
          setDetails([]);
          setArrLength(0);
          setLoading(false);
          return;
        }

        let Arr = [];
        getGroupEnrollmentsForSingleId({
          groupEnrollmentId: groupEnrollmentId,
        }).then((r) => {
          if (r.data?.milestoneArr) {
            console.log(r.data.milestoneArr);
            for (let i = 0; i < result.data.length; i++) {
              let obj = {};
              let flag = false;
              for (let j = 0; j < r.data.milestoneArr.length; j++) {
                if (
                  result.data[i].mileStoneId ===
                  r.data.milestoneArr[j].mileStoneId
                ) {
                  flag = true;
                  Arr.push({ ...result.data[i], ...r.data.milestoneArr[j] });
                }
              }
              if (!flag) {
                Arr.push(result.data[i]);
              }
            }
          } else {
            Arr = result.data;
          }

          setEditDataState(Arr);
          setEnrollments(Arr);
          setDetails(Arr);
          console.log(Arr);
          setArrLength(Arr.length);
          setLoading(false);
        });
      }
    );
  }, [handelUseEffectTogal]);

  const handleAction = (event, type, id) => {
    let data = event.target.value;
    if (data == "") return;
    let TempData = editDataState;

    let obj = TempData[id];
    if (type == "visible") {
      obj["visibalType"] = data;
    } else if (type == "active") {
      obj["activeType"] = data;
    }
    TempData[id] = obj;
    setEditDataState(TempData);

    setEnrollments(TempData);
    setDetails(TempData);
    settogal(!togarl);
    console.log(TempData);
  };

  const submitData = (id, item) => {
    addMilstoneOrderIngroupEnrollment({
      enrollmentId: groupEnrollmentId,
      milestoneArr: editDataState,
    }).then((res) => {
      console.log(res.data);
    });
  };
  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(arrLength / 5)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };

  return (
    <div className="admin-groupEnrol">
      <Navbar page={t("Group Enrollments MileStone")} />
      <div className="admin-groupEnrol-main">
        <Sidebar page={"enrollments"} hidepage={false} />
        <div className="admin-overdue">
          <div style={{ height: "10vw" }} className="courselist-main-cnt-top">
            {/*     <div className="courselist-main-cnt-top-txt">Group List</div> */}
            <div
              style={{
                marginTop: "7vw",
                marginLeft: "0vw",
                width: "23vw",
              }}
              className="head"
            >
              {t("ListOfMileStone")}
            </div>
            <div></div>
          </div>

          <div
            style={{ margin: "0vw 2vw 2vw 2vw" }}
            className="admin-overdue-bottom"
          >
            <div className="admin-overdue-bottom-table">
              <div
                className="admin-overdue-bottom-table-cnt-grpeden"
                style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr" }}
              >
                <div className="admin-overdue-bottom-table-head">
                  {t("SRNo")}
                </div>

                <div className="admin-overdue-bottom-table-head">
                  {t("mileStoneName")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("mileStoneId")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("createdOn")}
                </div>

                <div className="admin-overdue-bottom-table-head">
                  {t("visible/invisible")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("active/inactive")}
                </div>
              </div>
              {showLoading && (
                <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td">
                    <div className="spinner-container">
                      <div className="loading-spinner"></div>
                    </div>
                  </div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div
                    className="admin-overdue-bottom-table-td"
                    style={{ color: "#004577" }}
                  ></div>
                </div>
              )}
              {details?.map((item, id) => {
                if (id < pageSize * currentPage) return;
                if (id >= pageSize * (currentPage + 1)) return;

                let createdOn = Timestamp.fromMillis(
                  item.created?._seconds * 1000
                )
                  .toDate()
                  .toDateString();
                return (
                  <div
                    className="admin-overdue-bottom-table-cnt-grpeden"
                    style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr" }}
                    key={id}
                  >
                    <div className="admin-overdue-bottom-table-td">
                      {id + 1}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item.mileStoneName}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item.mileStoneId}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {" "}
                      {createdOn}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      <div>
                        <select
                          style={{
                            border: "none",
                            backgroundColor: "#F2F2F2",
                            height: "4vh",
                            width: "7vw",
                            padding: "0 1vh",
                            fontWeight: "700",
                            color: "#717579",
                            borderRadius: "6px",
                          }}
                          onChange={(e) => handleAction(e, "visible", id)}
                          value={item?.visibalType}
                        >
                          <option value="">{t("select ststus")}</option>

                          <option value={"visible"}>{t("visible")}</option>
                          <option value={"inVisible"}>{t("inVisible")}</option>
                        </select>
                      </div>
                    </div>

                    <div
                      className="admin-overdue-bottom-table-td"
                      style={{ color: "#004577" }}
                    >
                      <div>
                        <select
                          style={{
                            border: "none",
                            backgroundColor: "#F2F2F2",
                            height: "4vh",
                            width: "7vw",
                            padding: "0 1vh",
                            fontWeight: "700",
                            color: "#717579",
                            borderRadius: "6px",
                          }}
                          onChange={(e) => handleAction(e, "active", id)}
                          value={item?.activeType}
                        >
                          <option value="">{t("select ststus")}</option>

                          <option value={"active"}>{t("active")}</option>
                          <option value={"inActive"}>{t("inActive")}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="admin-overdue-bottom-pagination">
              <div className="admin-overdue-bottom-pagination-cnt">
                <div className="admin-overdue-bottom-pagination-cnt-item">
                  <svg
                    onClick={() => clickhandler(currentPage - 1)}
                    width="auto"
                    height="22"
                    viewBox="0 0 14 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div className="admin-overdue-bottom-pagination-cnt-item-btn">
                  {currentPage + 1} of {Math.ceil(arrLength / pageSize)}
                </div>
                <div
                  style={{ marginRight: "19vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <svg
                    onClick={() => clickhandler(currentPage + 1)}
                    width="auto"
                    height="20"
                    viewBox="0 0 13 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div
                  style={{ display: "flex", marginRight: "-23vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <label className="admin-row">{t("Show")}</label>
                  <select
                    style={{
                      width: "4vw",
                      margin: "0.5vw",
                      marginBottom: "1.5vw",

                      height: "1.5vw",
                    }}
                    className="rowInput"
                    value={pageSize}
                    onChange={(e) => setPageSize(e.target.value)}
                    type="number"
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                  </select>
                  <label className="admin-row"> {t("PagePerItem")}</label>
                </div>
              </div>
            </div>

            <div
              style={{ height: "100%", width: "6vw", backgroundColor: "white" }}
            ></div>
            <div className="courselist-main-cnt-back">
              <div className="courselist-main-cnt-back-btn">
                <button className="primary-btn" onClick={submitData}>
                  {t("Save")}
                </button>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupEnrollmentMileStone;
