import React, { createRef, useEffect } from "react";
import { useState } from "react";
import $ from "jquery";
import CreateSubQuestion from "./createSubQuestion";
import { SvgVector, EditSvg, ExpandMoreSvg, DeleteSVg } from "./icons";
import { functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import { Timestamp } from "firebase/firestore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { ExpandMoreSharp } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./createTopic.css";
import DeleteLesson from "./lessonDeletePopUp";
const CreateSubMilestone = ({
  editQuizArr,
  topicId,
  courseId,
  mileStoneId,
  name,
}) => {
  const initialList = [];
  const [list, setList] = useState(initialList);
  const createSubMileStoneQuiz = httpsCallable(
    functions,
    "createSubMileStoneQuiz"
  );
  const addExtraInfoForSubMileStone = httpsCallable(
    functions,
    "addExtraInfoForSubMileStone"
  );
  const getAllMileStoneSubjectiveQuiz = httpsCallable(
    functions,
    "getAllMileStoneSubjectiveQuiz"
  );
  const addMileStoneSubOrder = httpsCallable(functions, "addMileStoneSubOrder");
  const addLessonQuizOrder = httpsCallable(functions, "addLessonQuizOrder");
  const addTopicQuizOrder = httpsCallable(functions, "addTopicQuizOrder");
  const getQuizInfo = httpsCallable(functions, "getQuizInfo");
  const getQuestionSetInfo = httpsCallable(functions, "getQuestionSetInfo");
  const deleteMileStoneSubQuiz = httpsCallable(
    functions,
    "deleteMileStoneSubQuiz"
  );
  // deleteQuiz

  const [deleteQuizId, setDeleteQuizId] = useState("");
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);

  const [quizId, setQuizId] = useState("");
  const [editable, setEditable] = useState(false);
  const [checkFailed, setCheckFailed] = useState(false);
  const [faqAns, setFaqAns] = useState("");
  const [FAQ, setFAQ] = useState([]);

  const [add, setAdd] = useState(false);
  const [quizDetails, setQuizDetails] = useState({
    quizName: "f",
    passScore: "gjg",
    retry: "0",
    answerAll: "yes",
    timeLimit: "gjg",
    randomizeQuestions: "yes",
    skipQuestion: "yes",
    randomAnswers: "yes",
    successMessage: "You Successfully completed the Quiz",
    retakeMessage: "Better luck next time",
    instruction: "flow the instruction",
  });
  const [checkErrorInForm, setCheckErrorInForm] = useState({
    quizName: "f",
    passScore: "ghgh",
    retry: "0",
    answerAll: "yes",
    timeLimit: "hgh",
    randomizeQuestions: "yes",
    skipQuestion: "yes",
    randomAnswers: "yes",
    successMessage: "You Successfully completed the Quiz",
    retakeMessage: "Better luck next time",
    instruction: "flow the instruction",
  });

  useEffect(() => {
    if (!editQuizArr) return;
    getAllMileStoneSubjectiveQuiz({ mileStoneId: mileStoneId }).then((res) => {
      //console.log(res.data);
      for (let i = 0; i < res.data.length; i++) {
        setList((prev) =>
          prev.concat({
            id: res.data[i].quizId,
            name: res.data[i].quizName,

            retry: res.data[i].retry,

            instruction: res.data[i].instruction,
          })
        );
      }
    });
  }, [editQuizArr]);

  const quizFormRef = createRef();

  const handleInput = (e) => {
    const { value, name } = e.target;
    setQuizDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  function checkForm() {
    if (quizDetails.quizName == "") {
      setCheckErrorInForm((prev) => ({ ...prev, quizName: "error" }));
      return "quizname";
    }
    if (quizDetails.retry === "0") {
      setCheckErrorInForm((prev) => ({ ...prev, retry: "error" }));
      return "retry";
    }
    if (FAQ.length === 0) {
      setCheckErrorInForm((prev) => ({ ...prev, instruction: "error" }));
      return "instruction";
    }
    let res = "allIsWell";

    return res;
  }

  function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }
    if (topicId != null) {
      addTopicQuizOrder({
        topicId: topicId,
        quizzes: orderList,
        edit: true,
      });
    } else if (mileStoneId != null) {
      addMileStoneSubOrder({
        mileStoneId: mileStoneId,
        subjectiveQuiz: quizId,
        edit: true,
      });
    }
  }
  const addQuiz = async () => {
    let check = checkForm();
    if (check != "allIsWell") {
      //console.log(check);
      setCheckFailed(true);
      return;
    }
    setCheckErrorInForm({
      quizName: "",
      passScore: "",
      retry: "0",
      answerAll: "yes",
      timeLimit: "",
      randomizeQuestions: "yes",
      skipQuestion: "yes",
      randomAnswers: "yes",
      successMessage: "",
      retakeMessage: "",
      instruction: "",
    });
    setCheckFailed(false);

    const newList = list.concat({
      id: quizId,
      name: quizDetails.quizName,

      retake: quizDetails.retakeMessage,

      random: quizDetails.randomizeQuestions,
      skip: quizDetails.skipQuestion,
      randomAnswer: quizDetails.randomAnswers,
      instruction: FAQ,
    });
    createSubMileStoneQuiz({
      quizId: quizId,
      created: new Date().toString(),
      quizName: quizDetails.quizName,

      associatedMileStone: mileStoneId,
      courseAssociated: courseId,
      instruction: FAQ,
      noOfRetries: quizDetails.retry,
    });
    addExtraInfoForSubMileStone({
      quizId: quizId,
    });

    setList(newList);
    saveOrder(newList);
    alert("Added");

    setQuizDetails({
      quizName: "",
      passScore: "",
      retry: "",
      answerAll: "yes",
      timeLimit: "",
      randomizeQuestions: "yes",
      skipQuestion: "yes",
      randomAnswers: "yes",
      successMessage: "",
      retakeMessage: "",
      instruction: "",
    });

    toggleForm();
  };

  const toggleForm = (e) => {
    quizFormRef.current.style.display =
      quizFormRef.current.style.display === "none" ? "" : "none";
    setQuizDetails({
      quizName: "",
      passScore: "",
      retry: "0",
      answerAll: "yes",
      timeLimit: "",
      randomizeQuestions: "yes",
      skipQuestion: "yes",
      randomAnswers: "yes",
      successMessage: "You Successfully completed the Quiz",
      retakeMessage: "Better luck next time",
      instruction: "flow",
    });
    setQuizId("");
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    saveOrder(items);
  }

  function edit(quiz) {
    setEditable(true);
    quizFormRef.current.style.display = "";
    let params = {
      quizName: quiz.name,
      passScore: quiz.passScore,
      retry: quiz.retry,
      answerAll: quiz.answerAll,
      timeLimit: quiz.timeLimit,
      randomizeQuestions: quiz.random,
      skipQuestion: quiz.skip,
      randomAnswers: quiz.randomAnswer,
      successMessage: quiz.success,
      retakeMessage: quiz.retake,
    };
    setQuizDetails(params);
    setFAQ(quiz.instruction);
    setQuizId(quiz.id);
  }

  function updateQuiz() {
    let check = checkForm();
    if (check != "allIsWell") {
      setCheckFailed(true);
      // console.log("update ", check);
      return;
    }
    setCheckFailed(false);

    createSubMileStoneQuiz({
      quizId: quizId,
      created: new Date().toString(),
      quizName: quizDetails.quizName,

      associatedMileStone: mileStoneId,
      courseAssociated: courseId,
      instruction: FAQ,
    });
    addExtraInfoForSubMileStone({
      quizId: quizId,
      noOfRetries: quizDetails.retry,
    });

    for (let i = 0; i < list.length; i++) {
      if (quizId === list[i].id) {
        list[i] = {
          id: quizId,
          name: quizDetails.quizName,
          passScore: quizDetails.passScore,
          retry: quizDetails.retry,
          timeLimit: quizDetails.timeLimit,
          success: quizDetails.successMessage,
          retake: quizDetails.retakeMessage,
          answerAll: quizDetails.answerAll,
          random: quizDetails.randomizeQuestions,
          skip: quizDetails.skipQuestion,
          randomAnswer: quizDetails.randomAnswers,
          instruction: FAQ,
        };
      }
    }
    setList(list);
    alert("Updated");
    setEditable(false);
    quizFormRef.current.style.display = "none";
  }

  async function del(quiz) {
    // implement delete api done
    setopenLessonPopUp(true);
    setDeleteQuizId(quiz);
  }
  const delfunction = (quiz) => {
    deleteMileStoneSubQuiz({ quizId: quiz.id });
    alert("Deleted " + quiz.name);
    const item = [];
    for (let i = 0; i < list.length; i++) {
      if (quiz.id !== list[i].id) {
        item.push(list[i]);
      }
    }
    setList(item);
    saveOrder(item);
  };

  function addfun() {
    // console.log(add);

    setAdd(!add);
  }

  function addFAQ() {
    let data = FAQ;
    data.push({ data: faqAns });

    setFAQ(data);

    setFaqAns("");
    setAdd(false);
  }

  const deleteEligibilty = (dd) => {
    let data = [];
    for (let i = 0; i < FAQ.length; i++) {
      if (FAQ[i].data != dd) data.push(FAQ[i]);
    }
    setFAQ(data);
  };

  return (
    <>
      <DeleteLesson
        open={openLessonPopUp}
        onClose={() => setopenLessonPopUp(false)}
        delfun={(topic) => {
          delfunction(topic);
        }}
        topicId={deleteQuizId}
      />
      <section className="createQuiz-container">
        <button
          className="primary-btn"
          onClick={() => {
            setEditable(false);
            toggleForm();
          }}
        >
          {name}
        </button>
        <div
          className="createQuiz-quizForm"
          ref={quizFormRef}
          id={"quizForm"}
          style={{ display: "none" }}
        >
          <div className="createQuiz-heading">Quiz</div>
          <div className="createQuiz-row">
            <p
              className="createQuiz-inputLabel"
              style={{
                fontWeight: 500,
                color: "#171B1E",
                width: "20%",
              }}
            >
              Quiz Name *
            </p>
            <input
              type="text"
              className="createQuiz-inputBar"
              id={topicId + courseId + mileStoneId + "quizName"}
              style={{
                border: "1px solid #d7d7d7",
                width: "30%",
                borderRadius: "6px",
                outline: "none",
                padding: "15px",
              }}
              name="quizName"
              value={quizDetails.quizName}
              onChange={(event) => {
                handleInput(event);
                setCheckErrorInForm((prev) => ({ ...prev, quizName: "" }));
                if (!editable) {
                  let value = event.target.value.replaceAll(" ", "");
                  let time = new Date().getTime();
                  setQuizId(value + "_" + time);
                }
              }}
            />{" "}
            {checkErrorInForm.quizName == "error" && (
              <span
                style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
              >
                Enter Quiz Name
              </span>
            )}
          </div>

          {/* <div className="createQuiz-row">
            <p className="createQuiz-inputLabel">Quiz instruction</p>
            <textarea
              type="text"
              className="createQuiz-textBar"
              id={topicId + courseId + mileStoneId + "instruction"}
              value={quizDetails.instruction}
              name="instruction"
              onChange={handleInput}
            />
          </div>
          */}
          <div className="createQuiz-row">
            <p
              className="createQuiz-inputLabel"
              style={{
                fontWeight: 500,
                color: "#171B1E",
                width: "20%",
              }}
            >
              No fo retry *
            </p>
            <input
              type="number"
              value={quizDetails.retry}
              className="createQuiz-inputBar"
              style={{
                border: `${
                  checkErrorInForm.retry == "error"
                    ? "2px solid red"
                    : "1px solid #d7d7d7"
                }`,
                width: "30%",
                borderRadius: "6px",
                outline: "none",
                padding: "15px",
              }}
              id={topicId + courseId + mileStoneId + "noofretry"}
              name="retry"
              onChange={(e) => {
                handleInput(e);
                setCheckErrorInForm((prev) => ({ ...prev, retry: "" }));
              }}
            />
            {checkErrorInForm.retry == "error" && (
              <span
                style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
              >
                Enter Quiz retry
              </span>
            )}
          </div>

          {/* <div className="createQuiz-row">
            <p className="createQuiz-inputLabel">Success Message</p>
            <input
              type="text"
              className="createQuiz-inputBar"
              id={topicId + courseId + mileStoneId + "successMessage"}
              value={quizDetails.successMessage}
              name="successMessage"
              onChange={handleInput}
            />
          </div> */}

          {/* <div className="createQuiz-row">
            <p className="createQuiz-inputLabel">Retake Message</p>
            <input
              type="text"
              className="createQuiz-inputBar"
              id={topicId + courseId + mileStoneId + "retakeMessage"}
              value={quizDetails.retakeMessage}
              name="retakeMessage"
              onChange={handleInput}
            />
          </div> */}

          <div className="course-builder-info">
            <div
              className="course-builder-inputLable"
              style={{ marginTop: "2vw" }}
            >
              Quiz instruction{" "}
              <span>
                {" "}
                <button
                  className="primary-btn-input"
                  onClick={() => {
                    addfun();

                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      instruction: "",
                    }));
                  }}
                >
                  +
                </button>
              </span>
            </div>
            {checkErrorInForm.instruction == "error" && (
              <span
                style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
              >
                Enter Quiz instruction
              </span>
            )}
          </div>

          {FAQ?.map((e, id) => (
            <>
              <div className="course-builder-info">
                <p
                  // className="course-builder-inputLable"
                  style={{
                    fontWeight: 500,
                    color: "#171B1E",
                    width: "20%",
                  }}
                >
                  instruction {id + 1}
                </p>
                <input
                  type="text"
                  // className="course-builder-inputBar"
                  value={e.data}
                  style={{
                    border: `${
                      checkErrorInForm.trainingProvider == "error"
                        ? "2px solid red"
                        : "1px solid #d7d7d7"
                    }`,
                    width: "30%",
                    borderRadius: "6px",
                    outline: "none",
                    padding: "15px",
                  }}
                  id="trainingProvider"
                />
                <button
                  className="course-builder-button"
                  style={{
                    background:
                      "linear-gradient(92.2deg, #1E3758 0.25%, #0475B5 100%)",
                    marginLeft: "5vw",
                  }}
                  onClick={() => {
                    deleteEligibilty(e.data);
                  }}
                >
                  {" "}
                  Delete
                </button>
              </div>
            </>
          ))}

          {add == true && (
            <>
              <div className="course-builder-info">
                <p
                  className="course-builder-inputLable"
                  style={{
                    fontWeight: 500,
                    color: "#171B1E",
                    width: "20%",
                  }}
                >
                  instruction
                </p>
                <input
                  type="text"
                  className="course-builder-inputBar"
                  value={faqAns}
                  style={{
                    border: `${
                      checkErrorInForm.trainingProvider == "error"
                        ? "2px solid red"
                        : "1px solid #d7d7d7"
                    }`,
                    width: "30%",
                    borderRadius: "6px",
                    outline: "none",
                    padding: "15px",
                  }}
                  id="trainingProvider"
                  onChange={(event) => {
                    setFaqAns(event.target.value);
                  }}
                />
              </div>

              <button id="addC" className="secondary-btn" onClick={addFAQ}>
                Save
              </button>
            </>
          )}

          {checkFailed && <p>Enter the Required Fields</p>}
          <div className="createQuiz-row" style={{ marginTop: "30px" }}>
            <button
              className="primary-btn"
              onClick={!editable ? addQuiz : updateQuiz}
            >
              Save Quiz
            </button>
          </div>
        </div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <ul
                id="quizList"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: "block",
                  overflow: "hidden",
                  width: "100%",
                  listStyleType: "none",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                {list.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        key={item.id}
                        className="quiz-dragable"
                      >
                        <Accordion>
                          <AccordionSummary
                            {...provided.dragHandleProps}
                            expandIcon={<ExpandMoreSvg />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                              backgroundColor: "#1E3758",
                              color: "white",
                              borderRadius: "5px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <SvgVector />
                              <div className="quiz-accordion-heading">
                                {item.name}
                              </div>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="quiz-accordion-details-head">
                              <p>{item.name}</p>
                              <div
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "10px",
                                }}
                                onClick={() => del(item)}
                              >
                                <DeleteSVg size={28} />
                              </div>
                              <div onClick={() => edit(item)}>
                                <EditSvg size={28} />
                              </div>
                            </div>
                            <p>Associated course -{item.id} </p>

                            <CreateSubQuestion
                              editQuizArr={editQuizArr}
                              questionData={item.questionData}
                              mileStoneQuizId={item.id}
                              mileStoneId={mileStoneId}
                              type={"mileStone"}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </section>
    </>
  );
};

export default CreateSubMilestone;
