import React, { useState,useEffect } from "react";
import { t } from "i18next";
import { Timestamp } from "firebase/firestore";

const GroupMemners = ({ groupMembers, setGroupMembers,editGroupList }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const[showLoading,setShowLoading]=useState(false)
  const[ArrLength,setArrLength]=useState(true)
  const arrLength = groupMembers ? groupMembers.length : 0;
  // if(arrLength==0){
  //   setArrLength(false);
  // }
  const clickhandler = (i) => {
    if (i < 0) return;
   
    if (i + 1 > Math.ceil(arrLength / 5)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };
  useEffect(() => {
    // if (groupMembers) {
    //   setShowLoading(false);
      
    // }
    // if (arrLength>=0) {
    //   setShowLoading(false);
      
    // }
    if(editGroupList){
      setShowLoading(false)
      setArrLength(false)
    }
    
   else if (arrLength>0) {
    
      setArrLength(false)
    }
  },[groupMembers]);
  const handleCheck = (e, id) => {
    setArrLength(false)
    console.log("i am group"+groupMembers)
    setGroupMembers((prev) => {
      let newArr = Array.from(prev);
      newArr[id].checked = e.target.checked;
      console.log(newArr[id]);
      console.log(newArr)
     
      return newArr;
    });
  };

  return (
    <div style={{ margin: "0vw 2vw 2vw 2vw" }} className="admin-overdue-bottom">
      <div className="admin-overdue-bottom-table">
        <div
          style={{ gridTemplateColumns: "repeat(6, 1fr)" }}
          className="admin-overdue-bottom-table-cnt-grp"
        >
          <div className="admin-overdue-bottom-table-head">{t("EmployeeNo")}</div>
          <div className="admin-overdue-bottom-table-head">{t("Name")}</div>
          <div className="admin-overdue-bottom-table-head">{t("whydo")}</div>
          <div className="admin-overdue-bottom-table-head">{t("phoneNumber")}</div>
          {/* <div className="admin-overdue-bottom-table-head">{t("level")}</div>
          <div className="admin-overdue-bottom-table-head">{t("JoiningDate")}</div> */}
          <div className="admin-overdue-bottom-table-head">{t("Role")}</div>
          <div className="admin-overdue-bottom-table-head">{t("EmailID")}</div>
        </div>
        {editGroupList&&showLoading && (
          <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
            <div className="admin-overdue-bottom-table-td"></div>
            <div className="admin-overdue-bottom-table-td"></div>
            <div className="admin-overdue-bottom-table-td"></div>
            <div className="admin-overdue-bottom-table-td"></div>
            <div className="admin-overdue-bottom-table-td">
              <div className="spinner-container">
                <div className="loading-spinner"></div>
              </div>
            </div>
            <div className="admin-overdue-bottom-table-td"></div>
            <div className="admin-overdue-bottom-table-td"></div>
            <div className="admin-overdue-bottom-table-td"></div>
            <div className="admin-overdue-bottom-table-td" style={{ color: "#004577" }}></div>
          </div>
        )}
        {groupMembers?.map((item, id) => {
          if (id < 5 * currentPage) return;
          if (id >= 5 * (currentPage + 1)) return;
          // let joiningDate = Timestamp.fromMillis(item.joiningDate._seconds * 1000)
          //   .toDate()
          //   .toDateString();
          return (
            <div className="admin-overdue-bottom-table-cnt-grped"    style={{ gridTemplateColumns: "repeat(6, 1fr)" }}key={id}>
              <div className="admin-overdue-bottom-table-td">
                {/* <input
                  style={{
                    height: "1vw",
                    width: "2vw",
                    marginRight: "2vw",
                    marginLeft: "-3vw",
                  }}
                  onChange={(e) => handleCheck(e, id)}
                  checked={item.checked}
                  type="checkbox"
                /> */}
                {item.employeeId}
              </div>
              
              <div className="admin-overdue-bottom-table-td">{item?.name}</div>
              <div className="admin-overdue-bottom-table-td">{item?.whydo}</div>
              <div className="admin-overdue-bottom-table-td">{item?.phoneNumber}</div>
              {/* <div className="admin-overdue-bottom-table-td">{item?.level}</div>
              <div className="admin-overdue-bottom-table-td">{"joiningDate"}</div> */}
              <div className="admin-overdue-bottom-table-td">{item.role?item.role:item.identify
}</div>
              <div className="admin-overdue-bottom-table-td">{item.email}</div>
            </div>
          );
        })}
      </div>
      {!editGroupList&&ArrLength&& (
        <div style={{ color: "#818181", width: "100%", textAlign: "center" }}>
         You dont have any member in Group
        </div>
      )}
      <div className="admin-overdue-bottom-pagination">
        <div className="admin-overdue-bottom-pagination-cnt">
          <div className="admin-overdue-bottom-pagination-cnt-item">
            <svg
              onClick={() => clickhandler(currentPage - 1)}
              width="auto"
              height="22"
              viewBox="0 0 14 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                fill="#717579"
              />
            </svg>
          </div>
          <div className="admin-overdue-bottom-pagination-cnt-item-btn">
            {currentPage + 1} of {Math.ceil(arrLength / 5)} 
          </div>
          <div className="admin-overdue-bottom-pagination-cnt-item">
            <svg
              onClick={() => clickhandler(currentPage + 1)}
              width="auto"
              height="20"
              viewBox="0 0 13 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                fill="#717579"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupMemners;
