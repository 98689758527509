import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { functions } from "../../../Firebase";
import "./AdminSetting.css";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Sidebar from "../../Component/Sidebar";
import NavbarTop from "../../Component/NavbarTop";
import { t } from "i18next";
import { YourSkill } from "./YourSkill";
import { WhoAreYou } from "./whoAreYou";

export const UserFilterInAppQuestions = () => {
  const [showdepform, setShowdepform] = useState(false);
  const [showQuestionForm, setShowQuestionForm] = useState(false);
  //================company settings===========================//
  const [template, settemplate] = useState([]);
  const [deltemp, setdeltemp] = useState(false);
  const [showLoading, setLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState("");
  const [flagForEdit, setFlagForEdit] = useState(false);
  const fetchQuestionSetCotegory = httpsCallable(
    functions,
    "fetchQuestionSetCotegory"
  );
  const createWhyDoYouWantToJoin = httpsCallable(
    functions,
    "createWhyDoYouWantToJoin"
  );
  const getWhyDoYouWantToJoin = httpsCallable(
    functions,
    "getWhyDoYouWantToJoin"
  );
  const deleteWhyDoYouWantToJoin = httpsCallable(
    functions,
    "deleteWhyDoYouWantToJoin"
  );

  const [loading1, setLoading1] = useState(false);
  const [deledep, setDeldep] = useState(true);
  const [base64Img, setBase64Img] = useState("");

  const [questionhide, setQuestionhide] = useState(true);
  const [optionId, setOptionId] = useState("");
  // delete company
  const imageRef = useRef(null);

  const onImageClick = () => {
    imageRef.current.click();
  };
  // get company and department data
  const fetchdata = () => {
    setLoading1(true);
    getWhyDoYouWantToJoin({ type: "IdentifyYourself" })
      .then((res) => {
        setLoading1(false);
        // console.log(res);
        settemplate(res.data);
      })
      .catch((err) => {
        setLoading1(false);
        // console.log(err);
      });
  };

  //================make default settings===========================//
  const makeQuestionCategoryDefault = httpsCallable(
    functions,
    "makeQuestionCategoryDefault"
  );

  //================create Department===========================//

  const [saveloading, setSaveloading] = useState(false);
  const [depName, setDepName] = useState("");
  const [questionName, setQuestionName] = useState("");

  const saveDataOnFireStore = () => {
    // console.log({
    //   name: questionName,
    //   base64Img: base64Img ? base64Img : imageUrl,
    //   id: optionId,
    // });
    if (!imageUrl) {
      // setSaveloading(true);

      alert("image required");
      return;
    }

    if (questionName == "") {
      alert("Name is required");
      return;
    }
    setSaveloading(true);

    createWhyDoYouWantToJoin({
      optionName: questionName,
      optionImg: base64Img ? base64Img : imageUrl,
      optionId: optionId,
      type: "IdentifyYourself",
      hide: questionhide,
    })
      .then((res) => {
        setSaveloading(false);
        fetchdata();
      })
      .catch((err) => {
        setSaveloading(false);
      });

    setQuestionName("");
    setOptionId("");
    setBase64Img("");
    setShowQuestionForm(false);
    setFlagForEdit(false);
  };

  useEffect(() => {
    fetchdata();
  }, [deltemp]);

  const handleCourseImage = (e) => {
    let reader = new FileReader();
    // console.log(e);

    let filenameType = e.target.value.split(".");

    //  console.log(filenameType[filenameType.length - 1]);

    let filetypedata = filenameType[filenameType.length - 1].toLowerCase();
    if (
      filetypedata == "png" ||
      filetypedata == "jpeg" ||
      filetypedata == "jpg"
    ) {
    } else {
      alert("only jpeg  , gif  ,png  file accepted");
      e.target.value = null;

      return;
    }

    let rfilesize = Number(e.target.files[0].size) / 1024;

    rfilesize = rfilesize / 1024;
    //console.log(rfilesize);
    if (Number(rfilesize) > 5) {
      alert("size must be less than 5 mb");

      e.target.value = null;
      return;
    } else {
      // console.log(rfilesize);
      let url = URL.createObjectURL(e.target.files[0]);
      setImageUrl(url);

      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function () {
        // console.log(reader.result);
        setBase64Img(reader.result);
      };
    }
  };

  const handelChnage = (type, name, img, item, hideval) => {
    if (type == "edit") {
      setOptionId(item);
      setFlagForEdit(true);
      setShowQuestionForm(true);
      setQuestionName(name);
      setImageUrl(img);
      setQuestionhide(hideval);
    } else if (type == "delete") {
      setLoading1(true);
      deleteWhyDoYouWantToJoin({
        optionId: item,
      })
        .then((rse) => {
          setLoading1(false);
          fetchdata();
        })
        .catch((err) => {
          setLoading1(false);
        });
    }
  };

  //console.log(questionhide);
  const navigate = useNavigate();
  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("User Onboarding Questions")} />
        {/* <Navbar /> */}

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              {/* Company settings */}

              {/* <Button
                variant="contained"
                color="success"
                style={{ marginBottom: "20px" }}
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(-1)}
              >
                Back
              </Button> */}

              <div style={{ display: "flex", justifyContent: "end" }}>
                <button
                  className="primary-btn"
                  onClick={() =>
                    navigate("/admin/settings/CategoryForWellComeQuestion")
                  }
                >
                  + Category Create
                </button>
              </div>

              {/* Question Settings */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                  margin: "30px 0px",
                }}
              >
                <h1
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                  }}
                >
                  Identify Yourself
                </h1>

                <div className="create-new-box">
                  <button
                    className="secondary-btn"
                    onClick={() => {
                      setShowQuestionForm(!showQuestionForm);
                      setFlagForEdit(false);
                    }}
                  >
                    + Add option
                  </button>
                </div>
              </div>

              {showQuestionForm && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                    marginBottom: "20px",
                  }}
                >
                  <div>
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: 500,
                        fontFamily: "Poppins",
                        color: "#1E3758",
                      }}
                    >
                      Option Name:{" "}
                    </p>
                    <input
                      value={questionName}
                      style={{
                        margin: "10px 0px",
                        padding: "5px",
                        fontSize: "18px",
                        border: "1px solid #1E3758",
                        outline: "none",
                        padding: "15px",
                        borderRadius: "5px",
                        backgroundColor: "transparent",
                      }}
                      type="text"
                      onChange={(e) => {
                        setQuestionName(e.target.value);

                        if (!flagForEdit) {
                          let value = e.target.value.replaceAll(" ", "");
                          let time = new Date().getTime();
                          setOptionId(value + "_" + time);
                        }
                      }}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "30px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: 500,
                        fontFamily: "Poppins",
                        color: "#1E3758",
                      }}
                    >
                      Hide:{" "}
                    </span>
                    <input
                      value={questionhide}
                      checked={questionhide == true ? true : false}
                      type="radio"
                      name="onOff"
                      onChange={(e) => {
                        setQuestionhide(!questionhide);
                      }}
                    />

                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: 500,
                        fontFamily: "Poppins",
                        color: "#1E3758",
                      }}
                    >
                      Unhide:{" "}
                    </span>
                    <input
                      //value={questionName}
                      value={questionhide}
                      checked={questionhide == false ? true : false}
                      type="radio"
                      name="onOff"
                      onChange={(e) => {
                        setQuestionhide(!questionhide);
                      }}
                    />
                  </div>
                  <div>
                    {/* <span style={{ fontSize: "25px" }}>Img: </span>
    <input
      style={{
        margin: "20px 5px",
        padding: "5px",
        fontSize: "18px",
      }}
      type="file"
     
    /> */}

                    <div className="course-builder-info">
                      {imageUrl == "" ? (
                        <div className="image-box" onClick={onImageClick}>
                          <button className="image-box-btn">
                            <div className="image-box-icon">
                              <svg
                                width="25"
                                height="26"
                                viewBox="0 0 25 26"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.27998 25.2535C2.42931 25.2535 1.70135 24.9509 1.09609 24.3456C0.489792 23.7393 0.186646 23.0108 0.186646 22.1602V17.5202H3.27998V22.1602H21.84V17.5202H24.9333V22.1602C24.9333 23.0108 24.6307 23.7393 24.0254 24.3456C23.4191 24.9509 22.6906 25.2535 21.84 25.2535H3.27998ZM11.0133 19.0668V6.4615L6.99198 10.4828L4.82665 8.24017L12.56 0.506836L20.2933 8.24017L18.128 10.4828L14.1066 6.4615V19.0668H11.0133Z"
                                  fill="#D9D9D9"
                                />
                              </svg>
                            </div>
                          </button>
                          <p className="image-box-text">Upload Image</p>
                        </div>
                      ) : (
                        <img
                          style={{
                            width: "20vw",
                            height: "8em",
                            objectFit: "cover",
                            borderRadius: "10px",
                          }}
                          onClick={onImageClick}
                          src={imageUrl}
                        ></img>
                      )}
                      <input
                        ref={imageRef}
                        style={{ display: "none" }}
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        className="course-builder-inputImg"
                        id="courseImage"
                        onChange={(e) => {
                          handleCourseImage(e);
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      disabled={saveloading}
                      className="primary-btn"
                      onClick={() => {
                        saveDataOnFireStore();
                      }}
                    >
                      {saveloading ? "Loading" : "save"}
                    </button>
                  </div>
                </div>
              )}

              <div
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid #1E3758",
                  padding: "20px",
                  borderRadius: "10px",
                }}
              >
                <div
                  className="bigger-container"
                  style={{ textAlign: "center", alignItems: "center" }}
                >
                  <div style={{ width: "30%", textAlign: "center" }}> Name</div>

                  <div style={{ width: "40%", textAlign: "center" }}>Img</div>
                  <div style={{ width: "20%", textAlign: "center" }}>
                    Action
                  </div>
                </div>

                <div
                  className="setting_details_table"
                  style={{ display: "grid" }}
                >
                  {loading1 && (
                    <div
                      className="admin-overdue-bottom-table-cnt-certi"
                      key={0}
                    >
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td">
                        <div className="spinner-container">
                          <div className="loading-spinner"></div>
                        </div>
                      </div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div
                        className="admin-overdue-bottom-table-td"
                        style={{ color: "#004577" }}
                      ></div>
                    </div>
                  )}

                  {template?.map((ele) => {
                    return (
                      <div
                        className="outer-inner-container"
                        style={{ textAlign: "center", alignItems: "center" }}
                      >
                        <div className="templatename" style={{ width: "30%" }}>
                          {ele.optionName}
                        </div>

                        <div
                          className="button-box"
                          style={{
                            width: "40%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "20px",
                          }}
                        >
                          <img src={ele.optionImg} width="40%" />{" "}
                        </div>

                        <div style={{ width: "20%" }}>
                          <select
                            style={{ fontSize: "16px", height: "30px" }}
                            onChange={(e) => {
                              handelChnage(
                                e.target.value,
                                ele.optionName,
                                ele.optionImg,
                                ele.optionId,
                                ele?.hide
                              );
                            }}
                          >
                            <option value="">Select</option>
                            <option value="edit">Edit</option>
                            <option value="delete">Delete</option>
                          </select>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {/* secondQuestionYourSkiil */}

            <YourSkill />
            <WhoAreYou />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <button
                className="secondary-btn"
                style={{ marginBottom: "30px" }}
                onClick={() => navigate(-1)}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
