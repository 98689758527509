import { t } from "i18next";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getCookie } from "../../Cookies";
import Navbar from "../Component/NavbarTop";
import Sidebar from "../Component/Sidebar";
import "./Trainings.css";
import EnteranceImg from "./imgFolder/imgOne.png";
import basicCouseImg from "./imgFolder/imgTwo.png";
import { Box, Button, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: "center",
  background: "#FBFBFB",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "20px",
}));

function Trainings() {
  const { clrs } = useSelector((state) => state.createClr);
  const navigate = useNavigate();
  const goToHome = () => navigate;
  useEffect(() => {
    if (
      getCookie("UID") == null ||
      (getCookie("ROLE") != "admin" && getCookie("ROLE") != "instructor")
    ) {
      goToHome();
      return <></>;
    }
  });

  return (
    <>
      {/* <div className="courselist">
      <Navbar page={t("Training")} />
      <div className="courselist-main">
        <Sidebar page={"training"} hidepage={false} />
        <div className="courselist-main-cnt">
          <div className="main_contaner_intra">
            <div className="firstContanerIndiv">
              <div className="innerDivInFirstDivSecond">
                <img id="imgid" src={EnteranceImg} />
                <div className="contentBoxIndiv">
                  <div>
                    <div className="courseNameHeding"> Entrance Course</div>
                    <div className="courseNameDIscrpription">
                      List of Entrance Course
                    </div>
                  </div>

                  <div className="buttenBoxIndiv">
                    <div>
                      <Link to={"/admin/training/internal"}>
                        <button
                          className="buttenDignIndiv"
                          style={{
                            background: `${
                              clrs.CourseListBtn
                                ? clrs.CourseListBtn
                                : "hwb(224deg 78% 3%)"
                            }`,
                          }}
                        >
                          <div>{t("View")}</div>
                        </button>
                      </Link>
                    </div>
                    <div>
                      <Link to={"/admin/courseBuilder"}>
                        <button
                          className="buttenDignIndiv"
                          style={{
                            background: `${
                              clrs.CourseListBtn
                                ? clrs.CourseListBtn
                                : "hwb(224deg 78% 3%)"
                            }`,
                          }}
                        >
                          <div>{t("Add")}</div>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="innerDivInFirstDiv">
hh
 </div> /}
            </div>

            <div className="firstContanerIndiv">
              <div className="innerDivInFirstDivSecond">
                <img
                  id="imgid"
                  src={basicCouseImg}
                  style={{ borderRadius: "5px", height: "169px" }}
                />
                <div className="contentBoxIndiv">
                  <div>
                    <div className="courseNameHeding"> Basic Course</div>
                    <div className="courseNameDIscrpription">
                      List Of Basic Course
                    </div>
                  </div>

                  <div className="buttenBoxIndiv">
                    <div>
                      <Link to={"/admin/training/extrenal"}>
                        <button
                          className="buttenDignIndiv"
                          style={{
                            background: `${
                              clrs.CourseListBtn
                                ? clrs.CourseListBtn
                                : "hwb(224deg 78% 3%)"
                            }`,
                          }}
                        >
                          <div>{t("View")}</div>
                        </button>
                      </Link>
                    </div>
                    <div>
                      <Link to={"/admin/basicCourse"}>
                        <button
                          className="buttenDignIndiv"
                          style={{
                            background: `${
                              clrs.CourseListBtn
                                ? clrs.CourseListBtn
                                : "hwb(224deg 78% 3%)"
                            }`,
                          }}
                        >
                          <div>{t("Add")}</div>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ width: "320px", height: "262px" }}></div>
          </div>
        </div>
      </div>
    </div> */}
      <Box>
        <Navbar page={t("Training")} />
        <Box sx={{ display: "flex" }}>
          <Sidebar page={"training"} hidepage={false} />
          <Box
            sx={{
              width: "100%",
              backgroundColor: "#F6F6F6",
              padding: "20px",
            }}
          >
            <Box
              className="custom-scrollbar"
              sx={{
                flexGrow: 1,
                height: "80vh",
                padding: "30px",
                backgroundColor: "#FFF",
                borderRadius: "5px",
                overflowY: "scroll",
              }}
            >
              <Grid container spacing={4}>
                <Grid item xs={12} md={6} lg={4}>
                  <Item>
                    <Box>
                      <Box sx={{ width: "100%" }}>
                        <img
                          src={EnteranceImg}
                          style={{ width: "90%", borderRadius: "10px" }}
                        />
                      </Box>
                      <Box sx={{ mt: "10px" }}>
                        <Box>
                          <Typography
                            sx={{ fontWeight: 500, fontSize: "18px" }}
                          >
                            Entrance Course
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              color: "rgba(113, 117, 121, 0.5)",
                              mt: "5px",
                            }}
                          >
                            List of Entrance Course
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10%",
                            mt: "15px",
                          }}
                        >
                          <Box>
                            <Link to={"/admin/training/internal"}>
                              <button className="primary-btn">
                                {t("View")}
                              </button>
                            </Link>
                          </Box>
                          <Box>
                            <Link to={"/admin/courseBuilder"}>
                              <button className="secondary-btn">
                                {t("Add")}
                              </button>
                            </Link>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Item>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Item>
                    <Box>
                      <Box sx={{ width: "100%" }}>
                        <img
                          src={basicCouseImg}
                          style={{ width: "90%", borderRadius: "10px" }}
                        />
                      </Box>
                      <Box sx={{ mt: "10px" }}>
                        <Box>
                          <Typography
                            sx={{ fontWeight: 500, fontSize: "18px" }}
                          >
                            Basic Course
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              color: "rgba(113, 117, 121, 0.5)",
                              mt: "5px",
                            }}
                          >
                            List Of Basic Course
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10%",
                            mt: "15px",
                          }}
                        >
                          <Box>
                      <Link to={"/admin/training/extrenal"}>
                            
                              <button className="primary-btn">
                                {t("View")}
                              </button>
                            </Link>
                          </Box>
                          <Box>
                            <Link to={"/admin/basicCourse"}>
                              <button className="secondary-btn">
                                {t("Add")}
                              </button>
                            </Link>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Item>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Trainings;
