import { Timestamp } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { functions } from "../../../Firebase";
import Navbar from "../../Component/NavbarTop";
import Sidebar from "../../Component/Sidebar";
import "../StyleAdminComponents/GroupList.css";
import Notify from "../Dashboard/Notify";
import "../Dashboard/OverdueEnrollments.css";

import { create } from "sortablejs";
import TempFileForTest from "../Users/tempFile";
function GroupEnrollmentFunnelView() {
  const { clrs } = useSelector((state) => state.createClr);
  const location = useLocation();
  const overDueInfo = location.state;
  console.log(overDueInfo);

  const navigate = useNavigate();

  const [displayNotify, setDisplayNotify] = useState(false);

  ///////Company DAta///////////

  const [cardData, setCardData] = useState([]);

  /////////////////////Group ////////

  const initState = {
    courseName: "default",
    date: "",
  };
  const [query, setQuery] = useState(initState);
  const stateDataForGroupName = useLocation();

  const courseId = stateDataForGroupName.state.courseId;
  const groupEnrollmentId = stateDataForGroupName.state.groupEnrollmentId;

  // console.log("courseName",courseName);

  const NotifyClick = () => {
    setDisplayNotify(!displayNotify);
  };

  return (
    <div className="section" style={{ backgroundColor: "#e5e5e5" }}>
      {displayNotify && <Notify NotifyClick={NotifyClick} />}
      <Navbar page={t("Enrollments")} />
      <div className="createcourse-main">
        <Sidebar page={"enrollments"} hidepage={true} />
        <div className="admin-overdue">
          <div className="admin-overdue-top">
            <div className="admin-overdue-top-item">
              {t("View Group Enrollment Funnel")}
            </div>
          </div>

          <div className="admin-overdue-mid" style={{}}>
            <div className="admin-overdue-mid-left">
              <div className="admin-overdue-mid-left-txt">
                {t("Question Set")}
              </div>
              <div className="admin-overdue-mid-left-input">
                <select name="groupStatus" id="group">
                  <option value="" selected disabled hidden>
                    Select a Set
                  </option>
                  <option value="all">All</option>
                  <option value="overdue">A</option>
                  <option value="complete">B</option>
                </select>
              </div>
            </div>

            <div className="admin-overdue-mid-right">
              {/* <div className="admin-overdue-mid-left-txt">{t("Date")}</div> */}
              <div className="admin-overdue-mid-left-input-right">
                {/* <input type="date" name="dueDate"  onChange={handleDateChange} /> */}
                <div
                  className="admin-dashboard-main-mid-item-last-filter"
                  style={{ marginTop: "5vh" }}
                >
                  {" "}
                  <div className="admin-dashboard-main-top-last-filter">
                    <button
                      className="clear-btn"
                      //   onClick={()=>{selectedDateTohumanDate("clear")}}
                    >
                      {t("Clear")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="admin-overdue-bottom">
            <TempFileForTest
              courseId={courseId}
              groupEnrollmentId={groupEnrollmentId}
            />
          </div>
        </div>
        <div
          style={{ height: "100%", width: "6vw", backgroundColor: "white" }}
        ></div>
        <div className="courselist-main-cnt-back">
          <div className="courselist-main-cnt-back-btn">
            <Link to={"/admin/groupEnrollment"}>
              <button className="secondary-btn">{t("Back")}</button>{" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupEnrollmentFunnelView;
