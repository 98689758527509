import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { functions } from "../../../Firebase";
import "./AdminSetting.css";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Sidebar from "../../Component/Sidebar";
import NavbarTop from "../../Component/NavbarTop";
import { t } from "i18next";
import { YourSkill } from "./YourSkill";
import { WhoAreYou } from "./whoAreYou";
import DeleteLesson from "./addNameForCategoryPopUp";
import LoadingScreen from "./loadingScreen";

export const CategoryForWellComeQuestion = () => {
  const [showdepform, setShowdepform] = useState(false);
  const [showQuestionForm, setShowQuestionForm] = useState(false);
  //================company settings===========================//
  const [template, settemplate] = useState([]);
  const [deltemp, setdeltemp] = useState(false);
  const [showLoading, setLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState("");
  const [flagForEdit, setFlagForEdit] = useState(false);
  const fetchQuestionSetCotegory = httpsCallable(
    functions,
    "fetchQuestionSetCotegory"
  );
  const createWelComeCategory = httpsCallable(
    functions,
    "createWelComeCategory"
  );
  const getWhyDoYouWantToJoin = httpsCallable(
    functions,
    "getWhyDoYouWantToJoin"
  );
  const deleteWhyDoYouWantToJoin = httpsCallable(
    functions,
    "deleteWhyDoYouWantToJoin"
  );

  const makeWelcomeDefault = httpsCallable(functions, "makeWelcomeDefault");
  const getCategoryWithIds = httpsCallable(functions, "getCategoryWithIds");

  const addCategoryDataInArr = httpsCallable(functions, "addCategoryDataInArr");
  const editwelcomecategory = httpsCallable(functions, "editwelcomecategory");
  const [loading1, setLoading1] = useState(false);
  const [deledep, setDeldep] = useState(true);
  const [base64Img, setBase64Img] = useState("");
  const [WhoAreYouData, setWhoAreYouData] = useState([]);
  const [whyDOYouWantToJoin, seWhyDOYouWantToJoin] = useState([]);
  const [optionId, setOptionId] = useState("");
  const [Loading2, setLoading2] = useState(false);
  const [Loading3, setLoading3] = useState(false);

  const [openLessonPopUp, setopenLessonPopUp] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [valuefromRadioCateOne, setValuefromRadioCateOne] = useState("");
  const [valuefromRadioCateTwo, setValuefromRadioCateTwo] = useState("");
  const [valuefromRadioCateThree, setValuefromRadioCateThree] = useState("");

  const [categoryName, setCategoryName] = useState();
  const [cateGoryData, setCateGoryData] = useState([]);
  const [editFlag, setEditFlag] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [flagforpopup, setFlagforpopup] = useState(false);
  // delete company

  const [extraArrForIds, setExtarArrForIds] = useState([]);
  const imageRef = useRef(null);

  const onImageClick = () => {
    imageRef.current.click();
  };
  // get company and department data
  const fetchdata = () => {
    setLoading1(true);
    getWhyDoYouWantToJoin({ type: "IdentifyYourself" })
      .then((res) => {
        setLoading1(false);
        // console.log(res);
        settemplate(res.data);
      })
      .catch((err) => {
        setLoading1(false);
        console.log(err);
      });
  };

  const fetchdataSecond = () => {
    setLoading2(true);
    getWhyDoYouWantToJoin({ type: "WhoAreYou" })
      .then((res) => {
        setLoading2(false);
        //console.log(res);
        setWhoAreYouData(res.data);
      })
      .catch((err) => {
        setLoading2(false);
        console.log(err);
      });
  };

  const fetchdataThird = () => {
    setLoading3(true);
    getWhyDoYouWantToJoin({ type: "whyDoYouWantToJoin" })
      .then((res) => {
        setLoading3(false);
        //  console.log(res);
        seWhyDOYouWantToJoin(res.data);
      })
      .catch((err) => {
        setLoading3(false);
        console.log(err);
      });
  };

  const makeCategoryDefult = (id) => {
    //console.log(id);
    setLoadingScreen(true);
    setSaveloading(true);
    makeWelcomeDefault({
      categoryId: id,
    })
      .then((res) => {
        fetchdataForCategory();
        setLoadingScreen(false);
        setSaveloading(false);
        // console.log(res);
      })
      .catch((e) => {
        setLoadingScreen(false);
        setSaveloading(false);
      });
  };

  const fetchdataForCategory = () => {
    setCategoryLoading(true);
    getWhyDoYouWantToJoin({ type: "category" })
      .then((res) => {
        setCategoryLoading(false);
        //console.log(res);
        setCateGoryData(res.data);
      })
      .catch((err) => {
        setCategoryLoading(false);
        // console.log(err);
      });
  };

  //================make default settings===========================//

  //================create Department===========================//

  const [saveloading, setSaveloading] = useState(false);
  const [depName, setDepName] = useState("");
  const [questionName, setQuestionName] = useState("");

  const saveDataOnFireStore = () => {
    // setLoadingScreen(true)
    if (
      valuefromRadioCateOne != "" &&
      valuefromRadioCateTwo != "" &&
      valuefromRadioCateThree != ""
    ) {
      setFlagforpopup(!flagforpopup);
      setopenLessonPopUp(true);
    } else {
      alert("select all the three option");
    }
  };

  useEffect(() => {
    fetchdata();
    fetchdataSecond();
    fetchdataThird();
    fetchdataForCategory();
  }, [deltemp]);

  const handelChnage = (type, name, img, item) => {
    if (type.target.name == "IdentifyYourself") {
      setValuefromRadioCateOne({ name: name, id: item });
    }

    if (type.target.name == "whyDoYouWant") {
      setValuefromRadioCateTwo({ name: name, id: item });
    }
    if (type.target.name == "whoyouAre") {
      setValuefromRadioCateThree({ name: name, id: item });
    }
    //console.log(type.target.value, type.target.name);
  };

  const deleteQuestionCategoryFun = (item) => {
    setSaveloading(true);
    deleteWhyDoYouWantToJoin({
      optionId: item,
    })
      .then((rse) => {
        setSaveloading(false);
        fetchdataForCategory();
      })
      .catch((err) => {
        setSaveloading(false);
      });
  };
  const navigate = useNavigate();

  const delfunction = (ele) => {
    setLoadingScreen(true);
    if (editFlag) {
      // console.log(ele);

      //console.log(extraArrForIds);

      getCategoryWithIds({
        IdentifyYourself: valuefromRadioCateOne.id,
        whoyouAre: valuefromRadioCateThree.id,
        whyDoYouWant: valuefromRadioCateTwo.id,
      }).then((res) => {
        if (res.data == null) {
          //alert(res.data.categoryName)
          //setopenLessonPopUp(true)

          editwelcomecategory({
            id: categoryId,
            categoryName: ele,

            IdentifyYourself: valuefromRadioCateOne.id,
            whyDoYouWant: valuefromRadioCateTwo.id,
            whoyouAre: valuefromRadioCateThree.id,
            optionsId: [
              valuefromRadioCateOne.id,
              valuefromRadioCateTwo.id,
              valuefromRadioCateThree.id,
            ],
            type: "category",
          })
            .then((res) => {
              setSaveloading(false);
              alert("category updated");
              fetchdataForCategory();
              setLoadingScreen(false);
            })
            .catch((err) => {
              setSaveloading(false);
            });
        } else {
          if (
            extraArrForIds.IdentifyYourself == valuefromRadioCateOne.id &&
            extraArrForIds.whoyouAre == valuefromRadioCateThree.id &&
            extraArrForIds.whyDoYouWant == valuefromRadioCateTwo.id
          ) {
            if (ele) {
              editwelcomecategory({
                id: categoryId,
                categoryName: ele,

                IdentifyYourself: valuefromRadioCateOne.id,
                whyDoYouWant: valuefromRadioCateTwo.id,
                whoyouAre: valuefromRadioCateThree.id,
                optionsId: [
                  valuefromRadioCateOne.id,
                  valuefromRadioCateTwo.id,
                  valuefromRadioCateThree.id,
                ],
                type: "category",
              })
                .then((res) => {
                  alert("category updated");
                  setSaveloading(false);
                  fetchdataForCategory();
                  setLoadingScreen(false);
                })
                .catch((err) => {
                  setSaveloading(false);
                });
            }
          } else {
            setLoadingScreen(false);
            alert(
              `you can not create this category with this  option  this category is already exist with name ${res.data.categoryName}`
            );
          }
        }
        // console.log(res.data)
        // setopenLessonPopUp(true)
      });
    } else {
      getCategoryWithIds({
        IdentifyYourself: valuefromRadioCateOne.id,
        whoyouAre: valuefromRadioCateThree.id,
        whyDoYouWant: valuefromRadioCateTwo.id,
      }).then((res) => {
        if (res.data == null) {
          createWelComeCategory({
            categoryName: ele,

            IdentifyYourself: valuefromRadioCateOne.id,
            whyDoYouWant: valuefromRadioCateTwo.id,
            whoyouAre: valuefromRadioCateThree.id,
            optionsId: [
              valuefromRadioCateOne.id,
              valuefromRadioCateTwo.id,
              valuefromRadioCateThree.id,
            ],
            type: "category",
          })
            .then((res) => {
              addCategoryDataInArr({
                arr: [res.data],
              });
              alert("category added");
              setSaveloading(false);
              fetchdataForCategory();
              setLoadingScreen(false);
            })
            .catch((err) => {
              setLoadingScreen(false);
              setSaveloading(false);
            });
        } else {
          alert(
            `you can not create this category with this  option  this category is already exist with name ${res.data.categoryName}`
          );
          setLoadingScreen(false);
          setSaveloading(false);
        }
      });
    }
  };

  const editFunction = (ele) => {
    //console.log(ele);
    setExtarArrForIds(ele);
    setCategoryId(ele.id);
    setCategoryName(ele.categoryName);
    setValuefromRadioCateOne({ id: ele.IdentifyYourself, name: "" });
    setValuefromRadioCateTwo({ id: ele.whyDoYouWant, name: "" });
    setValuefromRadioCateThree({ id: ele.whoyouAre, name: "" });
    setEditFlag(true);
  };
  return (
    <>
      <DeleteLesson
        open={openLessonPopUp}
        onClose={() => setopenLessonPopUp(false)}
        delfun={(topic) => {
          delfunction(topic);
        }}
        cateName={categoryName}
        flagForEdit={flagforpopup}
      />
      <LoadingScreen
        open={loadingScreen}
        onClose={() => setLoadingScreen(false)}
      />

      <section className="dashboard">
        <NavbarTop page={t("User Onboarding Questions")} />
        {/* <Navbar /> */}

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              {/* Company settings */}

              {/* Question Settings */}
              <h1
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                }}
              >
                Identify Yourself
              </h1>

              <div
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid #1E3758",
                  padding: "20px",
                  borderRadius: "10px",
                  marginTop: "30px",
                }}
              >
                <div
                  className="bigger-container"
                  style={{
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "30%", textAlign: "center" }}> Name</div>

                  <div style={{ width: "40%", textAlign: "center" }}>Img</div>
                  <div style={{ width: "20%", textAlign: "center" }}>
                    Action
                  </div>
                </div>

                <div
                  className="setting_details_table"
                  style={{ display: "grid" }}
                >
                  {loading1 && (
                    <div
                      className="admin-overdue-bottom-table-cnt-certi"
                      key={0}
                    >
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td">
                        <div className="spinner-container">
                          <div className="loading-spinner"></div>
                        </div>
                      </div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div
                        className="admin-overdue-bottom-table-td"
                        style={{ color: "#004577" }}
                      ></div>
                    </div>
                  )}

                  {template?.map((ele) => {
                    return (
                      <div
                        className="outer-inner-container"
                        style={{ textAlign: "center", alignItems: "center" }}
                      >
                        <div className="templatename" style={{ width: "30%" }}>
                          {ele.optionName}
                        </div>

                        <div
                          className="button-box"
                          style={{
                            width: "40%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "20px",
                          }}
                        >
                          <img src={ele.optionImg} width="40%" />
                        </div>

                        <div style={{ width: "20%" }}>
                          <input
                            style={{ fontSize: "16px", height: "30px" }}
                            checked={
                              valuefromRadioCateOne.id == ele.optionId
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              handelChnage(
                                e,
                                ele.optionName,
                                ele.optionImg,
                                ele.optionId
                              );
                            }}
                            type="radio"
                            name="IdentifyYourself"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {/* secondQuestionYourSkiil */}

            <div className="outer-bigger-container">
              {/* Question Settings */}
              <h1
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                }}
              >
                Why do you want to learn digital marketing?
              </h1>

              <div
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid #1E3758",
                  padding: "20px",
                  borderRadius: "10px",
                  marginTop: "30px",
                }}
              >
                <div
                  className="bigger-container"
                  style={{
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "30%", textAlign: "center" }}> Name</div>

                  <div style={{ width: "40%", textAlign: "center" }}>Img</div>
                  <div style={{ width: "20%", textAlign: "center" }}>
                    Action
                  </div>
                </div>

                <div
                  className="setting_details_table"
                  style={{ display: "grid" }}
                >
                  {Loading3 && (
                    <div
                      className="admin-overdue-bottom-table-cnt-certi"
                      key={0}
                    >
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td">
                        <div className="spinner-container">
                          <div className="loading-spinner"></div>
                        </div>
                      </div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div
                        className="admin-overdue-bottom-table-td"
                        style={{ color: "#004577" }}
                      ></div>
                    </div>
                  )}

                  {whyDOYouWantToJoin?.map((ele) => {
                    return (
                      <div
                        className="outer-inner-container"
                        style={{ textAlign: "center", alignItems: "center" }}
                      >
                        <div className="templatename" style={{ width: "30%" }}>
                          {ele.optionName}
                        </div>

                        <div
                          className="button-box"
                          style={{
                            width: "40%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "20px",
                          }}
                        >
                          <img src={ele.optionImg} width="40%" />{" "}
                        </div>

                        <div style={{ width: "20%" }}>
                          <input
                            style={{ fontSize: "16px", height: "30px" }}
                            checked={
                              valuefromRadioCateTwo.id == ele.optionId
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              handelChnage(
                                e,
                                ele.optionName,
                                ele.optionImg,
                                ele.optionId
                              );
                            }}
                            type="radio"
                            name="whyDoYouWant"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {/* <YourSkill/> */}

            <div className="outer-bigger-container">
              {/* Question Settings */}
              <h1
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                }}
              >
                Your exisitng level of digital marketing understanding?
              </h1>

              <div
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid #1E3758",
                  padding: "20px",
                  borderRadius: "10px",
                  marginTop: "30px",
                }}
              >
                <div
                  className="bigger-container"
                  style={{
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "30%", textAlign: "center" }}> Name</div>

                  <div style={{ width: "40%", textAlign: "center" }}>Img</div>
                  <div style={{ width: "20%", textAlign: "center" }}>
                    Action
                  </div>
                </div>

                <div
                  className="setting_details_table"
                  style={{ display: "grid" }}
                >
                  {Loading2 && (
                    <div
                      className="admin-overdue-bottom-table-cnt-certi"
                      key={0}
                    >
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td">
                        <div className="spinner-container">
                          <div className="loading-spinner"></div>
                        </div>
                      </div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div
                        className="admin-overdue-bottom-table-td"
                        style={{ color: "#004577" }}
                      ></div>
                    </div>
                  )}

                  {WhoAreYouData?.map((ele) => {
                    return (
                      <div
                        className="outer-inner-container"
                        style={{ textAlign: "center", alignItems: "center" }}
                      >
                        <div className="templatename" style={{ width: "30%" }}>
                          {ele.optionName}
                        </div>

                        <div
                          className="button-box"
                          style={{
                            width: "40%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "20px",
                          }}
                        >
                          <img src={ele.optionImg} width="40%" />
                        </div>

                        <div style={{ width: "20%" }}>
                          <input
                            style={{ fontSize: "16px", height: "30px" }}
                            checked={
                              valuefromRadioCateThree.id == ele.optionId
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              handelChnage(
                                e,
                                ele.optionName,
                                ele.optionImg,
                                ele.optionId
                              );
                            }}
                            type="radio"
                            name="whoyouAre"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {/* <WhoAreYou/> */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "30px 0px",
              }}
            >
              <button
                className="primary-btn"
                onClick={() => {
                  saveDataOnFireStore();
                }}
              >
                Submit
              </button>
            </div>
            {/* CATEGORUFADAYHDS */}

            <div className="outer-bigger-container">
              {/* Question Settings */}

              <h1
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                }}
              >
                Category
              </h1>

              <div
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid #1E3758",
                  padding: "20px",
                  borderRadius: "10px",
                  marginTop: "30px",
                }}
              >
                <div
                  className="bigger-container"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <div style={{ width: "30%" }}> Name</div>

                  <div style={{ width: "30%" }}>Id</div>

                  <div style={{ width: "15%" }}>Default</div>
                  <div style={{ width: "25%" }}>Action </div>
                </div>

                <div
                  className="setting_details_table"
                  style={{ display: "grid" }}
                >
                  {categoryLoading && (
                    <div
                      className="admin-overdue-bottom-table-cnt-certi"
                      key={0}
                    >
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td">
                        <div className="spinner-container">
                          <div className="loading-spinner"></div>
                        </div>
                      </div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div
                        className="admin-overdue-bottom-table-td"
                        style={{ color: "#004577" }}
                      ></div>
                    </div>
                  )}

                  {cateGoryData?.map((ele) => {
                    return (
                      <div
                        className="outer-inner-container"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "15px 0px",
                          textAlign: "center",
                          fontFamily: "Poppins",
                        }}
                      >
                        <div style={{ width: "30%" }}>{ele.categoryName}</div>

                        <div style={{ width: "30%" }}>{ele.id} </div>

                        <div style={{ width: "15%" }}>
                          <input
                            type="checkbox"
                            name={"default"}
                            checked={ele?.isDefault}
                            onChange={() => makeCategoryDefult(ele.id)}
                          />
                        </div>
                        <div
                          style={{
                            width: "25%",
                            display: "flex",
                            alignItems: "center",
                            gap: "30px",
                          }}
                        >
                          {ele?.isDefault !== true && (
                            <button
                              className="action-btn"
                              onClick={() => {
                                deleteQuestionCategoryFun(ele.id);
                              }}
                            >
                              Delete
                            </button>
                          )}
                          <button
                            className="action-btn"
                            onClick={() => {
                              editFunction(ele);
                            }}
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "30px 0px",
                }}
              >
                <button className="secondary-btn" onClick={() => navigate(-1)}>
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
