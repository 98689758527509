import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";
import { Timestamp } from "firebase/firestore";
import { useNavigate } from "react-router";

const PreviousTraining = ({ uid, mileStone }) => {
  const [milstoneData, setMilstoneData] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [certificate, setCertificate] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [arrLength, setArrLength] = useState(7);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(mileStone.length / 5)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };
  const handleStatusChange = (e, id) => {
    console.log(e.target);
    console.log(e.target.value);
    console.log(certificate[id]);
    if (e.target.value == "Download") {
      window.open(certificate[id].certificateDownloadURL, "_blank");
    }
  };

  const moveToMileStone = (ele) => {
    console.log(uid, ele);
    navigate("/admin/user/MileStoneView", { state: { uid: uid, mile: ele } });
  };

  return (
    <div
      style={{
        margin: "0vw 2vw 2vw 2vw",
        display: "grid",
        gridGap: "20px",
        padding: "20px",
        fontFamily: "Poppins",
      }}
      className="admin-overdue-bottom"
    >
      <div style={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: 500 }}>
        {t("Milestone")}
      </div>
      {mileStone.length > 0 ? (
        mileStone.map((e, id) => {
          if (id < 5 * currentPage) return;
          if (id >= 5 * (currentPage + 1)) return;

          return (
            <div
              style={{ textAlign: "center" }}
              onClick={() => {
                moveToMileStone(e);
              }}
            >
              {/* <div>
                {" "}
                <img
                  src="https://st.depositphotos.com/1032577/4119/i/950/depositphotos_41197145-stock-photo-quiz.jpg"
                  alt="car"
                  width="70%"
                  height="100px"
                  style={{ borderRadius: "10px", margin: "10px" }}
                />
              </div> */}
              <div>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    backgroundColor: "#D9D9D9",
                    padding: "10px",
                  }}
                >
                  <h4 style={{ width: "33.33%" }}>Milstone Name :</h4>
                  <h4 style={{ width: "33.33%" }}>Date:</h4>
                  <h4 style={{ width: "33.33%" }}>UserStatus:</h4>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    backgroundColor: "#F5F5F5",
                    padding: "10px",
                  }}
                >
                  <p style={{ width: "33.33%" }}>{e?.id.split("_")[0]}</p>
                  <p style={{ width: "33.33%" }}>
                    {Timestamp.fromMillis(e?.date._seconds * 1000)
                      .toDate()
                      .toDateString()}
                  </p>
                  <p style={{ width: "33.33%" }}>{e?.status}</p>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <h1>No MileStone</h1>
      )}

      <div className="admin-overdue-bottom-pagination">
        <div className="admin-overdue-bottom-pagination-cnt">
          <div className="admin-overdue-bottom-pagination-cnt-item">
            <svg
              onClick={() => clickhandler(currentPage - 1)}
              width="auto"
              height="22"
              viewBox="0 0 14 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                fill="#717579"
              />
            </svg>
          </div>
          <div className="admin-overdue-bottom-pagination-cnt-item-btn">
            {currentPage + 1} of {Math.ceil(mileStone.length / 5)}
          </div>
          <div className="admin-overdue-bottom-pagination-cnt-item">
            <svg
              onClick={() => clickhandler(currentPage + 1)}
              width="auto"
              height="20"
              viewBox="0 0 13 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                fill="#717579"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviousTraining;
