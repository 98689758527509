import React, { useEffect } from "react";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
//import axios from 'axios';
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { functions } from "../../../Firebase";
import "./AdminSetting.css";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import editIcon from "../../../utilities/images/editIcon.svg";

import Sidebar from "../../Component/Sidebar";
import NavbarTop from "../../Component/NavbarTop";
import { t } from "i18next";
import { event } from "jquery";

export const EmailSettings = () => {
  const navigate = useNavigate();

  useEffect(() => {
    getShortCodes();
    getTemplates();
  }, []);

  const fetchShortcodes = httpsCallable(functions, "fetchShortcodes");
  const [showLoading, setShowLoading] = useState(false);
  const [sortcodetrigger, setsortcodetrigger] = useState([]);
  // const [trigger,settrigger]=useState("");

  const getShortCodes = () => {
    setShowLoading(true);
    fetchShortcodes()
      .then((res) => {
        //console.log("shortcodes and trigger: ", res.data);
        setsortcodetrigger(res.data);
        setShowLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setShowLoading(false);
      });
  };

  const fetchTemplateList = httpsCallable(functions, "fetchTemplateList");
  const [templateList, setTemplateList] = useState([]);
  const getTemplates = () => {
    fetchTemplateList()
      .then((res) => {
        // console.log("templates: ", res.data);
        setTemplateList(res.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Notification Settings")} />
        {/* <Navbar /> */}

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          {/* <div className="admin-dashboard-main"> */}
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              {/* <div className="create-new-box">
                <Link to={{ pathname: "/admin/settings/email-create" }}>
                  <Button
                    variant="contained"
                    component="span"
                    color="primary"
                    startIcon={<AddIcon />}
                  >
                    Create New
                  </Button>
                </Link>
              </div> */}

              <div className="setting-table-main">
                <div className="bigger-container">
                  <div> On Trigger </div>
                  <div style={{ display: "flex" }}>
                    <div>Action</div>
                  </div>
                </div>
                <div className="">
                  {sortcodetrigger?.map((ele, i) => {
                    return (
                      <div className="outer-inner-container" key={i}>
                        <div className="templatename">{ele.trigger}</div>
                        <div className="button-box-inner mx-3 my-1">
                          <Link
                            to={{
                              pathname: "/admin/settings/emailtemplate",
                              state: { fromDashboard: "it is first" },
                            }}
                            state={{
                              trigger: ele.trigger,
                              shortcodes: ele.shortcodes,
                            }}
                          >
                            <button className="action-btn">
                              <img src={editIcon} alt="" /> Edit
                            </button>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                  {showLoading && (
                    <div
                      className="admin-overdue-bottom-table-cnt-certi"
                      key={0}
                    >
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td">
                        <div className="spinner-container">
                          <div className="loading-spinner"></div>
                        </div>
                      </div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div
                        className="admin-overdue-bottom-table-td"
                        style={{ color: "#004577" }}
                      ></div>
                    </div>
                  )}
                </div>
              </div>

              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  className="secondary-btn"
                  onClick={() => navigate("/admin/settings")}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
