import { React, useEffect, useState, useRef } from "react";
import $ from "jquery";
import { Timestamp } from "firebase/firestore";

import { db, functions, storage } from "../../../Firebase";
import Upload from "./uploadPop";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { ExpandMoreSharp } from "@mui/icons-material";
import Alert from "@mui/material/Alert";
import ReactPlayer from "react-player";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdEdit, MdDelete } from "react-icons/md";
import "./styles/item.css";
import { DeleteSVg, ExpandMoreSvg } from "./icons";
import DeleteLesson from "./lessonDeletePopUp";
import LoadingScreen from "./lodingScree";

const Item = ({ editCourseId, courseId, topicId = "", lessonId = "" }) => {
  const storage = getStorage();
  var docURL = [];
  var files = [];
  const [itemName, setItemName] = useState("");
  const initialList = [];
  const [list, setList] = useState(initialList);
  const [editable, setEditable] = useState(false);
  const createItem = httpsCallable(functions, "createItem");
  const uploadPdf = httpsCallable(functions, "uploadPdf");
  const addiItemForLesson = httpsCallable(functions, "addiItemForLesson");
  const addTopicItemOrder = httpsCallable(functions, "addTopicItemOrder");
  const deleteItem = httpsCallable(functions, "deleteItem");
  const deleteBasicItem = httpsCallable(functions, "deleteBasicItem");
  const httpsnewVideo = httpsCallable(functions, "httpsnewVideo");
  const [imageUrl, setImageUrl] = useState("");

  const [uploadLinkAndvideoFlag, setUploadLinkAndVideoFlag] = useState(false);

  const getAllItemsFromABasicLesson = httpsCallable(
    functions,
    "getAllItemsFromABasicLesson"
  );
  const [cateGoryArr, setcateGoryArr] = useState([
    { name: "cat1" },
    { name: "cat2" },
  ]);
  const [category, setCategory] = useState("");
  const [deleteItemId, setDeleteItemId] = useState("");
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);
  // deleteItem
  const [base64Vid, setBase64Vid] = useState("");
  const [uploadedVideoUrl, setUploadesVideoUrl] = useState("");
  const [progressUpload, setProgressUpload] = useState(0);
  const [openUploadPopUp, setopenUploadPopUp] = useState(false);
  const [videoFile, setVideoFile] = useState();
  const [base64Img, setBase64Img] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [itemDetails, setItemDetails] = useState({
    itemName: "",
    pdfBase64: "",
    videoLink: "",
    htmlLink: "",
    externalLink: "",
    audioLink: "",
    itemDescription: "",
  });

  const [itemId, setItemId] = useState("");
  const [itemType, setItemType] = useState("video");

  const [pdfLink, setPDFLink] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [htmlLink, setHTMLLink] = useState("");
  const [extLink, setExtLink] = useState("");
  const [audioLink, setAudioLink] = useState("");
  const [checkFailed, setCheckFailed] = useState(false);

  const [loadingScreen, setLoadingScreen] = useState(false);
  const [checkErrorInForm, setCheckErrorInForm] = useState({
    itemName: "",
    pdfBase64: "",
    videoLink: "",
    imageUrl: "",
    htmlLink: "",
  });
  const getItemInfo = httpsCallable(functions, "getItemInfo");

  const imageRef = useRef(null);
  const videoRef = useRef(null);

  const onImageClick = () => {
    imageRef.current.click();
  };

  const onVideoClick = () => {
    videoRef.current.click();
  };
  useEffect(() => {
    if (!editCourseId) return;
    setLoadingScreen(true);
    getAllItemsFromABasicLesson({ lessonId: lessonId })
      .then((res) => {
        setLoadingScreen(false);
        //  console.log(res.data);
        for (let i = 0; i < res.data.length; i++) {
          setList((prev) =>
            prev.concat({
              id: res.data[i]?.itemId,
              name: res.data[i]?.itemName,
              type: res.data[i]?.itemType,
              videoURL: res.data[i]?.videoFile,
              category: res.data[i]?.category,
              itemDescription: res.data[i]?.itemDescription,
              itemImageURL: res.data[i]?.itemImageURL,
            })
          );
        }
      })
      .catch((e) => {
        setLoadingScreen(false);
      });
  }, []);

  const handleCourseImage = (e) => {
    let reader = new FileReader();
    // console.log(e);

    let filenameType = e.target.value.split(".");

    //console.log(filenameType[filenameType.length - 1]);

    let filetypedata = filenameType[filenameType.length - 1].toLowerCase();
    if (
      filetypedata == "png" ||
      filetypedata == "jpeg" ||
      filetypedata == "jpg"
    ) {
    } else {
      alert("only jpeg  , gif  ,png  file accepted");
      e.target.value = null;

      return;
    }

    let rfilesize = Number(e.target.files[0].size) / 1024;

    rfilesize = rfilesize / 1024;
    //console.log(rfilesize);
    if (Number(rfilesize) > 3) {
      alert("size must be less than 3 mb");

      e.target.value = null;
      return;
    } else {
      let url = URL.createObjectURL(e.target.files[0]);

      const img = new Image();
      img.onload = function () {
        //alert(this.width + 'x' + this.height);
        if (
          Number(this.width) > 660 ||
          (Number(this.width) < 650 && Number(this.height) > 240) ||
          Number(this.height) < 230
        ) {
          setBase64Img("");
          setImageUrl("");

          alert("Aspect Ratio must be 656px:232px. Update and try again.");
          return false;
        } else {
          setImageUrl(url);

          reader.readAsDataURL(e.target.files[0]);
          reader.onload = function () {
            // console.log(reader.result);
            setBase64Img(reader.result);
          };
        }
      };
      img.src = url;

      //
      // console.log(rfilesize);
      // let url = URL.createObjectURL(e.target.files[0]);
    }
  };

  const handleInput = (event) => {
    const { value, name } = event.target;
    setItemDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const fileHandler = (event) => {
    const { files } = event.target;

    let filenameType = event.target.value.split(".");

    // console.log(filenameType[filenameType.length - 1]);

    let filetypedata = filenameType[filenameType.length - 1].toLowerCase();
    if (filetypedata == "pdf") {
    } else {
      alert("Only PDF File accepted");
      event.target.value = null;
      return;
    }

    let rfilesize = Number(event.target.files[0].size) / 1024;

    rfilesize = rfilesize / 1024;
    //console.log(rfilesize);
    if (Number(rfilesize) > 5) {
      alert("size must be less than 5 mb");
      //  setImgFileName("")
      event.target.value = null;
      return;
    } else {
      let reader = new FileReader();
      reader.onloadend = () => {
        setItemDetails((prev) => ({ ...prev, pdfBase64: reader.result }));
      };
      reader.readAsDataURL(files[0]);
    }
  };

  function checkForm() {
    if (
      document.getElementById(topicId + courseId + lessonId + "itemName")
        .value === ""
    ) {
      setCheckErrorInForm((prev) => ({ ...prev, itemName: "error" }));
      return "itemName";
    }

    // console.log(uploadedVideoUrl, videoUrl);
    if (uploadedVideoUrl == "" && videoUrl == "") {
      setCheckErrorInForm((prev) => ({ ...prev, videoLink: "error" }));
      return "videoLink";
    }

    if (imageUrl == "") {
      setCheckErrorInForm((prev) => ({ ...prev, imageUrl: "error" }));
      return "imageUrl";
    }

    // if (itemType === "external" && itemDetails.externalLink === "") return "externalLink";
    return "allIsWell";
  }

  function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }
    if (lessonId != "") {
      addiItemForLesson({
        lessonId: lessonId,
        videoItem: orderList,
        edit: false,
      });
    }
  }
  const toggleForm = (e) => {
    document.getElementById(lessonId + "itemForm").style.display =
      document.getElementById(lessonId + "itemForm").style.display == "none"
        ? "block"
        : "none";
  };
  const addItem = () => {
    let check = checkForm();
    if (check != "allIsWell") {
      setCheckFailed(true);
      return;
    }

    alert(courseId);
    let nsn = itemDetails.itemName.replaceAll(" ", "");

    let dat = /[^A-Za-z0-9]/.test(nsn);

    if (!dat) {
    } else {
      alert("you can not use any special characters");
      return;
    }
    let img = uploadedVideoUrl ? uploadedVideoUrl : videoUrl;
    if (img.startsWith("blob")) {
      alert("upload video");
      return;
    }
    setCheckFailed(false);
    //console.log("called Add");
    docURL = [];
    files = [];
    setCheckErrorInForm({
      itemName: "",
      pdfBase64: "",
      videoLink: "",

      htmlLink: "",
    });

    const newList = list.concat({
      id: itemId,
      name: itemDetails.itemName,
      type: itemType,

      itemImageURL: imageUrl,
      videoUrl: uploadedVideoUrl ? uploadedVideoUrl : videoUrl,
    });

    createItem({
      itemId: itemId,
      courseAssociated: courseId,
      lessonAssociated: lessonId,
      itemImageURL: base64Img ? base64Img : imageUrl,
      category: category,
      videoFile: uploadedVideoUrl ? uploadedVideoUrl : videoUrl,
      itemName: itemDetails.itemName,
      itemDescription: itemDetails.itemDescription,
      itemType: itemType,
      created: new Date().toString(),
    });

    if (!editable) {
      setList(newList);
      saveOrder(newList);
    } else {
      for (let i = 0; i < list.length; i++) {
        if (itemId === list[i].id) {
          list[i] = {
            id: itemId,
            name: itemDetails.itemName,
            type: itemType,

            itemImageURL: imageUrl,
            videoUrl: uploadedVideoUrl ? uploadedVideoUrl : videoUrl,
          };
        }
      }
      setList(list);
    }
    setItemDetails({
      itemName: "",
      pdfBase64: "",
      videoLink: "",
      htmlLink: "",
      externalLink: "",
      audioLink: "",
    });
    setItemId("");
    setItemType("video");

    toggleForm();
  };

  const upload = (pdf, i, link, referDoc) => {
    const metadata = {
      contentType: pdf.type,
    };
    const fileName = pdf.name;
    const storageRef = ref(
      storage,
      link + i + fileName.substring(fileName.lastIndexOf("."))
    );
    const uploadTask = uploadBytesResumable(storageRef, pdf, metadata);
    files.push(lessonId + fileName.substring(fileName.lastIndexOf(".")));
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        //   console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            // console.log("Upload is paused");
            break;
          case "running":
            //   console.log("Upload is running");
            break;
        }
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // console.log("File available at", downloadURL);
          docURL.push(downloadURL);
          referDoc.update({ documentURL: docURL });
        });
      }
    );
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    saveOrder(items);
  }
  const itemToggle = () => {
    document.getElementById(lessonId + "itemForm").style.display =
      document.getElementById(lessonId + "itemForm").style.display == "none"
        ? "block"
        : "none";
  };
  function edit(item) {
    //console.log(item);
    setEditable(true);
    setItemType("video");

    setItemId(item.id);
    setImageUrl(item.itemImageURL);
    setVideoUrl(item.videoURL);
    itemToggle();

    let obj = {
      itemName: item.name,
      itemDescription: item.name,
    };
    setItemDetails(obj);
  }

  const updateItem = () => {
    let check = checkForm();
    if (check != "allIsWell") {
      setCheckFailed(true);
      return;
    }
    setCheckFailed(false);

    docURL = [];
    files = [];

    for (let i = 0; i < list.length; i++) {
      if (itemId === list[i].id) {
        list[i] = {
          id: itemId,
          name: itemName,
          type: itemType,
          videoURL: videoLink,
          pdfURL: pdfLink,
          htmlURL: htmlLink,
          externalURL: extLink,
          audioURL: audioLink,
        };
      }
    }
    setList(list);

    document.getElementById(
      topicId + courseId + lessonId + "itemForm"
    ).style.display = "none";
    setEditable(false);
  };

  async function del(item) {
    setopenLessonPopUp(true);
    setDeleteItemId(item);
  }

  const delfunction = (item) => {
    deleteBasicItem({ itemId: item.id });
    //deleteItem({ itemId: item.id });

    alert("Deleted " + item.name);
    const item1 = [];
    for (let i = 0; i < list.length; i++) {
      if (item.id !== list[i].id) {
        item1.push(list[i]);
      }
    }
    setopenLessonPopUp(false);
    setList(item1);
    saveOrder(item1);
  };

  const handleVideo = (e) => {
    setBase64Vid("");
    setVideoUrl("");
    setUploadLinkAndVideoFlag(true);
    let reader = new FileReader();
    //console.log(e);

    let filenameType = e.target.value.split(".");

    // console.log(filenameType[filenameType.length - 1]);

    let filetypedata = filenameType[filenameType.length - 1].toLowerCase();

    let url = URL.createObjectURL(e.target.files[0]);
    setVideoUrl(url);

    setVideoFile(e.target.files[0]);

    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      // console.log(reader.result);
      setBase64Vid(reader.result);
    };
  };

  const uploadVideoFile = (data) => {
    if (data) {
      setopenUploadPopUp(true);

      let str = Timestamp.fromDate(new Date()).toMillis();
      const storageref = ref(storage, `/files/${str}`);
      const uploadTask = uploadBytesResumable(storageref, data);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          setProgressUpload(prog);
          //console.log(prog);
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setUploadesVideoUrl(url);
            // return url
          });
        }
      );
    } else {
      alert("select any file to upload");
    }
  };

  function clier() {
    setUploadLinkAndVideoFlag(false);

    setVideoUrl("");
  }

  return (
    <>
      <Upload
        open={openUploadPopUp}
        onClose={() => {
          setopenUploadPopUp(false);
        }}
        upload={progressUpload}
      />
      <DeleteLesson
        open={openLessonPopUp}
        onClose={() => setopenLessonPopUp(false)}
        delfun={(topic) => {
          delfunction(topic);
        }}
        topicId={deleteItemId}
      />
      <LoadingScreen
        open={loadingScreen}
        onClose={() => setLoadingScreen(false)}
      />

      <section className="section" style={{ margin: "30px 0", width: "100%" }}>
        <button
          className="primary-btn"
          onClick={() => {
            setEditable(false);
            toggleForm();
          }}
          disabled={list.length >= 1 ? true : false}
        >
          Add Item
        </button>
        <div
          className="addItem-container"
          id={lessonId + "itemForm"}
          style={{ padding: "0 20px", display: "none" }}
        >
          <div className="addItem-heading">Item</div>
          <div className="addItem-row">
            <p
              className="addItem-inputLabel"
              style={{
                fontWeight: 500,
                color: "#171B1E",
                width: "20%",
              }}
            >
              Item Name *
            </p>
            <input
              type="text"
              className="addItem-inputBar"
              id={topicId + courseId + lessonId + "itemName"}
              name="itemName"
              value={itemDetails.itemName}
              style={{
                border: `${
                  checkErrorInForm.itemName == "error"
                    ? "2px solid red"
                    : "1px solid #d7d7d7"
                }`,
                width: "30%",
                outline: "none",
                padding: "15px",
              }}
              onChange={(event) => {
                handleInput(event);
                setCheckErrorInForm((prev) => ({ ...prev, itemName: "" }));
                if (!editable) {
                  let value = event.target.value.replaceAll(" ", "");
                  let time = new Date().getTime();
                  setItemId(value + "_" + time);
                }
              }}
            />
            {checkErrorInForm.itemName == "error" && (
              <span
                style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
              >
                Enter Item Name
              </span>
            )}
          </div>

          <div className="addItem-row">
            <div
              className="addItem-inputLabel"
              style={{
                fontWeight: 500,
                color: "#171B1E",
                width: "20%",
              }}
            >
              Item Description *
            </div>
            <input
              type="text"
              className="addItem-inputBar"
              id={topicId + courseId + lessonId + "itemDescription"}
              name="itemDescription"
              value={itemDetails.itemDescription}
              style={{
                width: "30%",
                border: "1px solid #d7d7d7",
                outline: "none",
                height: "40px",
              }}
              onChange={(event) => {
                handleInput(event);
              }}
            />
          </div>

          <div className="course-builder-info">
            <p
              className="addItem-inputLabel"
              style={{
                fontWeight: 500,
                color: "#171B1E",
                width: "20%",
              }}
            >
              {" "}
              Upload Video Thumbnail*
            </p>
            {imageUrl == "" ? (
              <div className="image-box" onClick={onImageClick}>
                <button className="image-box-btn">
                  <div className="image-box-icon">
                    <svg
                      width="25"
                      height="26"
                      viewBox="0 0 25 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.27998 25.2535C2.42931 25.2535 1.70135 24.9509 1.09609 24.3456C0.489792 23.7393 0.186646 23.0108 0.186646 22.1602V17.5202H3.27998V22.1602H21.84V17.5202H24.9333V22.1602C24.9333 23.0108 24.6307 23.7393 24.0254 24.3456C23.4191 24.9509 22.6906 25.2535 21.84 25.2535H3.27998ZM11.0133 19.0668V6.4615L6.99198 10.4828L4.82665 8.24017L12.56 0.506836L20.2933 8.24017L18.128 10.4828L14.1066 6.4615V19.0668H11.0133Z"
                        fill="#D9D9D9"
                      />
                    </svg>
                  </div>
                </button>
                <p className="image-box-text">Upload Image</p>
              </div>
            ) : (
              <img
                style={{
                  width: "20vw",
                  height: "8em",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
                onClick={onImageClick}
                src={imageUrl}
              ></img>
            )}
            <input
              ref={imageRef}
              style={{ display: "none" }}
              type="file"
              accept="image/png, image/gif, image/jpeg"
              className="course-builder-inputImg"
              id="courseImage"
              onChange={(e) => {
                handleCourseImage(e);
                setCheckErrorInForm((prev) => ({ ...prev, imageUrl: "" }));
              }}
            />
            {checkErrorInForm.imageUrl == "error" && (
              <span
                style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
              >
                {" "}
                upload Image
              </span>
            )}
          </div>

          {itemType == "video" && (
            <div
              className="addItem-row"
              style={{ display: "block" }}
              id={topicId + courseId + lessonId + "videoLinkBlock"}
            >
              <div className="addItem-row">
                <div
                  className="addItem-inputLabel"
                  style={{
                    fontWeight: 500,
                    color: "#171B1E",
                    width: "20%",
                  }}
                >
                  Video link*
                </div>
                <input
                  type="text"
                  className="addItem-inputBar"
                  id={topicId + courseId + lessonId + "itemDescription"}
                  name="itemDescription"
                  value={videoUrl}
                  style={{
                    width: "30%",
                    border: "1px solid #d7d7d7",
                    outline: "none",
                    padding: "15px",
                  }}
                  onChange={(event) => {
                    setVideoUrl(event.target.value);
                  }}
                />
              </div>

              <div className="course-builder-info">
                <div
                  className="addItem-inputLabel"
                  style={{
                    fontWeight: 500,
                    color: "#171B1E",
                    width: "20%",
                  }}
                >
                  {" "}
                  Upload Video*
                </div>
                {videoUrl == "" ? (
                  <div
                    style={{
                      width: "320px",
                      height: "240px",
                      background: "#d5d5d5",
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      borderRadius: "10px",
                    }}
                    onClick={onVideoClick}
                  >
                    Click to upload video
                  </div>
                ) : (
                  <>
                    {/* <video width="320" height="240" controls>
            <source  src={videoUrl} type="video/mp4"/>
      <source  src={videoUrl} type="video/ogg"/>
        Your browser does not support the video tag.
           </video> */}

                    <ReactPlayer
                      url={videoUrl}
                      playing
                      controls
                      config={{ file: { attributes: { id: "audio-element" } } }}
                      width="320px"
                      height="180px"
                    />
                  </>
                )}

                <input
                  ref={videoRef}
                  style={{
                    display: "none",
                  }}
                  type="file"
                  accept="video/*"
                  className="course-builder-inputImg"
                  id="courseVideo"
                  onChange={(e) => {
                    handleVideo(e);
                    setCheckErrorInForm((prev) => ({ ...prev, videoLink: "" }));
                  }}
                />
                <button
                  className="secondary-btn"
                  onClick={clier}
                  style={{ marginLeft: "20px" }}
                >
                  {" "}
                  Delete
                </button>
                <button
                  style={{
                    marginLeft: "20px",

                    // backgroundColor:videoUrl==""?"linear-gradient(94.87deg, #062A59 0%, #C2512C 99.3%)":
                    opacity: uploadLinkAndvideoFlag ? "100" : "0.6",
                  }}
                  className="primary-btn"
                  disabled={uploadLinkAndvideoFlag ? false : true}
                  onClick={() => {
                    uploadVideoFile(videoFile);
                  }}
                >
                  {" "}
                  Upload
                </button>
                {checkErrorInForm.videoLink == "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    upload video file
                  </span>
                )}
              </div>
            </div>
          )}

          {checkFailed && <p style={{ color: "ref" }}>Fill all the fields.</p>}
          <div className="addItem-row">
            <button
              className="primary-btn"
              style={{ marginLeft: "auto" }}
              onClick={addItem}
            >
              Save Item
            </button>

            <button
              className="secondary-btn"
              style={{ marginLeft: "10px" }}
              onClick={toggleForm}
            >
              Cancel
            </button>
          </div>
        </div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <ul
                id="itemList"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: "block",
                  overflow: "hidden",
                  width: "100%",
                  listStyleType: "none",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                {list.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        key={item.id}
                        className="draggableItem-li"
                      >
                        <Accordion>
                          <AccordionSummary
                            {...provided.dragHandleProps}
                            expandIcon={<ExpandMoreSvg />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                              backgroundColor: "#FF9A79",
                              color: "white",
                              borderRadius: "5px",
                            }}
                          >
                            <Typography> Item -{item.name}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div
                              style={{ float: "right", margin: "20px" }}
                              onClick={() => del(item)}
                            >
                              <DeleteSVg size={28} />
                            </div>
                            <MdEdit
                              style={{ float: "right", margin: "20px" }}
                              size={28}
                              onClick={() => edit(item)}
                            />
                            <p>Id- {item.id}</p>
                            {topicId != "" ? (
                              <p>Associated Topic- {topicId}</p>
                            ) : (
                              <></>
                            )}
                            {courseId != "" ? (
                              <p>Associated Course- {courseId}</p>
                            ) : (
                              <></>
                            )}
                            {lessonId != "" ? (
                              <p>Associated Lesson- {lessonId}</p>
                            ) : (
                              <></>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </section>
    </>
  );
};

export default Item;
