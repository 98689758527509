import { httpsCallable } from "firebase/functions";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import "../../../App.css";
import { getCookie } from "../../../Cookies";
import { db, functions } from "../../../Firebase";
import Navbar from "../../Component/NavbarTop";
import Sidebar from "../../Component/Sidebar";
import { Timestamp } from "firebase/firestore";
import "../StyleAdminComponents/User.css";
import "./Users.css";
import LoadingScreen from "../BasicCourse/lodingScree";

function Users() {
  const navigate = useNavigate();
  const goToAddNewUser = (state) =>
    navigate("/admin/addNewUser", { state: { ...state } });
  const getWhyDoYouWantToJoin = httpsCallable(
    functions,
    "getWhyDoYouWantToJoin"
  );
  const goToEditUser = (state) =>
    navigate("/admin/userview", { state: { ...state } });
  const goToHome = () => navigate("/");
  const [valueSelect, setvalue] = useState("");
  const [loadingScreen, setLoadingScreen] = useState(false);
  const initState = {
    name: "",
    employeeId: "",
    company: "",
    department: "",
    mobile: "",
    emailId: "",
    jobTitle: "",
    role: "",
    selected: "default",
    mobile: "",
  };
  const [query, setQuery] = useState(initState);
  useEffect(() => {
    if (getCookie("UID") == null || getCookie("ROLE") != "admin") {
      goToHome();
      return <></>;
    }
  });
  const [showLoading, setLoading] = useState(true);

  const [userArray, setUserArray] = useState([]);
  const [details, setDetails] = useState([]);
  const getAllUser = httpsCallable(functions, "getAllUser");
  const archiveUser = httpsCallable(functions, "archiveUser");
  const unarchiveUser = httpsCallable(functions, "unarchiveUser");
  const { clrs } = useSelector((state) => state.createClr);
  const [arrLength, setArrLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [userJobTitle, setUserJobTitle] = useState([]);
  const [userCompany, setUserCompany] = useState([]);
  const [userDepart, setUserDepart] = useState([]);
  const [stateTempForfetchUserList, setStateTempForfetchUserList] =
    useState(true);
  const [archivedUserList, setArchivedUserList] = useState([]);
  const [buttonTogalForarchived, setButtonTogalForarchived] = useState(true);
  const [extraArrForSaveTheUsers, setExtraArrForSaveTheUsers] = useState([]);
  const [tempLoading, setTemploading] = useState("test");
  const [cateGoryData, setCateGoryData] = useState([]);
  const [catExtraState, setCatExtraState] = useState([]);
  const handleQueryChange = (e) => {
    setQuery({
      ...query,
      [e.target.name]: e.target.value,
    });
  };
  const setDefault = () => {
    setQuery(initState);
    setDetails(userArray);
    setArrLength(userArray.length);
    return;
  };
  const handleSearch = (name) => {
    const value = query[name];
    if (value == "") {
      setDetails(userArray);
      setArrLength(userArray.length);
      return;
    }
    const temp = userArray.filter((item) =>
      item[name]?.toLowerCase().includes(value.toLowerCase())
    );
    // console.log(temp);
    setDetails(temp);
    setArrLength(temp.length);
    // console.log(temp);
    setCurrentPage(0);
  };
  const handleStatusChange = (e) => {
    setQuery({
      ...query,
      role: e.target.value,
    });
    // console.log(e.target.name);
    //console.log(e.target.value);
    if (e.target.value == "default") {
      setDetails(userArray);
      setArrLength(userArray.length);
      return;
    }
    // if(role==)
    const temp = userArray.filter((item) => item.role == e.target.value);
    // console.log(temp);
    setDetails(temp);
    setArrLength(temp.length);
    // console.log(temp);
    setCurrentPage(0);
  };

  const handerEmailANdPhone = (type) => {
    if (type == "mobile") {
      const temp = userArray.filter((item) =>
        item?.phoneNumber?.includes(query.mobile)
      );
      // console.log(temp);
      setDetails(temp);
      setArrLength(temp.length);
      // console.log(temp);
      setCurrentPage(0);
    }
    if (type == "emailId") {
      const temp = userArray.filter((item) =>
        item?.email?.includes(query.emailId)
      );
      // console.log(temp);
      setDetails(temp);
      setArrLength(temp.length);
      // console.log(temp);
      setCurrentPage(0);
    }
  };
  useEffect(() => {
    setLoading(true);
    setLoadingScreen(true);
    getAllUser({})
      .then((res) => {
        if (res.data.error == "No matches found") {
          setUserArray([]);
          setDetails([]);
          setLoadingScreen(false);
          setArrLength(0);
          setUserJobTitle([]);
          setUserCompany([]);
          setUserDepart([]);
          setLoading(false);
        } else {
          console.log(res.data);
          setUserArray(res.data?.map((item) => ({ ...item, selected: false })));
          setDetails(res.data?.map((item) => ({ ...item, selected: false })));
          setLoadingScreen(false);

          setCurrentPage(0);
          setArrLength(res.data.length);
          setLoading(false);

          getWhyDoYouWantToJoin({ type: "category" }).then((re) => {
            setCatExtraState(re.data);

            //  console.log(re.data);
            let obj = {};
            for (let i = 0; i < re.data.length; i++) {
              obj[re.data[i].id] = re.data[i].categoryName;
            }
            //  console.log(obj);
            setCateGoryData(obj);
          });
        }
      })
      .catch((e) => {
        setLoadingScreen(false);
      });
  }, [stateTempForfetchUserList]);
  // console.log(userArray);
  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(arrLength / pageSize)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };

  const handleChange = (event, item, id) => {
    var data = event.target.value;
    setvalue(event.target.value);

    if (data == "edit") {
      goToAddNewUser(item);
    } else if (data == "view") {
      goToEditUser(item);
    }
  };

  return (
    <div className="admin-users">
      <Navbar page={t("Users")} />
      <div className="admin-users-main">
        <LoadingScreen
          open={loadingScreen}
          onClose={() => setLoadingScreen(false)}
        />
        <Sidebar page={"user"} hidepage={false} />
        <div className="admin-overdue">
          <div
            style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr" }}
            className="admin-dashboard-main-mid"
          >
            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("UserName")}
              </div>
              <div style={{ position: "relative" }}>
                <input
                  style={{ width: "11vw" }}
                  value={query.name}
                  name="name"
                  type="text"
                  onChange={handleQueryChange}
                  placeholder={t("Enter Username")}
                />
                <div
                  className="admin-dashboard-main-top-last"
                  style={{
                    position: "absolute",
                    top: "-0.5vw",
                    right: "1.55vw",
                  }}
                >
                  <button
                    className="primary-btn-input"
                    onClick={() => handleSearch("name")}
                  >
                    {t("Search")}
                  </button>
                </div>
              </div>
            </div>
            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("Email Id")}
              </div>
              <div style={{ position: "relative" }}>
                <input
                  style={{ width: "11vw" }}
                  name="emailId"
                  value={query.emailId}
                  type="text"
                  onChange={handleQueryChange}
                  placeholder="Search By Employee No."
                />
                <div
                  className="admin-dashboard-main-top-last"
                  style={{
                    position: "absolute",
                    top: "-0.5vw",
                    right: "1.1vw",
                  }}
                >
                  <button
                    className="primary-btn-input"
                    onClick={() => handerEmailANdPhone("emailId")}
                  >
                    {t("Search")}
                  </button>
                </div>
              </div>
            </div>

            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("Mobile Number")}
              </div>
              <div style={{ position: "relative" }}>
                <input
                  style={{ width: "11vw" }}
                  name="mobile"
                  value={query.mobile}
                  type="Number"
                  onChange={handleQueryChange}
                  placeholder="Search By Mobile No."
                />
                <div
                  className="admin-dashboard-main-top-last"
                  style={{
                    position: "absolute",
                    top: "-0.5vw",
                    right: "1.1vw",
                  }}
                >
                  <button
                    className="primary-btn-input"
                    onClick={() => handerEmailANdPhone("mobile")}
                  >
                    {t("Search")}
                  </button>
                </div>
              </div>
            </div>

            <div>
              <button
                className="clear-btn"
                onClick={setDefault}
                style={{ marginTop: "15%" }}
              >
                {t("Clear Filters")}
              </button>
            </div>
            {/* <div
              class="admin-dashboard-main-top-last"
              style={{ height: " 16vh", width: "10vh" }}
            >
              <button
                style={{
                  width: "10vw",
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                onClick={setDefault}
              >
                <div>
                  <svg
                    width="auto"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div>{t("Clear Filters")}</div>
              </button>
            </div> */}
          </div>

          <div style={{ height: "8vw" }} className="courselist-main-cnt-top">
            {/*     <div className="courselist-main-cnt-top-txt">Group List</div> */}
            <div
              style={{
                marginTop: "6vw",
                marginLeft: "0vw",
              }}
              className="head"
            >
              {t("UserList")}
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateRow: "30px 30px",
                gridGap: "10px",
              }}
            >
              <Link to={"/admin/user/allUserFunnelview"}>
                <button className="primary-btn">{t("Funnel View")}</button>
              </Link>
              {/* <Link to={"/admin/addNewUser"}>
                <button className="primary-btn">{t("AddNewUser")}</button>
              </Link> */}
            </div>
          </div>
          <div
            style={{ margin: "0vw 2vw 2vw 2vw" }}
            className="admin-overdue-bottom"
          >
            <div className="admin-overdue-bottom-table">
              <div
                className="admin-overdue-bottom-table-cnt-usr"
                style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr" }}
              >
                <div className="admin-overdue-bottom-table-head">{t("")}</div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Name")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Email")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Mobile Number")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Category")}
                </div>

                <div className="admin-overdue-bottom-table-head">
                  {t("Action")}
                </div>
              </div>
              {showLoading && (
                <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td">
                    <div className="spinner-container">
                      <div className="loading-spinner"></div>
                    </div>
                  </div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div
                    className="admin-overdue-bottom-table-td"
                    style={{ color: "#004577" }}
                  ></div>
                </div>
              )}

              {details ? (
                details.map((item, id) => {
                  if (id < pageSize * currentPage) return;
                  if (id >= pageSize * (currentPage + 1)) return;
                  let joiningDate = Timestamp.fromMillis(
                    item?.joiningDate?._seconds * 1000
                  );
                  return (
                    <div
                      className="admin-overdue-bottom-table-cnt-usr"
                      key={id}
                      style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr" }}
                    >
                      <div className="admin-overdue-bottom-table-td">
                        <img
                          src="https://bobbyhadz.com/images/blog/react-prevent-multiple-button-clicks/thumbnail.webp"
                          alt="car"
                          width="35%"
                          height="40px"
                          style={{ borderRadius: "25%", margin: "10px" }}
                        />
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item?.name}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item?.email}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item?.phoneNumber}
                      </div>

                      {item?.courseCategory ? (
                        <div className="admin-overdue-bottom-table-td">
                          {cateGoryData[item.courseCategory]}
                        </div>
                      ) : (
                        <div className="admin-overdue-bottom-table-td">{}</div>
                      )}

                      <div
                        className="admin-overdue-bottom-table-td"
                        style={{ color: "#004577" }}
                      >
                        <select
                          style={{
                            border: "none",
                            backgroundColor: "#F2F2F2",
                            height: "4vh",
                            width: "7vw",
                            padding: "0 1vh",
                            fontWeight: "700",
                            color: "#717579",
                            borderRadius: "6px",
                          }}
                          onChange={(event) => handleChange(event, item, id)}
                        >
                          <option /* value={details.groupName} */>
                            {t("Action")}
                          </option>
                          {/* <option
                          
                            value={"edit"}
                          >
                            {t("Edit")}
                          </option> */}
                          <option
                            value={"view"} /* value={details.groupName} */
                          >
                            {t("View")}
                          </option>
                        </select>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No user </p>
              )}
            </div>
            <div className="admin-overdue-bottom-pagination">
              <div className="admin-overdue-bottom-pagination-cnt">
                <div className="admin-overdue-bottom-pagination-cnt-item">
                  <svg
                    onClick={() => clickhandler(currentPage - 1)}
                    width="auto"
                    height="22"
                    viewBox="0 0 14 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div className="admin-overdue-bottom-pagination-cnt-item-btn">
                  {currentPage + 1} of {Math.ceil(arrLength / pageSize)}
                </div>
                <div
                  style={{ marginRight: "19vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <svg
                    onClick={() => clickhandler(currentPage + 1)}
                    width="auto"
                    height="20"
                    viewBox="0 0 13 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div
                  style={{ display: "flex", marginRight: "-23vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <label className="admin-row">{t("Show")}</label>
                  <select
                    style={{
                      width: "4vw",
                      margin: "0.5vw",
                      marginBottom: "1.5vw",
                      height: "1.5vw",
                    }}
                    value={pageSize}
                    onChange={(e) => setPageSize(e.target.value)}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                  </select>
                  <label className="admin-row"> {t("PagePerItem")}</label>
                </div>
              </div>
            </div>
            <div>
              {/*    <Link to={"/admin/addNewUser"}>
              <button>add new user</button>
            </Link> */}
              {/*  <Link to={"/admin/userview"}>
              <button>userview</button>
            </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Users;
