import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";
import { Timestamp } from "firebase/firestore";

const Certificates = ({ uid, history }) => {
  const [certificate, setCertificate] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [currentPage, setCurrentPage] = useState(0);
  const [arrLength, setArrLength] = useState(7);
  const [loading, setLoading] = useState(false);

  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(history.length / 5)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };

  return (
    <div
      className="admin-overdue-bottom"
      style={{ padding: "20px", fontFamily: "Poppins" }}
    >
      <div style={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: 500 }}>
        {t("History")}
      </div>
      <div className="admin-overdue-bottom-table">
        <div
          className="admin-overdue-bottom-table-cnt-user-view-t"
          style={{ gridTemplateColumns: "1fr 1fr 1fr" }}
        >
          <div className="admin-overdue-bottom-table-head">{t("Sn")}</div>
          <div className="admin-overdue-bottom-table-head">{t("Event")}</div>
       
          <div className="admin-overdue-bottom-table-head">{t("Date")}</div>
        </div>
        {history.length > 0 ? (
          history.map((item, id) => {
            if (id < 5 * currentPage) return;
            if (id >= 5 * (currentPage + 1)) return;
            // let issuedOn = Timestamp.fromMillis(item.issuedOn?._seconds * 1000)
            //   .toDate()
            //   .toDateString();
            let expiry = Timestamp.fromMillis(item.date?._seconds * 1000)
              .toDate()
              .toDateString();
            return (
              <div
                className="admin-overdue-bottom-table-cnt-user-view-t"
                key={id}
                style={{ gridTemplateColumns: "1fr 1fr 1fr" }}
              >
                <div className="admin-overdue-bottom-table-td">{id + 1}</div>
                <div className="admin-overdue-bottom-table-td">
                  {item?.action}
                </div>
                {/* <div className="admin-overdue-bottom-table-td">
                  {item.userStatus}
                </div> */}
                <div className="admin-overdue-bottom-table-td">{expiry}</div>
              </div>
            );
          })
        ) : (
          <h1 style={{ textAlign: "center", padding: "30px" }}>
            No History Found
          </h1>
        )}
      </div>
      {loading && (
        <div className="spinner-container">
          <div className="loading-spinner"></div>
        </div>
      )}
      {arrLength == 0 && !loading && (
        <div style={{ width: "100%", textAlign: "center", color: "#1e1e1e" }}>
          No Records found
        </div>
      )}
      <div className="admin-overdue-bottom-pagination">
        <div className="admin-overdue-bottom-pagination-cnt">
          <div className="admin-overdue-bottom-pagination-cnt-item">
            <svg
              onClick={() => clickhandler(currentPage - 1)}
              width="auto"
              height="22"
              viewBox="0 0 14 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                fill="#717579"
              />
            </svg>
          </div>
          <div className="admin-overdue-bottom-pagination-cnt-item-btn">
            {currentPage + 1} of {Math.ceil(history.length / 5)}
          </div>
          <div className="admin-overdue-bottom-pagination-cnt-item">
            <svg
              onClick={() => clickhandler(currentPage + 1)}
              width="auto"
              height="20"
              viewBox="0 0 13 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                fill="#717579"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Certificates;
