import React, { useEffect, useState } from "react";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  uploadBytes,
  deleteObject,
} from "@firebase/storage";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  increment,
} from "firebase/firestore";
import { db, functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { Timestamp } from "firebase/firestore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import "./createQuiz.css";
import { ExpandMoreSharp } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdEdit, MdDelete } from "react-icons/md";
import { ExpandMoreSvg } from "./icons";
import DeleteLesson from "./lessonDeletePopUp";
import LoadingScreen from "../BasicCourse/lodingScree";
const CreateQuestionTextPara = ({
  editQuizArr,
  questionData,
  quizId,
  questionSetId,
  type,
  mileStoneQuizId,
  mileStoneId,
  setfetchDataFromApi,
  fetchDataFromApi,
  flagForSeconstEdit,
}) => {
  const storage = getStorage();

  const setTimeForObjective = httpsCallable(functions, "setTimeForObjective");
  const addQuestionObjectiveOrderMilStone = httpsCallable(
    functions,
    "addQuestionObjectiveOrderMilStone"
  );
  const getAllQuestionDataForMileStoneObjQuiz = httpsCallable(
    functions,
    "getAllQuestionDataForMileStoneObjQuiz"
  );
  const getAllQuestionDataForQuiz = httpsCallable(
    functions,
    "getAllQuestionDataForQuiz"
  );
  const createQuestion = httpsCallable(functions, "createQuestion");
  const addQuestionObjectiveOrder = httpsCallable(
    functions,
    "addQuestionObjectiveOrder"
  );

  const fetchQuestionSetCotegory = httpsCallable(
    functions,
    "fetchQuestionSetCotegory"
  );
  //addQuestionObjectiveOrder
  const deleteObjQuestion = httpsCallable(functions, "deleteObjQuestion");
  // added by Himanshu
  const [deleteQuestionId, setDeleteQuestionId] = useState("");
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);
  const [questionCategory, setquestionCategory] = useState("");

  const [falgForEdit, setFlagForEdit] = useState(false);

  const [loadingScreen, setLoadingScreen] = useState(false);
  const [questionName, setQuestionName] = useState("");
  const [questionId, setQuestionId] = useState("");

  const initialList = [];
  const [list, setList] = useState(initialList);

  const [picMCQ, setPicMCQ] = useState({
    url: "",
    base64: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    answer: 0,
  });

  //
  const [cateGoryArr, setCategoryArr] = useState([]);

  useEffect(() => {
    console.log(type, editQuizArr);

    fetchQuestionSetCotegory().then((res) => {
      // setquestionCategory(res.data)
      setCategoryArr(res.data);
    });
    // if (!editQuizArr) return;
    console.log(flagForSeconstEdit);
    if (flagForSeconstEdit == 0) {
      return;
    }

    console.log(questionData);
    setFlagForEdit(true);
    setExtraArrForParaGraf(questionData.data);
    onpeFormForSelectedQuestion(questionData.data[1], 1);
    setEditFlagForParaGraf(1);
    setqus(questionData.data?.[0]);

    setQuestionId(questionData?.id);
    setQuestionName(questionData?.name);
    setquestionCategory(questionData?.questionCategory);
    setqusTime(questionData?.questionTime);
    setqustype(questionData?.type);

    //   setQuestionMarks(questionData.data?.questionMarks)
    //   setqus(questionData.data?.question)
    //     setqus5Answer(questionData.data.options.map((e,i)=>{if(e.iscorrect){
    //       return `${e} ${i+1}`

    //     }}))

    //     let obj={}
    //     questionData.data.options.map((e,i)=>{
    //       if(i==0){
    //             obj["option1"]=e.option
    //             if(e.iscorrect){
    //               setqus5Answer("Option 1")
    //             }

    //       }
    //       if(i==1){
    //         obj["option2"]=e.option
    //         if(e.iscorrect){
    //           setqus5Answer("Option 2")
    //         }

    //       }
    //       if(i==2){
    //         obj["option3"]=e.option
    //         if(e.iscorrect){
    //           setqus5Answer("Option 3")
    //         }

    //       }
    //       if(i==3){
    //         obj["option4"]=e.option
    //         if(e.iscorrect){
    //           setqus5Answer("Option 4")
    //         }

    //       }
    //       })
    //       setqus5(obj)
  }, [flagForSeconstEdit]);

  const [maxWordLimit, setmaxWordLimit] = useState("");
  const [ansType, setansType] = useState("");
  const [qusTime, setqusTime] = useState();
  const [qusMode, setqusMode] = useState("");
  const [qustype, setqustype] = useState("paragraph");
  const [qus, setqus] = useState("");
  const [qusInstruction, setqusInstruction] = useState("test");
  const [PointorScore, setPointorScore] = useState("");

  const [paragrafQuestion, setPragrafQuestion] = useState("");

  const [paraGrafData, setParaGrafData] = useState([]);
  const [extraArrForParaGraf, setExtraArrForParaGraf] = useState([]);

  const [editFlagForParaGraf, setEditFlagForParaGraf] = useState(false);

  const [questionMarks, setQuestionMarks] = useState(0);
  const [qus5, setqus5] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4: "",
  });
  const [qus5Answer, setqus5Answer] = useState("");
  const [qus2, setqus2] = useState({ word: "", correctImage: "" });
  const [formCheck, setFormCheck] = useState("");
  function submit(e) {
    e.preventDefault();
  }

  const ParagraphQuestionFunction = () => {
    if (qus5Answer == "") {
      alert("select ans");
      return;
    }

    let setoptionFromuser;
    if (qus5Answer == "Option 1") {
      let optionsFromUser = [
        { iscorrect: true, option: qus5.option1 },
        { iscorrect: false, option: qus5.option2 },
        { iscorrect: false, option: qus5.option3 },
        { iscorrect: false, option: qus5.option4 },
      ];
      setoptionFromuser = optionsFromUser;
    } else if (qus5Answer == "Option 2") {
      let optionsFromUser = [
        { iscorrect: false, option: qus5.option1 },
        { iscorrect: true, option: qus5.option2 },
        { iscorrect: false, option: qus5.option3 },
        { iscorrect: false, option: qus5.option4 },
      ];
      setoptionFromuser = optionsFromUser;
    } else if (qus5Answer == "Option 3") {
      let optionsFromUser = [
        { iscorrect: false, option: qus5.option1 },
        { iscorrect: false, option: qus5.option2 },
        { iscorrect: true, option: qus5.option3 },
        { iscorrect: false, option: qus5.option4 },
      ];
      setoptionFromuser = optionsFromUser;
    } else if (qus5Answer == "Option 4") {
      let optionsFromUser = [
        { iscorrect: false, option: qus5.option1 },
        { iscorrect: false, option: qus5.option2 },
        { iscorrect: false, option: qus5.option3 },
        { iscorrect: true, option: qus5.option4 },
      ];
      setoptionFromuser = optionsFromUser;
    }
    let questionDatafromUser = {
      options: setoptionFromuser,
      question: qus,
      questionInstruction: qusInstruction,
    };

    let teampArr = [];
    teampArr[0] = qus;

    for (let i = 1; i < extraArrForParaGraf.length; i++) {
      if (editFlagForParaGraf == i) {
        teampArr[editFlagForParaGraf] = {
          question: paragrafQuestion,
          options: setoptionFromuser,
          questionMarks: questionMarks,
        };
      } else {
        teampArr.push(extraArrForParaGraf[i]);

        if (
          i == extraArrForParaGraf.length - 1 &&
          editFlagForParaGraf == false
        ) {
          teampArr.push({
            question: paragrafQuestion,
            options: setoptionFromuser,
            questionMarks: questionMarks,
          });
        }
      }
    }
    if (extraArrForParaGraf.length == 0) {
      teampArr.push({
        question: paragrafQuestion,
        options: setoptionFromuser,
        questionMarks: questionMarks,
      });
    }

    //console.log(setoptionFromuser)

    setExtraArrForParaGraf(teampArr);
    console.log(teampArr);
    setPragrafQuestion("");
    setqus5({
      option1: "",
      option2: "",
      option3: "",
      option4: "",
    });
    setQuestionMarks(0);
    setEditFlagForParaGraf(false);
  };

  const onpeFormForSelectedQuestion = (ele, i) => {
    
    if(!ele){

      return "You Can Not Chenge The Type of Questions"
    }
    setEditFlagForParaGraf(i);
    console.log(ele);
    setPragrafQuestion(ele.question);
    setQuestionMarks(ele.questionMarks);

    let obj = {};
    ele.options.map((e, i) => {
      if (i == 0) {
        obj["option1"] = e.option;
        if (e.iscorrect) {
          setqus5Answer("Option 1");
        }
      }
      if (i == 1) {
        obj["option2"] = e.option;
        if (e.iscorrect) {
          setqus5Answer("Option 2");
        }
      }
      if (i == 2) {
        obj["option3"] = e.option;
        if (e.iscorrect) {
          setqus5Answer("Option 3");
        }
      }
      if (i == 3) {
        obj["option4"] = e.option;
        if (e.iscorrect) {
          setqus5Answer("Option 4");
        }
      }
    });
    setqus5(obj);
  };

  function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }

    if (type == "mileStone") {
      addQuestionObjectiveOrderMilStone({
        quizId: mileStoneQuizId,
        questionsArr: orderList,
        edit: true,
      });
    } else if (type == "questionSet") {
      addQuestionObjectiveOrder({
        instructionForObjId: quizId,
        questionsArr: orderList,
        edit: true,
      });
    }
  }

  const makeFormDefault = () => {
    setPragrafQuestion("");
    setqus5({
      option1: "",
      option2: "",
      option3: "",
      option4: "",
    });
    setQuestionMarks(0);
    setEditFlagForParaGraf(false);
  };

  const [checkErrorInForm, setCheckErrorInForm] = useState({
    questionName: "",
    questionType: "",
    questionCategory: "",
    data: [],
    qus5Answer: "",
    qus: "",
    maxFileSize: "",
    questionMarks: "",
    maxWordLimit: "",
    qusTime: "",
    base64Agreement: "",
    agreementUrl: "",
    ansType: "",
    uploadesVideoUrl: "",
    extraArrForParaGraf: "",
  });

  const formCheckFunction = () => {
    if (questionName == "") {
      setCheckErrorInForm((prev) => ({ ...prev, questionName: "error" }));
      return "Question Name";
    }
    if (qus == "") {
      setCheckErrorInForm((prev) => ({ ...prev, qus: "error" }));
      return "qus Name";
    }

    // if (qusInstruction == "") {
    //   setCheckErrorInForm((prev)=>({...prev,qusInstruction:"error"}))
    //   return "qus Name"
    // };
    if (questionCategory == "") {
      setCheckErrorInForm((prev) => ({ ...prev, questionCategory: "error" }));
      return "qus questionCategory";
    }

    if (qustype == "MCQ") {
      if (questionMarks == "") {
        setCheckErrorInForm((prev) => ({ ...prev, questionMarks: "error" }));
        return "questionMarks Name";
      }

      if (qus5Answer == "") {
        setCheckErrorInForm((prev) => ({ ...prev, qus5Answer: "error" }));
        return "questionMarks Name";
      }
    }
    if (qustype == "paragraph") {
      if (extraArrForParaGraf.length < 2) {
        alert("add min one question");
        return "addminone";
      }
    }

    return "alliswell";
  };

  const storedDataOnFirebase = async () => {
    let check = formCheckFunction();
    if (check != "alliswell") {
      setFormCheck("check");
      return;
    }

    let nsn = questionName.replaceAll(" ", "");

    let dat = /[^A-Za-z0-9]/.test(nsn);

    if (!dat) {
    } else {
      alert("you can not use any special characters");
      return;
    }

    setFormCheck("");
    let questionTypeEnum;
    let managequestionData;

    if (qustype == "MCQ") {
      let setoptionFromuser;
      if (qus5Answer == "Option 1") {
        let optionsFromUser = [
          { iscorrect: true, option: qus5.option1 },
          { iscorrect: false, option: qus5.option2 },
          { iscorrect: false, option: qus5.option3 },
          { iscorrect: false, option: qus5.option4 },
        ];
        setoptionFromuser = optionsFromUser;
      } else if (qus5Answer == "Option 2") {
        let optionsFromUser = [
          { iscorrect: false, option: qus5.option1 },
          { iscorrect: true, option: qus5.option2 },
          { iscorrect: false, option: qus5.option3 },
          { iscorrect: false, option: qus5.option4 },
        ];
        setoptionFromuser = optionsFromUser;
      } else if (qus5Answer == "Option 3") {
        let optionsFromUser = [
          { iscorrect: false, option: qus5.option1 },
          { iscorrect: false, option: qus5.option2 },
          { iscorrect: true, option: qus5.option3 },
          { iscorrect: false, option: qus5.option4 },
        ];
        setoptionFromuser = optionsFromUser;
      } else if (qus5Answer == "Option 4") {
        let optionsFromUser = [
          { iscorrect: false, option: qus5.option1 },
          { iscorrect: false, option: qus5.option2 },
          { iscorrect: false, option: qus5.option3 },
          { iscorrect: true, option: qus5.option4 },
        ];
        setoptionFromuser = optionsFromUser;
      }
      let questionDatafromUser = {
        options: setoptionFromuser,
        question: qus,
        questionInstruction: qusInstruction,
        questionMarks: questionMarks,
      };
      managequestionData = questionDatafromUser;

      questionTypeEnum = 5;
    }

    setLoadingScreen(true);
    createQuestion({
      questionId: questionId,
      questionName: questionName,
      questionTime: qusTime,
      questionType: qustype,
      created: new Date().toString(),
      data: managequestionData ? managequestionData : extraArrForParaGraf,
      associateInstructionId: quizId,
      questionSet: questionSetId,
      questionCategory: questionCategory,
      maxWordLimit: maxWordLimit,
      ansType: ansType,
      mileStoneQuizId: mileStoneQuizId,
      mileStoneId: mileStoneId,
    }).then((res) => {
      setLoadingScreen(false);
      setfetchDataFromApi(fetchDataFromApi + 1);
    });

    alert("data saved successfully");
    const newList = list.concat({
      id: questionId,
      name: questionName,
      type: qustype,
      questionTime: qusTime,
      data: managequestionData ? managequestionData : extraArrForParaGraf,

      associateInstructionId: quizId,
      questionSet: questionSetId,

      mileStoneQuizId: mileStoneQuizId,
      mileStoneId: mileStoneId,

      questionCategory: questionCategory,
    });
    if (!falgForEdit) {
      let total = 0;
      for (let i = 0; i < newList.length; i++) {
        total = total + Number(newList[i].questionTime);
      }

      if (total > 0) {
        console.log(total);

        if (quizId) {
          setTimeForObjective({
            instructionForObjId: quizId,

            quizTime: total,
          });
        }
      }

      setList(newList);
      saveOrder(newList);
    } else {
      for (let i = 0; i < list.length; i++) {
        if (questionId === list[i].id) {
          list[i] = {
            id: questionId,
            name: questionName,
            type: qustype,
            questionTime: qusTime,

            data: managequestionData ? managequestionData : extraArrForParaGraf,

            associateInstructionId: quizId,
            questionSet: questionSetId,

            mileStoneQuizId: mileStoneQuizId,
            mileStoneId: mileStoneId,

            questionCategory: questionCategory,
          };
        }
      }

      let total = 0;
      for (let i = 0; i < list.length; i++) {
        total = total + Number(list[i].questionTime);
      }

      if (total > 0) {
        console.log(total);

        if (quizId) {
          setTimeForObjective({
            instructionForObjId: quizId,

            quizTime: total,
          });
        }
      }

      setList(list);
      setFlagForEdit(false);
    }

    setQuestionId("");
    setQuestionName("");
    setqusInstruction("");
    setqus("");
    setqus5Answer("");
    setqusTime(0);

    setqus5({
      option1: "",
      option2: "",
      option3: "",
      option4: "",
    });
    setQuestionMarks(0);
    toggleQuestionForm();
  };

  const toggleQuestionForm = (e) => {
    setQuestionId("");
    setQuestionName("");
    setqusInstruction("");
    setqus("");
    setqus5Answer("");
    setqusTime(0);

    setqus5({
      option1: "",
      option2: "",
      option3: "",
      option4: "",
    });
    setQuestionMarks(0);
    document.getElementById(
      quizId ? quizId : mileStoneQuizId + "questionForm"
    ).style.display =
      document.getElementById(
        quizId ? quizId : mileStoneQuizId + "questionForm"
      ).style.display == "none"
        ? "block"
        : "none";
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    saveOrder(items);
  }

  function edit(questionData) {
    //     // console.log(lesson);
    //     setFlagForEdit(true)
    //     setQuestionId(lesson.id)
    //     setQuestionName(lesson?.name)
    //     setqusInstruction(lesson?.data?.questionInstruction )
    //     setqusTime(lesson.questionTime)
    // setqustype(lesson.type)
    // if(lesson.type =="MCQ"){
    //   setQuestionMarks(lesson.data?.questionMarks)
    //   setqus(lesson.data?.question)
    //     setqus5Answer(lesson.data.options.map((e,i)=>{if(e.iscorrect){
    //       return `${e} ${i+1}`
    //     }}))
    //     let obj={}
    //     lesson.data.options.map((e,i)=>{
    //       if(i==0){
    //             obj["option1"]=e.option
    //             if(e.iscorrect){
    //               setqus5Answer("Option 1")
    //             }
    //       }
    //       if(i==1){
    //         obj["option2"]=e.option
    //         if(e.iscorrect){
    //           setqus5Answer("Option 2")
    //         }
    //       }
    //       if(i==2){
    //         obj["option3"]=e.option
    //         if(e.iscorrect){
    //           setqus5Answer("Option 3")
    //         }
    //       }
    //       if(i==3){
    //         obj["option4"]=e.option
    //         if(e.iscorrect){
    //           setqus5Answer("Option 4")
    //         }
    //       }
    //       })
    //       setqus5(obj)
    //     }
    //     else{
    //       setExtraArrForParaGraf(lesson.data)
    //       onpeFormForSelectedQuestion(lesson.data[1],1)
    //       setEditFlagForParaGraf(1)
    //       setqus(lesson.data?.[0])
    //     }
    //       console.log("lesson")
    //     setquestionCategory(lesson.questionCategory)
    //     toggleQuestionForm();
  }

  function resetData() {}
  async function del(question) {
    setopenLessonPopUp(true);
    console.log(question);
    setDeleteQuestionId(question);
  }

  const delfunction = (question) => {
    console.log(question);
    deleteObjQuestion({ questionId: question.id, quizId: quizId }).then(
      (res) => {
        console.log(res.data);
      }
    );

    alert("Deleted " + question.name);
    const item1 = [];
    for (let i = 0; i < list.length; i++) {
      if (question.id !== list[i].id) {
        item1.push(list[i]);
      }
    }
    setList(item1);
    saveOrder(item1);
  };

  const toggleForm = () => {
    toggleQuestionForm();
    setFlagForEdit(false);
  };

  return (
    <section className="quizForm-container">
      <DeleteLesson
        open={openLessonPopUp}
        onClose={() => setopenLessonPopUp(false)}
        delfun={(topic) => {
          delfunction(topic);
        }}
        topicId={deleteQuestionId}
      />
      <LoadingScreen
        open={loadingScreen}
        onClose={() => setLoadingScreen(false)}
      />

      {/* <button className="quizFrom-button" onClick={()=>{toggleQuestionForm();setFlagForEdit(false)}}>
        Add Question
      </button> */}
      <div
        className="quizFrom-mainFrom"
        id={quizId ? quizId : mileStoneQuizId + "questionForm"}
      >
        <div className="quizForm-mainForm-infoRow">
          <p
            className="quizForm-mainForm-label"
            style={{
              fontWeight: 500,
              color: "#171B1E",
              width: "20%",
            }}
          >
            Question Name :
          </p>
          <input
            type="text"
            className="quizForm-mainForm-inputBar"
            id={quizId + "questionName"}
            value={questionName}
            style={{
              border: `${
                checkErrorInForm.questionName == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
              width: "30%",
              outline: "none",
              padding: "15px",
            }}
            onChange={(e) => {
              setQuestionName(e.target.value);
              setCheckErrorInForm((prev) => ({ ...prev, questionName: "" }));
              let value = e.target.value.replaceAll(" ", "");
              let time = new Date().getTime();
              if (!falgForEdit) {
                setQuestionId(value + "_" + time);
              }
            }}
          />

          {checkErrorInForm.questionName == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question Name
            </span>
          )}
        </div>
        {/* <div className="quizForm-mainForm-infoRow">
            <label className="quizForm-mainForm-label">Type of question : </label>
            <select
              className="quizForm-mainForm-select"
              value={qustype}
              onChange={(event) => {
                setqustype(event.target.value);
              }}
            >
              <option value="MCQ">Multiple Choice Question</option>
             


              <option value="paragraph">Paragraph</option>



            </select>
          </div> */}
        <div className="quizForm-mainForm-infoRow">
          <label
            className="quizForm-mainForm-label"
            style={{
              fontWeight: 500,
              color: "#171B1E",
              width: "20%",
            }}
          >
            Question :{" "}
          </label>
          <textarea
            className="quizForm-mainForm-textarea"
            value={qus}
            style={{
              border: `${
                checkErrorInForm.qus == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
              width: "30%",
              minHeight: "100px",
              borderRadius: "6px",
              outline: "none",
              padding: "15px",
            }}
            onChange={(event) => {
              setqus(event.target.value);
              setCheckErrorInForm((prev) => ({ ...prev, qus: "" }));
            }}
          ></textarea>
          {checkErrorInForm.qus == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question{" "}
            </span>
          )}
        </div>

        <div className="quizForm-mainForm-infoRow">
          <label
            className="quizForm-mainForm-label"
            style={{
              fontWeight: 500,
              color: "#171B1E",
              width: "20%",
            }}
          >
            Question time :{" "}
          </label>
          <input
            className="quizForm-mainForm-inputBar"
            value={qusTime}
            type="Number"
            style={{
              border: `${
                checkErrorInForm.qusTime == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
              width: "30%",
              outline: "none",
              padding: "15px",
            }}
            onChange={(event) => {
              setqusTime(event.target.value);
              setCheckErrorInForm((prev) => ({ ...prev, qusTime: "" }));
            }}
          />

          {checkErrorInForm.qusTime == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question Time{" "}
            </span>
          )}
        </div>
        {/* <div className="quizForm-mainForm-infoRow">
            <label className="quizForm-mainForm-label">Instruction : </label>
            <textarea
              className="quizForm-mainForm-textarea"
              value={qusInstruction}
              onChange={(event) => {
                setqusInstruction(event.target.value);
              }}
            ></textarea>
          </div> */}

        <div className="quizForm-mainForm-infoRow">
          <label
            className="quizForm-mainForm-label"
            style={{
              fontWeight: 500,
              color: "#171B1E",
              width: "20%",
            }}
          >
            {" "}
            question Category :{" "}
          </label>
          <select
            className="quizForm-mainForm-select"
            value={questionCategory}
            style={{
              border: `${
                checkErrorInForm.questionCategory == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
              width: "30%",
              outline: "none",
              height: "40px",
            }}
            onChange={(event) => {
              setquestionCategory(event.target.value);
              setCheckErrorInForm((prev) => ({
                ...prev,
                questionCategory: "",
              }));
            }}
          >
            <option value="">Select Question Category </option>

            {cateGoryArr?.map((e) => (
              <option value={e.name}>{e.name}</option>
            ))}
          </select>

          {checkErrorInForm.questionCategory == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question questionCategory{" "}
            </span>
          )}
        </div>

        <div className="quizForm-mainForm-infoRow">
          {extraArrForParaGraf?.map((e, i) => {
            if (i != 0) {
              return (
                <button
                  style={{
                    width: "40px",
                    margin: "20px",
                    color: editFlagForParaGraf == i ? "white" : "",
                    backgroundColor: editFlagForParaGraf == i ? "blue" : "",
                  }}
                  onClick={() => {
                    onpeFormForSelectedQuestion(e, i);
                  }}
                >
                  {i}
                </button>
              );
            }
          })}

          <button
            className="action-btn"
            onClick={() => {
              makeFormDefault();
            }}
          >
            Add New Question
          </button>
        </div>

        <div className="quizForm-mainForm-infoRow">
          <label
            className="quizForm-mainForm-label"
            style={{
              fontWeight: 500,
              color: "#171B1E",
              width: "20%",
            }}
          >
            Paragraph Sub Question{" "}
          </label>
          <input
            className="quizForm-mainForm-inputBar"
            name="text"
            placeholder="Enter Question"
            value={paragrafQuestion}
            onChange={(event) => {
              setPragrafQuestion(event.target.value);
            }}
            style={{
              width: "30%",
              border: "1px solid #d7d7d7",
              outline: "none",
              padding: "15px",
            }}
          />
        </div>

        <div className="quizForm-mainForm-infoRow">
          <label
            className="quizForm-mainForm-label"
            style={{
              fontWeight: 500,
              color: "#171B1E",
              width: "20%",
            }}
          >
            Question Marks
          </label>
          <input
            className="quizForm-mainForm-inputBar"
            name="Number"
            placeholder="Enter Question"
            value={questionMarks}
            onChange={(event) => {
              setQuestionMarks(event.target.value);
            }}
            style={{
              width: "30%",
              border: "1px solid #d7d7d7",
              outline: "none",
              padding: "15px",
            }}
          />
        </div>

        <div className="quizForm-mainForm-infoRow">
          <label
            className="quizForm-mainForm-label"
            style={{
              fontWeight: 500,
              color: "#171B1E",
              width: "20%",
            }}
          >
            Option 1 :{" "}
          </label>
          <input
            className="quizForm-mainForm-inputBar"
            name="text"
            placeholder="Enter Option"
            value={qus5.option1}
            onChange={(event) => {
              setqus5({ ...qus5, option1: event.target.value });
            }}
            style={{
              width: "30%",
              border: "1px solid #d7d7d7",
              outline: "none",
              padding: "15px",
            }}
          />
        </div>
        <div className="quizForm-mainForm-infoRow">
          <label
            className="quizForm-mainForm-label"
            style={{
              fontWeight: 500,
              color: "#171B1E",
              width: "20%",
            }}
          >
            Option 2 :{" "}
          </label>
          <input
            className="quizForm-mainForm-inputBar"
            name="text"
            value={qus5.option2}
            placeholder="Enter Option"
            onChange={(event) => {
              setqus5({ ...qus5, option2: event.target.value });
            }}
            style={{
              width: "30%",
              border: "1px solid #d7d7d7",
              outline: "none",
              padding: "15px",
            }}
          />
        </div>
        <div className="quizForm-mainForm-infoRow">
          <label
            className="quizForm-mainForm-label"
            style={{
              fontWeight: 500,
              color: "#171B1E",
              width: "20%",
            }}
          >
            Option 3 :{" "}
          </label>
          <input
            className="quizForm-mainForm-inputBar"
            name="text"
            value={qus5.option3}
            placeholder="Enter Option"
            onChange={(event) => {
              setqus5({ ...qus5, option3: event.target.value });
            }}
            style={{
              width: "30%",
              border: "1px solid #d7d7d7",
              outline: "none",
              padding: "15px",
            }}
          />
        </div>
        <div className="quizForm-mainForm-infoRow">
          <label
            className="quizForm-mainForm-label"
            style={{
              fontWeight: 500,
              color: "#171B1E",
              width: "20%",
            }}
          >
            Option 4 :{" "}
          </label>
          <input
            className="quizForm-mainForm-inputBar"
            type="text"
            value={qus5.option4}
            placeholder="Enter Option"
            onChange={(event) => {
              setqus5({ ...qus5, option4: event.target.value });
            }}
            style={{
              width: "30%",
              border: "1px solid #d7d7d7",
              outline: "none",
              padding: "15px",
            }}
          />
        </div>

        <div className="quizForm-mainForm-infoRow">
          <label
            className="quizForm-mainForm-label"
            style={{
              fontWeight: 500,
              color: "#171B1E",
              width: "20%",
            }}
          >
            {" "}
            Choose the correct Option :{" "}
          </label>
          <select
            style={{
              width: "30%",
              border: "1px solid #d7d7d7",
              outline: "none",
              height: "40px",
            }}
            className="quizForm-mainForm-select"
            value={qus5Answer}
            onChange={(event) => {
              setqus5Answer(event.target.value);
            }}
          >
            <option value="">Select Option</option>
            <option value="Option 1">Option 1</option>
            <option value="Option 2">Option 2</option>
            <option value="Option 3">Option 3</option>
            <option value="Option 4">Option 4</option>
          </select>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "30px",
            margin: "20px",
          }}
        >
          <button
            onClick={ParagraphQuestionFunction}
            disabled={editFlagForParaGraf != false ? false : true}
            className="primary-btn"
          >
            {" "}
            update this Question
          </button>
          <button
            onClick={ParagraphQuestionFunction}
            disabled={editFlagForParaGraf == false ? false : true}
            className="secondary-btn"
          >
            {" "}
            Save this Question
          </button>
        </div>

        {formCheck && <div> Fill all the Required fields.</div>}
        <div
          className="quizForm-mainForm-infoRow"
          style={{ marginTop: "30px" }}
        >
          <button
            className="primary-btn"
            style={{ marginLeft: "auto" }}
            onClick={storedDataOnFirebase}
          >
            Submit
          </button>
          <button
            className="secondary-btn"
            style={{ marginLeft: "10px" }}
            onClick={toggleForm}
          >
            Cancel
          </button>
        </div>
      </div>
    </section>
  );
};

export default CreateQuestionTextPara;
