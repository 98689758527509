import React, { useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import Button from "@mui/material/Button";
import { useLocation } from "react-router";
import { functions } from "../../../Firebase";
import "./CertificateDetails.css";
import { setDefaultNamespace } from "i18next";
import Sidebar from "../../Component/Sidebar";
import NavbarTop from "../../Component/NavbarTop";
import { t } from "i18next";
import FileUploadIcon from "@mui/icons-material/FileUpload";

function CertificteCreate() {
  const saveCertificate = httpsCallable(functions, "saveCertificate");
  const previewCertificate = httpsCallable(functions, "previewCertificate");
  const pdfUpload = httpsCallable(functions, "pdfUpload");

  const [defname, setdefname] = useState();
  const [defcourse, setdefcourse] = useState();
  const [defdate, setdefdate] = useState();
  const [url, seturl] = useState("");
  const [defemployee, setdefemployee] = useState("");

  const [image, setImage] = useState({
    file: null,
    url: "",
  });
  const [base64Image, setBase64Image] = useState("");

  const [username, setusername] = useState({
    y: 0,
    color: "#066099",
    font: "Helvatica",
    fontsize: 0,
    bold: false,
    opacity: 0.8,
  });

  const [usercourse, setusercourse] = useState({
    y: 0,
    color: "#066099",
    font: "Helvatica",
    fontsize: 0,
    bold: false,
    opacity: 0.8,
  });

  const [date, setdate] = useState({
    y: 0,
    x: 0,
    color: "#066099",
    font: "Helvatica",
    fontsize: 0,
    bold: false,
    opacity: 0.8,
  });

  const [employee, setEmployee] = useState({
    y: 0,
    x: 0,
    color: "#066099",
    font: "Helvatica",
    fontsize: 0,
    bold: false,
    opacity: 0.8,
  });

  const [sign, setsign] = useState({
    y: 0,
    x: 0,
    width: 0,
    aspectRatio: true,
  });

  let name, value;
  const getuserdata = (e) => {
    name = e.target.name;
    if (name == "y" || name == "fontsize") {
      value = +e.target.value;
    } else if (name == "bold") {
      if (e.target.value == "true") {
        value = true;
      } else {
        value = false;
      }
    } else {
      value = e.target.value;
    }
    console.log(value);
    setusername({ ...username, [name]: value });
  };

  const getcoursedata = (e) => {
    name = e.target.name;
    if (name == "y" || name == "fontsize") {
      value = +e.target.value;
    } else if (name == "bold") {
      if (e.target.value == "true") {
        value = true;
      } else {
        value = false;
      }
    } else {
      value = e.target.value;
    }
    setusercourse({ ...usercourse, [name]: value });
  };

  const getdatedata = (e) => {
    name = e.target.name;
    if (name == "y" || name == "x" || name == "fontsize") {
      value = +e.target.value;
    } else if (name == "bold") {
      if (e.target.value == "true") {
        value = true;
      } else {
        value = false;
      }
    } else {
      value = e.target.value;
    }
    setdate({ ...date, [name]: value });
  };

  const getEmployeeData = (e) => {
    name = e.target.name;
    if (name == "y" || name == "x" || name == "fontsize") {
      value = +e.target.value;
    } else if (name == "bold") {
      if (e.target.value == "true") {
        value = true;
      } else {
        value = false;
      }
    } else {
      value = e.target.value;
    }
    setEmployee({ ...employee, [name]: value });
  };

  const getsigndata = (e) => {
    name = e.target.name;
    if (name == "y" || name == "x" || name == "width") {
      value = +e.target.value;
    } else {
      value = e.target.value;
    }
    setsign({ ...sign, [name]: value });
  };

  const postpdf = async () => {
    // console.log(base64Image);
    await pdfUpload({ base64Image: base64Image }).then((res) => {
      // console.log(res);
      seturl(res.data);
    });
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    const url = URL.createObjectURL(files[0]);
    setImage((prev) => ({
      file: files[0],
      url: url,
    }));
    const reader = new FileReader();
    reader.onloadend = () => {
      setBase64Image(reader.result);
      //console.log("setBase64Image done", reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const previewcertificate = () => {
    // console.log(url,username,usercourse,date,sign,defname,defcourse,defdate,base64Image,defemployee,employee)
    console.log(defname, defcourse, defdate);
    previewCertificate({
      tempUrl: url,
      tempUser: username,
      tempCourse: usercourse,
      tempDate: date,
      tempSign: sign,
      userName: defname,
      courseName: defcourse,
      completeDate: defdate,
      base64Image: base64Image,
      tempEnroll: employee,
    })
      .then((res) => {
        console.log("url :  ", res);
        const url = res.data;
        seturl("hfjsdjh");
        seturl(url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const savechanges = () => {
    saveCertificate({
      tempurl: url,
      user: username,
      course: usercourse,
      date: date,
      sign: sign,
      tempEnroll: employee,
    }).then((res) => {
      console.log(res);
      alert(res.data.msg);
    });
  };
  // console.log(employee);

  useEffect(() => {
    //   fetchdata();
  }, []);

  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Certificate Settings")} />
        {/* <Navbar /> */}

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-details-container">
              <div className="">
                <h1
                  className="bigger-container-header"
                  style={{ color: "#1E3758" }}
                >
                  New Template
                </h1>
              </div>

              <div className="create-new-box-pdf">
                <button className="action-btn">
                  <FileUploadIcon />
                  <input
                    type={"file"}
                    onChange={handleFileChange}
                    className="df"
                  />
                </button>

                <button className="action-btn" onClick={postpdf}>
                  Upload Pdf
                </button>
              </div>

              <div
                style={{
                  backgroundColor: "#fff",
                  padding: "15px 20px",
                  borderRadius: "10px",
                  marginTop: "30px",
                }}
              >
                <div
                  className="user-data-bigger-container"
                  style={{
                    fontSize: "20px",
                    color: "#1E3758",
                    fontWeight: 500,
                  }}
                >
                  Enter Users Data
                </div>

                <div>
                  <div className="user-data-container">
                    <div className="" style={{ margin: "10px 0px" }}>
                      <p className="user-data-label">User Name</p>
                      <input
                        type="text"
                        name="username"
                        placeholder=""
                        className="user-data-input"
                        value={defname}
                        onChange={(e) => {
                          setdefname(e.target.value);
                        }}
                      ></input>
                    </div>
                    <div className="" style={{ margin: "10px 0px" }}>
                      <p className="user-data-label">Date Of Completion</p>
                      <input
                        type="date"
                        name="completiondate"
                        placeholder=""
                        className="user-data-input"
                        value={defdate}
                        onChange={(e) => {
                          setdefdate(e.target.value);
                        }}
                      ></input>
                    </div>
                  </div>
                  <div className="user-data-container">
                    <div className="" style={{ margin: "10px 0px" }}>
                      <p className="user-data-label">Course Name</p>
                      <input
                        type="text"
                        name="coursename"
                        placeholder=""
                        className="user-data-input"
                        value={defcourse}
                        onChange={(e) => {
                          setdefcourse(e.target.value);
                        }}
                      ></input>
                    </div>
                    {/* <div className="" style={{margin:"10px 0px"}}>
<spam className="" style={{fontWeight:"bold"}}>Employee Number:</spam>
<input
  type="text"
  name="employeenumber"
  placeholder=""
  className="user-data-input"
  value={defemployee}
  onChange={(e)=>{setdefemployee(e.target.value)}}
></input>
</div> */}
                  </div>
                  <div className="user-data-container">
                    <div className="" style={{ margin: "10px 0px" }}>
                      <p className="user-data-label">Instruct Sign</p>
                      <input
                        type={"file"}
                        onChange={handleFileChange}
                        className="action-btn"
                        style={{
                          marginTop: "10px",
                          width: "200px",
                          padding: "10px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="" style={{ marginTop: "38px" }}>
                <h1
                  className="bigger-container-header"
                  style={{ color: "#1E3758" }}
                >
                  Settings
                </h1>
              </div>

              <div
                style={{
                  backgroundColor: "#fff",
                  padding: "15px 20px",
                  borderRadius: "10px",
                  marginTop: "30px",
                }}
              >
                <div
                  className="user-data-bigger-container"
                  style={{
                    fontSize: "20px",
                    color: "#1E3758",
                    fontWeight: 500,
                  }}
                >
                  Username
                </div>
                <div>
                  <div
                    className="username-container"
                    style={{ margin: "0 -10px" }}
                  >
                    <div className="" style={{ margin: "10px 20px" }}>
                      <p className="user-data-label">Y__ :</p>
                      <input
                        type="Number"
                        name="y"
                        placeholder=""
                        className="user-data-input"
                        min="0"
                        value={username.y}
                        onChange={getuserdata}
                      ></input>
                    </div>
                    <div className="" style={{ margin: "10px 20px" }}>
                      <p className="user-data-label">color :</p>
                      <input
                        type="text"
                        name="color"
                        placeholder=""
                        className="user-data-input"
                        value={username.color}
                        onChange={getuserdata}
                      ></input>
                    </div>
                    <div className="" style={{ margin: "10px 20px" }}>
                      <p className="user-data-label">Font :</p>
                      <input
                        type="text"
                        name="font"
                        placeholder=""
                        className="user-data-input"
                        value={username.font}
                        onChange={getuserdata}
                      ></input>
                    </div>
                  </div>
                  <div
                    className="username-container"
                    style={{ margin: "0 -10px" }}
                  >
                    <div className="" style={{ margin: "10px 20px" }}>
                      <p className="user-data-label">Font_Size :</p>
                      <input
                        type="Number"
                        name="fontsize"
                        placeholder=""
                        className="user-data-input"
                        min="0"
                        value={username.fontsize}
                        onChange={getuserdata}
                      ></input>
                    </div>
                    <div className="" style={{ margin: "10px 20px" }}>
                      <p className="user-data-label">Bold :</p>
                      <select
                        className="user-data-input"
                        name="bold"
                        id="option"
                        onChange={getuserdata}
                      >
                        <option selected={username.bold == false} value={false}>
                          False
                        </option>
                        <option selected={username.bold == true} value={true}>
                          True
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  backgroundColor: "#fff",
                  padding: "15px 20px",
                  borderRadius: "10px",
                  marginTop: "30px",
                }}
              >
                <div
                  className="user-data-bigger-container"
                  style={{
                    fontSize: "20px",
                    color: "#1E3758",
                    fontWeight: 500,
                  }}
                >
                  Usercourse
                </div>
                <div
                  className="username-container"
                  style={{ margin: "0 -15px" }}
                >
                  <div className="" style={{ margin: "10px 20px" }}>
                    <p className="user-data-label">Y__ :</p>
                    <input
                      type="Number"
                      name="y"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={usercourse.y}
                      onChange={getcoursedata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <p className="user-data-label">color :</p>
                    <input
                      type="text"
                      name="color"
                      placeholder=""
                      className="user-data-input"
                      value={usercourse.color}
                      onChange={getcoursedata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <p className="user-data-label">Font :</p>
                    <input
                      type="text"
                      name="font"
                      placeholder=""
                      className="user-data-input"
                      value={usercourse.font}
                      onChange={getcoursedata}
                    ></input>
                  </div>
                </div>
                <div
                  className="username-container"
                  style={{ margin: "0 -15px" }}
                >
                  <div className="" style={{ margin: "10px 20px" }}>
                    <p className="user-data-label">Font_Size :</p>
                    <input
                      type="Number"
                      name="fontsize"
                      placeholder=""
                      className="user-data-input"
                      min="0"
                      value={usercourse.fontsize}
                      onChange={getcoursedata}
                    ></input>
                  </div>
                  <div className="" style={{ margin: "10px 20px" }}>
                    <p className="user-data-label">Bold :</p>
                    <select
                      className="user-data-input"
                      name="bold"
                      id="option"
                      onChange={getcoursedata}
                    >
                      <option selected={usercourse.bold == false} value={false}>
                        False
                      </option>
                      <option selected={usercourse.bold == true} value={true}>
                        True
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div
                style={{
                  backgroundColor: "#fff",
                  padding: "15px 20px",
                  borderRadius: "10px",
                  marginTop: "30px",
                }}
              >
                <div
                  className="user-data-bigger-container"
                  style={{
                    fontSize: "20px",
                    color: "#1E3758",
                    fontWeight: 500,
                  }}
                >
                  Date
                </div>
                <div>
                  <div
                    className="username-container"
                    style={{ margin: "0 -10px" }}
                  >
                    <div className="" style={{ margin: "10px 20px" }}>
                      <p className="user-data-label">Y__ :</p>
                      <input
                        type="Number"
                        name="y"
                        placeholder=""
                        className="user-data-input"
                        min="0"
                        value={date.y}
                        onChange={getdatedata}
                      ></input>
                    </div>
                    <div className="" style={{ margin: "10px 20px" }}>
                      <p className="user-data-label">X__ :</p>
                      <input
                        type="Number"
                        name="x"
                        placeholder=""
                        className="user-data-input"
                        min="0"
                        value={date.x}
                        onChange={getdatedata}
                      ></input>
                    </div>
                    <div className="" style={{ margin: "10px 20px" }}>
                      <p className="user-data-label">Color :</p>
                      <input
                        type="text"
                        name="color"
                        placeholder=""
                        className="user-data-input"
                        value={date.color}
                        onChange={getdatedata}
                      ></input>
                    </div>
                  </div>
                  <div
                    className="username-container"
                    style={{ margin: "0 -10px" }}
                  >
                    <div className="" style={{ margin: "10px 20px" }}>
                      <p className="user-data-label">Font :</p>
                      <input
                        type="text"
                        name="font"
                        placeholder=""
                        className="user-data-input"
                        value={date.font}
                        onChange={getdatedata}
                      ></input>
                    </div>
                    <div className="" style={{ margin: "10px 20px" }}>
                      <p className="user-data-label">Font_Size :</p>
                      <input
                        type="Number"
                        name="fontsize"
                        placeholder=""
                        className="user-data-input"
                        min="0"
                        value={date.fontsize}
                        onChange={getdatedata}
                      ></input>
                    </div>
                    <div className="" style={{ margin: "10px 20px" }}>
                      <p className="user-data-label">Bold :</p>
                      <select
                        className="user-data-input"
                        name="bold"
                        id="option"
                        onChange={getdatedata}
                      >
                        <option selected={date.bold == false} value={false}>
                          False
                        </option>
                        <option selected={date.bold == true} value={true}>
                          True
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="user-data-bigger-container">Employee Number:</div>
        <div className="" style={{border:"2px solid lightgray"}}>

          <div className="username-container">
            <div className="" style={{margin:"10px 20px"}}>
              <spam className="" style={{fontWeight:"bold"}}>Y__ :</spam>
              <input
                type="Number"
                name="y"
                placeholder=""
                className="user-data-input"
                min="0"
                value={employee.y}
                onChange={getEmployeeData}
              ></input>
            </div>
            <div className="" style={{margin:"10px 20px"}}>
              <spam className="" style={{fontWeight:"bold"}}>X__ :</spam>
              <input
                type="Number"
                name="x"
                placeholder=""
                className="user-data-input"
                min="0"
                value={employee.x}
                onChange={getEmployeeData}
              ></input>
            </div>
            <div className="" style={{margin:"10px 20px"}}>
              <spam className="" style={{fontWeight:"bold"}}>Color :</spam>
              <input
                type="text"
                name="color"
                placeholder=""
                className="user-data-input"
                value={employee.color}
                onChange={getEmployeeData}
              ></input>
            </div>
          </div>
          <div className="username-container">
            <div className="" style={{margin:"10px 20px"}}>
              <spam className="" style={{fontWeight:"bold"}}>Font :</spam>
              <input
                type="text"
                name="font"
                placeholder=""
                className="user-data-input"
                value={employee.font}
                onChange={getEmployeeData}
              ></input>
            </div>
            <div className="" style={{margin:"10px 20px"}}>
              <spam className="" style={{fontWeight:"bold"}}>Font_Size :</spam>
              <input
                type="Number"
                name="fontsize"
                placeholder=""
                className="user-data-input"
                min="0"
                value={employee.fontsize}
                onChange={getEmployeeData}
              ></input>
            </div>
            <div className="" style={{margin:"10px 20px"}}>
              <spam className="" style={{fontWeight:"bold"}}>Bold :</spam>
              <select className="user-data-input" name="bold" id="option" onChange={getEmployeeData}>
              <option selected={employee.bold==false} value={false}>False</option>
              <option selected={employee.bold==true} value={true}>True</option>
              </select>  
            </div>
            </div>
        </div> */}

              <div
                style={{
                  backgroundColor: "#fff",
                  padding: "15px 20px",
                  borderRadius: "10px",
                  marginTop: "30px",
                }}
              >
                <div
                  className="user-data-bigger-container"
                  style={{
                    fontSize: "20px",
                    color: "#1E3758",
                    fontWeight: 500,
                  }}
                >
                  Signature
                </div>
                <div>
                  <div
                    className="username-container"
                    style={{ margin: "0 -10px" }}
                  >
                    <div className="" style={{ margin: "10px 20px" }}>
                      <p className="user-data-label">Y__ :</p>
                      <input
                        type="Number"
                        name="y"
                        placeholder=""
                        className="user-data-input"
                        min="0"
                        value={sign.y}
                        onChange={getsigndata}
                      ></input>
                    </div>
                    <div className="" style={{ margin: "10px 20px" }}>
                      <p className="user-data-label">X__ :</p>
                      <input
                        type="Number"
                        name="x"
                        placeholder=""
                        className="user-data-input"
                        min="0"
                        value={sign.x}
                        onChange={getsigndata}
                      ></input>
                    </div>
                    <div className="" style={{ margin: "10px 20px" }}>
                      <p className="user-data-label">Width :</p>
                      <input
                        type="Number"
                        name="width"
                        placeholder=""
                        className="user-data-input"
                        min="0"
                        value={sign.width}
                        onChange={getsigndata}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="preview-container">
                  <div className="" style={{ margin: "5px 10px" }}>
                    {/* <a href={data.templateUrl} target="_blank" rel="noopener noreferrer"> */}
                    <button className="view-btn" onClick={previewcertificate}>
                      Preview
                    </button>
                    {/* </a> */}
                  </div>
                </div>
              </div>

              <div className="">
                <h1
                  className="bigger-container-header"
                  style={{ margin: "20px 0", color: "#1E3758" }}
                >
                  Preview Certificate
                </h1>
              </div>
              <div className="">
                <embed type="" src={url} width="100%" height="500" />
              </div>

              <div className="preview-container">
                <button className="primary-btn" onClick={savechanges}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default CertificteCreate;
