import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCookie } from "../../../Cookies";
import "../Dashboard/OverdueEnrollments.css";
import Navbar from "../../Component/NavbarTop";
import Sidebar from "../../Component/Sidebar";
import "./UserView.css";
import { useLocation, useNavigate } from "react-router";
import { Link, useParams } from "react-router-dom";
import "../StyleAdminComponents/GroupList.css";
import InternalTraining from "./Components/InternalTraining";
import InternalOfflineTraining from "./Components/InternalOfflineTraining";
import ExternalTraining from "./Components/ExternalTraining";
import PreviousTraining from "./Components/PreviousTraining";
import Certificates from "./Components/Certificates";
import DocumentsOfUser from "./Components/documents";
import TempFIle from "./Components/tempFile";
import PaymentDetails from "./Components/paymentDetels";
import ChnageUserStatus from "./Components/chengeUserStatus";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import { Box } from "@mui/system";
import LoadingScreen from "../BasicCourse/lodingScree";
import ALlStatuspage from "./Components/allSatusPage";
import UserAgeAndDob from "./Components/ageAndDobData";
import QualificationAndExp from "./Components/QualificationAndExpe";

function UserView() {
  const navigate = useNavigate();
  const goToCer = () => navigate("/admin/certificateRenewal/:id");
  const location = useLocation();
  const getUserAppliedCouses = httpsCallable(functions, "getUserAppliedCouses");
  const getUserStatus = httpsCallable(functions, "getUserStatus");
  const getUserInformetionData = httpsCallable(
    functions,
    "getUserInformetionData"
  );
  const getAllCourse = httpsCallable(functions, "getAllCourse");
  const userEnrollment = httpsCallable(functions, "userEnrollment");
  const userDetails = location.state;

  const { clrs } = useSelector((state) => state.createClr);
  const [arr, setArr] = useState();
  const [pageNo, setPageNo] = useState();
  // const [curPage, setCurPage] = useState();
  const [popup, setpopup] = useState(false);
  const [open, setOpen] = useState(false);
  const [courseId, setCourseId] = useState("");
  // const { t } = useTranslation();
  // console.log("userDetails", userDetails);
  const getUserSubmitionCourses = httpsCallable(
    functions,
    "getUserSubmitionCourses"
  );

  const [coordinatore, setCoordinatore] = useState("");
  const getUserMileStone = httpsCallable(functions, "getUserMileStone");
  const getUserHistory = httpsCallable(functions, "getUserHistory");
  const getUserPayments = httpsCallable(functions, "getUserPayments");
  const [detaildData, setDetaildData] = useState([]);
  const [submitionId, setSubmitionId] = useState("");

  const [loadingScreen, setLoadingScreen] = useState(false);
  const [flagForEDit, setFlagForEDit] = useState(false);
  const [courseArray, setCourseArray] = useState([]);
  const [courseNameObj, setCourseNameObj] = useState({});

  const [submitionArr, setSubmitionArr] = useState([]);
  const [mileStoneArr, setMileStoneArr] = useState([]);
  const [userHistory, setUserHistory] = useState([]);

  const [userDocument, setUserDocument] = useState({});
  const [userdataFlag, setUserdataFlag] = useState(false);
  const [statusData, setStatusData] = useState([]);

  const [paymentsData, setPaymentsData] = useState([]);

  const [stateForVideoCount, setStateForVideoCount] = useState({});
  const [userStatusData, setUserStatusData] = useState({
    objectiveTestStatus: "",
    subjectiveTestStatus: "",
    documentsVerificationStatus: "",
    paymentStatus: "",
    agreementStatus: "",
    approvalStatus: "",
  });

  console.log(userStatusData);
  useEffect(() => {
    getAllCourse({}).then((res) => {
      let obj = {};
      res.data?.data?.map((el) => {
        obj[el.courseId] = el.courseName;
      });
      console.log(obj);
      setCourseNameObj(obj);
    });
    setLoadingScreen(true);

    getUserAppliedCouses({ uid: userDetails.uid })
      .then((res) => {
        //console.log(res.data);
        setCourseArray(res.data);
        setStatusData(res.data);
        setFlagForEDit(true);
        setLoadingScreen(false);

        if (userDetails.courseId && userDetails.attempt) {
          setSubmitionId(userDetails.attempt);
          setCourseId(userDetails.courseId);
          //  getUserSubmitions(userDetails.courseId);

          let filterArr = res.data.filter(
            (item) => item.courseId == userDetails.courseId
          );
          let obj = {};
          filterArr.map((e) => {
            obj[e?.groupEnrollmentId] = "data";
          });
          setSubmitionArr(filterArr[0]?.attemptsArr);
          //  setSubmitionArr(filterArr[0]?.attemptsArr);

          getDataFromApi(userDetails.courseId, userDetails.attempt);
        }
      })
      .catch((e) => {
        setLoadingScreen(false);
      });
  }, []);

  const goToHome = () => navigate("/");

  useEffect(() => {
    if (getCookie("UID") == null || getCookie("ROLE") != "admin") {
      goToHome();
      return <></>;
    }
  }, []);
  const { id } = useParams();

  const getUserSubmitions = (e) => {
    let filterArr = courseArray.filter((item) => item.courseId == e);
    let obj = {};
    filterArr.map((e) => {
      obj[e?.groupEnrollmentId] = "data";
    });
    setSubmitionArr(filterArr[0]?.attemptsArr);
  };

  const getDataFromApi = (cId, aId) => {
    console.log(courseId, submitionId);
    let flag = 0;
    if (courseId == "" && submitionId == "") {
      flag = flag + 1;
    }

    if (cId == "" && aId == "") {
      flag = flag + 1;
    }
    console.log(flag);
    if (flag == 2) {
      alert("select courseId and Attempt");
      return;
    }

    setLoadingScreen(true);

    // let userSta = statusData.filter(
    //   (ele) =>
    //     ele.courseId == courseId && ele.groupEnrollmentId == submitionId
    // );
    // console.log(userSta)
    // if (userSta.length > 0) {
    //   setUserStatusData({
    //     objective: userSta[0]?.objectiveTestStatus,
    //     subjective: userSta[0]?.subjectiveTestStatus,
    //     document: userSta[0]?.documentsVerificationStatus,
    //     payment: userSta[0]?.paymentStatus,
    //     agreement: userSta[0]?.agreementStatus,
    //     approve: userSta[0]?.approvalStatus,
    //   });
    // }

    getUserHistory({
      uid: userDetails.uid,
      courseId: courseId ? courseId : cId,
      attempt: submitionId ? submitionId : aId,
    }).then((res) => {
      if (res.data == null) {
        return;
      }
      setUserHistory(res.data);
    });

    getUserPayments({
      uid: userDetails.uid,
      courseId: courseId ? courseId : cId,
      attempt: submitionId ? submitionId : aId,
    }).then((res) => {
      //console.log(res.data);
      setPaymentsData(res.data);
    });

    getUserSubmitionCourses({
      uid: userDetails.uid,
      courseId: courseId ? courseId : cId,
      attempt: submitionId ? submitionId : aId,
    })
      .then((res) => {
        setLoadingScreen(false);
        //console.log(res.data);
        setDetaildData(res.data);
      })
      .catch((e) => {
        setLoadingScreen(false);
      });
    getUserStatus({
      uid: userDetails.uid,
      courseId: courseId ? courseId : cId,
      attempt: submitionId ? submitionId : aId,
    }).then((res) => {
      // console.log(res.data);
      if (res.data) {
        setStateForVideoCount(res.data[0]);

        setUserStatusData({
          objectiveTestStatus: res.data[0]?.objectiveTestStatus,
          subjectiveTestStatus: res.data[0]?.subjectiveTestStatus,
          documentsVerificationStatus: res.data[0]?.documentsVerificationStatus,
          paymentStatus: res.data[0]?.paymentStatus,
          agreementStatus: res.data[0]?.agreementStatus,
          approvalStatus: res.data[0]?.approvalStatus,
        });
        setCoordinatore(res.data[0]?.coordinatorId);

        getUserMileStone({
          uid: userDetails.uid,
          courseId: courseId ? courseId : cId,
          groupId: res.data[0].groupId,
        }).then((re) => {
          // console.log(re.data);

          setMileStoneArr(re.data);
        });
      }
    });
    getUserInformetionData({
      uid: userDetails.uid,
      courseId: courseId ? courseId : cId,

      groupId: submitionId,
    }).then((res) => {
      if (res.data != null) {
        // console.log(res.data);
        setUserDocument(res.data);
        setUserdataFlag(true);
      }

      // console.log(res.data);
    });

    // getUserHistory({
    //   uid:userDetails.uid,
    //   courseId:courseId
    // }).then((r)=>{
    //   setUserHistory(r.data)

    // })
  };

  return (
    <div className="section" style={{ backgroundColor: "#e5e5e5" }}>
      <Navbar page={t("user")} />
      <LoadingScreen
        open={loadingScreen}
        onClose={() => setLoadingScreen(false)}
      />
      <div className="createcourse-main">
        <Sidebar page={"user"} hidepage={true} />
        <div className="admin-overdue">
          <div className="admin-overdue-top">
            <div className="admin-overdue-top-item">
              {t("UserDetailsof")} {userDetails.name}
            </div>
          </div>
          <div className="admin-overdue-mid">
            <div
              className="admin-overdue-mid-user-view"
              style={{ display: "flex", flexDirection: "column", gap: "0.5vw" }}
            >
              <div
                className="admin-overdue-mid-user-view-cnt"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  fontSize: "18px",
                  fontFamily: "Poppins",
                }}
              >
                <div> {t("FirstName")} :</div>
                <div className="admin-overdue-mid-user-view-txt-dim">
                  {userDetails.name}
                </div>
              </div>

              <div
                className="admin-overdue-mid-user-view-cnt"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  fontSize: "18px",
                  fontFamily: "Poppins",
                }}
              >
                <div>{t("EmailID")} :</div>
                <div className="admin-overdue-mid-user-view-txt-dim">
                  {userDetails.email}
                </div>
              </div>

              <div
                className="admin-overdue-mid-user-view-cnt"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  fontSize: "18px",
                  fontFamily: "Poppins",
                }}
              >
                <div>{t("Contect Number")} :</div>
                <div className="admin-overdue-mid-user-view-txt-dim">
                  {userDetails.phoneNumber}
                </div>
              </div>

              <div
                className="admin-overdue-mid-user-view-cnt"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  fontSize: "18px",
                  fontFamily: "Poppins",
                }}
              >
                <div> {t("Role")} :</div>
                <div className="admin-overdue-mid-user-view-txt-dim">
                  <div> {t("User")}</div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              margin: "30px",
              gridTemplateColumns: "30% 30% 30%",
              backgroundColor: "white",
              fontSize: "20px",
              padding: "20px",
              fontWeight: "400",
              fontFamily: "Poppins",
              borderRadius: "6px",
              alignItems: "center",
            }}
          >
            <div style={{ width: "200px" }}>
              <label>Select a Course</label>
              <select
                className="admin-course-main-top-select"
                value={courseId}
                onChange={(e) => {
                  setCourseId(e.target.value);
                  getUserSubmitions(e.target.value);
                }}
              >
                <option value="">select course</option>
                {courseArray?.map((e) => {
                  return (
                    <option value={e.courseId}>
                      {courseNameObj[e?.courseId]}
                    </option>
                  );
                })}
              </select>
            </div>

            <div style={{ width: "200px" }}>
              <label>Select an attempt</label>
              <select
                className="admin-course-main-top-select"
                value={submitionId}
                onChange={(e) => {
                  setSubmitionId(e.target.value);
                }}
              >
                <option value="">Select an attempt</option>

                {submitionArr?.map((e) => (
                  <option value={e}>{e}</option>
                ))}
              </select>
            </div>

            <div>
              <button onClick={getDataFromApi} className="secondary-btn">
                {" "}
                Filter
              </button>
            </div>
          </div>

          <UserAgeAndDob uid={userDetails.uid} data={userDocument} />
          <QualificationAndExp uid={userDetails.uid} data={userDocument} />
          <ALlStatuspage uid={userDetails.uid} data={userStatusData} />
          <ChnageUserStatus
            uid={userDetails.uid}
            data={detaildData}
            setUserStatusData={setUserStatusData}
            userStatusData={userStatusData}
            coordinatoreData={coordinatore}
          />

          {/* {
            userdataFlag? <DocumentsOfUser uid={userDetails.uid}  userDocument={userDocument}/>:"No Data"
          } */}
          <DocumentsOfUser
            uid={userDetails.uid}
            userDocument={userDocument}
            courseId={courseId}
          />

          <InternalTraining uid={userDetails.uid} data={detaildData} />

          <InternalOfflineTraining uid={userDetails.uid} data={detaildData} />

          {detaildData.length > 0 ? (
            <ExternalTraining
              uid={userDetails.uid}
              data={detaildData}
              flag={flagForEDit}
              stateForVideoCount={stateForVideoCount}
            />
          ) : (
            <TempFIle uid={userDetails.uid} data={detaildData} />
          )}

          <PaymentDetails
            uid={userDetails.uid}
            data={paymentsData}
            flag={flagForEDit}
          />

          <div
            className="admin-user-head"
            style={{
              background: "linear-gradient(90deg, #007848 0%, #004577 105.46%)",
              width: "30vw",
              height: "2.5vw",
              /* padding-top: 1vw; */
              /* margin-top: 3vw; */
              marginBottom: "-2.5vw",
            }}
          ></div>
          <PreviousTraining uid={userDetails.uid} mileStone={mileStoneArr} />

          <Certificates uid={userDetails.uid} history={userHistory} />
        </div>
        <div
          style={{ height: "100%", width: "6vw", backgroundColor: "white" }}
        ></div>
        <div className="courselist-main-cnt-back">
          <div className="courselist-main-cnt-back-btn">
            <Link to={"/admin/users"}>
              <button className="secondary-btn">{t("Back")}</button>{" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserView;
