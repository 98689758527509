import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { Navigate, useNavigate } from "react-router";
import { functions } from "../../../../Firebase";

const CoordinatorePopUp = (props) => {
  const { onClose, open, delfun, coorId  } = props;

  const getcoordinator = httpsCallable(functions, "getcoordinator");
  const handleClose = () => {
    setInputId("");
    setBody("");
    onClose();
  };
  const [dueDate, setDueDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const[coordinaterId,setCoordinaterId]=useState("")
  const [loading, setLoading] = useState(false);
  const [template, settemplate] = useState([
    
  ]);
  useEffect(()=>{
   
    setCoordinaterId(coorId)
    getcoordinator({}).then((res)=>{


        settemplate(res.data)
    })
   
  },[open])
  
  const [inputId, setInputId] = useState("");

  const [body, setBody] = useState("");
  let navigater = useNavigate();
  const runGenerateCertificateTraining = () => {
    /// navigater("/admin/course-reports")

    if (coordinaterId=="") {
      alert("select coordinator");
      return;
    }

    let obj = {
     
      id: coordinaterId,
    };
    delfun(obj);
    setInputId("");
    setBody("");
    onClose();
  };


  return (
    <Dialog
      //   fullWidth={false}
      maxWidth="400px"
      PaperProps={{
        style: {
          borderRadius: "20px",
          height: "400px",
          padding: "16px",
          width: "600px",
        },
      }}
      onClose={() => {
        if (loading) return;
        handleClose();
      }}
      open={open}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "left",
          color: "#11047A",
        }}
      >
        <p> Coordinator </p>
        <button
          onClick={() => {
            handleClose();
            if (loading) return;
          }}
          style={{
            width: "40px",
            height: "40px",
            background: "#f4f7fe",
            marginLeft: "auto",
            borderRadius: "5px",
            border: "none",
          }}
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
              fill="#707EAE"
            />
          </svg>
        </button>
      </DialogTitle>
      <Box
        sx={{
          padding: "16px 24px",
        }}
      >
        <div>
          <div
            className="bigger-container"
            style={{
              display: "grid",
              marginTop: "30px",
              gridTemplateColumns: "1fr 2fr 2fr",
            }}
          >
            <div> SN.</div>
            <div> Name</div>

            <div>Number</div>
          </div>

          <div className="setting_details_table">
            {template?.map((ele) => {
              return (
                <div
                  className="outer-inner-container"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 2fr 2fr",
                  }}
                >
                  <div className="templatename">
                    <input type="radio"
                    name="radio"

                    checked={ele.id==coordinaterId?true:false}
                    onChange={(e)=>{
                    
                    if(e.target.checked){
                        setCoordinaterId(ele.id)
                        console.log("rahul true")
                    }
                        //handelSelect(ele,e.target.value)
                    }}
                    />
                  </div>
                  <div
                    className="templatename"
                    style={{ width: "150px" }}
                  >
                    {ele.name}
                  </div>
                  <div
                    className="templatename"
                    style={{ width: "150px" }}
                  >
                    {ele.mobile}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <Button
          variant="contained"
          sx={{
            mt: "1em",
            color: "#fff",
            background: "#11047A",
            borderRadius: "70px",
            width: "20vw",
          }}
          disable={loading}
          onClick={runGenerateCertificateTraining}
        >
          {loading ? "Loading..." : "Add"}
        </Button>
      </Box>
    </Dialog>
  );
};

export default CoordinatorePopUp;
