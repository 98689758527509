import { httpsCallable } from "firebase/functions";
import { t } from "i18next";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import "../../../App.css";
import { getCookie } from "../../../Cookies";
import { db, functions } from "../../../Firebase";
import Navbar from "../../Component/NavbarTop";
import Sidebar from "../../Component/Sidebar";
import { Timestamp } from "firebase/firestore";
import "../StyleAdminComponents/User.css";
import "./Users.css";
import { redis } from "../../../redisFun";
import LoadingScreen from "../BasicCourse/lodingScree";
import { url } from "../../../apiBaseUrl";
function TempFileForTest({ courseId, groupEnrollmentId }) {
  const navigate = useNavigate();
  const goToAddNewUser = (state) =>
    navigate("/admin/addNewUser", { state: { ...state } });
  const [loadingScreen, setLoadingScreen] = useState(false);
  const goToEditUser = (state) =>
    navigate("/admin/UsersListForFunnelView", { state: state });
  const goToHome = () => navigate("/");
  const [valueSelect, setvalue] = useState("");
  const initState = {
    startDate: new Date().setMonth(new Date().getMonth() - 2),
    endDate: new Date().toString(),
    selected: "default",
  };
  const [query, setQuery] = useState(initState);

  const [showLoading, setLoading] = useState(true);

  const [userArray, setUserArray] = useState([]);
  const [details, setDetails] = useState([]);
  const [checkBox, setCheckBox] = useState(false);

  let getFunnelDataForcourseIdAndDateRange = httpsCallable(
    functions,
    "getFunnelDataForcourseIdAndDateRange"
  );

  let getuserSubjectivePendinginallcourse = httpsCallable(
    functions,
    "getuserSubjectivePendinginallcourse"
  );

  let getuserSubjectivePassedinallcourse = httpsCallable(
    functions,
    "getuserSubjectivePassedinallcourse"
  );

  let getuserSubjectivegiveninallcourse = httpsCallable(
    functions,
    "getuserSubjectivegiveninallcourse"
  );

  let getuserSubjectiveRejectedinallcourse = httpsCallable(
    functions,
    "getuserSubjectiveRejectedinallcourse"
  );

  let getuserSubjectiveStartinallcourse = httpsCallable(
    functions,
    "getuserSubjectiveStartinallcourse"
  );

  let getuserObjectivePassedinallcourse = httpsCallable(
    functions,
    "getuserObjectivePassedinallcourse"
  );

  let getuserObjectivePendinginallcourse = httpsCallable(
    functions,
    "getuserObjectivePendinginallcourse"
  );

  let getuserObjectiveRejectinallcourse = httpsCallable(
    functions,
    "getuserObjectiveRejectinallcourse"
  );

  let getuserObjectiveStartinallcourse = httpsCallable(
    functions,
    "getuserObjectiveStartinallcourse"
  );

  const getuserApprovedpassinginallcourse = httpsCallable(
    functions,
    "getuserApprovedpassinginallcourse"
  );

  const getuserApprovedrejectedinginallcourse = httpsCallable(
    functions,
    "getuserApprovedrejectedinginallcourse"
  );

  const getuserApprovedpendinginginallcourse = httpsCallable(
    functions,
    "getuserApprovedpendinginginallcourse"
  );

  const getuserDocumentsPassedinallcourse = httpsCallable(
    functions,
    "getuserDocumentsPassedinallcourse"
  );

  const getuserDocumentsPendinginallcourse = httpsCallable(
    functions,
    "getuserDocumentsPendinginallcourse"
  );

  const getuserDocumentsRejectedinallcourse = httpsCallable(
    functions,
    "getuserDocumentsRejectedinallcourse"
  );

  const getuserDocumentsReUploadedinallcourse = httpsCallable(
    functions,
    "getuserDocumentsReUploadedinallcourse"
  );

  const getuserDocumentsUploadedinallcourse = httpsCallable(
    functions,
    "getuserDocumentsUploadedinallcourse"
  );

  const getuserPaymentpassedinallcourse = httpsCallable(
    functions,
    "getuserPaymentpassedinallcourse"
  );

  const getuserPaymentFailedinallcourse = httpsCallable(
    functions,
    "getuserPaymentFailedinallcourse"
  );

  const getuserPaymentPendinginallcourse = httpsCallable(
    functions,
    "getuserPaymentPendinginallcourse"
  );

  const getuserPaymentRefundedinallcourse = httpsCallable(
    functions,
    "getuserPaymentRefundedinallcourse"
  );

  const getuserAgreementPassedinallcourse = httpsCallable(
    functions,
    "getuserAgreementPassedinallcourse"
  );

  const getuserAgreementPendinginallcourse = httpsCallable(
    functions,
    "getuserAgreementPendinginallcourse"
  );

  const getuserAgreementRejectedinallcourse = httpsCallable(
    functions,
    "getuserAgreementRejectedinallcourse"
  );

  const getuserAgreementReuploadinallcourse = httpsCallable(
    functions,
    "getuserAgreementReuploadinallcourse"
  );

  const getuserAgreementUploadedinallcourse = httpsCallable(
    functions,
    "getuserAgreementUploadedinallcourse"
  );

  const getuserappliedinallcourse = httpsCallable(
    functions,
    "getuserappliedinallcourse"
  );
  const { clrs } = useSelector((state) => state.createClr);

  const [courseArr, setCourseArr] = useState([]);
  const [courseName, setCourseName] = useState("");

  const [subjectiveStart, setSubjectiveStart] = useState("");
  const [subjectivePending, setSubjectivePending] = useState("");

  let initStatus = [
    {
      for: "apllied",
      name: "Applied in this course",
      link: "getuserappliedinallcourse",
      value: "",
    },

    {
      for: "objectiveStart",
      name: "Objective test started",
      link: "getuserObjectiveStartinallcourse",
      value: "",
    },
    {
      for: "objectivePass",
      name: "Objective test passed",
      link: "getuserObjectivePassedinallcourse",
      value: "",
    },
    {
      for: "objectiveFailed",
      name: "Objective test failed",
      link: "getuserObjectiveRejectinallcourse",
      value: "",
    },

    {
      for: "subjectiveStart",
      name: "Subjective test started",
      link: "getuserSubjectiveStartinallcourse",
      value: "",
    },
    {
      for: "subjectivePending",
      name: "Subjective test under evaluation",
      link: "getuserSubjectivePendinginallcourse",
      value: "",
    },
    {
      for: "subjectivePass",
      name: "Subjective test passed",
      link: "getuserSubjectivePassedinallcourse",
      value: "",
    },
    {
      for: "subjectiveFaile",
      name: "Subjective test failed",
      link: "getuserSubjectiveRejectedinallcourse",
      value: "",
    },
    {
      for: "subjectiveGiven",
      name: "Subjective test taken",
      link: "getuserSubjectivegiveninallcourse",
      value: "",
    },

    {
      for: "documentPending",
      name: "Registration submission pending",
      link: "getuserDocumentsPendinginallcourse",
      value: "",
    },
    {
      for: "documentUploaded",
      name: "Registration form submitted",
      link: "getuserDocumentsUploadedinallcourse",
      value: "",
    },
    {
      for: "documentReuploaded",
      name: "Resubmit registration form",
      link: "getuserDocumentsReUploadedinallcourse",
      value: "",
    },
    {
      for: "documentPass",
      name: "Registration details accepted",
      link: "getuserDocumentsPassedinallcourse",
      value: "",
    },
    {
      for: "documentFailed",
      name: "Registration details rejected",
      link: "getuserDocumentsRejectedinallcourse",
      value: "",
    },

    {
      for: "paymentPending",
      name: "Payment pending",
      link: "getuserPaymentPendinginallcourse",
      value: "",
    },
    {
      for: "paymentFailed",
      name: "Payment failed",
      link: "getuserPaymentFailedinallcourse",
      value: "",
    },
    {
      for: "PaymentPass",
      name: "Payment Received",
      link: "getuserPaymentpassedinallcourse",
      value: "",
    },
    {
      for: "paymentRefuned",
      name: "Payment refunded",
      link: "getuserPaymentRefundedinallcourse",
      value: "",
    },

    {
      for: "agreementPending",
      name: "Documents submission pending",
      link: "getuserAgreementPendinginallcourse",
      value: "",
    },
    {
      for: "agreementUploaded",
      name: "Documents under review",
      link: "getuserAgreementUploadedinallcourse",
      value: "",
    },
    {
      for: "agreementPass",
      name: "Documents approved",
      link: "getuserAgreementPassedinallcourse",
      value: "",
    },
    {
      for: "agreementFailed",
      name: "Documents rejected",
      link: "getuserAgreementRejectedinallcourse",
      value: "",
    },
    {
      for: "agreementReUpload",
      name: "Resubmit documents",
      link: "getuserAgreementReuploadinallcourse",
      value: "",
    },

    {
      for: "approveIncourse",
      name: "Congratulations! You are selected",
      link: "getuserApprovedpassinginallcourse",
      value: "",
    },
    {
      for: "rejectedFromCourse",
      name: "Sorry! You are not selected",
      link: "getuserApprovedrejectedinginallcourse",
      value: "",
    },
    {
      for: "approvePending",
      name: "Final approval pending",
      link: "getuserApprovedpendinginginallcourse",
      value: "",
    },
  ];

  const [statusObj, setStatusObj] = useState(initStatus);
  const handleQueryChange = (e) => {
    setQuery({
      ...query,
      [e.target.name]: e.target.value,
    });
  };
  const setDefault = () => {
    setQuery(initState);
    setDetails(userArray);

    return;
  };

  useEffect(() => {
    setLoadingScreen(true);

    getuserappliedinallcourse({
      courseId: courseId ? courseId : "",
      type: "size",
    }).then((res) => {
      setLoadingScreen(false);

      setStatusObj((prev) => {
        return prev.filter((curvalue, idx) => {
          if (curvalue.for == "apllied") {
            curvalue.value = res.data;
          }
          return curvalue;
        });
      });
    });

    getuserAgreementUploadedinallcourse({
      courseId: courseId ? courseId : "",
      type: "size",
    }).then((res) => {
      setStatusObj((prev) => {
        return prev.filter((curvalue, idx) => {
          if (curvalue.for == "agreementUploaded") {
            curvalue.value = res.data;
          }
          return curvalue;
        });
      });
    });

    setLoadingScreen(true);

    getuserAgreementReuploadinallcourse({
      courseId: courseId ? courseId : "",
      type: "size",
    }).then((res) => {
      setLoadingScreen(false);

      setStatusObj((prev) => {
        return prev.filter((curvalue, idx) => {
          if (curvalue.for == "agreementReUpload") {
            curvalue.value = res.data;
          }
          return curvalue;
        });
      });
    });

    getuserAgreementReuploadinallcourse({
      courseId: courseId ? courseId : "",
      type: "size",
    }).then((res) => {
      setStatusObj((prev) => {
        return prev.filter((curvalue, idx) => {
          if (curvalue.for == "agreementReUpload") {
            curvalue.value = res.data;
          }
          return curvalue;
        });
      });
    });

    getuserAgreementRejectedinallcourse({
      courseId: courseId ? courseId : "",
      type: "size",
    }).then((res) => {
      setStatusObj((prev) => {
        return prev.filter((curvalue, idx) => {
          if (curvalue.for == "agreementFailed") {
            curvalue.value = res.data;
          }
          return curvalue;
        });
      });
    });

    setLoadingScreen(true);

    getuserAgreementPendinginallcourse({
      courseId: courseId ? courseId : "",
      type: "size",
    }).then((res) => {
      setLoadingScreen(false);

      setStatusObj((prev) => {
        return prev.filter((curvalue, idx) => {
          if (curvalue.for == "agreementPending") {
            curvalue.value = res.data;
          }
          return curvalue;
        });
      });
    });

    getuserAgreementPassedinallcourse({
      courseId: courseId ? courseId : "",
      type: "size",
    }).then((res) => {
      setStatusObj((prev) => {
        return prev.filter((curvalue, idx) => {
          if (curvalue.for == "agreementPass") {
            curvalue.value = res.data;
          }
          return curvalue;
        });
      });
    });

    getuserPaymentRefundedinallcourse({
      courseId: courseId ? courseId : "",
      type: "size",
    }).then((res) => {
      setStatusObj((prev) => {
        return prev.filter((curvalue, idx) => {
          if (curvalue.for == "paymentRefuned") {
            curvalue.value = res.data;
          }
          return curvalue;
        });
      });
    });

    getuserPaymentPendinginallcourse({
      courseId: courseId ? courseId : "",
      type: "size",
    }).then((res) => {
      setStatusObj((prev) => {
        return prev.filter((curvalue, idx) => {
          if (curvalue.for == "paymentPending") {
            curvalue.value = res.data;
          }
          return curvalue;
        });
      });
    });

    getuserPaymentFailedinallcourse({
      courseId: courseId ? courseId : "",
      type: "size",
    }).then((res) => {
      setStatusObj((prev) => {
        return prev.filter((curvalue, idx) => {
          if (curvalue.for == "paymentFailed") {
            curvalue.value = res.data;
          }
          return curvalue;
        });
      });
    });

    getuserPaymentpassedinallcourse({
      courseId: courseId ? courseId : "",
      type: "size",
    }).then((res) => {
      setStatusObj((prev) => {
        return prev.filter((curvalue, idx) => {
          if (curvalue.for == "PaymentPass") {
            curvalue.value = res.data;
          }
          return curvalue;
        });
      });
    });

    getuserDocumentsUploadedinallcourse({
      courseId: courseId ? courseId : "",
      type: "size",
    }).then((res) => {
      setStatusObj((prev) => {
        return prev.filter((curvalue, idx) => {
          if (curvalue.for == "documentUploaded") {
            curvalue.value = res.data;
          }
          return curvalue;
        });
      });
    });

    getuserDocumentsReUploadedinallcourse({
      courseId: courseId ? courseId : "",
      type: "size",
    }).then((res) => {
      setStatusObj((prev) => {
        return prev.filter((curvalue, idx) => {
          if (curvalue.for == "documentReuploaded") {
            curvalue.value = res.data;
          }
          return curvalue;
        });
      });
    });

    getuserDocumentsRejectedinallcourse({
      courseId: courseId ? courseId : "",
      type: "size",
    }).then((res) => {
      setStatusObj((prev) => {
        return prev.filter((curvalue, idx) => {
          if (curvalue.for == "documentFailed") {
            curvalue.value = res.data;
          }
          return curvalue;
        });
      });
    });

    getuserDocumentsPendinginallcourse({
      courseId: courseId ? courseId : "",
      type: "size",
    }).then((res) => {
      setStatusObj((prev) => {
        return prev.filter((curvalue, idx) => {
          if (curvalue.for == "documentPending") {
            curvalue.value = res.data;
          }
          return curvalue;
        });
      });
    });

    getuserDocumentsPassedinallcourse({
      courseId: courseId ? courseId : "",
      type: "size",
    }).then((res) => {
      setStatusObj((prev) => {
        return prev.filter((curvalue, idx) => {
          if (curvalue.for == "documentPass") {
            curvalue.value = res.data;
          }
          return curvalue;
        });
      });
    });

    getuserApprovedpendinginginallcourse({
      courseId: courseId ? courseId : "",
      type: "size",
    }).then((res) => {
      setStatusObj((prev) => {
        return prev.filter((curvalue, idx) => {
          if (curvalue.for == "approvePending") {
            curvalue.value = res.data;
          }
          return curvalue;
        });
      });
    });

    getuserApprovedrejectedinginallcourse({
      courseId: courseId ? courseId : "",
      type: "size",
    }).then((res) => {
      setStatusObj((prev) => {
        return prev.filter((curvalue, idx) => {
          if (curvalue.for == "rejectedFromCourse") {
            curvalue.value = res.data;
          }
          return curvalue;
        });
      });
    });

    getuserApprovedpassinginallcourse({
      courseId: courseId ? courseId : "",
      type: "size",
    }).then((res) => {
      setStatusObj((prev) => {
        return prev.filter((curvalue, idx) => {
          if (curvalue.for == "approveIncourse") {
            curvalue.value = res.data;
          }
          return curvalue;
        });
      });
    });

    getuserObjectiveStartinallcourse({
      courseId: courseId ? courseId : "",
      type: "size",
    }).then((res) => {
      setStatusObj((prev) => {
        return prev.filter((curvalue, idx) => {
          if (curvalue.for == "objectiveStart") {
            curvalue.value = res.data;
          }
          return curvalue;
        });
      });
    });

    getuserObjectiveRejectinallcourse({
      courseId: courseId ? courseId : "",
      type: "size",
    }).then((res) => {
      setStatusObj((prev) => {
        return prev.filter((curvalue, idx) => {
          if (curvalue.for == "objectiveFailed") {
            curvalue.value = res.data;
          }
          return curvalue;
        });
      });
    });

    getuserObjectivePassedinallcourse({
      courseId: courseId ? courseId : "",
      type: "size",
    }).then((res) => {
      setStatusObj((prev) => {
        return prev.filter((curvalue, idx) => {
          if (curvalue.for == "objectivePass") {
            curvalue.value = res.data;
          }
          return curvalue;
        });
      });
    });

    getuserSubjectiveStartinallcourse({
      courseId: courseId ? courseId : "",
      type: "size",
    }).then((res) => {
      setStatusObj((prev) => {
        return prev.filter((curvalue, idx) => {
          if (curvalue.for == "subjectiveStart") {
            curvalue.value = res.data;
          }
          return curvalue;
        });
      });
    });

    getuserSubjectivePendinginallcourse({
      courseId: courseId ? courseId : "",
      type: "size",
    }).then((res) => {
      setStatusObj((prev) => {
        return prev.filter((curvalue, idx) => {
          if (curvalue.for == "subjectivePending") {
            curvalue.value = res.data;
          }
          return curvalue;
        });
      });
    });

    getuserSubjectivePassedinallcourse({
      courseId: courseId ? courseId : "",
      type: "size",
    }).then((res) => {
      setStatusObj((prev) => {
        return prev.filter((curvalue, idx) => {
          if (curvalue.for == "subjectivePass") {
            curvalue.value = res.data;
          }
          return curvalue;
        });
      });
    });

    getuserSubjectivegiveninallcourse({
      courseId: courseId ? courseId : "",
      type: "size",
    }).then((res) => {
      setStatusObj((prev) => {
        return prev.filter((curvalue, idx) => {
          if (curvalue.for == "subjectiveGiven") {
            curvalue.value = res.data;
          }
          return curvalue;
        });
      });
    });

    getuserSubjectiveRejectedinallcourse({
      courseId: courseId ? courseId : "",
      type: "size",
    }).then((res) => {
      setStatusObj((prev) => {
        return prev.filter((curvalue, idx) => {
          if (curvalue.for == "subjectiveFaile") {
            curvalue.value = res.data;
          }
          return curvalue;
        });
      });
    });
  }, [courseId]);

  const filterDataFromApi = () => {
    if (courseId) {
      setLoadingScreen(true);
      getFunnelDataForcourseIdAndDateRange({
        courseId: courseId,
        startDate: query.startDate,
        endDate: query.endDate,
      })
        .then((res) => {
          setLoadingScreen(false);
          setDetails(res.data);
          setUserArray(res.data);
          console.log(res.data);
        })
        .catch((e) => {
          setLoadingScreen(false);
        });
    }
  };

  // getuserPaymentPendinginallcourse({
  //   courseId: courseId ? courseId : "",
  //   type: "size",
  // }).then((res) => {
  //   setStatusObj((prev) => {
  //     return prev.filter((curvalue, idx) => {
  //       if (curvalue.for == "paymentPending") {
  //         curvalue.value = res.data;
  //       }
  //       return curvalue;
  //     });
  //   });
  // });

  const handerInput = (id, item, check) => {
    console.log(check);
    if (check) {
      setCheckBox({ item: item, id: id });
    } else {
      setCheckBox(false);
    }
  };

  console.log(checkBox);
  const handleChange = (event, item, id) => {
    var data = event.target.value;
    setvalue(event.target.value);

    if (data == "edit") {
      goToAddNewUser(item);
    } else if (data == "view") {
      console.log(url);

      // /AgreementReuploadHttps
      console.log(item.link);
      let link = `${url}/${item.link}`;
      data = { courseId: courseId ? courseId : "" };
      axios
        .post(link, {
          data: data,
        })
        .then(function (response) {
          //console.log(response.data.result.map((item)=>({ ...item, selected: false })));

          goToEditUser(
            response.data.result.map((item) => ({ ...item, selected: false }))
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const sendNotification = () => {
    if (courseId !== "" && groupEnrollmentId !== undefined) {
      alert(
        `notification send to ${checkBox.item.name} and group id ${groupEnrollmentId}`
      );
    } else {
      alert(
        `notification send to ${checkBox.item.name} and course id ${courseId}`
      );
    }
  };

  // console.log(userArray);

  return (
    <div className="admin-overdue">
      <LoadingScreen
        open={loadingScreen}
        onClose={() => setLoadingScreen(false)}
      />

      <div style={{ height: "8vw" }} className="courselist-main-cnt-top">
        {/*     <div className="courselist-main-cnt-top-txt">Group List</div> */}
        <div
          style={{
            marginTop: "6vw",
            marginLeft: "0vw",
          }}
          className="head"
        >
          {t("Course Funnel View")}
        </div>
      </div>
      <div
        style={{ margin: "0vw 2vw 2vw 2vw" }}
        className="admin-overdue-bottom"
      >
        <div className="admin-overdue-bottom-table">
          <div
            className="admin-overdue-bottom-table-cnt-usr"
            style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr" }}
          >
            <div className="admin-overdue-bottom-table-head">{t("Sr.No.")}</div>
            <div className="admin-overdue-bottom-table-head">
              {t("Category")}
            </div>
            <div className="admin-overdue-bottom-table-head">{t("Total")}</div>

            <div className="admin-overdue-bottom-table-head">{t("Action")}</div>
          </div>
          {/* {showLoading && (
                <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td">
                    <div className="spinner-container">
                      <div className="loading-spinner"></div>
                    </div>
                  </div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td" style={{ color: "#004577" }}></div>
                </div>
              )} */}

          {statusObj ? (
            statusObj.map((item, id) => {
              return (
                <div
                  className="admin-overdue-bottom-table-cnt-usr"
                  key={id}
                  style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr" }}
                >
                  <div className="admin-overdue-bottom-table-td">
                    <input
                      type="checkBox"
                      style={{ marginRight: "4px" }}
                      checked={checkBox?.id == id ? true : false}
                      onChange={(e) => {
                        handerInput(id, item, e.target.checked);
                      }}
                    />

                    {id + 1}
                  </div>
                  <div className="admin-overdue-bottom-table-td">
                    {item?.name}
                  </div>
                  <div className="admin-overdue-bottom-table-td">
                    {item?.value}
                  </div>

                  <div
                    className="admin-overdue-bottom-table-td"
                    style={{ color: "#004577" }}
                  >
                    <select
                      style={{
                        border: "none",
                        backgroundColor: "#F2F2F2",
                        height: "4vh",
                        width: "7vw",
                        padding: "0 1vh",
                        fontWeight: "700",
                        color: "#717579",
                        borderRadius: "6px",
                      }}
                      onChange={(event) => handleChange(event, item, id)}
                    >
                      <option /* value={details.groupName} */>
                        {t("Action")}
                      </option>

                      <option value={"view"} /* value={details.groupName} */>
                        {t("View")}
                      </option>
                    </select>
                  </div>
                </div>
              );
            })
          ) : (
            <p>No user </p>
          )}
        </div>

        <div>
          {/*    <Link to={"/admin/addNewUser"}>
              <button>add new user</button>
            </Link> */}
          {/*  <Link to={"/admin/userview"}>
              <button>userview</button>
            </Link> */}
        </div>
      </div>
    </div>
  );
}

export default TempFileForTest;
