import React, { useEffect } from "react";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
//import axios from 'axios';
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { functions } from "../../../Firebase";
import "./AdminSetting.css";
import Sidebar from "../../Component/Sidebar";
import NavbarTop from "../../Component/NavbarTop";
import { t } from "i18next";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import editIcon from "../../../utilities/images/editIcon.svg";
import deleteIcon from "../../../utilities/images/deleteIcon.svg";
import viewIcon from "../../../utilities/images/viewIcon.svg";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function AvailableCertificates() {
  const navigate = useNavigate();
  const { clrs } = useSelector((state) => state.createClr);
  const [template, settemplate] = useState(null);
  const [deltemp, setdeltemp] = useState(false);
  const [showLoading, setLoading] = useState(true);

  const fetchCertificateTemplate = httpsCallable(
    functions,
    "fetchCertificateTemplate"
  );
  const deleteTemplate = httpsCallable(functions, "deleteTemplate");

  const deletetemplate = (id) => {
    //console.log(id);
    deleteTemplate({ tempuid: id }).then((res) => {
      // console.log(res);
      alert(res.data.msg);
      setdeltemp(!deltemp);
    });
  };

  const fetchdata = () => {
    setLoading(true);
    fetchCertificateTemplate({})
      .then((res) => {
        setLoading(false);
        // console.log("cerificate fetch:", res);
        settemplate(res.data);
      })
      .catch((err) => {
        setLoading(false);
        //   console.log(err);
      });
  };

  useEffect(() => {
    fetchdata();
  }, [deltemp]);

  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Certificate Settings")} />
        {/* <Navbar /> */}

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              <div className="create-new-box">
                <Link to={{ pathname: "/admin/settings/certificatecreate" }}>
                  <button className="primary-btn">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <AddIcon />
                      Create New
                    </div>
                  </button>
                </Link>
              </div>

              <div className="setting-table-main">
                <div
                  className="bigger-container"
                  style={{ textAlign: "center" }}
                >
                  <div style={{ width: "40%" }}> Available Certificate</div>

                  <div style={{ width: "40%" }}>Action</div>
                  <div style={{ width: "20%" }}>Make Default</div>
                </div>
                <div className="">
                  {showLoading && (
                    <div
                      className="admin-overdue-bottom-table-cnt-certi"
                      key={0}
                    >
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td">
                        <div className="spinner-container">
                          <div className="loading-spinner"></div>
                        </div>
                      </div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div
                        className="admin-overdue-bottom-table-td"
                        style={{ color: "#004577" }}
                      ></div>
                    </div>
                  )}

                  {template?.map((ele) => {
                    return (
                      <div
                        className="outer-inner-container"
                        style={{ textAlign: "center" }}
                      >
                        <div className="templatename" style={{ width: "40%" }}>
                          {ele.templateName}
                        </div>

                        <div
                          className="button-box"
                          style={{
                            width: "40%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: "20px",
                          }}
                        >
                          {ele.templateName != "Default Template" && (
                            <div className="button-box-inner mx-3 my-1">
                              <button
                                className="action-btn"
                                onClick={() => {
                                  deletetemplate(ele.id);
                                }}
                              >
                                <img src={deleteIcon} alt="" />
                                Delete
                              </button>
                            </div>
                          )}
                          <div className="button-box-inner mx-3 my-1">
                            <Link
                              to={{
                                pathname: "/admin/settings/certificate",
                                state: { fromDashboard: "it is first" },
                              }}
                              state={ele}
                            >
                              <button className="action-btn">
                                <img src={editIcon} /> Edit
                              </button>
                            </Link>
                          </div>
                          <div className="button-box-inner mx-3 my-1">
                            {/* <Link to={{
                pathname: "/certificatedetails",
             state: { fromDashboard: "it is first" }}}> */}
                            <a
                              href={ele.templateUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <button className="action-btn">
                                <img src={viewIcon} alt="" />
                                View
                              </button>
                            </a>
                            {/* </Link>   */}
                          </div>
                        </div>
                        <div className="button-box" style={{ width: "20%" }}>
                          <input
                            type="checkbox"
                            name={"default"}
                            checked={ele.templateName === "Default Template"}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button className="secondary-btn" onClick={() => navigate(-1)}>
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AvailableCertificates;
