import React, { useEffect, useState, useRef } from "react";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  uploadBytes,
  deleteObject,
} from "@firebase/storage";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  increment,
} from "firebase/firestore";
import { db, functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { Timestamp } from "firebase/firestore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import "./createQuiz.css";
import ReactPlayer from "react-player";
import { ExpandMoreSharp } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdEdit, MdDelete } from "react-icons/md";
import { ExpandMoreSvg } from "./icons";
import DeleteLesson from "./lessonDeletePopUp";
import Upload from "./uploadPop";
const CreateSubQuestion = ({
  editQuizArr,
  questionData,
  quizId,
  questionSetId,
  type,
  mileStoneQuizId,
  mileStoneId,
}) => {
  const storage = getStorage();

  const instructionForSubTest = httpsCallable(
    functions,
    "instructionForSubTest"
  );
  const deleteMileStoneSubQuestion = httpsCallable(
    functions,
    "deleteMileStoneSubQuestion"
  );
  const addQuestionSUbjectiveOrderMilStone = httpsCallable(
    functions,
    "addQuestionSUbjectiveOrderMilStone"
  );
  const getAllQuestionDataForQuiz = httpsCallable(
    functions,
    "getAllQuestionDataForQuiz"
  );
  const createQuestion = httpsCallable(functions, "createQuestion");
  const addQuestionSubjectiveOrder = httpsCallable(
    functions,
    "addQuestionSubjectiveOrder"
  );
  const getAllQuestionDataForMileStoneObjQuiz = httpsCallable(
    functions,
    "getAllQuestionDataForMileStoneObjQuiz"
  );
  const setTimeForSubjective = httpsCallable(functions, "setTimeForSubjective");
  const deleteSubQuestion = httpsCallable(functions, "deleteSubQuestion");
  //addQuestionSubjectiveOrder
  // added by Himanshu
  const [deleteQuestionId, setDeleteQuestionId] = useState("");
  const [openUploadPopUp, setopenUploadPopUp] = useState(false);
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);

  const [progressUpload, setProgressUpload] = useState(false);

  const [uploadesVideoUrl, setUploadesVideoUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");

  const [questionMarks, setQuestionMarks] = useState("");
  const [videoFile, setVideoFile] = useState("");

  const [maxFileSize, setmaxFileSize] = useState(0);

  const [maxnoUploadOfFile, setmaxnoUploadOfFile] = useState(0);
  const [base64Agreement, setBase64Agreement] = useState("");

  const [agreementUrl, setAgreementUrl] = useState("");
  const [questionName, setQuestionName] = useState("");
  const [questionId, setQuestionId] = useState("");

  const [FlagForEditQuestion, setFlagForEditQuestion] = useState(false);
  const initialList = [];
  const [list, setList] = useState(initialList);

  const [picMCQ, setPicMCQ] = useState({
    url: "",
    base64: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    answer: 0,
  });

  const videoRef = useRef(null);
  const onVideoClick = () => {
    videoRef.current.click();
  };

  const agreementRef = useRef(null);
  const onAgreementClick = () => {
    agreementRef.current.click();
  };

  const handlePdf = (e, state, urlset) => {
    let reader = new FileReader();
    // console.log(e);

    let filenameType = e.target.value.split(".");

    // console.log(filenameType[filenameType.length - 1]);

    let filetypedata = filenameType[filenameType.length - 1].toLowerCase();

    let url = URL.createObjectURL(e.target.files[0]);

    let rfilesize = Number(e.target.files[0].size) / 1024;

    rfilesize = rfilesize / 1024;
    //console.log(rfilesize);
    if (Number(rfilesize) > 5) {
      alert("size must be less than 5 mb");

      state("");

      urlset("");

      e.target.value = null;
      return;
    }
    urlset(url);

    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      state(reader.result);
    };
  };

  const [fillUpsAns, setFillUpsAns] = useState("");
  // rearrange
  const [canEditRearrange, setCanEditRearrange] = useState(true);
  const [rearrangeOptions, setRearrangeOptions] = useState([
    {
      text: "First",
      position: 1,
    },
    {
      text: "Second",
      position: 2,
    },
    {
      text: "Third",
      position: 3,
    },
    {
      text: "Fourth",
      position: 4,
    },
  ]);
  /* arr element example
    {
      text:"rearrange",
      position: 1
    }
  */

  const scrambleArr = (arr) => {
    // console.log(arr[0]);
    let res = Array.from(arr);
    return res.sort((a, b) => 0.5 - Math.random());
  };

  const [canEditMatchPair, setCanEditMatchPair] = useState(true);
  const [leftOptions, setLeftOptions] = useState(new Array(4).fill(""));
  const [rightOptions, setRightOptions] = useState([
    {
      text: "",
      match: 1,
    },
    {
      text: "",
      match: 2,
    },
    {
      text: "",
      match: 3,
    },
    {
      text: "",
      match: 4,
    },
  ]);

  // match words to image

  const [imageOptions, setImageOptions] = useState(
    new Array(4).fill("options")
  );
  const [imageFiles, setImageFiles] = useState([
    {
      base64: "",
      match: 1,
      url: "",
    },
    {
      base64: "",
      match: 2,
      url: "",
    },
    {
      base64: "",
      match: 3,
      url: "",
    },
    {
      base64: "",
      match: 4,
      url: "",
    },
  ]);
  const [disableImage, setDisableImage] = useState(false);
  const [uploading, setUploading] = useState(false);
  const handleOptionsChange = (e, index) => {
    let { value } = e.target;
    setImageOptions((prev) => {
      let newArr = Array.from(prev);
      newArr[index] = value;
      return newArr;
    });
  };

  const scrambleImage = () => {
    setDisableImage(true);
    setImageFiles((prev) => scrambleArr(prev));
  };

  const [imageMcq, setImageMcq] = useState([
    { base64: "", blobUrl: "" },
    { base64: "", blobUrl: "" },
    { base64: "", blobUrl: "" },
    { base64: "", blobUrl: "" },
  ]);
  const [imageMcqAnswer, setImageMcqAnswer] = useState(0);

  //

  useEffect(() => {
    //console.log(type, mileStoneQuizId);
    if (!editQuizArr) return;

    if (type == "mileStone") {
      getAllQuestionDataForMileStoneObjQuiz({
        instructionId: mileStoneQuizId,
      }).then((res) => {
        // console.log(res.data);
        for (let i = 0; i < res.data.length; i++) {
          setList((prev) =>
            prev.concat({
              data: res.data[i]?.data,
              ansType: res.data[i]?.ansType,
              id: res.data[i]?.questionId,
              name: res.data[i]?.questionName,
              type: res.data[i]?.questionType,
              maxFileSize: res.data[i]?.maxFileSize,
              maxVideoLimit: res.data[i]?.maxVideoLimit,
              maxWordLimit: res.data[i]?.maxWordLimit,
              questionTime: res.data[i]?.questionTime,
              questionType: res.data[i]?.questionType,
              questionTypeEnum: res.data[i]?.questionTypeEnum,
              questionMarks: res.data[i]?.questionMarks,
              minWordLimit: res.data[i]?.minWordLimit,
            })
          );
        }
      });
    } else {
      getAllQuestionDataForQuiz({ instructionId: quizId }).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          // console.log(res.data[i]);
          setList((prev) =>
            prev.concat({
              data: res.data[i]?.data,
              ansType: res.data[i]?.ansType,
              id: res.data[i]?.questionId,
              name: res.data[i]?.questionName,
              type: res.data[i]?.questionType,
              maxFileSize: res.data[i]?.maxFileSize,
              maxVideoLimit: res.data[i]?.maxVideoLimit,
              maxWordLimit: res.data[i]?.maxWordLimit,
              questionTime: res.data[i]?.questionTime,
              questionType: res.data[i]?.questionType,
              questionTypeEnum: res.data[i]?.questionTypeEnum,
              maxnoUploadOfFile: res.data[i]?.maxnoUploadOfFile,
              minWordLimit: res.data[i]?.minWordLimit,
            })
          );
        }
      });
    }
  }, []);

  const [questionCategory, setquestionCategory] = useState("");
  const [maxWordLimit, setmaxWordLimit] = useState("");

  const [minWordLimit, setMinWordLimit] = useState("");
  const [ansType, setansType] = useState("");
  const [qusTime, setqusTime] = useState("");

  const [qusMode, setqusMode] = useState("");
  const [qustype, setqustype] = useState("Text Question");
  const [qus, setqus] = useState("");
  const [qusInstruction, setqusInstruction] = useState("test");
  const [PointorScore, setPointorScore] = useState("");
  const [qus5, setqus5] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4: "",
  });
  const [qus5Answer, setqus5Answer] = useState("Option 1");
  const [qus2, setqus2] = useState({ word: "", correctImage: "" });
  const [formCheck, setFormCheck] = useState("");
  function submit(e) {
    e.preventDefault();
  }

  function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }

    if (type == "mileStone") {
      addQuestionSUbjectiveOrderMilStone({
        quizId: mileStoneQuizId,
        questionsArr: orderList,
        edit: true,
      });
    } else if (type == "questionSet") {
      addQuestionSubjectiveOrder({
        instructionForSubId: quizId,
        questionsArr: orderList,
        edit: true,
      });
    }

    // db.collection("quiz")
    //   .doc(quizId)
    //   .collection("extraInfo")
    //   .doc("infoDoc")
    //   .update({
    //     questions: orderList,
    //   });
    // db.collection("questionSet")
    //   .doc(quizId)
    //   .collection("extraInfo")
    //   .doc("infoDoc")
    //   .update({
    //     questions: orderList,
    //   });
  }

  const [checkErrorInForm, setCheckErrorInForm] = useState({
    questionName: "",
    questionType: "",

    data: [],

    qus: "",
    maxFileSize: "",

    maxWordLimit: "",
    minWordLimit: "",
    qusTime: "",
    base64Agreement: "",
    agreementUrl: "",
    ansType: "",
    uploadesVideoUrl: "",
    questionMarks: "",
  });

  const formCheckFunction = () => {
    if (questionName == "") {
      setCheckErrorInForm((prev) => ({ ...prev, questionName: "error" }));
      return "Question Name";
    }

    if (ansType == "") {
      setCheckErrorInForm((prev) => ({ ...prev, ansType: "error" }));
      return "ansType";
    }
    if (qusTime == "") {
      setCheckErrorInForm((prev) => ({ ...prev, qusTime: "error" }));
      return "qusTime";
    }
    if (questionMarks == "") {
      setCheckErrorInForm((prev) => ({ ...prev, questionMarks: "error" }));
      return "qusTime";
    }
    if (qusInstruction == "") {
      setCheckErrorInForm((prev) => ({ ...prev, qusInstruction: "error" }));
      return "Instruction";
    }

    if (maxFileSize == "" && ansType == "2") {
      setCheckErrorInForm((prev) => ({ ...prev, maxFileSize: "error" }));
      return "maxFileSize";
    }

    if (maxWordLimit == "" && ansType == "3") {
      setCheckErrorInForm((prev) => ({ ...prev, maxWordLimit: "error" }));
      return "maxWordLimit";
    }

    if (maxnoUploadOfFile == "" && ansType == "1") {
      setCheckErrorInForm((prev) => ({ ...prev, maxnoUploadOfFile: "error" }));
      return "maxnoUploadOfFile";
    }

    if (maxnoUploadOfFile == "" && ansType == "2") {
      setCheckErrorInForm((prev) => ({ ...prev, maxnoUploadOfFile: "error" }));
      return "maxnoUploadOfFile";
    }

    if (minWordLimit == "" && ansType == "3") {
      setCheckErrorInForm((prev) => ({ ...prev, minWordLimit: "error" }));
      return "minWordLimit";
    }
    if (qustype == "File Question") {
      if (agreementUrl == "") {
        alert("Upload File");
        setCheckErrorInForm((prev) => ({ ...prev, agreementUrl: "error" }));
        return "base64Agreement";
      }
      return "alliswell";
    } else if (qustype == "Video question") {
      if (uploadesVideoUrl == "") {
        alert("Upload File");
        setCheckErrorInForm((prev) => ({ ...prev, uploadesVideoUrl: "error" }));
        return "uploadesVideoUrl";
      }
      return "alliswell";
    } else if (qustype == "Text Question") {
      if (qus == "") {
        setCheckErrorInForm((prev) => ({ ...prev, qus: "error" }));
        return "Question";
      }

      return "alliswell";
    }
  };

  const storedDataOnFirebase = async () => {
    let check = formCheckFunction();
    if (check != "alliswell") {
      setFormCheck("check");
      return;
    }
    let nsn = questionName.replaceAll(" ", "");

    let dat = /[^A-Za-z0-9]/.test(nsn);

    if (!dat) {
    } else {
      alert("you can not use any special characters");
      return;
    }

    setFormCheck("");
    let questionTypeEnum;
    let managequestionData;

    if (qustype == "File Question") {
      let questionDatafromUser = {
        question: base64Agreement,
        questionInstruction: qusInstruction,
        questionMarks: questionMarks,
      };
      managequestionData = questionDatafromUser;
      questionTypeEnum = 2;
    } else if (qustype == "Video question") {
      let questionDatafromUser = {
        question: uploadesVideoUrl,
        questionInstruction: qusInstruction,
        questionMarks: questionMarks,
      };
      managequestionData = questionDatafromUser;
      questionTypeEnum = 1;

      //   <option value="Text Question">Text Question</option>
      //           <option value="Video question">video Question</option>

      //           <option value="File Question">File Question</option>
    } else if (qustype == "Text Question") {
      let questionDatafromUser = {
        question: qus,
        questionInstruction: qusInstruction,
        questionMarks: questionMarks,
      };
      managequestionData = questionDatafromUser;
      questionTypeEnum = 3;
    }
    const docData = {
      questionName: questionName,
      questionId: questionId,
      associatedQuiz: quizId,
      questionSet: quizId,
      data: managequestionData,
      questionType: qustype,
      created: Timestamp.now(),
    };
    //console.log(docData);

    createQuestion({
      questionId: questionId,
      questionName: questionName,
      questionType: qustype,
      created: new Date().toString(),
      data: managequestionData,
      associateInstructionId: quizId,

      maxFileSize: maxFileSize,
      maxnoUploadOfFile: maxnoUploadOfFile,
      maxWordLimit: maxWordLimit,
      minWordLimit: minWordLimit,
      questionTime: qusTime,
      questionTypeEnum: questionTypeEnum,
      questionSet: questionSetId,
      ansType: ansType,
      mileStoneId: mileStoneId,
      mileStoneQuizId: mileStoneQuizId,
    });

    alert("data saved successfully");

    let total = 0;
    for (let i = 0; i < list.length; i++) {
      total = total + Number(list[i].questionTime);
    }

    let totelSum = total + Number(qusTime);

    if (quizId) {
      setTimeForSubjective({
        instructionForSubId: quizId,

        quizTime: totelSum,
      });
    }

    const newList = list.concat({
      id: questionId,
      name: questionName,
      type: qustype,
      questionTime: qusTime,
      questionMarks: questionMarks,
      data: managequestionData,

      minWordLimit: minWordLimit,
      maxFileSize: maxFileSize,
      maxnoUploadOfFile: maxnoUploadOfFile,
      maxWordLimit: maxWordLimit,
      questionTime: qusTime,
      questionTypeEnum: questionTypeEnum,
      questionSet: questionSetId,
      ansType: ansType,
    });
    if (!FlagForEditQuestion) {
      setList(newList);
      saveOrder(newList);
    } else {
      for (let i = 0; i < list.length; i++) {
        if (questionId === list[i].id) {
          list[i] = {
            id: questionId,
            name: questionName,
            type: qustype,
            questionTime: qusTime,
            questionMarks: questionMarks,
            data: managequestionData,
            maxFileSize: maxFileSize,
            minWordLimit: minWordLimit,
            maxWordLimit: maxWordLimit,
            questionTime: qusTime,
            questionTypeEnum: questionTypeEnum,
            questionSet: questionSetId,
            maxnoUploadOfFile: maxnoUploadOfFile,
            ansType: ansType,
          };
        }
      }
      setList(list);
      setFlagForEditQuestion(false);
    }

    // document.getElementById(quizId?quizId:mileStoneQuizId + "question-form").reset();
    // document.getElementById(quizId?quizId:mileStoneQuizId + "questionName").value = "";

    toggleQuestionForm();
  };

  const toggleQuestionForm = (e) => {
    document.getElementById(
      quizId ? quizId : mileStoneQuizId + "questionForm"
    ).style.display =
      document.getElementById(
        quizId ? quizId : mileStoneQuizId + "questionForm"
      ).style.display == "none"
        ? "block"
        : "none";
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    saveOrder(items);
  }

  function edit(lesson) {
    // console.log(lesson)
    // alert("rahul")
    setFlagForEditQuestion(true);
    toggleQuestionForm();
    if (lesson.questionTypeEnum == 1) {
      setVideoUrl(lesson.data?.question);
    } else if (lesson.questionTypeEnum == 2) {
      setAgreementUrl(lesson.data?.question);
    } else {
      setqus(lesson.data?.question);
    }

    setmaxFileSize(lesson?.maxFileSize);

    setQuestionMarks(lesson.data?.questionMarks);
    setQuestionName(lesson?.name);
    setQuestionId(lesson?.id);
    setqusInstruction(lesson?.data?.questionInstruction);
    setmaxnoUploadOfFile(lesson?.maxnoUploadOfFile);
    setqustype(lesson?.type);
    setqusTime(lesson?.questionTime);
    setansType(lesson?.ansType);
    setmaxWordLimit(lesson?.maxWordLimit);
    setMinWordLimit(lesson?.minWordLimit);
  }

  function resetData() {}
  async function del(question) {
    setopenLessonPopUp(true);
    setDeleteQuestionId(question);
  }

  const delfunction = (question) => {
    if (mileStoneQuizId) {
      deleteMileStoneSubQuestion({
        questionId: question.id,
        quizId: mileStoneQuizId,
      });
    }
    deleteSubQuestion({ questionId: question.id, quizId: quizId }).then(
      (res) => {
        // console.log(res.data);
      }
    );
    alert("Deleted " + question.name);
    const item1 = [];
    for (let i = 0; i < list.length; i++) {
      if (question.id !== list[i].id) {
        item1.push(list[i]);
      }
    }
    setList(item1);
    saveOrder(item1);
  };

  const handleVideo = (e) => {
    setVideoUrl("");
    let reader = new FileReader();
    // console.log(e);

    let filenameType = e.target.value.split(".");

    //console.log(filenameType[filenameType.length - 1]);

    let filetypedata = filenameType[filenameType.length - 1].toLowerCase();

    let url = URL.createObjectURL(e.target.files[0]);
    setVideoUrl(url);

    setVideoFile(e.target.files[0]);

    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      ///console.log(reader.result);
    };
  };

  const deleteVidUrl = () => {
    setVideoUrl("");
    setVideoFile("");
  };

  const uploadVideoFile = (data) => {
    setopenUploadPopUp(true);

    let str = Timestamp.fromDate(new Date()).toMillis();
    const storageref = ref(storage, `/files/${str}`);
    const uploadTask = uploadBytesResumable(storageref, data);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        //setprogress1(prog);
        setProgressUpload(prog);
        // console.log(prog);
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUploadesVideoUrl(url);
          // return url
        });
      }
    );
  };

  const deletePdf = () => {
    setAgreementUrl("");
    setBase64Agreement("");
  };
  const toggleForm = () => {
    toggleQuestionForm();
    setFlagForEditQuestion(false);
  };

  return (
    <section className="quizForm-container">
      <Upload
        open={openUploadPopUp}
        onClose={() => {
          setopenUploadPopUp(false);
        }}
        upload={progressUpload}
      />
      <DeleteLesson
        open={openLessonPopUp}
        onClose={() => setopenLessonPopUp(false)}
        delfun={(topic) => {
          delfunction(topic);
        }}
        topicId={deleteQuestionId}
      />
      <button
        className="primary-btn"
        style={{ marginTop: "30px" }}
        onClick={() => {
          toggleQuestionForm();
          setFlagForEditQuestion(false);
        }}
      >
        Add Question
      </button>
      <div
        className="quizFrom-mainFrom"
        id={quizId ? quizId : mileStoneQuizId + "questionForm"}
        style={{ display: "none" }}
      >
        <div className="quizFrom-mainFrom-head">Question Form</div>

        <form id={quizId + "question-form"} onSubmit={submit}>
          <div className="quizForm-mainForm-infoRow">
            <p
              className="quizForm-mainForm-label"
              style={{
                fontWeight: 500,
                color: "#171B1E",
                width: "20%",
              }}
            >
              Question Name :
            </p>
            <input
              type="text"
              className="quizForm-mainForm-inputBar"
              id={quizId + "questionName"}
              value={questionName}
              style={{
                border: `${
                  checkErrorInForm.questionName == "error"
                    ? "2px solid red"
                    : "1px solid #d7d7d7"
                }`,
                width: "30%",
                borderRadius: "6px",
                outline: "none",
                padding: "15px",
              }}
              onChange={(e) => {
                setQuestionName(e.target.value);
                setCheckErrorInForm((prev) => ({ ...prev, questionName: "" }));
                let value = e.target.value.replaceAll(" ", "");
                let time = new Date().getTime();
                if (!FlagForEditQuestion) {
                  setQuestionId(value + "_" + time);
                }
              }}
            />

            {checkErrorInForm.questionName == "error" && (
              <span
                style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
              >
                Enter Question Name
              </span>
            )}
          </div>
          <div className="quizForm-mainForm-infoRow">
            <label
              className="quizForm-mainForm-label"
              style={{
                fontWeight: 500,
                color: "#171B1E",
                width: "20%",
              }}
            >
              Type of Question :{" "}
            </label>
            <select
              className="quizForm-mainForm-select"
              value={qustype}
              onChange={(event) => {
                setqustype(event.target.value);
              }}
              style={{
                width: "30%",
                border: "1px solid #d7d7d7",
                outline: "none",
                height: "40px",
              }}
            >
              <option value="Text Question">Text Question</option>
              <option value="Video question">Video Question</option>

              <option value="File Question">File Question</option>
            </select>
          </div>
          <div className="quizForm-mainForm-infoRow">
            <label
              className="quizForm-mainForm-label"
              style={{
                fontWeight: 500,
                color: "#171B1E",
                width: "20%",
              }}
            >
              Type of Answer :{" "}
            </label>
            <select
              className="quizForm-mainForm-select"
              value={ansType}
              style={{
                border: `${
                  checkErrorInForm.ansType == "error"
                    ? "2px solid red"
                    : "1px solid #d7d7d7"
                }`,
                width: "30%",
                outline: "none",
                height: "40px",
              }}
              onChange={(event) => {
                setCheckErrorInForm((prev) => ({ ...prev, ansType: "" }));
                setansType(event.target.value);
              }}
            >
              <option value="" selected disabled hidden>
                Select an Option
              </option>
              <option value="1">Video Answer</option>
              <option value="3">Text Answer</option>
              <option value="2">File Answer</option>
            </select>

            {checkErrorInForm.ansType == "error" && (
              <span
                style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
              >
                Enter Ans Type
              </span>
            )}
          </div>

          <div className="quizForm-mainForm-infoRow">
            <label
              className="quizForm-mainForm-label"
              style={{
                fontWeight: 500,
                color: "#171B1E",
                width: "20%",
              }}
            >
              Question Time :{" "}
            </label>

            <input
              className="quizForm-mainForm-inputBar"
              name="Number"
              type="Number"
              value={qusTime}
              placeholder="Enter Max time"
              style={{
                border: `${
                  checkErrorInForm.qusTime == "error"
                    ? "2px solid red"
                    : "1px solid #d7d7d7"
                }`,
                width: "30%",
                borderRadius: "6px",
                outline: "none",
                padding: "15px",
              }}
              onChange={(event) => {
                if (event.target.value < 0) {
                  event.target.value = qusTime;
                } else {
                  setqusTime(event.target.value);
                  setCheckErrorInForm((prev) => ({ ...prev, qusTime: "" }));
                }
              }}
            />

            {checkErrorInForm.qusTime == "error" && (
              <span
                style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
              >
                Enter Question Time
              </span>
            )}
          </div>

          <div className="quizForm-mainForm-infoRow">
            <label
              className="quizForm-mainForm-label"
              style={{
                fontWeight: 500,
                color: "#171B1E",
                width: "20%",
              }}
            >
              Question Marks :{" "}
            </label>

            <input
              className="quizForm-mainForm-inputBar"
              name="Number"
              type="Number"
              value={questionMarks}
              placeholder="Enter Max time"
              style={{
                border: `${
                  checkErrorInForm.questionMarks == "error"
                    ? "2px solid red"
                    : "1px solid #d7d7d7"
                }`,
                width: "30%",
                borderRadius: "6px",
                outline: "none",
                padding: "15px",
              }}
              onChange={(event) => {
                if (event.target.value < 0) {
                  event.target.value = questionMarks;
                } else {
                  setQuestionMarks(event.target.value);
                  setCheckErrorInForm((prev) => ({
                    ...prev,
                    questionMarks: "",
                  }));
                }
              }}
            />

            {checkErrorInForm.questionMarks == "error" && (
              <span
                style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
              >
                Enter Question Marks
              </span>
            )}
          </div>
          {ansType == "3" ? (
            <>
              {" "}
              <div className="quizForm-mainForm-infoRow">
                <label
                  className="quizForm-mainForm-label"
                  style={{
                    fontWeight: 500,
                    color: "#171B1E",
                    width: "20%",
                  }}
                >
                  Max Word:
                </label>
                <input
                  className="quizForm-mainForm-inputBar"
                  name="text"
                  type="Number"
                  value={maxWordLimit}
                  style={{
                    border: `${
                      checkErrorInForm.maxWordLimit == "error"
                        ? "2px solid red"
                        : "1px solid #d7d7d7"
                    }`,
                    width: "30%",
                    borderRadius: "6px",
                    outline: "none",
                    padding: "15px",
                  }}
                  placeholder="Enter Max word"
                  onChange={(event) => {
                    if (event.target.value < 0) {
                      event.target.value = maxWordLimit;
                    } else {
                      setmaxWordLimit(event.target.value);
                      setCheckErrorInForm((prev) => ({
                        ...prev,
                        maxWordLimit: "",
                      }));
                    }
                  }}
                />

                {checkErrorInForm.maxWordLimit == "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    Enter maxWordLimit
                  </span>
                )}
              </div>
              <div className="quizForm-mainForm-infoRow">
                <label
                  className="quizForm-mainForm-label"
                  style={{
                    fontWeight: 500,
                    color: "#171B1E",
                    width: "20%",
                  }}
                >
                  Min Word:
                </label>
                <input
                  className="quizForm-mainForm-inputBar"
                  name="text"
                  type="Number"
                  value={minWordLimit}
                  style={{
                    border: `${
                      checkErrorInForm.minWordLimit == "error"
                        ? "2px solid red"
                        : "1px solid #d7d7d7"
                    }`,
                    width: "30%",
                    borderRadius: "6px",
                    outline: "none",
                    padding: "15px",
                  }}
                  placeholder="Enter Max word"
                  onChange={(event) => {
                    if (event.target.value < 0) {
                      event.target.value = minWordLimit;
                    } else {
                      setMinWordLimit(event.target.value);
                      setCheckErrorInForm((prev) => ({
                        ...prev,
                        minWordLimit: "",
                      }));
                    }
                  }}
                />

                {checkErrorInForm.minWordLimit == "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    Enter Min Word Limit
                  </span>
                )}
              </div>
            </>
          ) : (
            ""
          )}
          {ansType == "2" ? (
            <>
              {" "}
              <div className="quizForm-mainForm-infoRow">
                <label
                  className="quizForm-mainForm-label"
                  style={{
                    fontWeight: 500,
                    color: "#171B1E",
                    width: "20%",
                  }}
                >
                  Max Ans file Size (in MB):
                </label>
                <input
                  className="quizForm-mainForm-inputBar"
                  name="Number"
                  placeholder="Enter Max file"
                  value={maxFileSize}
                  style={{
                    border: `${
                      checkErrorInForm.maxFileSize == "error"
                        ? "2px solid red"
                        : "1px solid #d7d7d7"
                    }`,
                    width: "30%",
                    borderRadius: "6px",
                    outline: "none",
                    padding: "15px",
                  }}
                  onChange={(event) => {
                    if (event.target.value < 0) {
                      event.target.value = maxFileSize;
                    } else {
                      setmaxFileSize(event.target.value);
                      setCheckErrorInForm((prev) => ({
                        ...prev,
                        maxFileSize: "",
                      }));
                    }
                  }}
                />
              </div>
              <div className="quizForm-mainForm-infoRow">
                <label
                  className="quizForm-mainForm-label"
                  style={{
                    fontWeight: 500,
                    color: "#171B1E",
                    width: "20%",
                  }}
                >
                  Max Number of File ReUpload :
                </label>
                <input
                  className="quizForm-mainForm-inputBar"
                  name="Number"
                  placeholder="Enter Max file"
                  value={maxnoUploadOfFile}
                  style={{
                    border: `${
                      checkErrorInForm.maxnoUploadOfFile == "error"
                        ? "2px solid red"
                        : "1px solid #d7d7d7"
                    }`,
                    width: "30%",
                    borderRadius: "6px",
                    outline: "none",
                    padding: "15px",
                  }}
                  onChange={(event) => {
                    if (event.target.value < 0) {
                      event.target.value = maxnoUploadOfFile;
                    } else {
                      setmaxnoUploadOfFile(event.target.value);
                      setCheckErrorInForm((prev) => ({
                        ...prev,
                        maxnoUploadOfFile: "",
                      }));
                    }
                  }}
                />
              </div>
            </>
          ) : (
            ""
          )}

          {ansType == "1" ? (
            <>
              {" "}
              <div className="quizForm-mainForm-infoRow">
                <label
                  className="quizForm-mainForm-label"
                  style={{
                    fontWeight: 500,
                    color: "#171B1E",
                    width: "20%",
                  }}
                >
                  Max Number of File ReUpload :
                </label>
                <input
                  className="quizForm-mainForm-inputBar"
                  name="Number"
                  placeholder="Enter Max file"
                  value={maxnoUploadOfFile}
                  style={{
                    border: `${
                      checkErrorInForm.maxnoUploadOfFile == "error"
                        ? "2px solid red"
                        : "1px solid #d7d7d7"
                    }`,
                    width: "30%",
                    borderRadius: "6px",
                    outline: "none",
                    padding: "15px",
                  }}
                  onChange={(event) => {
                    if (event.target.value < 0) {
                      event.target.value = maxnoUploadOfFile;
                    } else {
                      setmaxnoUploadOfFile(event.target.value);
                      setCheckErrorInForm((prev) => ({
                        ...prev,
                        maxnoUploadOfFile: "",
                      }));
                    }
                  }}
                />
              </div>
            </>
          ) : (
            ""
          )}
          {/* <div className="quizForm-mainForm-infoRow">
            <label className="quizForm-mainForm-label">Instruction : </label>
            <textarea
              className="quizForm-mainForm-textarea"
              value={qusInstruction}
              style={{border:`${ checkErrorInForm.qusInstruction=="error"?"2px solid red":"1px solid #d7d7d7"}`}}
              onChange={(event) => {
                setqusInstruction(event.target.value);
                setCheckErrorInForm((prev) => ({ ...prev, qusInstruction: "" }));
              }}
            ></textarea>


{

checkErrorInForm.qusInstruction=="error"&&<span style={{marginLeft:"20px",color:"red",fontSize:"15px"}}>Enter Instruction</span>
} 
          </div> */}

          <div style={{ padding: "20px" }}>
            {qustype === "Text Question" ? (
              <>
                <div className="quizForm-mainForm-infoRow">
                  <label
                    className="quizForm-mainForm-label"
                    style={{
                      fontWeight: 500,
                      color: "#171B1E",
                      width: "20%",
                    }}
                  >
                    Question :{" "}
                  </label>
                  <textarea
                    className="quizForm-mainForm-textarea"
                    value={qus}
                    style={{
                      border: `${
                        checkErrorInForm.qus == "error"
                          ? "2px solid red"
                          : "1px solid #d7d7d7"
                      }`,
                      width: "30%",
                      minHeight: "100px",
                      borderRadius: "6px",
                      outline: "none",
                      padding: "15px",
                    }}
                    onChange={(event) => {
                      setqus(event.target.value);
                      setCheckErrorInForm((prev) => ({ ...prev, qus: "" }));
                    }}
                  ></textarea>

                  {checkErrorInForm.qus == "error" && (
                    <span
                      style={{
                        marginLeft: "20px",
                        color: "red",
                        fontSize: "15px",
                      }}
                    >
                      Enter Question
                    </span>
                  )}
                </div>
              </>
            ) : (
              " "
            )}
            {qustype == "Video question" ? (
              <>
                <div className="addItem-row">
                  <p
                    className="addItem-inputLabel"
                    style={{
                      fontWeight: 500,
                      color: "#171B1E",
                      width: "20%",
                    }}
                  >
                    Video link*
                  </p>
                  <input
                    type="text"
                    className="addItem-inputBar"
                    id={"itemDescription"}
                    name="itemDescription"
                    value={videoUrl}
                    style={{
                      border: "1px solid #d7d7d7",
                      width: "30%",
                      borderRadius: "6px",
                      outline: "none",
                      padding: "15px",
                    }}
                    onChange={(event) => {
                      setVideoUrl(event.target.value);

                      setUploadesVideoUrl(event.target.value);
                    }}
                  />
                </div>

                <div className="course-builder-info">
                  <p
                    className="course-builder-inputLable"
                    style={{
                      fontWeight: 500,
                      color: "#171B1E",
                      width: "20%",
                    }}
                  >
                    Question Video *
                  </p>
                  {videoUrl == "" ? (
                    <div
                      style={{
                        width: "320px",
                        height: "240px",
                        background: "#d5d5d5",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        borderRadius: "10px",
                      }}
                      onClick={onVideoClick}
                    >
                      Click to Upload Video
                    </div>
                  ) : (
                    <>
                      {/* <video width="320" height="240" controls>
            <source  src={videoUrl} type="video/mp4"/>
      <source  src={videoUrl} type="video/ogg"/>
        Your browser does not support the video tag.
           </video>
            */}

                      <ReactPlayer
                        url={videoUrl}
                        playing
                        controls
                        config={{
                          file: { attributes: { id: "audio-element" } },
                        }}
                        width="320px"
                        height="180px"
                      />
                    </>
                  )}

                  <input
                    ref={videoRef}
                    style={{
                      display: "none",
                    }}
                    type="file"
                    accept="video/*"
                    className="course-builder-inputImg"
                    id="courseVideo"
                    onChange={(e) => {
                      handleVideo(e);
                    }}
                  />
                  <button
                    className="secondary-btn"
                    onClick={deleteVidUrl}
                    style={{ marginLeft: "7vw" }}
                  >
                    {" "}
                    Delete
                  </button>
                  <button
                    className="primary-btn"
                    onClick={() => {
                      uploadVideoFile(videoFile);
                    }}
                    style={{ marginLeft: "1vw" }}
                  >
                    {" "}
                    Upload
                  </button>

                  {checkErrorInForm.uploadesVideoUrl == "error" && (
                    <span
                      style={{
                        marginLeft: "20px",
                        color: "red",
                        fontSize: "15px",
                      }}
                    >
                      {" "}
                      Upload Video
                    </span>
                  )}
                </div>
              </>
            ) : (
              " "
            )}
            {qustype === "File Question" ? (
              <>
                <div className="course-builder-info">
                  <p
                    className="course-builder-inputLable"
                    style={{
                      fontWeight: 500,
                      color: "#171B1E",
                      width: "20%",
                    }}
                  >
                    File *
                  </p>
                  {agreementUrl == "" ? (
                    <div
                      style={{
                        width: "320px",
                        height: "240px",
                        background: "#d5d5d5",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        borderRadius: "10px",
                      }}
                      onClick={onAgreementClick}
                    >
                      Click to Upload File
                    </div>
                  ) : (
                    <iframe src={agreementUrl} title="pdf"></iframe>
                  )}
                  <input
                    ref={agreementRef}
                    style={{
                      display: "none",
                    }}
                    type="file"
                    accept="application/pdf, application/vnd.ms-excel"
                    className="course-builder-inputImg"
                    id="agreementpdf"
                    onChange={(e) => {
                      handlePdf(e, setBase64Agreement, setAgreementUrl);
                      setCheckErrorInForm((prev) => ({
                        ...prev,
                        agreementUrl: "",
                      }));
                    }}
                  />
                  <button
                    className="action-btn"
                    onClick={() => {
                      deletePdf();
                    }}
                    style={{ marginLeft: "7vw" }}
                  >
                    {" "}
                    Delete
                  </button>
                  {checkErrorInForm.agreementUrl == "error" && (
                    <span
                      style={{
                        marginLeft: "20px",
                        color: "red",
                        fontSize: "15px",
                      }}
                    >
                      {" "}
                      upload pdf
                    </span>
                  )}
                </div>
              </>
            ) : (
              " "
            )}
          </div>
        </form>
        {formCheck && <div> Fill all the Required fields.</div>}
        <div
          className="quizForm-mainForm-infoRow"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "30px",
          }}
        >
          <button className="primary-btn" onClick={storedDataOnFirebase}>
            Submit
          </button>

          <button
            className="secondary-btn"
            style={{ marginLeft: "10px" }}
            onClick={toggleForm}
          >
            Cancel
          </button>
        </div>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <ul
              id="questionList"
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                display: "block",
                overflow: "hidden",
                width: "100%",
                listStyleType: "none",
                margin: "0px",
                padding: "0px",
              }}
            >
              {list.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      key={item.id}
                    >
                      <Accordion>
                        <AccordionSummary
                          {...provided.dragHandleProps}
                          expandIcon={<ExpandMoreSvg />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          sx={{
                            backgroundColor: "#174C97",
                            marginTop: "10px",
                            color: "white",
                            borderRadius: "5px",
                          }}
                        >
                          <Typography>{item.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div onClick={() => del(item)}>
                            {" "}
                            <MdDelete
                              style={{ float: "right", margin: "20px" }}
                              size={28}
                            />{" "}
                          </div>
                          <div onClick={() => edit(item)}>
                            {" "}
                            <MdEdit
                              style={{ float: "right", margin: "20px" }}
                              size={28}
                            />{" "}
                          </div>
                          <p>{item.type}</p>
                        </AccordionDetails>
                      </Accordion>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </section>
  );
};

export default CreateSubQuestion;
