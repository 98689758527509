import { Timestamp } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { functions } from "../../../Firebase";
import Navbar from "../../Component/NavbarTop";
import Sidebar from "../../Component/Sidebar";
import "../StyleAdminComponents/GroupList.css";
import Notify from "../Dashboard/Notify";
import "../Dashboard/OverdueEnrollments.css";

import { create } from "sortablejs";
import LoadingScreen from "./loadingScreen";
function GroupEnrollmentView() {
  const { clrs } = useSelector((state) => state.createClr);
  const location = useLocation();
  const overDueInfo = location.state;
  console.log(overDueInfo);

  const [loadingScreen, setLoadingScreen] = useState(false);
  const navigate = useNavigate();
  const courseOverdueUserNotificationCallable = httpsCallable(
    functions,
    "courseOverdueUserNotificationCallable"
  );
  const groupEnrollmentView = httpsCallable(functions, "groupEnrollmentView");
  const getUserAttempsWithGroupId = httpsCallable(
    functions,
    "getUserAttempsWithGroupId"
  );
  const getAllQuestionSetDataForCourse = httpsCallable(
    functions,
    "getAllQuestionSetDataForCourse"
  );
  const [openEnrollment, setOpenEnrollment] = useState(false);
  const [reportLoading, setReportLoading] = useState(true);
  const [showLoading, setLoading] = useState(false);
  const [overDueArray, setOverDueArray] = useState(null);
  const [details, setDetails] = useState(null);
  const [arrLength, setArrLength] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [displayNotify, setDisplayNotify] = useState(false);
  const [courseName, setCourseName] = useState([]);
  const [newEmployeeArray, setNewEmployeeArray] = useState(null);

  const [selectDate, setSelectDate] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [courseType, setCourseType] = useState("");
  const [selectDefault, setselectDefault] = useState("default");

  const [extraArr, setExtraArr] = useState([]);
  const [fillterName, setFillterName] = useState("");

  const [questionSetData, setQuestionSetData] = useState([]);

  ///////Company DAta///////////

  const [cardData, setCardData] = useState([]);

  /////////////////////Group ////////
  const [getApiDataForGroupName, setGetApiDataForGroupName] = useState([]);
  const [selectGroupName, setSelectGroupName] = useState("");
  const [getDataForASelectedGroup, setGetDataForASelectedGroup] = useState([]);
  const [groupCourseName, setGroupCourseName] = useState("");
  const [getDataForASelectedCourse, setGetDataForASelectedCourse] = useState(
    []
  );
  const [tempstateForLoading, setTempStateForLoading] = useState("test");
  const initState = {
    courseName: "default",
    date: "",
  };
  const [query, setQuery] = useState(initState);
  const stateDataForGroupName = useLocation();
  const [groupNameFromState, setGroupNameFromState] = useState(
    stateDataForGroupName.state.groupId
  );

  useEffect(() => {
    setLoadingScreen(true);
    getUserAttempsWithGroupId({
      courseId: overDueInfo?.courseId,
      groupEnrollmentId: overDueInfo.groupId,
    })
      .then((res) => {
        setLoadingScreen(false);

        setCardData(res.data);
        setExtraArr(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        setLoadingScreen(false);
      });

    getAllQuestionSetDataForCourse({ courseId: overDueInfo?.courseId }).then(
      (res) => {
        setQuestionSetData(res.data);
      }
    );
  }, []);

  const callApiForGetCourse = (state, type) => {
    if (type == "name") {
      let filterDtaa = cardData.filter((e) => e.name.includes(fillterName));
      console.log(filterDtaa);
      setCardData(filterDtaa);
    }
    if (type == "status") {
      let filterDtaa = cardData.filter((e) => e.userStatus == state);
      console.log(filterDtaa);
      setCardData(filterDtaa);
    }
    if (type == "clear") {
      setCardData(extraArr);
    }
    if (type == "QuestionSet") {
      let filterDtaa = cardData.filter((e) => e.questionSet == state);
      console.log(filterDtaa);
      setCardData(filterDtaa);
      alert("QuestionSet");
    }
  };

  const selectedDateTohumanDate = (type) => {
    if (type == "clear") {
      setDetails([]);
      setArrLength(0);
      setCurrentPage(0);
    }
    // console.log(newarr)
    if (type == "Funnel") {
      navigate("/admin/groupEnrollmentFunnel", {
        state: {
          courseId: "data4oo8090gg9",
          groupEnrollmentId: overDueInfo.groupId,
        },
      });
    }
  };

  // console.log("courseName",courseName);
  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(arrLength / pageSize)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };
  const NotifyClick = () => {
    setDisplayNotify(!displayNotify);
  };

  const handelActionStateChange = (type, item, id) => {
    if (type == "complete") {
      window.open(item.downloadLink, "_blank", "noopener,noreferrer");
    } else if (type == "overdue") {
      setTempStateForLoading(id);
      courseOverdueUserNotificationCallable({
        courseName: item.courseName,
        courseId: item.courseId,
        dueDate: item.dueDate,
        uid: item.userId,
      }).then((res) => {
        setTempStateForLoading("test");
        alert("reminder done");
      });
    }
  };

  const goToViewUser = (item) => {
    let state = {
      name: item.name,
      role: item?.role,
      email: item?.email,
      uid: item?.uid,
    };
    navigate("/admin/userview", { state: { ...state } });
  };
  return (
    <div className="section" style={{ backgroundColor: "#e5e5e5" }}>
      <LoadingScreen
        open={loadingScreen}
        onClose={() => setLoadingScreen(false)}
      />
      {displayNotify && <Notify NotifyClick={NotifyClick} />}
      <Navbar page={t("Enrollments")} />
      <div className="createcourse-main">
        <Sidebar page={"enrollments"} hidepage={true} />
        <div className="admin-overdue">
          <div className="admin-overdue-mid" style={{ marginTop: "30px" }}>
            <div className="admin-overdue-mid-left">
              <div className="admin-overdue-mid-left-txt">{t("Name")}</div>
              <div className="admin-overdue-mid-left-input">
                <input
                  onChange={(e) => {
                    setFillterName(e.target.value);
                  }}
                  type="text"
                />
              </div>
            </div>

            <div className="admin-overdue-mid-right">
              {/* <div className="admin-overdue-mid-left-txt">{t("Date")}</div> */}
              <div className="admin-overdue-mid-left-input-right">
                {/* <input type="date" name="dueDate"  onChange={handleDateChange} /> */}
                <div
                  className="admin-dashboard-main-mid-item-last-filter"
                  style={{ marginTop: "5vh" }}
                >
                  {" "}
                  <div className="admin-dashboard-main-top-last-filter">
                    <button
                      className="primary-btn"
                      style={{
                        width: "8vw",
                      }}
                      onClick={() => {
                        callApiForGetCourse("ee", "name");
                      }}
                    >
                      {t("Search")}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="admin-overdue-mid-left">
              <div className="admin-overdue-mid-left-txt">{t("Status")}</div>
              <div className="admin-overdue-mid-left-input">
                <select
                  name="groupStatus"
                  id="group"
                  onChange={(e) => {
                    callApiForGetCourse(e.target.value, "status");
                  }}
                >
                  <option value="" selected disabled hidden>
                    Select a status
                  </option>
                  <option value="UserApplied"> User Applied </option>
                  <option value="UnderEvaluation"> Under Evaluation </option>
                  <option value="SubjectiveTestPassed">
                    {" "}
                    Subjective Test Passed
                  </option>
                  {/* <option value="UserRegister"> User Register </option> */}
                  <option value="ObjectiveTestPassed">
                    {" "}
                    Objective Test Passed{" "}
                  </option>
                  <option value="ObjectiveTestFailed">
                    {" "}
                    Objective Test Failed{" "}
                  </option>
                  <option value="SubjectiveTestFailed">
                    Subjective Test Failed
                  </option>
                  <option value="PaymentDone"> Payment Done </option>
                  <option value="PaymentPending"> Payment Pending </option>
                  <option value="PaymentFailed"> Payment Failed </option>
                  <option value="AggrementDone">Aggrement Done</option>
                  <option value="AggrementPending">Aggrement Pending </option>
                  <option value="ApplicationPending">
                    Application Pending
                  </option>
                  <option value="ApplicationDone">Application Done</option>
                  <option value="ApplicationVerificationFailed">
                    Application Verification Failed
                  </option>

                  <option value="SelectedInCourse">Selected In Course </option>
                  <option value="SelectedInCourseFailed">
                    Selected In Course Failed
                  </option>
                </select>
              </div>
            </div>

            <div className="admin-overdue-mid-left">
              <div className="admin-overdue-mid-left-txt">
                {t("Question Set")}
              </div>
              <div className="admin-overdue-mid-left-input">
                <select
                  name="groupStatus"
                  id="group"
                  onChange={(e) => {
                    callApiForGetCourse(e.target.value, "QuestionSet");
                  }}
                >
                  <option value="" selected disabled hidden>
                    Select a Set
                  </option>
                  <option value="all">All</option>
                  {questionSetData?.map((e) => (
                    <option value={e.questionSetId}>{e.questionSetId}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="admin-overdue-mid-right">
              {/* <div className="admin-overdue-mid-left-txt">{t("Date")}</div> */}
              <div className="admin-overdue-mid-left-input-right">
                {/* <input type="date" name="dueDate"  onChange={handleDateChange} /> */}
                <div
                  className="admin-dashboard-main-mid-item-last-filter"
                  style={{ marginTop: "5vh" }}
                >
                  {" "}
                  <div className="admin-dashboard-main-top-last-filter">
                    <button
                      className="clear-btn"
                      style={{
                        width: "8vw",
                      }}
                      onClick={(e) => {
                        callApiForGetCourse("9i", "clear");
                      }}
                    >
                      {t("Clear")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="admin-overdue-mid-right">
              {/* <div className="admin-overdue-mid-left-txt">{t("Date")}</div> */}
              <div className="admin-overdue-mid-left-input-right">
                {/* <input type="date" name="dueDate"  onChange={handleDateChange} /> */}
                <div
                  className="admin-dashboard-main-mid-item-last-filter"
                  style={{ marginTop: "5vh" }}
                >
                  {" "}
                  <div className="admin-dashboard-main-top-last-filter">
                    <button
                      className="primary-btn"
                      style={{
                        width: "10vw",
                        textAlign: "left",
                      }}
                      onClick={() => {
                        selectedDateTohumanDate("Funnel");
                      }}
                    >
                      <div></div>
                      <div>{t("Funnel View")}</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="admin-overdue-top">
            <div className="admin-overdue-top-item">
              {t("View Group Enrollment")}
            </div>
          </div>
          <div className="admin-overdue-bottom" style={{ marginTop: "-1vw" }}>
            {cardData?.map((e) => (
              <div
                style={{
                  gridTemplateColumns: "1fr 1fr 1fr",
                  display: "grid",
                  textAlign: "center",
                  margin: "20px",
                  border: "1px solid #291313",
                  borderRadius: "16px",
                  padding: "20px",
                }}
                onClick={() => {
                  goToViewUser(e);
                }}
              >
                <div>
                  <img src={e?.userImg} alt="car" width="70%" height="100px" />
                </div>
                <div style={{ gridGap: "20px", display: "grid" }}>
                  <div style={{ display: "flex", gap: "20px" }}>
                    <p
                      style={{
                        color: "#000",
                        fontWeight: 500,
                        fontFamily: "Poppins",
                      }}
                    >
                      Name :
                    </p>
                    <p
                      style={{
                        color: "#000",
                        fontFamily: "Poppins",
                      }}
                    >
                      {e.name}
                    </p>
                  </div>
                  <div style={{ display: "flex", gap: "20px" }}>
                    <p
                      style={{
                        color: "#000",
                        fontWeight: 500,
                        fontFamily: "Poppins",
                      }}
                    >
                      Status :
                    </p>
                    <p
                      style={{
                        color: "#000",
                        fontFamily: "Poppins",
                      }}
                    >
                      {e.userStatus}
                    </p>
                  </div>
                  <div style={{ display: "flex", gap: "20px" }}>
                    <p
                      style={{
                        color: "#000",
                        fontWeight: 500,
                        fontFamily: "Poppins",
                      }}
                    >
                      Contact number :{" "}
                    </p>
                    <p
                      style={{
                        color: "#000",
                        fontFamily: "Poppins",
                      }}
                    >
                      {e.phoneNumber}
                    </p>
                  </div>
                </div>
                <div style={{ gridGap: "20px", display: "grid" }}>
                  <div style={{ display: "flex", gap: "20px" }}>
                    <p
                      style={{
                        color: "#000",
                        fontWeight: 500,
                        fontFamily: "Poppins",
                      }}
                    >
                      attemps :
                    </p>
                    <p
                      style={{
                        color: "#000",
                        fontFamily: "Poppins",
                      }}
                    >
                      {e.attemps}
                    </p>
                  </div>
                  <div style={{ display: "flex", gap: "20px" }}>
                    <p
                      style={{
                        color: "#000",
                        fontWeight: 500,
                        fontFamily: "Poppins",
                      }}
                    >
                      courseId :
                    </p>
                    <p
                      style={{
                        color: "#000",
                        fontFamily: "Poppins",
                      }}
                    >
                      {e.courseId}
                    </p>
                  </div>
                  <div style={{ display: "flex", gap: "20px" }}>
                    <p
                      style={{
                        color: "#000",
                        fontWeight: 500,
                        fontFamily: "Poppins",
                      }}
                    >
                      Set attended:{" "}
                    </p>
                    <p
                      style={{
                        color: "#000",
                        fontFamily: "Poppins",
                      }}
                    >
                      {e.questionSet}
                    </p>
                  </div>
                </div>
              </div>
            ))}
            <div className="admin-overdue-bottom-pagination">
              <div className="admin-overdue-bottom-pagination-cnt">
                <div className="admin-overdue-bottom-pagination-cnt-item">
                  <svg
                    onClick={() => clickhandler(currentPage - 1)}
                    width="auto"
                    height="22"
                    viewBox="0 0 14 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div className="admin-overdue-bottom-pagination-cnt-item-btn">
                  {currentPage + 1} of {Math.ceil(arrLength / pageSize)}
                </div>
                <div
                  style={{ marginRight: "19vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <svg
                    onClick={() => clickhandler(currentPage + 1)}
                    width="auto"
                    height="20"
                    viewBox="0 0 13 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div
                  style={{ display: "flex", marginRight: "-30vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <label className="admin-row">{t("Show")}</label>
                  <select
                    style={{
                      width: "4vw",
                      margin: "0.5vw",
                      marginBottom: "1.5vw",
                      height: "1.5vw",
                    }}
                    value={pageSize}
                    onChange={(e) => setPageSize(e.target.value)}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                  </select>
                  <label className="admin-row"> {t("PagePerItem")}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ height: "100%", width: "6vw", backgroundColor: "white" }}
        ></div>
        <div className="courselist-main-cnt-back">
          <div className="courselist-main-cnt-back-btn">
            <Link to={"/admin/groupEnrollment"}>
              <button className="secondary-btn">{t("Back")}</button>{" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupEnrollmentView;
