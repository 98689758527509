export const defultGreen = {
  SidebariconClr: "#FFFFFF",
  SidebarcntClr: "#linear-gradient(180deg, #D55628 0%, #E18A58 100%);",
  SidebartxtClr: "#E18A58",
  EnrolBtnClr: "#E18A58",
  NavbarTxtClr: "black",
  DefSidebariconClr: "#D7D7D7",
  DefSidebarcntClr: "#ffffff",
  DefSidebartxtClr: "#717579",
};

// export const darkblue = {
//   SidebariconClr: "rgba(0, 69, 119, 1)",
//   SidebarcntClr: "rgba(0, 69, 119, 0.2)",
//   SidebartxtClr: "rgba(0, 69, 119, 1)",
//   EnrolBtnClr: "#007848",
//   NavbarTxtClr: "black",
//   DefSidebariconClr: "#D7D7D7",
//   DefSidebarcntClr: "#ffffff",
//   DefSidebartxtClr: "#717579",
// };

// export const blue = {
//   SidebariconClr: "rgb(36 24 135)",
//   SidebarcntClr: "hwb(224deg 78% 3%)",
//   SidebartxtClr: "rgb(1 5 16)",
//   EnrolBtnClr: "rgb(36 24 135)",
//   NavbarTxtClr: "rgba(0, 73, 95, 1)",
//   CourseListBtn: "rgb(36 24 135)",
//   DefSidebariconClr: "#D7D7D7",
//   DefSidebarcntClr: "#ffffff",
//   DefSidebartxtClr: "#717579",
// };

export const GradiantGreen = {
  SidebariconClr: "white",
  SidebarcntClr: "#D55628",
  SidebartxtClr: "white",
  NavbarTxtClr: "#1E3758",
  CourseListBtn: "#D55628",
  DefSidebariconClr: "#1E3758",
  DefSidebarcntClr: "#ffffff",
  DefSidebartxtClr: "#1E3758",
};
