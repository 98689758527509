import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";
import { Timestamp } from "firebase/firestore";

const PaymentDetails = ({ uid, data }) => {
  const [training, setTraining] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [arrLength, setArrLength] = useState(0);
  const [loading, setLoading] = useState(false);
  const fetch_user_courses = httpsCallable(functions, "fetch_user_courses");

  console.log(data);
  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(arrLength / 5)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };

  const datetohum=(data)=>{
    return Timestamp.fromMillis(
        data?.timeStamp?._seconds * 1000
      )
        .toDate()
        .toDateString();
  }
  return (
    <div
      style={{
        margin: "0vw 2vw 2vw 2vw",
        padding: "20px",
        fontFamily: "Poppins",
        height:"300px",
        overflowY:"scroll"
      }}
      className="admin-overdue-bottom"
    >
      <div style={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: 500 }}>
        {t("Payment Details")}
      </div>
      <div style={{ textAlign: "center", marginTop: "30px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            backgroundColor: "#D9D9D9",
            padding: "10px",
          }}
        >
          <h4 style={{ width: "25%" }}> Payment Status</h4>
          <h4 style={{ width: "25%" }}> Order No.</h4>
          <h4 style={{ width: "25%" }}> Payment Transaction No.</h4>
        
          <h4 style={{ width: "25%" }}> Payment date</h4>
        </div>

       { data?.map((ele)=> <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            backgroundColor: "#F5F5F5",
            padding: "10px",
          }}
        >
          <p style={{ width: "25%" }}> Success</p>
          <p style={{ width: "25%" }}> {ele?.orderId}</p>
          <p style={{ width: "25%" }}> {ele?.paymentId}</p>
          <p style={{ width: "25%" }}> {datetohum(ele)} </p>
        </div>)
}
       
      </div>
    </div>
  );
};

export default PaymentDetails;
