import React, { useEffect } from "react";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { functions } from "../../../../Firebase";
import ".././AdminSetting.css";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Sidebar from "../../../Component/Sidebar";
import NavbarTop from "../../../Component/NavbarTop";
import { t } from "i18next";
import deleteIcon from "../../../../utilities/images/deleteIcon.svg";
import editIcon from "../../../../utilities/images/editIcon.svg";
import LoadingScreen from "../../BasicCourse/lodingScree";

export const Coordinator = () => {
  const [showdepform, setShowdepform] = useState(false);
  const [showQuestionForm, setShowQuestionForm] = useState(false);
  //================company settings===========================//
  const [template, settemplate] = useState([]);
  const [deltemp, setdeltemp] = useState(false);
  const [showLoading, setLoading] = useState(true);
  const [questionId, setQuestionId] = useState("");
  const [flagForEdit, setFlagForEdit] = useState(false);

  const getcoordinator = httpsCallable(functions, "getcoordinator");
  const [department, setDepartment] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [deledep, setDeldep] = useState(false);

  const [coordinatorNumber, setCoordinatorNumber] = useState("");
  // delete company

  // get company and department data
  const fetchdata = () => {
    setLoading(true);
    getcoordinator({})
      .then((res) => {
        setLoading(false);
        //console.log(res);
        settemplate(res.data);
      })
      .catch((err) => {
        setLoading(false);
        //  console.log(err);
      });
  };

  const fetchDepartmentdata = () => {
    setLoading1(true);
    getcoordinator({})
      .then((res) => {
        // console.log(res);
        if (res.data.length > 0) {
          setDepartment(res.data);
        }
        setLoading1(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading1(false);
      });
  };

  //================make default settings===========================//

  //================create Department===========================//

  const createBasicLessonCategory = httpsCallable(
    functions,
    "createBasicLessonCategory"
  );
  const addcoordinator = httpsCallable(functions, "addcoordinator");
  const editcoordinator = httpsCallable(functions, "editcoordinator");
  const [saveloading, setSaveloading] = useState(false);
  const [depName, setDepName] = useState("");
  const [questionName, setQuestionName] = useState("");
  const summitDepartment = (type) => {
    if (questionName === "") return alert("Coordinator name is required");
    if (coordinatorNumber === "")
      return alert("Coordinator Number is required");
    setSaveloading(true);
    if (flagForEdit) {
      editcoordinator({
        name: questionName,
        mobile: coordinatorNumber,
        id: questionId,
      })
        .then((res) => {
          setQuestionName("");
          setCoordinatorNumber("");
          //console.log(res);
          alert("coordinator Details Update");
          fetchdata();
          setSaveloading(false);
          setShowQuestionForm(false);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setSaveloading(false);
        });
    } else {
      addcoordinator({
        name: questionName,
        mobile: coordinatorNumber,
      })
        .then((res) => {
          //  console.log(res);
          setQuestionName("");
          setCoordinatorNumber("");
          alert("coordinator Created");
          fetchdata();
          setShowQuestionForm(false);
          setSaveloading(false);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setSaveloading(false);
        });
    }
  };

  const editQuestion = (ele) => {
    // console.log(ele)
    setShowQuestionForm(true);
    setQuestionId(ele.id);
    setQuestionName(ele.name);
    setCoordinatorNumber(ele.mobile);
    setFlagForEdit(true);
  };

  useEffect(() => {
    fetchdata();
  }, [deltemp]);
  //   useEffect(() => {
  //     fetchDepartmentdata();
  //   }, [deledep]);

  const navigate = useNavigate();

  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Coordinator")} />
        {/* <Navbar /> */}

        <LoadingScreen open={showLoading} onClose={() => setLoading(false)} />
        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              {/* Company settings */}

              {/* Question Settings */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h1
                  style={{
                    color: "#1E3758",
                    fontSize: "18px",
                    fontWeight: 700,
                    fontFamily: "Poppins",
                  }}
                >
                  Coordinator
                </h1>

                <div className="create-new-box">
                  <button
                    className="primary-btn"
                    onClick={() => setShowQuestionForm(!showQuestionForm)}
                  >
                    + Add Coordinator
                  </button>
                </div>
              </div>

              {showQuestionForm && (
                <>
                  <div>
                    <p
                      style={{
                        fontSize: "18px",
                        color: "#1E3758",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                      }}
                    >
                      Coordinator Name:{" "}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "40px",
                      }}
                    >
                      <input
                        style={{
                          margin: "5px 0px",
                          padding: "8px 10px",
                          fontSize: "18px",
                          color: "#C1C1C1",
                          border: "1px solid #1E3758",
                          borderRadius: "5px",
                          outline: "none",
                        }}
                        type="text"
                        value={questionName}
                        onChange={(e) => setQuestionName(e.target.value)}
                        placeholder="Enter Coordinator Name"
                      />
                    </div>
                  </div>

                  <div>
                    <p
                      style={{
                        fontSize: "18px",
                        color: "#1E3758",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                      }}
                    >
                      Coordinator Number:{" "}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "40px",
                      }}
                    >
                      <input
                        type="number"
                        style={{
                          margin: "5px 0px",
                          padding: "8px 10px",
                          fontSize: "18px",
                          color: "#C1C1C1",
                          border: "1px solid #1E3758",
                          borderRadius: "5px",
                          outline: "none",
                        }}
                        value={coordinatorNumber}
                        onChange={(e) => setCoordinatorNumber(e.target.value)}
                        placeholder="Enter Coordinator Number"
                      />
                      <div>
                        <button
                          className="primary-btn"
                          disabled={saveloading}
                          onClick={() => {
                            summitDepartment();
                          }}
                        >
                          {saveloading ? "Loading" : "save"}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div
                className="bigger-container"
                style={{
                  display: "grid",
                  marginTop: "30px",
                  gridTemplateColumns: "2fr 2fr 1fr",
                }}
              >
                <div> Name</div>
                <div> Number</div>

                <div>Action</div>
              </div>

              <div className="setting_details_table">
                {template?.map((ele) => {
                  return (
                    <div
                      className="outer-inner-container"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "2fr 2fr 1fr",
                      }}
                    >
                      <div className="templatename">{ele.name}</div>
                      <div className="templatename">{ele.mobile}</div>
                      <div className="button-box">
                        <div className="button-box-inner mx-3 my-1">
                          <button
                            className="action-btn"
                            onClick={() => {
                              editQuestion(ele);
                            }}
                          >
                            <img src={editIcon} />
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "30px",
                }}
              >
                <button className="secondary-btn" onClick={() => navigate(-1)}>
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
