import React, { createRef, useEffect, useRef } from "react";
import { useState } from "react";
import CreateQuizMileStone from "./createQuizForMilStoen";
import $ from "jquery";
import CreateSubQuestion from "./createSubQuestion";
import { SvgVector, EditSvg, ExpandMoreSvg, DeleteSVg } from "./icons";
import { functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";

import { Timestamp } from "firebase/firestore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { ExpandMoreSharp } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./createTopic.css";
import DeleteLesson from "./lessonDeletePopUp";
import CreateSubMilestone from "./createSubMilstone";
const CreateSubjectiveQuiz = ({
  editQuizArr,
  topicId,
  courseId,
  lessonId,
  name,
}) => {
  const initialList = [];
  const [list, setList] = useState(initialList);

  const [imageUrl, setImageUrl] = useState("");
  const [base64Img, setBase64Img] = useState("");
  const createMileStone = httpsCallable(functions, "createMileStone");
  const addExtraInfoForSub = httpsCallable(functions, "addExtraInfoForSub");
  const addMileStoneSubOrder = httpsCallable(functions, "addMileStoneSubOrder");
  const addCourseMilstonOrder = httpsCallable(
    functions,
    "addCourseMilstonOrder"
  );

  const deleteMileStone = httpsCallable(functions, "deleteMileStone");
  const getAllMileStoneDataForCourse = httpsCallable(
    functions,
    "getAllMileStoneDataForCourse"
  );
  const deleteQuiz = httpsCallable(functions, "deleteQuiz");
  // deleteQuiz

  const [deleteQuizId, setDeleteQuizId] = useState("");
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);

  const [quizId, setQuizId] = useState("");
  const [editable, setEditable] = useState(false);
  const [checkFailed, setCheckFailed] = useState(false);

  const [editMileStoneFlag, setEditMileStoneFlag] = useState(false);
  const [quizDetails, setQuizDetails] = useState({
    quizName: "",
    passScore: "gjg",
    retry: "0",
    answerAll: "yes",
    timeLimit: "gjg",
    randomizeQuestions: "yes",
    skipQuestion: "yes",
    randomAnswers: "yes",
    successMessage: "You Successfully completed the Quiz",
    retakeMessage: "Better luck next time",
    instruction: "flow the instruction",
  });
  const [checkErrorInForm, setCheckErrorInForm] = useState({
    quizName: "",
    passScore: "ghgh",
    retry: "0",
    answerAll: "yes",
    timeLimit: "hgh",
    randomizeQuestions: "yes",
    skipQuestion: "yes",
    randomAnswers: "yes",
    successMessage: "You Successfully completed the Quiz",
    retakeMessage: "Better luck next time",
    instruction: "flow the instruction",
  });

  const imageRef = useRef(null);

  const onImageClick = () => {
    imageRef.current.click();
  };

  useEffect(() => {
    if (!editQuizArr) return;
    // setCheckEditFlag(true);

    // setCheckEditFlag(false);

    //setIsCourseIdSet(editCourseId)
    getAllMileStoneDataForCourse({ courseId: courseId }).then((res) => {
      for (let i = 0; i < res.data.length; i++) {
        // console.log(res.data[i]);

        setList((prev) =>
          prev.concat({
            id: res.data[i]?.mileStoneId,
            name: res.data[i]?.mileStoneName,
            mileStoneInstruction: res.data[i]?.mileStoneInstruction,
          })
        );
      }
    });
  }, []);

  const quizFormRef = createRef();

  const handleInput = (e) => {
    const { value, name } = e.target;
    setQuizDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  function checkForm() {
    let res = "allIsWell";

    if (quizDetails.quizName == "") {
      setCheckErrorInForm((prev) => ({ ...prev, quizName: "error" }));

      return;
    }
    return res;
  }

  function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }

    if (courseId != null) {
      addCourseMilstonOrder({
        milston: orderList,
        courseId: courseId,
      });
    }
  }
  const addQuiz = async () => {
    let check = checkForm();
    if (check != "allIsWell") {
      // console.log(check);
      setCheckFailed(true);
      return;
    }
    setCheckErrorInForm({
      quizName: "",
      passScore: "",
      retry: "0",
      answerAll: "yes",
      timeLimit: "",
      randomizeQuestions: "yes",
      skipQuestion: "yes",
      randomAnswers: "yes",
      successMessage: "",
      retakeMessage: "",
      instruction: "",
    });
    setCheckFailed(false);

    const newList = list.concat({
      id: quizId,
      name: quizDetails.quizName,

      instruction: quizDetails.instruction,
    });
    createMileStone({
      mileStoneId: quizId,
      mileStoneName: quizDetails.quizName,
      mileStoneInstruction: quizDetails.instruction,
      // base64Img:base64Img,
      courseAssociated: courseId,
    });

    if (!editable) {
      setList(newList);
      saveOrder(newList);
    }

    alert("Added");

    setQuizDetails({
      quizName: "",
      passScore: "",
      retry: "",
      answerAll: "yes",
      timeLimit: "",
      randomizeQuestions: "yes",
      skipQuestion: "yes",
      randomAnswers: "yes",
      successMessage: "",
      retakeMessage: "",
      instruction: "",
    });

    toggleForm();
  };

  const toggleForm = (e) => {
    quizFormRef.current.style.display =
      quizFormRef.current.style.display === "none" ? "" : "none";
    setQuizDetails({
      quizName: "",
      passScore: "",
      retry: "0",
      answerAll: "yes",
      timeLimit: "",
      randomizeQuestions: "yes",
      skipQuestion: "yes",
      randomAnswers: "yes",
      successMessage: "You Successfully completed the Quiz",
      retakeMessage: "Better luck next time",
      instruction: "flow",
    });
    setQuizId("");
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    saveOrder(items);
  }

  function edit(quiz) {
    setEditable(true);
    // console.log(quiz);
    quizFormRef.current.style.display = "";
    let params = {
      quizName: quiz.name,

      instruction: quiz.mileStoneInstruction,
    };
    setQuizDetails(params);
    setQuizId(quiz.id);
  }

  function updateQuiz() {
    createMileStone({
      mileStoneId: quizId,
      mileStoneName: quizDetails.quizName,
      mileStoneInstruction: quizDetails.instruction,
      // base64Img:base64Img,
      courseAssociated: courseId,
    });

    for (let i = 0; i < list.length; i++) {
      if (quizId === list[i].id) {
        list[i] = {
          id: quizId,
          name: quizDetails.quizName,

          instruction: quizDetails.instruction,
        };
      }
    }
    setList(list);
    alert("Updated");
    setEditable(false);
    quizFormRef.current.style.display = "none";
  }

  async function del(quiz) {
    // implement delete api done
    setopenLessonPopUp(true);
    setDeleteQuizId(quiz);
  }
  const delfunction = (quiz) => {
    deleteMileStone({ milestonId: quiz.id });
    alert("Deleted " + quiz.name);
    const item = [];
    for (let i = 0; i < list.length; i++) {
      if (quiz.id !== list[i].id) {
        item.push(list[i]);
      }
    }
    setList(item);
    saveOrder(item);
  };

  const handleCourseImage = (e) => {
    let reader = new FileReader();
    //console.log(e);

    let filenameType = e.target.value.split(".");

    // console.log(filenameType[filenameType.length - 1]);

    let filetypedata = filenameType[filenameType.length - 1].toLowerCase();
    if (
      filetypedata == "png" ||
      filetypedata == "jpeg" ||
      filetypedata == "jpg"
    ) {
    } else {
      alert("only jpeg  , gif  ,png  file accepted");
      e.target.value = null;

      return;
    }

    let rfilesize = Number(e.target.files[0].size) / 1024;

    rfilesize = rfilesize / 1024;
    //console.log(rfilesize);
    if (Number(rfilesize) > 5) {
      alert("size must be less than 5 mb");

      e.target.value = null;
      return;
    } else {
      // console.log(rfilesize);
      let url = URL.createObjectURL(e.target.files[0]);
      setImageUrl(url);

      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function () {
        //console.log(reader.result);
        setBase64Img(reader.result);
      };
    }
  };

  return (
    <>
      <DeleteLesson
        open={openLessonPopUp}
        onClose={() => setopenLessonPopUp(false)}
        delfun={(topic) => {
          delfunction(topic);
        }}
        topicId={deleteQuizId}
      />
      <section className="createQuiz-container">
        <button
          className="primary-btn"
          onClick={() => {
            setEditable(false);
            toggleForm();
          }}
        >
          add Milstone
        </button>
        <div
          className="createQuiz-quizForm"
          ref={quizFormRef}
          id={"quizForm"}
          style={{ display: "none" }}
        >
          <div className="createQuiz-heading">MilStone</div>
          <div className="createQuiz-row">
            <p
              className="createQuiz-inputLabel"
              style={{
                fontWeight: 500,
                color: "#171B1E",
                width: "20%",
              }}
            >
              MilStone Name *
            </p>
            <input
              style={{
                width: "30%",
                border: "1px solid #d7d7d7",
                outline: "none",
                height: "40px",
                padding: "15px",
              }}
              type="text"
              className="createQuiz-inputBar"
              id={topicId + courseId + lessonId + "quizName"}
              name="quizName"
              value={quizDetails.quizName}
              onChange={(event) => {
                handleInput(event);
                setCheckErrorInForm((prev) => ({ ...prev, quizName: "" }));
                if (!editable) {
                  let value = event.target.value.replaceAll(" ", "");
                  let time = new Date().getTime();

                  setQuizId(value + "_" + time);
                }
              }}
            />{" "}
            {checkErrorInForm.quizName == "error" && (
              <span
                style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
              >
                Enter Quiz Name
              </span>
            )}
          </div>

          <div className="createQuiz-row">
            <p
              className="createQuiz-inputLabel"
              style={{
                fontWeight: 500,
                color: "#171B1E",
                width: "20%",
              }}
            >
              Milstone instruction
            </p>
            <textarea
              style={{
                border: "1px solid #d7d7d7",
                width: "30%",
                minHeight: "100px",
                borderRadius: "6px",
                outline: "none",
                padding: "15px",
              }}
              type="text"
              className="createQuiz-textBar"
              id={topicId + courseId + lessonId + "instruction"}
              value={quizDetails.instruction}
              name="instruction"
              onChange={handleInput}
            />
          </div>

          {/* 
          <div className="course-builder-info">
              <p className="course-builder-inputLable">MileStone Image *</p>
              {imageUrl == "" ? (
                <div
                  style={{
                    width: "20vw",
                    height: "8em",
                    background: "#d5d5d5",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",
                  }}
                  onClick={onImageClick}
                >
                  Click to upload Image
                </div>
              ) : (
              


  <img
  style={{ width: "20vw", height: "8em", objectFit: "cover", borderRadius: "10px" }}
  onClick={onImageClick}
  src={imageUrl}
     ></img>
              )}
              <input
                ref={imageRef}
                style={{ display: "none" }}
                type="file"
                accept="image/png, image/gif, image/jpeg"
                className="course-builder-inputImg"
                id="courseImage"
                onChange={(e) => {
                  handleCourseImage(e);
                }}
              />
            
            </div>
          */}

          {/* <div className="createQuiz-row">
            <p className="createQuiz-inputLabel">Success Message</p>
            <input
              type="text"
              className="createQuiz-inputBar"
              id={topicId + courseId + lessonId + "successMessage"}
              value={quizDetails.successMessage}
              name="successMessage"
              onChange={handleInput}
            />
          </div> */}

          {/* <div className="createQuiz-row">
            <p className="createQuiz-inputLabel">Retake Message</p>
            <input
              type="text"
              className="createQuiz-inputBar"
              id={topicId + courseId + lessonId + "retakeMessage"}
              value={quizDetails.retakeMessage}
              name="retakeMessage"
              onChange={handleInput}
            />
          </div> */}

          {checkFailed && <p>Enter the Required Fields</p>}
          <div className="createQuiz-row">
            <button
              className="primary-btn"
              onClick={!editable ? addQuiz : updateQuiz}
            >
              Save
            </button>
          </div>
        </div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <ul
                id="quizList"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: "block",
                  overflow: "hidden",
                  width: "100%",
                  listStyleType: "none",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                {list.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        key={item.id}
                        className="quiz-dragable"
                      >
                        <Accordion>
                          <AccordionSummary
                            {...provided.dragHandleProps}
                            expandIcon={<ExpandMoreSvg />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                              backgroundColor: "#F17850",
                              color: "white",
                              borderRadius: "5px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <SvgVector />
                              <div className="quiz-accordion-heading">
                                {item.name}
                              </div>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="quiz-accordion-details-head">
                              <p>{item.name}</p>
                              <div
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "10px",
                                }}
                                onClick={() => del(item)}
                              >
                                <DeleteSVg size={28} />
                              </div>
                              <div onClick={() => edit(item)}>
                                <EditSvg size={28} />
                              </div>
                            </div>
                            <p>Associated course - </p>

                            {/* <CreateSubQuestion questionData={item.questionData} quizId={item.id} /> */}
                            <CreateSubMilestone
                              editQuizArr={editQuizArr}
                              courseId={courseId}
                              mileStoneId={item.id}
                              name={"Add Subjective Quiz"}
                            />
                            <CreateQuizMileStone
                              editQuizArr={editQuizArr}
                              courseId={courseId}
                              mileStoneId={item.id}
                              name={"add Objective quiz"}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </section>
    </>
  );
};

export default CreateSubjectiveQuiz;
