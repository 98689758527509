import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";
import { Timestamp } from "firebase/firestore";
const DocumentsOfUser = ({ uid, userDocument, courseId }) => {
  const [training, setTraining] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [arrLength, setArrLength] = useState(0);
  const [loading, setLoading] = useState(false);
  let [date, setDate] = useState("");
  let obj = [0];
  const [questionsArr, setQuestionsArr] = useState([0]);
  const [dat, setDat] = useState("");
  useEffect(() => {
    console.log();
    setDat(`${courseId}_agreementForm`);
  }, [userDocument]);
  console.log("UID", uid);
  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(arrLength / 5)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };

  const onpeNewWindo = (type) => {
    // console.log(userDocument);
    if (type == "Aadhar") {
      console.log(userDocument?.aadhar);
      window.open(userDocument?.aadhar);
    }
    if (type == "agreement") {
      window.open(userDocument?.[dat]);
    }
    if (type == "photo") {
      window.open(userDocument?.photo);
    }
  };
  return (
    <div className="admin-overdue-bottom" style={{ padding: "20px" }}>
      <div style={{ fontFamily: "Poppins" }}>
        <div
          style={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: 500 }}
        >
          {t("Document")}
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
          <div
            className="admin-overdue-mid-user-view-cnt"
            style={{ display: "flex", alignItems: "center", gap: "20px" }}
          >
            <div>{t("User Photo")} :</div>
            <div className="admin-overdue-mid-user-view-txt-dim">
              {userDocument.photo ? (
                <button
                  className="primary-btn"
                  onClick={(e) => {
                    onpeNewWindo("photo");
                  }}
                >
                  View User Photo
                </button>
              ) : (
                <button
                  className="primary-btn"
                  style={{
                    opacity: "0.6",
                  }}
                >
                  No User Photo
                </button>
              )}
            </div>
          </div>

          <div
            className="admin-overdue-mid-user-view-cnt"
            style={{ display: "flex", alignItems: "center", gap: "20px" }}
          >
            <div>{t("Agreement")} :</div>
            <div className="admin-overdue-mid-user-view-txt-dim">
              {userDocument?.[dat] ? (
                <button
                  className="primary-btn"
                  onClick={(e) => {
                    onpeNewWindo("agreement");
                  }}
                >
                  View Agreement
                </button>
              ) : (
                <button
                  className="primary-btn"
                  style={{
                    opacity: "0.6",
                  }}
                >
                  No Agreement
                </button>
              )}
            </div>
          </div>
          <div
            className="admin-overdue-mid-user-view-cnt"
            style={{ display: "flex", alignItems: "center", gap: "20px" }}
          >
            <div>{t("User Id")} :</div>
            <div className="admin-overdue-mid-user-view-txt-dim">
              {userDocument.aadhar ? (
                <button
                  className="primary-btn"
                  onClick={(e) => {
                    onpeNewWindo("Aadhar");
                  }}
                >
                  View User Id
                </button>
              ) : (
                <button
                  className="primary-btn"
                  style={{
                    opacity: "0.6",
                  }}
                >
                  No User Id
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentsOfUser;
