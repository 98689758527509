import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";
import { useSelector } from "react-redux";
import LoadingScreen from "../../BasicCourse/lodingScree";

const SubmileStoneTest = ({ uid, data, flag }) => {
  const { clrs } = useSelector((state) => state.createClr);
  const [training, setTraining] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [arrLength, setArrLength] = useState(0);

  const [loadingScreen, setLoadingScreen] = useState(false);

  const [milestoneName, setmilestoneName] = useState("");
  const [userStatusSelect, setUserStatusSelect] = useState({
    status: "",
    comment: "",
    result: "",
    marks: 0,
  });

  const userMileStoneStatusupdate = httpsCallable(
    functions,
    "userMileStoneStatusupdate"
  );
  const userMileStoneCheckedSattusChenged = httpsCallable(
    functions,
    "userMileStoneCheckedSattusChenged"
  );
  const mileStoneNotification = httpsCallable(
    functions,
    "mileStoneNotification"
  );
  const getOneMileStoneDataForCourse = httpsCallable(
    functions,
    "getOneMileStoneDataForCourse"
  );

  const [objData, setObjData] = useState([]);
  useState(() => {
    setObjData(data[0]?.subjectiveAns);

    getOneMileStoneDataForCourse({
      mileStoneId: data[0]?.milestoneId,
    }).then((res) => {
      setmilestoneName(res.data[0].mileStoneName);
    });
    setUserStatusSelect({
      status: data[0]?.userStatus,
      comment: data[0]?.rejectedMessageForUser,
      result: data[0]?.result,
      marks: data[0]?.marksOfSubjectiveTest,
    });
  }, [flag]);

  const clickhandler = (i) => {
    if (i < 0) return;
    if (
      i + 1 >
      Math.ceil(data.length > 0 && data[0]?.subjectiveAns?.length / 1)
    )
      return;
    //TODO: limit upper
    setCurrentPage(i);
  };

  const windoopen = (type, file) => {
    window.open(file);
  };

  const submitUserDetails = () => {
    setLoadingScreen(true);

    if (userStatusSelect.status == "SubjectiveTestPassed") {
      let arr = [uid];
      mileStoneNotification({
        body: `congratulations You have passed in the MileStone -${milestoneName} `,
        trigger: "MileStone Passed",

        userId: uid,

        params: {
          statusSms: userStatusSelect.comment,
          mileStoneId: data[0]?.milestoneId,
          groupId: data[0].groupId,
          status: "passed",
          courseId: data[0].courseId,
        },
      });
    }

    if (userStatusSelect.status == "SubjectiveTestFailed") {
      let arr = [uid];
      mileStoneNotification({
        body: `You have Failed in the MileStone -${milestoneName} `,
        trigger: "MileStone Failed",

        userId: uid,
        params: {
          rejectedMessageForUser: userStatusSelect.comment,
          mileStoneId: data[0]?.milestoneId,
          groupId: data[0].groupId,
          status: "failed",
          courseId: data[0].courseId,
          statusSms: userStatusSelect.comment,
        },
      });
    }

    let statusdata = userStatusSelect.status.includes("Failed")
      ? "failed"
      : "passed";

    userMileStoneStatusupdate({
      groupEnrollmentId: data[0].groupId,
      uid: uid,
      courseId: data[0].courseId,
      attemps: "first",
      userStatus: statusdata,
      result: userStatusSelect.result,
      marksOfSubjectiveTest: userStatusSelect.marks,
      rejectedMessageForUser: userStatusSelect.comment,
      mileStoneId: data[0]?.milestoneId,
    })
      .then((res) => {
        setLoadingScreen(false);
        alert("updated");
      })
      .catch((e) => {
        setLoadingScreen(false);
      })
      .then((res) => {
        setLoadingScreen(false);
        alert("updated");
      })
      .catch((e) => {
        setLoadingScreen(false);
      });
  };

  const updateFunction = (i, ckeck, item) => {
    console.log(ckeck);
    let newObj = { ...item };
    newObj.checked = ckeck;
    objData[i] = newObj;
    let arr = objData.map((e, id) => {
      if (i == id) {
        return newObj;
      } else {
        return e;
      }
    });
    setObjData(arr);
  };

  const updateIndataBase = () => {
    console.log(data);
    userMileStoneCheckedSattusChenged({
      subjectiveAns: objData,
      groupEnrollmentId: data[0].groupId,
      uid: uid,
      courseId: data[0].courseId,
      mileStoneId: data[0].milestoneId,
      attemps: "first",
    }).then((res) => {
      alert("updated");
    });
  };

  return (
    <div
      className="admin-overdue-bottom"
      style={{ padding: "20px", fontFamily: "Poppins" }}
    >
      <div className="admin-overdue-bottom-table">
        <LoadingScreen
          open={loadingScreen}
          onClose={() => setLoadingScreen(false)}
        />
        <div
          style={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: 500 }}
        >
          {t("Subjective Test View")}
        </div>
        <div style={{ display: "flex", gap: "20", alignItems: "center" }}>
          {objData?.map((item, id) => {
            return (
              <div>
                <div
                  style={{
                    background: id == currentPage ? "#fff" : "rgb(5,30,52)",
                    width: "50px",
                    fontSize: "20px",
                    height: "25px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={item.checked}
                    onChange={(e) => {
                      updateFunction(id, e.target.checked, item);
                    }}
                  />
                </div>
                <div
                  style={{
                    background: id == currentPage ? "#F4F4F4" : "rgb(5,30,52)",
                    width: "50px",
                    fontSize: "20px",
                    height: "25px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    style={{
                      background:
                        id == currentPage ? "#F4F4F4" : "rgb(5,30,52)",
                      width: "50px",
                      fontSize: "20px",
                      height: "25px",
                      color: "white",
                      border: "1px solid #000",
                      outline: "none",
                      borderRadius: "3px",
                      color: "#000",
                    }}
                  >
                    {id + 1}
                  </button>{" "}
                </div>
              </div>
            );
          })}
        </div>

        <div>
          {" "}
          <button
            onClick={() => {
              updateIndataBase();
            }}
            style={{ marginTop: "20px" }}
            className="primary-btn"
          >
            {" "}
            update your check status
          </button>
        </div>
        {data.length > 0 &&
          data[0]?.subjectiveAns?.map((item, id) => {
            if (id < 1 * currentPage) return;
            if (id >= 1 * (currentPage + 1)) return;
            return (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gridGap: "40px",
                }}
              >
                {item.questionType == "Video question" && (
                  <div>
                    <h1> Video Question</h1>
                    <video width="300" height="240" controls>
                      <source src={item.questionData} type="video/mp4" />
                      <source src={item.questionData} type="video/ogg" />
                      Your browser does not support the video tag.
                    </video>

                    <p>Date:20-20-2220</p>
                  </div>
                )}

                {item.questionType == "File Question" && (
                  <div style={{ marginTop: "5vh" }}>
                    <h1> File Question</h1>
                    <iframe src={item.questionData} title="pdf"></iframe>

                    <p>Date:20-20-2220</p>
                    <button
                      onClick={() => {
                        windoopen("file", item.questionData);
                      }}
                    >
                      view In a new Tab
                    </button>
                  </div>
                )}

                {item.questionType == "Text Question" && (
                  <div>
                    <h1> Text Question</h1>
                    <p style={{ margin: "10%" }}> {item.questionData}</p>

                    <p style={{ margin: "10%" }}>Date:20-20-2220</p>
                  </div>
                )}
                <div>
                  {item.ansType == "1" && (
                    <div>
                      <h1> Video Answer</h1>
                      <video width="300" height="240" controls>
                        <source src={item.ansData} type="video/mp4" />
                        <source src={item.ansData} type="video/ogg" />
                        Your browser does not support the video tag.
                      </video>

                      <p>Date:20-20-2220</p>
                    </div>
                  )}

                  {item.ansType == "2" && (
                    <div style={{ marginTop: "6vh" }}>
                      <h1> File Answer</h1>
                      <iframe src={item.ansData} title="pdf"></iframe>

                      <p>Date:20-20-2220</p>
                      <button
                        onClick={() => {
                          windoopen("file", item.ansData);
                        }}
                      >
                        view In a new Tab
                      </button>
                    </div>
                  )}

                  {item.ansType == "3" && (
                    <div>
                      <h1> Text Answer</h1>
                      <p style={{ margin: "10%" }}> {item.ansData}</p>

                      <p>Date:20-20-2220</p>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        {loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        {!training && !loading && (
          <div style={{ width: "100%", textAlign: "center", color: "#1e1e1e" }}>
            No Records found
          </div>
        )}
      </div>
      <div className="admin-overdue-bottom-pagination">
        <div className="admin-overdue-bottom-pagination-cnt">
          <div className="admin-overdue-bottom-pagination-cnt-item">
            <svg
              onClick={() => clickhandler(currentPage - 1)}
              width="auto"
              height="22"
              viewBox="0 0 14 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                fill="#717579"
              />
            </svg>
          </div>
          <div className="admin-overdue-bottom-pagination-cnt-item-btn">
            {currentPage + 1} of{" "}
            {data.length > 0 && data[0]?.subjectiveAns?.length
              ? Math.ceil(data.length > 0 && data[0]?.subjectiveAns?.length / 1)
              : 0}
          </div>
          <div className="admin-overdue-bottom-pagination-cnt-item">
            <svg
              onClick={() => clickhandler(currentPage + 1)}
              width="auto"
              height="20"
              viewBox="0 0 13 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                fill="#717579"
              />
            </svg>
          </div>
        </div>
      </div>

      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginTop: "15px",
          }}
        >
          <label> Overall marks : </label>
          <input
            style={{
              border: "2px solid gray",
              outline: "none",
              padding: "15px",
              borderRadius: "5px",
            }}
            type="Number"
            value={userStatusSelect.marks}
            onChange={(e) => {
              setUserStatusSelect({
                ...userStatusSelect,
                marks: e.target.value,
              });
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginTop: "15px",
          }}
        >
          <label> Status : </label>
          <select
            className="admin-course-main-top-select"
            value={userStatusSelect.status}
            onChange={(e) => {
              setUserStatusSelect({
                ...userStatusSelect,
                status: e.target.value,
              });
            }}
          >
            <option value="">select option </option>

            <option value="SubjectiveTestPassed">
              {" "}
              Subjective Test Passed
            </option>

            <option value="SubjectiveTestFailed">Subjective Test Failed</option>
          </select>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginTop: "15px",
          }}
        >
          <label> Result: </label>
          <select
            className="admin-course-main-top-select"
            value={userStatusSelect.result}
            onChange={(e) => {
              setUserStatusSelect({
                ...userStatusSelect,
                result: e.target.value,
              });
            }}
          >
            <option value="pass">Pass </option>
            <option value="fail">Fail </option>
          </select>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginTop: "15px",
          }}
        >
          <label> Comments: </label>
          <textarea
            value={userStatusSelect.comment}
            type="text"
            style={{
              border: "1px solid gray",
              borderRadius: "5px",
              marginTop: "15px",
              minHeight: "100px",
              minWidth: "300px",
              padding: "10px",
            }}
            onChange={(e) => {
              setUserStatusSelect({
                ...userStatusSelect,
                comment: e.target.value,
              });
            }}
          />
        </div>

        <div>
          <button
            onClick={() => {
              submitUserDetails();
            }}
            style={{ marginTop: "20px" }}
            className="primary-btn"
          >
            {" "}
            Update User details
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubmileStoneTest;
