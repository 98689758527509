import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getCookie, setCookie } from "../../../Cookies";
import { db, functions } from "../../../Firebase";
import classes from "../../styles/LoginpageStyle/LoginPage.module.css";
import loginLogo from "../../../utilities/images/digvidya.png";
import loginImg from "../../../utilities/images/Frame.png";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { httpsCallable } from "firebase/functions";

const { createTheme } = require("@mui/material");

const loginTheme = createTheme({
  typography: {
    fontFamily: ["Readex Pro", "sans-serif"].join(","),
  },
});

function LoginPage({ messagingToken }) {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const goToUserDashboard = () => navigate("/user/home");
  const goToAdminDashboard = () => navigate("/admin");
  const goToInstructorDashboard = () => navigate("/admin");
  const fetchUserProfile = httpsCallable(functions, "fetchUserProfile");
  // const [role, setRole] = useState("");
  const [user, setUser] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [userSetNotification, setUserSetNotification] = useState("");
  const [notification, setNotification] = useState("");
  const [loading, setLoading] = useState(false);

  ///========visibility password=======//
  const [showPassword, setShowPassword] = useState(false);

  const clearInputs = () => {
    setUserNameError("");
    setPassword("");
  };

  const clearErrors = () => {
    setUserNameError("");
    setPasswordError("");
  };

  ///if user is logged in, redirect to user dashboard
  useEffect(() => {
    if (getCookie("ROLE") === "admin") {
      goToAdminDashboard();
      return;
    }
  }, []);

  const handleLogin = () => {
    clearErrors();
    if (userName == "") {
      return setUserSetNotification("Please enter your username");
    } else {
      setUserSetNotification("");
    }
    if (password == "") {
      return setNotification("Please enter your password");
    } else {
      setNotification("");
    }
    setLoading(true);
    const auth = getAuth();
    signInWithEmailAndPassword(auth, userName, password)
      .then((userCredential) => {
        setLoading(false);
        const user = userCredential.user;
        //  saveToken({ token: messagingToken });
        // console.log(user)
        setCookie("UID", user.uid);
        console.log(user.uid);
        // db.collection("users").doc(user.uid).update({ invitationAccept: true });
        fetchUserProfile({ uid: user.uid }).then((result) => {
          console.log(result);
          const role = result.data.data.role;
          setCookie("ROLE", role);
          // setRole(role);
          const name = result.data.data.name;
          setCookie("NAME", name);

          // console.log("Role=" + role);
          if (role == "admin") goToAdminDashboard();
        });
        // db.collection("users")
        //   .doc(user.uid)
        //   .get()
        //   .then((user) => {

        //   });
      })
      .catch((err) => {
        setLoading(false);
        console.log("error", err.code);
        if (
          err.code == "auth/wrong-password" ||
          err.code == "auth/invalid-email" ||
          err.code == "auth/user-not-found"
        )
          return setNotification("Please enter correct credentials");
        else if (err.code == "auth/too-many-requests")
          return setNotification("Too many requests. Please try again later");
        switch (err.code) {
          case "auth/invalid-username":
          case "auth/user-disabled":
          case "auth/user-not-found":
            setUserNameError(err.message);
            break;
          case "auth/wrong-password":
            setPasswordError(err.message);
            break;
        }
      });
  };

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        goToHome();
      })
      .catch((error) => {
        // An error happened.
      });
    //   fire.auth().signOut();
  };

  const authListener = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        clearInputs();
        const uid = user.uid;
        console.log(user.accessToken);
        setUser(user);
      } else {
        setUser("");
      }
    });
  };

  useEffect(() => {
    authListener();
  }, []);

  return (
    <ThemeProvider theme={loginTheme}>
      <Box>
        <Container
          style={{ height: "100vh", gap: 40 }}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "40%",

              minHeight: "610px",
              maxHeight: "610px",
              padding: "0px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                height: "96px",
                width: "270px",
              }}
            >
              <img className={classes.logo} src={loginLogo} alt="" />
            </Box>
            <Box sx={{ p: 7, padding: "0px" }}>
              <Box>
                <Typography
                  variant="h3"
                  sx={{ color: "#1E3758", fontWeight: 700 }}
                >
                  Log in
                </Typography>
                <Typography sx={{ my: 2, color: "#1E3758", fontWeight: 400 }}>
                  Log in with your credentials provided by admin
                </Typography>
              </Box>
              <form action="">
                <Box>
                  <Typography
                    sx={{ fontWeight: "700", mb: 1, color: "##1E3758" }}
                  >
                    Enter Your Username
                  </Typography>
                  <div
                    style={{
                      width: "416px",
                      borderRadius: "6px",
                      padding: "15px",
                      border: "1px solid #1E3758",
                      outline: "none",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="text"
                      required
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      placeholder="name@domain.com"
                      style={{
                        width: "416px",
                        padding: "7px 15px",
                        border: "none",
                        outline: "none",
                      }}
                    />
                  </div>
                </Box>
                <Box>
                  {userSetNotification && (
                    <span style={{ color: "red", marginTop: "20px" }}>
                      {userSetNotification}
                    </span>
                  )}
                </Box>
                <Box sx={{ mt: 3, marginBottom: "2px" }}>
                  <Typography
                    sx={{ fontWeight: "700", mb: 1, color: "#1E3758" }}
                  >
                    Enter Your Password
                  </Typography>
                  <div
                    style={{
                      width: "416px",
                      borderRadius: "6px",
                      padding: "10px 15px",
                      border: "1px solid #1E3758",
                      outline: "none",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      style={{
                        border: "none",
                        outline: "none",
                        width: "416px",
                      }}
                    />
                    <div onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? (
                        <VisibilityIcon sx={{ color: "#1E3758" }} />
                      ) : (
                        <VisibilityOffIcon sx={{ color: "#1E3758" }} />
                      )}
                    </div>
                  </div>
                </Box>
                <Box>
                  {notification && (
                    <span style={{ color: "red", marginTop: "20px" }}>
                      {notification}
                    </span>
                  )}
                </Box>

                <Box sx={{ mt: 3, display: "flex", alignItems: "center" }}>
                  <FormControlLabel
                    control={
                      <Checkbox color="success" sx={{ color: "#1E3758" }} />
                    }
                  />
                  <label
                    style={{
                      fontWeight: 700,
                      color: "#1E3758",
                      fontSize: "16px",
                      marginLeft: "-20px",
                      fontFamily: "Readex Pro",
                    }}
                  >
                    Keep me logged in
                  </label>
                </Box>
                <Box sx={{ mt: 3 }}>
                  <Button
                    onClick={handleLogin}
                    style={{
                      background: "#D55628",
                      color: "#fff",
                      boxShadow: "0px 20px 15px rgba(213, 86, 40, 0.15)",
                    }}
                    sx={{
                      textTransform: "none",
                      width: "416px",
                      py: 1,

                      fontSize: "16px",
                      fontWeight: 700,
                      background: "#D55628",

                      borderRadius: "12px",
                    }}
                  >
                    {loading ? "Loging in ..." : "Log in"}
                  </Button>
                </Box>

                <Box sx={{ mt: 3 }}>
                  <Link to="/forgot-password">
                    <Typography
                      style={{ color: "#1E3758", fontWeight: 600 }}
                      sx={{ textAlign: "center" }}
                    >
                      Forgot Password?
                    </Typography>
                  </Link>
                </Box>
              </form>
            </Box>
          </Box>
          <Box
            sx={{
              width: "60%",

              borderRadius: "10px",
              minHeight: "610px",
              maxHeight: "610px",
            }}
          >
            <div style={{ maxHeight: "610px" }}>
              <img
                width="100%"
                height="605px"
                style={{ borderRadius: "10px", marginLeft: "2vw" }}
                src={loginImg}
                alt=""
              />{" "}
            </div>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default LoginPage;

/* <div className="loginpage">
  <div className="loginpage-main">
    <div className="loginpage-main-left">
      <div className="loginpage-main-left-cnt">
        <div className="loginpage-main-left-cnt-items">
          <div className="loginpage-main-left-cnt-items-text-1">Log in</div>
          <div className="loginpage-main-left-cnt-items-text-2">
            Log in with your credentials provided by admin
          </div>
          <div className="loginpage-main-left-cnt-items-text-3">
            Enter Your Username
          </div>
          <div className="loginpage-main-left-cnt-items-input">
            <input type="text" placeholder="name@domain.com" required
              value={userName}
              onChange={(e) => setUserName(e.target.value)} />
            <p className="errorMsg">{userNameError}</p>
          </div>
          <div className="loginpage-main-left-cnt-items-text-4">
            Enter Your Password
          </div>
          <div className="loginpage-main-left-cnt-items-input">
            <input type="password" placeholder="At least 8 charactor" required
              value={password}
              onChange={(e) => setPassword(e.target.value)} />
            <p className="errorMsg"> {passwordError}</p>
          </div>
          <div className="loginpage-main-left-cnt-items-text-5">
            <FormControlLabel
              control={<Checkbox color="success" />}
              label="Keep me logged in"
            />
          </div>
          <div className="loginpage-main-left-cnt-items-login-btn">
            <button onClick={handleLogin}>Log in</button>
          </div>
          <div className="loginpage-main-left-cnt-items-text-6">
            Forgot Password?
          </div>
        </div>
      </div>
    </div>
    <div className="loginpage-main-right">
      <div className="loginpage-main-right-cnt">
        <img src={loginImg} alt="loading" />
      </div>
    </div>
  </div>
</div> */
