import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../Component/NavbarTop";
import { Timestamp } from "firebase/firestore";
import Sidebar from "../Component/Sidebar";
// import { dataFromApi } from "./Dashboard/Data";
import { functions } from "../../Firebase";
import "./CourseReport.css";
import { borderRadius } from "@mui/system";
function CourseReport() {
  const getReports = httpsCallable(functions, "getReports");
  const { clrs } = useSelector((state) => state.createClr);
  const [arr, setArr] = useState();
  const [pageSize, setPageSize] = useState(5);
  const [pageNo, setPageNo] = useState();
  const [showLoading, setLoading] = useState(true);
  const [reportsArr, setReportsArr] = useState([]);
  const [curPage, setCurPage] = useState();
  const [displayNotify, setDisplayNotify] = useState(false);
  const [selectToDate, setSelectToDate] = useState("");
  const [selectFromDate, setSelectFromDate] = useState("");
  const navigate = useNavigate();
  const [dataFromApi, setDataFromApi] = useState([]);
  const [arrLength, setArrLength] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  useEffect(() => {
    getReports({}).then((res) => {
      setArr(res.data);
      setArrLength(res.data.length);
      setDataFromApi(res.data);
      // console.log(res.data);
      setReportsArr(res.data);
      setCurPage(0);

      setLoading(false);
    });
  }, []);

  const filterButtenFunction = (type) => {
    if (type == "filter") {
      var myDate = selectFromDate;
      myDate = myDate.split("-");

      var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]);

      let secondsData = Timestamp.fromDate(newDate).seconds;

      var myDateToselect = selectToDate;
      myDateToselect = myDateToselect.split("-");

      var newDate = new Date(
        myDateToselect[0],
        myDateToselect[1] - 1,
        myDateToselect[2]
      );

      let secondsDataTodate = Timestamp.fromDate(newDate).seconds;

      // console.log(secondsData, secondsDataTodate);

      //  console.log(reportsArr);
      let tempArr = reportsArr.filter((item) => {
        let milisecon = item.createdOn._seconds * 1000;
        let year = new Date(milisecon).getFullYear();
        let months = new Date(milisecon).getMonth();
        let days = new Date(milisecon).getDate();
        let secondsData = "";
        if (months < 9) {
          months = Number(months) + 1;
          months = "0" + months;
        } else {
          months = Number(months) + 1;
        }
        if (days <= 9) {
          days = "0" + days;
        } else {
          days = Number(days);
        }
        secondsData = `${year}-${months}-${days}`;
        // console.log(fulldate,selestedDate)

        let miliseconTodate = item.createdOn._seconds * 1000;
        let yearToDate = new Date(miliseconTodate).getFullYear();
        let monthsTodate = new Date(miliseconTodate).getMonth();
        let daysTodate = new Date(miliseconTodate).getDate();
        let secondsDataTodate = "";
        if (monthsTodate < 9) {
          monthsTodate = Number(monthsTodate) + 1;
          monthsTodate = "0" + monthsTodate;
        } else {
          monthsTodate = Number(monthsTodate) + 1;
        }
        if (daysTodate <= 9) {
          daysTodate = "0" + daysTodate;
        } else {
          daysTodate = Number(daysTodate);
        }
        secondsDataTodate = `${yearToDate}-${monthsTodate}-${daysTodate}`;

        if (
          secondsData >= selectFromDate &&
          secondsDataTodate <= selectToDate
        ) {
          return item;
        }
      });

      // console.log(tempArr);
      setArrLength(tempArr.length);
      setCurrentPage(0);
      setArr(tempArr);
    } else if (type == "clear") {
      setArrLength(reportsArr.length);
      setCurrentPage(0);
      setSelectToDate("");
      setSelectFromDate("");
      setArr(reportsArr);
    }
  };
  const handleDownload = (e, id) => {
    if (e.target.value == "pdf") {
      window.open("https://" + arr[id].downloadPDF, "_blank");
    }
    if (e.target.value == "csv") {
      window.open(arr[id].downloadLink, "_blank");
    }
    if (e.target.value == "excel") {
      window.open("https://" + arr[id].downloadExcel, "_blank");
    }
  };

  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(arrLength / pageSize)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };
  const NotifyClick = () => {
    setDisplayNotify(!displayNotify);
  };

  const moveTogeneratePage = (type) => {
    if (type == "basic") {
      navigate("/admin/course-reports/GenerateReportPage");
    } else {
      navigate("/admin/course-reports/GenerateEntranceReportPage");
    }
  };
  return (
    <div className="section" style={{ backgroundColor: "#e5e5e5" }}>
      <Navbar page={t("Reports")} />

      <div
        className="createcourse-main"
        style={{ gridTemplateColumns: "19vw 1fr" }}
      >
        <Sidebar page={"reports"} hidepage={false} />
        <div className="admin-overdue">
          <div
            className="admin-overdue-top"
            style={{ display: "flex", marginLeft: "40.5vw", marginTop: "1vh" }}
          >
            <div className="admin-overdue-top-item"></div>

            <div
              className="admin-dashboard-main-mid-item-last-filter"
              style={{ marginLeft: "1vw", marginBottom: "1vw" }}
            >
              <button
                className="primary-btn"
                onClick={() => {
                  moveTogeneratePage("entrance");
                }}
              >
                Entrance Course Reports
              </button>
            </div>
            <div
              className="admin-dashboard-main-mid-item-last-filter"
              style={{ marginLeft: "1vw", marginBottom: "1vw" }}
            >
              <button
                className="primary-btn"
                onClick={() => {
                  moveTogeneratePage("basic");
                }}
              >
                Basic Course Reports
              </button>
            </div>
          </div>
          <div className="admin-overdue-mid">
            <div className="admin-overdue-mid-left">
              <div className="admin-overdue-mid-left-txt"> {t("FromDate")}</div>
              <div className="admin-overdue-mid-left-input">
                <input
                  type="date"
                  value={selectFromDate}
                  onChange={(e) => {
                    setSelectFromDate(e.target.value);
                  }}
                  placeholder="Select Date"
                />
              </div>
            </div>
            <div className="admin-overdue-mid-left">
              <div className="admin-overdue-mid-left-txt">{t("ToDate")}</div>
              <div className="admin-overdue-mid-left-input">
                <input
                  type="date"
                  value={selectToDate}
                  onChange={(e) => {
                    setSelectToDate(e.target.value);
                  }}
                  placeholder="Select Date"
                />
              </div>
            </div>
            <div
              className="admin-dashboard-main-mid-item-last-filter"
              style={{ marginTop: "2.5vw" }}
            >
              {" "}
              <div className="admin-dashboard-main-top-last-filter">
                <button
                  className="primary-btn"
                  onClick={() => {
                    filterButtenFunction("filter");
                  }}
                >
                  Filter
                </button>
              </div>
            </div>
            <div
              className="admin-dashboard-main-mid-item-last-filter"
              style={{ marginTop: "2.5vw" }}
            >
              {" "}
              <div className="admin-dashboard-main-top-last-filter">
                <button
                  className="clear-btn"
                  onClick={() => {
                    filterButtenFunction("clear");
                  }}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
          <div className="admin-overdue-bottom">
            <div className="admin-overdue-bottom-table">
              <div
                className="admin-overdue-bottom-table-cnt"
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                }}
              >
                <div className="admin-overdue-bottom-table-head">Sr.No</div>
                <div className="admin-overdue-bottom-table-head">Report Id</div>
                <div className="admin-overdue-bottom-table-head">
                  Report generated By
                </div>
                <div className="admin-overdue-bottom-table-head">
                  Report Type
                </div>
                <div className="admin-overdue-bottom-table-head">
                  Date Of Generation
                </div>
                <div className="admin-overdue-bottom-table-head">
                  Download File
                </div>
              </div>
              {showLoading && (
                <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td">
                    <div className="spinner-container">
                      <div className="loading-spinner"></div>
                    </div>
                  </div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div
                    className="admin-overdue-bottom-table-td"
                    style={{ color: "#004577" }}
                  ></div>
                </div>
              )}
              {arr &&
                arr?.map((item, id) => {
                  if (id < pageSize * currentPage) return;
                  if (id >= pageSize * (currentPage + 1)) return;

                  let creationDate = Timestamp.fromMillis(
                    item.createdOn._seconds * 1000
                  )
                    .toDate()
                    .toDateString();

                  return (
                    <div
                      className="admin-overdue-bottom-table-cnt"
                      key={id}
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                      }}
                    >
                      <div className="admin-overdue-bottom-table-td">
                        {id + 1}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item.filename}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item.createdBy}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item.reportType}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {creationDate}
                      </div>

                      <select
                        // className="admin-overdue-bottom-table-td"
                        onChange={(e) => handleDownload(e, id)}
                        style={{ height: "50%" }}
                        value="default"
                      >
                        <option value={"default"}>Download</option>
                        <option value={"pdf"}>PDF</option>
                        <option value={"excel"}>Excel</option>
                        <option value={"csv"}>CSV</option>
                      </select>
                    </div>
                  );
                })}
            </div>
            <div className="admin-overdue-bottom-pagination">
              <div className="admin-overdue-bottom-pagination-cnt">
                <div className="admin-overdue-bottom-pagination-cnt-item">
                  <svg
                    onClick={() => clickhandler(currentPage - 1)}
                    width="auto"
                    height="22"
                    viewBox="0 0 14 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div className="admin-overdue-bottom-pagination-cnt-item-btn">
                  {currentPage + 1} of {Math.ceil(arrLength / pageSize)}
                </div>
                <div
                  style={{ marginRight: "19vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <svg
                    onClick={() => clickhandler(currentPage + 1)}
                    width="auto"
                    height="20"
                    viewBox="0 0 13 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div
                  style={{ display: "flex", marginRight: "-30vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <label className="admin-row">{t("Show")}</label>
                  <select
                    style={{
                      width: "4vw",
                      margin: "0.5vw",
                      marginBottom: "1.5vw",
                      height: "1.5vw",
                    }}
                    value={pageSize}
                    onChange={(e) => setPageSize(e.target.value)}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                  </select>
                  <label className="admin-row"> {t("PagePerItem")}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ height: "100%", width: "19vw", backgroundColor: "white" }}
        ></div>
        <div className="courselist-main-cnt-back">
          <div className="courselist-main-cnt-back-btn">
            <Link to={"/admin"}>
              <button className="secondary-btn">Back</button>{" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseReport;
