import React, { useEffect, useState } from "react";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  uploadBytes,
  deleteObject,
} from "@firebase/storage";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  increment,
} from "firebase/firestore";
import { db, functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { Timestamp } from "firebase/firestore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import "./createQuiz.css";
import { ExpandMoreSharp } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdEdit, MdDelete } from "react-icons/md";
import { ExpandMoreSvg } from "./icons";
import DeleteLesson from "./lessonDeletePopUp";
import LoadingScreen from "../BasicCourse/lodingScree";
import CreateQuestionMcq from "./createMcqquestion";
import CreateQuestionTextPara from "./TextParaGraf";
import CreateQuestionVideoPara from "./videoParaQuestion";
const CreateQuestion = ({
  editQuizArr,
  questionData,
  quizId,
  questionSetId,
  type,
  mileStoneQuizId,
  mileStoneId,
}) => {
  const storage = getStorage();

  const setTimeForObjective = httpsCallable(functions, "setTimeForObjective");
  const addQuestionObjectiveOrderMilStone = httpsCallable(
    functions,
    "addQuestionObjectiveOrderMilStone"
  );
  const getAllQuestionDataForMileStoneObjQuiz = httpsCallable(
    functions,
    "getAllQuestionDataForMileStoneObjQuiz"
  );
  const getAllQuestionDataForQuiz = httpsCallable(
    functions,
    "getAllQuestionDataForQuiz"
  );
  const createQuestion = httpsCallable(functions, "createQuestion");
  const addQuestionObjectiveOrder = httpsCallable(
    functions,
    "addQuestionObjectiveOrder"
  );

  const [fetchDataFromApi, setfetchDataFromApi] = useState(0);
  const fetchQuestionSetCotegory = httpsCallable(
    functions,
    "fetchQuestionSetCotegory"
  );
  //addQuestionObjectiveOrder
  const deleteObjQuestion = httpsCallable(functions, "deleteObjQuestion");
  const deleteMileStoneSubQuestion = httpsCallable(
    functions,
    "deleteMileStoneSubQuestion"
  );
  const deleteMileStoneObjectiveQuestion = httpsCallable(
    functions,
    "deleteMileStoneObjectiveQuestion"
  );
  // added by Himanshu
  const [deleteQuestionId, setDeleteQuestionId] = useState("");
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);
  const [questionCategory, setquestionCategory] = useState("");

  const [saveAndRefreshQuestion, setSaveAndRefreshQuestion] = useState("");

  const [flagForSeconstEdit, setflagForSeconstEdit] = useState(0);

  const [questionDataFromApi, setQuestionDataFromApi] = useState({});

  const [falgForEdit, setFlagForEdit] = useState(false);

  const [loadingScreen, setLoadingScreen] = useState(false);
  const [questionName, setQuestionName] = useState("");
  const [questionId, setQuestionId] = useState("");

  const initialList = [];
  const [list, setList] = useState(initialList);

  const [picMCQ, setPicMCQ] = useState({
    url: "",
    base64: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    answer: 0,
  });

  //
  const [cateGoryArr, setCategoryArr] = useState([]);

  useEffect(() => {
    // console.log(type, editQuizArr);

    fetchQuestionSetCotegory().then((res) => {
      // setquestionCategory(res.data)
      setCategoryArr(res.data);
    });
    if (!editQuizArr) return;

    setLoadingScreen(true);
    if (type == "mileStone") {
      // console.log(mileStoneQuizId, mileStoneId);
      getAllQuestionDataForMileStoneObjQuiz({ instructionId: mileStoneQuizId })
        .then((res) => {
          setLoadingScreen(false);
          //console.log(res.data);
          for (let i = 0; i < res.data?.length; i++) {
            // console.log(res.data);
            setList((prev) =>
              prev.concat({
                data: res.data[i]?.data,
                id: res.data[i]?.questionId,
                name: res.data[i]?.questionName,
                type: res.data[i]?.questionType,
                questionCategory: res.data[i]?.questionCategory,
                questionTime: res.data[i]?.questionTime,
              })
            );
          }
        })
        .catch((e) => {
          setLoadingScreen(false);
        });
    }
    if (type == "questionSet") {
      ///console.log(quizId);
      getAllQuestionDataForQuiz({ instructionId: quizId })
        .then((res) => {
          setLoadingScreen(false);
          for (let i = 0; i < res.data.length; i++) {
            //  console.log(res.data);
            setList((prev) =>
              prev.concat({
                data: res.data[i]?.data,
                id: res.data[i]?.questionId,
                name: res.data[i]?.questionName,
                type: res.data[i]?.questionType,
                questionCategory: res.data[i]?.questionCategory,
                questionTime: res.data[i]?.questionTime,
              })
            );
          }
        })
        .catch((e) => {
          setLoadingScreen(false);
        });
    }
  }, []);

  useEffect(() => {
    //console.log(type, editQuizArr, fetchDataFromApi);

    if (fetchDataFromApi == 0) {
      return;
    }
    setList([]);
    fetchQuestionSetCotegory().then((res) => {
      // setquestionCategory(res.data)
      setCategoryArr(res.data);
    });

    setLoadingScreen(true);
    if (type == "mileStone") {
      // console.log(mileStoneQuizId, mileStoneId);
      getAllQuestionDataForMileStoneObjQuiz({ instructionId: mileStoneQuizId })
        .then((res) => {
          setLoadingScreen(false);
          // console.log(res.data);
          for (let i = 0; i < res.data?.length; i++) {
            //console.log(res.data);
            setList((prev) =>
              prev.concat({
                data: res.data[i]?.data,
                id: res.data[i]?.questionId,
                name: res.data[i]?.questionName,
                type: res.data[i]?.questionType,
                questionCategory: res.data[i]?.questionCategory,
                questionTime: res.data[i]?.questionTime,
              })
            );
          }
        })
        .catch((e) => {
          setLoadingScreen(false);
        });
    }
    if (type == "questionSet") {
      //console.log(quizId);
      getAllQuestionDataForQuiz({ instructionId: quizId })
        .then((res) => {
          setLoadingScreen(false);
          let total = 0;
          for (let i = 0; i < res.data.length; i++) {
            //  console.log(res.data);

            total = total + Number(res.data[i]?.questionTime);
            setList((prev) =>
              prev.concat({
                data: res.data[i]?.data,
                id: res.data[i]?.questionId,
                name: res.data[i]?.questionName,
                type: res.data[i]?.questionType,
                questionCategory: res.data[i]?.questionCategory,
                questionTime: res.data[i]?.questionTime,
              })
            );
          }

          if (total > 0) {
            // console.log(total);

            if (quizId) {
              setTimeForObjective({
                instructionForObjId: quizId,

                quizTime: total,
              });
            }
          }

          saveOrder(res.data);
        })
        .catch((e) => {
          setLoadingScreen(false);
        });
    }
  }, [fetchDataFromApi]);

  //console.log(fetchDataFromApi);
  const [maxWordLimit, setmaxWordLimit] = useState("");
  const [ansType, setansType] = useState("");
  const [qusTime, setqusTime] = useState();
  const [qusMode, setqusMode] = useState("");
  const [qustype, setqustype] = useState("MCQ");
  const [qus, setqus] = useState("");
  const [qusInstruction, setqusInstruction] = useState("test");
  const [PointorScore, setPointorScore] = useState("");

  const [paragrafQuestion, setPragrafQuestion] = useState("");

  const [paraGrafData, setParaGrafData] = useState([]);
  const [extraArrForParaGraf, setExtraArrForParaGraf] = useState([]);

  const [editFlagForParaGraf, setEditFlagForParaGraf] = useState(false);

  const [questionMarks, setQuestionMarks] = useState(0);
  const [qus5, setqus5] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4: "",
  });
  const [qus5Answer, setqus5Answer] = useState("");
  const [qus2, setqus2] = useState({ word: "", correctImage: "" });
  const [formCheck, setFormCheck] = useState("");
  function submit(e) {
    e.preventDefault();
  }

  const ParagraphQuestionFunction = () => {
    if (qus5Answer == "") {
      alert("select ans");
      return;
    }

    let setoptionFromuser;
    if (qus5Answer == "Option 1") {
      let optionsFromUser = [
        { iscorrect: true, option: qus5.option1 },
        { iscorrect: false, option: qus5.option2 },
        { iscorrect: false, option: qus5.option3 },
        { iscorrect: false, option: qus5.option4 },
      ];
      setoptionFromuser = optionsFromUser;
    } else if (qus5Answer == "Option 2") {
      let optionsFromUser = [
        { iscorrect: false, option: qus5.option1 },
        { iscorrect: true, option: qus5.option2 },
        { iscorrect: false, option: qus5.option3 },
        { iscorrect: false, option: qus5.option4 },
      ];
      setoptionFromuser = optionsFromUser;
    } else if (qus5Answer == "Option 3") {
      let optionsFromUser = [
        { iscorrect: false, option: qus5.option1 },
        { iscorrect: false, option: qus5.option2 },
        { iscorrect: true, option: qus5.option3 },
        { iscorrect: false, option: qus5.option4 },
      ];
      setoptionFromuser = optionsFromUser;
    } else if (qus5Answer == "Option 4") {
      let optionsFromUser = [
        { iscorrect: false, option: qus5.option1 },
        { iscorrect: false, option: qus5.option2 },
        { iscorrect: false, option: qus5.option3 },
        { iscorrect: true, option: qus5.option4 },
      ];
      setoptionFromuser = optionsFromUser;
    }
    let questionDatafromUser = {
      options: setoptionFromuser,
      question: qus,
      questionInstruction: qusInstruction,
    };

    let teampArr = [];
    teampArr[0] = qus;

    for (let i = 1; i < extraArrForParaGraf.length; i++) {
      if (editFlagForParaGraf == i) {
        teampArr[editFlagForParaGraf] = {
          question: paragrafQuestion,
          options: setoptionFromuser,
          questionMarks: questionMarks,
        };
      } else {
        teampArr.push(extraArrForParaGraf[i]);

        if (
          i == extraArrForParaGraf.length - 1 &&
          editFlagForParaGraf == false
        ) {
          teampArr.push({
            question: paragrafQuestion,
            options: setoptionFromuser,
            questionMarks: questionMarks,
          });
        }
      }
    }
    if (extraArrForParaGraf.length == 0) {
      teampArr.push({
        question: paragrafQuestion,
        options: setoptionFromuser,
        questionMarks: questionMarks,
      });
    }

    //console.log(setoptionFromuser)

    setExtraArrForParaGraf(teampArr);
    //console.log(teampArr);
    setPragrafQuestion("");
    setqus5({
      option1: "",
      option2: "",
      option3: "",
      option4: "",
    });
    setQuestionMarks(0);
    setEditFlagForParaGraf(false);
  };

  const onpeFormForSelectedQuestion = (ele, i) => {
    setEditFlagForParaGraf(i);
    // console.log(ele);
    setPragrafQuestion(ele.question);
    setQuestionMarks(ele.questionMarks);

    let obj = {};
    ele.options.map((e, i) => {
      if (i == 0) {
        obj["option1"] = e.option;
        if (e.iscorrect) {
          setqus5Answer("Option 1");
        }
      }
      if (i == 1) {
        obj["option2"] = e.option;
        if (e.iscorrect) {
          setqus5Answer("Option 2");
        }
      }
      if (i == 2) {
        obj["option3"] = e.option;
        if (e.iscorrect) {
          setqus5Answer("Option 3");
        }
      }
      if (i == 3) {
        obj["option4"] = e.option;
        if (e.iscorrect) {
          setqus5Answer("Option 4");
        }
      }
    });
    setqus5(obj);
  };

  function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].questionId);
    }

    if (type == "mileStone") {
      addQuestionObjectiveOrderMilStone({
        quizId: mileStoneQuizId,
        questionsArr: orderList,
        edit: true,
      });
    } else if (type == "questionSet") {
      addQuestionObjectiveOrder({
        instructionForObjId: quizId,
        questionsArr: orderList,
        edit: true,
      });
    }
  }

  const makeFormDefault = () => {
    setPragrafQuestion("");
    setqus5({
      option1: "",
      option2: "",
      option3: "",
      option4: "",
    });
    setQuestionMarks(0);
    setEditFlagForParaGraf(false);
  };

  const [checkErrorInForm, setCheckErrorInForm] = useState({
    questionName: "",
    questionType: "",
    questionCategory: "",
    data: [],
    qus5Answer: "",
    qus: "",
    maxFileSize: "",
    questionMarks: "",
    maxWordLimit: "",
    qusTime: "",
    base64Agreement: "",
    agreementUrl: "",
    ansType: "",
    uploadesVideoUrl: "",
    extraArrForParaGraf: "",
  });

  const formCheckFunction = () => {
    if (questionName == "") {
      setCheckErrorInForm((prev) => ({ ...prev, questionName: "error" }));
      return "Question Name";
    }
    if (qus == "") {
      setCheckErrorInForm((prev) => ({ ...prev, qus: "error" }));
      return "qus Name";
    }

    // if (qusInstruction == "") {
    //   setCheckErrorInForm((prev)=>({...prev,qusInstruction:"error"}))
    //   return "qus Name"
    // };
    if (questionCategory == "") {
      setCheckErrorInForm((prev) => ({ ...prev, questionCategory: "error" }));
      return "qus questionCategory";
    }

    if (qustype == "MCQ") {
      if (questionMarks == "") {
        setCheckErrorInForm((prev) => ({ ...prev, questionMarks: "error" }));
        return "questionMarks Name";
      }

      if (qus5Answer == "") {
        setCheckErrorInForm((prev) => ({ ...prev, qus5Answer: "error" }));
        return "questionMarks Name";
      }
    }
    if (qustype == "paragraph") {
      if (extraArrForParaGraf.length < 2) {
        alert("add min one question");
        return "addminone";
      }
    }

    return "alliswell";
  };

  const storedDataOnFirebase = async () => {
    let check = formCheckFunction();
    if (check != "alliswell") {
      setFormCheck("check");
      return;
    }

    let nsn = questionName.replaceAll(" ", "");

    let dat = /[^A-Za-z0-9]/.test(nsn);

    if (!dat) {
    } else {
      alert("you can not use any special characters");
      return;
    }

    setFormCheck("");
    let questionTypeEnum;
    let managequestionData;

    if (qustype == "MCQ") {
      let setoptionFromuser;
      if (qus5Answer == "Option 1") {
        let optionsFromUser = [
          { iscorrect: true, option: qus5.option1 },
          { iscorrect: false, option: qus5.option2 },
          { iscorrect: false, option: qus5.option3 },
          { iscorrect: false, option: qus5.option4 },
        ];
        setoptionFromuser = optionsFromUser;
      } else if (qus5Answer == "Option 2") {
        let optionsFromUser = [
          { iscorrect: false, option: qus5.option1 },
          { iscorrect: true, option: qus5.option2 },
          { iscorrect: false, option: qus5.option3 },
          { iscorrect: false, option: qus5.option4 },
        ];
        setoptionFromuser = optionsFromUser;
      } else if (qus5Answer == "Option 3") {
        let optionsFromUser = [
          { iscorrect: false, option: qus5.option1 },
          { iscorrect: false, option: qus5.option2 },
          { iscorrect: true, option: qus5.option3 },
          { iscorrect: false, option: qus5.option4 },
        ];
        setoptionFromuser = optionsFromUser;
      } else if (qus5Answer == "Option 4") {
        let optionsFromUser = [
          { iscorrect: false, option: qus5.option1 },
          { iscorrect: false, option: qus5.option2 },
          { iscorrect: false, option: qus5.option3 },
          { iscorrect: true, option: qus5.option4 },
        ];
        setoptionFromuser = optionsFromUser;
      }
      let questionDatafromUser = {
        options: setoptionFromuser,
        question: qus,
        questionInstruction: qusInstruction,
        questionMarks: questionMarks,
      };
      managequestionData = questionDatafromUser;

      questionTypeEnum = 5;
    }

    createQuestion({
      questionId: questionId,
      questionName: questionName,
      questionTime: qusTime,
      questionType: qustype,
      created: new Date().toString(),
      data: managequestionData ? managequestionData : extraArrForParaGraf,
      associateInstructionId: quizId,
      questionSet: questionSetId,
      questionCategory: questionCategory,
      maxWordLimit: maxWordLimit,
      ansType: ansType,
      mileStoneQuizId: mileStoneQuizId,
      mileStoneId: mileStoneId,
    });

    alert("data saved successfully");
    const newList = list.concat({
      id: questionId,
      name: questionName,
      type: qustype,
      questionTime: qusTime,
      data: managequestionData ? managequestionData : extraArrForParaGraf,

      associateInstructionId: quizId,
      questionSet: questionSetId,

      mileStoneQuizId: mileStoneQuizId,
      mileStoneId: mileStoneId,

      questionCategory: questionCategory,
    });
    if (!falgForEdit) {
      let total = 0;
      for (let i = 0; i < newList.length; i++) {
        total = total + Number(newList[i].questionTime);
      }

      if (total > 0) {
        // console.log(total);

        if (quizId) {
          setTimeForObjective({
            instructionForObjId: quizId,

            quizTime: total,
          });
        }
      }

      setList(newList);
      saveOrder(newList);
    } else {
      for (let i = 0; i < list.length; i++) {
        if (questionId === list[i].id) {
          list[i] = {
            id: questionId,
            name: questionName,
            type: qustype,
            questionTime: qusTime,

            data: managequestionData ? managequestionData : extraArrForParaGraf,

            associateInstructionId: quizId,
            questionSet: questionSetId,

            mileStoneQuizId: mileStoneQuizId,
            mileStoneId: mileStoneId,

            questionCategory: questionCategory,
          };
        }
      }

      let total = 0;
      for (let i = 0; i < list.length; i++) {
        total = total + Number(list[i].questionTime);
      }

      if (total > 0) {
        //console.log(total);

        if (quizId) {
          setTimeForObjective({
            instructionForObjId: quizId,

            quizTime: total,
          });
        }
      }

      setList(list);
      setFlagForEdit(false);
    }

    setQuestionId("");
    setQuestionName("");
    setqusInstruction("");
    setqus("");
    setqus5Answer("");
    setqusTime(0);

    setqus5({
      option1: "",
      option2: "",
      option3: "",
      option4: "",
    });
    setQuestionMarks(0);
    toggleQuestionForm();
  };

  const toggleQuestionForm = (e) => {
    // setQuestionDataFromApi({})
    // setQuestionId("");
    // setQuestionName("");
    // setqusInstruction("");
    // setqus("");
    // setqus5Answer("");
    // setqusTime(0);

    // setqus5({
    //   option1: "",
    //   option2: "",
    //   option3: "",
    //   option4: "",
    // });
    // setQuestionMarks(0);

    document.getElementById(
      quizId ? quizId : mileStoneQuizId + "questionForm"
    ).style.display =
      document.getElementById(
        quizId ? quizId : mileStoneQuizId + "questionForm"
      ).style.display == "none"
        ? "block"
        : "none";
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    saveOrder(items);
  }

  function edit(lesson) {
    // console.log(lesson);
    setFlagForEdit(true);
    setQuestionId(lesson.id);
    setQuestionName(lesson?.name);

    setflagForSeconstEdit(flagForSeconstEdit + 1);
    setQuestionDataFromApi(lesson);
    setqusInstruction(lesson?.data?.questionInstruction);

    setqusTime(lesson.questionTime);
    setqustype(lesson.type);

    if (lesson.type == "MCQ") {
      setQuestionMarks(lesson.data?.questionMarks);
      setqus(lesson.data?.question);
      setqus5Answer(
        lesson.data.options.map((e, i) => {
          if (e.iscorrect) {
            return `${e} ${i + 1}`;
          }
        })
      );

      let obj = {};
      lesson.data.options.map((e, i) => {
        if (i == 0) {
          obj["option1"] = e.option;
          if (e.iscorrect) {
            setqus5Answer("Option 1");
          }
        }
        if (i == 1) {
          obj["option2"] = e.option;
          if (e.iscorrect) {
            setqus5Answer("Option 2");
          }
        }
        if (i == 2) {
          obj["option3"] = e.option;
          if (e.iscorrect) {
            setqus5Answer("Option 3");
          }
        }
        if (i == 3) {
          obj["option4"] = e.option;
          if (e.iscorrect) {
            setqus5Answer("Option 4");
          }
        }
      });
      setqus5(obj);
    } else {
      setExtraArrForParaGraf(lesson.data);
      onpeFormForSelectedQuestion(lesson.data[1], 1);
      setEditFlagForParaGraf(1);
      setqus(lesson.data?.[0]);
    }
    //console.log("lesson");
    setquestionCategory(lesson.questionCategory);
    toggleQuestionForm();
  }

  function resetData() {}
  async function del(question) {
    setopenLessonPopUp(true);
    // console.log(question);
    setDeleteQuestionId(question);
  }

  const delfunction = (question) => {
    //console.log(question);

    if (mileStoneQuizId) {
      deleteMileStoneObjectiveQuestion({
        questionId: question.id,
        quizId: mileStoneQuizId,
      }).then((res) => {
        // console.log(res.data);
      });
    }

    deleteObjQuestion({ questionId: question.id, quizId: quizId }).then(
      (res) => {
        // console.log(res.data);
      }
    );

    alert("Deleted " + question.name);
    const item1 = [];
    for (let i = 0; i < list.length; i++) {
      if (question.id !== list[i].id) {
        item1.push(list[i]);
      }
    }
    setList(item1);
    saveOrder(item1);
  };

  const toggleForm = () => {
    toggleQuestionForm();
    setFlagForEdit(false);
  };

  return (
    <section className="quizForm-container">
      <DeleteLesson
        open={openLessonPopUp}
        onClose={() => setopenLessonPopUp(false)}
        delfun={(topic) => {
          delfunction(topic);
        }}
        topicId={deleteQuestionId}
      />
      <LoadingScreen
        open={loadingScreen}
        onClose={() => setLoadingScreen(false)}
      />

      <button
        className="primary-btn"
        style={{ marginTop: "30px" }}
        onClick={() => {
          toggleQuestionForm();
          setFlagForEdit(false);
          setqustype("MCQ");
          setflagForSeconstEdit(0);
        }}
      >
        Add Question
      </button>
      <div
        className="quizFrom-mainFrom"
        id={quizId ? quizId : mileStoneQuizId + "questionForm"}
        style={{ display: "none" }}
      >
        <div className="quizFrom-mainFrom-head">Question Form</div>

        <form id={"question-form"} onSubmit={submit}>
          {/* <div className="quizForm-mainForm-infoRow">
            <p className="quizForm-mainForm-label">Question Name :</p>
            <input
              type="text"
              className="quizForm-mainForm-inputBar"
              id={quizId + "questionName"}
              value={questionName}
              style={{border:`${ checkErrorInForm.questionName=="error"?"2px solid red":"1px solid #d7d7d7"}`}}
              onChange={(e) => {
                setQuestionName(e.target.value);
                setCheckErrorInForm((prev)=>({...prev,questionName:""}))
                let value = e.target.value.replaceAll(" ", "");
                let time = new Date().getTime();
              if(!falgForEdit){
                setQuestionId(value + "_" + time);
              }
                 
                
              
              }}
            /> */}

          {/* {

checkErrorInForm.questionName=="error"&&<span style={{marginLeft:"20px",color:"red",fontSize:"15px"}}>Enter Question Name</span>
}  */}
          {/* </div> */}
          <div className="quizForm-mainForm-infoRow">
            <label
              className="quizForm-mainForm-label"
              style={{
                fontWeight: 500,
                color: "#171B1E",
                width: "20%",
              }}
            >
              Type of question :{" "}
            </label>
            <select
              style={{
                width: "30%",
                border: "1px solid #d7d7d7",
                outline: "none",
                height: "40px",
              }}
              className="quizForm-mainForm-select"
              value={qustype}
              disabled={falgForEdit ? falgForEdit : false}
              onChange={(event) => {
                setqustype(event.target.value);
              }}
            >
              <option value="MCQ">Multiple Choice Question</option>

              <option value="paragraph">Paragraph Text</option>
              <option value="videoparagraph">Paragraph Video</option>
            </select>
          </div>

          {qustype == "MCQ" ? (
            <>
              <CreateQuestionMcq
                editQuizArr={editQuizArr}
                questionData={questionDataFromApi}
                quizId={quizId}
                questionSetId={questionSetId}
                type={type}
                mileStoneQuizId={mileStoneQuizId}
                mileStoneId={mileStoneId}
                setfetchDataFromApi={setfetchDataFromApi}
                fetchDataFromApi={fetchDataFromApi}
                flagForSeconstEdit={flagForSeconstEdit}
              />
            </>
          ) : (
            " "
          )}

          {qustype == "paragraph" ? (
            <CreateQuestionTextPara
              editQuizArr={editQuizArr}
              questionData={questionDataFromApi}
              quizId={quizId}
              questionSetId={questionSetId}
              type={type}
              mileStoneQuizId={mileStoneQuizId}
              mileStoneId={mileStoneId}
              setfetchDataFromApi={setfetchDataFromApi}
              fetchDataFromApi={fetchDataFromApi}
              flagForSeconstEdit={flagForSeconstEdit}
            />
          ) : (
            " "
          )}

          {qustype == "videoparagraph" ? (
            <CreateQuestionVideoPara
              editQuizArr={editQuizArr}
              questionData={questionDataFromApi}
              quizId={quizId}
              questionSetId={questionSetId}
              type={type}
              mileStoneQuizId={mileStoneQuizId}
              mileStoneId={mileStoneId}
              setfetchDataFromApi={setfetchDataFromApi}
              fetchDataFromApi={fetchDataFromApi}
              flagForSeconstEdit={flagForSeconstEdit}
            />
          ) : (
            " "
          )}
        </form>
        {formCheck && <div> Fill all the Required fields.</div>}
        {/* <div className="quizForm-mainForm-infoRow">
          <button
            className="quizFrom-button"
            style={{ marginLeft: "auto" }}
            onClick={storedDataOnFirebase}
          >
            Submit
          </button>
          <button className="addItem-btn" style={{ marginLeft: "10px" }}  onClick={toggleForm}>
             Cancel
            </button>
        </div> */}
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <ul
              id="questionList"
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                display: "block",
                overflow: "hidden",
                width: "100%",
                listStyleType: "none",
                margin: "0px",
                padding: "0px",
              }}
            >
              {list.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      key={item.id}
                    >
                      <Accordion>
                        <AccordionSummary
                          {...provided.dragHandleProps}
                          expandIcon={<ExpandMoreSvg />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          sx={{
                            backgroundColor: "#3373CD",
                            marginTop: "10px",
                            color: "white",
                            borderRadius: "5px",
                          }}
                        >
                          <Typography>{item.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <MdDelete
                            style={{ float: "right", margin: "20px" }}
                            size={28}
                            onClick={() => del(item)}
                          />
                          <div onClick={() => edit(item)}>
                            {" "}
                            <MdEdit
                              style={{ float: "right", margin: "20px" }}
                              size={28}
                            />{" "}
                          </div>
                          <p>{item.type}</p>
                        </AccordionDetails>
                      </Accordion>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </section>
  );
};

export default CreateQuestion;
