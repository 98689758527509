import React, { useEffect, useState } from 'react'
import parse from 'html-react-parser';
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../Firebase";
import Button from "@mui/material/Button";
import "./email.css";
import { useLocation } from 'react-router';

import Sidebar from "../../Component/Sidebar";
import NavbarTop from "../../Component/NavbarTop";
import { t } from "i18next";
import { Input } from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from "@mui/icons-material/Add";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

export const CreateEmailNotification = () => {
    

  // =====================================================Email Templet====================================================//
  
    const fetchEmailTemplate = httpsCallable(functions,"fetchEmailTemplate");
    const sendDummyEmail = httpsCallable(functions,"sendDummyEmail");
    const saveEmailTemplate = httpsCallable(functions,"saveEmailTemplate");
    
    const [enableuser,setEnableuser]=useState(false);
    const [enableInst,setEnableInst]=useState(false);
    const [enableadmin,setEnableadmin]=useState(false);

    const [htmlInput,sethtmlInput]=useState("");

    const [userbody,setuserBody]=useState(`user`);
    const [instbody,setinstBody]=useState(`instructor`);
    const [adminbody,setadminBody]=useState(`admin`);

    const [SortCodes,setsortcodes]=useState([]);

    const [instconvert,setinstconvert]=useState(false);
    const [userconvert,setuserconvert]=useState(false);
    const [adminconvert,setadminconvert]=useState(false);

    const [info,setinfo]=useState({
        username:"",
        instructorname:"",
        adminname:"",
        useremail:"",
        instructoremail:"",
        adminemail:"",
        groupname:"",
        lessonname:"",
        coursename:""
      });

      let name,value;
     const getinfodata=(e)=>{
     name=e.target.name;
     value=e.target.value;
     setinfo({...info,[name]:value});
  }


  const [userSubject,setuserSubject]=useState("");
  const [instructorSubject,setinstructorSubject]=useState("");
  const [adminSubject,setadminSubject]=useState("");
  const [adminto,setadminto]=useState("");
  const [emailloading,setemailloading]=useState(false);
  
  const postsavemail=()=>{
    //======save email to the database=====//
    // console.log("email template",trigger,userbody,adminbody,instbody,enableInst,enableuser,enableadmin,userSubject,adminSubject,instructorSubject);
    if(trigger===""){
      alert("Please select trigger");
      return;
    }
   setemailloading(true);
    saveEmailTemplate({
      trigger,
      userbody:userbody,
      adminbody:adminbody,
      instructorbody:instbody,
      enableInstructor:enableInst,
      enableUser:enableuser,
      enableAdmin:enableadmin,
      usersubject:userSubject,
      adminsubject:adminSubject,
      instructorsubject:instructorSubject,
      adminto:adminto,
        }).then(
          (res)=>{console.log(res);
            setemailloading(false);
            alert("successfully Saved !!");
          }).catch((err)=>{
          console.log(err);
          setemailloading(false);
         });
  }
  
    

    let body={
        user:userbody,
        instructor:instbody,
        admin:adminbody
      }
    
      const postdummymail=()=>{
        sendDummyEmail({info:info,body:body}).then(
          (res)=>{console.log(res);
            alert("successfully Send !!");
          }).catch((err)=>{
          console.log(err);
         });
      }

     

    // const postdata=()=>{
    //   //get the data of Email Template
    //   fetchEmailTemplate({event:data.event,eventtype:data.ele}).then(
    //     (res)=>{console.log(res);
    //       setuserBody(res.data.template.user);
    //       setadminBody(res.data.template.instructor);
    //       setinstBody(res.data.template.admin);
    //       setsortcodes(res.data.template.sortcode);
    //     }).catch((err)=>{
    //       console.log(err);
    //     });

    //   //get the data of Notification Template


      // }
      
 // =====================================================notification Template ====================================================//
 const [enableuserNot,setEnableuserNot]=useState(false);
 const [enableInstNot,setEnableInstNot]=useState(false);
 const [enableadminNot,setEnableadminNot]=useState(false);

 const [userbodyNot,setuserBodyNot]=useState(`user`);
 const [instbodyNot,setinstBodyNot]=useState(`instructor`);
 const [adminbodyNot,setadminBodyNot]=useState(`admin`);

 
 const [userHlinkNot,setuserHlinkNot]=useState(``);
 const [instHlinkNot,setinstHlinkNot]=useState(``);
 const [adminHlinkNot,setadminHlinkNot]=useState(``);

 

//  const [htmlInput,sethtmlInput]=useState("");   /// it is same as above
//  const [SortCodes,setsortcodes]=useState({});   /// it is same as above

const [userconvertNot,setuserconvertNot]=useState(false);
const [adminconvertNot,setadminconvertNot]=useState(false);
const [instconvertNot,setinstconvertNot]=useState(false);



const saveNotificationTemplate = httpsCallable(functions,"saveNotificationTemplate"); 
const [notloading,setnotloading]=useState(false);

const postsaveNotificationTemplate=()=>{
  if(trigger===""){
    alert("Please select trigger");
    return;
  }
  setnotloading(true);
    saveNotificationTemplate({
      trigger,
      userbody:userbodyNot,
      instructorbody:instbodyNot,
      adminbody:adminbodyNot,
      enableUser:enableuserNot,
      enableInstructor:enableInstNot,
      enableAdmin:enableadminNot,
      userHlink:userHlinkNot,
      instructorHlink:instHlinkNot,
      adminHlink:adminHlinkNot,
    })
    .then( (res)=>{console.log(res);
      setnotloading(false);
      alert("successfully Saved Notification!!");
    }).catch((err)=>{
      console.log(err);
      setnotloading(false);
    });
}


   useEffect(()=>{
    getShortCodes();
  },[]);

  // console.log(Object.keys(SortCodes));

  const fetchShortcodes = httpsCallable(functions,"fetchShortcodes");

  const [sortcodetrigger,setsortcodetrigger]=useState([]);
  const [trigger,settrigger]=useState("");

  const getShortCodes = ()=>{
    fetchShortcodes().then(
      (res)=>{
        console.log("shortcodes and trigger: ",res.data);
        setsortcodetrigger(res.data);
      }).catch((err)=>{
        console.log(err);
      }
    );
  }





  return (
    <>

<section className="dashboard">
      <NavbarTop page={t("Settings")} />
      {/* <Navbar /> */}

      <div style={{ height: "41vw" }} className="dashboard-main">
        <Sidebar page={"Dashboard"} hidepage={false} />
        <div className="admin-dashboard-main srollbar_div">

        <div className="outer-details-container">

       

            <div >
                <h1 className="bigger-container-header">{trigger}</h1>
            </div>

           <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>

            <div className="user-data-bigger-container">Available SortCodes</div>
            <div>
              <select onChange={(e)=>{
                setsortcodes(sortcodetrigger[+(e.target.value)].shortcodes);
                settrigger(sortcodetrigger[+(e.target.value)].trigger);
              }}>
                <option value="" >Select</option>
                {sortcodetrigger.map((item,index)=>{

                  return <option value={index}  key={index}>{item.trigger}</option>
                })}
              </select>
            </div>

           </div>


            <div  style={{border:"2px solid lightgray"}}>
            {/* {Object.keys(SortCodes).map((keyName, i) => (
              <li className="sortcode-list-style" key={i}>
                    <span >{keyName} : {SortCodes[keyName]}</span>
                </li>
            ))}   */}
             {SortCodes.map((item,index)=>{
              return <li className="sortcode-list-style" key={index}>
                    <span >{item} : {item}</span>
                </li>
             })}
            </div>

 {/* =============================================Emails======================================================== */}

              {/* <h1>Email:</h1>
        <div className="user-data-bigger-container" style={{border:"2px solid lightgray"}}>
            <label for="EnableInst" className=''> Enable Instructor:</label>
                <input type="checkbox" id="enableInst" name="EnableInst" className='' style={{marginLeft:"8px"}}
                onChange={()=>setEnableInst(!enableInst)}/>
        { enableInst && <div>
        <div className=''>
            <div className='body-box-header' style={{marginLeft:"8px"}}>
            <h3 className='' style={{marginRight:"390px",marginTop:"8px"}}>Body</h3>
            <Button variant="contained" component="span" color="primary" onClick={()=>{setinstconvert(!instconvert); sethtmlInput(instbody)}}>
                Convert
            </Button>
            </div>
        <div className='text-area-box'>
            <textarea name="" id="" cols="70" rows="15" className='' style={{border:"2px solid lightgray",marginLeft:"10px"}} value={instbody} onChange={(e)=>{
                // console.log(e.target.value);
                setinstBody(e.target.value);
              return sethtmlInput(e.target.value)}}></textarea>
            {instconvert &&
            <div  style={{width:"550px",height:"234px",border:"2px solid lightgray"}} className=''>
            {parse(htmlInput)}</div>
            }
        </div>
        </div>
          
        <span>Subject:</span>
          <input type="text" name="hyperLink" id="hyperLink" value={instructorSubject}  onChange={(e)=>setinstructorSubject(e.target.value)} style={{marginLeft:"8px",marginTop:"8px"}}  />
        
        </div>}
        </div> */}

        {/* <div className="user-data-bigger-container" style={{border:"2px solid lightgray"}}>
        <label for="EnableUser" className=''> Enable User:</label>
            <input type="checkbox" id="enableUser" name="EnableUser" className='' style={{marginLeft:"8px"}} onChange={()=>setEnableuser(!enableuser)}/>
        { enableuser && <div>
        <div className=''>
            <div className='body-box-header' style={{marginLeft:"8px"}}>
            <h3 className='' style={{marginRight:"390px",marginTop:"8px"}}>Body</h3>
            <Button variant="contained" component="span" color="primary" onClick={()=>{setuserconvert(!userconvert);sethtmlInput(userbody)}}>
                Convert
            </Button>
            </div>
        <div className='body-box-header'>
            <textarea name="" id="" cols="70" rows="15" className='' style={{border:"2px solid lightgray",marginRight:"40px",marginLeft:"10px"}}  value={userbody} onChange={(e)=>{
                // console.log(e.target.value);
                setuserBody(e.target.value);
              return sethtmlInput(e.target.value)}}></textarea>
            {userconvert && <div style={{width:"550px",height:"234px",border:"2px solid lightgray"}} className=''>
            {parse(htmlInput)}</div>}
        </div>
        </div>
        <span>Subject:</span>
          <input type="text" name="hyperLink" id="hyperLink" onChange={(e)=>setuserSubject(e.target.value)} style={{marginLeft:"8px",marginTop:"8px"}}  />
        
        </div>}
        </div> */}

        {/* <div className="user-data-bigger-container" style={{border:"2px solid lightgray"}}>
        <label for="EnableAdmin" className='font-bold m-2'> Enable Admin:</label>
        <input type="checkbox" id="enableAdmin" name="EnableAdmin" className='' style={{marginLeft:"8px"}} onChange={()=>setEnableadmin(!enableadmin)}/> */}
        {/* { enableadmin && <div>
        <div className=''>
            <div className='body-box-header' style={{marginLeft:"8px"}}>
            <h3 className='' style={{marginRight:"390px",marginTop:"8px"}}>Body</h3>
            <Button variant="contained" component="span" color="primary" onClick={()=>{setadminconvert(!adminconvert);sethtmlInput(adminbody)}}>
                Convert
            </Button>
            </div>
        <div className='text-area-box'>
            <textarea name="" id="" cols="70" rows="15" className='' style={{border:"2px solid lightgray",marginLeft:"10px"}} value={adminbody} onChange={(e)=>{
                // console.log(e.target.value);
                setadminBody(e.target.value);
              return sethtmlInput(e.target.value)}}></textarea>
            {adminconvert && <div  style={{width:"550px",height:"234px",border:"2px solid lightgray"}} className=''>
            {parse(htmlInput)}</div>}
        </div>
        </div>
        <span>Subject:</span>
          <input type="text" name="hyperLink" id="hyperLink" className='' style={{marginLeft:"8px",marginTop:"8px"}}  />

          <span>To:</span>
          <input type="text" name="hyperLink" id="hyperLink" value={adminto} onChange={(e)=>setadminto(e.target.value)} style={{marginLeft:"16px",marginTop:"8px"}}  />
        
        </div>} */}
        {/* </div> */}
        {/* <div style={{textAlign:"right"}}> */}
        {/* <LoadingButton variant="contained" loading={emailloading} loadingPosition="start" startIcon={<SaveIcon />} color="primary" onClick={postsavemail}>
            Save Email
        </LoadingButton> */}
        {/* </div> */}
{/* =============================================notification======================================================== */}
        <h1>Notifications: </h1>

        
        <div className="user-data-bigger-container" style={{border:"2px solid lightgray"}}>
        <label for="EnableUser" className=''> Notification To User:</label>
            <input type="checkbox" id="enableUser" name="EnableUser" className='' style={{marginLeft:"8px"}} onChange={()=>setEnableuserNot(!enableuserNot)}/>
        { enableuserNot && <div>
        <div className=''>
            <div className='body-box-header' style={{marginLeft:"8px"}}>
            <h3 className='' style={{marginRight:"390px",marginTop:"8px"}}>Body</h3>
            <Button variant="contained" component="span" color="primary" onClick={()=>{setuserconvertNot(!userconvertNot); sethtmlInput(userbodyNot)}}>
                Convert
            </Button>
            </div>
        <div className='body-box-header'>
            <textarea name="" id="" cols="70" rows="15" className='' style={{border:"2px solid lightgray",marginRight:"40px",marginLeft:"10px"}}  value={userbodyNot} onChange={(e)=>{
                console.log(e.target.value);
                setuserBodyNot(e.target.value);
              return sethtmlInput(e.target.value)}}></textarea>
            {userconvertNot && <div style={{width:"550px",height:"234px",border:"2px solid lightgray"}} className=''>
            {parse(htmlInput)}</div>}
        </div>
        </div>
          <span>HyperLink:</span>
          <input type="text" name="hyperLink" id="hyperLink" className='' style={{marginLeft:"8px"}} value={userHlinkNot} onChange={(e)=>setuserHlinkNot(e.target.value)}  />
        </div>}
        </div>

        {/* <div className="user-data-bigger-container" style={{border:"2px solid lightgray"}}>
        <label for="EnableAdmin" className='font-bold m-2'> Notification To Admin:</label>
        <input type="checkbox" id="enableAdmin" name="EnableAdmin" className='' style={{marginLeft:"8px"}} onChange={()=>setEnableadminNot(!enableadminNot)}/>
        { enableadminNot && <div>
        <div className=''>
            <div className='body-box-header' style={{marginLeft:"8px"}}>
            <h3 className='' style={{marginRight:"390px",marginTop:"8px"}}>Body</h3>
            <Button variant="contained" component="span" color="primary" onClick={()=>{setadminconvertNot(!adminconvertNot);sethtmlInput(adminbodyNot)}}>
                Convert
            </Button>
            {/* <button className='bg-blue-500 border-2' onClick={()=>{setadminconvertNot(!adminconvertNot)}}> convert </button> */}
            {/* </div> */}
        {/* <div className='text-area-box'>
            <textarea name="" id="" cols="70" rows="15" className='' style={{border:"2px solid lightgray",marginLeft:"10px"}} value={adminbodyNot} onChange={(e)=>{
                console.log(e.target.value);
                setadminBodyNot(e.target.value);
              return sethtmlInput(e.target.value)}}></textarea>
            {adminconvertNot && <div  style={{width:"550px",height:"234px",border:"2px solid lightgray"}} className=''>
            {parse(htmlInput)}</div>}
        </div>
        </div>
        <span>HyperLink:</span>
          <input type="text" name="hyperLink" id="hyperLink" className='' style={{marginLeft:"8px",marginTop:"8px"}} value={adminHlinkNot} onChange={(e)=>setadminHlinkNot(e.target.value)} />
        </div>}
        </div> */} 

        {/* <div className="user-data-bigger-container" style={{border:"2px solid lightgray"}}>
            <label for="EnableInst" className=''> Notification To Instructor:</label>
                <input type="checkbox" id="enableInst" name="EnableInst" className='' style={{marginLeft:"8px"}}
                onChange={()=>setEnableInstNot(!enableInstNot)}/>
        { enableInstNot && <div>
        <div className=''>
            <div className='body-box-header' style={{marginLeft:"8px"}}>
            <h3 className='' style={{marginRight:"390px",marginTop:"8px"}}>Body</h3>
            <Button variant="contained" component="span" color="primary" onClick={()=>{setinstconvertNot(!instconvertNot);sethtmlInput(instbodyNot)}}>
                Convert
            </Button>
            </div>
        <div className='text-area-box'>
            <textarea name="" id="" cols="70" rows="15" className='' style={{border:"2px solid lightgray",marginLeft:"10px"}} value={instbodyNot} onChange={(e)=>{
                // console.log(e.target.value);
                setinstBodyNot(e.target.value);
              return sethtmlInput(e.target.value)}}></textarea>
            {instconvertNot &&
            <div  style={{width:"550px",height:"234px",border:"2px solid lightgray"}} className=''>
            {parse(htmlInput)}</div>
            }
        </div>
        </div>
        <span>HyperLink:</span>
          <input type="text" name="hyperLink" id="hyperLink" className='' style={{marginLeft:"8px",marginTop:"8px"}} value={instHlinkNot} onChange={(e)=>setinstHlinkNot(e.target.value)} />
        </div>}
        </div> */}

        <div style={{textAlign:"right", marginBottom:"20px"}}>
  
        <LoadingButton variant="contained" loading={notloading} loadingPosition="start" startIcon={<SaveIcon />} color="primary" onClick={postsaveNotificationTemplate}>
        Save Notification
        </LoadingButton>
        </div>

        


{/* =============================================Dummy data======================================================== */}

        {/* <div >
                <h1 className="bigger-container-header">Enter Dummy Data</h1>
        </div>

        <div className=''>
        <div className='' style={{border:"2px solid lightgray"}}>

        <div className='dummy-box'>
        <div className=''><spam  style={{fontWeight:"bold"}}>%username%:</spam><input type="text" name="username" placeholder="" className='user-data-input' value={info.username}
        onChange={getinfodata}></input></div>
        <div className=''><spam  style={{fontWeight:"bold"}}>%instructorname%:</spam><input type="text" name="instructorname" placeholder="" className='user-data-input' value={info.instructorname} onChange={getinfodata}></input></div>
        <div className=''><spam  style={{fontWeight:"bold"}}>%adminname%:</spam><input type="text" name="adminname" placeholder="" className='user-data-input' value={info.adminname} onChange={getinfodata}></input></div>
        </div>

        <div className='dummy-box'>
        <div className='my-4'><spam  style={{fontWeight:"bold"}}>%coursename%:</spam><input type="text" name="coursename" placeholder="" className='user-data-input' value={info.coursename} onChange={getinfodata}></input></div>
        <div className='my-4'><spam  style={{fontWeight:"bold"}}>%groupname%:</spam><input type="text" name="groupname" placeholder="" className='user-data-input' value={info.groupname} onChange={getinfodata}></input></div>
        <div className='my-4'><spam  style={{fontWeight:"bold"}}>%lessonname%:</spam><input type="text" name="lessonname" placeholder="" className='user-data-input' value={info.lessonname} onChange={getinfodata}></input></div>
        </div>
        
        <div className='dummy-box'>
        <div className='my-4'><spam  style={{fontWeight:"bold"}}>To Dummy_user:</spam><input type="text" name="useremail" placeholder="" className='user-data-input' value={info.useremail} onChange={getinfodata}></input></div>
        <div className='my-4'><spam  style={{fontWeight:"bold"}}>To Dummy_instructor:</spam><input type="text" name="instructoremail" placeholder="" className='user-data-input' value={info.instructoremail} onChange={getinfodata}></input></div>
        <div className='my-4'><spam  style={{fontWeight:"bold"}}>To Dummy_admin:</spam><input type="text" name="adminemail" placeholder="" className='user-data-input' value={info.adminemail} onChange={getinfodata}></input></div>
        </div>
        </div>
        </div>

        <div className='save-dummy-box'>
        <Button variant="contained" component="span" color="primary" onClick={postdummymail}>
            sent test Email
        </Button>
        
        </div> */}

        </div>

          </div>
        </div>
        </section>

      
    </>
  )
}