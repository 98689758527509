import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./AdminSetting.css";
import Sidebar from "../../Component/Sidebar";
import NavbarTop from "../../Component/NavbarTop";
import { t } from "i18next";
import { useSelector } from "react-redux";

function AdminSettings() {
  const { clrs } = useSelector((state) => state.createClr);
  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Settings")} />
        {/* <Navbar /> */}

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="admin-dashboard-main-bottom">
              <div
                style={{ width: "24vw" }}
                className="admin-dashboard-main-bottom-item"
              >
                <div className="admin-dashboard-main-bottom-item-left">
                  <div className="admin-dashboard-main-bottom-item-left-top">
                    {t("Certificate Settings")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-mid">
                    {t("Add and edit certificate templates")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/settings/available-certificates"}>
                      <button className="primary-btn">{t("Open")}</button>
                    </Link>
                  </div>
                </div>
                {/* <div className="admin-dashboard-main-bottom-item-right">
                <div
                  className="admin-dashboard-main-bottom-item-right-cnt"
                  style={{
                    background: `${clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"}`,
                  }}
                >
                </div>
              </div> */}
              </div>

              <div
                style={{ width: "24vw" }}
                className="admin-dashboard-main-bottom-item"
              >
                <div className="admin-dashboard-main-bottom-item-left">
                  <div className="admin-dashboard-main-bottom-item-left-top">
                    {t("Notification Settings")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-mid">
                    {t("Add and edit notification template")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/settings/email"}>
                      <button className="primary-btn">{t("Open")}</button>
                    </Link>
                  </div>
                </div>
              </div>

              <div
                style={{ width: "24vw" }}
                className="admin-dashboard-main-bottom-item"
              >
                <div className="admin-dashboard-main-bottom-item-left">
                  <div className="admin-dashboard-main-bottom-item-left-top">
                    {t("Question Category")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-mid">
                    {t("Add and edit question category")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/settings/course-question-Category"}>
                      <button className="primary-btn">{t("Open")}</button>
                    </Link>
                  </div>
                </div>
              </div>

              <div
                style={{ width: "24vw" }}
                className="admin-dashboard-main-bottom-item"
              >
                <div className="admin-dashboard-main-bottom-item-left">
                  <div className="admin-dashboard-main-bottom-item-left-top">
                    {t("Basic Lesson Category")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-mid">
                    {t("Add and edit basic lesson category")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/settings/BasicLessonCategory"}>
                      <button className="primary-btn">{t("Open")}</button>
                    </Link>
                  </div>
                </div>
              </div>

              <div
                style={{ width: "24vw" }}
                className="admin-dashboard-main-bottom-item"
              >
                <div className="admin-dashboard-main-bottom-item-left">
                  <div className="admin-dashboard-main-bottom-item-left-top">
                    {t("User Onboarding Questions")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-mid">
                    {t("Add and edit user onboarding questions")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/settings/UserFilterInAppQuestions"}>
                      <button className="primary-btn">{t("Open")}</button>
                    </Link>
                  </div>
                </div>
              </div>

              <div
                style={{ width: "24vw" }}
                className="admin-dashboard-main-bottom-item"
              >
                <div className="admin-dashboard-main-bottom-item-left">
                  <div className="admin-dashboard-main-bottom-item-left-top">
                    {t("Coordinator")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-mid">
                    {t("Add and edit coordinator")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/settings/coordinator"}>
                      <button className="primary-btn">{t("Open")}</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AdminSettings;
