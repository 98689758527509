import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";
import { Timestamp } from "firebase/firestore";
import ReactPlayer from "react-player";
const InternalTraining = ({ uid, data }) => {
  const [training, setTraining] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [arrLength, setArrLength] = useState(0);
  const [loading, setLoading] = useState(false);

  const ref = React.createRef();
  const fetch_user_courses = httpsCallable(functions, "fetch_user_courses");

  console.log(data[0]);
  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(arrLength / 5)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };

  const [speeds, setSpeeds] = useState(1.0);
  const chnagespeed = (speed) => {
    var vid = document.getElementById("myVideo");

    vid.playbackRate = Number(speed);
  };
  return (
    <div
      style={{
        margin: "0vw 2vw 2vw 2vw",
        padding: "20px",
        fontFamily: "Poppins",
      }}
      className="admin-overdue-bottom"
    >
      <div style={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: 500 }}>
        {t("Objective Test Recording Video")}
      </div>
      <div
        className="custom-scrollbar"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gridGap: "30px",
          overflow: "scroll",
          height: "400px",
          marginTop: "30px",
          padding: "20px",
        }}
      >
        {data.length > 0 ? (
          data[0].objectiveTestRecording?.map((item, id) => (
            <div>
              {/* <video  id={`myVideo${id}`} width="300" height="240" controls>
            <source  src={item} type="video/mp4"/>
      <source  src={item} type="video/ogg"/>
        Your browser does not support the video tag.
           </video> */}

              <div>
                <ReactPlayer
                  ref={ref}
                  url={item}
                  playing
                  controls
                  playbackRate={speeds}
                  config={{ file: { attributes: { id: "audio-element" } } }}
                  width="100%"
                  height="180px"
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "20px",
                    marginTop: "10px",
                  }}
                >
                  <label> Select play back speed</label>
                  <select
                    onChange={(e) => {
                      setSpeeds(Number(e.target.value));
                    }}
                  >
                    <option value={-1.0}>-1.0</option>
                    <option value={-0.5}>-0.5</option>
                    <option value={0.5}>0.5</option>
                    <option value={1}>1.0</option>
                    <option value={1.5}>1.5</option>
                    <option value={2.0}>2.0</option>
                    <option value={2.5}>2.5</option>
                    <option value={3.0}>3.0</option>
                    <option value={4.0}>4.0</option>
                  </select>
                </div>
              </div>

              {/* <p>Date:20-20-2220</p>
               */}
              {/* <select 
            onChange={(e)=>{chnagespeed(e.target.value)}}
            >

              <option value={0.5}>0.5</option>
              <option value={1}>1.0</option>
              <option value={1.5}>1.5</option>
              <option value={2.0}>2.0</option>
            </select> */}
            </div>
          ))
        ) : (
          <div>
            {" "}
            <h1>No Video Found</h1>{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default InternalTraining;
