import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../Firebase";
import Button from "@mui/material/Button";
import "./email.css";
import { useLocation } from "react-router";

import Sidebar from "../../Component/Sidebar";
import NavbarTop from "../../Component/NavbarTop";
import { t } from "i18next";
import { Input } from "@mui/material";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
function EmailSettingPage() {
  const location = useLocation();
  const data = location.state;
  // console.log("data", data.sho);

  //======================================= bcc and cc===========================================================//
  const [instbcc, setinstbcc] = useState([]);
  const [instcc, setinstcc] = useState([]);
  const [instvalbcc, setinstvalbcc] = useState("");
  const [instvalcc, setinstvalcc] = useState("");

  const [adminbcc, setadminbcc] = useState([]);
  const [admincc, setadmincc] = useState([]);
  const [adminvalbcc, setadminvalbcc] = useState("");
  const [adminvalcc, setadminvalcc] = useState("");

  const [userbcc, setuserbcc] = useState([]);
  const [usercc, setusercc] = useState([]);
  const [uservalbcc, setuservalbcc] = useState("");
  const [uservalcc, setuservalcc] = useState("");

  const addbcc = (val, num) => {
    if (val === "") return;
    if (num == 1) {
      setinstbcc([...instbcc, val]);
    } else if (num == 2) {
      setadminbcc([...adminbcc, val]);
    } else if (num == 3) {
      setuserbcc([...userbcc, val]);
    }
  };

  const addcc = (val, num) => {
    if (val === "") return;
    if (num == 1) {
      setinstcc([...instcc, val]);
    } else if (num == 2) {
      setadmincc([...admincc, val]);
    } else if (num == 3) {
      setusercc([...usercc, val]);
    }
  };

  const removebcc = (val, num) => {
    if (num == 1) {
      setinstbcc(instbcc.filter((item, index) => index != val));
    } else if (num == 2) {
      setadminbcc(adminbcc.filter((item, index) => index != val));
    } else if (num == 3) {
      setuserbcc(userbcc.filter((item, index) => index != val));
    }
  };
  const removecc = (val, num) => {
    if (num == 1) {
      setinstcc(instcc.filter((item, index) => index != val));
    } else if (num == 2) {
      setadmincc(admincc.filter((item, index) => index != val));
    } else if (num == 3) {
      setusercc(usercc.filter((item, index) => index != val));
    }
  };

  // =====================================================Email Templet====================================================//

  const fetchEmailTemplate = httpsCallable(functions, "fetchEmailTemplate");
  const sendDummyEmail = httpsCallable(functions, "sendDummyEmail");
  const saveEmailTemplate = httpsCallable(functions, "saveEmailTemplate");

  const [enableuser, setEnableuser] = useState(false);
  const [enableInst, setEnableInst] = useState(false);
  const [enableadmin, setEnableadmin] = useState(false);

  const [htmlInput, sethtmlInput] = useState("");

  const [userbody, setuserBody] = useState(`user`);
  const [instbody, setinstBody] = useState(`instructor`);
  const [adminbody, setadminBody] = useState(`admin`);

  const [SortCodes, setsortcodes] = useState({});

  const [instconvert, setinstconvert] = useState(false);
  const [userconvert, setuserconvert] = useState(false);
  const [adminconvert, setadminconvert] = useState(false);

  const [info, setinfo] = useState({
    username: "",
    instructorname: "",
    adminname: "",
    useremail: "",
    instructoremail: "",
    adminemail: "",
    groupname: "",
    lessonname: "",
    coursename: "",
  });

  let name, value;
  const getinfodata = (e) => {
    name = e.target.name;
    value = e.target.value;
    setinfo({ ...info, [name]: value });
  };

  let body = {
    user: userbody,
    instructor: instbody,
    admin: adminbody,
  };
  const [dummyloading, setdummyloading] = useState(false);

  const postdummymail = () => {
    setdummyloading(true);
    sendDummyEmail({ info: info, body: body })
      .then((res) => {
        console.log(res);
        setdummyloading(false);
        alert("successfully Send !!");
      })
      .catch((err) => {
        setdummyloading(false);
        console.log(err);
      });
  };

  //===================================================fetch email templates====================================================//
  const [enableuserNot, setEnableuserNot] = useState(false);
  const [enableInstNot, setEnableInstNot] = useState(false);
  const [enableadminNot, setEnableadminNot] = useState(false);

  const [hashAdmin, sethashAdmin] = useState(false);
  const [hashUser, sethashUser] = useState(false);
  const [hashInstructor, sethashInstructor] = useState(false);
  const [stateForEnableAndDiseble, setStateForEnableAndDiseble] = useState({
    userEmail: true,
    userNoti: true,
    instructorEmail: true,
    instructorNoti: true,
    adminEmail: true,
    adminNoti: true,
  });
  const [userSubject, setuserSubject] = useState("");
  const [instructorSubject, setinstructorSubject] = useState("");
  const [adminSubject, setadminSubject] = useState("");
  const [adminto, setadminto] = useState("");
  const postdata = () => {
    //get the data of Email Template
    fetchEmailTemplate({ trigger: data.trigger })
      .then((res) => {
        console.log("fetchEmailTempletes: ", res);
        let temp = res.data;
        for (let i = 0; i < temp.length; i++) {
          if (temp[i].for == "user") {
            if (temp[i].type == "notification") {
              setStateForEnableAndDiseble((prev) => ({
                ...prev,
                userNoti: false,
              }));
              setuserBodyNot(temp[i].body);
              setEnableuserNot(temp[i].enabled);
            } else {
              setStateForEnableAndDiseble((prev) => ({
                ...prev,
                userEmail: false,
              }));
              setuserBody(temp[i].body);
              setEnableuser(temp[i].enabled);
              setuserSubject(temp[i].subject);
              // setuserbcc([...temp[i].bcc]);
              // setusercc([...temp[i].cc]);
            }
          } else if (temp[i].for == "admin") {
            if (temp[i].type == "notification") {
              setStateForEnableAndDiseble((prev) => ({
                ...prev,
                adminNoti: false,
              }));
              setadminBodyNot(temp[i].body);
              setEnableadminNot(temp[i].enabled);
            } else {
              setStateForEnableAndDiseble((prev) => ({
                ...prev,
                adminEmail: false,
              }));

              setadminBody(temp[i].body);
              setEnableadmin(temp[i].enabled);
              setadminSubject(temp[i].subject);
              setadminto(temp[i].to);
              // setadminbcc([...temp[i].bcc]);
              // setadmincc([...temp[i].cc]);
            }
          } else if (temp[i].for == "instructor") {
            if (temp[i].type == "notification") {
              setStateForEnableAndDiseble((prev) => ({
                ...prev,
                instructorNoti: false,
              }));

              setinstBodyNot(temp[i].body);
              setEnableInstNot(temp[i].enabled);
            } else {
              setStateForEnableAndDiseble((prev) => ({
                ...prev,
                instructorEmail: false,
              }));

              setinstBody(temp[i].body);
              setEnableInst(temp[i].enabled);
              setinstructorSubject(temp[i].subject);
              // setinstbcc([...temp[i].bcc]);
              // setinstcc([...temp[i].cc]);
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //=====================================================save email templates====================================================//

  const [emailloading, setemailloading] = useState(false);
  const postsavemail = () => {
    //======save email to the database=====//
    // console.log("save email::  " , trigger,userSubject,adminSubject,instructorSubject,adminto);
    console.log(
      "save enable::  ",
      data.trigger,
      enableInst,
      enableuser,
      enableadmin
    );
    console.log("save hashmap::  ", hashInstructor, hashUser, hashAdmin);

    // if(enableuser==true){sethashUser(true);}
    // if(enableadmin==true){sethashAdmin(true);}
    // if(enableInst==true){sethashInstructor(true);}

    setemailloading(true);
    saveEmailTemplate({
      trigger: data.trigger,
      userbody: userbody,
      adminbody: adminbody,
      instructorbody: instbody,
      enableInstructor: enableInst,
      enableUser: enableuser,
      enableAdmin: enableadmin,
      usersubject: userSubject,
      adminsubject: adminSubject,
      instructorsubject: instructorSubject,
      adminto: adminto,
      // hasAdmin:enableadmin,
      // hasUser:enableuser,             ///hashmap change only when enable is true
      // hasInstructor: enableInst,
      hasAdmin: hashAdmin,
      hasUser: hashUser,
      hasInstructor: hashInstructor,
      userCc: usercc,
      adminCc: admincc,
      instructorCc: instcc,
      userBcc: userbcc,
      adminBcc: adminbcc,
      instructorBcc: instbcc,
    })
      .then((res) => {
        console.log(res);
        setemailloading(false);
        alert("successfully Saved !!");
      })
      .catch((err) => {
        setemailloading(false);
        console.log(err);
      });
  };

  // =====================================================notification Template ====================================================//

  const [userbodyNot, setuserBodyNot] = useState(`user`);
  const [instbodyNot, setinstBodyNot] = useState(`instructor`);
  const [adminbodyNot, setadminBodyNot] = useState(`admin`);

  const [userHlinkNot, setuserHlinkNot] = useState(``);
  const [instHlinkNot, setinstHlinkNot] = useState(``);
  const [adminHlinkNot, setadminHlinkNot] = useState(``);

  const [hashAdminNot, sethashAdminNot] = useState("");
  const [hashUserNot, sethashUserNot] = useState("");
  const [hashInstructorNot, sethashInstructorNot] = useState("");

  const [userconvertNot, setuserconvertNot] = useState(false);
  const [adminconvertNot, setadminconvertNot] = useState(false);
  const [instconvertNot, setinstconvertNot] = useState(false);

  const saveNotificationTemplate = httpsCallable(
    functions,
    "saveNotificationTemplate"
  );
  const [notloading, setnotloading] = useState(false);
  const postsaveNotificationTemplate = () => {
    // console.log("saveNotificationTemplate: ", data.trigger,userbodyNot,adminbodyNot,instbodyNot,userHlinkNot,adminHlinkNot,instHlinkNot);
    console.log(
      "saveNotificationTemplate hash: ",
      hashAdminNot,
      hashUserNot,
      hashInstructorNot
    );

    setnotloading(true);
    saveNotificationTemplate({
      trigger: data.trigger,
      userbody: userbodyNot,

      enableUser: enableuserNot,

      hasUser: hashUserNot,
    })
      .then((res) => {
        console.log(res);
        setnotloading(false);
        alert("successfully Saved Notification!!");
      })
      .catch((err) => {
        console.log(err);
        setnotloading(false);
      });
  };

  useEffect(() => {
    postdata();
    getShortCodes();
  }, []);

  //=======================================fetch short codes===========================================================//
  const fetchShortcodes = httpsCallable(functions, "fetchShortcodes");

  const [sortcodetrigger, setsortcodetrigger] = useState([]);
  const [trigger, settrigger] = useState("");

  const getShortCodes = () => {
    fetchShortcodes()
      .then((res) => {
        console.log("shortcodes and trigger: ", res.data);
        setsortcodetrigger(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Settings")} />
        {/* <Navbar /> */}

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-details-container">
              <div>
                <h1 className="bigger-container-header">{data.trigger}</h1>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="user-data-bigger-container">
                  Available SortCodes
                </div>
                {/* <div>
              <select>
                
                {dara.shortcodes.map((item,index)=>{
                  return <option value={item.trigger} key={index}>{item.trigger}</option>
                })}
              </select>
            </div> */}
              </div>

              <div
                style={{
                  border: "1px solid #1E3758",
                  borderRadius: "5px",
                  marginBottom: "20px",
                  backgroundColor: "#fff",
                  padding: "5px 0px",
                }}
              >
                {/* {data.shortcodes.map((item,index)=>
               <li className="sortcode-list-style" key={index}>
                    <span >{item} : {item}</span>
                </li>
            )} */}
                {Object.keys(data.shortcodes).map((keyName, i) => (
                  <li className="sortcode-list-style" key={i}>
                    <span>
                      {keyName} : {data.shortcodes[keyName]}
                    </span>
                  </li>
                ))}
              </div>

              {/* =============================================Emails======================================================== */}
              {/* <h1>Email:</h1> */}

              {/* <div className="user-data-bigger-container" style={{border:"2px solid lightgray"}}>
            <label for="EnableInst"  className=''> Enable Instructor:</label>
                <input type="checkbox" disabled={stateForEnableAndDiseble.instructorEmail} checked={enableInst==true} id="enableInst" name="EnableInst" className='' style={{marginLeft:"8px"}}
                onChange={()=>{
                   setEnableInst(!enableInst);
                   if(enableInst==true){
                    sethashInstructor(true);
                   }
                   
                }}/> */}
              {/* { enableInst && <div>
        <div className=''>
            <div className='body-box-header' style={{marginLeft:"8px"}}>
            <h3 className='' style={{marginRight:"390px",marginTop:"8px"}}>Body</h3>
            <Button variant="contained" component="span" color="primary" onClick={()=>{setinstconvert(!instconvert); sethtmlInput(instbody)}}>
                Convert
            </Button>
            </div>
        <div className='text-area-box'>
            <textarea name="" id="" cols="70" rows="15" className='' style={{border:"2px solid lightgray",marginLeft:"10px"}} value={instbody} onChange={(e)=>{
                console.log(e.target.value);
                setinstBody(e.target.value);
                sethashInstructor(true)
              return sethtmlInput(e.target.value)}}></textarea>
            {instconvert &&
            <div  style={{width:"550px",height:"234px",border:"2px solid lightgray"}} className=''>
            {parse(htmlInput)}</div>
            }
        </div>
        </div>
          <div>
        <span>Subject:</span>
          <input type="text" name="hyperLink" id="hyperLink" value={instructorSubject} onChange={(e)=>setinstructorSubject(e.target.value)} style={{marginLeft:"8px",marginTop:"8px"}}  />
          </div>

          <div style={{width:"50%", display:"flex"}}>

          <div style={{width:"50%", }}>
          <span>CC : </span>
          <input className="ccs_" type="text" name="instcc"  value={instvalcc} onChange={(e)=>setinstvalcc(e.target.value)} style={{marginLeft:"8px",marginTop:"8px"}}  />
          <button className='btn_addccs' onClick={()=>{addcc(instvalcc,1)}}>Add</button>
          </div>

          <div style={{width:"50%", }}>
          <span>BCC : </span>
          <input className="ccs_" type="text" name="instcc"  value={instvalbcc} onChange={(e)=>setinstvalbcc(e.target.value)} style={{marginLeft:"8px",marginTop:"8px"}}  />
          <button className='btn_addccs' onClick={()=>{addbcc(instvalbcc,1)}}>Add</button>
          </div>
          
          </div>
          <div style={{width:"50%", display:"flex"}}>

          <div style={{width:"50%", paddingLeft:"5%",paddingTop:"10px",paddingRight:"5%", }}>
         {instcc[0] && <>
          {instcc?.map((item,index)=>{
            return <div key={index} style={{display:"flex", justifyContent:"space-between", alignItems:"center"}} >

              <span>{index+1}. {item}</span> 
              
              <button onClick={()=>{removecc(index,1)}}>Remove</button></div>
          })}
         
         </> }
             
          </div>

          <div style={{width:"50%", paddingLeft:"5%",paddingTop:"10px",paddingRight:"5%", }}>
         {instbcc[0] && <>
          {instbcc?.map((item,index)=>{
            return <div key={index} style={{display:"flex", justifyContent:"space-between", alignItems:"center"}} >

              <span>{index+1}. {item}</span> 
              
              <button onClick={()=>{removebcc(index,1)}}>Remove</button></div>
          })}
         
         </> }
             
          </div>
          
          </div>

  
        
        </div>} */}
              {/* </div> */}
              {/* email user============================ */}
              {/* <div className="user-data-bigger-container" style={{border:"2px solid lightgray"}}>
        <label for="EnableUser" className=''> Enable User:</label>
            <input type="checkbox" disabled={stateForEnableAndDiseble.userEmail} checked={enableuser==true} id="enableUser" name="EnableUser" className='' style={{marginLeft:"8px"}}
             onChange={()=>{setEnableuser(!enableuser)
                if(enableuser==true){
                    sethashUser(true);
                }
             }}/> */}
              {/* { enableuser && <div>
        <div className=''>
            <div className='body-box-header' style={{marginLeft:"8px"}}>
            <h3 className='' style={{marginRight:"390px",marginTop:"8px"}}>Body</h3>
            <Button variant="contained" component="span" color="primary" onClick={()=>{setuserconvert(!userconvert);sethtmlInput(userbody)}}>
                Convert
            </Button>
            </div>
        <div className='body-box-header'>
            <textarea name="" id="" cols="70" rows="15" className='' style={{border:"2px solid lightgray",marginRight:"40px",marginLeft:"10px"}}  value={userbody} onChange={(e)=>{
                console.log(e.target.value);
                setuserBody(e.target.value);
                sethashUser(true)
              return sethtmlInput(e.target.value)}}></textarea>
            {userconvert && <div style={{width:"550px",height:"234px",border:"2px solid lightgray"}} className=''>
            {parse(htmlInput)}</div>}
        </div>
        </div>
        <div>
        <span>Subject:</span>
          <input type="text" name="hyperLink" id="hyperLink" value={userSubject} onChange={(e)=>setuserSubject(e.target.value)} style={{marginLeft:"8px",marginTop:"8px"}}  />
        </div>

        <div style={{width:"50%", display:"flex"}}>

<div style={{width:"50%", }}>
<span>CC : </span>
<input className="ccs_" type="text" name="instcc"  value={uservalcc} onChange={(e)=>setuservalcc(e.target.value)} style={{marginLeft:"8px",marginTop:"8px"}}  />
<button className='btn_addccs' onClick={()=>{addcc(uservalcc,3)}}>Add</button>
</div>

<div style={{width:"50%", }}>
<span>BCC : </span>
<input className="ccs_" type="text" name="usercc"  value={uservalbcc} onChange={(e)=>setuservalbcc(e.target.value)} style={{marginLeft:"8px",marginTop:"8px"}}  />
<button className='btn_addccs' onClick={()=>{addbcc(uservalbcc,3)}}>Add</button>
</div>

</div>
<div style={{width:"50%", display:"flex"}}>

<div style={{width:"50%", paddingLeft:"5%",paddingTop:"10px",paddingRight:"5%", }}>
{usercc[0] && <>
{usercc?.map((item,index)=>{
  return <div key={index} style={{display:"flex", justifyContent:"space-between", alignItems:"center"}} >

    <span>{index+1}. {item}</span> 
    
    <button onClick={()=>{removecc(index,3)}}>Remove</button></div>
})}

</> }
   
</div>

<div style={{width:"50%", paddingLeft:"5%",paddingTop:"10px",paddingRight:"5%", }}>
{userbcc[0] && <>
{userbcc?.map((item,index)=>{
  return <div key={index} style={{display:"flex", justifyContent:"space-between", alignItems:"center"}} >

    <span>{index+1}. {item}</span> 
    
    <button onClick={()=>{removebcc(index,3)}}>Remove</button></div>
})}

</> }
   
</div>

</div>


        </div>} */}
              {/* </div> */}
              {/* email admin============================ */}

              {/* { enableadmin && <div>
        <div className=''>
            <div className='body-box-header' style={{marginLeft:"8px"}}>
            <h3 className='' style={{marginRight:"390px",marginTop:"8px"}}>Body</h3>
            <Button variant="contained" component="span" color="primary" onClick={()=>{setadminconvert(!adminconvert);sethtmlInput(adminbody)}}>
                Convert
            </Button>
            {/* <button className='bg-blue-500 border-2' onClick={()=>{setadminconvert(!adminconvert)}}> convert </button> */}
              {/* </div>
        <div className='text-area-box'>
            <textarea name="" id="" cols="70" rows="15" className='' style={{border:"2px solid lightgray",marginLeft:"10px"}} value={adminbody} onChange={(e)=>{
                console.log(e.target.value);
                setadminBody(e.target.value);
                sethashAdmin(true)
              return sethtmlInput(e.target.value)}}></textarea>
            {adminconvert && <div  style={{width:"550px",height:"234px",border:"2px solid lightgray"}} className=''>
            {parse(htmlInput)}</div>}
        </div>
        </div>
        <div>
        <span>Subject:</span>
          <input type="text" name="hyperLink" id="hyperLink" value={adminSubject} onChange={(e)=>setadminSubject(e.target.value)} style={{marginLeft:"8px",marginTop:"8px"}}  />

          <span>To:</span>
          <input type="text" name="hyperLink" id="hyperLink" value={adminto} onChange={(e)=>setadminto(e.target.value)} style={{marginLeft:"16px",marginTop:"8px"}}  />
        </div>

        <div style={{width:"50%", display:"flex"}}>

<div style={{width:"50%", }}>
<span>CC : </span>
<input className="ccs_" type="text" name="instcc"  value={adminvalcc} onChange={(e)=>setadminvalcc(e.target.value)} style={{marginLeft:"8px",marginTop:"8px"}}  />
<button className='btn_addccs' onClick={()=>{addcc(adminvalcc,2)}}>Add</button>
</div>

<div style={{width:"50%", }}>
<span>BCC : </span>
<input className="ccs_" type="text" name="admincc"  value={adminvalbcc} onChange={(e)=>setadminvalbcc(e.target.value)} style={{marginLeft:"8px",marginTop:"8px"}}  />
<button className='btn_addccs' onClick={()=>{addbcc(adminvalbcc,2)}}>Add</button>
</div>

</div>
<div style={{width:"50%", display:"flex"}}>

<div style={{width:"50%", paddingLeft:"5%",paddingTop:"10px",paddingRight:"5%", }}>
{admincc[0] && <>
{admincc?.map((item,index)=>{
  return <div key={index} style={{display:"flex", justifyContent:"space-between", alignItems:"center"}} >

    <span>{index+1}. {item}</span> 
    
    <button onClick={()=>{removecc(index,2)}}>Remove</button></div>
})} 

</> }
   
</div>

<div style={{width:"50%", paddingLeft:"5%",paddingTop:"10px",paddingRight:"5%", }}>
{adminbcc[0] && <>
{adminbcc?.map((item,index)=>{
  return <div key={index} style={{display:"flex", justifyContent:"space-between", alignItems:"center"}} >

    <span>{index+1}. {item}</span> 
    
    <button onClick={()=>{removebcc(index,2)}}>Remove</button></div>
})}

</> }
   
</div>

</div>
        
        </div>} */}

              {/* <div style={{textAlign:"right"}}>
        <LoadingButton variant="contained" loading={emailloading} loadingPosition="start" startIcon={<SaveIcon />} color="primary" onClick={postsavemail}>
            Save Email
        </LoadingButton>
        </div> */}
              {/* =============================================notification======================================================== */}
              <h1
                style={{
                  color: "#1E3758",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                }}
              >
                Notifications:{" "}
              </h1>

              <div className="user-data-bigger-container">
                <label
                  for="EnableUser"
                  style={{
                    color: "#2C343E",
                    fontFamily: "Poppins",
                    display: "inline-block",
                  }}
                >
                  {" "}
                  Notification To User:
                </label>
                <input
                  type="checkbox"
                  disabled={stateForEnableAndDiseble.userNoti}
                  checked={enableuserNot == true}
                  id="enableUser"
                  name="EnableUser"
                  className=""
                  style={{ marginLeft: "8px" }}
                  onChange={() => {
                    setEnableuserNot(!enableuserNot);
                  }}
                />
                {enableuserNot && (
                  <div>
                    <div className="">
                      <div className="body-box-header">
                        <h3
                          className=""
                          style={{
                            marginRight: "390px",
                            marginTop: "8px",
                            color: "#1E3758",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                          }}
                        >
                          Body
                        </h3>
                        <button
                          className="secondary-btn"
                          onClick={() => {
                            setuserconvertNot(!userconvertNot);
                            sethtmlInput(userbodyNot);
                          }}
                        >
                          Convert
                        </button>
                      </div>
                      <div className="body-box-header">
                        <textarea
                          name=""
                          id=""
                          cols="70"
                          rows="15"
                          style={{
                            border: "1px solid #1E3758",
                            marginTop: "20px",
                            padding: "15px",
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                            borderRadius: "6px",
                            fontFamily: "Poppins",
                          }}
                          value={userbodyNot}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setuserBodyNot(e.target.value);
                            sethashUserNot(true);
                            return sethtmlInput(e.target.value);
                          }}
                        ></textarea>
                        {userconvertNot && (
                          <div
                            style={{
                              width: "550px",
                              height: "234px",
                              border: "2px solid lightgray",
                            }}
                            className=""
                          >
                            {parse(htmlInput)}
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <span>HyperLink:</span>
          <input type="text" name="hyperLink" id="hyperLink" className='' style={{marginLeft:"8px"}} value={userHlinkNot} onChange={(e)=>setuserHlinkNot(e.target.value)}  /> */}
                  </div>
                )}
              </div>

              {/* <div className="user-data-bigger-container" style={{border:"2px solid lightgray"}}>
        <label for="EnableAdmin" className='font-bold m-2'> Notification To Admin:</label>
        <input type="checkbox" disabled={stateForEnableAndDiseble.adminNoti} checked={enableadminNot==true} id="enableAdmin" name="EnableAdmin" className='' style={{marginLeft:"8px"}}
         onChange={()=>{setEnableadminNot(!enableadminNot)
         
         }}/>
        { enableadminNot && <div>
        <div className=''>
            <div className='body-box-header' style={{marginLeft:"8px"}}>
            <h3 className='' style={{marginRight:"390px",marginTop:"8px"}}>Body</h3>
            <Button variant="contained" component="span" color="primary" onClick={()=>{setadminconvertNot(!adminconvertNot);sethtmlInput(adminbodyNot)}}>
                Convert
            </Button>
            </div>
        <div className='text-area-box'>
            <textarea name="" id="" cols="70" rows="15" className='' style={{border:"2px solid lightgray",marginLeft:"10px"}} value={adminbodyNot} onChange={(e)=>{
                console.log(e.target.value);
                setadminBodyNot(e.target.value);
                sethashAdminNot(true);
              return sethtmlInput(e.target.value)}}></textarea>
            {adminconvertNot && <div  style={{width:"550px",height:"234px",border:"2px solid lightgray"}} className=''>
            {parse(htmlInput)}</div>}
        </div>
        </div>
        <span>HyperLink:</span>
          <input type="text" name="hyperLink" id="hyperLink" className='' style={{marginLeft:"8px",marginTop:"8px"}} value={adminHlinkNot} onChange={(e)=>setadminHlinkNot(e.target.value)} />
        </div>}
        </div>

        <div className="user-data-bigger-container" style={{border:"2px solid lightgray"}}>
            <label for="EnableInst" className=''> Notification To Instructor:</label>
                <input type="checkbox" disabled={stateForEnableAndDiseble.instructorNoti} checked={enableInstNot==true} id="enableInst" name="EnableInst" className='' style={{marginLeft:"8px"}}
                onChange={()=>{setEnableInstNot(!enableInstNot)
                 
                }}/>
        { enableInstNot && <div>
        <div className=''>
            <div className='body-box-header' style={{marginLeft:"8px"}}>
            <h3 className='' style={{marginRight:"390px",marginTop:"8px"}}>Body</h3>
            <Button variant="contained" component="span" color="primary" onClick={()=>{setinstconvertNot(!instconvertNot);sethtmlInput(instbodyNot)}}>
                Convert
            </Button>
            </div>
        <div className='text-area-box'>
            <textarea name="" id="" cols="70" rows="15" className='' style={{border:"2px solid lightgray",marginLeft:"10px"}} value={instbodyNot} onChange={(e)=>{
                // console.log(e.target.value);
                setinstBodyNot(e.target.value);
                sethashInstructorNot(true);
              return sethtmlInput(e.target.value)}}></textarea>
            {instconvertNot &&
            <div  style={{width:"550px",height:"234px",border:"2px solid lightgray"}} className=''>
            {parse(htmlInput)}</div>
            }
        </div>
        </div>
        <span>HyperLink:</span>
          <input type="text" name="hyperLink" id="hyperLink" className='' style={{marginLeft:"8px",marginTop:"8px"}} value={instHlinkNot} onChange={(e)=>setinstHlinkNot(e.target.value)} />
        </div>}
        </div> */}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "30px",
                  marginTop: "30px",
                }}
              >
                <LoadingButton
                  sx={{ color: "#fff", px: "20px" }}
                  className="primary-btn"
                  loading={notloading}
                  loadingPosition="start"
                  onClick={postsaveNotificationTemplate}
                >
                  + Save Notification
                </LoadingButton>
                <Link to={"/admin/settings/email"}>
                  <button className="secondary-btn">{t("Back")}</button>{" "}
                </Link>
              </div>

              {/* =============================================Dummy data======================================================== */}

              {/* <div >
                <h1 className="bigger-container-header">Enter Dummy Data</h1>
        </div> */}

              {/* <div className=''>
        <div className='' style={{border:"2px solid lightgray"}}>

        <div className='dummy-box'>
        <div className=''><spam  style={{fontWeight:"bold"}}>%username%:</spam><input type="text" name="username" placeholder="" className='user-data-input' value={info.username}
        onChange={getinfodata}></input></div>
        <div className=''><spam  style={{fontWeight:"bold"}}>%instructorname%:</spam><input type="text" name="instructorname" placeholder="" className='user-data-input' value={info.instructorname} onChange={getinfodata}></input></div>
        <div className=''><spam  style={{fontWeight:"bold"}}>%adminname%:</spam><input type="text" name="adminname" placeholder="" className='user-data-input' value={info.adminname} onChange={getinfodata}></input></div>
        </div>

        <div className='dummy-box'>
        <div className='my-4'><spam  style={{fontWeight:"bold"}}>%coursename%:</spam><input type="text" name="coursename" placeholder="" className='user-data-input' value={info.coursename} onChange={getinfodata}></input></div>
        <div className='my-4'><spam  style={{fontWeight:"bold"}}>%groupname%:</spam><input type="text" name="groupname" placeholder="" className='user-data-input' value={info.groupname} onChange={getinfodata}></input></div>
        <div className='my-4'><spam  style={{fontWeight:"bold"}}>%lessonname%:</spam><input type="text" name="lessonname" placeholder="" className='user-data-input' value={info.lessonname} onChange={getinfodata}></input></div>
        </div>
        
        <div className='dummy-box'>
        <div className='my-4'><spam  style={{fontWeight:"bold"}}>To Dummy_user:</spam><input type="text" name="useremail" placeholder="" className='user-data-input' value={info.useremail} onChange={getinfodata}></input></div>
        <div className='my-4'><spam  style={{fontWeight:"bold"}}>To Dummy_instructor:</spam><input type="text" name="instructoremail" placeholder="" className='user-data-input' value={info.instructoremail} onChange={getinfodata}></input></div>
        <div className='my-4'><spam  style={{fontWeight:"bold"}}>To Dummy_admin:</spam><input type="text" name="adminemail" placeholder="" className='user-data-input' value={info.adminemail} onChange={getinfodata}></input></div>
        </div>
        </div>
        </div> */}

              <div className="save-dummy-box">
                {/* <LoadingButton variant="contained" loading={dummyloading} loadingPosition="start" color="primary" onClick={postdummymail}>
        sent test Email
        </LoadingButton> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EmailSettingPage;
