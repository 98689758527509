import { httpsCallable } from "firebase/functions";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import "../../../App.css";
import { getCookie } from "../../../Cookies";
import { db, functions } from "../../../Firebase";
import Navbar from "../../Component/NavbarTop";
import Sidebar from "../../Component/Sidebar";
import { Timestamp } from "firebase/firestore";
import "../StyleAdminComponents/User.css";
import "./Users.css";
import { redis } from "../../../redisFun";
import LoadingScreen from "../BasicCourse/lodingScree";
import TempFileForTest from "./tempFile";

function AllUsersFunnelView() {
  const navigate = useNavigate();
  const goToAddNewUser = (state) =>
    navigate("/admin/addNewUser", { state: { ...state } });
  const goToEditUser = (state) =>
    navigate("/admin/UsersListForFunnelView", {
      state: { ...state, courseId: courseName },
    });
  const goToHome = () => navigate("/");
  const [valueSelect, setvalue] = useState("");
  const initState = {
    startDate: new Date().setMonth(new Date().getMonth() - 2),
    endDate: new Date().toString(),
    selected: "default",
  };

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [query, setQuery] = useState(initState);
  useEffect(() => {
    if (getCookie("UID") == null || getCookie("ROLE") != "admin") {
      goToHome();
      return <></>;
    }
  }, []);
  const [showLoading, setLoading] = useState(true);

  const [userArray, setUserArray] = useState([]);
  const [details, setDetails] = useState([]);

  const getFunnelDataForcourseIdAndDateRange = httpsCallable(
    functions,
    "getFunnelDataForcourseIdAndDateRange"
  );
  const getAllCourse = httpsCallable(functions, "getAllCourse");
  const fetchQuestionSetIdOnly = httpsCallable(
    functions,
    "fetchQuestionSetIdOnly"
  );
  const getFunnlViewOfaUser = httpsCallable(functions, "getFunnlViewOfaUser");
  const { clrs } = useSelector((state) => state.createClr);

  const [courseArr, setCourseArr] = useState([]);
  const [courseName, setCourseName] = useState("");

  const handleQueryChange = (e) => {
    setQuery({
      ...query,
      [e.target.name]: e.target.value,
    });
  };
  const setDefault = () => {
    setQuery(initState);
    setDetails(userArray);

    return;
  };

  useEffect(() => {
    if (redis) {
    } else {
      setLoadingScreen(true);
      getFunnlViewOfaUser({})
        .then((res) => {
          setLoadingScreen(false);
          setDetails(res.data);
          setUserArray(res.data);
        })
        .catch((e) => {
          setLoadingScreen(false);
        });

      getAllCourse({}).then((res) => {
        console.log(res.data);
        setCourseArr(res.data.data);
      });
    }
  }, []);
  // console.log(userArray);

  const fetchCorseData = (value) => {
    setCourseName(value);
    fetchQuestionSetIdOnly({ courseId: value }).then((res) => {});
  };

  const handleChange = (event, item, id) => {
    var data = event.target.value;
    setvalue(event.target.value);

    if (data == "edit") {
      goToAddNewUser(item);
    } else if (data == "view") {
      goToEditUser(item);
    }
  };

  const filterDataFromAPi = (type) => {
    setLoadingScreen(true);
    if (type == "filter") {
      if (courseName !== "" && startDate !== "" && endDate !== "") {
        getFunnelDataForcourseIdAndDateRange({
          startDate: new Date(startDate).toString(),
          endDate: new Date(endDate).toString(),
          courseId: "data4oo8090gg9",
        })
          .then((res) => {
            setDetails(res.data);
            setLoadingScreen(false);
            console.log(res.data);
          })
          .catch((err) => {
            setLoadingScreen(false);
            console.log(err);
          });
      }
    }
    if (type == "clear") {
      setDetails(userArray);
      setCourseName("");
      setStartDate("");
      setEndDate("");
    }
  };

  return (
    <div className="admin-users">
      <Navbar page={t("Funnel View")} />
      <LoadingScreen
        open={loadingScreen}
        onClose={() => setLoadingScreen(false)}
      />
      <div className="admin-users-main">
        <Sidebar page={"user"} hidepage={false} />
        <div className="admin-overdue">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
            }}
            className="admin-dashboard-main-mid"
          >
            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("Course")}
              </div>
              <div style={{ position: "relative" }}>
                <select onChange={(e) => fetchCorseData(e.target.value)}>
                  <option>select course</option>
                  {courseArr?.map((e) => (
                    <option value={e.courseId}>{e.courseName}</option>
                  ))}
                </select>
              </div>
            </div>

            {/* <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">{t("Question Set")}</div>
              <div style={{ position: "relative" }}>
              <select>
                <option>Set A</option>
                <option>Set B</option>
                <option>Set B</option>
              </select>
                <div
                  className="admin-dashboard-main-top-last"
                  style={{
                    position: "absolute",
                    top: "-0.5vw",
                    right: "1.1vw",
                  }}
                >
                 
                </div>
              </div>
            </div> */}

            {/* <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">{t("From Date")}</div>
              <div style={{ position: "relative" }}>
                <input
                  style={{ width: "11vw" }}
                  name="employeeId"
                  value={query.employeeId}
                  type="date"
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                  placeholder="Search By Employee No."
                />
              </div>
            </div>

            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("To Date")}
              </div>
              <div style={{ position: "relative" }}>
                <input
                  style={{ width: "11vw" }}
                  name="employeeId"
                  value={query.employeeId}
                  type="date"
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                  placeholder="Search By Employee No."
                />
              </div>
            </div>

            <div
              class="admin-dashboard-main-top-last"
              style={{ height: " 16vh", width: "10vh" }}
            >
              <button
                className="primary-btn"
                onClick={() => {
                  filterDataFromAPi("filter");
                }}
              >
                {t("Filters")}
              </button>
            </div> */}

            <div class="admin-dashboard-main-top-last">
              <button
                className="clear-btn"
                style={{ marginTop: "25%" }}
                onClick={() => {
                  filterDataFromAPi("clear");
                }}
              >
                {t("Clear Filters")}
              </button>
            </div>
          </div>

          {/*     <div className="courselist-main-cnt-top-txt">Group List</div> */}

          <TempFileForTest courseId={courseName} />
        </div>
      </div>
    </div>
  );
}

export default AllUsersFunnelView;
