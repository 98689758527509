import { t } from "i18next";
import React, { useState } from "react";

import Navbar from "../../Component/NavbarTop";
import Sidebar from "../../Component/Sidebar";
import "../CourseList.css";
import { Timestamp } from "firebase/firestore";
import { redis } from "../../../redisFun";
import { getCookie } from "../../../Cookies";

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { Checkbox } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../Firebase";
import AllUsersFunnelView from "../Users/AllUserOnTheApp";
import TempFileForTest from "../Users/tempFile";
import FilterPageForCorseOverview from "./UserFilterPages";

function CourseOverView() {
  const getCourseFullData = httpsCallable(functions, "getCourseFullData");
  const getQuestionSetMarks = httpsCallable(functions, "getQuestionSetMarks");
  const { clrs } = useSelector((state) => state.createClr);
  const location = useLocation();

  let dataFromLocation = location.state;
  console.log(dataFromLocation);
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const goToBack = () => navigate("/admin/training");

  const [details, setDetails] = useState([0, 0]);
  const [videoLink, setVideoLink] = useState("");
  const [courseData, setCourseData] = useState([]);
  const [questionSetData, setQuestionSetData] = useState([]);
  // useEffect(() => {
  //   if (
  //     getCookie("UID") == null ||
  //     (getCookie("ROLE") != "admin" && getCookie("ROLE") != "instructor")
  //   ) {
  //     goToHome();
  //     return <></>;
  //   }
  // }, []);
  useEffect(() => {
    getCourseFullData({ courseId: dataFromLocation.courseId })
      .then((res) => {
        setCourseData(res.data);

        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    getQuestionSetMarks({ courseId: dataFromLocation.courseId }).then((res) => {
      console.log(res.data);
      setQuestionSetData(res.data);
    });
  }, []);

  const goToView = () => {
    navigate("/admin/groupEnrollment/view", { state: { ...details } });
  };

  return (
    <div className="courselist">
      <Navbar page={t("Training")} />
      <div
        className="courselist-main"
        style={{ gridTemplateColumns: "6vw 1fr" }}
      >
        <Sidebar page={"training"} hidepage={true} />
        <div className="courselist-main-cnt">
          <div
            style={{
              fontFamily: "Poppins",
              fontWeight: 400,
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "5px",
              margin: "2vw",
            }}
          >
            <div className="">
              <div>
                <span style={{ fontWeight: 500, fontSize: "20px" }}>
                  Course Name :
                </span>{" "}
                <span> {courseData?.courseName}</span>
              </div>
            </div>
            <div className="">
              <div className=" ">
                {" "}
                <span style={{ fontWeight: 500, fontSize: "20px" }}>
                  Course Discription :{" "}
                </span>
                <span> {courseData?.courseDiscription}</span>
              </div>
            </div>

            <div className="" style={{ marginTop: "10vh" }}>
              <div className=" ">
                {" "}
                <span style={{ fontWeight: 500, fontSize: "20px" }}>
                  Curriculam :{" "}
                </span>
                <button
                  className="primary-btn"
                  onClick={() => {
                    window.open(courseData?.courseCurriculumPDF, "_blank");
                  }}
                >
                  {" "}
                  view Pdf
                </button>
              </div>
            </div>

            <div className="" style={{ marginTop: "8vh" }}>
              <div className=" ">
                {" "}
                <span style={{ fontWeight: 500, fontSize: "20px" }}>
                  Requirments :{" "}
                </span>
                <ul style={{ marginLeft: "2%" }}>
                  <li> {courseData?.courseRequirements}</li>
                </ul>
              </div>
            </div>

            <div className="" style={{ marginTop: "30vh" }}>
              <div className=" ">
                <span style={{ fontWeight: 500, fontSize: "20px" }}>
                  promises:{" "}
                </span>
                <ul style={{ marginLeft: "2%" }}>
                  <li> {courseData?.coursePromises}</li>
                </ul>
              </div>
            </div>
          </div>

          {/* FilterPageForCorseOverview */}
          <FilterPageForCorseOverview courseId={dataFromLocation.courseId} />

          <TempFileForTest courseId={dataFromLocation.courseId} />

          {/* <div className="admin-overdue-bottom">
            <div className="admin-overdue-bottom-table">
              <div
                className="admin-overdue-bottom-table-cnt"
                style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr" }}
              >
                <div className="admin-overdue-bottom-table-head">
                  {t("SrNo")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("set Name")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("attemps")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Attempts")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Action")}
                </div>
              </div>
              
              {questionSetData?.map((item, id) => {
                
                return (
                  <div
                    className="admin-overdue-bottom-table-cnt"
                    key={id}
                    style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr" }}
                  >
                    <div className="admin-overdue-bottom-table-td">
                    
                      {id + 1}
                    </div>
                   
                    <div className="admin-overdue-bottom-table-td">
                      {item?.questionSet}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item?.totalNumberOfAttemps}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {(item?.totalMarks / item?.totalNumberOfAttemps) * 100}
                    </div>
                    <div className="admin-overdue-bottom-table-td">{""}</div>
                   
                  </div>
                );
              })}
            </div>
            <div className="admin-overdue-bottom-pagination">
              <div className="admin-overdue-bottom-pagination-cnt">
                <div className="admin-overdue-bottom-pagination-cnt-item">
                  <svg
                  
                    width="auto"
                    height="22"
                    viewBox="0 0 14 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div className="admin-overdue-bottom-pagination-cnt-item-btn">
                 
                </div>
                <div
                  style={{ marginRight: "19vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <svg
                  
                    width="auto"
                    height="20"
                    viewBox="0 0 13 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div
                  style={{ display: "flex", marginRight: "-30vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <label className="admin-row">{t("Show")}</label>
                  <select
                    style={{
                      width: "4vw",
                      margin: "0.5vw",
                      marginBottom: "1.5vw",
                      height: "1.5vw",
                    }}
                   
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                  </select>
                  <label className="admin-row"> {t("PagePerItem")}</label>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default CourseOverView;
