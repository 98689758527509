import { t } from "i18next";
import React, { useState } from "react";

import Navbar from "../../Component/NavbarTop";
import Sidebar from "../../Component/Sidebar";
import "../CourseList.css";
import { Timestamp } from "firebase/firestore";
import { redis } from "../../../redisFun";
import { getCookie } from "../../../Cookies";

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { Checkbox } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../Firebase";
import AllUsersFunnelView from "../Users/AllUserOnTheApp";
import TempFileForTest from "../Users/tempFile";
import "../StyleAdminComponents/User.css";
import "../Users/Users.css";
import LoadingScreen from "../BasicCourse/lodingScree";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import EnterBodyAndTittel from "./bodyAndti";

function FilterPageForCorseOverview({ courseId }) {
  const [loadingScreen, setLoadingScreen] = useState(false);
  const { clrs } = useSelector((state) => state.createClr);
  const navigate = useNavigate();

  const CourseOverViewFillters = httpsCallable(
    functions,
    "CourseOverViewFillters"
  );

  const uploadReports = httpsCallable(functions, "uploadReports");
  const manualNotification = httpsCallable(functions, "manualNotification");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [startDateSub, setStartDateSub] = useState(new Date());
  const [endDateSub, setEndDateSub] = useState(new Date());

  const [startDateDoc1, setStartDateDoc1] = useState(new Date());
  const [endDateDoc1, setEndDateDoc1] = useState(new Date());

  const [startDateDoc2, setStartDateDoc2] = useState(new Date());
  const [endDateDoc2, setEndDateDoc2] = useState(new Date());

  const [startDatePay, setStartDatePay] = useState(new Date());
  const [endDatePay, setEndDatePay] = useState(new Date());

  const [startDateApp, setStartDateApp] = useState(new Date());
  const [endDateApp, setEndDateApp] = useState(new Date());

  const [openLessonPopUp, setopenLessonPopUp] = useState(false);
  const [query, setQuery] = useState({
    userName: "",
    emailId: "",
    userMobile: "",
  });
  const [details, setdetails] = useState([]);

  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [arrLength, setArrLength] = useState(0);

  const [extraArr, setExtraArr] = useState([]);
  let stobj = {
    objectiveTestStatus: "",
    subjectiveTestStatus: "",
    documentsVerificationStatus: "",
    paymentStatus: "",
    agreementStatus: "",
    approvalStatus: "",
  };
  const [filterState, setFilterState] = useState(stobj);

  const [dateFiledData, setDateFiledData] = useState("");
  const getDataapi = () => {
    setLoadingScreen(true);
    CourseOverViewFillters({
      courseId: courseId,

      objectiveTestStatus: filterState.objectiveTestStatus,
      subjectiveTestStatus: filterState.subjectiveTestStatus,
      documentsVerificationStatus: filterState.documentsVerificationStatus,
      paymentStatus: filterState.paymentStatus,
      agreementStatus: filterState.agreementStatus,
      approvalStatus: filterState.agreementStatus,
      dateFiledData: dateFiledData,
      startDate: startDate,

      endDate: endDate,
    })
      .then((res) => {
        setLoadingScreen(false);
        if (res.data != null) {
          console.log(res.data);
          setArrLength(res.data.length);
          setExtraArr(res.data.map((item) => ({ ...item, selected: false })));
          setdetails(res.data.map((item) => ({ ...item, selected: false })));
        } else {
          setArrLength(0);
          setdetails([]);
          setExtraArr([]);
        }
      })
      .catch((e) => {
        setLoadingScreen(false);
      });
  };

  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(arrLength / pageSize)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };

  const clearFillter = () => {
    setFilterState({
      objectiveTestStatus: "",
      subjectiveTestStatus: "",
      documentsVerificationStatus: "",
      paymentStatus: "",
      agreementStatus: "",
      approvalStatus: "",
    });

    setDateFiledData("");
    CourseOverViewFillters({
      courseId: courseId,

      objectiveTestStatus: "",
      subjectiveTestStatus: "",
      documentsVerificationStatus: "",
      paymentStatus: "",
      agreementStatus: "",
      approvalStatus: "",
      dateFiledData: "",
    }).then((res) => {
      if (res.data != null) {
        console.log(res.data);
        setArrLength(res.data.length);
        setdetails(res.data.map((item) => ({ ...item, selected: false })));
      } else {
        setArrLength(0);
        setdetails([]);
      }
    });
    //setTimeout()
  };

  useEffect(() => {
    getDataapi();
   
  }, []);

  const handleQueryChange = (e) => {
    setQuery({ ...query, [e.target.name]: e.target.value });
  };

  const handerEmailANdPhone = (type) => {
    if (type == "userName") {
      let arr = extraArr.filter((e) =>
        e.name.toLowerCase().includes(query.userName.toLowerCase())
      );

      setdetails(arr);
    }

    if (type == "emailId") {
      let arr = extraArr.filter((e) => e.email.includes(query.emailId));

      setdetails(arr);
    }

    if (type == "userMobile") {
      let arr = extraArr.filter((e) =>
        e.phoneNumber.includes(query.userMobile)
      );

      setdetails(arr);
    }
    if (type == "clear") {
      setdetails(extraArr);
      setQuery({
        userName: "",
        userMobile: "",
        emailId: "",
      });
    }
  };

  const checkBoxHelder = (data, item, type) => {
    if (type == "all") {
      let temparr = details.map((e) => ({ ...e, select: item.target.checked }));
      setdetails(temparr);
    }

    if (type == "one") {
      let temparr = details.map((e) => {
        if (e.uid == data.uid) {
          return { ...e, select: item.target.checked };
        } else {
          return e;
        }
      });
      setdetails(temparr);
    }
  };

  const generateReport = () => {
    let fil = [];

    let userList = details.filter((e) => e.select == true);
    if (userList.length > 0) {
      // setopenLessonPopUp(true)
    } else {
      alert("select user");
      return;
    }

    setLoadingScreen(true);
    for (let i = 0; i < details.length; i++) {
      if (details[i].select == true) {
        let obj = {};
        obj["name"] = details[i]?.name;
        obj["id"] = details[i].uid;

        obj["email"] = details[i]?.email;
        obj["phoneNumber"] = details[i]?.phoneNumber;
        obj["courseId"] = details[i]?.courseId;

        obj["status"] = details[i]?.userStatus;
        fil.push(obj);
      }
    }
    let time = new Date().getTime();
    uploadReports({
      id: "testReport" + time,
      data: fil,
      reportType: "Entrence Course Report",
      userId: "RAHUL",
    })
      .then((res) => {
        setLoadingScreen(false);
        console.log(res.data);
        alert("genreted");
      })
      .catch((e) => {
        setLoadingScreen(false);
      });
    console.log(fil);
  };

  const sendNotification = () => {
    let userList = details.filter((e) => e.select == true);
    if (userList.length > 0) {
      setopenLessonPopUp(true);
    } else {
      alert("select user");
      return;
    }
  };

  const delfunction = (name) => {
    console.log(name);
    setopenLessonPopUp(false);
    let userList = details.filter((e) => e.select == true);

    manualNotification({
      body: name.body,
      trigger: name.title,
      userList: userList,

      params: {
        courseId: courseId,
      },
    });
  };
  const goToEditUser = (state) =>
    navigate("/admin/userview", { state: { ...state } });
  const handleChange=(type,item)=>{

    let data=type.target.value;

    goToEditUser(item)
  }
  return (
    <div className="courselist">
      <LoadingScreen
        open={loadingScreen}
        onClose={() => setLoadingScreen(false)}
      />

      {/* <EnterBodyAndTittel open={openLessonPopUp} onClose={() => setopenLessonPopUp(false)}/> */}
      <EnterBodyAndTittel
        open={openLessonPopUp}
        onClose={() => setopenLessonPopUp(false)}
        delfun={(topic) => {
          delfunction(topic);
        }}
      />

      <div className="admin-overdue">
        <div
          style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr" }}
          className="admin-dashboard-main-mid"
        >
          <div className="admin-dashboard-main-mid-item">
            <div className="admin-dashboard-main-mid-item-txt">
              {t("Objective test status")}
            </div>
            <div style={{ position: "relative" }}>
              <select
                style={{ width: "80%" }}
                value={filterState.objectiveTestStatus}
                onChange={(e) => {
                  setFilterState({
                    ...filterState,
                    objectiveTestStatus: e.target.value,
                  });
                }}
              >
                {/* pending	-->	started	-->	rejected	-->	passed */}

                <option value="">Select</option>
                <option value="pending">Pending</option>
                <option value="started">Started</option>
                <option value="passed">Passed</option>
                <option value="rejected">Rejected</option>
              </select>
            </div>
          </div>

          {/* <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">{t("Objective Test Status Date Range")}</div>
              <div style={{ position: "relative",display:"flex" }}>
              

              
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
      />
      <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
      />
    

               
              </div>
            </div> */}

          <div className="admin-dashboard-main-mid-item">
            <div className="admin-dashboard-main-mid-item-txt">
              {t("Subjective test status")}
            </div>
            <div style={{ position: "relative" }}>
              <select
                style={{ width: "80%" }}
                value={filterState.subjectiveTestStatus}
                onChange={(e) => {
                  setFilterState({
                    ...filterState,
                    subjectiveTestStatus: e.target.value,
                  });
                }}
              >
                {/* pending	-->	started	-->	given	-->	rejected	-->	passed */}

                <option value="">Select</option>
                <option value="pending">Pending</option>
                <option value="started">Started</option>
                <option value="given">given</option>
                <option value="passed">Passed</option>
                <option value="rejected">Rejected</option>
              </select>
            </div>
          </div>

          {/* <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">{t("Subjective Test Status Date Range")}</div>
              <div style={{ position: "relative",display:"flex" }}>
              

              
      <DatePicker
        selected={startDateSub}
        onChange={(date) => setStartDateSub(date)}
        selectsStart
        startDate={startDateSub}
        endDate={endDateSub}
      />
      <DatePicker
        selected={endDateSub}
        onChange={(date) => setEndDateSub(date)}
        selectsEnd
        startDate={startDateSub}
        endDate={endDateSub}
        minDate={startDateSub}
      />
    

               
              </div>
            </div> */}

          <div className="admin-dashboard-main-mid-item">
            <div className="admin-dashboard-main-mid-item-txt">
              {t("Registration details status")}
            </div>
            <div style={{ position: "relative" }}>
              <select
                style={{ width: "80%" }}
                value={filterState.documentsVerificationStatus}
                onChange={(e) => {
                  setFilterState({
                    ...filterState,
                    documentsVerificationStatus: e.target.value,
                  });
                }}
              >
                <option value="">Select</option>
                <option value="pending">Pending</option>
                <option value="uploaded">uploaded</option>
                <option value="reupload">reupload</option>
                <option value="passed">Passed</option>
                <option value="rejected">Rejected</option>
              </select>
            </div>
          </div>

          {/* <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">{t("Document 1st Level Status Date Range")}</div>
              <div style={{ position: "relative",display:"flex" }}>
              

              
      <DatePicker
        selected={startDateDoc1}
        onChange={(date) => setStartDateDoc1(date)}
        selectsStart
        startDate={startDateDoc1}
        endDate={endDateDoc1}
      />
      <DatePicker
        selected={endDateDoc1}
        onChange={(date) => setEndDateDoc1(date)}
        selectsEnd
        startDate={startDateDoc1}
        endDate={endDateDoc1}
        minDate={startDateDoc1}
      />
    

               
              </div>
            </div> */}

          <div className="admin-dashboard-main-mid-item">
            <div className="admin-dashboard-main-mid-item-txt">
              {t("Documents status")}
            </div>
            <div style={{ position: "relative" }}>
              <select
                style={{ width: "80%" }}
                value={filterState.agreementStatus}
                onChange={(e) => {
                  setFilterState({
                    ...filterState,
                    agreementStatus: e.target.value,
                  });
                }}
              >
                {/* pending	-->	uploaded	-->	reupload	-->	passed	-->	rejected */}

                <option value="">Select</option>
                <option value="pending">pending</option>

                <option value="uploaded">Uploaded</option>
                <option value="reupload">Reupload</option>

                <option value="passed">passed</option>
                <option value="rejected">Rejected</option>
              </select>
            </div>
          </div>

          <div className="admin-dashboard-main-mid-item">
            <div className="admin-dashboard-main-mid-item-txt">
              {t("Payment status")}
            </div>
            <div style={{ position: "relative" }}>
              {/* <input
                  style={{ width: "11vw" }}
                  name="emailId"
                //   value={query.emailId}
                  type="text"
                //   onChange={handleQueryChange}
                  placeholder="Search By Employee No."
                /> */}

              <select
                style={{ width: "80%" }}
                value={filterState.paymentStatus}
                onChange={(e) => {
                  setFilterState({
                    ...filterState,
                    paymentStatus: e.target.value,
                  });
                }}
              >
                {/* pending	-->	failed	-->	passed	-->	refunded */}

                <option value="">Select</option>
                <option value="pending">Pending</option>
                <option value="failed">Failed</option>

                <option value="passed">Passed</option>
                <option value="refunded">Refunded</option>
              </select>
              {/* <div
                  className="admin-dashboard-main-top-last"
                  style={{
                    position: "absolute",
                    top: "-0.5vw",
                    right: "12vw",
                    
                  }}
                >
                  <button
                    style={{
                      background: `${
                        clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
                      }`,
                      height: "4.1vh",
                      borderRadius: "0px 6px 6px 0px",
                    }}
                    // onClick={() => handerEmailANdPhone("emailId")}
                  >
                    <div style={{ marginRight: "-2.9vw" }}>{t("Search")}</div>
                  </button>
                </div> */}
            </div>
          </div>

          <div className="admin-dashboard-main-mid-item">
            <div className="admin-dashboard-main-mid-item-txt">
              {t("Final approval status")}
            </div>
            <div style={{ position: "relative" }}>
              <select
                style={{ width: "80%" }}
                value={filterState.approvalStatus}
                onChange={(e) => {
                  setFilterState({
                    ...filterState,
                    approvalStatus: e.target.value,
                  });
                }}
              >
                <option value="">Select</option>
                <option value="pending">Pending</option>

                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
            </div>
          </div>

          <div className="admin-dashboard-main-mid-item">
            <div className="admin-dashboard-main-mid-item-txt">
              {t("Select Date Filed")}
            </div>
            <div style={{ position: "relative" }}>
              <select
                style={{ width: "80%" }}
                value={dateFiledData}
                onChange={(e) => {
                  setDateFiledData(e.target.value);
                }}
              >
                <option value="">Select</option>
                <option value="appliedDate">
                User Applied in this Course
                </option>
                <option value="objectiveTestStatusDate">
                  Objective Test 
                </option>

                <option value="subjectiveTestStatusDate">
                  Subjective Test 
                </option>
                <option value="documentsVerificationStatusDate">
                Registration Form 
                </option>
                <option value="paymentStatusDate">Deposit Registration Fee</option>

                <option value="agreementStatusDate">
                Documents Submission
                </option>
                <option value="approvalStatusDate">Admission Approval</option>
              </select>
            </div>
          </div>

          <div className="admin-dashboard-main-mid-item">
            <div className="admin-dashboard-main-mid-item-txt">
              {t("Start Date Range")}
            </div>
            <div
              style={{ position: "relative", display: "flex", width: "200px" }}
            >
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          </div>

          <div className="admin-dashboard-main-mid-item">
            <div className="admin-dashboard-main-mid-item-txt">
              {t("End Date Range")}
            </div>
            <div
              style={{ position: "relative", display: "flex", width: "200px" }}
            >
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
            </div>
          </div>

          <div
            class="admin-dashboard-main-top-last"
            style={{
              height: "16vh",
              width: "20vw",
              display: "flex",
              gap: "10px",
              marginLeft: "4%",
            }}
          >
            <button className="primary-btn" onClick={getDataapi}>
              {t("Filter")}
            </button>

            <button className="clear-btn" onClick={clearFillter}>
              {t("Clear Filters")}
            </button>
          </div>
        </div>

        {/* SECOND FILLTERS IN THE PAGE  */}

        <div
          style={{ display: "grid", gridTemplateColumns: "1fr 1fr  1fr 1fr" }}
          className="admin-dashboard-main-mid"
        >
          <div className="admin-dashboard-main-mid-item">
            <div className="admin-dashboard-main-mid-item-txt">
              {t("User Name")}
            </div>
            <div style={{ position: "relative" }}>
              <input
                style={{ width: "50%", outline: "none" }}
                name="userName"
                value={query.userName}
                type="text"
                onChange={handleQueryChange}
                placeholder="Search By User Name"
              />

              <div
                className="admin-dashboard-main-top-last"
                style={{
                  position: "absolute",
                  top: "-0.5vw",
                  right: "4.5vw",
                }}
              >
                <button
                  className="primary-btn-input"
                  onClick={() => handerEmailANdPhone("userName")}
                >
                  {t("Search")}
                </button>
              </div>
            </div>
          </div>

          <div className="admin-dashboard-main-mid-item">
            <div className="admin-dashboard-main-mid-item-txt">
              {t("User Email")}
            </div>
            <div style={{ position: "relative" }}>
              <input
                style={{ width: "50%", outline: "none" }}
                name="emailId"
                value={query.emailId}
                type="text"
                onChange={handleQueryChange}
                placeholder="Search By User Email No."
              />

              <div
                className="admin-dashboard-main-top-last"
                style={{
                  position: "absolute",
                  top: "-0.5vw",
                  right: "4.5vw",
                }}
              >
                <button
                  className="primary-btn-input"
                  // onClick={() => handerEmailANdPhone("emailId")}
                >
                  {t("Search")}
                </button>
              </div>
            </div>
          </div>

          <div className="admin-dashboard-main-mid-item">
            <div className="admin-dashboard-main-mid-item-txt">
              {t("Mobile Number")}
            </div>
            <div style={{ position: "relative" }}>
              <input
                style={{ width: "50%", outline: "none" }}
                name="userMobile"
                value={query.userMobile}
                type="text"
                onChange={handleQueryChange}
                placeholder="Search By User Mobile Number "
              />

              <div
                className="admin-dashboard-main-top-last"
                style={{
                  position: "absolute",
                  top: "-0.5vw",
                  right: "4.5vw",
                }}
              >
                <button
                  className="primary-btn-input"
                  onClick={() => handerEmailANdPhone("userMobile")}
                >
                  {t("Search")}
                </button>
              </div>
            </div>
          </div>

          <div
            class="admin-dashboard-main-top-last"
            style={{
              height: "16vh",
              width: "20vw",
              display: "flex",
              gap: "10px",
              marginLeft: "4%",
            }}
          >
            <button
              className="primary-btn"
              // onClick={setDefault}
            >
              {t("Filter")}
            </button>

            <button
              className="clear-btn"
              onClick={(e) => {
                handerEmailANdPhone("clear");
              }}
            >
              {t("Clear Filters")}
            </button>
          </div>
        </div>

        <div
          class="admin-dashboard-main-top-last"
          style={{
            margin: "0 2vw",
            display: "flex",
            gap: "10px",
          }}
        >
          <button className="primary-btn" onClick={sendNotification}>
            {t("Send Notification")}
          </button>

          <button className="primary-btn" onClick={generateReport}>
            {t("Generate Pdf")}
          </button>
        </div>

        {/* USER DETELS DATA  */}

        <div
          style={{ margin: "0vw 2vw 2vw 2vw" }}
          className="admin-overdue-bottom"
        >
          <div className="admin-overdue-bottom-table">
            <div
              className="admin-overdue-bottom-table-cnt-usr"
              style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr" }}
            >
              <div className="admin-overdue-bottom-table-head">
                <input
                  type="checkbox"
                  onClick={(e) => {
                    checkBoxHelder("", e, "all");
                  }}
                />
                {t("SN")}
              </div>
              <div className="admin-overdue-bottom-table-head">{t("Name")}</div>
              <div className="admin-overdue-bottom-table-head">
                {t("Email")}
              </div>
              <div className="admin-overdue-bottom-table-head">
                {t("Mobail Number")}
              </div>
              <div className="admin-overdue-bottom-table-head">
                {t("Current Status")}
              </div>

              <div className="admin-overdue-bottom-table-head">
                {t("Action")}
              </div>
            </div>

            {details ? (
              details.map((item, id) => {
                if (id < pageSize * currentPage) return;
                if (id >= pageSize * (currentPage + 1)) return;
                // let joiningDate = Timestamp.fromMillis(item?.joiningDate?._seconds * 1000);
                return (
                  <div
                    className="admin-overdue-bottom-table-cnt-usr"
                    key={id}
                    style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr" }}
                  >
                    <div
                      className="admin-overdue-bottom-table-td"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "20px 20px",
                        margin: "auto",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={item.select ? true : false}
                        onClick={(e) => {
                          checkBoxHelder(item, e, "one");
                        }}
                      />
                      <div> {id + 1}</div>
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item?.name}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item?.email}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item?.phoneNumber}
                    </div>
                    {/*                    
                    {
                      item?.courseCategory?  <div className="admin-overdue-bottom-table-td">{ cateGoryData[item.courseCategory]}</div>: <div className="admin-overdue-bottom-table-td">{}</div>
                    } */}

                    <div className="admin-overdue-bottom-table-td">
                      {item?.userStatus}
                    </div>

                    <div
                      className="admin-overdue-bottom-table-td"
                      style={{ color: "#004577" }}
                    >
                      <select
                        style={{
                          border: "none",
                          backgroundColor: "#F2F2F2",
                          height: "4vh",
                          width: "7vw",
                          padding: "0 1vh",
                          fontWeight: "700",
                          color: "#717579",
                          borderRadius: "6px",
                        }}
                          onChange={(event) => handleChange(event, item,id)}
                      >
                        <option /* value={details.groupName} */>
                          {t("Action")}
                        </option>

                        <option value={"view"} >
                          {t("view")}
                        </option>
                      </select>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No user </p>
            )}
          </div>

          {/* USER DATA PAGE NAVIGATION0 */}

          <div className="admin-overdue-bottom-pagination">
            <div className="admin-overdue-bottom-pagination-cnt">
              <div className="admin-overdue-bottom-pagination-cnt-item">
                <svg
                  onClick={() => clickhandler(currentPage - 1)}
                  width="auto"
                  height="22"
                  viewBox="0 0 14 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                    fill="#717579"
                  />
                </svg>
              </div>
              <div className="admin-overdue-bottom-pagination-cnt-item-btn">
                {currentPage + 1} of {Math.ceil(arrLength / pageSize)}
                {/* {"currentPage" + 1} of {Math.ceil(9 / 3)} */}
              </div>
              <div
                style={{ marginRight: "19vw" }}
                className="admin-overdue-bottom-pagination-cnt-item"
              >
                <svg
                  onClick={() => clickhandler(currentPage + 1)}
                  width="auto"
                  height="20"
                  viewBox="0 0 13 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                    fill="#717579"
                  />
                </svg>
              </div>
              <div
                style={{ display: "flex", marginRight: "-23vw" }}
                className="admin-overdue-bottom-pagination-cnt-item"
              >
                <label className="admin-row">{t("Show")}</label>
                <select
                  style={{
                    width: "4vw",
                    margin: "0.5vw",
                    marginBottom: "1.5vw",
                    height: "1.5vw",
                  }}
                  value={pageSize}
                  onChange={(e) => setPageSize(e.target.value)}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </select>
                <label className="admin-row"> {t("PagePerItem")}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterPageForCorseOverview;
