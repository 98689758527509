import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";
import { useSelector } from "react-redux";

const TempFIle = ({ uid,data,flag }) => {
  const { clrs } = useSelector((state) => state.createClr);
  const [training, setTraining] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [arrLength, setArrLength] = useState(0);
 
  const userAppliedCourseUpdate =httpsCallable(functions,"userAppliedCourseUpdate")
  const [objData,setObjData] =useState([])
 


console.log(objData)
  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(data.length>0&&data[0]?.subjectiveAns?.length / 1)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };

  const windoopen=(type,file)=>{

    window.open(file)
  }


  const submitUserDetails=()=>{
    alert("updated")
  }

  const updateFunction=(i,ckeck,item)=>{

    console.log(ckeck)
    let newObj={...item}
    newObj.checked=ckeck
    objData[i]=newObj
   let arr= objData.map((e,id)=>{
      if(i==id){
        return newObj
      }else{
        return e
      }
    })
    setObjData(arr)

  }

  

  return (
    <div className="admin-overdue-bottom">

      
      <div className="admin-overdue-bottom-table">
       
        { data.length>0&&data[0]?.subjectiveAns?.map((item, id) => {
          if (id < 1 * currentPage) return;
          if (id >= 1 * (currentPage + 1)) return;
          return (
           <div style={{display:"grid",gridTemplateColumns:"1fr 1fr",gridGap:"40px"}}>
            {
              item.questionType=="video"&& <div>
                <h1> Video Question</h1>
              <video width="300" height="240" controls>
              <source  src={item.questionData} type="video/mp4"/>
        <source  src={item.questionData} type="video/ogg"/>
          Your browser does not support the video tag.
             </video>
  
  
             <p>Date:20-20-2220</p>
              </div>
            }

{
              item.questionType=="file"&& <div style={{marginTop:"5vh"}}>
                  <h1> File Question</h1>
           <iframe src={item.questionData} title="pdf"></iframe>
  
             <p>Date:20-20-2220</p>
             <button onClick={()=>{windoopen("file",item.questionData)}}>view In a new Tab</button>
              </div>
            }
          


          {
              item.questionType=="text"&& <div>
                  <h1> Text Question</h1>
             <p style={{margin:"10%"}}> {item.questionData}</p>
   
             <p style={{margin:"10%"}}>Date:20-20-2220</p>
              </div>
            }
            <div>
            {
              item.ansType=="video"&& <div>
                  <h1> Video Answer</h1>
              <video width="300" height="240" controls>
              <source  src={item.ansData} type="video/mp4"/>
        <source  src={item.ansData} type="video/ogg"/>
          Your browser does not support the video tag.
             </video>
  
  
             <p>Date:20-20-2220</p>
              </div>
            }

{
              item.ansType=="file"&& <div style={{marginTop:"6vh"}}>
                     <h1> File Answer</h1>
           <iframe src={item.ansData} title="pdf"></iframe>
  
             <p>Date:20-20-2220</p>
             <button onClick={()=>{windoopen("file",item.ansData)}}>view In a new Tab</button>
              </div>
            }
          


          {
              item.ansType=="text"&& <div >
                     <h1> Text Answer</h1>
             <p style={{margin:"10%"}}>  {item.ansData}</p>
   
             <p>Date:20-20-2220</p>
              </div>
            }
            </div>


           </div>
          );
        })}
        {loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        {!training && !loading && (
          <div style={{ width: "100%", textAlign: "center", color: "#1e1e1e" }}>
            No Records found
          </div>
        )}
      </div>
      <div className="admin-overdue-bottom-pagination">
        <div className="admin-overdue-bottom-pagination-cnt">
          <div className="admin-overdue-bottom-pagination-cnt-item">
            <svg
              onClick={() => clickhandler(currentPage - 1)}
              width="auto"
              height="22"
              viewBox="0 0 14 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                fill="#717579"
              />
            </svg>
          </div>
          <div className="admin-overdue-bottom-pagination-cnt-item-btn">
            {currentPage + 1} of {  data.length>0&&data[0]?.subjectiveAns?.length?Math.ceil(data.length>0&&data[0]?.subjectiveAns?.length/1):0}
          </div>
          <div className="admin-overdue-bottom-pagination-cnt-item">
            <svg
              onClick={() => clickhandler(currentPage + 1)}
              width="auto"
              height="20"
              viewBox="0 0 13 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                fill="#717579"
              />
            </svg>
          </div>
        </div>
      </div>


      <div>


        <div>
          <label> Overall marks : </label>
          <input type="Number"/>
        </div>
        <div>
          <label> Status : </label>
          <select
         //  value={userStatusSelect.status}
          // onChange={(e)=>{setUserStatusSelect({...userStatusSelect , status:e.target.value})}}
           >
            <option value="">select option </option>
            
            <option value="SubjectiveTestPassed"> Subjective Test Passed</option>
           
            <option value="SubjectiveTestFailed">Subjective Test Failed</option>
           
           </select>
        </div>
        <div>
          <label> Result: </label>
          <select>
            <option value="pass">Pass </option>
            <option value="fail">Fail </option>
           </select>
        </div>


        <div>
          <label> Comments: </label>
         <textarea 
         type="text"
         width="200px"
         height="50px"
         />
        </div>

        <div>
          <button onClick={()=>{submitUserDetails()}} style={{margin:"20px",width:"150px",height:"30px",borderRadius:"10px", background: `${clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"}`,color:"white"}}> Update User details</button>
        </div>
      </div>
    </div>
  );
};

export default TempFIle;
