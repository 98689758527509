import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getStorage, ref } from "firebase/storage";

import { getFunctions } from "firebase/functions";

import { getAuth } from "firebase/auth";
let firebaseConfig;

firebaseConfig = {
  apiKey: "AIzaSyCfneuERCyE9lZLuSmogfiH-btjslGWLBE",

  authDomain: "user-engagement-370308.firebaseapp.com",

  projectId: "user-engagement-370308",

  storageBucket: "user-engagement-370308.appspot.com",

  messagingSenderId: "987195923925",

  appId: "1:987195923925:web:3bad5396ae0e1f5de1315c",

  measurementId: "G-FVG20TG8NH",
};

// Use this to initialize the firebase App

// Use these for db & auth

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
/* const auth = app.auth(); */
const storage = getStorage(app);

const auth = getAuth(app);

export const functions = getFunctions(app, "asia-south1");
export { auth, db, storage };
