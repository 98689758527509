import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCookie } from "../../../Cookies";
import "../Dashboard/OverdueEnrollments.css";
import Navbar from "../../Component/NavbarTop";
import Sidebar from "../../Component/Sidebar";
import "./UserView.css";
import { useLocation, useNavigate } from "react-router";
import { Link, useParams } from "react-router-dom";
import "../StyleAdminComponents/GroupList.css";
import InternalTraining from "./Components/InternalTraining";
import InternalOfflineTraining from "./Components/InternalOfflineTraining";
import ExternalTraining from "./Components/ExternalTraining";
import PreviousTraining from "./Components/PreviousTraining";
import Certificates from "./Components/Certificates";
import DocumentsOfUser from "./Components/documents";
import TempFIle from "./Components/tempFile";
import PaymentDetails from "./Components/paymentDetels";
import ChnageUserStatus from "./Components/chengeUserStatus";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import { Box } from "@mui/system";
import LoadingScreen from "../BasicCourse/lodingScree";
import MileStoneObjective from "./mileStonecom/objTest";
import SubmileStoneTest from "./mileStonecom/subjectiveTest";

function MileStoneView() {
  const navigate = useNavigate();
  const goToCer = () => navigate("/admin/certificateRenewal/:id");
  const location = useLocation();
  const getUserAppliedCouses = httpsCallable(functions, "getUserAppliedCouses");

  const getUserInformetionData = httpsCallable(
    functions,
    "getUserInformetionData"
  );
  const getUserSubmitedMileStone = httpsCallable(
    functions,
    "getUserSubmitedMileStone"
  );
  const userEnrollment = httpsCallable(functions, "userEnrollment");
  console.log(location);
  const userDetails = location.state;
  const { clrs } = useSelector((state) => state.createClr);
  const [arr, setArr] = useState();
  const [pageNo, setPageNo] = useState();
  // const [curPage, setCurPage] = useState();
  const [popup, setpopup] = useState(false);
  const [open, setOpen] = useState(false);
  const [courseId, setCourseId] = useState("");
  // const { t } = useTranslation();
  console.log("userDetails", userDetails);
  const getUserSubmitionCourses = httpsCallable(
    functions,
    "getUserSubmitionCourses"
  );
  const getUserMileStone = httpsCallable(functions, "getUserMileStone");
  const getUserHistory = httpsCallable(functions, "getUserHistory");
  const [detaildData, setDetaildData] = useState([]);
  const [submitionId, setSubmitionId] = useState("");

  const [loadingScreen, setLoadingScreen] = useState(false);
  const [flagForEDit, setFlagForEDit] = useState(false);
  const [courseArray, setCourseArray] = useState([]);
  const [submitionArr, setSubmitionArr] = useState([]);
  const [mileStoneArr, setMileStoneArr] = useState([]);
  const [userHistory, setUserHistory] = useState([]);

  const [userDocument, setUserDocument] = useState({});
  const [userdataFlag, setUserdataFlag] = useState(false);

  useEffect(() => {
    setLoadingScreen(true);

    getUserSubmitedMileStone({
      uid: userDetails.uid,

      courseId: userDetails.mile?.courseId,
      groupId: userDetails.mile?.groupId,
      milestoneId: userDetails.mile?.id,
    })
      .then((res) => {
        console.log(res.data);
        setCourseArray(res.data);
        setDetaildData(res.data);
        setFlagForEDit(true);
        setLoadingScreen(false);
      })
      .catch((e) => {
        setLoadingScreen(false);
      });
  }, []);

  const goToHome = () => navigate("/");

  useEffect(() => {
    if (getCookie("UID") == null || getCookie("ROLE") != "admin") {
      goToHome();
      return <></>;
    }
  });
  const { id } = useParams();

  return (
    <div className="section" style={{ backgroundColor: "#e5e5e5" }}>
      <Navbar page={t("user")} />
      <LoadingScreen
        open={loadingScreen}
        onClose={() => setLoadingScreen(false)}
      />
      <div className="createcourse-main">
        <Sidebar page={"user"} hidepage={true} />
        <div className="admin-overdue">
          <MileStoneObjective uid={userDetails.uid} data={detaildData} />

          {detaildData.length > 0 ? (
            <SubmileStoneTest
              uid={userDetails.uid}
              data={detaildData}
              flag={flagForEDit}
            />
          ) : (
            <TempFIle uid={userDetails.uid} data={detaildData} />
          )}
        </div>
        <div
          style={{ height: "100%", width: "6vw", backgroundColor: "white" }}
        ></div>
        <div className="courselist-main-cnt-back">
          <div className="courselist-main-cnt-back-btn">
            <button
              className="secondary-btn"
              onClick={() => {
                navigate(-1);
              }}
            >
              {t("Back")}
            </button>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MileStoneView;
