//import * as functions from 'firebase-functions';
import React, { useState, useEffect, useRef } from "react";
import styledComponents from "styled-components";
import "../style/User.css";
import { collection, addDoc } from "firebase/firestore";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import {
  isSignInWithEmailLink,
  createUserWithEmailAndPassword,
  signInWithEmailLink,
} from "firebase/auth";
import { sendPasswordResetEmail } from "firebase/auth";
import $ from "jquery";
import { useLocation, useNavigate } from "react-router";
import { getCookie } from "../../Cookies";
import { auth, db, functions } from "../../Firebase";
import { t } from "i18next";
import Sidebar from "../Component/Sidebar";
import Navbar from "../Component/NavbarTop";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { httpsCallable } from "firebase/functions";
import { display } from "@mui/system";
import { Box, Button, Typography } from "@mui/material";

const AddNewUser = ({ handleLogout }) => {
  const { clrs } = useSelector((state) => state.createClr);
  const spanStyle = {
    position: "absolute",
    background: "black",
    paddingLeft: "1vw",
    marginTop: "-0.2vw",
    borderRadius: "0px 5px 5px 0px",
    width: "45vw",
    color: "white",
    marginLeft: "-0.3vw",
  };
  const spanStyleINS = {
    position: "absolute",
    background: "black",
    paddingLeft: "1vw",
    marginTop: "0.4vw",
    borderRadius: "0px 5px 5px 0px",
    width: "45vw",
    color: "white",
    marginLeft: "-0.3vw",
  };
  const location = useLocation();
  const editUserState = location.state;
  //console.log(editUserState);
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const [isUser, setIsUser] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [departmentList, setDepartmentList] = useState(null);
  const [isInstructor, setIsInstructor] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  //const [disabledButt,setDisabledButt]=useState(false)
  let disabledButt = false;
  const createUser = httpsCallable(functions, "createUser");

  const updateUser = httpsCallable(functions, "updateUser");
  const fetchDepartment = httpsCallable(functions, "fetchDepartment");
  const fetchCompany = httpsCallable(functions, "fetchCompany");

  if (editUserState) {
    //setDisabledButt(true)
    disabledButt = true;
  }

  //console.log(location.state);
  const handleMouseOver = (data) => {
    if (data == "User") {
      setIsUser(true);
    } else if (data == "Admin") {
      setIsAdmin(true);
    } else if (data == "Instructor") {
      setIsInstructor(true);
    }
  };

  const handleMouseOut = (data) => {
    if (data == "User") {
      setIsUser(false);
    } else if (data == "Admin") {
      setIsAdmin(false);
    } else if (data == "Instructor") {
      setIsInstructor(false);
    }
  };
  useEffect(() => {
    if (getCookie("UID") == null || getCookie("ROLE") != "admin") {
      goToHome();
      return <></>;
    }
  });
  const goToUser = () => navigate("/admin/users");

  const [fullname, setFullName] = useState("");
  const [company, setCompany] = useState("default");
  const [companyDetails, setCompanyDetails] = useState(null);
  const [department, setDepartment] = useState([]);
  const [jobTitle, setJobTitle] = useState([]);
  const [joiningDate, setJoiningDate] = useState([]);
  const [email, setEmail] = useState([]);
  const [employeeId, setEmployeeId] = useState([]);
  const [role, setRole] = useState([]);
  const [error, setError] = useState();
  const [signData, setSignData] = useState({ base64: "", url: "" });
  const [toggelpage, settogalpage] = useState(true);
  const inputRef = useRef(null);
  const onImageClick = () => {
    inputRef.current.click();
  };
  const handleImage = (e) => {
    //console.log("aaa", e);
    let reader = new FileReader();

    let filenameType = e.target.value.split(".");
    let filetypedata = filenameType[filenameType.length - 1].toLowerCase();
    if (
      filetypedata == "png" ||
      filetypedata == "jpeg" ||
      filetypedata == "jpg"
    ) {
    } else {
      alert("only jpeg  , gif  ,png  file accepted");
      e.target.value = null;

      return;
    }
    reader.onload = () => {
      if (reader.result.length > 1000000) {
        // alert if size > 1 MB
        alert("Image Too large, Update and try again.");
        return;
      }
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        let height = this.height;
        let width = this.width;
        // console.log(Number(width / height));
        // console.log(Number(width / height) != 1.5);
        if (Number(width / height) != 1.5) {
          alert("Aspect Ratio must be 3:2. Update and try again.");
          return false;
        }
        // alert("Uploaded image has valid Height and Width.");

        setSignData({
          base64: reader.result,
          url: URL.createObjectURL(e.target.files[0]),
        });
        return true;
      };
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  useEffect(() => {
    if (editUserState) {
      setFullName(editUserState.name);
      setCompany(editUserState.company);
      setCompanyDetails(null);
      setDepartment(editUserState.department);
      setJobTitle(editUserState.jobTitle);
      setJoiningDate(seconedTohumanDate);
      setEmail(editUserState.email);
      setEmployeeId(editUserState.employeeId);
      setRole(editUserState.role);
      setSignData({ base64: "", url: editUserState.signUrl });
    }
  }, []);

  var seconedTohumanDate;
  // if (location.state) {
  //   let milisecond = Number(location.state.joiningDate._seconds) * 1000;
  //   let datamethod = new Date(milisecond).getDate();
  //   let getmounth = new Date(milisecond).getMonth();
  //   let getyerr = new Date(milisecond).getFullYear();

  //   if (getmounth < 9) {
  //     getmounth = `0${getmounth + 1}`;
  //     // seconedTohumanDate = `${getyerr}-0${getmounth + 1}-${datamethod}`;
  //   } else {
  //     getmounth = getmounth + 1;
  //     // seconedTohumanDate = `${getyerr}-${getmounth + 1}-${datamethod}`;
  //   }
  //   if (datamethod <= 9) {
  //     datamethod = `0${datamethod}`;
  //   }
  //   seconedTohumanDate = `${getyerr}-${getmounth}-${datamethod}`;
  //   console.log(seconedTohumanDate);
  // }

  const allIsWell = () => {
    setError("");
    if (fullname == "") {
      setError("Full Name");
      return false;
    } else if (company == "default") {
      setError("Company");
      return false;
    } else if (department == "") {
      setError("Department");
      return false;
    } else if (jobTitle == "") {
      setError("Job Title");
      return false;
    } else if (joiningDate == "") {
      setError("Joining Date");
      return false;
    } else if (email == "") {
      setError("Email");
      return false;
    } else if (employeeId == "") {
      setError("Employee ID");
      return false;
    } else if (role == "") {
      setError("Role");
      return false;
    } else if (role != "user" && signData.url == "") {
      setError("Signature");
      return false;
    }

    return true;
  };

  const handleCreateUser = () => {
    // buttendesiblefunction()

    if (!allIsWell()) return;

    setButtonDisable(false);
    if (editUserState) {
      updateUser({
        uid: editUserState.userId,
        name: fullname,
        company: company,
        role: role,
        instructorSignature: signData.base64,
        jobTitle: jobTitle,
        department: department,
      })
        .then((res) => {
          //console.log(res);
          setButtonDisable(true);
          if (res.data.status == "Success") {
            goToUser();
          } else {
            setError("User Updation Failed!!");
          }
        })
        .catch((err) => {
          // console.log(err);
          setButtonDisable(true);
        });
    } else {
      createUser({
        joiningDate: joiningDate,
        name: fullname,
        company: company,
        email: email,
        role: role,
        employeeId: employeeId,
        jobTitle: jobTitle,
        department: department,
        instructorSignature: signData.base64,
      })
        .then((res) => {
          //console.log(res);
          setButtonDisable(true);
          if (res.data.status == "Success") {
            setError("Success");
            // sendPasswordResetEmail(auth, email);
            goToUser();
          } else {
            setError("User Creation Failed!!");
          }
        })
        .catch((err) => {
          setButtonDisable(true);
        });
    }
  };

  useEffect(() => {
    fetchDepartment().then((res) => {
      if (res.data.error) {
        return;
      }
      setDepartmentList(res.data);
    });
    fetchCompany().then((res) => {
      if (res.data.error) {
        return;
      }
      setCompanyDetails(res.data);
    });
    // db.collection("company")
    //   .get()
    //   .then((snapshot) => {
    //     var comp = [];
    //     snapshot.forEach((company) => {
    //       let temp = {
    //         name: company.data().name,
    //         id: company.id,
    //       };
    //       comp.push(temp);
    //     });
    //     setCompanyDetails(comp);
    //   });
  }, []);
  return (
    <div className="section" style={{ backgroundColor: "#e5e5e5" }}>
      <Navbar page={t("User")} />
      <div className="createcourse-main">
        <Sidebar page={"user"} hidepage={true} />

        <div className="admin-overdue">
          <div className="admin-overdue-top">
            <div className="admin-overdue-top-item">
              {t("Add New User / Edit User")}
            </div>
          </div>
          <div
            className="admin-course-main-top"
            style={{ margin: "2vw", padding: "1vw 2vw 4vw 2vw" }}
          >
            <div>
              <div style={{ margin: "2vw" }} id="admin-course-main-top-id">
                <p>{t("FullName")} *</p>
                <input
                  type="text"
                  id="name"
                  className="admin-course-main-top-input"
                  value={fullname}
                  onChange={(event) => {
                    setFullName(event.target.value);
                  }}
                />
                {/* <span style={{color:"red", display:"inline"}} > {error && <span>{error} Not Filled</span>}</span> */}
              </div>

              <div style={{ margin: "2vw" }} id="admin-course-main-top-id">
                <p className="inputLabel">{t("Company")} *</p>
                <select
                  className="admin-course-main-top-select"
                  id="company"
                  value={company}
                  onChange={(event) => {
                    setCompany(event.target.value);
                  }}
                >
                  <option value={"default"}>Select Company</option>
                  {companyDetails?.map((value, index) => (
                    <option key={index} value={value.name}>
                      {value.name}
                    </option>
                  ))}
                </select>
              </div>

              <div style={{ margin: "2vw" }} id="admin-course-main-top-id">
                <p className="inputLabel">{t("Department")} *</p>
                <select
                  className="admin-course-main-top-select"
                  id="company"
                  value={department}
                  onChange={(event) => {
                    setDepartment(event.target.value);
                  }}
                >
                  <option value={"default"}>Select Department</option>
                  {departmentList?.map((value, index) => (
                    <option key={index} value={value.name}>
                      {value.name}
                    </option>
                  ))}
                </select>
                {/* <input
                  type="text"
                  id="department"
                  className="admin-course-main-top-input"
                  value={department}
                  onChange={(event) => {
                    setDepartment(event.target.value);
                  }}
                /> */}
              </div>

              <div style={{ margin: "2vw" }} id="admin-course-main-top-id">
                <p className="inputLabel">{t("JobTitle")} *</p>
                <input
                  type="text"
                  id="jobtitle"
                  className="admin-course-main-top-input"
                  value={jobTitle}
                  onChange={(event) => {
                    setJobTitle(event.target.value);
                    // setJobTitle(event.target.value);
                  }}
                />
              </div>

              <div style={{ margin: "2vw" }} id="admin-course-main-top-id">
                <p className="inputLabel">{t("JoiningDate")} *</p>
                <input
                  type="date"
                  id="joiningdate"
                  value={joiningDate}
                  disabled={disabledButt}
                  className="admin-course-main-top-input"
                  onChange={(event) => {
                    setJoiningDate(event.target.value);
                  }}
                />
              </div>

              <div style={{ margin: "2vw" }} id="admin-course-main-top-id">
                <p>{t("Email")} *</p>
                <input
                  type="text"
                  id="email"
                  value={email}
                  className="admin-course-main-top-input"
                  disabled={disabledButt}
                  onChange={(event) => {
                    // location.state.email?setEmail(location.state.email):
                    setEmail(event.target.value);
                  }}
                />
              </div>

              <div style={{ margin: "2vw" }} id="admin-course-main-top-id">
                <p>{t("EmployeeID")}</p>
                <input
                  type="text"
                  id="employeeid"
                  className="admin-course-main-top-input"
                  disabled={disabledButt}
                  value={employeeId}
                  onChange={(e) => setEmployeeId(e.target.value)}
                />
              </div>

              <div style={{ margin: "2vw" }} id="admin-course-main-top-id">
                <div
                  style={{ fontSize: "18px", fontWeight: "500" }}
                  className="label"
                >
                  {t("Role")} *
                </div>
                <div style={{ fontSize: "0.9vw" }}>
                  <div style={{ height: "4vw" }} className="elementLeft">
                    <input
                      onChange={(e) => setRole(e.target.value)}
                      type="radio"
                      value="admin"
                      name="role"
                      checked={role == "admin" ? true : false}
                    />
                    {t("Admin")}
                    <span
                      onMouseOver={() => {
                        handleMouseOver("Admin");
                      }}
                      onMouseOut={() => {
                        handleMouseOut("Admin");
                      }}
                      style={{
                        background: isAdmin ? "black" : "white",
                        marginLeft: "3vw",
                        cursor: "pointer",
                      }}
                      className="Role"
                    >
                      {" "}
                      &#10068;
                    </span>
                    {isAdmin && (
                      <>
                        <span style={{ fontSize: "23px", color: "black" }}>
                          &#9664;
                        </span>
                        <span style={spanStyleINS} className="spanData">
                          {t("AdminIns")}
                        </span>
                      </>
                    )}
                  </div>
                  <div style={{ height: "4vw" }} className="elementLeft">
                    <input
                      onChange={(e) => setRole(e.target.value)}
                      type="radio"
                      value="user"
                      name="role"
                      checked={role == "user" ? true : false}
                    />{" "}
                    {t("User")}{" "}
                    <span
                      onMouseOver={() => {
                        handleMouseOver("User");
                      }}
                      onMouseOut={() => {
                        handleMouseOut("User");
                      }}
                      style={{
                        background: isUser ? "black" : "white",
                        cursor: "pointer",
                      }}
                      className="Role"
                    >
                      {" "}
                      &#10068;
                    </span>
                    {isUser && (
                      <>
                        <span style={{ fontSize: "20px", color: "black" }}>
                          &#9664;
                        </span>
                        <span style={spanStyle} className="spanData">
                          {t("UserIns")}
                        </span>
                      </>
                    )}
                  </div>
                  <div style={{ height: "4vw" }} className="elementLeft">
                    <input
                      onChange={(e) => setRole(e.target.value)}
                      type="radio"
                      value="instructor"
                      name="role"
                      checked={role == "instructor" ? true : false}
                    />{" "}
                    {t("Instructor")}
                    <span
                      onMouseOver={() => {
                        handleMouseOver("Instructor");
                      }}
                      onMouseOut={() => {
                        handleMouseOut("Instructor");
                      }}
                      style={{
                        background: isInstructor ? "black" : "white",
                        marginLeft: "2vw",
                        cursor: "pointer",
                      }}
                      className="Role"
                    >
                      {" "}
                      &#10068;
                    </span>
                    {isInstructor && (
                      <>
                        <span style={{ fontSize: "23px", color: "black" }}>
                          &#9664;
                        </span>
                        <span style={spanStyleINS}>{t("InstructorIns")}</span>
                      </>
                    )}
                  </div>
                </div>
              </div>

              {role != "user" && (
                <div style={{ margin: "2vw" }} id="admin-course-main-top-id">
                  <p>{t("Signature")}</p>
                  {!signData.url ? (
                    <div className="image-box" onClick={onImageClick}>
                      <button className="image-box-btn">
                        <div className="image-box-icon">
                          <svg
                            width="25"
                            height="26"
                            viewBox="0 0 25 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.27998 25.2535C2.42931 25.2535 1.70135 24.9509 1.09609 24.3456C0.489792 23.7393 0.186646 23.0108 0.186646 22.1602V17.5202H3.27998V22.1602H21.84V17.5202H24.9333V22.1602C24.9333 23.0108 24.6307 23.7393 24.0254 24.3456C23.4191 24.9509 22.6906 25.2535 21.84 25.2535H3.27998ZM11.0133 19.0668V6.4615L6.99198 10.4828L4.82665 8.24017L12.56 0.506836L20.2933 8.24017L18.128 10.4828L14.1066 6.4615V19.0668H11.0133Z"
                              fill="#D9D9D9"
                            />
                          </svg>
                        </div>
                      </button>
                      <p className="image-box-text">Upload Image</p>
                    </div>
                  ) : (
                    <img
                      style={{
                        width: "20vw",
                        height: "8em",
                        borderRadius: "10px",
                      }}
                      onClick={onImageClick}
                      src={signData.url}
                    />
                  )}
                  <input
                    type="file"
                    onChange={handleImage}
                    ref={inputRef}
                    accept="image/png, image/gif, image/jpeg"
                    style={{ display: "none" }}
                  />
                </div>
              )}

              {/* <div className="btn">
                <button onClick={sendmail}> Save </button>

                <button> Cancel </button>
              </div> */}
              {error && <div style={{ color: "red" }}>{error} Not Filled</div>}
              <div
                style={{ justifyContent: "center" }}
                className="admin-dashboard-main-mid-item-last-filter-notify"
              >
                <div className="admin-dashboard-main-top-last-filter-notify">
                  {buttonDisable ? (
                    <button className="primary-btn" onClick={handleCreateUser}>
                      {t("Save")}
                    </button>
                  ) : (
                    <button
                      style={{
                        background: `${
                          clrs.CourseListBtn
                            ? clrs.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                      }}
                      disabled={true}
                    >
                      <div>{t("Loading")} </div>
                    </button>
                  )}

                  <button className="clear-btn" onClick={() => navigate(-1)}>
                    {t("Cancel")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ height: "100%", width: "6vw", backgroundColor: "white" }}
        ></div>
        <div className="courselist-main-cnt-back">
          <div className="courselist-main-cnt-back-btn">
            <button className="secondary-btn" onClick={() => navigate(-1)}>
              {t("Back")}
            </button>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewUser;
