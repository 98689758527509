import React, { useEffect, useRef } from "react";
import { useState } from "react";
// import $ from "jquery";
import { db, functions, storage } from "../../../Firebase";

import { httpsCallable } from "firebase/functions";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { Timestamp } from "firebase/firestore";
import "./Form.css";
import "../StyleAdminComponents/GroupList.css";
import { useNavigate, useLocation } from "react-router-dom";
import { EditSvg, DeleteSVg } from "./icons";
import { width } from "@mui/system";
import Upload from "./uploadPop";
import { async } from "@firebase/util";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  selectClasses,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DeleteLesson from "./lessonDeletePopUp";
import LoadingScreen from "../BasicCourse/lodingScree";

const Course = ({ editCourseInfo, setIsCourseIdSet, checkEditFlag }) => {
  //Course
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);
  const navigate = useNavigate();
  const [deleteTopicId, setDeleteTopicId] = useState("");
  const createCourse = httpsCallable(functions, "createCourse");
  const createMapping = httpsCallable(functions, "createMapping");
  const addCourseEvaluationInfo = httpsCallable(
    functions,
    "addCourseEvaluationInfo"
  );
  const getCourseFullData = httpsCallable(functions, "getCourseFullData");
  const fetchCertificateTemplate = httpsCallable(
    functions,
    "fetchCertificateTemplate"
  );
  const getAdminAndInstructorList = httpsCallable(
    functions,
    "getAdminAndInstructorList"
  );
  const getWhyDoYouWantToJoin = httpsCallable(
    functions,
    "getWhyDoYouWantToJoin"
  );
  const deleteCourse = httpsCallable(functions, "deleteCourse");
  const [courseName, setCourseName] = useState("");
  const [add, setAdd] = useState(false);

  const [categoryArr, setCategoryArr] = useState([]);

  const [loadingScreen, setLoadingScreen] = useState(false);
  const [selectedCateGory, setSelectedCateGory] = useState([]);
  const [registrationTime, setRegistrationTime] = useState(0);

  const [progressUpload, setProgressUpload] = useState(0);
  const [openUploadPopUp, setopenUploadPopUp] = useState(false);
  const [courseDescription, setCourseDescription] = useState("");
  const [courseDuration, setCourseDuration] = useState(0);

  const [courseTypeAndDis, setCourseTypeAndDis] = useState({
    courseType: "",
    courseTypeDis: "",
    papTextOnHomePage: "",
    papTextDiscriptionOnHomePage: "",
    guaranteeText: "",
    oneTimePaymentTitleInPaymentPage: "",

    papCourseTitleInPaymentPage: "",
  });

  const [templateArr, setTemplateArr] = useState([{ templateName: "temp1" }]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [accessMode, setAccessMode] = useState("free");
  const [objectiveAccessMode, setObjectiveAccessMode] = useState("true");
  const [hasQuiz, setHasQuiz] = useState("yes");

  const [keyFileImg, setKeyFileImg] = useState([]);

  const [xDaysAfterReapply, setXDaysAfterReapply] = useState("");

  const [subjectiveAccessMode, setSubjectiveAccessMode] = useState("true");

  const [sharedByArr, setSharedByArr] = useState(null);
  const [courseId, setCourseId] = useState("");
  const [courseList, setCourseList] = useState(null);
  let newDate = new Date().toDateString();
  const [createdDate, setCreatedDate] = useState(newDate);
  const [lastVisited, setLastVisited] = useState(newDate);

  const [flow, setFlow] = useState("linear");
  // const storage = getStorage();
  const [editable, setEditable] = useState(false);
  const [checkFailed, setCheckFailed] = useState(false);
  const [base64Img, setBase64Img] = useState("");
  const [base64Vid, setBase64Vid] = useState("");
  const [imgfilename, setImgFileName] = useState("");
  const [curriculamUrl, setCurriculamUrl] = useState("");

  const [promises, setPromises] = useState("");
  const [preRequsite, setPreRequsite] = useState("");
  const [curriculam, setCurriculam] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [agreementUrl, setAgreementUrl] = useState("");
  const [base64Agreement, setBase64Agreement] = useState("");
  const [base64Curriculam, setBase64Curriculam] = useState("");

  const [industryImg, setIndustryImg] = useState([]);
  const [registrationFees, setRegistrationFees] = useState(0);
  const [courseFees, setCourseFees] = useState(0);

  const [LinkForextrenalpage, setLinkForextrenalpage] = useState("");

  const [isThisCoursePAP, setIsThisCoursePAP] = useState(false);

  const [papCourseFeesDiscription, setPapCourseFeesDiscription] = useState("");

  const [oneTimecourseFeesDiscription, setOneTimecourseFeesDiscription] =
    useState("");
  const [numberOfRetry, setNumberOfRetry] = useState(0);
  const [checkErrorInForm, setCheckErrorInForm] = useState({
    courseName: "",
    courseDescription: "",
    trainingProvider: "",
    courseDuration: "",
    LinkForextrenalpage: "",
    preRequsite: "",
    courseFees: "",
    promises: "",
    curriculamUrl: "",
    sampleAgreement: "",
    courseExpirationDays: "",
    imageUrl: "",
    ragistrationTime: 0,
    registrationFees: "",
    curriculam: "",
    videoUrl: "",
    instructorId: "",
    selectedTemplate: "",
    objectiveAccessMode: "",
    subjectiveAccessMode: "",
    courseCategory: "",
    papCourseFeesDiscription: "",
    xDaysAfterReapply: "",
    numberOfRetry: 0,
    oneTimecourseFeesDiscription: "",
    papTextOnHomePage: "",
    papTextDiscriptionOnHomePage: "",
    guaranteeText: "",
    oneTimePaymentTitleInPaymentPage: "",

    papCourseTitleInPaymentPage: "",
  });

  //console.log(industryImg);

  const editData = useLocation();

  let EditFlagCourse = editData.state;

  const [editdataflagForLoading, setEditdataflagForLoading] = useState(false);
  const imageRef = useRef(null);

  const videoRef = useRef(null);
  const curriculamRef = useRef(null);
  const keyHighlishtRef = useRef(null);

  const agreementRef = useRef(null);
  const industrypartnerRef = useRef(null);
  var docURL = [];
  var files = [];

  // console.log(sharedByArr);

  const onImageClick = () => {
    imageRef.current.click();
  };

  const onCurriculamClick = () => {
    curriculamRef.current.click();
  };
  const onAgreementClick = () => {
    agreementRef.current.click();
  };

  useEffect(() => {
    getWhyDoYouWantToJoin({ type: "category" }).then((res) => {
      //console.log(res.data);
      setCategoryArr(res.data);
    });

    fetchCertificateTemplate().then((res) => {
      //  console.log(res);
      setTemplateArr(res.data);
    });
  }, []);

  const formCheck = () => {
    if (courseName === "") {
      setCheckErrorInForm((prev) => ({ ...prev, courseName: "error" }));
      return "Course Name";
    }

    if (imageUrl === "") {
      setCheckErrorInForm((prev) => ({ ...prev, imageUrl: "error" }));
      alert("Upload image");
      return "base64Img";
    }
    if (LinkForextrenalpage === "") {
      setCheckErrorInForm((prev) => ({
        ...prev,
        LinkForextrenalpage: "error",
      }));
      return "LinkForextrenalpage";
    }

    if (preRequsite === "") {
      setCheckErrorInForm((prev) => ({ ...prev, preRequsite: "error" }));

      return "preRequsite";
    }

    if (promises === "") {
      setCheckErrorInForm((prev) => ({ ...prev, promises: "error" }));

      return "promises";
    }

    if (isThisCoursePAP) {
      // console.log(isThisCoursePAP, "rahu;l");
      if (papCourseFeesDiscription == "") {
        setCheckErrorInForm((prev) => ({
          ...prev,
          papCourseFeesDiscription: "error",
        }));

        return "papCourseFeesDiscription";
      }

      if (registrationFees == 0) {
        setCheckErrorInForm((prev) => ({ ...prev, registrationFees: "error" }));
        return "registrationFees";
      }
    }
    if (courseFees == 0) {
      setCheckErrorInForm((prev) => ({ ...prev, courseFees: "error" }));
      return "courseFees";
    }

    if (oneTimecourseFeesDiscription == "") {
      setCheckErrorInForm((prev) => ({
        ...prev,
        oneTimecourseFeesDiscription: "error",
      }));
      return "oneTimecourseFeesDiscription";
    }

    if (curriculamUrl === "") {
      setCheckErrorInForm((prev) => ({ ...prev, curriculamUrl: "error" }));
      alert("Upload curriculam");
      return "curriculamUrl";
    }
    if (agreementUrl === "") {
      setCheckErrorInForm((prev) => ({ ...prev, sampleAgreement: "error" }));
      alert("upload sample Agreement");
      return "sample Agreement ";
    }

    if (courseDescription === "") {
      setCheckErrorInForm((prev) => ({ ...prev, courseDescription: "error" }));
      return "Description";
    }

    if (courseTypeAndDis.papTextOnHomePage == "") {
      setCheckErrorInForm((prev) => ({ ...prev, papTextOnHomePage: "error" }));
      return "papTextOnHomePage";
    }

    if (courseTypeAndDis.papTextDiscriptionOnHomePage == "") {
      setCheckErrorInForm((prev) => ({
        ...prev,
        papTextDiscriptionOnHomePage: "error",
      }));
      return "papTextDiscriptionOnHomePage";
    }

    if (courseTypeAndDis.guaranteeText == "") {
      setCheckErrorInForm((prev) => ({
        ...prev,
        guaranteeText: "error",
      }));
      return "guaranteeText";
    }

    if (courseTypeAndDis.oneTimePaymentTitleInPaymentPage == "") {
      setCheckErrorInForm((prev) => ({
        ...prev,
        oneTimePaymentTitleInPaymentPage: "error",
      }));
      return "oneTimePaymentTitleInPaymentPage";
    }

    if (courseTypeAndDis.papCourseTitleInPaymentPage == "") {
      setCheckErrorInForm((prev) => ({
        ...prev,
        papCourseTitleInPaymentPage: "error",
      }));
      return "papCourseTitleInPaymentPage";
    }

    if (courseDuration == 0) {
      setCheckErrorInForm((prev) => ({ ...prev, courseDuration: "error" }));
      return "Duration";
    }

    if (xDaysAfterReapply == "") {
      setCheckErrorInForm((prev) => ({ ...prev, xDaysAfterReapply: "error" }));
      return "xDaysAfterReapply";
    }

    if (numberOfRetry == 0) {
      setCheckErrorInForm((prev) => ({ ...prev, numberOfRetry: "error" }));
      return "numberOfRetry";
    }

    if (registrationTime === 0) {
      setCheckErrorInForm((prev) => ({ ...prev, registrationTime: "error" }));

      return "registrationTime";
    }

    if (objectiveAccessMode === "") {
      setCheckErrorInForm((prev) => ({
        ...prev,
        objectiveAccessMode: "error",
      }));
      return "objectiveAccessMode";
    }

    if (objectiveAccessMode === "") {
      setCheckErrorInForm((prev) => ({
        ...prev,
        objectiveAccessMode: "error",
      }));
      return "objectiveAccessMode";
    }

    if (subjectiveAccessMode === "") {
      setCheckErrorInForm((prev) => ({
        ...prev,
        subjectiveAccessMode: "error",
      }));
      return "subjective Access Mode";
    }
    if (selectedTemplate == "") {
      setCheckErrorInForm((prev) => ({ ...prev, selectedTemplate: "error" }));
      return "Certificate Template";
    }
    if (selectedCateGory.length == 0) {
      let arr = [];

      for (var option of document.getElementById("courseCategory").options) {
        if (option.selected) {
          if (option.value !== "") {
            arr.push(option.value);
            setSelectedCateGory(arr);
            // console.log(arr);
          }
        }
      }
      if (arr.length == 0) {
        setCheckErrorInForm((prev) => ({ ...prev, courseCategory: "error" }));
        return "courseCategory";
      }
    }
    return "allIsWell";
  };

  if (editdataflagForLoading) {
    // console.log(checkEditFlag)

    document.getElementsByClassName("course-builder-container").display =
      "none";
  }

  useEffect(() => {
    if (!EditFlagCourse) return;
    setEditdataflagForLoading(true);
    setLoadingScreen(true);
    getCourseFullData({ courseId: EditFlagCourse.courseId }).then((res) => {
      // console.log(res.data);
      setCourseId(res.data?.courseId);
      setEditdataflagForLoading(false);
      setLoadingScreen(false);
      setCourseName(res.data?.courseName);
      setCourseDescription(res.data?.courseDiscription);
      setImageUrl(res.data?.courseImageURL);

      setIsThisCoursePAP(res.data?.isThisCoursePAP);
      setOneTimecourseFeesDiscription(res.data?.oneTimecourseFeesDiscription);

      setCourseTypeAndDis((prev) => ({
        ...prev,
        courseType: res.data?.courseType,
      }));
      setCourseTypeAndDis((prev) => ({
        ...prev,
        papTextOnHomePage: res.data?.papTextOnHomePage,
      }));
      setCourseTypeAndDis((prev) => ({
        ...prev,
        papTextDiscriptionOnHomePage: res.data?.papTextDiscriptionOnHomePage,
      }));
      setCourseTypeAndDis((prev) => ({
        ...prev,
        guaranteeText: res.data?.guaranteeText,
      }));

      setCourseTypeAndDis((prev) => ({
        ...prev,
        oneTimePaymentTitleInPaymentPage:
          res.data?.oneTimePaymentTitleInPaymentPage,
      }));

      setCourseTypeAndDis((prev) => ({
        ...prev,
        papCourseTitleInPaymentPage: res.data?.papCourseTitleInPaymentPage,
      }));

      setCourseDuration(res.data?.courseDuration);
      setNumberOfRetry(res.data?.numberOfRetry);
      setSelectedCateGory(res.data?.associatedCategory);
      setAgreementUrl(res.data?.courseSampleAgreement);
      setCourseFees(res.data?.courseFee);
      setRegistrationFees(res.data?.payAfterPlacementFees);
      setPreRequsite(res.data?.courseRequirements);

      // let createdOn = Timestamp.fromMillis(res.data?.xDaysAfterReapply?._seconds * 1000)
      // .toDate().toISOString().split("T")[0];
      setXDaysAfterReapply(res.data?.xDaysAfterReapply);
      setPromises(res.data?.coursePromises);
      setCurriculamUrl(res.data?.courseCurriculumPDF);
      setLinkForextrenalpage(res.data?.LinkForextrenalpage);
      setRegistrationTime(res.data?.registrationTime);
      setSelectedTemplate(res.data?.selectedTemplate);
      setAccessMode(res.data?.accessMode);
      setPapCourseFeesDiscription(res.data?.papCourseFeesDiscription);
      setSubjectiveAccessMode(res.data?.subjectiveTestRequerd);
      setObjectiveAccessMode(res.data?.objectiveTestRequerd);
      //courseProgress

      let created = new Date(res.data?.created?._seconds * 1000)
        .toDate()
        .toDateString();
      setCreatedDate(created);
    });

    // console.log("i am in useEffect", new Date());
    // console.log(editCourseInfo);
  }, []);

  // useEffect(() => {
  //   fetchCertificateTemplate().then((res) => {
  //     setTemplateArr(res.data);
  //   });
  //   getCourseFullData().then((result) => {
  //     setCourseList([...result.data].map((ele) => ({ ...ele, select: false })));
  //   });
  //   getAdminAndInstructorList().then((res) => {
  //     setInstructorArr(res.data.instructor_list);
  //     setSharedByArr([...res.data.instructor_list, ...res.data.admin_list]);
  //     // let shared = res.data.admin_list;
  //     // let instructorList = [];
  //     // res.data.instructor_list;
  //     // instructorList.forEach(ele=>)
  //   });
  // }, []);

  const handleCourseImage = (e) => {
    let reader = new FileReader();
    //  console.log(e);

    let filenameType = e.target.value.split(".");

    //console.log(filenameType[filenameType.length - 1]);

    let filetypedata = filenameType[filenameType.length - 1].toLowerCase();
    if (
      filetypedata == "png" ||
      filetypedata == "jpeg" ||
      filetypedata == "jpg"
    ) {
    } else {
      alert("only jpeg  , gif  ,png  file accepted");
      e.target.value = null;

      return;
    }

    let rfilesize = Number(e.target.files[0].size) / 1024;

    rfilesize = rfilesize / 1024;
    // console.log(rfilesize);
    if (Number(rfilesize) > 5) {
      alert("size must be less than 5 mb");
      setImgFileName("");
      e.target.value = null;
      return;
    }
    // } else {
    //   console.log(rfilesize);
    //   let url = URL.createObjectURL(e.target.files[0]);

    // }

    let url = URL.createObjectURL(e.target.files[0]);
    const img = new Image();
    img.onload = function () {
      //alert(this.width + 'x' + this.height);
      if (
        Number(this.width) > 660 ||
        (Number(this.width) < 650 && Number(this.height) > 240) ||
        Number(this.height) < 230
      ) {
        alert("Aspect Ratio must be 656px:232px. Update and try again.");
        return false;
      } else {
        setImageUrl(url);
        setImgFileName(e.target.files[0].name);
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function () {
          //console.log(reader.result);
          setBase64Img(reader.result);
        };
      }
    };
    img.src = url;
  };

  const handlePdf = (e, state, urlset) => {
    let reader = new FileReader();
    // console.log(e);

    let filenameType = e.target.value.split(".");

    //console.log(filenameType[filenameType.length - 1]);

    let filetypedata = filenameType[filenameType.length - 1].toLowerCase();

    let url = URL.createObjectURL(e.target.files[0]);

    let rfilesize = Number(e.target.files[0].size) / 1024;

    rfilesize = rfilesize / 1024;
    // console.log(rfilesize);
    if (Number(rfilesize) > 5) {
      alert("size must be less than 5 mb");

      state("");
      setKeyFileImg("");
      urlset("");

      e.target.value = null;
      return;
    }
    reader.readAsDataURL(e.target.files[0]);
    urlset(url);

    reader.onload = function () {
      state(reader.result);
      setKeyFileImg(reader.result);
    };
  };

  const addCourse = () => {
    let arr = [];

    for (var option of document.getElementById("courseCategory").options) {
      if (option.selected) {
        if (option.value !== "") {
          arr.push(option.value);
          setSelectedCateGory(arr);
          //console.log(arr)
        }
      }
    }
    // console.log(arr);

    const check = formCheck();
    let nsn = courseName.replaceAll(" ", "");

    let dat = /[^A-Za-z0-9]/.test(nsn);

    if (!dat) {
    } else {
      alert("you can not use any special characters");
      return;
    }

    if (check != "allIsWell") {
      setCheckFailed(true);
      return;
    }
    setCheckErrorInForm({
      courseName: "",
      courseDescription: "",
      trainingProvider: "",
      courseDuration: "",
      courseExpirationDays: "",
      imageUrl: "",
      instructorId: "",
      selectedTemplate: "",
      numberOfRetry: 0,
      oneTimecourseFeesDiscription: "",
      guaranteeText: "",
      papCourseFeesDiscription: "",
      papTextOnHomePage: "",
      courseType: "",

      oneTimePaymentTitleInPaymentPage: "",

      papCourseTitleInPaymentPage: "",
    });
    setCheckFailed(false); // removes error message after succesful creation
    let dependency = [];
    // for (var option of document.getElementById("courseDependency").options) {
    //   if (option.selected) {
    //     dependency.push(option.value);
    //   }
    // }
    let shared = [];

    // console.log({
    //   courseDescription: courseDescription,
    //   image: base64Img,

    //   courseName: courseName,

    //   courseId: courseId,

    //   coursePromises:promises,
    //   courseRequirements:preRequsite,
    //   courseDuration:courseDuration,
    //   courseDiscription:courseDescription,
    //   accessMode:accessMode,
    //   courseId: courseId,
    //   payAfterPlacementFees:registrationFees,
    //  courseFee:courseFees,

    //  objectiveTestRequerd:objectiveAccessMode,
    //  subjectiveTestRequerd:subjectiveAccessMode,

    //  associatedCategory:selectedCateGory,

    //  courseCurriculumPDF:base64Curriculam,
    //  courseSampleAgreement:base64Agreement,

    // })

    createCourse({
      courseDescription: courseDescription,
      image: base64Img,

      courseName: courseName,
      xDaysAfterReapply: xDaysAfterReapply,
      courseId: courseId,
      LinkForextrenalpage: LinkForextrenalpage,
      coursePromises: promises,
      courseRequirements: preRequsite,
      courseDuration: courseDuration,
      courseDiscription: courseDescription,
      papTextDiscriptionOnHomePage:
        courseTypeAndDis?.papTextDiscriptionOnHomePage,
      papTextOnHomePage: courseTypeAndDis?.papTextOnHomePage,
      courseType: courseTypeAndDis?.courseType,
      accessMode: accessMode,
      numberOfRetry: numberOfRetry,

      created: EditFlagCourse ? "edit" : new Date(),
    }).then((res) => {
      //  console.log(res);
      addCourseEvaluationInfo({
        courseId: courseId,
        payAfterPlacementFees: registrationFees,
        courseFee: courseFees,
        oneTimecourseFeesDiscription: oneTimecourseFeesDiscription,
        objectiveTestRequerd: objectiveAccessMode,
        subjectiveTestRequerd: subjectiveAccessMode,

        guaranteeText: courseTypeAndDis?.guaranteeText,

        associatedCategory: arr,

        selectedTemplate: selectedTemplate,

        isThisCoursePAP: isThisCoursePAP,

        papCourseFeesDiscription: papCourseFeesDiscription,

        courseCurriculumPDF: base64Curriculam ? base64Curriculam : "",
        courseSampleAgreement: base64Agreement,

        oneTimePaymentTitleInPaymentPage:
          courseTypeAndDis?.oneTimePaymentTitleInPaymentPage,

        papCourseTitleInPaymentPage:
          courseTypeAndDis?.papCourseTitleInPaymentPage,
        //  registrationTime:data.registrationTime?data.registrationTime:10,
        //  subjectiveTime:data.subjectiveTime?data.subjectiveTime:30,
        //instruction
      });
    });

    sessionStorage.setItem("courseId", courseId);
    if (EditFlagCourse) {
      alert("Course " + courseName + " Updated");
    } else {
      alert("Course " + courseName + " Added");
    }
    setIsCourseIdSet(courseId);
    localStorage.setItem("courseId", courseId);
    document.getElementById("courseName").disabled = true; // disable the input field
    const elements = document.getElementsByClassName("course-builder-top");
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.display = "none";
    }
    document.getElementById("editC").style.display = "";
    document.getElementById("addC").style.display = "none";

    document.getElementById("addL").style.display = "none";
  };

  const moveToLesson = () => {
    sessionStorage.setItem("courseId", courseId);

    setIsCourseIdSet(courseId);
    document.getElementById("courseName").disabled = true; // disable the input field
    const elements = document.getElementsByClassName("course-builder-top");
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.display = "none";
    }
    document.getElementById("addC").style.display = "none";
    document.getElementById("addL").style.display = "none";
    document.getElementById("editC").style.display = "";
  };

  function edit() {
    document.getElementById("addC").style.display = "";
    const elements = document.getElementsByClassName("course-builder-top");
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.display = "";
    }
  }
  //change this and do it the React Way

  function clier() {
    setVideoUrl("");
  }
  function addfun() {
    //  console.log(add);

    setAdd(!add);
  }

  const deletePdf = (type) => {
    if (type == "curriculam") {
      setCurriculamUrl("");
      setBase64Curriculam("");
    }
    if (type == "agreement") {
      setBase64Agreement("");
      setAgreementUrl("");
    }
  };
  async function delCourse() {
    setopenLessonPopUp(true);
    setDeleteTopicId(courseId);
    // deleteTopic({ topicId: topic.id });
    // alert("Deleted " + topic.name);
    // const item = [];
    // for (let i = 0; i < list.length; i++) {
    //   if (topic.id !== list[i].id) {
    //     item.push(list[i]);
    //   }
    // }
    // setList(item);
    // saveOrder(item);
  }
  //  const delCourse=()=>{

  //   deleteCourse({courseId:courseId}).then((res)=>{

  //     navigate("/admin/training/internal")
  //   })
  //  }

  const delfunction = (courseId) => {
    deleteCourse({ courseId: courseId.id }).then((res) => {
      navigate("/admin/training/internal");
    });
  };
  return (
    <>
      {/* ------------------ */}{" "}
      {!editdataflagForLoading ? (
        <div className="course-builder-container" style={{ marginTop: "30px" }}>
          <DeleteLesson
            open={openLessonPopUp}
            onClose={() => setopenLessonPopUp(false)}
            delfun={(courseId) => {
              delfunction(courseId);
            }}
            topicId={{ id: courseId }}
          />
          <LoadingScreen
            open={loadingScreen}
            onClose={() => setLoadingScreen(false)}
          />

          <div
            id="editC"
            style={{ display: "none" }}
            className="course-builder-top-info"
          >
            <table className="course-builder-top-table">
              <thead className="course-builder-top-thead">
                <tr>
                  <th>Course Name</th>
                  <th>Created Date</th>
                  <th>Last Updated</th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{courseName}</td>
                  <td>{createdDate}</td>
                  <td>{lastVisited}</td>

                  <td>
                    <div style={{ display: "flex" }}>
                      <div
                        onClick={() => {
                          setEditable(true);
                          edit();
                        }}
                      >
                        <EditSvg style={{ marginRight: "3px" }} />
                      </div>
                      <div onClick={delCourse}>
                        <DeleteSVg />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Upload
            open={openUploadPopUp}
            onClose={() => {
              setopenUploadPopUp(false);
            }}
            upload={progressUpload}
          />

          <Box
            sx={{
              width: "100%",
              backgroundColor: "#FFF",
              padding: "20px",
              mt: "30px",
              borderRadius: "6px",
            }}
            className="course-builder-top"
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "20px",
                color: "#1E3758",
              }}
            >
              Add Entrance Course
            </Typography>
            <Stack spacing={4}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Typography
                  sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                >
                  Course Name <span style={{ color: "#DE5353" }}>*</span>
                </Typography>
                <TextField
                  type="text"
                  id="courseName"
                  value={courseName}
                  sx={{
                    border: `${
                      checkErrorInForm.courseName == "error"
                        ? "2px solid red"
                        : "1px solid #1E3758"
                    }`,
                    width: "30%",
                    borderRadius: "6px",
                  }}
                  onChange={(e) => {
                    setCourseName(e.target.value);

                    let value = courseName.replaceAll(" ", "");
                    let time = new Date().getTime();
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      courseName: "",
                    }));
                    if (!EditFlagCourse) {
                      setCourseId(value + "_" + time);
                    }
                  }}
                />
                {checkErrorInForm.courseName == "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    Enter Course Title
                  </span>
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Typography
                  sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                >
                  Featured Image <span style={{ color: "#DE5353" }}>*</span>
                  <p style={{ fontSize: "15px", fontWeight: "200" }}>
                    (Aspect Ratio 656px:232px.){" "}
                  </p>
                </Typography>
                <Box sx={{ display: "30%" }}>
                  {imageUrl == "" ? (
                    <Box
                      onClick={onImageClick}
                      sx={{
                        width: "230px",
                        height: "180px",
                        border: "2px dashed #1E3758",
                        borderRadius: "16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <Button
                        component="label"
                        sx={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50%",
                          border: "2px dashed #1C1B1F",
                          display: "flex",
                          justifyContent: "center",
                          "&:hover": {
                            backgroundColor: "#FFF",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <svg
                            width="25"
                            height="26"
                            viewBox="0 0 25 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.27998 25.2535C2.42931 25.2535 1.70135 24.9509 1.09609 24.3456C0.489792 23.7393 0.186646 23.0108 0.186646 22.1602V17.5202H3.27998V22.1602H21.84V17.5202H24.9333V22.1602C24.9333 23.0108 24.6307 23.7393 24.0254 24.3456C23.4191 24.9509 22.6906 25.2535 21.84 25.2535H3.27998ZM11.0133 19.0668V6.4615L6.99198 10.4828L4.82665 8.24017L12.56 0.506836L20.2933 8.24017L18.128 10.4828L14.1066 6.4615V19.0668H11.0133Z"
                              fill="#D9D9D9"
                            />
                          </svg>
                        </Box>
                      </Button>
                      <Typography sx={{ color: "#1E3758" }}>
                        Upload Image
                      </Typography>
                    </Box>
                  ) : (
                    <img
                      style={{
                        width: "20vw",
                        height: "10em",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                      onClick={onImageClick}
                      src={imageUrl}
                    ></img>
                  )}
                </Box>
                <input
                  hidden
                  ref={imageRef}
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => {
                    handleCourseImage(e);
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      imageUrl: "",
                    }));
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Typography
                  sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                >
                  Link For External Page{" "}
                  <span style={{ color: "#DE5353" }}>*</span>
                </Typography>
                <TextField
                  type="text"
                  value={LinkForextrenalpage}
                  sx={{
                    border: `${
                      checkErrorInForm.LinkForextrenalpage == "error"
                        ? "2px solid red"
                        : "1px solid #1E3758"
                    }`,
                    width: "30%",
                    borderRadius: "6px",
                  }}
                  onChange={(event) => {
                    setLinkForextrenalpage(event.target.value);
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      LinkForextrenalpage: "",
                    }));
                  }}
                />
                {checkErrorInForm.LinkForextrenalpage == "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    Enter Course Link For External page
                  </span>
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Typography
                  sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                >
                  Pre Requisite <span style={{ color: "#DE5353" }}>*</span>
                </Typography>
                <TextField
                  type="text"
                  value={preRequsite}
                  sx={{
                    border: `${
                      checkErrorInForm.preRequsite == "error"
                        ? "2px solid red"
                        : "1px solid #1E3758"
                    }`,
                    width: "30%",
                    borderRadius: "6px",
                  }}
                  onChange={(event) => {
                    setPreRequsite(event.target.value);
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      preRequsite: "",
                    }));
                  }}
                />
                {checkErrorInForm.preRequsite == "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    Enter Course PreRequisite
                  </span>
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Typography
                  sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                >
                  Promises <span style={{ color: "#DE5353" }}>*</span>
                </Typography>
                <TextField
                  type="text"
                  value={promises}
                  sx={{
                    border: `${
                      checkErrorInForm.promises == "error"
                        ? "2px solid red"
                        : "1px solid #1E3758"
                    }`,
                    width: "30%",
                    borderRadius: "6px",
                  }}
                  onChange={(event) => {
                    setPromises(event.target.value);
                    setCheckErrorInForm((prev) => ({ ...prev, promises: "" }));
                  }}
                />
                {checkErrorInForm.promises == "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    Enter Course promises
                  </span>
                )}
              </Box>
              {/* <div className="course-builder-info">
              <p className="course-builder-inputLable">Curriculam *</p>
              <textarea
                type="text"
                className="course-builder-textarea"
                value={curriculam}
                style={{
                  border: `${
                    checkErrorInForm.curriculam == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
                id="curriculam"
                onChange={(event) => {
                  setCurriculam(event.target.value);
                  setCheckErrorInForm((prev) => ({ ...prev, curriculam: "" }));
                }}
              />
              {checkErrorInForm.curriculam == "error" && (
                <span style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}>
                  Enter Course curriculam
                </span>
              )}
            </div> */}
              <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Typography
                  sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                >
                  Curriculam Upload <span style={{ color: "#DE5353" }}>*</span>
                </Typography>
                <Box
                  sx={{
                    width: "35%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  {curriculamUrl == "" ? (
                    <Box
                      sx={{
                        width: "230px",
                        height: "180px",
                        border: "2px dashed #1E3758",
                        borderRadius: "16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                      onClick={onCurriculamClick}
                    >
                      <Button
                        component="label"
                        sx={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50%",
                          border: "2px dashed #1C1B1F",
                          display: "flex",
                          justifyContent: "center",
                          "&:hover": {
                            backgroundColor: "#FFF",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <svg
                            width="25"
                            height="26"
                            viewBox="0 0 25 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.27998 25.2535C2.42931 25.2535 1.70135 24.9509 1.09609 24.3456C0.489792 23.7393 0.186646 23.0108 0.186646 22.1602V17.5202H3.27998V22.1602H21.84V17.5202H24.9333V22.1602C24.9333 23.0108 24.6307 23.7393 24.0254 24.3456C23.4191 24.9509 22.6906 25.2535 21.84 25.2535H3.27998ZM11.0133 19.0668V6.4615L6.99198 10.4828L4.82665 8.24017L12.56 0.506836L20.2933 8.24017L18.128 10.4828L14.1066 6.4615V19.0668H11.0133Z"
                              fill="#D9D9D9"
                            />
                          </svg>
                        </Box>
                      </Button>
                      <Typography sx={{ color: "#1E3758" }}>
                        Upload Pdf
                      </Typography>
                    </Box>
                  ) : (
                    <iframe src={curriculamUrl} title="pdf"></iframe>
                  )}
                  <input
                    ref={curriculamRef}
                    style={{
                      display: "none",
                    }}
                    type="file"
                    accept="application/pdf, application/vnd.ms-excel"
                    className="course-builder-inputImg"
                    id="agreementpdf"
                    onChange={(e) => {
                      handlePdf(e, setBase64Curriculam, setCurriculamUrl);
                      setCheckErrorInForm((prev) => ({
                        ...prev,
                        curriculamUrl: "",
                      }));
                    }}
                  />
                  <Button
                    sx={{
                      color: "#1E3758",
                      borderRadius: "5px",
                      border: "1px solid #062A59",
                    }}
                    onClick={() => {
                      deletePdf("curriculam");
                    }}
                  >
                    {" "}
                    Change File
                  </Button>
                </Box>
                {checkErrorInForm.curriculamUrl == "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    {" "}
                    upload pdf
                  </span>
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Typography
                  sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                >
                  Sample Agreement <span style={{ color: "#DE5353" }}>*</span>
                </Typography>
                <Box
                  sx={{
                    width: "35%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  {agreementUrl == "" ? (
                    <Box
                      sx={{
                        width: "230px",
                        height: "180px",
                        border: "2px dashed #1E3758",
                        borderRadius: "16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                      onClick={onAgreementClick}
                    >
                      <Button
                        component="label"
                        sx={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50%",
                          border: "2px dashed #1C1B1F",
                          display: "flex",
                          justifyContent: "center",
                          "&:hover": {
                            backgroundColor: "#FFF",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <svg
                            width="25"
                            height="26"
                            viewBox="0 0 25 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.27998 25.2535C2.42931 25.2535 1.70135 24.9509 1.09609 24.3456C0.489792 23.7393 0.186646 23.0108 0.186646 22.1602V17.5202H3.27998V22.1602H21.84V17.5202H24.9333V22.1602C24.9333 23.0108 24.6307 23.7393 24.0254 24.3456C23.4191 24.9509 22.6906 25.2535 21.84 25.2535H3.27998ZM11.0133 19.0668V6.4615L6.99198 10.4828L4.82665 8.24017L12.56 0.506836L20.2933 8.24017L18.128 10.4828L14.1066 6.4615V19.0668H11.0133Z"
                              fill="#D9D9D9"
                            />
                          </svg>
                        </Box>
                      </Button>
                      <Typography sx={{ color: "#1E3758" }}>
                        Upload Pdf
                      </Typography>
                    </Box>
                  ) : (
                    <iframe src={agreementUrl} title="pdf"></iframe>
                  )}
                  <input
                    ref={agreementRef}
                    style={{
                      display: "none",
                    }}
                    type="file"
                    accept="application/pdf, application/vnd.ms-excel"
                    className="course-builder-inputImg"
                    id="agreementpdf"
                    onChange={(e) => {
                      handlePdf(e, setBase64Agreement, setAgreementUrl);
                      setCheckErrorInForm((prev) => ({
                        ...prev,
                        sampleAgreement: "",
                      }));
                    }}
                  />
                  <Button
                    sx={{
                      color: "#1E3758",
                      borderRadius: "5px",
                      border: "1px solid #062A59",
                    }}
                    onClick={() => {
                      deletePdf("agreement");
                    }}
                  >
                    {" "}
                    Change File
                  </Button>
                </Box>
                {checkErrorInForm.sampleAgreement == "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    {" "}
                    upload pdf
                  </span>
                )}
              </Box>
              {/* <div className="course-builder-info">
              <p className="course-builder-inputLable">Intructions*</p>
              <textarea
                type="text"
                className="course-builder-textarea"
                value={instruction}
                
                id="intructions"
                onChange={(event) => {
                  setInstructions(event.target.value);
               
                }}
              />
              
            </div> */}
              <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Typography
                  sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                >
                  Short Description <span style={{ color: "#DE5353" }}>*</span>
                </Typography>
                <TextField
                  type="text"
                  value={courseDescription}
                  multiline
                  minRows={4}
                  sx={{
                    border: `${
                      checkErrorInForm.courseDescription == "error"
                        ? "2px solid red"
                        : "1px solid #1E3758"
                    }`,
                    width: "30%",
                    borderRadius: "6px",
                  }}
                  onChange={(event) => {
                    setCourseDescription(event.target.value);
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      courseDescription: "",
                    }));
                  }}
                />
                {checkErrorInForm.courseDescription == "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    Enter Course Description
                  </span>
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Typography
                  sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                >
                  Course Type <span style={{ color: "#DE5353" }}>*</span>
                </Typography>
                <TextField
                  type="text"
                  value={courseTypeAndDis?.courseType}
                  minRows={4}
                  sx={{
                    border: `${
                      checkErrorInForm.courseType == "error"
                        ? "2px solid red"
                        : "1px solid #1E3758"
                    }`,
                    width: "30%",
                    borderRadius: "6px",
                  }}
                  onChange={(event) => {
                    setCourseTypeAndDis((prev) => ({
                      ...prev,
                      courseType: event.target.value,
                    }));
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      courseType: "",
                    }));
                  }}
                />
                {checkErrorInForm.courseType == "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    Enter Course type
                  </span>
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Typography
                  sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                >
                  Pay After Placement Text On Home Sceen{" "}
                  <span style={{ color: "#DE5353" }}>*</span>
                </Typography>
                <TextField
                  type="text"
                  value={courseTypeAndDis?.papTextOnHomePage}
                  minRows={4}
                  sx={{
                    border: `${
                      checkErrorInForm.papTextOnHomePage == "error"
                        ? "2px solid red"
                        : "1px solid #1E3758"
                    }`,
                    width: "30%",
                    borderRadius: "6px",
                  }}
                  onChange={(event) => {
                    setCourseTypeAndDis((prev) => ({
                      ...prev,
                      papTextOnHomePage: event.target.value,
                    }));
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      papTextOnHomePage: "",
                    }));
                  }}
                />
                {checkErrorInForm.papTextOnHomePage == "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    Enter Pay After Placement Text On Home Screen
                  </span>
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Typography
                  sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                >
                  Pay After Placement Text Discription On Home Sceen{" "}
                  <span style={{ color: "#DE5353" }}>*</span>
                </Typography>
                <TextField
                  type="text"
                  value={courseTypeAndDis?.papTextDiscriptionOnHomePage}
                  minRows={4}
                  sx={{
                    border: `${
                      checkErrorInForm.papTextDiscriptionOnHomePage == "error"
                        ? "2px solid red"
                        : "1px solid #1E3758"
                    }`,
                    width: "30%",
                    borderRadius: "6px",
                  }}
                  onChange={(event) => {
                    setCourseTypeAndDis((prev) => ({
                      ...prev,
                      papTextDiscriptionOnHomePage: event.target.value,
                    }));
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      papTextDiscriptionOnHomePage: "",
                    }));
                  }}
                />
                {checkErrorInForm.papTextDiscriptionOnHomePage == "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    Enter Pay After Placement Text Discription On Home Screen
                  </span>
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Typography
                  sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                >
                  Guarantee Text <span style={{ color: "#DE5353" }}>*</span>
                </Typography>
                <TextField
                  type="text"
                  value={courseTypeAndDis?.guaranteeText}
                  minRows={4}
                  sx={{
                    border: `${
                      checkErrorInForm.guaranteeText == "error"
                        ? "2px solid red"
                        : "1px solid #1E3758"
                    }`,
                    width: "30%",
                    borderRadius: "6px",
                  }}
                  onChange={(event) => {
                    setCourseTypeAndDis((prev) => ({
                      ...prev,
                      guaranteeText: event.target.value,
                    }));
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      guaranteeText: "",
                    }));
                  }}
                />
                {checkErrorInForm.guaranteeText == "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    Enter Payment Guarantee Text
                  </span>
                )}
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Typography
                  sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                >
                  One Time Payment Title In Payment Page{" "}
                  <span style={{ color: "#DE5353" }}>*</span>
                </Typography>
                <TextField
                  type="text"
                  value={courseTypeAndDis?.oneTimePaymentTitleInPaymentPage}
                  minRows={4}
                  sx={{
                    border: `${
                      checkErrorInForm.oneTimePaymentTitleInPaymentPage ==
                      "error"
                        ? "2px solid red"
                        : "1px solid #1E3758"
                    }`,
                    width: "30%",
                    borderRadius: "6px",
                  }}
                  onChange={(event) => {
                    setCourseTypeAndDis((prev) => ({
                      ...prev,
                      oneTimePaymentTitleInPaymentPage: event.target.value,
                    }));
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      oneTimePaymentTitleInPaymentPage: "",
                    }));
                  }}
                />
                {checkErrorInForm.oneTimePaymentTitleInPaymentPage ==
                  "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    Enter one Time Payment Title In Payment Page
                  </span>
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Typography
                  sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                >
                  P.A.P.Course Payment Title In Payment Page{" "}
                  <span style={{ color: "#DE5353" }}>*</span>
                </Typography>
                <TextField
                  type="text"
                  value={courseTypeAndDis?.papCourseTitleInPaymentPage}
                  minRows={4}
                  sx={{
                    border: `${
                      checkErrorInForm.papCourseTitleInPaymentPage == "error"
                        ? "2px solid red"
                        : "1px solid #1E3758"
                    }`,
                    width: "30%",
                    borderRadius: "6px",
                  }}
                  onChange={(event) => {
                    setCourseTypeAndDis((prev) => ({
                      ...prev,
                      papCourseTitleInPaymentPage: event.target.value,
                    }));
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      papCourseTitleInPaymentPage: "",
                    }));
                  }}
                />
                {checkErrorInForm.papCourseTitleInPaymentPage == "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    Enter P.A.P.Course Title In Payment Page
                  </span>
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Typography
                  sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                >
                  X Days To Reapply <span style={{ color: "#DE5353" }}>*</span>{" "}
                  <span style={{ fontSize: "11px" }}>(Days)</span>
                </Typography>
                <TextField
                  type="Number"
                  value={xDaysAfterReapply}
                  min={0}
                  sx={{
                    border: `${
                      checkErrorInForm.xDaysAfterReapply == "error"
                        ? "2px solid red"
                        : "1px solid #1E3758"
                    }`,
                    width: "30%",
                    borderRadius: "6px",
                  }}
                  onChange={(event) => {
                    setXDaysAfterReapply(event.target.value);
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      xDaysAfterReapply: "",
                    }));
                  }}
                />
                {checkErrorInForm.xDaysAfterReapply === "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    Enter X days to reapply
                  </span>
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Typography
                  sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                >
                  Number Of Retry <span style={{ color: "#DE5353" }}>*</span>
                </Typography>
                <TextField
                  type="Number"
                  value={numberOfRetry}
                  min={0}
                  sx={{
                    border: `${
                      checkErrorInForm.numberOfRetry == "error"
                        ? "2px solid red"
                        : "1px solid #1E3758"
                    }`,
                    width: "30%",
                    borderRadius: "6px",
                  }}
                  onChange={(event) => {
                    setNumberOfRetry(event.target.value);
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      numberOfRetry: 0,
                    }));
                  }}
                />
                {checkErrorInForm.numberOfRetry === "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    Enter Number Of Retry
                  </span>
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Typography
                  sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                >
                  Course Duration <span style={{ color: "#DE5353" }}>*</span>{" "}
                  <span style={{ fontSize: "11px" }}> (in months)</span>
                </Typography>
                <TextField
                  type="Number"
                  value={courseDuration}
                  min={0}
                  sx={{
                    border: `${
                      checkErrorInForm.courseDuration == "error"
                        ? "2px solid red"
                        : "1px solid #1E3758"
                    }`,
                    width: "30%",
                    borderRadius: "6px",
                  }}
                  onChange={(event) => {
                    if (event.target.value < 0) {
                      event.target.value = courseDuration;
                    } else {
                      setCourseDuration(event.target.value);
                      setCheckErrorInForm((prev) => ({
                        ...prev,
                        courseDuration: "",
                      }));
                    }
                  }}
                />
                {checkErrorInForm.courseDuration === "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    Enter Course Duration
                  </span>
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Typography
                  sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                >
                  Is Pay After Placement Course?
                </Typography>
                <select
                  value={isThisCoursePAP}
                  style={{
                    border: `${
                      checkErrorInForm.objectiveAccessMode == "error"
                        ? "2px solid red"
                        : "1px solid #1E3758"
                    }`,
                    outline: "none",
                    width: "30%",
                    height: "60px",
                    padding: "10px",
                    fontSize: "18px",
                    borderRadius: "6px",
                  }}
                  min={1}
                  onChange={(e) => {
                    setIsThisCoursePAP(e.target.value == "true" ? true : false);
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      objectiveAccessMode: "",
                    }));
                  }}
                >
                  <option
                    style={{
                      padding: "10px",
                      color: "#1E3758",
                      fontSize: "20px",
                      height: "60px",
                    }}
                    value={true}
                  >
                    Yes
                  </option>
                  <option
                    style={{
                      padding: "10px",
                      color: "#1E3758",
                      fontSize: "20px",
                      height: "60px",
                    }}
                    value={false}
                  >
                    No
                  </option>
                </select>
                {checkErrorInForm.courseDuration === "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    Enter Course Duration
                  </span>
                )}
              </Box>
              {isThisCoursePAP ? (
                <>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "20px" }}
                  >
                    <Typography
                      sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                    >
                      Discription For PAP Course Fees
                    </Typography>
                    <TextField
                      type="text"
                      value={papCourseFeesDiscription}
                      sx={{
                        border: `${
                          checkErrorInForm.papCourseFeesDiscription == "error"
                            ? "2px solid red"
                            : "1px solid #1E3758"
                        }`,
                        width: "30%",
                        borderRadius: "6px",
                      }}
                      onChange={(event) => {
                        setPapCourseFeesDiscription(event.target.value);
                        setCheckErrorInForm((prev) => ({
                          ...prev,
                          papCourseFeesDiscription: "",
                        }));
                      }}
                    />
                    {checkErrorInForm.papCourseFeesDiscription === "error" && (
                      <span
                        style={{
                          marginLeft: "20px",
                          color: "red",
                          fontSize: "15px",
                        }}
                      >
                        Enter Course Pay after Placement Fees Discription
                      </span>
                    )}
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "20px" }}
                  >
                    <Typography
                      sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                    >
                      Pay After Placement Fees{" "}
                      <span style={{ color: "#DE5353" }}>*</span>
                    </Typography>
                    <TextField
                      type="Number"
                      value={registrationFees}
                      min={1}
                      sx={{
                        border: `${
                          checkErrorInForm.registrationFees == "error"
                            ? "2px solid red"
                            : "1px solid #1E3758"
                        }`,
                        width: "30%",
                        borderRadius: "6px",
                      }}
                      onChange={(event) => {
                        if (event.target.value < 0) {
                          event.target.value = registrationFees;
                        } else {
                          setRegistrationFees(event.target.value);
                          setCheckErrorInForm((prev) => ({
                            ...prev,
                            registrationFees: "",
                          }));
                        }
                      }}
                    />
                    {checkErrorInForm.registrationFees === "error" && (
                      <span
                        style={{
                          marginLeft: "20px",
                          color: "red",
                          fontSize: "15px",
                        }}
                      >
                        Enter Course Pay after Placement Fees
                      </span>
                    )}
                  </Box>
                </>
              ) : (
                <></>
              )}
              <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Typography
                  sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                >
                  One Time Course Fees{" "}
                  <span style={{ color: "#DE5353" }}>*</span>
                </Typography>
                <TextField
                  type="Number"
                  value={courseFees}
                  min={1}
                  sx={{
                    border: `${
                      checkErrorInForm.courseFees == "error"
                        ? "2px solid red"
                        : "1px solid #1E3758"
                    }`,
                    width: "30%",
                    borderRadius: "6px",
                  }}
                  onChange={(event) => {
                    if (event.target.value < 0) {
                      event.target.value = courseFees;
                    } else {
                      setCourseFees(event.target.value);
                      setCheckErrorInForm((prev) => ({
                        ...prev,
                        courseFees: "",
                      }));
                    }
                  }}
                />
                {checkErrorInForm.courseFees === "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    Enter course Fees
                  </span>
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Typography
                  sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                >
                  One Time Course Fees Discription{" "}
                  <span style={{ color: "#DE5353" }}>*</span>
                </Typography>
                <TextField
                  type="text"
                  value={oneTimecourseFeesDiscription}
                  min={1}
                  sx={{
                    border: `${
                      checkErrorInForm.courseFees == "error"
                        ? "2px solid red"
                        : "1px solid #1E3758"
                    }`,
                    width: "30%",
                    borderRadius: "6px",
                  }}
                  onChange={(event) => {
                    if (event.target.value < 0) {
                      event.target.value = courseFees;
                    } else {
                      setOneTimecourseFeesDiscription(event.target.value);
                      setCheckErrorInForm((prev) => ({
                        ...prev,
                        oneTimecourseFeesDiscription: "",
                      }));
                    }
                  }}
                />
                {checkErrorInForm.oneTimecourseFeesDiscription === "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    Enter Course Fees Discription
                  </span>
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Typography
                  sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                >
                  Registration Time <span style={{ color: "#DE5353" }}>*</span>
                </Typography>
                <TextField
                  type="Number"
                  value={registrationTime}
                  min={1}
                  sx={{
                    border: `${
                      checkErrorInForm.ragistrationTime == "error"
                        ? "2px solid red"
                        : "1px solid #1E3758"
                    }`,
                    width: "30%",
                    borderRadius: "6px",
                  }}
                  onChange={(event) => {
                    if (event.target.value < 0) {
                      event.target.value = registrationTime;
                    } else {
                      setRegistrationTime(event.target.value);
                      setCheckErrorInForm((prev) => ({
                        ...prev,
                        ragistrationTime: "",
                      }));
                    }
                  }}
                />
                {checkErrorInForm.ragistrationTime === "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    Enter registration Time
                  </span>
                )}
              </Box>
              {/* <div className="course-builder-info">
              <div className="course-builder-inputLable" style={{ marginTop: "2vw" }}>
                Access Mode
              </div>
              <select
                value={accessMode}
                id="accessMode"
                className="course-builder-inputSelect"
                onChange={(e) => setAccessMode(e.target.value)}
              >  <option value="" selected disabled hidden>
              Select an Option
            </option>
                <option value="free">Free</option>
                <option value="paid">Paid</option>
              </select>
            </div> */}
              {/* <div className="course-builder-info">
              <div className="course-builder-inputLable" style={{ marginTop: "2vw" }}>
              Objective Evaluation Required 
              </div>
              <select
                value={objectiveAccessMode}
                id="accessMode"
                className="course-builder-inputSelect"
                style={{
                  border: `${
                    checkErrorInForm.objectiveAccessMode == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
                onChange={(e) => {setObjectiveAccessMode(e.target.value)
                  setCheckErrorInForm((prev) => ({ ...prev, objectiveAccessMode: "" }));
                }}
              >  <option value="" selected disabled hidden>
              Select An Option
            </option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>


{checkErrorInForm.objectiveAccessMode === "error" && (
                <span style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}>
                  select  Objective Evaluation required 
                </span>
              )}
            </div> */}
              {/* <div className="course-builder-info">
              <div className="course-builder-inputLable" style={{ marginTop: "2vw" }}>
              Subjective Evaluation Required  
              </div>
              <select
                value={subjectiveAccessMode}
                id="accessMode"
                className="course-builder-inputSelect"
                style={{
                  border: `${
                    checkErrorInForm.subjectiveAccessMode == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
                onChange={(e) => {setSubjectiveAccessMode(e.target.value)
                  setCheckErrorInForm((prev) => ({ ...prev, subjectiveAccessMode: "" }));
                }}
              >  <option value="" selected disabled hidden>
              Select An Option
            </option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>


              {checkErrorInForm.subjectiveAccessMode === "error" && (
                <span style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}>
                  select  subjective  Evaluation required 
                </span>
              )}
            </div> */}
            </Stack>
          </Box>
          <Box
            sx={{
              width: "100%",
              padding: "20px",
              backgroundColor: "#FFF",
              mt: "20px",
            }}
            className="course-builder-top"
          >
            <Stack spacing={4}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  borderRadius: "6px",
                }}
              >
                <Typography
                  sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                >
                  Course ID
                </Typography>
                <TextField
                  type="text"
                  value={courseId}
                  disabled
                  sx={{
                    border: `${
                      checkErrorInForm.courseName == "error"
                        ? "2px solid red"
                        : "1px solid #1E3758"
                    }`,
                    width: "30%",
                    borderRadius: "6px",
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Typography
                  sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                >
                  Certificate Template{" "}
                  <span style={{ color: "#DE5353" }}>*</span>
                </Typography>
                <select
                  value={selectedTemplate}
                  style={{
                    border: `${
                      checkErrorInForm.selectedTemplate == "error"
                        ? "2px solid red"
                        : "1px solid #1E3758"
                    }`,
                    outline: "none",
                    width: "30%",
                    height: "60px",
                    padding: "10px",
                    fontSize: "18px",
                    borderRadius: "6px",
                  }}
                  min={1}
                  onChange={(e) => {
                    setSelectedTemplate(e.target.value);
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      selectedTemplate: "",
                    }));
                  }}
                >
                  <option
                    style={{
                      padding: "10px",
                      color: "#1E3758",
                      fontSize: "20px",
                      height: "60px",
                    }}
                    value=""
                    selected
                    disabled
                    hidden
                  >
                    Select an Option
                  </option>
                  {templateArr?.map((item, id) => (
                    <option
                      style={{
                        padding: "10px",
                        color: "#1E3758",
                        fontSize: "20px",
                        height: "60px",
                      }}
                      key={id}
                      value={item.id}
                    >
                      {item.templateName}
                    </option>
                  ))}
                </select>
                {checkErrorInForm.selectedTemplate === "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    {" "}
                    select Template
                  </span>
                )}
              </Box>

              {/* <div className="course-builder-info">
              <div className="course-builder-inputLable" style={{ marginTop: "2vw" }}>
                Course Instructor *
              </div>
              <select
                id="courseInstructor"
                className="course-builder-inputSelect"
                value={instructorId}
                onChange={(e) => {
                  setInstructorId(e.target.value);
                  setCheckErrorInForm((prev) => ({ ...prev, instructorId: "" }));
                }}
                style={{
                  border: `${
                    checkErrorInForm.instructorId == "error" ? "2px solid red" : "1px solid #d7d7d7"
                  }`,
                }}
              >
                <option value="" selected disabled hidden>
                  Select an Option
                </option>
                {instructorArr?.map((item, id) => (
                  <option value={item.name} key={id}>
                    {item.name}
                  </option>
                ))}
              </select>

              {checkErrorInForm.instructorId === "error" && (
                <span style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}>
                  {" "}
                  select instructor
                </span>
              )}
            </div> */}
              <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Typography
                  sx={{ fontWeight: 500, color: "#171B1E", width: "20%" }}
                >
                  Course category <span style={{ color: "#DE5353" }}>*</span>
                </Typography>
                <select
                  id="courseCategory"
                  style={{
                    border: `${
                      checkErrorInForm.courseCategory == "error"
                        ? "2px solid red"
                        : "1px solid #1E3758"
                    }`,
                    outline: "none",
                    width: "30%",
                    height: "60px",
                    padding: "10px",
                    fontSize: "18px",
                    borderRadius: "6px",
                  }}
                  onClick={(e) => {
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      courseCategory: "",
                    }));
                  }}
                  multiple
                >
                  <option
                    style={{
                      padding: "10px",
                      color: "#1E3758",
                      fontSize: "20px",
                      height: "30px",
                    }}
                    value=""
                    selected
                    disabled
                    hidden
                  >
                    Select an Option
                  </option>
                  {categoryArr?.map((item, id) => {
                    const fun = (i) => {
                      for (let j = 0; j < selectedCateGory.length; j++) {
                        if (selectedCateGory[j] == i.id) {
                          return true;
                        }
                      }
                      return false;
                    };
                    if (fun(item)) {
                      // console.log(item);
                      return (
                        <option
                          value={item.id}
                          selected={true}
                          key={id}
                          style={{
                            padding: "10px",
                            color: "#1E3758",
                            fontSize: "20px",
                            height: "30px",
                          }}
                        >
                          {item.categoryName}
                        </option>
                      );
                    } else {
                      return (
                        <option
                          value={item.id}
                          key={id}
                          style={{
                            padding: "10px",
                            color: "#1E3758",
                            fontSize: "20px",
                            height: "30px",
                          }}
                        >
                          {item.categoryName}
                        </option>
                      );
                    }
                  })}
                </select>
                {checkErrorInForm.courseCategory === "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    {" "}
                    select courseCategory
                  </span>
                )}
              </Box>

              <br></br>
              {checkFailed && (
                <div>
                  <p style={{ color: "red" }}>Fill all the field in the Form</p>
                </div>
              )}
            </Stack>
            <Box
              sx={{
                display: "flex",
                gap: "40px",
                justifyContent: "center",
                alignItems: "center",
                mt: "30px",
              }}
            >
              {" "}
              <Button
                sx={{
                  background:
                    "linear-gradient(94.87deg, #062A59 0%, #C2512C 99.3%)",
                  boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
                  borderRadius: "5px",
                  color: "#FFF",
                  padding: "8px 25px",
                }}
                onClick={addCourse}
                id="addC"
              >
                Save Course
              </Button>
              {EditFlagCourse ? (
                <button
                  id="addL"
                  className="secondary-btn"
                  onClick={moveToLesson}
                >
                  Move To Question Set
                </button>
              ) : (
                ""
              )}
            </Box>
          </Box>

          {/* <div style={{ height: "100px", margin: "auto" }} className="course-builder-top">
        <button
        id="addC"
          style={{ margin: "auto" }}
          className="course-builder-button"
          onClick={() => navigate(-1)}
        >
          Back
        </button>{" "}
      </div> */}
          {/* {courseAdd==false?<ViewCourses/>:<Lesson/>} */}
        </div>
      ) : (
        <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td">
            <div className="spinner-container">
              <div className="loading-spinner"></div>
            </div>
          </div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div
            className="admin-overdue-bottom-table-td"
            style={{ color: "#004577" }}
          ></div>
        </div>
      )}
    </>
  );
};

export default Course;
