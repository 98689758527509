import { t } from "i18next";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Component/NavbarTop";
import Sidebar from "../Component/Sidebar";
import "./CourseList.css";
import { Timestamp } from "firebase/firestore";
import { redis } from "../../redisFun";
import { getCookie } from "../../Cookies";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Cdata } from "./CourseData";
import { Checkbox } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../Firebase";
import LoadingScreen from "./BasicCourse/lodingScree";

function TrainInternalTrainEn() {
  const [courseArray, setCourseArray] = useState(null);
  const getAllCourse = httpsCallable(functions, "getAllCourse");
  const archiveCourse = httpsCallable(functions, "archiveCourse");
  const unarchiveCourse = httpsCallable(functions, "unarchiveCourse");
  const { clrs } = useSelector((state) => state.createClr);
  const [showLoading, setLoading] = useState(true);
  const [extraArrForCourseData, setExtraArrForCourse] = useState([]);
  const [archiveDataArr, setArchivedDataArr] = useState([]);
  const [buttonTogalForChangeState, setButtonTogalForarchived] = useState(true);
  const [handelUseEffectTogal, setHandelUseEffectTogal] = useState(true);
  const [tempLoading, setTemploading] = useState("test");

  const [loadingScreen, setLoadingScreen] = useState(false);
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const goToBack = () => navigate("/admin/training");

  useEffect(() => {
    if (
      getCookie("UID") == null ||
      (getCookie("ROLE") != "admin" && getCookie("ROLE") != "instructor")
    ) {
      goToHome();
      return <></>;
    }
  });

  const [arrLength, setArrLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    if (redis) {
    } else {
      setLoadingScreen(true);
      getAllCourse()
        .then((res) => {
          if (res.data?.error) {
            setCourseArray([]);
            console.log(res.data?.error);
            setArrLength(0);
            setLoading(false);
            setLoadingScreen(false);
            return;
          }
          setLoadingScreen(false);
          console.log(res.data?.data);
          setCourseArray(
            res.data?.data.map((item) => ({ ...item, selected: false }))
            // .filter((item) => item.archived != true)
          );
          setExtraArrForCourse(
            res.data?.data.map((item) => ({ ...item, selected: false }))
            // .filter((item) => item.archived != true)
          );
          setArchivedDataArr(
            res.data?.data.map((item) => ({ ...item, selected: false }))
            // .filter((item) => item.archived == true)
          );
          setArrLength(res.data?.data?.length);
          setButtonTogalForarchived(true);
          setLoading(false);
        })
        .catch((e) => {
          setLoadingScreen(false);
        });
    }
  }, [handelUseEffectTogal]);

  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(arrLength / pageSize)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };
  const handleAction = (e, item, id) => {
    if (e.target.value == "edit") {
      navigate("/admin/courseBuilder", {
        state: { courseId: courseArray[id].courseId },
      });
    }

    if (e.target.value == "overview") {
      navigate("/admin/course/CourseOverView", {
        state: { courseId: courseArray[id].courseId },
      });
    }
  };
  const SelectHand = (i) => {
    let newData;
    newData = courseArray.map((item, id) => {
      if (id === i) {
        item.selected = item.selected ? false : true;
        return item;
      } else {
        return item;
      }
    });
    if (newData) setCourseArray(newData);
  };

  function createdDate(createdOn) {
    let displayDate = Timestamp.fromMillis(createdOn?._seconds * 1000)
      .toDate()
      .toDateString();
    return displayDate;
  }

  const buttonForArchivedFun = (type) => {
    if (type == "archived") {
      setCourseArray(archiveDataArr);
      setArrLength(archiveDataArr.length);
      setCurrentPage(0);
    } else if (type == "course") {
      setCourseArray(extraArrForCourseData);
      setArrLength(extraArrForCourseData.length);
      setCurrentPage(0);
    }
    setButtonTogalForarchived(!buttonTogalForChangeState);
  };

  const GoToOverView = (courseId) => {};
  return (
    <div className="courselist">
      <Navbar page={t("Training")} />
      <LoadingScreen
        open={loadingScreen}
        onClose={() => setLoadingScreen(false)}
      />

      <div
        className="courselist-main"
        style={{ gridTemplateColumns: "6vw 1fr" }}
      >
        <Sidebar page={"training"} hidepage={true} />
        <div className="courselist-main-cnt">
          <div
            class="admin-dashboard-main-top-last"
            style={{
              height: " 3vh",
              width: "10vh",
              marginTop: "1vw",
              marginLeft: "75.5vw",
              marginBottom: "1vw",
            }}
          >
            {/* {buttonTogalForChangeState ? (
              <button
                style={{
                  width: "10vw",

                  background: `${clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"}`,
                }}
                onClick={() => {
                  buttonForArchivedFun("archived");
                }}
              >
                <div></div>
                <div style={{ marginLeft: "-2vw" }}>{t("Show Archive ")}</div>
              </button>
            ) : (
              <button
                style={{
                  width: "10vw",

                  background: `${clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"}`,
                }}
                onClick={() => {
                  buttonForArchivedFun("course");
                }}
              >
                <div></div>
                <div style={{ marginLeft: "-2vw" }}>{t("Show Course")}</div>
              </button>
            )} */}
          </div>

          <div className="courselist-main-cnt-top">
            <div className="courselist-main-cnt-top-txt"></div>

            <div>
              <Link to={"/admin/courseBuilder"}>
                <button className="primary-btn">
                  {t("Add New Entrance Course")}
                </button>
              </Link>
            </div>
          </div>
          <div className="course-head">{t("Entrance Course")}</div>
          <div className="courselist-main-cnt-bottom">
            <div
              className="courselist-main-cnt-bottom-heading"
              style={{ textAlign: "center", padding: "0" }}
            >
              <div style={{ textAlign: "left", paddingLeft: "3vw" }}>
                {t("Sr.No.")}
              </div>

              <div style={{ paddingLeft: "1vw" }}>{t("Trainings")}</div>
              <div style={{ paddingLeft: "1vw" }}>{t("Created Date")}</div>
              <div>{t("Action")}</div>
            </div>
            <div className="admin-overdue-bottom">
              <div className="admin-overdue-bottom-table">
                {showLoading && (
                  <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td">
                      <div className="spinner-container">
                        <div className="loading-spinner"></div>
                      </div>
                    </div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div
                      className="admin-overdue-bottom-table-td"
                      style={{ color: "#004577" }}
                    ></div>
                  </div>
                )}
                {courseArray?.map((item, id) => {
                  if (id < pageSize * currentPage) return;
                  if (id >= pageSize * (currentPage + 1)) return;
                  return (
                    <div
                      className="admin-overdue-bottom-table-cnt-c "
                      style={{
                        backgroundColor: `${
                          item.selected
                            ? "rgba(0, 69, 119, 0.1)"
                            : "rgba(255, 255, 255, 1)"
                        }`,
                      }}
                      key={id}
                    >
                      <div
                        className="admin-overdue-bottom-table-td"
                        style={{
                          display: "grid",
                          justifyContent: "left",
                          gridTemplateColumns: "1fr 1fr 1fr 1fr",
                          alignItems: "center",
                        }}
                        id="c-div"
                      >
                        {/* <Checkbox
                          checked={item.selected}
                          onClick={() => SelectHand(id)}
                        /> */}
                        {id + 1}
                      </div>

                      <div className="admin-overdue-bottom-table-td" id="c-div">
                        {item.courseName}
                      </div>
                      <div className="admin-overdue-bottom-table-td" id="c-div">
                        {createdDate(item.created)}
                      </div>

                      <div className="admin-overdue-bottom-table-td" id="c-div">
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "3vw 3vw",
                          }}
                        >
                          <div>
                            {item.archived ? (
                              tempLoading == id ? (
                                <p>Loading...</p>
                              ) : (
                                <select
                                  style={{
                                    border: "none",
                                    backgroundColor: "#F2F2F2",
                                    height: "4vh",
                                    width: "7vw",
                                    padding: "0 1vh",
                                    fontWeight: "700",
                                    color: "#717579",
                                    borderRadius: "6px",
                                  }}
                                  onChange={(e) => handleAction(e, item, id)}
                                >
                                  <option value={"action"}>
                                    {t("Action")}
                                  </option>
                                  {/* <option value={"unarchive"}>{t("UnArchive")}</option> */}
                                </select>
                              )
                            ) : tempLoading == id ? (
                              <p>Loading...</p>
                            ) : (
                              <select
                                style={{
                                  border: "none",
                                  backgroundColor: "#F2F2F2",
                                  height: "4vh",
                                  width: "7vw",
                                  padding: "0 1vh",
                                  fontWeight: "700",
                                  color: "#717579",
                                  borderRadius: "6px",
                                }}
                                onChange={(e) => handleAction(e, item, id)}
                              >
                                <option value={"action"}>{t("Action")}</option>
                                <option value={"edit"}>{t("Edit")}</option>
                                <option value={"overview"}>
                                  {t("Course Over View")}
                                </option>
                              </select>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="admin-overdue-bottom-pagination">
                <div className="admin-overdue-bottom-pagination-cnt">
                  <div className="admin-overdue-bottom-pagination-cnt-item">
                    <svg
                      onClick={() => clickhandler(currentPage - 1)}
                      width="auto"
                      height="22"
                      viewBox="0 0 14 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                        fill="#717579"
                      />
                    </svg>
                  </div>
                  <div className="admin-overdue-bottom-pagination-cnt-item-btn">
                    {currentPage + 1} of {Math.ceil(arrLength / pageSize)}
                  </div>
                  <div
                    style={{ marginRight: "19vw" }}
                    className="admin-overdue-bottom-pagination-cnt-item"
                  >
                    <svg
                      onClick={() => clickhandler(currentPage + 1)}
                      width="auto"
                      height="20"
                      viewBox="0 0 13 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                        fill="#717579"
                      />
                    </svg>
                  </div>
                  <div
                    style={{ display: "flex", marginRight: "-30vw" }}
                    className="admin-overdue-bottom-pagination-cnt-item"
                  >
                    <label className="admin-row">{t("Show")}</label>
                    <select
                      style={{
                        width: "4vw",
                        margin: "0.5vw",
                        marginBottom: "1.5vw",
                        height: "1.5vw",
                      }}
                      value={pageSize}
                      onChange={(e) => setPageSize(e.target.value)}
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={15}>15</option>
                      <option value={20}>20</option>
                    </select>
                    <label className="admin-row"> {t("PagePerItem")}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="courselist-main-cnt-back">
            <div className="courselist-main-cnt-back-btn">
              <button className="secondary-btn" onClick={goToBack}>
                Back
              </button>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrainInternalTrainEn;
