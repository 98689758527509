import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { Navigate, useNavigate } from "react-router";
// import { functions } from "../../../../Firebase";

const LoadingScreen = (props) => {
  const { onClose, open} = props;
  const handleClose = () => {
    setInputId("")
    onClose();
  };
  const [dueDate, setDueDate] = useState(new Date().toISOString().split("T")[0]);
  const [loading, setLoading] = useState(false);

  const [inputId,setInputId]=useState("")


  let navigater=useNavigate()
 

  return (
    <Dialog
      fullWidth={false}
      maxWidth="300px"
      PaperProps={{
        style: {
          borderRadius: "20px",
          height: "270px",
          padding: "16px",
        },
      }}
      onClose={() => {
        if (loading) return;
        handleClose();
      }}
      open={open}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "left",
          color: "#11047A",
        }}
      >
        
      </DialogTitle>
      <Box
        sx={{
          padding: "16px 24px",
        }}
      >
       
       <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
    <div className="admin-overdue-bottom-table-td"></div>
    <div className="admin-overdue-bottom-table-td"></div>
    <div className="admin-overdue-bottom-table-td"></div>
    <div className="admin-overdue-bottom-table-td"></div>
    <div className="admin-overdue-bottom-table-td">
      <div className="spinner-container" style={{width:"100px",height:"100px"}}>
        <div className="loading-spinner" style={{width:"100px",height:"100px"}}></div>
      </div>
    </div>
    <div className="admin-overdue-bottom-table-td"></div>
    <div className="admin-overdue-bottom-table-td"></div>
    <div className="admin-overdue-bottom-table-td"></div>
    <div
      className="admin-overdue-bottom-table-td"
      style={{ color: "#004577"}}></div>

       </div>
      </Box>
    </Dialog>
  );
};

export default LoadingScreen;
