import React, { createRef, useEffect } from "react";
import { useState } from "react";
import $ from "jquery";
import "./CreateCourse.css";
import CreateQuestion from "./CreateQuestion";
import { SvgVector, EditSvg, ExpandMoreSvg, DeleteSVg } from "./icons";
import { functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import { Timestamp } from "firebase/firestore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { ExpandMoreSharp } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./createTopic.css";
import DeleteLesson from "./lessonDeletePopUp";
import LoadingScreen from "../BasicCourse/lodingScree";
const CreateQuiz = ({ editQuizArr, topicId, courseId, lessonId, name }) => {
  const initialList = [];
  const [list, setList] = useState(initialList);
  const instructionForObjTest = httpsCallable(
    functions,
    "instructionForObjTest"
  );
  const addQuestionObjectiveExtraInfo = httpsCallable(
    functions,
    "addQuestionObjectiveExtraInfo"
  );
  const addAssociateInstructionForObjective = httpsCallable(
    functions,
    "addAssociateInstructionForObjective"
  );
  const getAllQUizDataForObjQuestionSet = httpsCallable(
    functions,
    "getAllQUizDataForObjQuestionSet"
  );
  const addLessonQuizOrder = httpsCallable(functions, "addLessonQuizOrder");

  const getQuizInfo = httpsCallable(functions, "getQuizInfo");
  const getQuestionSetInfo = httpsCallable(functions, "getQuestionSetInfo");
  const deleteQuestionSetObjQuiz = httpsCallable(
    functions,
    "deleteQuestionSetObjQuiz"
  );
  // deleteQuiz

  const [deleteQuizId, setDeleteQuizId] = useState("");
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);

  const [loadingScreen, setLoadingScreen] = useState(false);
  const [quizId, setQuizId] = useState("");
  const [editable, setEditable] = useState(false);
  const [checkFailed, setCheckFailed] = useState(false);
  const [faqAns, setFaqAns] = useState("");
  const [FAQ, setFAQ] = useState([]);

  const [add, setAdd] = useState(false);
  const [quizDetails, setQuizDetails] = useState({
    quizName: "",
    passScore: "",
    retry: "",
    answerAll: "yes",
    timeLimit: 1,
    randomizeQuestions: "",
    skipQuestion: "",
    randomAnswers: "",
    successMessage: "You Successfully completed the Quiz",
    retakeMessage: "Better luck next time",
    instruction: "flow the ins",
  });
  const [checkErrorInForm, setCheckErrorInForm] = useState({
    quizName: "",
    passScore: "",
    retry: "",
    answerAll: "yes",
    timeLimit: 1,
    randomizeQuestions: "yes",
    skipQuestion: "yes",
    randomAnswers: "yes",
    successMessage: "You Successfully completed the Quiz",
    retakeMessage: "Better luck next time",
    instruction: "flow t",
  });
  useEffect(() => {
    if (!editQuizArr) return;
    setLoadingScreen(true);
    getAllQUizDataForObjQuestionSet({ questionSetId: lessonId })
      .then((res) => {
        setLoadingScreen(false);
        for (let i = 0; i < res.data.length; i++) {
          setList((prev) =>
            prev.concat({
              id: res.data[i]?.instructionForObjId,
              name: res.data[i]?.instructionName,
              passScore: res.data[i]?.passingScore,
              retry: res.data[i]?.noOfRetries,
              timeLimit: res.data[i]?.quizTime,
              success: res.data[i]?.successMessage,
              retake: res.data[i]?.retakeMessage,
              answerAll: res.data[i]?.answerAll,
              random: res.data[i]?.randomizeQuestions,
              skip: res.data[i]?.skipQuestion,
              randomAnswer: res.data[i]?.randomiseAns,

              instruction: res.data[i]?.instruction,
            })
          );
        }
      })
      .catch((e) => {
        setLoadingScreen(false);
      });
  }, [editQuizArr]);

  const quizFormRef = createRef();

  const handleInput = (e) => {
    const { value, name } = e.target;
    setQuizDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  function checkForm() {
    let keys = Object.keys(quizDetails);
    let res = "allIsWell";
    for (let key of keys) {
      if (quizDetails[key] === "" && key !== "timeLimit") {
        //console.log(key);
        setCheckErrorInForm((prev) => ({ ...prev, [key]: "error" }));
        res = key;
        break;
      }
    }
    if (FAQ.length == 0) {
      setCheckErrorInForm((prev) => ({ ...prev, instruction: "error" }));
      res = "errpt";
    }
    return res;
  }

  function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }
    if (lessonId != null) {
      addAssociateInstructionForObjective({
        questionSetId: lessonId,
        instructionForObjectiveId: quizId,
        edit: true,
      });
    }
    //else if (lessonId != null) {
    //   addLessonQuizOrder({
    //     lessonId: lessonId,
    //     quizzes: orderList,
    //     edit: true,
    //   });
    // }
  }
  const addQuiz = async () => {
    let check = checkForm();

    let nsn = quizDetails.quizName.replaceAll(" ", "");

    let dat = /[^A-Za-z0-9]/.test(nsn);

    if (!dat) {
    } else {
      alert("you can not use any special characters");
      return;
    }
    //////

    if (check != "allIsWell") {
      // console.log(check);
      setCheckFailed(true);
      return;
    }

    setCheckErrorInForm({
      quizName: "",
      passScore: "",
      retry: "0",
      answerAll: "yes",
      timeLimit: "",
      randomizeQuestions: "yes",
      skipQuestion: "yes",
      randomAnswers: "yes",
      successMessage: "",
      retakeMessage: "",
      instruction: "",
    });
    setCheckFailed(false);

    const newList = list.concat({
      id: quizId,
      name: quizDetails.quizName,
      passScore: quizDetails.passScore,
      retry: quizDetails.retry,
      timeLimit: quizDetails.timeLimit,
      success: quizDetails.successMessage,
      retake: quizDetails.retakeMessage,
      answerAll: quizDetails.answerAll,
      random: quizDetails.randomizeQuestions,
      skip: quizDetails.skipQuestion,
      randomAnswer: quizDetails.randomAnswers,
      instruction: FAQ,
      lessonId: lessonId,
    });
    instructionForObjTest({
      instructionForObjId: quizId,
      created: new Date().toString(),
      instructionName: quizDetails.quizName,

      associatedQuestionSet: lessonId,
      courseAssociated: courseId,
      instruction: FAQ,
    });

    addQuestionObjectiveExtraInfo({
      instructionForObjId: quizId,

      retakeMessage: quizDetails.retakeMessage,
      successMessage: quizDetails.successMessage,
      noOfRetries: quizDetails.retry,
      passingScore: quizDetails.passScore,
      randomiseAns: quizDetails.randomAnswers,
      randomizeQuestions: quizDetails.randomizeQuestions,
      skipQuestion: quizDetails.skipQuestion,
      quizTime: quizDetails.timeLimit,
    });

    setList(newList);
    saveOrder(newList);

    alert("Added");

    setQuizDetails({
      quizName: "",
      passScore: "",
      retry: "5",
      answerAll: "yes",
      timeLimit: "",
      randomizeQuestions: "yes",
      skipQuestion: "yes",
      randomAnswers: "yes",
      successMessage: "",
      retakeMessage: "",
      instruction: "",
    });

    toggleForm();
  };

  const toggleForm = (e) => {
    quizFormRef.current.style.display =
      quizFormRef.current.style.display === "none" ? "" : "none";
    setQuizDetails({
      quizName: "",
      passScore: "",
      retry: "5",
      answerAll: "yes",
      timeLimit: "",
      randomizeQuestions: "yes",
      skipQuestion: "yes",
      randomAnswers: "yes",
      successMessage: "You Successfully completed the Quiz",
      retakeMessage: "Better luck next time",
      instruction: "flow",
    });
    setQuizId("");
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    saveOrder(items);
  }

  function edit(quiz) {
    setEditable(true);
    quizFormRef.current.style.display = "";
    let params = {
      quizName: quiz.name,
      passScore: quiz.passScore,
      retry: quiz.retry,
      answerAll: quiz.answerAll,
      timeLimit: quiz.timeLimit,
      randomizeQuestions: quiz.random,
      skipQuestion: quiz.skip,
      randomAnswers: quiz.randomAnswer,
      successMessage: quiz.success,
      retakeMessage: quiz.retake,

      lessonId: quiz.lessonId,
    };
    setFAQ(quiz.instruction);
    setQuizDetails(params);
    setQuizId(quiz.id);
  }

  function updateQuiz() {
    let check = checkForm();
    let nsn = quizDetails.quizName.replaceAll(" ", "");

    let dat = /[^A-Za-z0-9]/.test(nsn);

    if (!dat) {
    } else {
      alert("you can not use any special characters");
      return;
    }
    if (check != "allIsWell") {
      setCheckFailed(true);
      // console.log("update ", check);
      return;
    }
    setCheckFailed(false);

    instructionForObjTest({
      instructionForObjId: quizId,
      created: new Date().toString(),
      instructionName: quizDetails.quizName,

      associatedQuestionSet: lessonId,
      courseAssociated: courseId,
      instruction: FAQ,
    });
    addQuestionObjectiveExtraInfo({
      instructionForObjId: quizId,

      retakeMessage: quizDetails.retakeMessage,
      successMessage: quizDetails.successMessage,
      noOfRetries: quizDetails.retry,
      passingScore: quizDetails.passScore,
      randomiseAns: quizDetails.randomAnswers,
      randomizeQuestions: quizDetails.randomizeQuestions,
      skipQuestion: quizDetails.skipQuestion,
      quizTime: quizDetails.timeLimit,
    });

    for (let i = 0; i < list.length; i++) {
      if (quizId === list[i].id) {
        list[i] = {
          id: quizId,
          name: quizDetails.quizName,
          passScore: quizDetails.passScore,
          retry: quizDetails.retry,
          timeLimit: quizDetails.timeLimit,
          success: quizDetails.successMessage,
          retake: quizDetails.retakeMessage,
          answerAll: quizDetails.answerAll,
          random: quizDetails.randomizeQuestions,
          skip: quizDetails.skipQuestion,
          randomAnswer: quizDetails.randomAnswers,
          instruction: quizDetails.instruction,
        };
      }
    }
    setList(list);
    alert("Updated");
    setEditable(false);
    quizFormRef.current.style.display = "none";
  }

  async function del(quiz) {
    // implement delete api done
    setopenLessonPopUp(true);
    setDeleteQuizId(quiz);
  }
  const delfunction = (quiz) => {
    deleteQuestionSetObjQuiz({ quizId: quiz.id });
    alert("Deleted " + quiz.name);
    const item = [];
    for (let i = 0; i < list.length; i++) {
      if (quiz.id !== list[i].id) {
        item.push(list[i]);
      }
    }
    setList(item);
    saveOrder(item);
  };

  function addfun() {
    // console.log(add);

    setAdd(!add);
  }

  function addFAQ() {
    let data = FAQ;
    data.push({ data: faqAns });

    setFAQ(data);

    setFaqAns("");
    setAdd(false);
  }
  // const toggleDForm=()=>{
  //   quizFormRef.current.style.display = quizFormRef.current.style.display === "none" ? "" : "none";
  // }

  const deleteEligibilty = (dd) => {
    let data = [];
    for (let i = 0; i < FAQ.length; i++) {
      if (FAQ[i].data != dd) data.push(FAQ[i]);
    }
    setFAQ(data);
  };
  return (
    <>
      <LoadingScreen
        open={loadingScreen}
        onClose={() => setLoadingScreen(false)}
      />

      <DeleteLesson
        open={openLessonPopUp}
        onClose={() => setopenLessonPopUp(false)}
        delfun={(topic) => {
          delfunction(topic);
        }}
        topicId={deleteQuizId}
      />
      <section className="createQuiz-container">
        {list?.length < 1 ? (
          <button
            className="primary-btn"
            onClick={() => {
              setEditable(false);
              toggleForm();
            }}
          >
            {name}
          </button>
        ) : (
          <button
            className="primary-btn"
            onClick={() => {
              alert("You Can Not Add More Then 1 quiz");
            }}
          >
            {name}
          </button>
        )}
        <div
          className="createQuiz-quizForm"
          ref={quizFormRef}
          id={"quizForm"}
          style={{ display: "none" }}
        >
          <div className="createQuiz-heading">Quiz</div>
          <div className="createQuiz-row">
            <label
              className="createQuiz-inputLabel"
              style={{
                fontWeight: 500,
                color: "#171B1E",
                width: "20%",
              }}
            >
              Quiz Name *
            </label>
            <input
              type="text"
              className="createQuiz-inputBar"
              id={topicId + courseId + lessonId + "quizName"}
              style={{
                border: `${
                  checkErrorInForm.quizName == "error"
                    ? "2px solid red"
                    : "1px solid #d7d7d7"
                }`,
                width: "30%",
                borderRadius: "6px",
                outline: "none",
                padding: "15px",
              }}
              name="quizName"
              value={quizDetails.quizName}
              onChange={(event) => {
                handleInput(event);
                setCheckErrorInForm((prev) => ({ ...prev, quizName: "" }));
                if (!editable) {
                  let value = event.target.value.replaceAll(" ", "");
                  let time = new Date().getTime();
                  setQuizId(value + "_" + time);
                }
              }}
            />{" "}
            {checkErrorInForm.quizName == "error" && (
              <span
                style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
              >
                Enter Quiz Name
              </span>
            )}
          </div>

          {/* <div className="createQuiz-row">
            <p className="createQuiz-inputLabel">Quiz instruction</p>
            <textarea
              type="text"
              className="createQuiz-textBar"
              id={topicId + courseId + lessonId + "instruction"}
              value={quizDetails.instruction}
              name="instruction"
              onChange={handleInput}
            />
          </div> */}

          <div className="createQuiz-row">
            <label
              className="createQuiz-inputLabel"
              style={{
                fontWeight: 500,
                color: "#171B1E",
                width: "20%",
              }}
            >
              Passing Score in Percentage *
            </label>
            <input
              type="number"
              className="createQuiz-inputBar"
              id={topicId + courseId + lessonId + "passScore"}
              min={0}
              max={100}
              style={{
                border: `${
                  checkErrorInForm.passScore == "error"
                    ? "2px solid red"
                    : "1px solid #d7d7d7"
                }`,
                width: "30%",
                borderRadius: "6px",
                outline: "none",
                padding: "15px",
              }}
              name={"passScore"}
              value={quizDetails.passScore}
              onChange={(event) => {
                if (event.target.value < 0) {
                  event.target.value = quizDetails.passScore;
                } else {
                  if (event.target.value > 100) {
                    event.target.value = 0;
                  } else {
                    handleInput(event);
                    setCheckErrorInForm((prev) => ({ ...prev, passScore: "" }));
                  }
                }
              }}
            />
            {checkErrorInForm.passScore == "error" && (
              <span
                style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
              >
                Enter Passing Score in Percentage
              </span>
            )}
          </div>

          <div className="createQuiz-row">
            <p
              className="createQuiz-inputLabel"
              style={{
                fontWeight: 500,
                color: "#171B1E",
                width: "20%",
              }}
            >
              Time Limit (minutes)*
            </p>
            <input
              type="number"
              disabled={true}
              className="createQuiz-inputBar"
              id={topicId + courseId + lessonId + "timeLimit"}
              name="timeLimit"
              value={quizDetails.timeLimit}
              style={{
                width: "30%",
                borderRadius: "6px",
                outline: "none",
                padding: "15px",
              }}
            />
          </div>

          {/* <div className="createQuiz-row">
            <p className="createQuiz-inputLabel">No fo retry *</p>
            <input
              type="number"
           value={quizDetails.retry}
              className="createQuiz-inputBar"
              id={topicId + courseId + lessonId + "noofretry"}
              style={{border:`${ checkErrorInForm.retry=="error"?"2px solid red":"1px solid #d7d7d7"}`}}
              name="retry"
              onChange={(e)=>{handleInput(e);
              setCheckErrorInForm((prev)=>({...prev,retry:""}))
              }}
             
            />

{

checkErrorInForm.retry=="error"&&<span style={{marginLeft:"20px",color:"red",fontSize:"15px"}}>Enter number of retry</span>
}    
            
          </div> */}

          <div className="createQuiz-row">
            <p
              className="createQuiz-inputLabel"
              style={{
                fontWeight: 500,
                color: "#171B1E",
                width: "20%",
              }}
            >
              Randomize Questions
            </p>
            <select
              className="createQuiz-inputBar"
              id={topicId + courseId + lessonId + "randomQuestion"}
              value={quizDetails.randomizeQuestions}
              style={{
                border: `${
                  checkErrorInForm.randomizeQuestions == "error"
                    ? "2px solid red"
                    : "1px solid #d7d7d7"
                }`,
                width: "30%",
                outline: "none",
                height: "40px",
              }}
              name="randomizeQuestions"
              onChange={(e) => {
                handleInput(e);
                setCheckErrorInForm((prev) => ({
                  ...prev,
                  randomizeQuestions: "",
                }));
              }}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>

            {checkErrorInForm.randomizeQuestions == "error" && (
              <span
                style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
              >
                Enter Passing Score in Percentage
              </span>
            )}
          </div>

          <div className="createQuiz-row">
            <p
              className="createQuiz-inputLabel"
              style={{
                fontWeight: 500,
                color: "#171B1E",
                width: "20%",
              }}
            >
              Randomize Answers
            </p>
            <select
              className="createQuiz-inputBar"
              id={topicId + courseId + lessonId + "randomAnswer"}
              value={quizDetails.randomAnswers}
              style={{
                border: `${
                  checkErrorInForm.randomAnswers == "error"
                    ? "2px solid red"
                    : "1px solid #d7d7d7"
                }`,
                width: "30%",
                outline: "none",
                height: "40px",
              }}
              name="randomAnswers"
              onChange={(e) => {
                handleInput(e);
                setCheckErrorInForm((prev) => ({ ...prev, randomAnswers: "" }));
              }}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>

            {checkErrorInForm.randomAnswers == "error" && (
              <span
                style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
              >
                Enter Passing Score in Percentage
              </span>
            )}
          </div>

          {/* <div className="createQuiz-row">
            <p className="createQuiz-inputLabel">Success Message</p>
            <input
              type="text"
              className="createQuiz-inputBar"
              id={topicId + courseId + lessonId + "successMessage"}
              value={quizDetails.successMessage}
              name="successMessage"
              onChange={handleInput}
            />
          </div> */}

          {/* <div className="createQuiz-row">
            <p className="createQuiz-inputLabel">Retake Message</p>
            <input
              type="text"
              className="createQuiz-inputBar"
              id={topicId + courseId + lessonId + "retakeMessage"}
              value={quizDetails.retakeMessage}
              name="retakeMessage"
              onChange={handleInput}
            />
          </div> */}

          <div className="course-builder-info">
            <div
              className="course-builder-inputLable"
              style={{
                fontWeight: 500,
                color: "#171B1E",
                width: "20%",
              }}
            >
              Quiz Instruction{" "}
              <span>
                {" "}
                <button
                  className="primary-btn-input"
                  onClick={(e) => {
                    addfun();

                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      instruction: "",
                    }));
                  }}
                >
                  +
                </button>
              </span>
            </div>
            {checkErrorInForm.instruction == "error" && (
              <span
                style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
              >
                Enter Quiz instruction
              </span>
            )}
          </div>

          {FAQ.map((e, id) => (
            <>
              <div className="course-builder-info">
                <div
                  className="course-builder-inputLable"
                  style={{
                    fontWeight: 500,
                    color: "#171B1E",
                    width: "20%",
                  }}
                >
                  Instruction {id + 1}
                </div>
                <input
                  type="text"
                  className="course-builder-inputBar"
                  value={e.data}
                  style={{
                    border: `${
                      checkErrorInForm.trainingProvider == "error"
                        ? "2px solid red"
                        : "1px solid #d7d7d7"
                    }`,
                    width: "30%",
                    borderRadius: "6px",
                    outline: "none",
                    padding: "15px",
                  }}
                  id="trainingProvider"
                />

                <button
                  className="course-builder-button"
                  style={{
                    background:
                      "linear-gradient(92.2deg, #1E3758 0.25%, #0475B5 100%)",
                    marginLeft: "5vw",
                  }}
                  onClick={() => {
                    deleteEligibilty(e.data);
                  }}
                >
                  {" "}
                  Delete
                </button>
              </div>
            </>
          ))}

          {add == true && (
            <>
              <div className="course-builder-info">
                <div
                  className="course-builder-inputLable"
                  style={{
                    fontWeight: 500,
                    color: "#171B1E",
                    width: "20%",
                  }}
                >
                  Instruction
                </div>
                <input
                  type="text"
                  className="course-builder-inputBar"
                  value={faqAns}
                  style={{
                    border: `${
                      checkErrorInForm.trainingProvider == "error"
                        ? "2px solid red"
                        : "1px solid #d7d7d7"
                    }`,
                    width: "30%",
                    borderRadius: "6px",
                    outline: "none",
                    padding: "15px",
                  }}
                  id="trainingProvider"
                  onChange={(event) => {
                    setFaqAns(event.target.value);
                  }}
                />
              </div>

              <button id="addC" className="action-btn" onClick={addFAQ}>
                Save
              </button>
            </>
          )}

          {checkFailed && <p>Enter the Required Fields</p>}
          <div
            className="createQuiz-row"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "30px",
              marginTop: "30px",
            }}
          >
            <button
              className="primary-btn"
              onClick={!editable ? addQuiz : updateQuiz}
            >
              Save Quiz
            </button>

            <button
              className="secondary-btn"
              style={{ marginLeft: "10px" }}
              onClick={toggleForm}
            >
              Cancel
            </button>
          </div>
        </div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <ul
                id="quizList"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: "block",
                  overflow: "hidden",
                  width: "100%",
                  listStyleType: "none",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                {list.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        key={item.id}
                        className="quiz-dragable"
                      >
                        <Accordion>
                          <AccordionSummary
                            {...provided.dragHandleProps}
                            expandIcon={<ExpandMoreSvg />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                              backgroundColor: "#1E3758",
                              color: "white",
                              borderRadius: "5px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <SvgVector />
                              <div className="quiz-accordion-heading">
                                {item.name}
                              </div>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="quiz-accordion-details-head">
                              <p>{item.name}</p>
                              <div
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "10px",
                                }}
                                onClick={() => del(item)}
                              >
                                <DeleteSVg size={28} />
                              </div>
                              <div onClick={() => edit(item)}>
                                <EditSvg size={28} />
                              </div>
                            </div>
                            <p>Associated course - {item.id}</p>

                            <CreateQuestion
                              editQuizArr={editQuizArr}
                              questionData={item.questionData}
                              quizId={item.id}
                              questionSetId={item.lessonId}
                              type={"questionSet"}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </section>
    </>
  );
};

export default CreateQuiz;
