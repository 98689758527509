import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";

const MileStoneObjective = ({ uid, data }) => {
  const [training, setTraining] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [arrLength, setArrLength] = useState(0);
  const [loading, setLoading] = useState(false);

  const [questionsArr, setQuestionsArr] = useState([]);
  const fetch_user_courses = httpsCallable(functions, "fetch_user_courses");
  useEffect(() => {
    setLoading(true);
    fetch_user_courses({ select: 2, uid: uid }).then((result) => {
      setLoading(false);
      if (result.data?.error) {
        return;
      }
      setTraining(result.data);
      setArrLength(result.data?.length);
    });
  }, []);
  console.log("UID", uid);
  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(arrLength / 5)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };
  return (
    <div
      className="admin-overdue-bottom"
      style={{ padding: "20px", fontFamily: "Poppins" }}
    >
      <div style={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: 500 }}>
        {t("Objective Test View")}
      </div>

      <div
        className="custom-scrollbar"
        style={{
          height: "400px",
          overflowY: "scroll",
        }}
      >
        <div
          style={{
            margin: "10px",
            borderRadius: "10px",
            padding: "20px",
            border: "2px solid #000",
          }}
        >
          {data.length > 0 ? (
            data[0].objectiveAns?.map((e, index) => (
              <div>
                <div style={{ display: "flex", gap: "20px" }}>
                  <h2>Q.{index + 1}</h2>
                  <h3>{e.question}</h3>
                </div>

                <div
                  style={{
                    display: "grid",
                    gridTemplateRows: "40px 40px 40px 40px",
                    marginLeft: "100px",
                    marginTop: "30px",
                  }}
                >
                  <div
                    style={{
                      color:
                        e.rightAns === e.userAns && e.userAns === "0"
                          ? "green"
                          : e.userAns === "0"
                          ? "red"
                          : "black",
                    }}
                  >
                    <p>1: {e.option1} </p>
                  </div>
                  <div
                    style={{
                      color:
                        e.rightAns === e.userAns && e.userAns === "1"
                          ? "green"
                          : e.userAns === "1"
                          ? "red"
                          : "black",
                    }}
                  >
                    <p>2: {e.option2} </p>
                  </div>{" "}
                  <div
                    style={{
                      color:
                        e.rightAns === e.userAns && e.userAns === "2"
                          ? "green"
                          : e.userAns === "2"
                          ? "red"
                          : "black",
                    }}
                  >
                    <p>3: {e.option3} </p>
                  </div>{" "}
                  <div
                    style={{
                      color:
                        e.rightAns === e.userAns && e.userAns === "3"
                          ? "green"
                          : e.userAns === "3"
                          ? "red"
                          : "black",
                    }}
                  >
                    <p>4: {e.option4} </p>
                  </div>
                </div>

                <div style={{ marginLeft: "80%", marginTop: "20px" }}>
                  <p>User Ans {Number(e.userAns) + 1}</p>
                  <p>right Ans {Number(e.rightAns) + 1}</p>
                </div>
              </div>
            ))
          ) : (
            <div>
              {" "}
              <h1> No Objective Data Found</h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MileStoneObjective;
