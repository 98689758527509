import React, { useEffect } from "react";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { functions } from "../../../Firebase";
import "./AdminSetting.css";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Sidebar from "../../Component/Sidebar";
import NavbarTop from "../../Component/NavbarTop";
import { t } from "i18next";
import editIcon from "../../../utilities/images/editIcon.svg";
import deleteIcon from "../../../utilities/images/deleteIcon.svg";

export const CompanyDepartmentSettings = () => {
  const [showdepform, setShowdepform] = useState(false);
  const [showQuestionForm, setShowQuestionForm] = useState(false);

  const [questionId, setQuestionId] = useState("");
  //================company settings===========================//
  const [template, settemplate] = useState([]);
  const [deltemp, setdeltemp] = useState(false);
  const [showLoading, setLoading] = useState(true);
  const [flagForEdit, setFlagForEdit] = useState(false);
  const fetchQuestionSetCotegory = httpsCallable(
    functions,
    "fetchQuestionSetCotegory"
  );
  const deleteCompany = httpsCallable(functions, "deleteCompany");

  const fetchCourseCategory = httpsCallable(functions, "fetchCourseCategory");
  const [department, setDepartment] = useState([]);
  const [loading1, setLoading1] = useState(true);
  const [deledep, setDeldep] = useState(true);
  // delete company

  // get company and department data
  const fetchdata = () => {
    setLoading(true);
    fetchQuestionSetCotegory({})
      .then((res) => {
        setLoading(false);
        // console.log(res);
        settemplate(res.data);
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err);
      });
  };

  const fetchDepartmentdata = () => {
    setLoading1(true);
    fetchCourseCategory({})
      .then((res) => {
        // console.log(res);
        if (res.data.length > 0) {
          setDepartment(res.data);
        }
        setLoading1(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoading1(false);
      });
  };

  //================make default settings===========================//
  const makeQuestionCategoryDefault = httpsCallable(
    functions,
    "makeQuestionCategoryDefault"
  );
  const makeCourseCategoryDefault = httpsCallable(
    functions,
    "makeCourseCategoryDefault"
  );

  const DefaultCompany = (id) => {
    //  console.log("default c id", id);
    makeQuestionCategoryDefault({ questionCategoryId: id })
      .then((res) => {
        // console.log(res);
        setdeltemp(!deltemp);
        alert("Question category Default changed");
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const DefaultDepartment = (id) => {
    //console.log("default d id", id);
    makeCourseCategoryDefault({ courseCategoryId: id })
      .then((res) => {
        // console.log(res);
        setDeldep(!deledep);
        alert("course Category Default changed");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //================create Department===========================//

  const createQuestionSetCotegory = httpsCallable(
    functions,
    "createQuestionSetCotegory"
  );
  const createCourseCategory = httpsCallable(functions, "createCourseCategory");
  const [saveloading, setSaveloading] = useState(false);
  const [depName, setDepName] = useState("");
  const [questionName, setQuestionName] = useState("");
  const summitDepartment = (type) => {
    // console.log(
    //   "department",
    //   depName,
    //   depName.split(" ").join("_") + "_" + new Date().getTime().toString()
    // );
    if (type == "course") {
      if (depName === "") return alert("question category name is required");
      setSaveloading(true);
      createCourseCategory({
        name: depName,
        courseCategoryId:
          depName.split(" ").join("") + "_" + new Date().getTime().toString(),
      })
        .then((res) => {
          // console.log(res);
          setDepName("");
          alert("courseCategory Created");
          fetchDepartmentdata();
          setSaveloading(false);
          setDeldep(!deledep);
        })
        .catch((err) => {
          // console.log(err);
          setSaveloading(false);
        });
    } else {
      if (questionName === "")
        return alert("question category name is required");
      setSaveloading(true);
      createQuestionSetCotegory({
        name: questionName,
        questionCategoryId: flagForEdit
          ? questionId
          : questionName.split(" ").join("") +
            "_" +
            new Date().getTime().toString(),
      })
        .then((res) => {
          // console.log(res);
          setDepName("");

          setShowQuestionForm(false);
          if (flagForEdit) {
            alert("question Category Updated");
          } else {
            alert("question Category Created");
          }

          fetchdata();
          setSaveloading(false);
          setDeldep(!deledep);
          setFlagForEdit(false);
        })
        .catch((err) => {
          // console.log(err);
          setSaveloading(false);
          setFlagForEdit(false);
        });
    }
  };
  const deleteQuestionCategory = httpsCallable(
    functions,
    "deleteQuestionCategory"
  );
  const deleteQuestionCategoryFun = (id) => {
    deleteQuestionCategory({ questionCategoryId: id })
      .then((res) => {
        // console.log(res);
        setDeldep(!deledep);
        alert("question Category Deleted");
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const editQuestion = (ele) => {
    // console.log(ele)
    setShowQuestionForm(true);
    setQuestionId(ele.questionCategoryId);
    setQuestionName(ele.name);
    setFlagForEdit(true);
  };

  const deleteCourseCategory = httpsCallable(functions, "deleteCourseCategory");
  const deleteDepart = (id) => {
    deleteCourseCategory({ courseCategoryId: id })
      .then((res) => {
        // console.log(res);
        setDeldep(!deledep);
        alert("course Category Deleted");
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    fetchdata();
  }, [deltemp]);
  useEffect(() => {
    fetchDepartmentdata();
  }, [deledep]);

  const navigate = useNavigate();

  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Question Category Settings")} />
        {/* <Navbar /> */}

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              {/* Company settings */}

              {/* Question Settings */}

              <div className="create-new-box">
                <h1
                  style={{
                    fontSize: "18px",
                    fontWeight: 700,
                    fontFamily: "Poppins",
                  }}
                >
                  Question Category
                </h1>
                <button
                  className="primary-btn"
                  onClick={() => setShowQuestionForm(!showQuestionForm)}
                >
                  + Add Question Category
                </button>
              </div>
              {showQuestionForm && (
                <div
                  style={{
                    marginBottom: "30px",
                  }}
                >
                  <div>
                    <p style={{ fontWeight: 500, fontFamily: "Poppins" }}>
                      Question Category Name:{" "}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "50px",
                      marginTop: "10px",
                    }}
                  >
                    <input
                      placeholder="Enter Category Name"
                      style={{
                        fontSize: "18px",
                        padding: "15px",
                        borderRadius: "5px",
                        border: "1px solid #1E3758",
                      }}
                      type="text"
                      value={questionName}
                      onChange={(e) => setQuestionName(e.target.value)}
                    />
                    <button
                      disabled={saveloading}
                      className="primary-btn"
                      onClick={() => {
                        summitDepartment();
                      }}
                    >
                      {saveloading ? "Loading" : "save"}
                    </button>
                  </div>
                </div>
              )}
              <div
                style={{
                  backgroundColor: "#fff",
                  padding: "15px",
                  border: "1px solid #1E3758",
                  borderRadius: "10px",
                }}
              >
                <div className="bigger-container">
                  <div> Question Category Name</div>
                  <div style={{ display: "flex" }}>
                    <div>Action</div>
                    <div style={{ marginLeft: "200px", marginRight: "10px" }}>
                      Make Default
                    </div>
                  </div>
                </div>

                <div className="setting_details_table">
                  {loading1 && (
                    <div
                      className="admin-overdue-bottom-table-cnt-certi"
                      key={0}
                    >
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td">
                        <div className="spinner-container">
                          <div className="loading-spinner"></div>
                        </div>
                      </div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div
                        className="admin-overdue-bottom-table-td"
                        style={{ color: "#004577" }}
                      ></div>
                    </div>
                  )}

                  {template?.map((ele) => {
                    return (
                      <div className="outer-inner-container">
                        <div className="templatename">{ele.name}</div>

                        <div className="button-box">
                          <div className="button-box-inner mx-3 my-1">
                            <button
                              className="action-btn"
                              onClick={() => {
                                editQuestion(ele);
                              }}
                            >
                              <img src={editIcon} alt="" />
                              Edit
                            </button>
                          </div>

                          {!ele.isDefault && (
                            <div className="button-box-inner mx-3 my-1">
                              <button
                                className="action-btn"
                                onClick={() => {
                                  deleteQuestionCategoryFun(
                                    ele.questionCategoryId
                                  );
                                }}
                              >
                                <img src={deleteIcon} alt="" />
                                Delete
                              </button>
                            </div>
                          )}

                          <div
                            className="button-box"
                            style={{ marginLeft: "160px", marginRight: "50px" }}
                          >
                            <input
                              type="checkbox"
                              name={"default"}
                              checked={ele?.isDefault}
                              onChange={(e) =>
                                DefaultCompany(ele.questionCategoryId)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* Department Settings */}
              {/* <h1>Course Category Details</h1>

              <div className="create-new-box">
                <Button
                  variant="contained"
                  component="span"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => setShowdepform(!showdepform)}
                >
                  Add Course Category 
                </Button>
              </div>

              {showdepform && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <span style={{ fontSize: "25px" }}>Course Category Name: </span>
                    <input
                      style={{
                        margin: "20px 5px",
                        padding: "5px",
                        fontSize: "18px",
                      }}
                      type="text"
                      onChange={(e) => setDepName(e.target.value)}
                    />
                  </div>
                  <div>
                    <Button
                      disabled={saveloading}
                      variant="contained"
                      color="primary"
                      onClick={()=>{summitDepartment("course")}}
                    >
                      
                      {saveloading ? "Loading" : "save"}
                    </Button>
                  </div>
                </div>
              )}

              <div className="bigger-container">
                <div>Course Category Name</div>
                <div style={{ display: "flex" }}>
                  <div>Action</div>
                  <div style={{ marginLeft: "100px", marginRight: "10px" }}>
                    Make Default
                  </div>
                </div>
              </div>

              <div className="setting_details_table">
                {loading1 && (
                  <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td">
                      <div className="spinner-container">
                        <div className="loading-spinner"></div>
                      </div>
                    </div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div
                      className="admin-overdue-bottom-table-td"
                      style={{ color: "#004577" }}
                    ></div>
                  </div>
                )}

                {department?.map((ele) => {
                  return (
                    <div className="outer-inner-container">
                      <div className="templatename">{ele.name}</div>

                      <div className="button-box">
                        {!ele.isDefault  && (
                          <div className="button-box-inner mx-3 my-1">
                            <Button
                              variant="contained"
                              component="span"
                              color="error"
                              onClick={() => {
                                deleteDepart(ele.courseCategoryId);
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        )}

                        <div
                          className="button-box"
                          style={{ marginLeft: "160px", marginRight: "50px" }}
                        >
                          <input
                           type="checkbox"
                            name={"default"}
                            checked={ele?.isDefault}
                            onChange={(e) => DefaultDepartment(ele.courseCategoryId)}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>*/}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "30px",
                }}
              >
                <button className="secondary-btn" onClick={() => navigate(-1)}>
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
