import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";
import { useSelector } from "react-redux";
import { display } from "@mui/system";

const ExternalTraining = ({ uid, data, flag, stateForVideoCount }) => {
  const { clrs } = useSelector((state) => state.createClr);
  const [training, setTraining] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [arrLength, setArrLength] = useState(0);

  const RejectedFromCourseHttps = httpsCallable(
    functions,
    "RejectedFromCourseHttps"
  );
  const UpdateUserStatus = httpsCallable(functions, "UpdateUserStatus");
  const subjectiveTestPassHttps = httpsCallable(
    functions,
    "subjectiveTestPassHttps"
  );
  const [userStatusSelect, setUserStatusSelect] = useState({
    status: "",
    comment: "",
    result: "",
    marks: 0,
  });

  const userAppliedCourseUpdate = httpsCallable(
    functions,
    "userAppliedCourseUpdate"
  );
  const userSubjectiveTestStatusChange = httpsCallable(
    functions,
    "userSubjectiveTestStatusChange"
  );
  const [objData, setObjData] = useState([]);
  useState(() => {
    setObjData(data[0]?.subjectiveAns);
    console.log(data[0]);
    setUserStatusSelect({
      status: data[0]?.userStatus,
      comment: data[0]?.rejectedMessageForUser,
      result: data[0]?.result,
      marks: data[0]?.marksOfSubjectiveTest,
    });
    // console.log("rahul");
  }, [flag]);

  console.log(objData);
  const clickhandler = (i) => {
    if (i < 0) return;
    if (
      i + 1 >
      Math.ceil(data.length > 0 && data[0]?.subjectiveAns?.length / 1)
    )
      return;
    //TODO: limit upper
    setCurrentPage(i);
  };

  const windoopen = (type, file) => {
    window.open(file);
  };

  const submitUserDetails = () => {
    if (userStatusSelect.status == "SubjectiveTestPassed") {
      subjectiveTestPassHttps({
        userId: uid,
        coursename: data[0].courseName,
        params: {
          courseId: data[0].courseId,
          attempt: data[0].attempt,
          img: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.vecteezy.com%2Fvector-art%2F6086198-notification-icon-for-web&psig=AOvVaw0Y-_kHylgjWh7t3uGUm7Rf&ust=1674367924256000&source=images&cd=vfe&ved=0CBAQjRxqFwoTCIDWs-aA2PwCFQAAAAAdAAAAABAE",
          subjectiveTestStatus: "passed",
        },
      });

      UpdateUserStatus({
        attempts: data[0].attempt,
        subjectiveTestSms: userStatusSelect.comment,
        uid: uid,
        courseId: data[0].courseId,

        userStatus: userStatusSelect.status
          ? userStatusSelect.status
          : data[0].userStatus,
        subjectiveTestStatus: "passed",
        subjectiveTestStatusDate: new Date(),
      });
    }

    if (userStatusSelect.status == "SubjectiveTestFailed") {
      RejectedFromCourseHttps({
        userId: uid,
        coursename: data[0].courseName,
        params: {
          courseId: data[0].courseId,
          attempt: data[0].attempt,
          img: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.vecteezy.com%2Fvector-art%2F6086198-notification-icon-for-web&psig=AOvVaw0Y-_kHylgjWh7t3uGUm7Rf&ust=1674367924256000&source=images&cd=vfe&ved=0CBAQjRxqFwoTCIDWs-aA2PwCFQAAAAAdAAAAABAE",
          subjectiveTestStatus: "passed",
          subjectiveTestSms: userStatusSelect.comment,
        },
      }).then((res) => {
        console.log("f");
      });

      UpdateUserStatus({
        attempts: data[0].attempt,
        uid: uid,
        courseId: data[0].courseId,

        subjectiveTestSms: userStatusSelect.comment,
        userStatus: userStatusSelect.status
          ? userStatusSelect.status
          : data[0].userStatus,
        subjectiveTestStatus: "failed",
        subjectiveTestStatusDate: new Date(),
      });
    }

    userAppliedCourseUpdate({
      attempts: data[0].attempt,
      uid: uid,
      courseId: data[0].courseId,

      userStatus: userStatusSelect.status
        ? userStatusSelect.status
        : data[0].userStatus,
      result: userStatusSelect.result,
      marksOfSubjectiveTest: userStatusSelect.marks,
      rejectedMessageForUser: userStatusSelect.comment,
    }).then((res) => {
      alert("updated");
    });
  };

  const updateFunction = (i, ckeck, item) => {
    console.log(ckeck);
    let newObj = { ...item };
    newObj.checked = ckeck;
    objData[i] = newObj;
    let arr = objData.map((e, id) => {
      if (i == id) {
        return newObj;
      } else {
        return e;
      }
    });
    setObjData(arr);
  };

  const updateIndataBase = () => {
    userSubjectiveTestStatusChange({
      attempts: data[0].attempt,

      subjectiveAns: objData,
      groupEnrollmentId: data[0].groupEnrollmentId,
      uid: uid,
      courseId: data[0].courseId,
    }).then((res) => {
      alert("updated");
    });
  };

  return (
    <div
      className="admin-overdue-bottom"
      style={{ padding: "20px", fontFamily: "Poppins" }}
    >
      <div
        style={{
          fontFamily: "Poppins",
          fontSize: "20px",
          fontWeight: 500,
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        <div> {t("Subjective Test View")} </div>
        <div
          style={{
            fontFamily: "Poppins",
            fontSize: "10px",
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          {" "}
          <div>
            {t("No Of Video Need To Uploaded")} :
            {stateForVideoCount?.[0]?.noOfRecordedVideos}{" "}
          </div>
          <div>
            {t("No Of Video Uploaded")}:{" "}
            {stateForVideoCount?.[0]?.noOfUploadedVideos}{" "}
          </div>
        </div>
      </div>
      <div className="admin-overdue-bottom-table">
        <div style={{ display: "flex", gap: "20", alignItems: "center" }}>
          {objData?.map((item, id) => {
            return (
              <div>
                <div
                  style={{
                    background: id == currentPage ? "blue" : "rgb(5,30,52)",
                    width: "50px",
                    fontSize: "20px",
                    height: "25px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={item.checked}
                    onChange={(e) => {
                      updateFunction(id, e.target.checked, item);
                    }}
                  />
                </div>
                <div
                  style={{
                    background: id == currentPage ? "blue" : "rgb(5,30,52)",
                    width: "50px",
                    fontSize: "20px",
                    height: "25px",
                  }}
                >
                  <button
                    style={{
                      background: id == currentPage ? "blue" : "rgb(5,30,52)",
                      width: "50px",
                      fontSize: "20px",
                      height: "25px",
                      color: "white",
                    }}
                  >
                    {id + 1}
                  </button>{" "}
                </div>
              </div>
            );
          })}
        </div>

        <div>
          <button
            style={{ marginTop: "20px" }}
            className="primary-btn"
            onClick={() => {
              updateIndataBase();
            }}
          >
            {" "}
            update your check status
          </button>
        </div>
        {data.length > 0 &&
          data[0]?.subjectiveAns?.map((item, id) => {
            if (id < 1 * currentPage) return;
            if (id >= 1 * (currentPage + 1)) return;
            return (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gridGap: "40px",
                }}
              >
                {item.questionType == "Video question" && (
                  <div>
                    <h1> Video Question</h1>
                    <video width="300" height="240" controls>
                      <source src={item.questionData} type="video/mp4" />
                      <source src={item.questionData} type="video/ogg" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )}

                {item.questionType == "File Question" && (
                  <div style={{ marginTop: "5vh" }}>
                    <h1> File Question</h1>
                    <iframe src={item.questionData} title="pdf"></iframe>

                    <button
                      onClick={() => {
                        windoopen("file", item.questionData);
                      }}
                    >
                      view In a new Tab
                    </button>
                  </div>
                )}

                {item.questionType == "Text Question" && (
                  <div>
                    <h1> Text Question</h1>
                    <p style={{ margin: "10%" }}> {item.questionData}</p>
                  </div>
                )}
                <div>
                  {item.ansType == "1" && (
                    <div>
                      <h1> Video Answer</h1>
                      <video width="300" height="240" controls>
                        <source src={item.ansData} type="video/mp4" />
                        <source src={item.ansData} type="video/ogg" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  )}

                  {item.ansType == "2" && (
                    <div style={{ marginTop: "6vh" }}>
                      <h1> File Answer</h1>
                      <iframe src={item.ansData} title="pdf"></iframe>

                      <button
                        onClick={() => {
                          windoopen("file", item.ansData);
                        }}
                      >
                        view In a new Tab
                      </button>
                    </div>
                  )}

                  {item.ansType == "3" && (
                    <div>
                      <h1> Text Answer</h1>
                      <p style={{ margin: "10%" }}> {item.ansData}</p>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        {loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        {!training && !loading && (
          <div style={{ width: "100%", textAlign: "center", color: "#1e1e1e" }}>
            No Records found
          </div>
        )}
      </div>
      <div className="admin-overdue-bottom-pagination">
        <div className="admin-overdue-bottom-pagination-cnt">
          <div className="admin-overdue-bottom-pagination-cnt-item">
            <svg
              onClick={() => clickhandler(currentPage - 1)}
              width="auto"
              height="22"
              viewBox="0 0 14 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                fill="#717579"
              />
            </svg>
          </div>
          <div className="admin-overdue-bottom-pagination-cnt-item-btn">
            {currentPage + 1} of{" "}
            {data.length > 0 && data[0]?.subjectiveAns?.length
              ? Math.ceil(data.length > 0 && data[0]?.subjectiveAns?.length / 1)
              : 0}
          </div>
          <div className="admin-overdue-bottom-pagination-cnt-item">
            <svg
              onClick={() => clickhandler(currentPage + 1)}
              width="auto"
              height="20"
              viewBox="0 0 13 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                fill="#717579"
              />
            </svg>
          </div>
        </div>
      </div>

      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginTop: "15px",
          }}
        >
          <label> Overall marks : </label>
          <input
            style={{
              border: "2px solid gray",
              outline: "none",
              padding: "15px",
              borderRadius: "5px",
            }}
            type="Number"
            value={userStatusSelect.marks}
            onChange={(e) => {
              setUserStatusSelect({
                ...userStatusSelect,
                marks: e.target.value,
              });
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginTop: "15px",
          }}
        >
          <label> Status : </label>
          <select
            className="admin-course-main-top-select"
            value={userStatusSelect.status}
            onChange={(e) => {
              setUserStatusSelect({
                ...userStatusSelect,
                status: e.target.value,
              });
            }}
          >
            <option value="">select option </option>

            <option value="SubjectiveTestPassed">
              {" "}
              Subjective Test Passed
            </option>

            <option value="SubjectiveTestFailed">Subjective Test Failed</option>
          </select>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginTop: "15px",
          }}
        >
          <label> Result: </label>
          <select
            className="admin-course-main-top-select"
            value={userStatusSelect.result}
            onChange={(e) => {
              setUserStatusSelect({
                ...userStatusSelect,
                result: e.target.value,
              });
            }}
          >
            {" "}
            <option value="">select option </option>
            <option value="pass">Pass </option>
            <option value="fail">Fail </option>
          </select>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginTop: "15px",
          }}
        >
          <label> Comments: </label>
          <textarea
            value={userStatusSelect.comment}
            type="text"
            style={{
              border: "1px solid gray",
              borderRadius: "5px",
              marginTop: "15px",
              minHeight: "100px",
              minWidth: "300px",
              padding: "10px",
            }}
            onChange={(e) => {
              setUserStatusSelect({
                ...userStatusSelect,
                comment: e.target.value,
              });
            }}
          />
        </div>

        <div>
          <button
            onClick={() => {
              submitUserDetails();
            }}
            className="primary-btn"
            style={{ marginTop: "20px" }}
          >
            {" "}
            Update User details
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExternalTraining;
