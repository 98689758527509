import { Timestamp } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { functions } from "../../Firebase";
import Navbar from "../Component/NavbarTop";
import Sidebar from "../Component/Sidebar";
import "./StyleAdminComponents/GroupList.css";
import Notify from "./Dashboard/Notify";
import "./Dashboard/OverdueEnrollments.css";
import GenerateReport from "./Dashboard/popUpForReports";
import { create } from "sortablejs";
import LoadingScreen from "./BasicCourse/lodingScree";
import EnterBodyAndTittel from "./courseOverView/bodyAndti";
import { Category } from "@mui/icons-material";
function GenerateEntranceReportPage() {
  const manualNotification = httpsCallable(functions, "manualNotification");
  const uploadReports = httpsCallable(functions, "uploadReports");
  const getWhyDoYouWantToJoin = httpsCallable(
    functions,
    "getWhyDoYouWantToJoin"
  );

  const getbasicCourseDataForreports = httpsCallable(
    functions,
    "getbasicCourseDataForreports"
  );

  const fetchEntranceReportData = httpsCallable(
    functions,
    "fetchEntranceReportData"
  );

  const [openLessonPopUp, setopenLessonPopUp] = useState(false);
  const getReports = httpsCallable(functions, "getReports");
  const getAllCourse = httpsCallable(functions, "getAllCourse");
  const { clrs } = useSelector((state) => state.createClr);
  const [arr, setArr] = useState();
  const [pageSize, setPageSize] = useState(5);
  const [pageNo, setPageNo] = useState();
  const [showLoading, setLoading] = useState(true);
  const [reportsArr, setReportsArr] = useState([]);
  const [curPage, setCurPage] = useState();
  const [displayNotify, setDisplayNotify] = useState(false);
  const [selectToDate, setSelectToDate] = useState("");
  const [selectFromDate, setSelectFromDate] = useState("");
  const [courseId, setCourseId] = useState("");

  const navigate = useNavigate();
  const [dataFromApi, setDataFromApi] = useState([]);
  const [courseArr, serCourseArr] = useState([]);
  const [arrLength, setArrLength] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [Allselect, setAllselect] = useState(false);

  const [categoryArr, setCategoryArr] = useState({});
  const [loadingScreen, setLoadingScreen] = useState(false);
  useEffect(() => {
    //setLoadingScreen(true);
    // getbasicCourseDataForreports()
    //   .then((res) => {

    //     setArr(res.data);
    //     setDataFromApi(res.data);
    //     setArrLength(res.data.length);
    //     setLoadingScreen(false);
    //   })
    //   .catch((e) => {
    //     setLoadingScreen(false);
    //   });

    // setLoading(false);

    getWhyDoYouWantToJoin({
      type: "category",
    }).then((res) => {
      let obj = {};
      res.data.map((e) => {
        obj[e.associatedCategory] = e.categoryName;
      });

      setCategoryArr(obj);
    });

    getAllCourse()
      .then((res) => {
        //console.log(res.data);
        serCourseArr(res.data.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, []);

  const filterButtenFunction = (type) => {
    if (type == "filter") {
      let tempArr = [0, 0, 0];

      // console.log(tempArr);
      setArrLength(tempArr.length);
      setCurrentPage(0);
      setArr(tempArr);
    } else if (type == "clear") {
      setArrLength(dataFromApi.length);
      setArr(dataFromApi);

      setCurrentPage(0);
    }
  };

  const handelCheckBox = (id, check, type) => {
    //console.log(id, check);

    let tempData = [];
    if (type == "all") {
      setAllselect(check);
      let data = arr.filter((e) => {
        let obj = { ...e, select: check };
        // console.log(obj);
        tempData.push(obj);
        return obj;
      });
    } else {
      let data = arr.filter((e) => {
        // console.log(e.id, id);
        if (e.uid == id) {
          let obj = { ...e, select: check };
          // console.log(obj);
          tempData.push(obj);
          return obj;
        } else {
          tempData.push(e);
          return e;
        }
      });
    }
    // console.log(tempData);
    setArr(tempData);
  };

  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(arrLength / pageSize)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };
  const NotifyClick = () => {
    setDisplayNotify(!displayNotify);
  };

  const haldelCourseFilter = (e) => {
    setLoadingScreen(true);
    fetchEntranceReportData({
      courseId: courseId,
      startDate: selectFromDate,
      endDate: selectToDate,
    })
      .then((res) => {
        // console.log(res.data)
        setLoadingScreen(false);
        setArr(res.data);
        setArrLength(res.data.length);

        setCurrentPage(0);
      })
      .catch((er) => {
        setLoadingScreen(false);
      });
  };

  const moveTogeneratePage = () => {
    setLoadingScreen(true);
    let fil = [];

    for (let i = 0; i < arr.length; i++) {
      let completed = 0;

      if (arr[i].select == true) {
        let obj = {};
        obj["name"] = arr[i]?.name;
        obj["id"] = arr[i].uid;

        obj["email"] = arr[i]?.email;
        obj["phoneNumber"] = arr[i]?.phoneNumber;
        obj["RegistrationDate"] = arr[i]?.accountCreationDate;
        obj["UserCategory"] = categoryArr[arr[i]?.courseCategory];

        fil.push(obj);
      }
    }
    let time = new Date().getTime();
    uploadReports({
      id: "testReport" + time,
      data: fil,
      reportType: "Entrance Course",
    })
      .then((res) => {
        setLoadingScreen(false);
        // console.log(res.data);
        alert("genreted");
      })
      .catch((e) => {
        setLoadingScreen(false);
      });
    // console.log(fil);
  };

  const delfunction = (name) => {
    // console.log(name);
    setopenLessonPopUp(false);
    let userList = arr.filter((e) => e.select == true);

    manualNotification({
      body: name.body,
      trigger: name.title,
      userList: userList,

      params: {
        courseId: arr[0].courseId ? arr[0].courseId : "",
      },
    });
  };

  const sendNotification = () => {
    let userList = arr.filter((e) => e.select == true);
    // console.log(userList);
    if (userList.length > 0) {
      setopenLessonPopUp(true);
    } else {
      alert("select user");
      return;
    }
  };

  return (
    <div className="section" style={{ backgroundColor: "#e5e5e5" }}>
      <Navbar page={t("Reports")} />
      <LoadingScreen
        open={loadingScreen}
        onClose={() => setLoadingScreen(false)}
      />

      <EnterBodyAndTittel
        open={openLessonPopUp}
        onClose={() => setopenLessonPopUp(false)}
        delfun={(topic) => {
          delfunction(topic);
        }}
      />
      <div
        className="createcourse-main"
        style={{ gridTemplateColumns: "19vw 1fr" }}
      >
        <Sidebar page={"reports"} hidepage={false} />
        <div className="admin-overdue">
          <div className="admin-overdue-top">
            <div className="admin-overdue-top-item"></div>
            <div
              className="admin-dashboard-main-mid-item-last-filter"
              style={{
                marginLeft: "39.5vw",
                marginBottom: "1vw",
                gap: "20px",
                display: "flex",
              }}
            >
              <div className="admin-dashboard-main-top-last-filter">
                <button
                  className="primary-btn"
                  onClick={() => {
                    moveTogeneratePage();
                  }}
                >
                  Generate Reports
                </button>
              </div>

              <div className="admin-dashboard-main-top-last-filter">
                <button
                  className="primary-btn"
                  onClick={() => {
                    sendNotification();
                  }}
                >
                  Send Notification
                </button>
              </div>
            </div>
          </div>
          <div className="admin-overdue-mid">
            <div className="admin-overdue-mid-left">
              <div className="admin-overdue-mid-left-txt">
                {" "}
                {t("Entrance Course")}
              </div>
              <div className="admin-overdue-mid-left-input">
                <select
                  onChange={(e) => {
                    setCourseId(e.target.value);
                  }}
                >
                  {courseArr?.map((e) => (
                    <option value={e?.courseId}> {e?.courseId}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="admin-overdue-mid-left">
              <div className="admin-overdue-mid-left-txt"> {t("FromDate")}</div>
              <div className="admin-overdue-mid-left-input">
                <input
                  type="date"
                  value={selectFromDate}
                  onChange={(e) => {
                    setSelectFromDate(e.target.value);
                  }}
                  placeholder="Select Date"
                />
              </div>
            </div>
            <div className="admin-overdue-mid-left">
              <div className="admin-overdue-mid-left-txt">{t("ToDate")}</div>
              <div className="admin-overdue-mid-left-input">
                <input
                  type="date"
                  value={selectToDate}
                  onChange={(e) => {
                    setSelectToDate(e.target.value);
                  }}
                  placeholder="Select Date"
                />
              </div>
            </div>

            <div
              className="admin-dashboard-main-mid-item-last-filter"
              style={{ marginTop: "2.5vw" }}
            >
              {" "}
              <div className="admin-dashboard-main-top-last-filter">
                <button
                  className="primary-btn"
                  onClick={() => {
                    haldelCourseFilter();
                    //filterButtenFunction("clear");
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
            <div
              className="admin-dashboard-main-mid-item-last-filter"
              style={{ marginTop: "2.5vw" }}
            >
              {" "}
              <div className="admin-dashboard-main-top-last-filter">
                <button
                  className="primary-btn"
                  onClick={() => {
                    filterButtenFunction("clear");
                  }}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
          <div className="admin-overdue-bottom">
            <div className="admin-overdue-bottom-table">
              <div
                className="admin-overdue-bottom-table-cnt"
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                }}
              >
                <div className="admin-overdue-bottom-table-head">
                  <input
                    type="checkBox"
                    checked={Allselect}
                    style={{ marginRight: "2vw" }}
                    onChange={(e) => {
                      handelCheckBox("c", e.target.checked, "all");
                    }}
                  />
                  Sr.No.
                </div>
                <div className="admin-overdue-bottom-table-head">Name</div>
                <div className="admin-overdue-bottom-table-head">Email ID</div>
                <div className="admin-overdue-bottom-table-head">
                  Mobile number
                </div>
                <div className="admin-overdue-bottom-table-head">
                  Registration Date
                </div>
                <div className="admin-overdue-bottom-table-head">
                  User Category
                </div>
              </div>
              {showLoading && (
                <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td">
                    <div className="spinner-container">
                      <div className="loading-spinner"></div>
                    </div>
                  </div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div
                    className="admin-overdue-bottom-table-td"
                    style={{ color: "#004577" }}
                  ></div>
                </div>
              )}
              {arr &&
                arr?.map((item, id) => {
                  if (id < pageSize * currentPage) return;
                  if (id >= pageSize * (currentPage + 1)) return;

                  let creationDate = Timestamp.fromMillis(
                    item?.accountCreationDate._seconds * 1000
                  )
                    .toDate()
                    .toDateString();

                  return (
                    <div
                      className="admin-overdue-bottom-table-cnt"
                      key={id}
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                      }}
                    >
                      <div className="admin-overdue-bottom-table-td">
                        <input
                          type="checkBox"
                          checked={item.select}
                          style={{ marginRight: "2vw" }}
                          onChange={(e) => {
                            handelCheckBox(
                              item.uid,
                              e.target.checked,
                              "single"
                            );
                          }}
                        />
                        {id + 1}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item?.name}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item?.email}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item?.phoneNumber}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {creationDate}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {categoryArr[item.courseCategory]}
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="admin-overdue-bottom-pagination">
              <div className="admin-overdue-bottom-pagination-cnt">
                <div className="admin-overdue-bottom-pagination-cnt-item">
                  <svg
                    onClick={() => clickhandler(currentPage - 1)}
                    width="auto"
                    height="22"
                    viewBox="0 0 14 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div className="admin-overdue-bottom-pagination-cnt-item-btn">
                  {currentPage + 1} of {Math.ceil(arrLength / pageSize)}
                </div>
                <div
                  style={{ marginRight: "19vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <svg
                    onClick={() => clickhandler(currentPage + 1)}
                    width="auto"
                    height="20"
                    viewBox="0 0 13 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div
                  style={{ display: "flex", marginRight: "-30vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <label className="admin-row">{t("Show")}</label>
                  <select
                    style={{
                      width: "4vw",
                      margin: "0.5vw",
                      marginBottom: "1.5vw",
                      height: "1.5vw",
                    }}
                    value={pageSize}
                    onChange={(e) => setPageSize(e.target.value)}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                  </select>
                  <label className="admin-row"> {t("PagePerItem")}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ height: "100%", width: "19vw", backgroundColor: "white" }}
        ></div>
        <div className="courselist-main-cnt-back">
          <div className="courselist-main-cnt-back-btn">
            <Link to={"/admin"}>
              <button className="secondary-btn">Back</button>{" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenerateEntranceReportPage;
