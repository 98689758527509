import React from 'react'
import { DateRangePicker } from 'rsuite';
//import 'rsuite/dist/styles/rsuite-default.css';
//import 'rsuite/styles/less/index.less';
function ONe() {
  return (
    <div >
        <h4>React Suite DateRangePicker Component</h4>
        <DateRangePicker
        />
      </div>
  )
}

export default ONe