import { getDocs, Timestamp } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getCookie } from "../../../Cookies";
import { db, functions } from "../../../Firebase";
import Navbar from "../../Component/NavbarTop";
import Sidebar from "../../Component/Sidebar";
import "../GroupEnrollments.css";
import "../StyleAdminComponents/GroupList.css";

function GroupEnrollment() {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const getGroupEnrollments = httpsCallable(functions, "getGroupEnrollments");
  const deleteGroupEnrollment = httpsCallable(
    functions,
    "deleteGroupEnrollment"
  );

  const [enrollment, setEnrollments] = useState(null);
  const { clrs } = useSelector((state) => state.createClr);
  const [pageSize, setPageSize] = useState(5);
  const [showLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [arrLength, setArrLength] = useState(0);
  const [action, setAction] = useState("action");
  const [details, setDetails] = useState([]);
  const [extraArrForGroupUser, setExtraArrForGroupUser] = useState([]);
  const [archiveDataArr, setArchivedDataArr] = useState([]);
  const [buttonTogalForChangeState, setButtonTogalForarchived] = useState(true);
  const [handelUseEffectTogal, setHandelUseEffectTogal] = useState(true);
  const [tempLoading, setTemploading] = useState("test");
  const initState = {
    courseName: "",
    groupName: "",
    courseType: "demo",
  };
  const [query, setQuery] = useState(initState);
  console.log("enrollment", enrollment);
  const handleChange = (e) => {
    setQuery({
      ...query,
      [e.target.name]: e.target.value,
    });
  };
  // console.log(details);
  const setDefault = () => {
    setQuery(initState);
    setDetails(enrollment);
    setArrLength(enrollment.length);
    return;
  };
  const handleSearch = (name) => {
    const value = query[name];
    if (value == "") {
      setDetails(enrollment);
      setArrLength(enrollment.length);
      return;
    }
    const temp = enrollment.filter((item) =>
      item[name]?.toLowerCase().includes(value.toLowerCase())
    );
    console.log(temp);
    setDetails(temp);
    setArrLength(temp.length);
    console.log(temp);
    setCurrentPage(0);
  };
  const handleStatusChange = (e, name) => {
    setQuery({
      ...query,
      [name]: e.target.value,
    });
    // console.log(e.target.name);
    console.log(e.target.value);
    if (e.target.value == "demo") {
      setDetails(enrollment);
      setArrLength(enrollment.length);
      return;
    }
    const temp = enrollment.filter((item) => item[name] == e.target.value);
    console.log(temp);
    setDetails(temp);
    setArrLength(temp.length);
    console.log(temp);
    setCurrentPage(0);
  };
  useEffect(() => {
    if (
      getCookie("UID") == null ||
      (getCookie("ROLE") != "admin" && getCookie("ROLE") != "instructor")
    ) {
      goToHome();
      return <></>;
    }

    getGroupEnrollments().then((result) => {
      if (result.data?.error) {
        console.log(result.data?.error);
        setEnrollments([]);
        setDetails([]);
        setArrLength(0);
        setLoading(false);
        return;
      }
      console.log(result.data);
      setEnrollments(
        result.data
          .map((item) => ({ ...item, selected: false }))
          .filter((item) => item.archived != true)
      );
      setDetails(
        result.data
          .map((item) => ({ ...item, selected: false }))
          .filter((item) => item.archived != true)
      );
      setArchivedDataArr(
        result.data
          .map((item) => ({ ...item, selected: false }))
          .filter((item) => item.archived == true)
      );
      setExtraArrForGroupUser(
        result.data
          .map((item) => ({ ...item, selected: false }))
          .filter((item) => item.archived != true)
      );
      setButtonTogalForarchived(true);

      setArrLength(result.data.length);
      setLoading(false);
    });
  }, [handelUseEffectTogal]);

  const handleAction = (event, details, id) => {
    let data = event.target.value;
    if (data == "delete") {
      deleteGroupEnrollment({ enrollmentId: details.enrollmentId }).then(
        (res) => {
          setHandelUseEffectTogal(!handelUseEffectTogal);
        }
      );
    }

    if (data == "view") {
      navigate("/admin/groupEnrollment/view", { state: { ...details } });
    }
    if (data == "edit") {
      navigate("/admin/groupEnrollmentEdits/", { state: { ...details } });
    }
    if (data == "milestone") {
      navigate("/admin/groupEnrollmentMileStone/", { state: { ...details } });
    }
  };

  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(arrLength / 5)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };

  const buttonForArchivedFun = (type) => {
    if (type == "user") {
      setDetails(extraArrForGroupUser);
      setEnrollments(extraArrForGroupUser);
      setCurrentPage(0);
      setArrLength(extraArrForGroupUser.length);
    } else if (type == "archive") {
      setDetails(archiveDataArr);
      setEnrollments(archiveDataArr);
      setCurrentPage(0);
      setArrLength(archiveDataArr.length);
    }
    setButtonTogalForarchived(!buttonTogalForChangeState);
  };

  return (
    <div className="admin-groupEnrol">
      <Navbar page={t("Group Enrollments")} />
      <div className="admin-groupEnrol-main">
        <Sidebar page={"enrollments"} hidepage={false} />
        <div className="admin-overdue">
          <div className="admin-dashboard-main-mid">
            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("Group Name")}
              </div>
              <div className="filter-section">
                <input
                  type="text"
                  value={query.groupName}
                  onChange={handleChange}
                  name="groupName"
                  placeholder={t("Search By Group Name")}
                />

                <button
                  className="primary-btn"
                  onClick={() => handleSearch("groupName")}
                >
                  {t("Search")}
                </button>
                <div onClick={setDefault} style={{ cursor: "pointer" }}>
                  {t("Clear Filters")}
                </div>
              </div>
            </div>
          </div>

          <div style={{ height: "10vw" }} className="courselist-main-cnt-top">
            {/*     <div className="courselist-main-cnt-top-txt">Group List</div> */}
            <div
              style={{
                marginTop: "7vw",
                marginLeft: "0vw",
                width: "23vw",
              }}
              className="head"
            >
              {t("ListOfGroupEnrollment")}
            </div>
            <div>
              <Link to={"/admin/groupEnrollmentEdits/"}>
                <button className="primary-btn">
                  {t("AddNewGroupEnrollment")}
                </button>
              </Link>
            </div>
          </div>

          <div
            style={{ margin: "0vw 2vw 2vw 2vw" }}
            className="admin-overdue-bottom"
          >
            <div className="admin-overdue-bottom-table">
              <div
                className="admin-overdue-bottom-table-cnt-grpeden"
                style={{ gridTemplateColumns: "repeat(7, 1fr)" }}
              >
                <div className="admin-overdue-bottom-table-head">
                  {t("SRNo")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("CourseID")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("CourseTitle")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("GroupName")}
                </div>
                {/* <div className="admin-overdue-bottom-table-head">{t("TypeOftraining")}</div> */}
                <div className="admin-overdue-bottom-table-head">
                  {t("EnrollmentDate")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("EndDate")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Action")}
                </div>
              </div>
              {showLoading && (
                <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td">
                    <div className="spinner-container">
                      <div className="loading-spinner"></div>
                    </div>
                  </div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div
                    className="admin-overdue-bottom-table-td"
                    style={{ color: "#004577" }}
                  ></div>
                </div>
              )}
              {details?.map((item, id) => {
                if (id < pageSize * currentPage) return;
                if (id >= pageSize * (currentPage + 1)) return;
                let dueDate = Timestamp.fromMillis(
                  item.applicationStartDate?._seconds * 1000
                )
                  .toDate()
                  .toDateString();
                let createdOn = Timestamp.fromMillis(
                  item.applicationEndDate?._seconds * 1000
                )
                  .toDate()
                  .toDateString();
                return (
                  <div
                    style={{ gridTemplateColumns: "repeat(7, 1fr)" }}
                    className="admin-overdue-bottom-table-cnt-grpeden"
                    key={id}
                  >
                    <div className="admin-overdue-bottom-table-td">
                      {id + 1}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item.courseId}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item.courseName}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {" "}
                      {item.groupName}
                    </div>
                    {/* <div className="admin-overdue-bottom-table-td">
                      {" "}
                      {item.courseType === "course" ? "InternalTraning" : item.courseType}
                    </div> */}
                    <div className="admin-overdue-bottom-table-td">
                      {dueDate}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {createdOn}
                    </div>
                    <div
                      className="admin-overdue-bottom-table-td"
                      style={{ color: "#004577" }}
                    >
                      <div>
                        {item.archived ? (
                          tempLoading == id ? (
                            <p>Loading...</p>
                          ) : (
                            <select
                              style={{
                                border: "none",
                                backgroundColor: "#F2F2F2",
                                height: "4vh",
                                width: "7vw",
                                padding: "0 1vh",
                                fontWeight: "700",
                                color: "#717579",
                                borderRadius: "6px",
                              }}
                              onChange={(e) => handleAction(e, item, id)}
                            >
                              <option value={"action"}>{t("Action")}</option>
                            </select>
                          )
                        ) : tempLoading == id ? (
                          <p>Loading...</p>
                        ) : (
                          <select
                            style={{
                              border: "none",
                              backgroundColor: "#F2F2F2",
                              height: "4vh",
                              width: "7vw",
                              padding: "0 1vh",
                              fontWeight: "700",
                              color: "#717579",
                              borderRadius: "6px",
                            }}
                            onChange={(e) => handleAction(e, item, id)}
                          >
                            <option value={"action"}>{t("Action")}</option>
                            <option value={"edit"}>{t("Edit")}</option>
                            <option value={"view"}>{t("View")}</option>
                            {/* <option value={"delete"}>{t("delete")}</option> */}
                            <option value={"milestone"}>
                              {t("milestone")}
                            </option>
                          </select>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="admin-overdue-bottom-pagination">
              <div className="admin-overdue-bottom-pagination-cnt">
                <div className="admin-overdue-bottom-pagination-cnt-item">
                  <svg
                    onClick={() => clickhandler(currentPage - 1)}
                    width="auto"
                    height="22"
                    viewBox="0 0 14 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div className="admin-overdue-bottom-pagination-cnt-item-btn">
                  {currentPage + 1} of {Math.ceil(arrLength / pageSize)}
                </div>
                <div
                  style={{ marginRight: "19vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <svg
                    onClick={() => clickhandler(currentPage + 1)}
                    width="auto"
                    height="20"
                    viewBox="0 0 13 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div
                  style={{ display: "flex", marginRight: "-23vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <label className="admin-row">{t("Show")}</label>
                  <select
                    style={{
                      width: "4vw",
                      margin: "0.5vw",
                      marginBottom: "1.5vw",

                      height: "1.5vw",
                    }}
                    className="rowInput"
                    value={pageSize}
                    onChange={(e) => setPageSize(e.target.value)}
                    type="number"
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                  </select>
                  <label className="admin-row"> {t("PagePerItem")}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupEnrollment;

{
  /* import { getDocs, Timestamp } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getCookie } from "../../../Cookies";
import { db, functions } from "../../../Firebase";
import Navbar from "../../Component/NavbarTop";
import Sidebar from "../../Component/Sidebar";
import "../GroupEnrollments.css";
import "../StyleAdminComponents/GroupList.css";

function GroupEnrollment() {
  
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const getGroupEnrollments = httpsCallable(functions, "getGroupEnrollments");
const deleteGroupEnrollment=httpsCallable(functions,"deleteGroupEnrollment")

  const [enrollment, setEnrollments] = useState(null);
  const { clrs } = useSelector((state) => state.createClr);
  const [pageSize, setPageSize] = useState(5);
  const [showLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [arrLength, setArrLength] = useState(0);
  const [action, setAction] = useState("action");
  const [details, setDetails] = useState([]);
  const [extraArrForGroupUser, setExtraArrForGroupUser] = useState([]);
  const [archiveDataArr, setArchivedDataArr] = useState([]);
  const [buttonTogalForChangeState, setButtonTogalForarchived] = useState(true);
  const [handelUseEffectTogal, setHandelUseEffectTogal] = useState(true);
  const [tempLoading, setTemploading] = useState("test");
  const initState = {
    courseName: "",
    groupName: "",
    courseType: "demo",
  };
  const [query, setQuery] = useState(initState);
  console.log("enrollment", enrollment);
  const handleChange = (e) => {
    setQuery({
      ...query,
      [e.target.name]: e.target.value,
    });
  };
  // console.log(details);
  const setDefault = () => {
    setQuery(initState);
    setDetails(enrollment);
    setArrLength(enrollment.length);
    return;
  };
  const handleSearch = (name) => {
    const value = query[name];
    if (value == "") {
      setDetails(enrollment);
      setArrLength(enrollment.length);
      return;
    }
    const temp = enrollment.filter((item) =>
      item[name]?.toLowerCase().includes(value.toLowerCase())
    );
    console.log(temp);
    setDetails(temp);
    setArrLength(temp.length);
    console.log(temp);
    setCurrentPage(0);
  };
  const handleStatusChange = (e, name) => {
    setQuery({
      ...query,
      [name]: e.target.value,
    });
    // console.log(e.target.name);
    console.log(e.target.value);
    if (e.target.value == "demo") {
      setDetails(enrollment);
      setArrLength(enrollment.length);
      return;
    }
    const temp = enrollment.filter((item) => item[name] == e.target.value);
    console.log(temp);
    setDetails(temp);
    setArrLength(temp.length);
    console.log(temp);
    setCurrentPage(0);
  };
  useEffect(() => {
    if (
      getCookie("UID") == null ||
      (getCookie("ROLE") != "admin" && getCookie("ROLE") != "instructor")
    ) {
      goToHome();
      return <></>;
    }

    getGroupEnrollments().then((result) => {
      if (result.data?.error) {
        console.log(result.data?.error);
        setEnrollments([]);
        setDetails([]);
        setArrLength(0);
        setLoading(false);
        return;
      }
      console.log(result.data)
      setEnrollments(
        result.data
          .map((item) => ({ ...item, selected: false }))
          .filter((item) => item.archived != true)
      );
      setDetails(
        result.data
          .map((item) => ({ ...item, selected: false }))
          .filter((item) => item.archived != true)
      );
      setArchivedDataArr(
        result.data
          .map((item) => ({ ...item, selected: false }))
          .filter((item) => item.archived == true)
      );
      setExtraArrForGroupUser(
        result.data
          .map((item) => ({ ...item, selected: false }))
          .filter((item) => item.archived != true)
      );
      setButtonTogalForarchived(true);

      setArrLength(result.data.length);
      setLoading(false);
    });
  }, [handelUseEffectTogal]);

  const handleAction = (event, details, id) => {
    let data = event.target.value;
if(data=="delete"){

  deleteGroupEnrollment({enrollmentId:details.enrollmentId}).then((res)=>{
    
    setHandelUseEffectTogal(!handelUseEffectTogal)
  })
}
    
    if(data=="view"){
      navigate("/admin/groupEnrollment/view",{ state: { ...details } })
    }
    if (data == "edit") {
      navigate("/admin/groupEnrollmentEdits/", { state: { ...details } });
    }
    if(data=="milestone"){
      navigate("/admin/groupEnrollmentMileStone/", { state: { ...details } });
    }
  };

  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(arrLength / 5)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };

  const buttonForArchivedFun = (type) => {
    if (type == "user") {
      setDetails(extraArrForGroupUser);
      setEnrollments(extraArrForGroupUser);
      setCurrentPage(0);
      setArrLength(extraArrForGroupUser.length);
    } else if (type == "archive") {
      setDetails(archiveDataArr);
      setEnrollments(archiveDataArr);
      setCurrentPage(0);
      setArrLength(archiveDataArr.length);
    }
    setButtonTogalForarchived(!buttonTogalForChangeState);
  };

  return (
    <div className="admin-groupEnrol">
      <Navbar page={t("Group Enrollments")} />
      <div className="admin-groupEnrol-main">
        <Sidebar page={"enrollments"} hidepage={false} />
        <div className="admin-overdue">
          <div
            className="admin-dashboard-main-mid"
            style={{
              gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
              display: "grid",
            }}
          >
           
            <div style={{ width: "18vw" }} className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">{t("Group Name")}</div>
              <div style={{ position: "relative" }}>
                <input
                  style={{ width: "10.5vw" }}
                  type="text"
                  value={query.groupName}
                  onChange={handleChange}
                  name="groupName"
                  placeholder={t("Search By Group Name")}
                />
                <div
                  className="admin-dashboard-main-top-last"
                  style={{
                    position: "absolute",
                    top: "-0.35vw",
                    right: "1vw",
                  }}
                >
                  <button
                    style={{
                      background: `${
                        clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
                      }`,
                      height: "4.1vh",

                      borderRadius: "0px 6px 6px 0px",
                    }}
                    onClick={() => handleSearch("groupName")}
                  >
                    <div style={{ marginRight: "-2.9vw" }}>{t("Search")}</div>
                  </button>
                </div>
              </div>
            </div>
           

         
            <div className="admin-dashboard-main-mid-item-last">
              {" "}
              <div className="admin-dashboard-main-top-last">
                <button
                  style={{
                    background: `${clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"}`,
                    marginTop: "2vw",
                    width: "9vw",
                  }}
                  onClick={setDefault}
                >
                  <div>
                    <svg
                      width="auto"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div>{t("Clear Filters")}</div>
                </button>
              </div>
            </div>
           
          </div>

          <div style={{ height: "10vw" }} className="courselist-main-cnt-top">
            {/*     <div className="courselist-main-cnt-top-txt">Group List</div> /}
            <div
              style={{
                background: `${clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"}`,
                marginTop: "7vw",
                marginLeft: "0vw",
                width: "23vw",
              }}
              className="head"
            >
              {t("ListOfGroupEnrollment")}
            </div>
            <div>
           
             
              <Link to={"/admin/groupEnrollmentEdits/"}>
                <button
                  style={{
                    background: `${clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"}`,
                    width: "21vw",
                    fontWeight: "500",
                    height: "8vh",
                  }}
                >
                  {t("AddNewGroupEnrollment")}
                </button>
              </Link>
            </div>
          </div>
       
          <div style={{ margin: "0vw 2vw 2vw 2vw" }} className="admin-overdue-bottom">
            <div className="admin-overdue-bottom-table">
              <div className="admin-overdue-bottom-table-cnt-grpeden">
                <div className="admin-overdue-bottom-table-head">{t("SRNo")}</div>
                <div className="admin-overdue-bottom-table-head">{t("CourseID")}</div>
                <div className="admin-overdue-bottom-table-head">{t("CourseTitle")}</div>
                <div className="admin-overdue-bottom-table-head">{t("GroupName")}</div>
                {/* <div className="admin-overdue-bottom-table-head">{t("TypeOftraining")}</div> /}
                <div className="admin-overdue-bottom-table-head">{t("EnrollmentDate")}</div>
                <div className="admin-overdue-bottom-table-head">{t("EndDate")}</div>
                <div className="admin-overdue-bottom-table-head">{t("Action")}</div>
              </div>
              {showLoading && (
                <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td">
                    <div className="spinner-container">
                      <div className="loading-spinner"></div>
                    </div>
                  </div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td" style={{ color: "#004577" }}></div>
                </div>
              )}
              {details?.map((item, id) => {
                if (id < pageSize * currentPage) return;
                if (id >= pageSize * (currentPage + 1)) return;
                let dueDate = Timestamp.fromMillis(item.applicationStartDate?._seconds * 1000)
                  .toDate()
                  .toDateString();
                let createdOn = Timestamp.fromMillis(item.applicationEndDate?._seconds * 1000)
                  .toDate()
                  .toDateString();
                return (
                  <div className="admin-overdue-bottom-table-cnt-grpeden" key={id}>
                    <div className="admin-overdue-bottom-table-td">{id + 1}</div>
                    <div className="admin-overdue-bottom-table-td">{item.courseId}</div>
                    <div className="admin-overdue-bottom-table-td">{item.courseName}</div>
                    <div className="admin-overdue-bottom-table-td"> {item.groupName}</div>
                    {/* <div className="admin-overdue-bottom-table-td">
                      {" "}
                      {item.courseType === "course" ? "InternalTraning" : item.courseType}
                    </div> /}
                    <div className="admin-overdue-bottom-table-td">{dueDate}</div>
                    <div className="admin-overdue-bottom-table-td">{createdOn}</div>
                    <div className="admin-overdue-bottom-table-td" style={{ color: "#004577" }}>
                      <div>
                        {item.archived ? (
                          tempLoading == id ? (
                            <p>Loading...</p>
                          ) : (
                            <select
                              style={{
                                border: "none",
                                backgroundColor: "#F2F2F2",
                                height: "4vh",
                                width: "7vw",
                                padding: "0 1vh",
                                fontWeight: "700",
                                color: "#717579",
                                borderRadius: "6px",
                              }}
                              onChange={(e) => handleAction(e, item, id)}
                            >
                              <option value={"action"}>{t("Action")}</option>

                             
                            </select>
                          )
                        ) : tempLoading == id ? (
                          <p>Loading...</p>
                        ) : (
                          <select
                            style={{
                              border: "none",
                              backgroundColor: "#F2F2F2",
                              height: "4vh",
                              width: "7vw",
                              padding: "0 1vh",
                              fontWeight: "700",
                              color: "#717579",
                              borderRadius: "6px",
                            }}
                            onChange={(e) => handleAction(e, item, id)}
                          >
                            <option value={"action"}>{t("Action")}</option>
                            <option value={"edit"}>{t("Edit")}</option>
                            <option value={"view"}>{t("View")}</option>
                            <option value={"delete"}>{t("delete")}</option>
                            <option value={"milestone"}>{t("milestone")}</option>
                            
                          </select>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="admin-overdue-bottom-pagination">
              <div className="admin-overdue-bottom-pagination-cnt">
                <div className="admin-overdue-bottom-pagination-cnt-item">
                  <svg
                    onClick={() => clickhandler(currentPage - 1)}
                    width="auto"
                    height="22"
                    viewBox="0 0 14 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div className="admin-overdue-bottom-pagination-cnt-item-btn">
                  {currentPage + 1} of {Math.ceil(arrLength / pageSize)}
                </div>
                <div
                  style={{ marginRight: "19vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <svg
                    onClick={() => clickhandler(currentPage + 1)}
                    width="auto"
                    height="20"
                    viewBox="0 0 13 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div
                  style={{ display: "flex", marginRight: "-23vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <label className="admin-row">{t("Show")}</label>
                  <select
                    style={{
                      width: "4vw",
                      margin: "0.5vw",
                      marginBottom: "1.5vw",

                      height: "1.5vw",
                    }}
                    className="rowInput"
                    value={pageSize}
                    onChange={(e) => setPageSize(e.target.value)}
                    type="number"
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                  </select>
                  <label className="admin-row"> {t("PagePerItem")}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupEnrollment;
 */
}
