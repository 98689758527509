import { Timestamp } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { functions } from "../../Firebase";
import Navbar from "../Component/NavbarTop";
import Sidebar from "../Component/Sidebar";
import "./StyleAdminComponents/GroupList.css";
import Notify from "./Dashboard/Notify";
import "./Dashboard/OverdueEnrollments.css";
import GenerateReport from "./Dashboard/popUpForReports";
import { create } from "sortablejs";
import LoadingScreen from "./BasicCourse/lodingScree";
import EnterBodyAndTittel from "./courseOverView/bodyAndti";
function GenerateReportPage() {
  const manualNotification = httpsCallable(functions, "manualNotification");
  const uploadReports = httpsCallable(functions, "uploadReports");
  const getbasicCourseDataForreports = httpsCallable(
    functions,
    "getbasicCourseDataForreports"
  );

  const [openLessonPopUp, setopenLessonPopUp] = useState(false);
  const getReports = httpsCallable(functions, "getReports");
  const getAllBasicCourse = httpsCallable(functions, "getAllBasicCourse");
  const { clrs } = useSelector((state) => state.createClr);
  const [arr, setArr] = useState();
  const [pageSize, setPageSize] = useState(5);
  const [pageNo, setPageNo] = useState();
  const [showLoading, setLoading] = useState(true);
  const [reportsArr, setReportsArr] = useState([]);
  const [curPage, setCurPage] = useState();
  const [displayNotify, setDisplayNotify] = useState(false);
  const [selectTo, setSelectTo] = useState("");
  const [selectFrom, setSelectFrom] = useState("");
  const navigate = useNavigate();
  const [dataFromApi, setDataFromApi] = useState([]);
  const [courseArr, serCourseArr] = useState([]);
  const [arrLength, setArrLength] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [Allselect, setAllselect] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);
  useEffect(() => {
    setLoadingScreen(true);
    getbasicCourseDataForreports()
      .then((res) => {
        // "phoneNumber": "+917038414288",
        // "identify": "identifysecond_1668776614761",
        // "name": "ak",
        // "basicCourseEnrolled": true,
        // "uid": "JQi1zS89LjhJwq7te5wGLT1610G3",
        // "level": "exisitnglevelfour_1668773675651",
        // "enrolledBasicCourse": "BasicCourseomkarRahulakashatest_1669966734747",
        // "courseCategory": "OyLG3AdOnJiV4dQkCGQ4",
        // "whydo": "SECONDQUESTIOn_1668670693929",
        // "completed": "4",
        // "lastWatchedLesson": "bbbbb_1669983842377",
        // "totalLesson": "12"
        setArr(res.data);
        setDataFromApi(res.data);
        setArrLength(res.data.length);
        setLoadingScreen(false);
      })
      .catch((e) => {
        setLoadingScreen(false);
      });
    // setArr([{select:false,id:1,name:"rdfsfsdfsfsahul",email:"r@gmail.gdgdgdgcom",phoneNumber:"123456",courseName:"courseOne",complited:"80%"}
    // ,{select:false,id:2,name:"rahfsfddsfdsul",email:"r@gmail.dfgdgdcom",phoneNumber:"123456",courseName:"coursetwo",complited:"70%"},
    // {select:false,id:3,name:"rahusdfdsfsfsfsfdsfsfsdfsdfsfsfsl",email:"r@gmail.dgdfgdcom",phoneNumber:"12345dgdgdffgdf6",courseName:"coursethree",complited:"90%"}])
    //
    setLoading(false);

    // setArr([{select:false,id:1,name:"rdfsfsdfsfsahul",email:"r@gmail.gdgdgdgcom",phoneNumber:"123456",courseName:"courseOne",complited:"80%"}
    // ,{select:false,id:2,name:"rahfsfddsfdsul",email:"r@gmail.dfgdgdcom",phoneNumber:"123456",courseName:"coursetwo",complited:"70%"},
    // {select:false,id:3,name:"rahusdfdsfsfsfsfdsfsfsdfsdfsfsfsl",email:"r@gmail.dgdfgdcom",phoneNumber:"12345dgdgdffgdf6",courseName:"coursethree",complited:"90%"}])
    // setLoading(false)

    // setDataFromApi([{select:false,id:1,name:"rdfsfsdfsfsahul",email:"r@gmail.gdgdgdgcom",phoneNumber:"123456",courseName:"courseOne",complited:"80%"}
    // ,{select:false,id:2,name:"rahfsfddsfdsul",email:"r@gmail.dfgdgdcom",phoneNumber:"123456",courseName:"coursethree",complited:"70%"},
    // {select:false,id:3,name:"rahusdfdsfsfsfsfdsfsfsdfsdfsfsfsl",email:"r@gmail.dgdfgdcom",phoneNumber:"12345dgdgdffgdf6",courseName:"coursetwo",complited:"90%"}])
    //

    getAllBasicCourse()
      .then((res) => {
        //console.log(res.data);
        serCourseArr(res.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
    //serCourseArr([{courseName:"coursethree"},{courseName:"coursetwo"},{courseName:"courseOne"}])

    // getReports({}).then((res)=>{
    //   setArr(res.data)
    //   setArrLength(res.data.length)
    //   setDataFromApi(res.data)
    //   console.log(res.data)
    //   setReportsArr(res.data)
    //   setCurPage(0);

    //   setLoading(false)
    // })
  }, []);

  const filterButtenFunction = (type) => {
    if (type == "filter") {
      let tempArr = [0, 0, 0];

      // console.log(tempArr);
      setArrLength(tempArr.length);
      setCurrentPage(0);
      setArr(tempArr);
    } else if (type == "clear") {
      setArrLength(dataFromApi.length);
      setArr(dataFromApi);
      setSelectFrom("");
      setSelectTo("");
      setCurrentPage(0);
    }
  };

  const handelCheckBox = (id, check, type) => {
    //console.log(id, check);

    let tempData = [];
    if (type == "all") {
      setAllselect(check);
      let data = arr.filter((e) => {
        let obj = { ...e, select: check };
        // console.log(obj);
        tempData.push(obj);
        return obj;
      });
    } else {
      let data = arr.filter((e) => {
        // console.log(e.id, id);
        if (e.uid == id) {
          let obj = { ...e, select: check };
          // console.log(obj);
          tempData.push(obj);
          return obj;
        } else {
          tempData.push(e);
          return e;
        }
      });
    }
    // console.log(tempData);
    setArr(tempData);
  };

  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(arrLength / pageSize)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };
  const NotifyClick = () => {
    setDisplayNotify(!displayNotify);
  };

  const haldelCourseFilter = (type, e) => {
    if (type == "course") {
      let data = dataFromApi.filter((el) => el.id == e);
      setArrLength(data.length);
      setCurrentPage(0);

      setArr(data);
    } else {
      let data = dataFromApi.filter(
        (el) =>
          (
            (Number(el?.completedLessons) / Number(el?.totalLesson)) *
            100
          ).toFixed(2) >= Number(selectFrom) &&
          (
            (Number(el?.completedLessons) / Number(el?.totalLesson)) *
            100
          ).toFixed(2) <= Number(selectTo)
      );
      setArrLength(data.length);
      setCurrentPage(0);
      setArr(data);

      // ((Number(arr[i]?.completedLessons) / Number(arr[i]?.totalLesson)) *
      // 100).toFixed(2);
      // completed = completedfd.toFixed(2);
      // const [selectTo, setSelectTo] = useState("");
      // const [selectFrom, setSelectFrom] = useState("");
    }
  };

  const moveTogeneratePage = () => {
    setLoadingScreen(true);
    let fil = [];

    for (let i = 0; i < arr.length; i++) {
      let completed = 0;
      if (arr[i]?.totalLesson && arr[i]?.completedLessons) {
        let completedfd =
          (Number(arr[i]?.completedLessons) / Number(arr[i]?.totalLesson)) *
          100;
        completed = completedfd.toFixed(2);
      }
      if (arr[i].select == true) {
        let obj = {};
        obj["name"] = arr[i]?.name;
        obj["id"] = arr[i].uid;

        obj["email"] = arr[i]?.email;
        obj["phoneNumber"] = arr[i]?.phoneNumber;
        obj["courseId"] = arr[i]?.id;
        obj["complited"] = completed;

        fil.push(obj);
      }
    }
    let time = new Date().getTime();
    uploadReports({
      id: "testReport" + time,
      data: fil,
      reportType: "Basic Course",
    })
      .then((res) => {
        setLoadingScreen(false);
        //console.log(res.data);
        alert("genreted");
      })
      .catch((e) => {
        setLoadingScreen(false);
      });
    // console.log(fil);
  };

  const delfunction = (name) => {
    // console.log(name);
    setopenLessonPopUp(false);
    let userList = arr.filter((e) => e.select == true);

    manualNotification({
      body: name.body,
      trigger: name.title,
      userList: userList,

      params: {
        courseId: arr[0].courseId ? arr[0].courseId : "",
      },
    });
  };

  const sendNotification = () => {
    let userList = arr.filter((e) => e.select == true);
    //console.log(userList);
    if (userList.length > 0) {
      setopenLessonPopUp(true);
    } else {
      alert("select user");
      return;
    }
  };

  return (
    <div className="section" style={{ backgroundColor: "#e5e5e5" }}>
      <Navbar page={t("Reports")} />
      <LoadingScreen
        open={loadingScreen}
        onClose={() => setLoadingScreen(false)}
      />

      <EnterBodyAndTittel
        open={openLessonPopUp}
        onClose={() => setopenLessonPopUp(false)}
        delfun={(topic) => {
          delfunction(topic);
        }}
      />
      <div
        className="createcourse-main"
        style={{ gridTemplateColumns: "19vw 1fr" }}
      >
        <Sidebar page={"reports"} hidepage={false} />
        <div className="admin-overdue">
          <div className="admin-overdue-top">
            <div className="admin-overdue-top-item"></div>
            <div
              className="admin-dashboard-main-mid-item-last-filter"
              style={{
                marginLeft: "39.5vw",
                marginBottom: "1vw",
                gap: "20px",
                display: "flex",
              }}
            >
              <div className="admin-dashboard-main-top-last-filter">
                <button
                  className="primary-btn"
                  onClick={() => {
                    moveTogeneratePage();
                  }}
                >
                  Generate Reports
                </button>
              </div>

              <div className="admin-dashboard-main-top-last-filter">
                <button
                  className="primary-btn"
                  onClick={() => {
                    sendNotification();
                  }}
                >
                  Send Notification
                </button>
              </div>
            </div>
          </div>
          <div className="admin-overdue-mid">
            <div className="admin-overdue-mid-left">
              <div className="admin-overdue-mid-left-txt">
                {" "}
                {t("Basic Course")}
              </div>
              <div className="admin-overdue-mid-left-input">
                <select
                  onChange={(e) => {
                    haldelCourseFilter("course", e.target.value);
                  }}
                >
                  {courseArr?.map((e) => (
                    <option value={e?.courseId}> {e?.courseId}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="admin-overdue-mid-left">
              <div
                className="admin-overdue-mid-left-txt"
                style={{ width: "120px", fontSize: "10px" }}
              >
                {" "}
                {t("completion range from")}
              </div>
              <div className="admin-overdue-mid-left-input">
                <input
                  value={selectFrom}
                  style={{ width: "100px", height: "50px" }}
                  onChange={(e) => {
                    setSelectFrom(e.target.value);
                    // haldelCourseFilter("from", e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="admin-overdue-mid-left">
              <div
                className="admin-overdue-mid-left-txt"
                style={{ width: "110px", fontSize: "10px" }}
              >
                {" "}
                {t("completion range to")}
              </div>
              <div className="admin-overdue-mid-left-input">
                <input
                  value={selectTo}
                  style={{ width: "100px", height: "50px" }}
                  onChange={(e) => {
                    // haldelCourseFilter("to", e.target.value);
                    setSelectTo(e.target.value);
                  }}
                />
              </div>
            </div>

            <div
              className="admin-dashboard-main-mid-item-last-filter"
              style={{ marginTop: "2.5vw" }}
            >
              {" "}
              <div className="admin-dashboard-main-top-last-filter">
                <button
                  className="primary-btn"
                  onClick={() => {
                    haldelCourseFilter("clear");
                  }}
                >
                  Filter
                </button>
              </div>
            </div>
            <div
              className="admin-dashboard-main-mid-item-last-filter"
              style={{ marginTop: "2.5vw" }}
            >
              {" "}
              <div className="admin-dashboard-main-top-last-filter">
                <button
                  className="primary-btn"
                  onClick={() => {
                    filterButtenFunction("clear");
                  }}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
          <div className="admin-overdue-bottom">
            <div className="admin-overdue-bottom-table">
              <div
                className="admin-overdue-bottom-table-cnt"
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                }}
              >
                <div className="admin-overdue-bottom-table-head">
                  <input
                    type="checkBox"
                    checked={Allselect}
                    style={{ marginRight: "2vw" }}
                    onChange={(e) => {
                      handelCheckBox("c", e.target.checked, "all");
                    }}
                  />
                  Sr.No
                </div>
                <div className="admin-overdue-bottom-table-head">Name</div>
                <div className="admin-overdue-bottom-table-head">Email ID</div>
                <div className="admin-overdue-bottom-table-head">
                  Mobile number
                </div>
                <div className="admin-overdue-bottom-table-head">Course Id</div>
                <div className="admin-overdue-bottom-table-head">
                  Course Completion %
                </div>
              </div>
              {showLoading && (
                <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td">
                    <div className="spinner-container">
                      <div className="loading-spinner"></div>
                    </div>
                  </div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div
                    className="admin-overdue-bottom-table-td"
                    style={{ color: "#004577" }}
                  ></div>
                </div>
              )}
              {arr &&
                arr?.map((item, id) => {
                  if (id < pageSize * currentPage) return;
                  if (id >= pageSize * (currentPage + 1)) return;
                  let completed = 0;
                  if (item?.totalLesson && item?.completedLessons) {
                    let completedfd =
                      (Number(item?.completedLessons) /
                        Number(item?.totalLesson)) *
                      100;
                    completed = completedfd.toFixed(2);
                  }

                  // let creationDate =Timestamp.fromMillis(item.createdOn._seconds*1000).toDate()
                  // .toDateString();

                  return (
                    <div
                      className="admin-overdue-bottom-table-cnt"
                      key={id}
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                      }}
                    >
                      <div className="admin-overdue-bottom-table-td">
                        <input
                          type="checkBox"
                          checked={item.select}
                          style={{ marginRight: "2vw" }}
                          onChange={(e) => {
                            handelCheckBox(
                              item.uid,
                              e.target.checked,
                              "single"
                            );
                          }}
                        />
                        {id + 1}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item?.name}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item?.email}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item?.phoneNumber}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item?.id}
                      </div>
                      <div
                        className="admin-overdue-bottom-table-td"
                        // style={{ color: "#FD5353" }}
                        // onClick={() => {
                        //   window.open(
                        //     "item.downloadLink",
                        //     "_blank",
                        //     "noopener,noreferrer"
                        //   );
                        // }}
                      >
                        {completed}
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="admin-overdue-bottom-pagination">
              <div className="admin-overdue-bottom-pagination-cnt">
                <div className="admin-overdue-bottom-pagination-cnt-item">
                  <svg
                    onClick={() => clickhandler(currentPage - 1)}
                    width="auto"
                    height="22"
                    viewBox="0 0 14 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div className="admin-overdue-bottom-pagination-cnt-item-btn">
                  {currentPage + 1} of {Math.ceil(arrLength / pageSize)}
                </div>
                <div
                  style={{ marginRight: "19vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <svg
                    onClick={() => clickhandler(currentPage + 1)}
                    width="auto"
                    height="20"
                    viewBox="0 0 13 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div
                  style={{ display: "flex", marginRight: "-30vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <label className="admin-row">{t("Show")}</label>
                  <select
                    style={{
                      width: "4vw",
                      margin: "0.5vw",
                      marginBottom: "1.5vw",
                      height: "1.5vw",
                    }}
                    value={pageSize}
                    onChange={(e) => setPageSize(e.target.value)}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                  </select>
                  <label className="admin-row"> {t("PagePerItem")}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ height: "100%", width: "19vw", backgroundColor: "white" }}
        ></div>
        <div className="courselist-main-cnt-back">
          <div className="courselist-main-cnt-back-btn">
            <Link to={"/admin"}>
              <button className="secondary-btn">Back</button>{" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenerateReportPage;
