import { Box, Button, Paper, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { getCookie } from "../../../Cookies";
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from "firebase/auth";
import "../../styles/Training/Training.css";
import "../../Translater/Translater";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { auth } from "../../../Firebase";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function ChangePassword() {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  useEffect(() => {
    if (getCookie("UID") == null || getCookie("ROLE") != "user") {
      goToHome();
      return <></>;
    }
  });
  const { t } = useTranslation();

  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const updateUserPassword = async () => {
    if(!newPassword || !confirmPassword) {
      alert("Please fill all fields");
      return;
    }
    if(newPassword.length < 6) {
      alert("Password must be at least 6 characters");
      return;
    }
    if(!currentPassword){
      alert("Please enter your current password");
    }
    if (newPassword != confirmPassword) {
      alert("New password and confirm password are not match");
      return;
    }
    const user = auth.currentUser;
    let credential = EmailAuthProvider.credential(user.email, currentPassword);
    reauthenticateWithCredential(user, credential)
      .then(async (userCred) => {
        console.log(userCred);
        await updatePassword(userCred.user, newPassword);
        alert("Password Changed");
        navigate("/user/profile");
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
  };

  return (
    <div>
    
      <Box sx={{ mt: 5 }}>
        <Container>
          <form action="" style={{ padding: "20px", height: "80vh", background: "#fff" }}>
            <Box>
              <Typography sx={{ fontWeight: "700", mb: 1, color: "#007C84" }}>
                Current Password
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  width: "50%",
                  borderRadius: "2px",
                  padding: "10px 15px",
                  border: "1px solid #007C84",
                  outline: "none",
                }}
              >
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  required
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  style={{ border: "none", outline: "none", width: "90%" }}
                />
                <Box
                  onClick={() => setShowPassword(!showPassword)}
                  sx={{
                    marginLeft: "auto",
                  }}
                >
                  {showPassword ? (
                    <VisibilityIcon sx={{ color: "#007C84" }} />
                  ) : (
                    <VisibilityOffIcon sx={{ color: "#007C84" }} />
                  )}
                </Box>
              </Box>
              {/* <input
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
                style={{
                  width: "50%",
                  borderRadius: "2px",
                  padding: "10px 15px",
                  border: "1px solid #007C84",
                  outline: "none",
                }}
              /> */}
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ fontWeight: "700", mb: 1, color: "#007C84" }}>
                Enter New Password
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  width: "50%",
                  borderRadius: "2px",
                  padding: "10px 15px",
                  border: "1px solid #007C84",
                  outline: "none",
                }}
              >
                <input
                  type={showNewPassword ? "text" : "password"}
                  placeholder="Password"
                  required
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  style={{ border: "none", outline: "none", width: "90%" }}
                />
                <Box
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  sx={{
                    marginLeft: "auto",
                  }}
                >
                  {showNewPassword ? (
                    <VisibilityIcon sx={{ color: "#007C84" }} />
                  ) : (
                    <VisibilityOffIcon sx={{ color: "#007C84" }} />
                  )}
                </Box>
              </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ fontWeight: "700", mb: 1, color: "#007C84" }}>
                Confirm Password
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  width: "50%",
                  borderRadius: "2px",
                  padding: "10px 15px",
                  border: "1px solid #007C84",
                  outline: "none",
                }}
              >
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Password"
                  required
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  style={{ border: "none", outline: "none", width: "90%" }}
                />
                <Box
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  sx={{
                    marginLeft: "auto",
                  }}
                >
                  {showConfirmPassword ? (
                    <VisibilityIcon sx={{ color: "#007C84" }} />
                  ) : (
                    <VisibilityOffIcon sx={{ color: "#007C84" }} />
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  marginTop: "auto",
                  justifyContent: "end",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  sx={{
                    width: "180px",
                    textTransform: "none",
                    color: "#fff",
                    padding: "5px 30px",
                    background: "linear-gradient(to right, #007848, #004577)",
                    borderRadius: "12px",
                    fontWeight: 600,
                  }}
                  onClick={updateUserPassword}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        </Container>
      </Box>
    </div>
  );
}

export default ChangePassword;
